import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  cancelType,
  policyStatus,
  transactionTypes,
  typeOfPolicy,
  form_of_buisness,
  segment,
  cancellationReason,
  STANDARD_APP_TIME_FORMAT,
  application_language,
} from "../../../../../../../constants/constants";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../../components/formsComponents/CustomDatePicker";
import {
  useCustomerFullInfo,
  useCustomerPropspect,
  useDispatchWrapper,
  useFetchPolicies,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import {
  addHabitationalCustomerInfoAsync,
  fetchHabitationalAdditionalInsuredListAsync,
} from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import {
  IHabitationalAdditionalInsured,
  IHabitationalCustomerInfo,
} from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetails.types";
import {
  LoadState,
  MODULE_IDS,
  POLICIES,
} from "../../../../../../../constants/enums";
import { useNavigate } from "react-router-dom";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { BinderNumber } from "../../../../../../../components/BinderNumber/BinderNumber";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { AddressSearchAutoComplete } from "../../../../../../../components/AddressAutoComplete/AddressSearchAutoComplete";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { RoleBasedMessages } from "../../../../../../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { AdditionalInsuredForm } from "../AdditionalInsured/AdditionalInsuredDialog/AdditionalInsuredDialogForm";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { AddOutlined } from "@mui/icons-material";

export const HabitationalCustomerInfo: React.FC<{
  customerId: number;
  customerPolicyId?: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const isUpdate = customerPolicyId ? true : false;
  const { policies, getPolicySubtypes } = useFetchPolicies();
  const customerFullDetails = useCustomerFullInfo();
  const customerProspect = useCustomerPropspect();
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { list, loading: listloading } = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.basicDetails.additional_insured
  );
  const [openContact, setOpenContact] =
    React.useState<IHabitationalAdditionalInsured | null>(null);
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      ...customerInfo.data,
      effective_time: moment().hours(0).minutes(1).format("HH:mm"),
      current_policy_status: customerPolicyId
        ? customerInfo.data?.current_policy_status ?? "Prospect"
        : "Prospect", // Force "Prospect" if new
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_name) {
        errors.customer_name = "*Customer name is required!";
      } else if (!values.form_of_business) {
        errors.form_of_business = "*Form of Business is required";
      } else if (!values.transaction_type) {
        errors.transaction_type = "*Transaction type is required!";
      } else if (!values.residential_or_business) {
        errors.residential_or_business = "*Address type is required!";
      } else if (!values.line_of_business) {
        errors.line_of_business = "*Line of Business is required";
      } else if (Number(values.sub_type) === 0) {
        errors.sub_type = "*Line of Business is required";
      }
      if (!values.segment || values.segment.length === 0) {
        errors.segment = "*Segment is required!";
      }

      if (values.current_policy_status === "Active") {
        if (!values.policy_number && !values.binder_number) {
          errors.binder_number = "*Binder number is required!";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        addHabitationalCustomerInfoAsync(
          { ...values, customer_id: customerId },
          customerInfo.data,
          isUpdate,
          (isSuccess, policyId) => {
            if (isSuccess && policyId) {
              navigate(
                `/habitational-policy/${props.customerId}/basic-details/${policyId}`
              );
              if (buttonClicked === "saveAndNext") {
                changeStep(1);
              }
            }
            setLoading(false);
          }
        )
      );
    },
  });
  const handleBinderSave = (
    binderNumber: string | null,
    onCallBack: (isSuccess: boolean) => void
  ) => {
    dispatch(
      addHabitationalCustomerInfoAsync(
        { ...values, binder_number: binderNumber },
        customerInfo.data,
        isUpdate,
        (isSuccess) => {
          onCallBack(isSuccess);
        }
      )
    );
  };

  const handleCheckBox =
    (key: keyof IHabitationalCustomerInfo) =>
    (e: React.SyntheticEvent<Element>, checked: boolean) => {
      setFieldValue(key, checked);
    };

  // React.useEffect(() => {
  //   setValues({
  //     ...customerInfo.data,
  //     customer_code:
  //       customerInfo.data.customer_code || customerProspect.data.customer_code,
  //     // line_of_business: customerFullDetails.,
  //     current_policy_status:
  //       customerInfo.data?.current_policy_status || "Prospect",
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customerProspect.data]);

  const isInitialized = React.useRef(false);

  React.useEffect(() => {
    if (!isInitialized.current) {
      if (!customerPolicyId && customerFullDetails) {
        // New Policy Case — force Prospect
        setValues((prevValues: any) => ({
          ...prevValues,
          customer_code: customerProspect.data.customer_code,
          customer_name: customerProspect.data.customer_name,
          address: prevValues.address || customerProspect.data.mailing_address,
          province_or_state:
            prevValues.province_or_state ||
            customerProspect.data.province_or_state,
          city: prevValues.city || customerProspect.data.city,
          postal_code:
            prevValues.postal_code || customerProspect.data.postal_code,
          country: prevValues.country || customerProspect.data.country,
          unit_or_suite:
            prevValues.unit_or_suite || customerProspect.data.unit_or_suite,
          language: customerProspect.data.language,
          line_of_business: POLICIES.HABITATIONAL_POLICY,
          form_of_business: customerProspect.data.form_of_business,
          effective_time: moment().hours(0).minutes(1).format("HH:mm"),
          current_policy_status: "Prospect",
        }));
      } else {
        // Update Case — preserve current_policy_status if it exists
        setValues((prev) => ({
          ...prev,
          ...customerInfo.data,
          effective_time: moment().hours(0).minutes(1).format("HH:mm"),
          current_policy_status:
            customerInfo.data?.current_policy_status ?? "Prospect",
        }));
      }
      isInitialized.current = true;
    }
  }, [customerInfo.data, customerProspect.data, customerPolicyId]);

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues((prevValues: any) => ({
      ...prevValues, // Ensure previous state is maintained
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    }));
  };

  const handlePolicyStatusChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    // Safely cast value to string
    const selectedValue = value as string; // Cast `unknown` to `string`

    console.log("status", selectedValue);

    setValues((prevValues: any) => ({
      ...prevValues,
      current_policy_status: selectedValue,
      binder_number:
        selectedValue === "Prospect" ? null : prevValues.binder_number,
      policy_number:
        selectedValue === "Prospect" ? null : prevValues.policy_number,
    }));
  };
  const handleLineOfBusiness = (event: SelectChangeEvent<unknown>) => {
    setValues({
      ...values,
      line_of_business: event.target.value as string,
      sub_type: "",
    });
  };

  const handleFormOfBusiness = async (event: SelectChangeEvent<unknown>) => {
    const formOfBusiness = event.target.value as string;

    if (customerPolicyId && formOfBusiness === "Individual") {
      dispatch(fetchHabitationalAdditionalInsuredListAsync(customerPolicyId));
      setValues({
        ...values,
        form_of_business: formOfBusiness,
      });
    } else {
      setValues({
        ...values,
        form_of_business: formOfBusiness,
      });
    }
  };

  const contactsTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: listloading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "type",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: (value, row: IHabitationalAdditionalInsured) => (
              <Button
                variant="contained"
                onClick={() =>
                  setOpenContact({
                    ...row,
                    customer_policy_id: customerPolicyId ?? null,
                  })
                }
                startIcon={<AddOutlined />}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },

    columns: [
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IHabitationalAdditionalInsured) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenContact(row);
              }}
            >
              {row.type}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "mobile_number",
        headerName: "Mobile",
        fieldName: "mobile_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "phone_number",
        headerName: "Phone",
        fieldName: "phone_number",
        exportCellWidth: 25,
        enableSorting: true,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: IHabitationalAdditionalInsured) => {
          if (row.phone_number) {
            return (
              <RoleBasedMessages
                phone={row.phone_number}
                moduleKey={MODULE_IDS.CUSTOMER}
              />
            );
          }

          return "--";
        },
      },
      {
        key: "gender",
        headerName: "Gender",
        fieldName: "gender",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "birth_date",
        headerName: "DOB",
        fieldName: "birth_date",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "marital_status",
        headerName: "Marital Status",
        fieldName: "marital_status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              // onViewClick={() => setOpenViewontacts(row)}
              onEditClick={() => {
                setOpenContact(row);
              }}
            />
          );
        },
      },
    ],
    items: list,
  };

  const handleComplete = () => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalAdditionalInsuredListAsync(customerPolicyId));
      setOpenContact(null);
    }
  };

  const formOfBusiness = values.form_of_business === "Individual";

  return (
    <form onSubmit={handleSubmit}>
      {formOfBusiness && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Form of Business</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="form_of_business"
              fullWidth
              value={values.form_of_business}
              onChange={handleFormOfBusiness}
              displayEmpty
              placeholder="Select one"
              options={form_of_buisness}
              error={errors.form_of_business ? true : false}
              helperText={errors.form_of_business as string}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <DataTable {...contactsTableProps} />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Customer Name</CustomFormLabel>
          <CustomTextField
            id="customer_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.customer_name}
            onChange={handleChange}
            error={errors.customer_name ? true : false}
            helperText={errors.customer_name as string}
          />
        </Grid>

        <Grid item xs={12} lg={formOfBusiness ? 2.4 : 1.8}>
          <CustomFormLabel>Customer Code</CustomFormLabel>
          <CustomTextField
            name="customer_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            disabled
            value={values.customer_code}
          />
        </Grid>

        {!formOfBusiness && (
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Form of Business</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="form_of_business"
              fullWidth
              value={values.form_of_business}
              onChange={handleFormOfBusiness}
              displayEmpty
              placeholder="Select one"
              options={form_of_buisness}
              error={errors.form_of_business ? true : false}
              helperText={errors.form_of_business as string}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={formOfBusiness ? 2.4 : 1.8}>
          <CustomFormLabel>Transaction Type</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="transaction_type"
            fullWidth
            value={values.transaction_type}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
            error={errors.transaction_type ? true : false}
            helperText={errors.transaction_type as string}
            options={transactionTypes.map((transactionType) => {
              return { label: transactionType, value: transactionType };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={formOfBusiness ? 2.4 : 1.8}>
          <CustomFormLabel>Language</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.language}
            name="language"
            onChange={handleChange}
            placeholder="Select One"
            displayEmpty
            options={application_language}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={formOfBusiness ? 2.4 : 1.8}>
          <CustomFormLabel>Address Type</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="residential_or_business"
            fullWidth
            value={values.residential_or_business}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
            error={errors.residential_or_business ? true : false}
            helperText={errors.residential_or_business as string}
            options={["R", "B"].map((transactionType) => {
              return { label: transactionType, value: transactionType };
            })}
          ></ControlledCustomSelect>
        </Grid>
        <Grid item xs={12} lg={3.6}>
          <AddressSearchAutoComplete
            address={values.address}
            onAddressChange={handleAddress}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Unit/Suite</CustomFormLabel>
          <CustomTextField
            name="unit_or_suite"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.unit_or_suite}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            id="city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>State/Province</CustomFormLabel>
          <CustomTextField
            id="province_or_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.province_or_state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.2}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            id="postal_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.postal_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            id="country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.country}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <LabelCard heading="Policy Information" />

      <Grid container spacing={1}>
        <Grid item xs={12} md={1.5}>
          <CustomFormLabel>Quote Number</CustomFormLabel>
          <CustomTextField
            name="quote_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.quote_number}
            onChange={handleChange}
            disabled={!customerPolicyId}
          />
        </Grid>

        {values.current_policy_status !== "Prospect" && (
          <>
            <BinderNumber
              value={values.binder_number}
              policyId={customerPolicyId}
              onSave={handleBinderSave}
              girdSize={2.5}
              error={errors.binder_number ? true : false}
              helperText={errors.binder_number as string}
            />

            <Grid item xs={12} lg={2.5}>
              <CustomFormLabel>Policy Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.policy_number}
                onChange={handleChange}
                disabled={!customerPolicyId}
                required={values.current_policy_status === "Active"}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={2}>
          <CustomFormLabel>Effective Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.effective_date}
            onChange={(newValue) => {
              setFieldValue("effective_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} md={customerPolicyId ? 1.7 : 1.8}>
          <CustomFormLabel>Effective Time</CustomFormLabel>
          <CustomTimePicker
            fullWidth
            value={values.effective_time}
            onChange={(newValue) => {
              setFieldValue("effective_time", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} md={customerPolicyId ? 1.7 : 1.8}>
          <CustomFormLabel>Expiry Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.expiry_date || ""}
            onChange={(newValue) => {
              setFieldValue("expiry_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Segment</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            name="segment"
            value={values.segment || []}
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            multiple
            options={segment.map((template) => {
              return { label: template, value: template };
            })}
            error={errors.segment ? true : false}
            helperText={errors.segment as string}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Type</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="line_of_business"
            fullWidth
            value={values.line_of_business}
            onChange={handleLineOfBusiness}
            disabled
            displayEmpty
            placeholder={"Select one"}
            error={errors.line_of_business ? true : false}
            helperText={errors.line_of_business as string}
            options={policies.map((policy) => {
              return { label: policy.type, value: policy.type };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Line of Business</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="sub_type"
            fullWidth
            value={Number(values.sub_type)}
            onChange={handleChange}
            displayEmpty
            placeholder="Select One"
            helperText={errors.sub_type as string}
            options={getPolicySubtypes(values.line_of_business || "").map(
              (item) => {
                return { label: item.name, value: item.policy_id };
              }
            )}
            error={errors.sub_type ? true : false}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Program</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.program}
            name="program"
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            options={["Option 1", "Option 2"].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Type of Policy</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="type_of_policy"
            fullWidth
            value={values.type_of_policy}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {typeOfPolicy.map((policyType) => {
              return <MenuItem value={policyType}>{policyType}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Transaction</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            name="policy_status"
            value={values.policy_status}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {policyStatus.map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Status</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="current_policy_status"
            fullWidth
            value={values.current_policy_status}
            onChange={handlePolicyStatusChange}
            displayEmpty
            placeholder="Select one"
            options={["Active", "Cancelled", "Lapsed", "Prospect"].map(
              (status) => {
                return { label: status, value: status };
              }
            )}
          ></ControlledCustomSelect>
        </Grid>
        {values.current_policy_status === "Cancelled" && (
          <>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Cancel Reason</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="cancellation_reason"
                fullWidth
                value={values.cancellation_reason}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {cancellationReason.map((reason) => {
                  return <MenuItem value={reason}>{reason}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date of Cancel</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_cancelled || ""}
                onChange={(newValue) => {
                  setFieldValue("date_cancelled", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Cancel Type</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="cancellation_type"
                value={values.cancellation_type}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {cancelType.map((status) => {
                  return <MenuItem value={status}>{status}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <LabelCard heading="Preferences" />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox(
                "send_invoices_renewal_and_receivacble_letter_via_emails"
              )}
              control={
                <Checkbox
                  checked={
                    values.send_invoices_renewal_and_receivacble_letter_via_emails ||
                    false
                  }
                />
              }
              label="Send Invoices , Renewal , Recievable By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("send_statements_via_email")}
              control={
                <Checkbox
                  name="send_statements_via_email"
                  checked={values.send_statements_via_email || false}
                />
              }
              label="Send Statement By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("send_eDocs_via_email")}
              control={
                <Checkbox
                  name="send_eDocs_via_email"
                  checked={values.send_eDocs_via_email || false}
                />
              }
              label="Send Docs By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
        <RoleBasedCustomButton
          type="submit"
          moduleKey={MODULE_IDS.HABITATIONAL}
          disabled={loading}
          variant="contained"
          onClick={() => setButtonClicked("save")}
        >
          Save
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          type="submit"
          moduleKey={MODULE_IDS.HABITATIONAL}
          disabled={loading}
          variant="contained"
          onClick={() => setButtonClicked("saveAndNext")}
        >
          Save & Next
        </RoleBasedCustomButton>
      </Stack>
      {openContact && (
        <AdditionalInsuredForm
          open={true}
          insured={openContact}
          onClose={() => setOpenContact(null)}
          onComplete={handleComplete}
        />
      )}
    </form>
  );
};
