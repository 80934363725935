import produce from "immer";
import {
  ADD_CSIO_COMPANY,
  ADD_CSIO_COMPANY_ID,
  ADD_CSIO_COMPANY_PROGESS,
  CLEAR_CSIO_COMPANY_STATE,
  CSIO_COMPANY_ERROR,
  FETCH_CSIO_COMPANY_LIST_FAILED,
  FETCH_CSIO_COMPANY_LIST_PROGRESS,
  FETCH_CSIO_COMPANY_LIST_SUCCESS,
  FETCH_CSIO_COMPANY_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialCsioCompanyReducerState } from "./csioCompanyState";
import { ICsioCompanyActions } from ".";

export const csioCompanyReducer = (
  state: IStoreState["borkerage"]["csioCompany"] = initialCsioCompanyReducerState,
  action: ICsioCompanyActions
) => {
  switch (action.type) {
    case FETCH_CSIO_COMPANY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.loading = LoadState.InProgress;
        draftState.csioCompany.list = [];
      });
      return newState;
    }
    case FETCH_CSIO_COMPANY_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.loading = LoadState.Loaded;
        draftState.csioCompany.list = list;
      });
      return newState;
    }
    case FETCH_CSIO_COMPANY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.loading = LoadState.InProgress;
        draftState.csioCompany.list = [];
      });
      return newState;
    }
    case ADD_CSIO_COMPANY_PROGESS: {
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.saveLoading = true;
      });
      return newState;
    }
    case ADD_CSIO_COMPANY: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.loading = LoadState.Loaded;
        draftState.csioCompany.saveLoading = false;
        draftState.csioCompany.data = data;
      });
      return newState;
    }

    case FETCH_CSIO_COMPANY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.loading = LoadState.InProgress;
      });
      return newState;
    }

    case CSIO_COMPANY_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.csioCompany.error = action.payload.message;
        draftState.csioCompany.saveLoading = false;
        draftState.csioCompany.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_CSIO_COMPANY_STATE: {
      return initialCsioCompanyReducerState;
    }

    default: {
      return state;
    }
  }
};
