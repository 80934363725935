/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearInterestedPartyState } from "../../../../../../redux/InterestedParties/IntrestedPartiesActions";
import { fetchHabitationalIntrestedListAsync } from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterestedActions";
import { clearHabitationalCoverageState, fetchHabitationalCoverageListAsync } from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoveragesActions";

export const useHabitationalCoevrage = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();

  const Coverage = useSelector(
    (storeState: IStoreState) => storeState.habitational.coverages.data
  );
  const coverageDropDown = React.useMemo(() => {
    return Coverage.map((item) => {
      return {
        label: item.incremental_number,
        value: item.incremental_number || "",
      };
    });
  }, [Coverage]);



    React.useEffect(() => {
      if (customerPolicyId) {
        dispatch(fetchHabitationalCoverageListAsync(Number(customerPolicyId)));
      }
      return(()=>{
        dispatch(clearHabitationalCoverageState());
      })
    }, []);
  


  return { coverageDropDown };
};
