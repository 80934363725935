import React from "react";
import { RightPanel } from "../../../../../../../components/RightPanel";
import { IAutomobileDriverClaims } from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { DriverClaims } from "./DriverClaims";

interface IAutomobileDriverClaimsRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  claimNumber?: number;
  onSaveSuccess: (claim: IAutomobileDriverClaims) => void;
  readonly?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onClose: () => void;
  initialData?: IAutomobileDriverClaims;
    disableApiCalls?: boolean;
}

export const DriverClaimsRightPanel: React.FC<
IAutomobileDriverClaimsRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    claimNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Driver Claims"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="90%"
      >
        <DriverClaims
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          claimNumber={claimNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          showOnlySaveButton
          staticStatusValue={staticStatusValue}
          created_from={created_from}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
