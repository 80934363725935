import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { initialEquipmentState } from "./equipment.types";
import { EquipmentActions } from ".";
import { CLEAR_EQUIPMENT_STATE, FETCH_EQUIPMENT_FAILED, FETCH_EQUIPMENT_LIST_FAILED, FETCH_EQUIPMENT_LIST_PROGRESS, FETCH_EQUIPMENT_LIST_SUCCESS, FETCH_EQUIPMENT_PROGRESS, FETCH_EQUIPMENT_SUCCESS } from "./equipmentActions";



export const equipmentReducer = (
    state: IStoreState["borkerage"]["equipment"] = initialEquipmentState,
    action: EquipmentActions,
  ) => {
    switch (action.type) {
      case FETCH_EQUIPMENT_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.list = [];
        });
        return newState;
      }
      case FETCH_EQUIPMENT_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.list = data;
        });
        return newState;
      }
      case FETCH_EQUIPMENT_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.list = [];
        });
        return newState;
      }

      case FETCH_EQUIPMENT_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.dataLoading = LoadState.InProgress;
          draftState.data = initialEquipmentState["data"];
          draftState.error = null;
        });
        return newState;
      }
      case FETCH_EQUIPMENT_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
            draftState.dataLoading = LoadState.Loaded;
            draftState.data =data;
        });
        return newState;
      }
      case FETCH_EQUIPMENT_FAILED: {
        const { errorMessage } = action.payload;
        const newState = produce(state, (draftState) => {
            draftState.dataLoading = LoadState.Failed;
            draftState.error =errorMessage;
        });
        return newState;
      }
  
      case CLEAR_EQUIPMENT_STATE: {
        return initialEquipmentState;
      }
  
      default: {
        return state;
      }
    }
  };