import { LoadState } from "../../constants/enums";
import { IBrokerCodeState } from "./brokerCode.types";

export const initialBrokerCodeState: IBrokerCodeState = {
  brokerCode: {
    list: [],
    data: {
      broker_code_id: null,
      broker_code_primary_id: 0,
      insurer_code_id: 2,
      insurer_code_or_mga_or_aga: "",
      branch_office_name: null,
      broker_code: "",
      group_code: null,
      group: null,
      group_name: null,
      producer: null,
      auto_discount: null,
      property_discount: null,
      type: null,
      branch_code_insurer: null,
      branch_office_code: null,
      province: null,
      assinged_to: null,
      status: "ACTIVE",
      insert_ts: "",
      create_ts: ""
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
