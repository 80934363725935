import React from "react";
import {
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import {
  IAutomobileDriverInformation,
  IFraudMisrepresentation,
} from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import produce from "immer";
import { FormikErrors } from "formik";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import {
  ControlledCustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { Add, Remove } from "@mui/icons-material";

interface FraudOrMisrepresentationTableProps {
  values: IAutomobileDriverInformation;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<IAutomobileDriverInformation>> | any;
  onAddNewFraudOrMisrepresentation: (currentIndex: number) => void;
  onRemoveFraudOrMisrepresentation: (index: number) => void;
  onFraudOrMisrepresentationRowChange: (
    key: keyof IFraudMisrepresentation,
    rowIndex: number,
    value: string
  ) => void;
}

const FraudOrMisrepresentationTable: React.FC<FraudOrMisrepresentationTableProps> = ({
  values,
  setFieldValue,
  onAddNewFraudOrMisrepresentation,
  onRemoveFraudOrMisrepresentation,
  onFraudOrMisrepresentationRowChange,
}) => {
  const handleFraudOrMisrepresentationRowChange =
    (key: keyof IFraudMisrepresentation, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFraudOrMisrepresentationRowChange(key, rowIndex, event.target.value);
    };

  const handleFraudOrMisrepresentationSelectChange =
    (key: keyof IFraudMisrepresentation, rowIndex: number) =>
    (event: SelectChangeEvent<any>) => {
      onFraudOrMisrepresentationRowChange(key, rowIndex, event.target.value);
    };

  // const handleCurrencyRowChange =
  //   (key: keyof IFraudMisrepresentation, rowIndex: number) => (newValue: string) => {
  //     onFraudOrMisrepresentationRowChange(key, rowIndex, newValue);
  //   };

  const handleRemoveFraudOrMisrepresentationRow = (index: number) => () => {
    onRemoveFraudOrMisrepresentation(index);
  };

  const handleFraudOrMisrepresentationNewRow = (currentRowIndex: number) => () => {
    onAddNewFraudOrMisrepresentation(currentRowIndex);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TableContainer>
          <Table
            aria-label="collapsible table"
            sx={{
              whiteSpace: {
                xs: "nowrap",
                sm: "unset",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h5">
                    #
                  </Typography>
                </TableCell>
               
                <TableCell sx={{ width: "30%" }} align="center">
                  <Typography fontWeight={600} variant="h5">
                    Description
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display={"flex"}>
                    <Button
                        variant="contained"
                      color="primary"
                      onClick={handleFraudOrMisrepresentationNewRow(
                        values.fraud_or_misrepresentation.length + 1
                      )}
                    >
                      <Add fontSize="small" />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.fraud_or_misrepresentation.map((item, index) => {
                return (
                  <TableRow key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography fontWeight={600} variant="h5">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ControlledCustomSelect
                        value={item.description}
                        fullWidth
                        onChange={handleFraudOrMisrepresentationSelectChange(
                          "description",
                          index
                        )}
                        placeholder="Select"
                        displayEmpty
                        name="description"
                        options={[
                          "Cancelled due to Defaulting Policy Payment",
                          "Cancelled due to Licence Suspension",
                          "Cancelled due to Material Misrepresentation",
                          "Cancelled due to Non Disclosure",
                          "Driving Without Insurance",
                          "No Vehicle Hence No Insurance Required",
                          "Other",
                        ].map((template) => {
                          return { label: template, value: template };
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.date || ""}
                        onChange={(newValue) =>
                          onFraudOrMisrepresentationRowChange(
                            "date",
                            index,
                            newValue
                          )
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Box display={"flex"}>
                        <IconButton
                          color="error"
                          onClick={handleRemoveFraudOrMisrepresentationRow(
                            index
                            // handleRemoveFraudOrMisrepresentationRow(value.column.rowIndex
                          )}
                        >
                          <Remove fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={handleFraudOrMisrepresentationNewRow(index)}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FraudOrMisrepresentationTable;
