import { action } from "typesafe-actions";

import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IHabitationalScheduleDetail, IHabitationalScheduleSummary } from "./habitationalPropertySchedule.types";

export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_PROGRESS =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_PROGRESS";
export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_SUCCESS =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_SUCCESS";
export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_FAILED =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_FAILED";

export const fetchHabitationalScheduleSummaryListProgress = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_PROGRESS);
export const fetchHabitationalScheduleSummaryListSuccess = (
  data: IHabitationalScheduleSummary[]
) => action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_SUCCESS, { data });
export const fetchHabitationalScheduleSummaryListFailed = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_FAILED);

export const fetchHabitationalScheduleSummaryListAsync =
  (
    customerPolicyId: number,
    status?: String
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalScheduleSummaryListProgress());
      let finalUrl = `/policy/get-habitational-property-schedule-summary?customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IHabitationalScheduleSummary[] = res.data.data;
      dispatch(fetchHabitationalScheduleSummaryListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalScheduleSummaryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalScheduleSummaryAsync =
  (
    data: IHabitationalScheduleSummary,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-habitational-property-schedule-summary`, {
        ...data,
        customer_policy_id: customerPolicyId,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "ScheduleSummary saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_PROGRESS =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_PROGRESS";
export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_SUCCESS =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_SUCCESS";
export const FETCH_HABITATIONAL_SCHEDULE_SUMMARY_FAILED =
  "FETCH_HABITATIONAL_SCHEDULE_SUMMARY_FAILED";

export const fetchHabitationalScheduleSummaryProgress = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_PROGRESS);
export const fetchHabitationalScheduleSummarySuccess = (
  data: IHabitationalScheduleSummary
) => action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_SUCCESS, { data });
export const fetchHabitationalScheduleSummaryFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_SCHEDULE_SUMMARY_FAILED, { errorMessage });

export const fetchHabitationalScheduleSummaryAsync =
  (
    customerPolicyId: number,
    incrementalNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalScheduleSummaryProgress());
      const res = await api.get(
        `/policy/get-habitational-property-schedule-summary?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementalNumber}`
      );
      const data: IHabitationalScheduleSummary[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalScheduleSummarySuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchHabitationalScheduleSummaryFailed(
            "Oops! We couldn't find any records associated with your ScheduleSummary number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalScheduleSummaryFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const CLEAR_HABITATIONAL_SCHEDULE_SUMMARY =
  "CLEAR_HABITATIONAL_SCHEDULE_SUMMARY";
export const clearHabitationalScheduleSummary = () =>
  action(CLEAR_HABITATIONAL_SCHEDULE_SUMMARY);


export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_PROGRESS =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_PROGRESS";
export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_SUCCESS =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_SUCCESS";
export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_FAILED =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_FAILED";

export const fetchHabitationalScheduleDetailListProgress = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_PROGRESS);
export const fetchHabitationalScheduleDetailListSuccess = (
  data: IHabitationalScheduleDetail[]
) => action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_SUCCESS, { data });
export const fetchHabitationalScheduleDetailListFailed = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_FAILED);

export const fetchHabitationalScheduleDetailListAsync =
  (
    customerPolicyId: number,
    status?: String
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalScheduleDetailListProgress());
      let finalUrl = `/policy/get-habitational-property-schedule-detail?customer_policy_id=${customerPolicyId}`;

      if (status !== "-1" && status) {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IHabitationalScheduleDetail[] = res.data.data;
      dispatch(fetchHabitationalScheduleDetailListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalScheduleDetailListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalScheduleDetailAsync =
  (
    data: IHabitationalScheduleDetail,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean,data?: IHabitationalScheduleDetail) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(`/policy/edit-habitational-property-schedule-detail`, {
        ...data,
        customer_policy_id: customerPolicyId,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "ScheduleDetail saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true,res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_PROGRESS =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_PROGRESS";
export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_SUCCESS =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_SUCCESS";
export const FETCH_HABITATIONAL_SCHEDULE_DETAIL_FAILED =
  "FETCH_HABITATIONAL_SCHEDULE_DETAIL_FAILED";

export const fetchHabitationalScheduleDetailProgress = () =>
  action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_PROGRESS);
export const fetchHabitationalScheduleDetailSuccess = (
  data: IHabitationalScheduleDetail
) => action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_SUCCESS, { data });
export const fetchHabitationalScheduleDetailFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_SCHEDULE_DETAIL_FAILED, { errorMessage });

export const fetchHabitationalScheduleDetailAsync =
  (
    customerPolicyId: number,
    incrementalNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalScheduleDetailProgress());
      const res = await api.get(
        `/policy/get-habitational-property-schedule-detail?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementalNumber}`
      );
      const data: IHabitationalScheduleDetail[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalScheduleDetailSuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchHabitationalScheduleDetailFailed(
            "Oops! We couldn't find any records associated with your ScheduleDetail number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalScheduleDetailFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const CLEAR_HABITATIONAL_SCHEDULE_DETAIL =
  "CLEAR_HABITATIONAL_SCHEDULE_DETAIL";
export const clearHabitationalScheduleDetail = () =>
  action(CLEAR_HABITATIONAL_SCHEDULE_DETAIL);



export const CLEAR_HABITATIONAL_SCHEDULE_SUMMARY_STATE =
  "CLEAR_HABITATIONAL_SCHEDULE_SUMMARY_STATE";

export const clearHabitationalScheduleSummaryState = () =>
  action(CLEAR_HABITATIONAL_SCHEDULE_SUMMARY_STATE);
