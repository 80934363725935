import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { IGeneralLedger } from "./generalLedger.types";

export const FETCH_GENERAL_LEDGER_LIST_PROGRESS =
  "FETCH_GENERAL_LEDGER_LIST_PROGRESS";
export const FETCH_GENERAL_LEDGER_LIST_SUCCESS =
  "FETCH_GENERAL_LEDGER_LIST_SUCCESS";
export const FETCH_GENERAL_LEDGER_LIST_FAILED =
  "FETCH_GENERAL_LEDGER_LIST_FAILED";

export const fetchGeneralLedgerListProgress = () =>
  action(FETCH_GENERAL_LEDGER_LIST_PROGRESS);

export const fetchGeneralLedgerListSuccess = (data: IGeneralLedger[]) =>
  action(FETCH_GENERAL_LEDGER_LIST_SUCCESS, { data });

export const fetchGeneralLedgerListFailed = () =>
  action(FETCH_GENERAL_LEDGER_LIST_FAILED);

export const fetchGeneralLedgerListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    year: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGeneralLedgerListProgress());
      const res = await api.get(`/accounting/get-account-seeding-chart-of-accounts?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&year=${year}`);
      const data: IGeneralLedger[] = res.data.data;
      dispatch(fetchGeneralLedgerListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_GENERAL_LEDGER_STATE = "CLEAR_GENERAL_LEDGER_STATE";
export const clearGeneralLedgerState = () => action(CLEAR_GENERAL_LEDGER_STATE);
