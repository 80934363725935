import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { IHabitationalPolicyChange } from "./habitationalPolicyChangeReq.types";
import { uploadTaskFile } from "../../../helpers";

export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_PROGRESS =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_PROGRESS";
export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_SUCCESS =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_SUCCESS";
export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_FAILED =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_FAILED";

export const fetchHabitationalPolicyChangeReqListProgress = () =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_PROGRESS);
export const fetchHabitationalPolicyChangeReqListSuccess = (
  list: IHabitationalPolicyChange[],
  totalRecords: number
) =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchHabitationalPolicyChangeReqListFailed = () =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_LIST_FAILED);

export const fetchHabitationalPolicyChangeListAsync =
  (
    customerpolicyId: number,
    status: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPolicyChangeReqListProgress());

      let finalUrl = `/policy/get-habitational-policy-change-request?customer_policy_id=${customerpolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IHabitationalPolicyChange[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchHabitationalPolicyChangeReqListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchHabitationalPolicyChangeReqListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_PROGRESS =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_PROGRESS";
export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS";
export const FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_FAILED =
  "FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_FAILED";

export const fetchHabitationalPolicyChangeReqProgress = () =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_PROGRESS);
export const fetchHabitationalPolicyChangeReqSuccess = (
  data: IHabitationalPolicyChange
) =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS, { data });
export const fetchHabitationalPolicyChangeReqFailed = (error?: string) =>
  action(FETCH_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_FAILED, { error });

export const fetchHabitationalPolicyChangeReqAsync =
  (customerPolicyId: number, incrementalNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPolicyChangeReqProgress());
      const res = await api.get(
        `/policy/get-habitational-policy-change-request?customer_policy_id=${customerPolicyId}&incremental_number=${incrementalNumber}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalPolicyChangeReqSuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchHabitationalPolicyChangeReqFailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalPolicyChangeReqFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalPolicyChange =
  (
    data: IHabitationalPolicyChange,
    onCallback: (isSuccess: boolean, data?: IHabitationalPolicyChange) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
         customer_id: data.customer_id,
         policy_type: data.policy_type,
         customer_policy_id: data.customer_policy_id,
         page_name: "Habitational Policy Change",
      };

      const updateFilesJson = await uploadTaskFile(
        data.attachments as any,
        "POLICY",
        asPayload
      );
      const res = await api.post("/policy/edit-habitational-policy-change-request", {
        ...data,
        attachments: updateFilesJson,
      });
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Changed successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchHabitationalPolicyChangeReqSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_STATE =
  "CLEAR_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_STATE";

export const clearHabitationalPolicyChangeReqState = () =>
  action(CLEAR_HABITATIONAL_POLICY_CHANGE_REQ_APPLICATION_STATE);
