import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyRiskLocationState {
  data: IBusinessPolicyRiskLocation[];
  loading: LoadState;
  risklocation: IBusinessPolicyRiskLocation;
  risklocationLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyRiskLocation {
  customer_policy_id: number | null;
  customer_id: number | null;
  location_number: number | null;
  policy_number: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  year_built: string | null;
  building_name: string | null;
  number_of_stories: string | null;
  total_building_area: string | null;
  area_occupied: string | null;
  risk_inspected: string | null;
  date_inspected: string | null;
  walls: string | null;
  floors: string | null;
  roof: string | null;
  roof_coverings: string | null;
  electrical: string | null;
  heating: string | null;
  plumbing: string | null;
  other: string | null;
  basement: string | null;
  use_of_basement: string | null;
  roof_year: string | null;
  roof_year_status: string | null;
  electrical_year: string | null;
  electrical_year_status: string | null;
  heating_year: string | null;
  heating_year_status: string | null;
  plumbing_year: string | null;
  plumbing_year_status: string | null;
  fire_department: string | null;
  fire_hydrant: string | null;
  fire_extinguising_system: string | null;
  extinguishing_agent: string | null;
  fire_alarm: string | null;
  sprinklered: string | null;
  percentage_sprinklered: string | null;
  safe: string | null;
  safe_type: string | null;
  safe_class: string | null;
  burglar_alarm: string | null;
  protection_one: string | null;
  protection_two: string | null;
  protection_three: string | null;
  protection_four: string | null;
  left_value: string | null;
  right_value: string | null;
  front: string | null;
  back: string | null;
  status: string;
  insert_ts?: string;
  assigned_to: string | null;
  created_from: string | null;
}

export interface IRiskLocationRow {
  customer_policy_id: string;
  policy_number: string | null;
  customer_id: string | null;
  location_number: string | null;
}

export const defaultBusinessPolicyRiskLocationState: IBusinessPolicyRiskLocationState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    risklocation: {
      customer_policy_id: null,
      left_value: null,
      location_number: null,
      policy_number: null,
      status: "ACTIVE",
      customer_id: null,
      created_from: null,
      address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      unit_or_suite: null,
      year_built: null,
      building_name: null,
      number_of_stories: null,
      total_building_area: null,
      area_occupied: null,
      risk_inspected: null,
      date_inspected: null,
      walls: null,
      floors: null,
      roof: null,
      roof_coverings: null,
      electrical: null,
      heating: null,
      plumbing: null,
      other: null,
      basement: null,
      use_of_basement: null,
      roof_year: null,
      roof_year_status: null,
      electrical_year: null,
      electrical_year_status: null,
      heating_year: null,
      heating_year_status: null,
      plumbing_year: null,
      plumbing_year_status: null,
      fire_department: null,
      fire_hydrant: null,
      fire_extinguising_system: null,
      extinguishing_agent: null,
      fire_alarm: null,
      sprinklered: null,
      percentage_sprinklered: null,
      safe: null,
      safe_type: null,
      safe_class: null,
      burglar_alarm: null,
      protection_one: null,
      protection_two: null,
      protection_three: null,
      protection_four: null,
      right_value: null,
      front: null,
      back: null,
      assigned_to: null,
    },
    risklocationLoading: LoadState.NotLoaded,
    error: null,
  };
