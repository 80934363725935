import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { IExcelTemplate, IUpsertExcelTemplateStepTwo } from "./excel-template.types";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { api } from "../../../api/api";
import { closeLoaderWithMessage, openLoaderWithMessage, saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { makeApiCall } from "../../../helpers/postRequest";
import { fetchTemplateSuccess } from "../templateActions";





export const FETCH_EXCEL_TEMPLATE_PROGRESS = "FETCH_EXCEL_TEMPLATE_PROGRESS";
export const FETCH_EXCEL_TEMPLATE_SUCCESS = "FETCH_EXCEL_TEMPLATE_SUCCESS";
export const FETCH_EXCEL_TEMPLATE_FAILED = "FETCH_EXCEL_TEMPLATE_FAILED";

export const fetchExcelTemplateProgress = () => action(FETCH_EXCEL_TEMPLATE_PROGRESS);
export const fetchExcelTemplateSuccess = (data: IExcelTemplate) =>
    action(FETCH_EXCEL_TEMPLATE_SUCCESS, { data });
export const fetchExcelTemplateFailed = (errorMessage: string) =>
    action(FETCH_EXCEL_TEMPLATE_FAILED, { errorMessage });

export const fetchSingleExcelTemplateAsync =
    (template_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchExcelTemplateProgress());
                const res = await api.get(
                    `/template/get-template?template_uuid=${template_uuid}&pageNo=1&itemPerPage=10`
                );
                const data = res.data.data;
                if (data.length > 0) {
                    dispatch(fetchExcelTemplateSuccess(data[0]));
                } else {
                    dispatch(
                        fetchExcelTemplateFailed(
                            "Unfortunately, there are no records available at the moment."
                        )
                    );
                }
            } catch (err: any) {
                dispatch(fetchExcelTemplateFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            }
        };





export const upsertExcelTemplateAsync =
    (
        data: IExcelTemplate,
        // file: File | null,
        onCallback: (isSuccess: boolean, template_info?: IExcelTemplate) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const {
                    create_ts,
                    insert_ts,
                    ...rest
                } = data;
                dispatch(saveLoaderProgress());
                // const asPayload = {
                //     template_unique_no: rest.template_name,
                // };
                // const path = await uploadFile(
                //     file,
                //     "TEMPLATE",
                //     data.base_file_path as string,
                //     asPayload
                // );
                const res = await makeApiCall(
                    {
                        url: "/template/upsert-template",
                        method: "POST",
                    },
                    undefined,
                    {
                        ...rest,
                        // base_file_path: path,
                    }
                );

                const finalDocument: IExcelTemplate = res.data.data;

                dispatch(
                    showMessage({
                        type: "success",
                        message: "Template saved successfully",
                        displayAs: "snackbar",
                    })
                );
                dispatch(fetchTemplateSuccess(finalDocument as any));
                onCallback(true, finalDocument);
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            } finally {
                dispatch(saveLoaderCompleted());
            }
        };



export const CLEAR_EXCEL_TEMPLATES = "CLEAR_EXCEL_TEMPLATES";
export const clearExcelTemplateState = () => action(CLEAR_EXCEL_TEMPLATES);