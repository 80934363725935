/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import moment from "moment";
import {
  current_status,
  InsuredOrPrincipal,
  LineOfBusiness,
  payment_method,
} from "../../../../../../../constants/constants";
import { renderDropdownValue } from "../../../../../../../helpers";
import { IAutomobilePolicyBasicDetailsProps } from "../AutomobileBasicDetails.types";
import {
  addAutomobilePriorPolicyInfoAsync,
  fetchAutomobilePriorPolicyAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import { StandadCard } from "../../../../../../../components/Cards";

export const AutomobilePriorPolicy: React.FC<
  IAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.priorPolicy
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addAutomobilePriorPolicyInfoAsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if (buttonClicked === "saveAndNext" && isSuccess) {
              changeStep(3);
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchAutomobilePriorPolicyAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <StandadCard heading="Prior Policy" sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Line of Business </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.line_of_business}
                options={LineOfBusiness.map((business) => {
                  return { label: business, value: business };
                })}
                onChange={(newValue) => {
                  setFieldValue("line_of_business", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Policy Expiry Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_expiry_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_expiry_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Current Insurer</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.current_insurer}
                options={InsuredOrPrincipal.map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue("current_insurer", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Expiring Premium</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.expiring_premium}
                onChange={(value) => {
                  setFieldValue("expiring_premium", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Current Status</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="current_status"
                fullWidth
                value={values.current_status}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {(current_status || []).map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
          </Grid>
        </StandadCard>

        <Divider sx={{ mt: 4, mb: 2.5 }} />
        <StandadCard heading="Non-Payment Cancellation" sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Non Payment Code</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="non_payment_code"
                fullWidth
                value={values.non_payment_code}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {(payment_method || []).map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Non Payment Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.non_payment_date || ""}
                onChange={(newValue) => {
                  setFieldValue("non_payment_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Insurer</CustomFormLabel>
              <CustomTextField
                name="insurer"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.insurer}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Amount Owing</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.amount_owing}
                onChange={(value) => {
                  setFieldValue("amount_owing", value);
                }}
              />
            </Grid>
          </Grid>
        </StandadCard>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleKey={MODULE_IDS.AUTOMOBILE as string}
            disabled={saveLoading}
            variant="contained"
            onClick={() => changeStep(1)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.AUTOMOBILE as string}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.AUTOMOBILE as string}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
  );
};
