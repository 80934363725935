import { Typography } from "@mui/material";
import React from "react";
import { AutoSaveDraftDialog } from "./DraftProviderDialog";
import { IDraftSave, useDraft } from "./DraftProvider";

interface IDraftStatusProps {
  referenceId: any;
    onSelect: (data: IDraftSave) => void;
}

export const DraftStatus: React.FC<IDraftStatusProps> = ({referenceId, onSelect}) => {
  const { autoSaveDataList,fetchAutoSavedDrafts,clearAutoSaveState } = useDraft();
  const [open, setOpen] = React.useState(false);
  const totalCount = autoSaveDataList.length;

  const handleSelect = (data: IDraftSave) => {
    setOpen(false);
    onSelect(data);
  }


  React.useEffect(()=>{
   if(referenceId){
    fetchAutoSavedDrafts(referenceId);
   }
  },[referenceId])

  React.useEffect(()=>{
   return(()=>{
    clearAutoSaveState();
   })
  },[])

  if(totalCount === 0){
      return <></>
  }
  
  return (
    <>
      <Typography
        variant="body1"
        color={"error"}
        fontWeight={600}
        sx={{ cursor: "pointer" }}
        onClick={()=> setOpen(true)}
      >
        {`You have ${totalCount} saved draft${
          totalCount > 1 ? "s" : ""
        } available.`}
      </Typography>

      {open &&
      <AutoSaveDraftDialog
         open={open}
         onClose={()=> setOpen(false)}
         onSelect={handleSelect}
      />

      }
    </>
  );
};
