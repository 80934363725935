import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_OLD_INSURANCE_REPORTS,
  FETCH_OLD_INSURANCE_REPORTS_LIST_FAILED,
  FETCH_OLD_INSURANCE_REPORTS_LIST_PROGRESS,
  FETCH_OLD_INSURANCE_REPORTS_LIST_SUCCESS,
} from "./oldInsuranceReportActions";
import { initialOldInsuranceReportsState } from "./oldInsuranceReport.types";
import { OldInsuranceReportsActions } from ".";

export const oldInsuranceReportsReducer = (
  state: IStoreState["tasks"]["oldInsurancReports"] = initialOldInsuranceReportsState,
  action: OldInsuranceReportsActions
) => {
  switch (action.type) {
    case FETCH_OLD_INSURANCE_REPORTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_OLD_INSURANCE_REPORTS_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
      });
      return newState;
    }
    case FETCH_OLD_INSURANCE_REPORTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case CLEAR_OLD_INSURANCE_REPORTS: {
      return initialOldInsuranceReportsState;
    }

    default: {
      return state;
    }
  }
};
