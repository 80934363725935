/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  StickyNote2,
  StickyNote2Outlined,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { AutomobileDriverInformationList } from "./AutomobileDriver/AutomobileDriverInformation/AutomobileDriverInformationList";
import { AutomobileDriverClaimsList } from "./AutomobileDriver/AutomobileDriverClaims/AutomobileDriverClaimsList";
import { AutomobileDriverConvictionList } from "./AutomobileDriver/AutomobileDriverConviction/AutomobileDriverConvictionList";
import { AutomobileDriverActivityList } from "./AutomobileDriver/AutomobileDriverActivity/AutomobileDriverActivityList";
import { fetchAutomobileCustomerInfoAsync } from "../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { AutomobileInsuranceLapseList } from "./AutomobileDriver/AutomobileInsuranceLapse/AutomobileInsuranceLapseList";
import { AutomobileFraudMisRepresentList } from "./AutomobileDriver/AutomobileFraudMisRepresent/AutomobileFraudMisRepresentList";

export const AutoDriverRoot: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/automobile-policy/automobile/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "driver",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            { label: "Driver Information", icon: <AccountCircle /> },
            {
              label: "Claims",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Convictions",
              icon: <StickyNote2 />,
            },
            {
              label: "Insurance Reports",
              icon: <StickyNote2Outlined />,
            },
            // {
            //   label: "Insurance Lapse",
            //   icon: <StickyNote2Outlined />,
            // },
            // {
            //   label: "Fraud/ Misrepresentation ",
            //   icon: <StickyNote2Outlined />,
            // },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <AutomobileDriverInformationList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AutomobileDriverClaimsList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <AutomobileDriverConvictionList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <AutomobileDriverActivityList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={4}>
          <AutomobileInsuranceLapseList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={5}>
          <AutomobileFraudMisRepresentList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel> */}
      </>
    </PageLoader>
  );
};
