import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialGeneralLedgerState } from "./generalLedger.types";
import { GeneralLedgerActions } from ".";
import { CLEAR_GENERAL_LEDGER_STATE, FETCH_GENERAL_LEDGER_LIST_FAILED, FETCH_GENERAL_LEDGER_LIST_PROGRESS, FETCH_GENERAL_LEDGER_LIST_SUCCESS } from "./generalLedgerActions";

export const GeneralLedgerReducer = (
  state: IStoreState["accounting"]["generalLedger"] = initialGeneralLedgerState,
  action: GeneralLedgerActions
) => {
  switch (action.type) {
   
    case FETCH_GENERAL_LEDGER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list = [];
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_GENERAL_LEDGER_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_GENERAL_LEDGER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list = [];
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_GENERAL_LEDGER_STATE: {
     return initialGeneralLedgerState;
    }

    default:
      return state;
  }
};
