import React from "react";
import { Grid } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { IAutomobilePolicyChangeProps } from "./AutomobilePolicyChange.types";

export const NameChangeInformation: React.FC<
  IAutomobilePolicyChangeProps
> = ({ values, setValues, errors, handleChange, setFieldValue }) => {
  return (
    <>
      <LabelCard heading=" Name Change" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>New Name</CustomFormLabel>
          <CustomTextField
            name="name_change.new_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.name_change.new_name}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
