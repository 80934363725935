import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getUserAuth, uploadFile } from "../../helpers";
import { action } from "typesafe-actions";
import {
  ADD_INSURER_CODE,
  ADD_INSURER_CODE_PROGESS,
  CLEAR_INSURER_STATE,
  FETCH_INSURER_CODE_LIST_FAILED,
  FETCH_INSURER_CODE_LIST_PROGRESS,
  FETCH_INSURER_CODE_LIST_SUCCESS,
  FETCH_INSURER_CODE_PROGRESS,
  FETCH_INSURER_CODE_SUCCESS,
} from "../constants";
import { api } from "../../api/api";
import { IInsurerState, IInsurerCode } from "./insurer.types";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const INSURER_CODE_ERROR = "INSURER_CODE_ERROR";
export const ADD_INSURER_CODE_ERROR = "ADD_INSURER_CODE_ERROR";

export const addInsurerCodeProgress = () => action(ADD_INSURER_CODE_PROGESS);

export const addInsurerCode = (data: IInsurerCode) =>
  action(ADD_INSURER_CODE, { data: data });

export const addInsurerCodeAsync =
  (
    data: IInsurerCode,
    initialData: IInsurerCode,
    isUpdate: boolean,
    file: any | null,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      dispatch(addInsurerCodeProgress());
      const asPayload = {
        insurer_code_id: data.insurer_code_id,
      };
      const path = await uploadFile(
        file,
        "INSURER",
        data.logo_file || "",
        asPayload
      );
      await makeApiCall(
        {
          url: endPoints.insurer.createInsurer,
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "dim_insurer_code_id",
          },
        },
        initialData,
        {
          ...data,
          logo_file: path,
        }
      );

      dispatch(addInsurerCode(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Insurer code saved successfully!",
          displayAs: "snackbar",
        })
      );
      onSuccess();
    } catch (err: any) {
      dispatch(insurerCodeError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchInsurerCodeProgress = () =>
  action(FETCH_INSURER_CODE_PROGRESS);

export const fetchInsurerCodeSuccess = (data: IInsurerState) =>
  action(FETCH_INSURER_CODE_SUCCESS, { data: data });

export const fetchInsurerCodeAsync =
  (codeId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchInsurerCodeProgress());
        const res = await api.get(
          `insurance/get-insurer-code?dim_insurer_code_id=${codeId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IInsurerCode[] = res.data.data;
        if (data.length > 0) {
          dispatch(addInsurerCode(data[0]));
        } else {
          dispatch(
            insurerCodeError(
              "Oops! We couldn't find any records associated with your insurer code at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchInsurerCodeWithCallbackAsync =
  (
    codeId: string,
    onCallBack: (data: IInsurerCode) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      const res = await api.get(
        `insurance/get-insurer-code?column=insurer_code&column=name&value=${codeId}`
      );
      const data: IInsurerCode[] = res.data.data;
      if (data.length > 0) {
        onCallBack(data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const fetchInsurerCodeListProgress = () => action(FETCH_INSURER_CODE_LIST_PROGRESS);
export const fetchInsurerCodeListSuccess = (list: IInsurerCode[]) =>
  action(FETCH_INSURER_CODE_LIST_SUCCESS, { list });
export const fetchInsurerCodeListFailed = () => action(FETCH_INSURER_CODE_LIST_FAILED);

export const fetchInsurerCodeListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInsurerCodeListProgress());
      let finalUrl = `/insurance/get-insurer-code?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/insurance/get-insurer-code?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IInsurerCode[] = res.data.data;

      dispatch(fetchInsurerCodeListSuccess(data ));
    } catch (err: any) {
      dispatch(fetchInsurerCodeListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const insurerCodeError = (message: string | null) =>
  action(INSURER_CODE_ERROR, {
    message: message,
  });

export const clearInsurerState = () => action(CLEAR_INSURER_STATE);
