import exp from "constants";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalPropertyScheduledState {
  schedule_detail: {
    list: IHabitationalScheduleDetail[];
    data: IHabitationalScheduleDetail;
    loading: LoadState;
    error: string | null;
  };
  schedule_summary: {
    list: IHabitationalScheduleSummary[];
    data: IHabitationalScheduleSummary;
    loading: LoadState;
    error: string | null;
  };
}

export interface IHabitationalPropertyScheduleDetial {
  key: string;
  property_class_code: string;
  description: string;
  exhibited: string;
  professional_use: string;
  photo_attached: string;
  purchase_date: string;
  appraisal_date: string;
  all_risks_named_perils: string;
  deductible: string;
  deductible_type: string;
  premium: string;
  amount_of_insurance: string;
}

export interface IHabitationalScheduleDetail {
  customer_policy_id: number | null;
  incremental_number: string | null;
  property_number: number | null;
  property_schedule_detail: IHabitationalPropertyScheduleDetial[];
  status: string;
  created_from: string | null;
}

export interface IHabitationalPropertyScheduleSummary {
  key: string;
  coverage_code: string;
  class_code: string;
  item_description: string;
  type_of: string;
  total_no_of_items: string;
  total_amount_of_insurance: string;
  deductible: string;
  deductible_type: string;
  premium: string;
  csio_fixed_id: string;
}

export interface IHabitationalScheduleSummary {
  customer_policy_id: number;
  incremental_number: string | null;
  property_number: number | null;
  property_schedule_summary: IHabitationalPropertyScheduleSummary[];
  status: string;
}

export const initialPropertyScheduleState: IHabitationalPropertyScheduledState =
  {
    schedule_detail: {
      list: [],
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        property_number: null,
        property_schedule_detail: [
          {
            key: getUniqueId(),
            property_class_code: "",
            description: "",
            exhibited: "",
            professional_use: "",
            photo_attached: "",
            purchase_date: "",
            appraisal_date: "",
            all_risks_named_perils: "",
            deductible: "",
            deductible_type: "",
            premium: "",
            amount_of_insurance: "",
          },
        ],
        created_from: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    schedule_summary: {
      list: [],
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        property_number: null,
        property_schedule_summary: [
          {
            key: getUniqueId(),
            coverage_code: "",
            class_code: "",
            item_description: "",
            type_of: "",
            total_no_of_items: "",
            total_amount_of_insurance: "",
            deductible: "",
            deductible_type: "",
            premium: "",
            csio_fixed_id: "",
          },
        ],
        status: "",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
  };
