import React from "react";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  IBusinessPolicyCoverage,
  IBusinessPolicyCoverageState,
  ICoverageRow,
} from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import { CustomTextField } from "../../../../../../components/formsComponents";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../constants/enums";

interface ICoverageCrimeTableProps {
  crime: IBusinessPolicyCoverageState["coverage"]["crime"];
  onRowChange: (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => void;
  onRemoveRow: (index: number) => void;
  onAddNewRow: (currentIndex: number) => void;
  changeTab: (value: number) => void;
}

export const CoverageCrimeTable: React.FC<ICoverageCrimeTableProps> = (
  props
) => {
  const { crime, onRowChange, onRemoveRow, onAddNewRow, changeTab } = props;

  const handleRowChange =
    (key: keyof ICoverageRow, rowIndex: number) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onRowChange(key, rowIndex, event.target.value);
      };


  const handleCurrencyRowChange =
    (key: keyof ICoverageRow, rowIndex: number) =>
      (value: string) => {
        onRowChange(key, rowIndex, value);
      };

  const handleRemoveRow = (index: number) => () => {
    onRemoveRow(index);
  };

  const handleNewRow = (currentRowIndex: number) => () => {
    onAddNewRow(currentRowIndex);
  };

  const crimeTableProps: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 10000,
    uniqueRowKeyName: "coverage",
    columns: [
      {
        key: "coverage",
        headerName: "Coverage",
        fieldName: "coverage",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CustomTextField
              fullWidth
              sx={{ minWidth: "350px" }}
              value={row.type}
              onChange={handleRowChange("type", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "deductible",
        headerName: "Deductible",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CustomTextField
              type="text"
              fullWidth
              value={row.deductible}
              onChange={handleRowChange("deductible", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "co_insurance",
        headerName: "Co-Insurance",
        fieldName: "co_insurance",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CustomTextField
              type="text"
              fullWidth
              value={row.co_insurance}
              onChange={handleRowChange("co_insurance", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "limit_amount",
        headerName: "Limit ($)",
        fieldName: "limit_amount",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limit}
              onChange={handleCurrencyRowChange("limit", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "rate",
        headerName: "Rate",
        fieldName: "rate",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.rate}
              onChange={handleCurrencyRowChange("rate", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "Premium",
        headerName: "Premium",
        fieldName: "premimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleCurrencyRowChange("premium", value.column.rowIndex)}
            />
          );
        },
      },

      {
        key: "delete",
        headerName: "",
        fieldName: "add",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <Box display={"flex"}>
              <IconButton
                color="error"
                onClick={handleRemoveRow(value.column.rowIndex)}
              >
                <Remove fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleNewRow(value.column.rowIndex)}
              >
                <Add fontSize="small" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    items: crime,
  };

  return (
    <>
      <DataTable {...crimeTableProps} />
      <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
        <RoleBasedCustomButton
          sx={{ width: "15%" }}
          moduleKey={MODULE_IDS.BUSINESS_POLICY}
          variant="contained"
          onClick={() => changeTab(2)}
          type="button"
        >
          Back
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          sx={{ width: "15%" }}
          moduleKey={MODULE_IDS.BUSINESS_POLICY}
          variant="contained"
          onClick={() => changeTab(4)}
          type="button"
        >
          Next
        </RoleBasedCustomButton>
      </Stack>
    </>
  );
};
