import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import "./PageLoader.css";
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CircularProgress,
  keyframes,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomTypography } from "../formsComponents";
import { ErrorOutline } from "@mui/icons-material";

const barUpDown = keyframes`
  0% { transform: scaleY(1); }
  50% { transform: scaleY(0.5); } /* Shrink the bar */
  100% { transform: scaleY(1); } /* Back to full height */
`;

export const AnimatedBar = ({ delay, bgColor }: any) => {
  return (
    <Box
      sx={{
        width: "6px",
        height: "30px",
        backgroundColor: bgColor,
        margin: "0 2px",
        display: "inline-block",
        transformOrigin: "bottom", // Animate from the bottom of the bar
        animation: `${barUpDown} 1s ease-in-out infinite`,
        animationDelay: `${delay}s`, // Stagger the animation delay for each bar
      }}
    />
  );
};

export const AnimatedBarList = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      {/* Render multiple bars with different delays for a staggered animation effect */}
      <AnimatedBar delay={0} bgColor={theme.palette.primary.main} />
      <AnimatedBar delay={0.2} bgColor={theme.palette.warning.main} />
      <AnimatedBar delay={0.4} bgColor={theme.palette.error.main} />
      <AnimatedBar delay={0.6} bgColor={theme.palette.success.main} />
    </Box>
  );
};

export const PageLoader: React.FC<{
  loading: boolean;
  error?: {
    icon?: React.ReactNode;
    message: string;
    button?: {
      label: string;
      variant: ButtonProps["variant"];
      sx?: ButtonProps["sx"];
      onClick?: () => void;
    };
  } | null;
  children: React.ReactNode;
}> = (props) => {
  if (props.loading && !props.error) {
    return (
      <div className="page-loader">
        <Card sx={{ p: 0 }}>
          {/* <LinearProgress color="primary" /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minHeight: "50vh",
            }}
          >
            <AnimatedBarList />

            <Box mt={2}>
              <Typography
                variant="h3"
                textAlign={"center"}
                color={"unset"}
                fontWeight={600}
              >
                Please wait a moment...
              </Typography>
              <Typography variant="body1" textAlign={"center"}>
                We are processing your request.
              </Typography>
            </Box>
          </Box>
        </Card>
      </div>
    );
  } else if (props.error) {
    return (
      <div className="page-loader">
        <Card sx={{ p: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minHeight: "50vh",
            }}
          >
            {props.error.icon ? (
              props.error.icon
            ) : (
              <ErrorOutline fontSize={"large"} color="error" />
            )}

            <Box mt={2}>
              <Typography
                variant="h3"
                textAlign={"center"}
                color={"error.main"}
                fontWeight={600}
              >
                {props.error.message || "Something went to be wrong."}
              </Typography>
              <Typography variant="body1" textAlign={"center"}>
                {props.error.button && (
                  <Button
                    sx={props.error.button.sx}
                    variant={props.error.button.variant}
                    onClick={props.error.button.onClick}
                  >
                    {props.error.button.label}
                  </Button>
                )}
              </Typography>
            </Box>
          </Box>
        </Card>
      </div>
    );
  }
  return <>{props.children}</>;
};

export const AnimatedBarLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "50vh",
      }}
    >
      <AnimatedBarList />

      <Box mt={2}>
        <Typography
          variant="h3"
          textAlign={"center"}
          color={"unset"}
          fontWeight={600}
        >
          Please wait a moment...
        </Typography>
        <Typography variant="body1" textAlign={"center"}>
          We are processing your request.
        </Typography>
      </Box>
    </Box>
  );
};
