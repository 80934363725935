import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";
import { IHabitatioanalUpgrades } from "../habitationalRiskLocation/habitationalRiskLocation.types";

export interface IHabitationalApplicationState {
  list: IHabitationalApplication[];
  data: IHabitationalApplication;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}


export interface IHabitationalApplicantData {
  key: string;
  applicant_name: string | null;
  occupation: string | null;
  years_continuously_employed: string | null;
  date_of_birth: string;
}

export interface IHabitationalLossHistory {
  key: string;
  date_of_loss: string;
  loc_no: string | null;
  cause_of_loss: string | null;
  status: string | null;
  amount_paid: string | null;
  insurance_company_1: string | null;
  policy_number: string | null;
}
export interface IHabitationalPolicyHistory {
  key: string;
  insurance_company_2: string | null;
  policy_number: string | null;
  effective_date: string;
  end_date: string;
  reason_for_ending: string | null;
  if_terminated_by_insurer_reason: string | null;
}

export interface IHabitationalCrossReferrence {
  key: string;
  line_of_business: string | null;
  policy_number: string | null;
}
export interface IHabitationalInteriorWallConstruction {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitationalInteriorWallHeight {
  key: string;
  height: string | null;
  percentage: string | null;
}
export interface IHabitationalInteriorFloorFinish {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitationalCeilingConstruction {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitationalUpgrades {
  key: string;
  upgrade_name: string;
  full_yy: string | null;
  partial_yy: string | null;
}
export interface IHabitationalPrimaryHeatingType {
  key: string;
  loc_no: number | null;
  apparatus: string | null;
  fuel: string | null;
  location: string | null;
  professionally_installed: string | null;
  approved_by_ulc_csa_or_wh: string | null;
}
export interface IHabitationalAuxiliaryHeat1 {
  key: string;
  loc_no: number | null;
  apparatus: string | null;
  fuel: string | null;
  location: string | null;
  professionally_installed: string | null;
  approved_by_ulc_csa_or_wh: string | null;
  no_of_face_cords_per_year: string | null;
  solid_fuel_heating_questionaire_attached: string | null;
}
export interface IHabitationalAuxiliaryHeat2 {
  key: string;
  loc_no: number | null;
  radiant_heating_area: string | null;
  make: string | null;
  year: string | null;
  oil_tank_year: string | null;
  oil_tank_year_type: string | null;
  fuel_oil_tank_questionaire_attached: string | null;
}
export interface IHabitationalPlumbingType {
  key: string;
  loc_no: number | null;
  copper_percentage: string | null;
  gaivanized_percentage: string | null;
  abs_percentage: string | null;
  pvc_percentage: string | null;
  pex_percentage: string | null;
  poly_b_percentage: string | null;
  lead_percentage: string | null;
  other: string | null;
}
export interface IHabitationalWaterHeaterType {
  key: string;
  loc_no: number | null;
  apparatus: string | null;
  water_heater_type: string | null;
  fuel: string | null;
  professionally_installed: string | null;
  approved_by_ulc_csa_or_wh: string | null;
}
export interface IHabitationalPrimaryWaterMitigationType {
  key: string;
  loc_no: number | null;
  sump_pump_type: string | null;
  auxiliary_power: string | null;
  backup_value: string | null;
}
export interface IHabitationalAuxiliaryWaterMitigationType {
  key: string;
  loc_no: number | null;
  sump_pump_type: string | null;
  auxiliary_power: string | null;
  backup_value: string | null;
  main_water_value_shut_off_type: string | null;
  no_of_main_water_value_shut_off_sensor: string | null;
  sewer_backeup_questionaire_attached: string | null;
}
export interface IHabitationalFireProtection {
  key: string;
  loc_no: number | null;
  distance_to_hydrant: string | null;
  hydrant_type: string | null;
  distance_to_responding_fire_hall: string | null;
  fire_hall_name: string | null;
}
export interface IHabitationalSecuritySystem {
  key: string;
  loc_no: number | null;
  fire: string | null;
  burglary: string | null;
  smoke_detectors: string | null;
  smoke_detector_type: string | null;
  no_of_detectors: string | null;
  if_any_of_the_above_are_monitored_monitored_by: string | null;
  home_sprinklered: string | null;
  alarm_certificate: boolean;
  premises_type_security_system: string | null;
}
export interface IHabitationalBathroom {
  key: string;
  loc_no: number | null;
  no_of_full: string | null;
  no_of_half: string | null;
}
export interface IHabitationalKitchen {
  key: string;
  loc_no: number | null;
  no_of_kitchens: string | null;
  kitchen_1_quality: string | null;
  kitchen_2_quality: string | null;
}
export interface IHabitationalGarageCarport {
  key: string;
  loc_no: number | null;
  no_of_cars: string | null;
  garage_type: string | null;
}
export interface IHabitationalSwimmingPool {
  key: string;
  loc_no: number | null;
  year: string | null;
  pool_type: string | null;
  pool_fenced: string | null;
}
export interface IHabitationalDetachedOutbuildingStructure {
  key: string;
  number: number | null;
  year: string | null;
  structure_type: string | null;
  exterior_wall_framing_type: string | null;
  heating_apparatus_type: string | null;
  fuel_type: string | null;
  total_area: string | null;
  value: string | null;
}

export interface IHabitationalCoverage {
  key: string;
  code: string;
  coverage_discription: string | null;
  requested_or_declined: string | null;
  amount_of_insurance: string | null;
  deductible: string | null;
  deductible_type: string | null;
  type1: string | null;
  type2: string | null;
  type3: string | null;
  type4: string | null;
  type5: string | null;
  premium: string | null;
}
export interface IHabitationalLiabilityCoverage {
  key: string;
  code: string;
  liability_coverage_discription: string | null;
  requested_or_declined: string | null;
  amount_of_insurance: string | null;
  deductible: string | null;
  deductible_type: string | null;
  type1: string | null;
  type2: string | null;
  type3: string | null;
  type4: string | null;
  type5: string | null;
  estimated_premium: string | null;
}
export interface IHabitationalDiscountSurcharge {
  key: string;
  discount_or_surcharge_discription: string | null;
  percentage: string | null;
  applied_to_premium: string | null;
  est_discount_or_surchage: string | null;
}
export interface IHabitationalMortgageLossPayee {
  key: string;
  loc_no: number | null;
  name: string | null;
  nature_of_interest: string | null;
  address: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_or_zip_code: string | null;
}

export interface IHabitationalAddressForm {
  key: string;
  serial_number: number;
  address_type: string | null;
  address: string | null;
  unit_of_suit: string | null;
  state: string | null;
  city: string | null;
  postal_code: string | null;
  country: string | null;
  date_moved_in: string;
  date_moved_out: string;
}
export interface IHabitationalRiskLocationData {
  key: string;
  loc_no: number | null;
  risk_location_address: {
    address: string | null;
    unit_suite: string | null;
    city: string | null;
    province_or_state: string | null;
    postal_code: string | null;
    country: string | null;
  };
  risk_location_construction: {
    year_built: string | null;
    no_of_storeys: string | null;
    no_of_families: string | null;
    no_of_units: string | null;
    total_living_area: string | null;
    total_living_area_unit: string | null;
    access_type: string | null;
    smokers: string | null;
    replacement_cost_emulator_product: string | null;
    data_evaluation_completed: string;
    date_of_birth_of_eldest_occupant: string;
    relationship_to_applicant: string | null;
    occupancy_type: string | null;
    structure_type: string | null;
    foundation_type: string | null;
    finished_basement_percentage: string | null;
    exterior_wall_framing_type: string | null;
    exterior_wall_finish_type: string | null;
  };
  interior_wall_construction_type: IHabitationalInteriorWallConstruction[];
  interior_wall_height: IHabitationalInteriorWallHeight[];
  interior_floor_finish_type: IHabitationalInteriorFloorFinish[];
  ceiling_construction_type: IHabitationalCeilingConstruction[];
  risk_location_upgrades: IHabitatioanalUpgrades[];
  risk_location_services: {
    risk_location_roof_covering_type: string | null;
    risk_location_electrical_wiring_type: string | null;
    risk_location_electrical_panel_type: string | null;
    risk_location_service: string | null;
  };

  primary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  auxiliary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
    no_of_face_cords_per_year: string | null;
    solid_fuel_heating_questionaire_attached: string | null;
    radiant_heating_area: string | null;
    make: string | null;
    year: string | null;
    oil_tank_year: string | null;
    oil_tank_year_type: string | null;
    fuel_oil_tank_questionaire_attached: string | null;
  };
  plumbing_type: {
    copper_percentage: string | null;
    galvanized_percentage: string | null;
    abs_percentage: string | null;
    pvc_percentage: string | null;
    pex_percentage: string | null;
    poly_b_percentage: string | null;
    lead_percentage: string | null;
    other: string | null;
  };
  water_heater_type: {
    apparatus: string | null;
    water_heater_type: string | null;
    fuel: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  primary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
  };
  auxiliary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
    main_water_value_shut_off_type: string | null;
    no_of_main_water_value_shut_off_sensor: string | null;
    sewer_backeup_questionaire_attached: string | null;
  };
  fire_protection: {
    distance_to_hydrant: string | null;
    hydrant_type: string | null;
    distance_to_responding_fire_hall: string | null;
    fire_hall_name: string | null;
  };
  security_system: {
    fire: string | null;
    burglary: string | null;
    smoke_detectors: string | null;
    smoke_detector_type: string | null;
    no_of_detectors: string | null;
    if_any_of_the_above_are_monitored_monitored_by: string | null;
    home_sprinklered: string | null;
    alarm_certificate: string | null;
    premises_type_security_system: string | null;
  };
  bathrooms: {
    no_of_full: string | null;
    no_of_half: string | null;
  };
  kitchens: {
    no_of_kitchens: string | null;
    kitchen_1_quality: string | null;
    kitchen_2_quality: string | null;
  };
  garage_or_carport: {
    no_of_cars: string | null;
    garage_type: string | null;
  };
  swimming_pool: {
    year: string | null;
    pool_type: string | null;
    pool_fenced: string | null;
  };
  detached_outbuildings_structures: IHabitationalDetachedOutbuildingStructure[];
  risk_location_questions: {
    do_you_own_rent_more_than_one_location: string | null;
    number_of_weeks_location_rented_to_others: string | null;
    number_of_rooms_rented_to_others: string | null;
    renewable_energy_installation_on_premises: string | null;
    number_of_full_residence_employees: string | null;
    number_of_dogs_in_the_household: string | null;
    breed_of_dogs: string | null;
    total_property_area: string | null;
  };
  coverages_form: IHabitationalCoverage[];
  liability_extension_and_exclusion_form: IHabitationalLiabilityCoverage[];
  discount_and_surcharges_form: IHabitationalDiscountSurcharge[];
}

export interface IHabitationalPremiumPaymentSchedule {
  id: string;
  installment_number: string;
  installment_amount: string;
  installment_date: string;
}

export interface IHabitationalApplication {
  customer_policy_id: number;
  incremental_number: number | null;
  insured_company: string | null;
  billing_method: string | null;
  billing_account_number: string | null;
  company_type: string | null;
  binder_number: string | null;
  policy_number: number;
  // applicant_details: IHabitationalApplicantDetails[];
  applicant_details: {
    applicant_name: string | null;
    applicant_address: string | null;
    applicant_address_two: string | null;
    applicant_country: string | null;
    applicant_city: string | null;
    applicant_province_or_state: string | null;
    applicant_postalcode: string | null;
    applicant_contact_name: string | null;
    applicant_contact_type: string | null;
    applicant_home: string | null;
    applicant_cell: string | null;
    applicant_business: string | null;
    applicant_fax: string | null;
    applicant_email: string | null;
    applicant_website: string | null;
  };
  applicant_preferred_language: string | null;
  broker_details: {
    broker_name: string | null;
    broker_address: string | null;
    broker_unit_or_suite: string | null;
    broker_city: string | null;
    broker_province_or_state: string | null;
    broker_country: string | null;
    broker_postal_or_zip_code: string | null;
    broker_contact_name: string | null;
    broker_home: string | null;
    broker_cell: string | null;
    broker_business: string | null;
    broker_fax: string | null;
    broker_email: string | null;
    broker_code: string | null;
    broker_sub_code: string | null;
    broker_client_id: string | null;
    broker_company_client_id: string | null;
    broker_group_name: string | null;
    broker_group_id: string | null;
  };
  policy_period_effective_date: string;
  policy_period_effective_time: string;
  policy_period_expiry_date: string;
  policy_period_expiry_time: string;
  applicant_data: IHabitationalApplicantData[];

  loss_history: IHabitationalLossHistory[];
  policy_history: IHabitationalPolicyHistory[];
  cross_reference_information: IHabitationalCrossReferrence[];
  risk_location_data: IHabitationalRiskLocationData[];
  mortgage_or_loss_payee: IHabitationalMortgageLossPayee[];

  liability_exposures: {
    daycare_operation_number_of_children: null;
    do_you_own_a_trampoline: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_have_a_garden_tractor: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_have_a_golf_cart: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    no_of_saddle_draft_animals: null;
    do_you_own_any_unlicensed_recreational_vehicles: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_own_any_watercrafts: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    is_there_a_co_occupant_that_requires_coverage: null;
    co_occupant_name: null;
    is_there_any_kind_of_business_operation: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    if_yes_describe_business: null;
    number_of_cannabis_plants_grown_on_premises: null;
    other_exposures: null;
  };
  address_form: IHabitationalAddressForm[];

  attachments: {
    key: string;
    attachments_serial_number: number;
    attachments_date_completed: string;
    attachments_description: string | null;
    attachments_documents_link: string | null;
  }[];

  premium_info_type_of_payment_plan: string | null;
  premium_info_estimated_policy_premium: string | null;
  premium_info_provincial_sales_tax: string | null;
  premium_info_additional_charges: string | null;
  premium_info_total_estimated_cost: string | null;
  premium_info_amount_paid_with_application: string | null;
  premium_info_amount_still_due: string | null;
  premium_info_no_of_remaining_installments: string | null;
  premium_info_amount_of_each_installment: string | null;
  premium_info_installment_due_date: string;
  payment_schedule: IHabitationalPremiumPaymentSchedule[];
  premium_info_Remarks: string | null;
  status: string;
}

export const initialHabitationalApplicationState: IHabitationalApplicationState =
  {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      insured_company: null,
      billing_method: null,
      billing_account_number: null,
      company_type: null,
      binder_number: null,
      policy_number: 0,
      // applicant_details: [
      //   {
      //     key: getUniqueId(),
      //     applicant_name: null,
      //     applicant_address: null,
      //     applicant_city: null,
      //     applicant_province_or_state: null,
      //     applicant_country: null,
      //     applicant_postal_or_zip_code: null,
      //     applicant_home: null,
      //     applicant_contact_name: null,
      //     applicant_contact_type: null,
      //     applicant_cell: null,
      //     applicant_business: null,
      //     applicant_fax: null,
      //     applicant_email: null,
      //     applicant_website: null,
      //     applicant_preferred_language: null,
      //   },
      // ],
      applicant_details: {
        applicant_name: null,
        applicant_address: null,
        applicant_address_two: null,
        applicant_country: null,
        applicant_city: null,
        applicant_province_or_state: null,
        applicant_postalcode: null,
        applicant_contact_name: null,
        applicant_contact_type: null,
        applicant_home: null,
        applicant_cell: null,
        applicant_business: null,
        applicant_fax: null,
        applicant_email: null,
        applicant_website: null,
      },
      applicant_preferred_language: null,
      broker_details: {
        broker_name: null,
        broker_address: null,
        broker_unit_or_suite: null,
        broker_city: null,
        broker_province_or_state: null,
        broker_country: null,
        broker_postal_or_zip_code: null,
        broker_contact_name: null,
        broker_home: null,
        broker_cell: null,
        broker_business: null,
        broker_fax: null,
        broker_email: null,
        broker_code: null,
        broker_sub_code: null,
        broker_client_id: null,
        broker_company_client_id: null,
        broker_group_name: null,
        broker_group_id: null,
      },
      policy_period_effective_date: moment().format("YYYY-MM-DD"),
      policy_period_effective_time: moment().format(),
      policy_period_expiry_date: moment().format("YYYY-MM-DD"),
      policy_period_expiry_time: moment().hours(0).minutes(1).format(),
      applicant_data: [
        {
          key: getUniqueId(),
          applicant_name: null,
          occupation: null,
          years_continuously_employed: null,
          date_of_birth: moment().format("YYYY-MM-DD"),
        },
      ],
      loss_history: [
        {
          key: getUniqueId(),
          date_of_loss: "",
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
      ],
      policy_history: [
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: "",
          end_date: "",
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
      ],
      cross_reference_information: [
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
      ],
      risk_location_data: [
        {
          key: getUniqueId(),
          loc_no: null,
          risk_location_address: {
            address: null,
            unit_suite: null,
            city: null,
            province_or_state: null,
            postal_code: null,
            country: null,
          },
          risk_location_construction: {
            year_built: null,
            no_of_storeys: null,
            no_of_families: null,
            no_of_units: null,
            total_living_area: null,
            total_living_area_unit: null,
            access_type: null,
            smokers: null,
            replacement_cost_emulator_product: null,
            data_evaluation_completed: moment().format("YYYY-MM-DD"),
            date_of_birth_of_eldest_occupant: moment().format("YYYY-MM-DD"),
            relationship_to_applicant: null,
            occupancy_type: null,
            structure_type: null,
            foundation_type: null,
            finished_basement_percentage: null,
            exterior_wall_framing_type: null,
            exterior_wall_finish_type: null,
          },
          interior_wall_construction_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          interior_wall_height: [
            {
              key: getUniqueId(),
              height: null,
              percentage: null,
            },
          ],
          interior_floor_finish_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          ceiling_construction_type: [
            {
              key: getUniqueId(),
              type: null,
              percentage: null,
            },
          ],
          risk_location_upgrades: [
            {
              key: getUniqueId(),
              upgrade_name: "Roof",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Electrical",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Heating",
              full_yy: null,
              partial_yy: null,
            },
            {
              key: getUniqueId(),
              upgrade_name: "Plumbing",
              full_yy: null,
              partial_yy: null,
            },
          ],
          risk_location_services: {
            risk_location_roof_covering_type: null,
            risk_location_electrical_wiring_type: null,
            risk_location_electrical_panel_type: null,
            risk_location_service: null,
          },
          primary_heating_type: {
            apparatus: null,
            fuel: null,
            location: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
          },
          auxiliary_heating_type: {
            apparatus: null,
            fuel: null,
            location: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
            no_of_face_cords_per_year: null,
            solid_fuel_heating_questionaire_attached: null,
            radiant_heating_area: null,
            make: null,
            year: null,
            oil_tank_year: null,
            oil_tank_year_type: null,
            fuel_oil_tank_questionaire_attached: null,
          },
          plumbing_type: {
            copper_percentage: null,
            galvanized_percentage: null,
            abs_percentage: null,
            pvc_percentage: null,
            pex_percentage: null,
            poly_b_percentage: null,
            lead_percentage: null,
            other: null,
          },
          water_heater_type: {
            apparatus: null,
            water_heater_type: null,
            fuel: null,
            professionally_installed: null,
            approved_by_ulc_csa_or_wh: null,
          },
          primary_water_mitigation_type: {
            sump_pump_type: null,
            auxiliary_power: null,
            backup_value: null,
          },
          auxiliary_water_mitigation_type: {
            sump_pump_type: null,
            auxiliary_power: null,
            backup_value: null,
            main_water_value_shut_off_type: null,
            no_of_main_water_value_shut_off_sensor: null,
            sewer_backeup_questionaire_attached: null,
          },
          fire_protection: {
            distance_to_hydrant: null,
            hydrant_type: null,
            distance_to_responding_fire_hall: null,
            fire_hall_name: null,
          },
          security_system: {
            fire: null,
            burglary: null,
            smoke_detectors: null,
            smoke_detector_type: null,
            no_of_detectors: null,
            if_any_of_the_above_are_monitored_monitored_by: null,
            home_sprinklered: null,
            alarm_certificate: null,
            premises_type_security_system: null,
          },
          bathrooms: {
            no_of_full: null,
            no_of_half: null,
          },

          kitchens: {
            no_of_kitchens: null,
            kitchen_1_quality: null,
            kitchen_2_quality: null,
          },

          garage_or_carport: {
            no_of_cars: null,
            garage_type: null,
          },

          swimming_pool: {
            year: null,
            pool_type: null,
            pool_fenced: null,
          },
          detached_outbuildings_structures: [
            {
              key: getUniqueId(),
              number: null,
              year: null,
              structure_type: null,
              exterior_wall_framing_type: null,
              heating_apparatus_type: null,
              fuel_type: null,
              total_area: null,
              value: null,
            },
          ],
          risk_location_questions: {
            do_you_own_rent_more_than_one_location: null,
            number_of_weeks_location_rented_to_others: null,
            number_of_rooms_rented_to_others: null,
            renewable_energy_installation_on_premises: null,
            number_of_full_residence_employees: null,
            number_of_dogs_in_the_household: null,
            breed_of_dogs: null,
            total_property_area: null,
          },
          coverages_form: [
            {
              key: getUniqueId(),
              code: "",
              coverage_discription: null,
              deductible: null,
              requested_or_declined: null,
              amount_of_insurance: null,
              deductible_type: null,
              type1: null,
              type2: null,
              type3: null,
              type4: null,
              type5: null,
              premium: null,
            },
          ],
          liability_extension_and_exclusion_form: [
            {
              key: getUniqueId(),
              code: "",
              liability_coverage_discription: null,
              deductible: null,
              requested_or_declined: null,
              amount_of_insurance: null,
              deductible_type: null,
              type1: null,
              type2: null,
              type3: null,
              type4: null,
              type5: null,
              estimated_premium: null,
            },
          ],
          discount_and_surcharges_form: [
            {
              key: getUniqueId(),
              discount_or_surcharge_discription: null,
              percentage: null,
              applied_to_premium: null,
              est_discount_or_surchage: null,
            },
          ],
        },
      ],
      mortgage_or_loss_payee: [
        {
          key: getUniqueId(),
          loc_no: null,
          name: null,
          nature_of_interest: null,
          address: null,
          city: null,
          province_or_state: null,
          postal_or_zip_code: null,
        },
      ],

      liability_exposures: {
        daycare_operation_number_of_children: null,
        do_you_own_a_trampoline: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_garden_tractor: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_golf_cart: {
          questionCd: null,
          yesNoCd: null,
        },
        no_of_saddle_draft_animals: null,
        do_you_own_any_unlicensed_recreational_vehicles: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_own_any_watercrafts: {
          questionCd: null,
          yesNoCd: null,
        },
        is_there_a_co_occupant_that_requires_coverage: null,
        co_occupant_name: null,
        is_there_any_kind_of_business_operation: {
          questionCd: null,
          yesNoCd: null,
        },
        if_yes_describe_business: null,
        number_of_cannabis_plants_grown_on_premises: null,
        other_exposures: null,
      },

      address_form: [
        {
          key: getUniqueId(),
          serial_number: 1,
          address_type: null,
          address: null,
          unit_of_suit: null,
          state: null,
          city: null,
          postal_code: null,
          country: null,
          date_moved_in: "",
          date_moved_out: "",
        },
      ],
      attachments: [
        {
          key: getUniqueId(),
          attachments_serial_number: 1,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 2,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 3,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 4,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 5,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 6,
          attachments_date_completed: "",
          attachments_description: null,
          attachments_documents_link: null,
        },
      ],

      premium_info_type_of_payment_plan: null,
      premium_info_estimated_policy_premium: null,
      premium_info_provincial_sales_tax: null,
      premium_info_additional_charges: null,
      premium_info_total_estimated_cost: null,
      premium_info_amount_paid_with_application: null,
      premium_info_amount_still_due: null,
      premium_info_no_of_remaining_installments: null,
      premium_info_amount_of_each_installment: null,
      premium_info_installment_due_date: moment().format("YYYY-MM-DD"),
      payment_schedule: [
        {
          id: getUniqueId(),
          installment_number: "",
          installment_amount: "",
          installment_date: "",
        },
      ],
      premium_info_Remarks: null,
      status: "ACTIVE",
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.Loaded,
    error: null,
  };
