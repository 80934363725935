import React from "react";
import {
  DataTableV2DateTypes,
  IDataTableV2DateState,
} from "../preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { getDataTablev2InitialDate } from "../helpers/dataTableV2DatesFilter";
import { IDataTableV2Tab } from "../components/TableTabs/DataTableV2Tabs.types";
import {
  IDataTableV2SearchFilterSearchItem,
  IDatatableV2AdvancedSearchFilter,
} from "../preDefinedPlugins/SearchFilter/SearchFilter.types";
import produce from "immer";
import { IDataTableV2MasterColumn } from "../interfaces/IDataTableV2Props";
import { IDataTableV2ColumnsVisibilityState } from "../preDefinedPlugins/DataTableV2ColumnsVisibility/DataTableV2ColumnsVisibility.types";
import { useDataTableV2Context } from "../context/DataTableV2Provider";

export interface IDatatableV2State {
  filtersInitialState?: {
    defaultDateRange?: DataTableV2DateTypes;
    selectedTab?: any;
    search?: IDataTableV2SearchFilterSearchItem[];
  };
  columnsConfig?: {
    columnVisibility?: IDataTableV2ColumnsVisibilityState;
  };
  pagination?: {
    pageNumber: number;
    rowsInPerPage: number;
    apiFetchRowCount?: number;
    extraFetchFactor: number;
  };
  cachingFilters?: {
    uniqueKey: string;
  };
  masterColumnResize?: {
    [key: string]: {
      width: number;
    };
  }
}

const getDataFromContext = (
  initialState: IDatatableV2State,
  getCachedDataByKey: (uniqueKey: string) => any | null
) => {
  const {
    filtersInitialState = {
      defaultDateRange: "last28Days",
      selectedTab: "0",
      search: [],
    },
    pagination = {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 0,
      extraFetchFactor: 0,
    },
    columnsConfig = {
      columnVisibility: {},
    },
    cachingFilters,
    masterColumnResize= {}
  } = initialState;
  if (initialState.cachingFilters && initialState.cachingFilters.uniqueKey) {
    const data = getCachedDataByKey(initialState.cachingFilters.uniqueKey);
   
    if (data) {
      const dateState = data.dateState;
      const rangeType = dateState.rangeType;
      const {fromDate, toDate} = getDataTablev2InitialDate(rangeType);
      const finaDateState = {
        dates: {
          fromDate: fromDate,
          toDate: toDate,
        },
        rangeType: rangeType,
      };
      const newData = {
        ...data,
        dateState: finaDateState,
        pagination: {
          ...data.pagination,
          pageNumber: pagination.pageNumber,
          apiFetchRowCount: pagination.apiFetchRowCount,
          extraFetchFactor: pagination.extraFetchFactor,
        },
      
      }

      return newData;
    }
  }
  return {
    dateState: {
      rangeType: filtersInitialState.defaultDateRange || "last28Days",
      dates: {
        ...getDataTablev2InitialDate(
          filtersInitialState.defaultDateRange || "last28Days"
        ),
      },
    },
    tabs: {
      selectedTab: filtersInitialState.selectedTab,
      tabs: [] as IDataTableV2Tab[],
    },
    searchState:
      filtersInitialState.search || ([] as IDatatableV2AdvancedSearchFilter),
    columnsConfig: {
      columnVisibility: {},
    },
    pagination: pagination,
    masterColumnResize: masterColumnResize,
  };
};

export const useTableV2State = (initialData: IDatatableV2State) => {
  const { getCachedDataByKey,saveDataInCache } = useDataTableV2Context();

  const [state, setState] = React.useState(
    getDataFromContext(initialData, getCachedDataByKey)
  );

  const updateStateInCache = (newSate: any) => {
     if(initialData.cachingFilters && initialData.cachingFilters.uniqueKey){
       saveDataInCache(initialData.cachingFilters.uniqueKey, newSate);
     }
  }

  const setDateState = (newData: IDataTableV2DateState) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.dateState = newData;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const setSelectedTab = (newData: any) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.tabs.selectedTab = newData;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const setTableTabs = (newData: IDataTableV2Tab[]) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.tabs.tabs = newData;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const setSearchState = (newData: IDatatableV2AdvancedSearchFilter) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.searchState = newData;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const setColumnVisibility = (newData: IDataTableV2ColumnsVisibilityState) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.columnsConfig.columnVisibility = newData;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const onPageChange = (newPageNumber: number) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.pagination.pageNumber = newPageNumber;
      draftState.pagination.apiFetchRowCount =
        state.pagination.rowsInPerPage + state.pagination.extraFetchFactor;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const onRowsPerPageChange = (newPageNumber: number, rowsPerPage: number) => {
    //@ts-ignore
    const newState = produce(state, (draftState) => {
      draftState.pagination.pageNumber = newPageNumber;
      draftState.pagination.rowsInPerPage = rowsPerPage;
      draftState.pagination.apiFetchRowCount =
        rowsPerPage + state.pagination.extraFetchFactor;
    });
    updateStateInCache(newState);
    setState(newState);
  };

  const onResizeMasterColumns= (newResizeValues: {[key: string]: {width: number}}) => {
    const newState = {...state, masterColumnResize: newResizeValues};
    updateStateInCache(newState);
     setState(newState);
  }

  return {
    state,
    setState,
    setDateState,

    setTableTabs,

    setSelectedTab,

    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
    
  };
};
