import { action } from "typesafe-actions";
import { IPolicyChangeRequest } from "./policyChangeRequest.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";

export const FETCH_POLICY_CHANGE_REQ_LIST_PROGRESS =
  "FETCH_POLICY_CHANGE_REQ_LIST_PROGRESS";
export const FETCH_POLICY_CHANGE_REQ_LIST_SUCCESS =
  "FETCH_POLICY_CHANGE_REQ_LIST_SUCCESS";
export const FETCH_POLICY_CHANGE_REQ_LIST_FAILED =
  "FETCH_POLICY_CHANGE_REQ_LIST_FAILED";

export const fetchPolicyChangeRequestListProgress = () =>
  action(FETCH_POLICY_CHANGE_REQ_LIST_PROGRESS);
export const fetchPolicyChangeRequestListSuccess = (
  list: IPolicyChangeRequest[]
) => action(FETCH_POLICY_CHANGE_REQ_LIST_SUCCESS, { list });
export const fetchPolicyChangeRequestListFailed = () =>
  action(FETCH_POLICY_CHANGE_REQ_LIST_FAILED);

export const fetchPolicyChangeRequestListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPolicyChangeRequestListProgress());
      let finalUrl = `/policy/get-all-policy-change-request?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/policy/get-all-policy-change-request?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IPolicyChangeRequest[] = res.data.data;

      dispatch(fetchPolicyChangeRequestListSuccess(data));
    } catch (err: any) {
      dispatch(fetchPolicyChangeRequestListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_POLICY_CHANGE_REQ = "CLEAR_POLICY_CHANGE_REQ";

export const clearPolicyChangeRequestState = () => action(CLEAR_POLICY_CHANGE_REQ);
