import React from "react";
import { api } from "../../api/api";
import { Alert, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";

export interface IDraftSave {
  draft_code: string | null;
  form_url: string | null;
  reference_data_id: any | null;
  reference_data: any;
  status: string | null;
}

interface IDraftContext {
  autoSaveDataList: IDraftSave[];
  fetchAutoSavedDrafts: (referenceId: any) => void;
  saveAutoDraft: (payload: IDraftSave) => void;
  removeAutoSavedDraft: (referenceId: any) => void;
  clearAutoSaveState: () => void;
  getAutoSavedDraft: (referenceId: any) => IDraftSave | undefined;
}

const DraftContext = React.createContext<IDraftContext>({
  autoSaveDataList: [],
  fetchAutoSavedDrafts: () => {},
  saveAutoDraft: () => {},
  removeAutoSavedDraft: () => {},
  clearAutoSaveState: () => {},
  getAutoSavedDraft: () => {
    return undefined;
  },
});

export const useDraft = () => React.useContext(DraftContext);

export const useDraftUrl = () => {
  const  location = useLocation();
  return location.pathname;
} 

export const DraftProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [autoSaveDataList, setAutoSaveDataList] = React.useState<IDraftSave[]>(
    []
  );
  const [message, setMessage] = React.useState<{
    message: string;
    type: "general" | "loaded" | "error";
  } | null>(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const draftCodeRef = React.useRef<string | null>(null);

  const fetchSavedDrafts = async (referenceId: any) => {
    if (!referenceId) {
      return;
    }
    try {
      setIsFetching(true);
      const res = await api.get(
        "/draft/get-draft?form_url=" + referenceId+"&status=ACTIVE"
      );
      setAutoSaveDataList(res.data.data);
    } catch (err: any) {
      setMessage(err.response.data.message);
    } finally {
      setIsFetching(false);
    }
  };

  const saveAutoDraft = async (payload: IDraftSave) => {
    try {
      setMessage({ message: "Auto-saving in progress...", type: "general" });
      const finalPayload: IDraftSave = {
        ...payload,
        draft_code: draftCodeRef.current,
      };
      const res = await api.post("/draft/save-draft", finalPayload);
      const draftCode =  res.data.data.draft_code;
      draftCodeRef.current = draftCode;
    } catch (err: any) {
      setMessage({ message: err.response.data.message, type: "error" });
    } finally {
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  };

  const removeAutoSavedDraft = async (url: any) => {
    try {
      setAutoSaveDataList([]);
      draftCodeRef.current = null;
      await api.delete("/draft/delete-draft?form_url=" + url);
    } catch (err: any) {
      setAutoSaveDataList(err.response.data.message);
    }
  };

  const getAutoSavedDraft = (
    draftCode: string | null
  ): IDraftSave | undefined => {
    const item = autoSaveDataList.find(
      (draft) => draft.draft_code === draftCode
    );
    if (item) {
      setMessage({
        message: "Draft loaded successfully. Continue editing!",
        type: "loaded",
      });
      setTimeout(()=>{
        setMessage(null);
      },5000)
      draftCodeRef.current = item.draft_code;
      return item;
    }
  };

  const clearAutoSaveState = () => {
    draftCodeRef.current = null;
    setAutoSaveDataList([]);
  };

  return (
    <>
      <DraftContext.Provider
        value={{
          autoSaveDataList,
          fetchAutoSavedDrafts: fetchSavedDrafts,
          saveAutoDraft,
          removeAutoSavedDraft,
          clearAutoSaveState,
          getAutoSavedDraft,
        }}
      >
        {children}
      </DraftContext.Provider>
      {message && (
        <Snackbar
          anchorOrigin={
            message.type !== "loaded"
              ? { vertical: "bottom", horizontal: "right" }
              : {
                  vertical: "top",
                  horizontal: "center",
                }
          }
          autoHideDuration={5000}
          open={true}
          onClose={() => setMessage(null)}
          key="bottomCenter"
        >
          <Alert
          variant="filled"
            severity={
              message.type === "error"
                ? "error"
                : message.type === "general"
                ? "warning"
                : "success"
            }
          >
            {message.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
