/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";


import produce from "immer";
import {
  clearAutomobileScheduleState,
} from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";

import { AutoComplete } from "../../../../../../../components/AutoCompleteSearches/AutoComplete";
import {
  COMMON_STATUS,
  csioOtherCoverages,
} from "../../../../../../../constants/constants";
import { addCommAutomobileScheduleAAsync, fetchCommAutomobileScheduleAAsync } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { fetchCommAutomobileVehicleListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";

export const CommAutomobileScheduleA = () => {
  const { customerId, customerPolicyId, incrementalNumber, vehicleNumber } =
    useParams() as {
      customerId?: number;
      customerPolicyId?: number;
      incrementalNumber?: number;
      vehicleNumber?: number;
    };

  const { data } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleA
  );
  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.vehicle.VehicleInformation.vehiclesList
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: data,
    validate: (values) => {},
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addCommAutomobileScheduleAAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=0`
                );
              }
             
              setSaveLoading(false);
            }
          )
        );
      }
    },
  });

  const handleTextChange =
    (index: number, key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = e.target.value;
      });
      setValues(newValues);
    };

  const handleSelectChange =
    (index: number, key: string) => (selectedOption: any) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = selectedOption
          ? selectedOption
          : "";
      });
      setValues(newValues);
    };

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && vehicleNumber) {
      dispatch(
        fetchCommAutomobileScheduleAAsync(
          customerPolicyId,
          incrementalNumber,
          vehicleNumber
        )
      );
    }
  }, []);

  React.useEffect(() => {
    setValues({ ...data });
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, []);

  const finalList = React.useMemo(() => {
    return vehiclesList.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (Year: ${
          item.year || "N/A"
        }, Make: ${item.make || "N/A"}, Model: ${item.model || "N/A"} 
        )`,
        value: item.incremental_number || "",
      };
    });
  }, [vehiclesList]);

  React.useEffect(()=>{
    if(customerPolicyId){
      dispatch(fetchCommAutomobileVehicleListAsync(customerPolicyId ))
    }
  },[])

  return (
    <PageLoader loading={false}>
      <StandadCard heading="Vehicle Coverage Summary">
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} md={6}>
              <CustomFormLabel
                fontWeight={600}
                variant="h4"
                textAlign={"center"}
              >
                Vehicle Number
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="vehicle_number"
                value={values.vehicle_number}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={finalList}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
            <CustomFormLabel
                fontWeight={600}
                variant="h4"
                textAlign={"center"}
              >
                Status
              </CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.status}
                name="status"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "nowrap",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Section
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Automobile
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} variant="h4">
                          Occasional Premium
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.coverage_details.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell sx={{ minWidth: 600 }}>
                            {!item.isTypeOther ? (
                              <Typography
                                sx={{
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item.label}
                              </Typography>
                            ) : (
                              <AutoComplete
                                value={item.label}
                                placeholder="Select one"
                                options={csioOtherCoverages}
                                onChange={handleSelectChange(index, "label")}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} spacing={1}>
                              <CustomTextField
                                name="limit"
                                value={item.limit}
                                onChange={handleTextChange(index, "limit")}
                                variant="outlined"
                                size="small"
                                placeholder="Limit"
                              />
                              <CustomTextField
                                name="deductible"
                                value={item.deductible}
                                onChange={handleTextChange(index, "deductible")}
                                variant="outlined"
                                size="small"
                                placeholder="Deductible"
                              />
                              <CustomTextField
                                name="premimum"
                                value={item.premimum}
                                onChange={handleTextChange(index, "premimum")}
                                variant="outlined"
                                size="small"
                                placeholder="Premium"
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <CustomTextField
                              name="description"
                              value={item.description}
                              onChange={handleTextChange(index, "description")}
                              variant="outlined"
                              size="small"
                              placeholder="Description"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              moduleKey={MODULE_IDS.COMMERCIAL_AUTO  as string}
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
