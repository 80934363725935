import { LoadState } from "../../constants/enums";

export interface IUtilityState {
  list: IUtilityResponse[];
  totalRecords: number;
  loading: LoadState;
}

export interface IUtilityBranchCode {
  producer_code: string[];
  old_branch_code: string | null;
  new_branch_code: string | null;
  status: string;
}

export interface IUtilityCSRCode {
  producer_code: string[];
  branch_code: string[];
  type_of_policy: string[];
  customer_name: string[];
  customer_code: string[];
  csr_code: string;
  status: string;
}

export interface IUtilityProducerCode {
  producer_code: string[];
  branch_code: string[];
  type_of_policy: string[];
  customer_name: string[];
  customer_code: string[];
  new_producer_code: string;
  status: string;
}

export interface IUtilityCustomerCode {
  old_customer_id: string | null;
  new_customer_id: string | null;
}

export interface IUtilityLineOfBusinessPolicyMerge {
  old_customer_policy_id: string | null;
  new_customer_policy_id: string | null; 
}

export interface IUtilityResponse {
  utility_log_code: string | null;
  utility_type: string | null;
  incoming_payload: {
    [key: string]: number;
  };
  table_name: string | null;
  status: string;
}

export interface IUtilityResponseHistory {
  utility_log_code: string | null;
  utility_type: string | null;
  incoming_payload: {
    [key: string]: string;
  };
  table_name: string | null;
  customer_policy_id: string | null;
  type_of_policy: string | null;
  producer_code: string | null;
  branch_code: string | null;
  customer_name: string | null;
  customer_code: string | null;
  csr_code: string | null;
  new_producer_code: string | null;
  previous_id: string | null;
  current_id: string | null;
  query: string | null;
  process_status: string | null;
  status: string;
  created_by_id: string | null;
  modified_by_id: string | null;
  create_ts: string | null;
  insert_ts: string | null;
}
export interface IUtilityAssignPolicy {
  customer_policy_id: number | null;
  customer_id: number | null;
}

export const initialUtilityState: IUtilityState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
};

export const initialUtilityBranchCode: IUtilityBranchCode = {
  new_branch_code: "",
  old_branch_code: "",
  producer_code: [],
  status: "ACTIVE",
};

export const initialUtilityCSRCode: IUtilityCSRCode = {
  producer_code: [],
  branch_code: [],
  type_of_policy: [],
  customer_name: [],
  customer_code: [],
  csr_code: "",
  status: "ACTIVE",
};

export const initialUtilityProducerCode: IUtilityProducerCode = {
  producer_code: [],
  branch_code: [],
  type_of_policy: [],
  customer_name: [],
  customer_code: [],
  new_producer_code: "",
  status: "ACTIVE",
};

export const initialUtilityCustomerCode: IUtilityCustomerCode = {
  old_customer_id: null,
  new_customer_id: null,
};

export const initialUtilityAssign: IUtilityAssignPolicy = {
  customer_policy_id: null,
  customer_id: null,
};

export const initialUtilityLineOfBusinessPolicyMerge: IUtilityLineOfBusinessPolicyMerge = {
  old_customer_policy_id: null,
  new_customer_policy_id: null,
}
