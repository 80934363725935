import { action } from "typesafe-actions";
import { IEquipment } from "./equipment.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { makeApiCall } from "../../helpers/postRequest";
import { endPoints } from "../../constants/endPoints";
import { uploadFile } from "../../components/FileUpload/utils";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_EQUIPMENT_LIST_PROGRESS = "FETCH_EQUIPMENT_LIST_PROGRESS";
export const FETCH_EQUIPMENT_LIST_SUCCESS = "FETCH_EQUIPMENT_LIST_SUCCESS";
export const FETCH_EQUIPMENT_LIST_FAILED = "FETCH_EQUIPMENT_LIST_FAILED";

export const fetchEquipmentListProgress = () =>
  action(FETCH_EQUIPMENT_LIST_PROGRESS);
export const fetchEquipmentListSuccess = (data: IEquipment[]) =>
  action(FETCH_EQUIPMENT_LIST_SUCCESS, {
    data,
  });
export const fetchEquipmentListFailed = () =>
  action(FETCH_EQUIPMENT_LIST_FAILED);

export const fetchEquipmentListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEquipmentListProgress());

      let finalUrl = `/insurance/get-assigned-equipments?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/insurance/get-assigned-equipments?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await makeApiCall({
        method: "GET",
        url: finalUrl,
      });
      const data: IEquipment[] = res.data.data;

      dispatch(fetchEquipmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchEquipmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_EQUIPMENT_PROGRESS = "FETCH_EQUIPMENT_PROGRESS";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";
export const FETCH_EQUIPMENT_FAILED = "FETCH_EQUIPMENT_FAILED";

export const fetchEquipmentProgress = () => action(FETCH_EQUIPMENT_PROGRESS);
export const fetchEquipmentSuccess = (data: IEquipment) =>
  action(FETCH_EQUIPMENT_SUCCESS, {
    data,
  });
export const fetchEquipmentFailed = (errorMessage: string) =>
  action(FETCH_EQUIPMENT_FAILED, { errorMessage });

export const fetchEquipmentAsync =
  (equipmentCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEquipmentProgress());

      const res = await makeApiCall({
        method: "GET",
        url: `/insurance/get-assigned-equipments?brokerage_equipment_code=${equipmentCode}`,
      });
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchEquipmentSuccess(data[0]));
      } else {
        dispatch(
          fetchEquipmentFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchEquipmentFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertEquipmentAsync =
  (
    data: IEquipment,
    initialData: IEquipment,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        brokerage_equipment_code: data.brokerage_equipment_code,
      };
      const path = await uploadFile(
        file,
        "BROKERAGE_EQUIPMENT",
        data.attachments || "",
        asPayload
      );
      await makeApiCall(
        {
          url: "/insurance/upsert-assigned-equipments",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "brokerage_equipment_code",
          },
        },
        initialData,
        {
          ...data,
          attachments: path,
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Equipment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_EQUIPMENT_STATE = "CLEAR_EQUIPMENT_STATE";
export const clearEquipmentState = () => action(CLEAR_EQUIPMENT_STATE);
