import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomobileAdditionalInformation,
  IAutomobileInterestedParty,
  IAutomobileVehicleInformation,
} from "./automobileVehicle.types";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED";

export const fetchAutomobileVehcileListProgress = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS);

export const fetchAutomobileVehiclesListSuccess = (
  data: IAutomobileVehicleInformation[]
) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileVehcileListFailed = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED);

export const fetchAutomobileVehicleListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileVehcileListProgress());

      let finalUrl = `/policy/get-policy-auto-vehicle-information?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchAutomobileVehicleListByPolicyNumberAsync =
  (policyNumber: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileVehcileListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-vehicle-information?column=policy_number&value=${policyNumber}`
      );
      const data: IAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED";

export const fetchAutomobileVehicleProgress = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS);

export const fetchAutomobileVehicleSuccess = (
  data: IAutomobileVehicleInformation
) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS, {
    data,
  });
export const fetchAutomobileVehicleFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED, { errorMessage });

export const addAutomobileVehicleAsync =
  (
    data: IAutomobileVehicleInformation,
    onCallback: (
      isSuccess: boolean,
      data?: IAutomobileVehicleInformation
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/policy/edit-policy-auto-vehicle-information",
        {
          ...data,
        }
      );
      dispatch(fetchAutomobileVehicleSuccess(res.data.data));
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vehicle information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileVehicleAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileVehicleProgress());
      const res = await api.get(
        `/policy/get-policy-auto-vehicle-information?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileVehicleInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileVehicleSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileVehicleFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileVehicleFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED";

export const fetchAutomobilAdditionalInfoProgress = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS);
export const addAutomobilAdditionalInfo = (
  data: IAutomobileAdditionalInformation
) =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS, {
    data,
  });
export const fetchAutomobilAdditionalInfoFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED, { errorMessage });

export const addAutomobilAdditionalInfoAsync =
  (
    data: IAutomobileAdditionalInformation,
    vehcileNumber: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-additional-information", {
        ...data,
        vehicle_id: vehcileNumber,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Additional information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobilAdditionalInfoVehicleAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilAdditionalInfoProgress());
      const res = await api.get(
        `/policy/get-policy-auto-additional-information?customer_policy_id=${customerPolicyId}&vehicle_id=${incremental_number}`
      );
      const data: IAutomobileAdditionalInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          addAutomobilAdditionalInfo({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobilAdditionalInfoFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobilAdditionalInfoFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobilIntrestedPartiesAsync =
  (
    data: IAutomobileInterestedParty,
    vehicleNumber: number,
    onCallback: (isSuccess: boolean, data?: IAutomobileInterestedParty) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/policy/edit-policy-auto-interested-party", {
        ...data,
        vehicle_id: vehicleNumber,
      });
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested party saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED";

export const fetchAutomobileIntrestedPartieListProgress = () =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS);

export const fetchAutomobileIntrestedPartieListSuccess = (
  data: IAutomobileInterestedParty[]
) =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileIntrestedPartieListFailed = () =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED);

export const fetchAutomobileIntrestedPartieListAsync =
  (
    customerPolicyId: number,
    status?: string,
    vehicleNumber?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileIntrestedPartieListProgress());

      let finalUrl = `/policy/get-policy-auto-interested-party?customer_policy_id=${customerPolicyId}`;
      if (vehicleNumber) {
        finalUrl += `&vehicle_id=${vehicleNumber}`;
      }
      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileInterestedParty[] = res.data.data;
      dispatch(fetchAutomobileIntrestedPartieListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileIntrestedPartieListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_VEHICLE_STATE = "CLEAR_AUTOMOBILE_VEHICLE_STATE";

export const clearAutomobileVehicleState = () =>
  action(CLEAR_AUTOMOBILE_VEHICLE_STATE);
