import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../../../../../components/formsComponents";
import { IHabitationalPolicyChangeProps } from "./HabitationalPolicyChange.types";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { useHabitationalIntrestedParty } from "../../hooks/useHabitationalIntrestedParty";
import { InterestedPartiesRightPanel } from "../../InterestedParties/InterestedPartiesRightPanel";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import produce from "immer";
import { getFullAddres } from "../../../../../../../helpers";
import { IHabitationalInterested } from "../../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";
import { ConfirmDialog } from "../../../../../../../components/Dialogs/ConfirmDialog";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";

export const InterestedPartyInformation: React.FC<
  IHabitationalPolicyChangeProps
> = ({
  values,
  setValues,
  errors,
  handleChange,
  setFieldValue,
  customerId,
  customerPolicyId,
  isStatusCompleted,
  autoSaveData = () => {},
}) => {
  const [open, setOpen] = React.useState<{
    type: "create" | "edit" | "view";
    index?: number;
    data?: IHabitationalInterested;
  } | null>(null);
  const { interestedPartyDropDown } =
    useHabitationalIntrestedParty(customerPolicyId);

  const handleCheckBoxChange = (value: string) => {
    setFieldValue("interested_party_change.type", value);
  };

  const handleSaveSuccess = (data: IHabitationalInterested) => {
    const newValues = produce(values, (draftValues) => {
      const updatedData = {
        ...data,
        customer_policy_id: customerPolicyId ?? null,
        customer_id: customerId ? Number(customerId) : null,
      };
      if (open && open.type === "edit" && open.index !== undefined) {
        draftValues.interested_party_change.records[open.index] = updatedData;
      } else {
        draftValues.interested_party_change.records.push(updatedData);
      }
    });
    autoSaveData(newValues);
    setValues(newValues);
    setOpen(null);
    setOpen(null);
  };

  const handleCreateEdit = (index?: number) => () => {
    if(isStatusCompleted){
      return;
    }
    setOpen({
      type: index !== undefined ? "edit" : "create",
      index: index,
      data:
        index !== undefined
          ? values.interested_party_change.records[index]
          : undefined,
    });
  };

  const handleView = (index: number) => () => {
 
    setOpen({
      type: "view",
      index: index,
      data: values.interested_party_change.records[index]
    });
  };

  const [deleteConfirm, setDeleteConfirm] = React.useState<number | null>(null)
    const handleDelete =(index: number) =>() => {
      setDeleteConfirm(index)
    }
  
    const handleDeleteConfirm = () => {
      if(deleteConfirm ===null){
        return;
      }
      const newValues = produce(values, draftValues=>{
        draftValues.interested_party_change.records.splice(deleteConfirm,1);
      });
      setValues(newValues);
      autoSaveData(newValues);
      setDeleteConfirm(null);
    }

  return (
    <>
      <LabelCard heading="Interested Party Change" />
           <FieldSet disabled={isStatusCompleted}>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.interested_party_change.type === "Add"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Add" : "");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.interested_party_change.type === "Delete"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Delete" : "");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.interested_party_change.type === "Change"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Change" : "");
              }}
            />
            <Typography>Change</Typography>
          </Stack>
        </Grid>
      </Grid>
      </FieldSet>

      {values.interested_party_change.type &&
        ["Add", "Change"].includes(values.interested_party_change.type) && (
          <>
            <Grid container spacing={3}>
              {values.interested_party_change.type === "Change" && (
                <Grid item xs={12} md={6}>
                  <CustomFormLabel>Delete Interested Party</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="interested_party_change.delete_interested_party"
                    fullWidth
                    displayEmpty
                    value={Number(
                      values.interested_party_change.delete_interested_party
                    )}
                    onChange={handleChange}
                    placeholder="Select One"
                    options={interestedPartyDropDown}
                  ></ControlledCustomSelect>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"end"}>
                  <Button variant="contained" disabled={isStatusCompleted} onClick={handleCreateEdit()}>
                    Add New
                  </Button>
                </Stack>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>View</TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.interested_party_change.records.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ display: "flex" }}>
                                <StandardTableActions
                                  onViewClick={handleView(index)}
                                  onEditClick={!isStatusCompleted ? handleCreateEdit(index) : undefined}
                                  onDeleteClick={!isStatusCompleted ? handleDelete(index): undefined}
                                />
                              </TableCell>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {" "}
                                {getFullAddres(
                                  item.mortgage_or_loss_payee_address,
                                  item.mortgage_or_loss_payee_unit_or_suite,
                                  item.mortgage_or_loss_payee_city,
                                  item.mortgage_or_loss_payee_province_or_state,
                                  item.mortgage_or_loss_payee_country,
                                  item.mortgage_or_loss_payee_postal_or_zip_code
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}

      {values.interested_party_change.type &&
        ["Delete"].includes(values.interested_party_change.type) && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Interested Party</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="interested_party_change.delete_interested_party"
                fullWidth
                displayEmpty
                value={Number(
                  values.interested_party_change.delete_interested_party
                )}
                onChange={handleChange}
                placeholder="Select One"
                options={interestedPartyDropDown}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
        )}

      {open && (
        <InterestedPartiesRightPanel
          open={true}
          readonly={open.type === "view"}
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          onSaveSuccess={handleSaveSuccess}
          createdFrom="Policy_Change"
          staticStatusValue="ACTIVE"
          onClose={() => setOpen(null)}
          initialData={open.data}
          disableApiCalls
        />
      )}
      {deleteConfirm !== null &&
              <ConfirmDialog
                open
                content="Are you sure you want to delete this record?"
                onClose={()=> setDeleteConfirm(null)}
                onConfrim={handleDeleteConfirm}
              />
      
            }
    </>
  );
};
