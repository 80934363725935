/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import {
  clearAutomobileScheduleState,
  fetchAutomobileScheduleAListAsync,
} from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";
import { IAutomobileScheduleA } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";

export const AutomobileScheduleAList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const { listData: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.schedule.scheduleA
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { columnsConfig },
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage`
    );
  };

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchAutomobileScheduleAListAsync(customerpolicyId, status));
    }
  };

  const automobileApplicationTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        // headingAlign: 'center',
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileScheduleA) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage/${row.vehicle_number}/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileScheduleA) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Customer Policy Id",
        fieldName: "customer_policy_id",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        onRowCellRender: (value, row: IAutomobileScheduleA) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/manage-coverage/${row.vehicle_number}/${row.incremental_number}`
                );
              }}
            >
              {row.customer_policy_id}
            </Link>
          );
        },
      },
      {
        key: "vehicle_number",
        headerName: "Vehicle Number",
        fieldName: "vehicle_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "incremental_number",
        headerName: "Coverage Number",
        fieldName: "incremental_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      //   {
      //     key: "broker_agent",
      //     headerName: "Broker Agent",
      //     fieldName: "broker_agent",
      // exportCellWidth: 25,
      //     renderType: DataTableV2RowRenderType.CHIP_WARNING,
      //   },

      //   {
      //     key: "interest",
      //     headerName: "Interest",
      //     fieldName: "interest",
      // exportCellWidth: 25,
      //     renderType: DataTableV2RowRenderType.TEXT,
      //   },
      //   {
      //     key: "type_of_payment_plan",
      //     headerName: "Type of Payment Plan",
      //     fieldName: "type_of_payment_plan",
      // exportCellWidth: 25,
      //     renderType: DataTableV2RowRenderType.TEXT,
      //   },

      //   {
      //     key: "amount_due",
      //     headerName: "Amount Due",
      //     fieldName: "amount_due",
      // exportCellWidth: 25,
      //     renderType: DataTableV2RowRenderType.TEXT,
      //   },
      //   {
      //     key: "status",
      //     headerName: "Status",
      //     fieldName: "status",
      // exportCellWidth: 25,
      //     renderType: DataTableV2RowRenderType.TEXT,
      //   },
    ],
    rows: data || [],
  };

  React.useEffect(() => {
    fetchList();
  }, [status]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <PageTitleBar
        heading="Coverage Summary"
        rightHeading={
          <Button variant="contained" onClick={handleCreate}>
            Create
          </Button>
        }
      />
      <DataTableV2 {...automobileApplicationTableProps} />
    </Box>
  );
};
