import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { APP_FILE_POLICY_CHANGE_ROUTE } from "./policychangerequest.constants";
const PolicyChangeRequestList = Loadable(
  lazy(() =>
    import("./PolicyChangeRequestList").then(({ PolicyChangeRequestList }) => ({
      default: PolicyChangeRequestList,
    }))
  )
);
export const policyChangeRequestRoutes = [
  {
    path: `${APP_FILE_POLICY_CHANGE_ROUTE}`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleKeys={[]}>
        <PolicyChangeRequestList />
      </AuthorizedRoute>
    ),
  },
];
