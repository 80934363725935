import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";
import { ICustomer } from "./customer.types";

export const initialCustomerState: ICustomer = {
  personalInformation: {
    data: {
      customer_personal_information_id:null,
      customer_id: null,
      salutation: "",
      first_name: "",
      last_name: "",
      goes_by: "",
      position: "",
      mobile: "",
      phone: "",
      phone_ext: "",
      email: "",
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
  },
  customerProspect: {
    data: {
      customer_prospect_details_id: 0,
      customer_id: 0,
      customer_name: "",
      csr: "",
      customer_code: "",
      form_of_business: "",
      tax_zone: "ON",
      mailing_address: "",
      language: "E",
      producer: "",
      marketer: "",
      branch: "",
      assigned_to_id: null,
      status: "ACTIVE",
      insert_ts: "",
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      unit_or_suite: "",
      assigned_to_code: "",
      csr_code: "",
      marketer_code: "",
      producer_code: "",
      branch_code: "",
      sector_status_code: null,
      customer_since: ""
    },
    loading: LoadState.NotLoaded,
  },
  customerSocial: {
    data: {
      customer_social_id: 0,
      customer_id: 0,
      website: "",
      facebook: "",
      linkedin: "",
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
  },
  customerRelatedAccount: {
    data: [],
    loading: LoadState.NotLoaded,
  },
  customerConsent: {
    data: {
      incremental_number: 0,
      customer_id: "",
      privacy_consent: "",
      privacy_act_consent: "",
      anti_spam_consent: "",
      telematics_consent_code: "",
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
  },
  opportunity: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  customerConsentList: {
    data: [],
    loading: LoadState.NotLoaded,
  },
  customerGroupsList: {
    data: [],
    loading: LoadState.NotLoaded,
  },

  customerContacts: {
    data: [],
    loading: LoadState.NotLoaded,
  },
  policies: {
    data: [],
    loading: LoadState.NotLoaded,
  },
  error: "",
};
