
import { LoadState } from "../../../constants/enums";
import { IExcelTemplate, IExcelTemplateState } from "./excel-template.types";


export const defaultExcelTemplate:IExcelTemplate = {
    "template_uuid": null,
    "template_name": null,
    "file_type": "EXCEL",
    "brand": null,
    "heading_row": {
      start: 0,
      end: 0
    },
    column_mapping: null,
    // "column_mapping": {
    //   "product_no": "field",
    //   "image": "field",
    //   "name": "field",
    //   "color": "field",
    //   "color_code": "field",
    //   "color_id": "field",
    //   "material": "field",
    //   "description": "field",
    //   "category": "field",
    //   "sub_category": "field",
    //   "available_size": "field",
    //   "price": "field",
    //   "quantity": "field",
    //   "additional_property": {
    //     "new_column1": "field",
    //     "new_column2": "field"
    //   }
    // },
    "box_count": null,
    "base_file_path": null,
    "page_box_coordinate": [],
    "item_labeling": {},
    "status": "ACTIVE"
  }


  export const defaultExcelTemplateState:IExcelTemplateState = {
    excel_template: {
        data: defaultExcelTemplate,
        loading: LoadState.NotLoaded,
        error: null
    }
  }