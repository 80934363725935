import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../../api/api";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomSelect } from "../../../components/formsComponents";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import { INoPolicyInsuranceReports } from "./insuranceReport.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IInsuranceReports } from "../../../redux/insuranceReports/insuranceReports.types";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { MenuItem } from "@mui/material";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
import {
  TABLE_CACHING_KEYS,
  TASK_STATUS,
  task_status,
} from "../../../constants/constants";
import { ViewOldInsuranceReport } from "./ViewOldInsuranceReport/ViewOldInsuranceReport";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import { IOldInsuranceReportsList } from "../../../redux/OldInsuranceReport/oldInsuranceReport.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import { fetchOldInsuranceReportsListAsync } from "../../../redux/OldInsuranceReport/oldInsuranceReportActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";

export const OldInsuranceReportsList: React.FC = (props) => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.oldInsurancReports
  );

  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: IInsuranceReports[];
  }>({
    isAllSelected: false,
    leads: [],
  });

  const [openView, setOpenView] =
    React.useState<INoPolicyInsuranceReports | null>(null);
  const [viewOldInsuranceReport, setViewOldInsuranceReport] =
    React.useState<IOldInsuranceReportsList | null>(null);

  const [openWarning, setOpenWarning] = React.useState<boolean>(false);

  const dispatch = useDispatchWrapper();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination ,masterColumnResize },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.OLD_INSURANCE_REPORTS,
    },
  });

  const fetchList = () => {
    dispatch(
      fetchOldInsuranceReportsListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const handleComplete = () => {
    fetchList();
    setOpenView(null);
  };

  const [dropdownList, setDropdownList] = React.useState<any>([]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["customer_code", "driver_name", "policy_number"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        isFirstColumnSticky: true,
        disableResize: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IOldInsuranceReportsList) => {
          return (
            <StandardTableActions
              onViewClick={() => {
                setViewOldInsuranceReport(row);
              }}
            />
          );
        },
      },
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
      },
      {
        key: "customer_code",
        headerName: "Customer Code",
        fieldName: "customer_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // onRowCellRender: (value, row: ITask) => {
        //   return <LeadOppStatus status={row.task_status} />;
        // },
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "notes",
        headerName: "Notes",
        fieldName: "notes",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "producer_one_code",
        headerName: "Producer Code",
        fieldName: "producer_one_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },

      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "report_requested",
        headerName: "Report Requested",
        fieldName: "report_requested",
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "create_ts",
        headerName: "Due Date",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "request_status",
        headerName: "Request Status",
        fieldName: "request_status",
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
      },

      
    ],
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns
    }
  };
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(TASK_STATUS, recordsCounts);
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_task_driver_insurance_report",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);
  
  React.useEffect(() => {
    setTimeout(() => {
      setOpenWarning(true);
    }, 1000);
  }, []);

  return (
    <PageContainer
      title="View Insurance Report"
      description="this is innerpage"
    >
      {/* <Breadcrumb title="View Opportunites" items={BCrumb} /> */}

      <PageTitleBar heading="Old Insurance Reports" />
      <DataTableV2 {...opportunitesTableProps} />

      {openView && (
        <InsuranceReportDialog
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
          onComplete={handleComplete}
        />
      )}
      {viewOldInsuranceReport && (
        <ViewOldInsuranceReport
          open={true}
          onClose={() => setViewOldInsuranceReport(null)}
          data={viewOldInsuranceReport}
        />
      )}
    </PageContainer>
  );
};
