import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileDetails } from "./commAutoBasicDetails.types";
import { CommAutoPolicyBasicAction } from ".";
import {
  CLAER_COMM_AUTOMOBILE_POLICY_ATTACHMENT_STATE,
  CLEAR_COMM_AUTOMOBILE_BASIC_DETAILS_STATE,
  FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_FAILED,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_PROGRESS,
  FETCH_COMM_AUTOMOBILE_ATTACHMENT_SUCCESS,
  FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_FAILED,
  FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_SUCCESS,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_FAILED,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_PROGRESS,
  FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_SUCCESS,
  FETCH_COMM_AUTOMOBILE_PREMIUM_FAILED,
  FETCH_COMM_AUTOMOBILE_PREMIUM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_PREMIUM_SUCCESS,
  FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_FAILED,
  FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_PROGRESS,
  FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_SUCCESS,
} from "./commAutoBasicDetailsActions";

export const CommAutomobilespolicBasicDetailsReducer = (
  state: IStoreState["commAuto"]["basicDetails"] = initialCommAutomobileDetails,
  action: CommAutoPolicyBasicAction
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.customerInfo.loading = LoadState.InProgress;
        draftState.customerInfo.data =
          initialCommAutomobileDetails["customerInfo"]["data"];
        draftState.customerInfo.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_SUCCESS: {
      const { customerInfo, customerPolicyId, isNewPolicy } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.customerInfo.loading = LoadState.Loaded;
        draftState.customerInfo.data = customerInfo;
        draftState.customerPoliycId = customerPolicyId;
        draftState.isNewPolicy = isNewPolicy;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.customerInfo.loading = LoadState.Failed;
        draftState.customerInfo.error = errorMessage;
        draftState.customerInfo.data =
          initialCommAutomobileDetails["customerInfo"]["data"];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = [];
        draftState.additional_insured.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = data;
        draftState.additional_insured.loading = LoadState.Loaded;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.additional_insured.list = [];
        draftState.additional_insured.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdmin.loading = LoadState.InProgress;
        draftState.policyAdmin.data =
          initialCommAutomobileDetails["policyAdmin"]["data"];
        draftState.policyAdmin.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdmin.loading = LoadState.Loaded;
        draftState.policyAdmin.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdmin.loading = LoadState.Failed;
        draftState.policyAdmin.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdminManager.loading = LoadState.InProgress;
        draftState.policyAdminManager.data =
          initialCommAutomobileDetails["policyAdminManager"]["data"];
        draftState.policyAdminManager.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdminManager.loading = LoadState.Loaded;
        draftState.policyAdminManager.data = data;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.policyAdminManager.loading = LoadState.Failed;
        draftState.policyAdminManager.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_PREMIUM_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.premium.loading = LoadState.InProgress;
        draftState.premium.data =
          initialCommAutomobileDetails["premium"]["data"];
        draftState.premium.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_PREMIUM_SUCCESS: {
      const { premium } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.premium.loading = LoadState.Loaded;
        draftState.premium.data = premium;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_PREMIUM_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.premium.loading = LoadState.Failed;
        draftState.premium.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.priorPolicy.loading = LoadState.InProgress;
        draftState.priorPolicy.data =
          initialCommAutomobileDetails["priorPolicy"]["data"];
        draftState.priorPolicy.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_SUCCESS: {
      const { priorPolicy } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.priorPolicy.loading = LoadState.Loaded;
        draftState.priorPolicy.data = priorPolicy;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.priorPolicy.loading = LoadState.Failed;
        draftState.priorPolicy.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.InProgress;
        draftState.attachment.data =
          initialCommAutomobileDetails["attachment"]["data"];
        draftState.attachment.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.Loaded;
        draftState.attachment.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.attachment.loading = LoadState.Failed;
        draftState.attachment.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.InProgress;
        draftState.attachment.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Loaded;
        draftState.attachment.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.attachment.listLoading = LoadState.Failed;
        draftState.attachment.list = [];
      });
      return newState;
    }

    case CLAER_COMM_AUTOMOBILE_POLICY_ATTACHMENT_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.attachment = initialCommAutomobileDetails["attachment"];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_BASIC_DETAILS_STATE: {
      return initialCommAutomobileDetails;
    }
    default: {
      return state;
    }
  }
};
