import { LoadState } from "../../constants/enums";

export interface IOldBusinessTrackerState {
    list: IOldBusinessTracker[];
    data: IOldBusinessTracker;
    loading: LoadState;
    saveLoading: boolean;
    error: string | null;
}




export interface IOldBusinessTracker {
  customer_policy_id: string | null;
  task_name: string | null;
  task_created_date: string | null;
  task_due_date: string | null;
  task_completed_date: string | null;
  task_type: string | null;
  task_sub_type: string | null;
  task_status: string | null;
  task_specify: string | null;
  task_details: string | null;
  task_priority: string | null;
  task_related_to: string | null;
  task_related_to_id: string | null;
  assigned_to_id: string | null;
  notes: string | null;
  status: string;
  created_by_id: string | null;
  insert_ts: string | null;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  effective_date_of_change: string | null;
  old_business_tracker_id: string;
}

export const initialOldBusinessTrackerState: IOldBusinessTrackerState = {
  list: [],
  data: {
    customer_policy_id: null,
    task_name: null,
    task_created_date: null,
    task_due_date: null,
    task_completed_date: null,
    task_type: null,
    task_sub_type: null,
    task_status: null,
    task_specify: null,
    task_details: null,
    task_priority: null,
    task_related_to: null,
    task_related_to_id: null,
    assigned_to_id: null,
    notes: null,
    status: "ACTIVE",
    created_by_id: null,
    insert_ts: null,
    customer_code: null,
    customer_name: null,
    policy_number: null,
    line_of_business: null,
    branch: null,
    broker_code: null,
    insurer: null,
    producer: null,
    csr: null,
    effective_date_of_change: null,
    old_business_tracker_id: "",
  },
  loading: LoadState.NotLoaded,
  saveLoading: false,
  error: null,
}
