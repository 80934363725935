/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import { Box, Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { batch } from "react-redux";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { InterestedPartyCodeSearchesSelect } from "../../../../../../components/AutoCompleteSearches/InterestedPartyCodeSearchSelect";
import {
  addHabitationalInterestedAsync,
  clearHabitationalIntrested,
  fetchHabitationalIntrestedAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterestedActions";
import { IHabitationalInterested } from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";
import { fetchHabitationalRiskListAsync } from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { useHabitationalPolicyRiskLocation } from "../hooks/useHabitationalPolicyRiskLocation";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IInterestedPartiesProps {
  customerId?: number;
  customerPolicyId?: number;
  interestedNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  onSaveSuccess: (interestedParty: IHabitationalInterested) => void;
  staticStatusValue?: string;
  createdFrom?: string;
  initialData?: IHabitationalInterested;
  disableApiCalls?: boolean;
}

export const InterestedParties: React.FC<IInterestedPartiesProps> = (props) => {
  const {
    customerId,
    customerPolicyId,
    interestedNumber,
    onSaveSuccess,
    fromRightPanel = false,
    readOnly = false,
    staticStatusValue,
    createdFrom,
    disableApiCalls = false,
    initialData,
  } = props;

  const { intrestLoading, interest, error } = useSelector(
    (storeState: IStoreState) => storeState.habitational.interested
  );
  const { riskLocationsDropDown } =
    useHabitationalPolicyRiskLocation(customerPolicyId);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialData ? initialData : interest,
    validate: (values) => {},
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          setSaveLoading(true);
          dispatch(
            addHabitationalInterestedAsync(
              {
                ...values,
                customer_id: customerId,
                customer_policy_id: customerPolicyId,
                status: finalStatus,
                created_from: createdFrom || "Interested_Party",
              },
              (isSuccess, data) => {
                if (isSuccess && data) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(data);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      mortgage_or_loss_payee_address: data.address,
      mortgage_or_loss_payee_city: data.city,
      mortgage_or_loss_payee_country: data.country,
      mortgage_or_loss_payee_province_or_state: data.state,
      mortgage_or_loss_payee_postal_or_zip_code: data.postalCode,
    });
  };

  const handleIntrestedPartyCode = (data: IHabitationalInterested) => {
    if (data) {
      setValues({
        ...values,
        mortgage_or_loss_payee_nature_of_interest:
          data.mortgage_or_loss_payee_nature_of_interest || "",
        mortgage_or_loss_payee_code: data.interested_party_code || "",
        mortgage_or_loss_payee_name: data.interested_party_name || "",
        mortgage_or_loss_payee_address: data.address || "",
        mortgage_or_loss_payee_city: data.city || "",
        mortgage_or_loss_payee_country: data.country || "",
        mortgage_or_loss_payee_province_or_state: data.province_or_state || "",
        mortgage_or_loss_payee_postal_or_zip_code: data.postal_code || "",
        mortgage_or_loss_payee_unit_or_suite: data.unit_or_suite || "",
      });
    }
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      batch(() => {
        dispatch(fetchHabitationalRiskListAsync(customerPolicyId));
      });
    }
  }, []);

  React.useEffect(() => {
    if (customerPolicyId && interestedNumber && !disableApiCalls) {
      dispatch(
        fetchHabitationalIntrestedAsync(customerPolicyId, interestedNumber)
      );
    }
  }, [customerPolicyId, interestedNumber]);

  React.useEffect(() => {
    if(disableApiCalls && initialData){
       return;
    }
    setValues({ ...interest, incremental_number: interestedNumber || null });
  }, [interest]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalIntrested());
    };
  }, []);

  return (
    <PageLoader
      loading={intrestLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={fromRightPanel ? "" : "Interested Parties"}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>#</CustomFormLabel>
                <CustomTextField
                  name="incremental_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.incremental_number}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.5}>
                <CustomFormLabel>Risk Location #</CustomFormLabel>

                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="location_number"
                  fullWidth
                  displayEmpty
                  value={Number(values.location_number)}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={riskLocationsDropDown}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <InterestedPartyCodeSearchesSelect
                  label="Interested Party Code"
                  value={values.mortgage_or_loss_payee_code}
                  onSelect={(value) => handleIntrestedPartyCode(value)}
                />
              </Grid>

              <Grid item xs={12} lg={2.5}>
                <CustomFormLabel>Interested Party</CustomFormLabel>
                <CustomTextField
                  name="mortgage_or_loss_payee_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Interest</CustomFormLabel>
                <CustomTextField
                  name="mortgage_or_loss_payee_nature_of_interest"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_nature_of_interest}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3.5}>
                <CustomFormLabel>Address</CustomFormLabel>
                <LocationAutoComplete
                  id="mortgage_or_loss_payee_address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_address}
                  onLocationChange={handleAddress}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  id="mortgage_or_loss_payee_unit_or_suite"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_unit_or_suite}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  id="mortgage_or_loss_payee_city"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>State/Province</CustomFormLabel>
                <CustomTextField
                  id="mortgage_or_loss_payee_province_or_state"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_province_or_state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Postal Code</CustomFormLabel>
                <CustomTextField
                  id="mortgage_or_loss_payee_postal_or_zip_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_postal_or_zip_code}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Country</CustomFormLabel>
                <CustomTextField
                  id="mortgage_or_loss_payee_country"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.mortgage_or_loss_payee_country}
                  onChange={handleChange}
                />
              </Grid>
              {!staticStatusValue && (
                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  ></ControlledCustomSelect>
                </Grid>
              )}
            </Grid>

            <Box sx={{ mt: 4 }}>
              <RoleBasedCustomButton
                sx={{ width: "15%" }}
                disabled={saveLoading}
                variant="contained"
                type="submit"
                moduleKey={MODULE_IDS.HABITATIONAL}
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
