/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { CustomerBaiscDetailsLayout } from "../../../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import { Breadcrumb } from "../../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { addAutomobilIntrestedPartiesAsync } from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { useNavigate } from "react-router-dom";
import {
  COMMON_STATUS,
  interested_interest,
} from "../../../../../../../constants/constants";
import { renderDropdownValue } from "../../../../../../../helpers";
import { IAutoVehicleInterestedProps } from "./AutomobileInterested.types";
import { Dialog } from "../../../../../../../components/Dialogs/Dialog";
import { fetchBrokerageIntrestedAllPartiesAsync } from "../../../../../../../redux/InterestedParties/IntrestedPartiesActions";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import { IAutomobileInterestedParty } from "../../../../../../../redux/automobileVehicle/automobileVehicle.types";

export const AutomobileInterested: React.FC<{
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  onComplete: (data: IAutomobileInterestedParty) => void;
  onClose: () => void;
  data?: IAutomobileInterestedParty;
  readOnly?: boolean;
  number?: number;
  staticStatusValue?: string;
  created_from?: string;
  initialData?: IAutomobileInterestedParty;
  disableApiCalls?: boolean;
}> = (props) => {
  const {
    open,
    customerId,
    customerPolicyId,
    onComplete,
    onClose,
    readOnly = false,
    data,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialData ? initialData : { ...data },
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      if (disableApiCalls) {
        onComplete(values as any);
      } else {
        if (customerPolicyId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setLoading(true);
          dispatch(
            addAutomobilIntrestedPartiesAsync(
              {
                ...values,
                customer_id: customerId,
                customer_policy_id: customerPolicyId ?? 0,
                status: statusValue,
                created_from: created_from || "Interested Party",
              } as any,
              values.vehicle_id as any,
              (isSuccess, data) => {
                if (onComplete && isSuccess && data) {
                  onComplete(data);
                }
                setLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleIntrestedPartyCode = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    const item = intrestedParities.find(
      (x) => x.interested_party_code === value
    );
    if (item) {
      setValues({
        ...values,
        interested_party_code: item.interested_party_code,
        name: item.interested_party_name,
        address: item.address,
        unit_or_suite: item.unit_or_suite || "",
        city: item.city || "",
        country: item.country || "",
        province_or_state: item.state_or_province || "",
        postal_code: item.postal_code || "",
      });
    }
  };

  const intrestedParities = useSelector(
    (storeState: IStoreState) =>
      storeState.borkerage.intrestedParties.intrestedParties.list
  );

  const intrestedParitiesDropdown = React.useMemo(() => {
    return intrestedParities.map((item) => {
      return {
        label: item.interested_party_code,
        value: item.interested_party_code || "",
      };
    });
  }, [intrestedParities]);

  React.useEffect(() => {
    dispatch(fetchBrokerageIntrestedAllPartiesAsync());
    // return () => {
    //   dispatch(clearAutomobileIntrestedParty());
    // };
  }, []);

  return (
    <>
      <Dialog
        open={open}
        title="Create/Edit Vehicle Interested Parties"
        onClose={onClose}
        size="lg"
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "submit",
            label: "Save",
            variant: "contained",
            disabled: loading,
          },
        ]}
      >
        <FieldSet disabled={readOnly}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Number</CustomFormLabel>
              <CustomTextField
                name="number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.number}
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Interested Party Code</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="interested_party_code"
                fullWidth
                displayEmpty
                value={values.interested_party_code}
                onChange={handleIntrestedPartyCode}
                placeholder="Select One"
                options={intrestedParitiesDropdown}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Interest</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="interest"
                value={values.interest}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {interested_interest.map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Name</CustomFormLabel>
              <CustomTextField
                name="name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Address</CustomFormLabel>
              <CustomTextField
                name="address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.address}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                id="unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.unit_or_suite}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                id="city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>State/Province</CustomFormLabel>
              <CustomTextField
                id="province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                id="postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.postal_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                id="country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.country}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {!staticStatusValue && (
            <Grid container>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={COMMON_STATUS}
                />
              </Grid>
            </Grid>
          )}
        </FieldSet>
      </Dialog>
    </>
  );
};
