import React from "react";
import { Avatar, Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBreadcrumbContext } from "../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { IStoreState } from "../../../redux/initialStoreState";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import {
  fetchNoteAsync,
  fetchNoteListAsync,
  fetchNoteListFromPolicyAsync,
} from "../../../redux/notes/notesActions";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { INotices } from "../../../redux/notices/notices.types";
import { DataTable } from "../../../components/Table/DataTable";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IPolicyNote } from "../../../redux/notes/notes.types";
import { StandadCard } from "../../../components/Cards";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { CustomSelect } from "../../../components/formsComponents";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { capitalizeWords, createTabsWithRecordcounts } from "../../../helpers";
import { PageContainer } from "../../../components/container/PageContainer";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import { Add } from "@mui/icons-material";
import { TABLE_CACHING_KEYS } from "../../../constants/constants";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";

export const TaskNotesList: React.FC = () => {
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard/",
      title: "Dashboard",
    },
    {
      title: "Abeyances/Notes",
    },
  ];
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.policyNotes
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "-1"
  );
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination ,masterColumnResize},
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.NOTES,
    },
  });

  const fetchList = async () => {
    dispatch(
      fetchNoteListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const handleRedirect = () => {
    navigate(`/manage-note`);
  };

  const handleEditRedirect =
    (customerPolicyId: number, serialNumber: number) => () => {
      navigate(`/manage-note/${customerPolicyId}/${serialNumber}`);
    };

  const notesTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["notes_title", "customer", "policy_number"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        disableResize: true,
        isFirstColumnSticky: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: IPolicyNote) => {
          return (
            <StandardTableActions
              commentBoxTypeId={
                row.customer_policy_id + "-" + row.incremental_number
              }
              onEditClick={handleEditRedirect(
                row.customer_policy_id,
                row.incremental_number as number
              )}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <StatusRenderer
              status={row.status === "ACTIVE" ? "Open" : "Closed"}
            />
          );
        },
      },
      {
        key: "notes_title",
        headerName: "Title",
        fieldName: "notes_title",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={handleEditRedirect(
                row.customer_policy_id,
                row.incremental_number as number
              )}
            >
              {row.notes_title}
            </Link>
          );
        },
      },
      {
        key: "customer",
        headerName: "Customer",
        fieldName: "customer",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        enableSorting: true,
        // exportCellWidth: 30,
      },
      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        enableSorting: true,
        // exportCellWidth: 20,
      },
      {
        key: "date_created",
        headerName: "Date Modifed",
        fieldName: "date_created",
        renderType: DataTableV2RowRenderType.DATE,
        enableSorting: true,
        // exportCellWidth: 20,
      },
      {
        key: "time_completed",
        headerName: "Date Completed",
        fieldName: "time_completed",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        enableSorting: true,
        // exportCellWidth: 20,
      },
    ],
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns
    }
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Open", value: "ACTIVE", variant: "success" },
        { label: "Closed", value: "DELETED", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_notes",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  return (
    <PageContainer title="View Abeyances/Notes" description="this is innerpage">
      <Breadcrumb title="" items={BCrumb} />

      <PageTitleBar
        heading="Abeyances/Notes"
        rightHeading={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleRedirect}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...notesTableProps} />
    </PageContainer>
  );
};
