import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../components/formsComponents";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import { BeforeCreateTypes } from "./BeforeCreate.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { fetchTasksListAsync } from "../../../redux/tasks/tasksActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { ITask } from "../../../redux/tasks/task.types";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { ViewTaskDetails } from "./ViewTaskDetails/ViewTaskDetails";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import {
  TABLE_CACHING_KEYS,
  TASK_OLD_BUSINESS,
  TASK_STATUS,
  task_status,
} from "../../../constants/constants";
import { TaskAssignedTo } from "./TaskAssignedTo";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { api } from "../../../api/api";
import { ViewOldBusinessTracker } from "./ViewOldBusinessTracker/ViewOldBusinessTracker";
import { IViewOldBusinessTrackerState } from "./ViewOldBusinessTracker/ViewOldBusinessTracker.types";
import { IOldBusinessTrackerState } from "./OldBusinessTrackerDialog/OldBusinessTrackerDialog.types";
import { OldBusinessTrackerDialog } from "./OldBusinessTrackerDialog/OldBusinessTrackerDialog";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import { fetchOldBusinessTrackerListAsync } from "../../../redux/OldBusinessTracker/oldBusinessTrackerActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";

// export interface IOldBussinesTracker {
//   customer_policy_id: number;
//   incremental_number: string | null;
//   customer_code: string;
//   customer_name: string | null;
//   driver_number: string | null;
//   driver_name: string | null;
//   license_number: string | null;
//   business_reason: string | null;
//   date_and_time: string | null;
//   days_left: string | null;
//   transaction_type: string | null;
//   report_requested: string | null;
//   years_at_this_address: string | null;
//   current_address_type: string | null;
//   current_address: string | null;
//   current_address_unit_or_suite: string | null;
//   current_address_city: string | null;
//   current_address_state_or_province: string | null;
//   current_address_postal_code: string | null;
//   current_address_country: string | null;
//   new_address_type: string | null;
//   new_address: string | null;
//   new_address_unit_or_suite: string | null;
//   new_address_city: string | null;
//   new_address_state_or_province: string | null;
//   new_address_postal_code: string | null;
//   new_address_country: string | null;
//   request_status: string | null;
//   insurer_code: string | null;
//   intact_branch: string | null;
//   number_of_disclosed_convictions: string | null;
//   policy_number: string | null;
//   policy_status_cancelled: string | null;
//   requested_by: string | null;
//   requested_by_code: string | null;
//   assigned_to: string | null;
//   branch: string | null;
//   producer: string | null;
//   csr: string | null;
//   notes: string | null;
//   file_upload: string | null;
//   file_upload_two: string | null;
//   file_type: string | null;
//   file_type_two: string | null;
//   date_written: string | null;
//   date_destroyed: string | null;
//   date_voided: string | null;
//   date_cancelled: string | null;
//   producer_one_code: string | null;
//   csr_code: string | null;
//   insurer_code_written: string | null;
//   status: string;
// }

export const OldBusinessTracker: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.oldBusinessTracker
  );

  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });

  const [openView, setOpenView] =
    React.useState<IViewOldBusinessTrackerState | null>(null);
  const [editOldBusinessTracker, setEditOldBusinessTracker] =
    React.useState<IOldBusinessTrackerState | null>(null);

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination ,masterColumnResize },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.OLD_BUSINESS_TRACKER,
    },
  });

  const fetchList = () => {
    dispatch(
      fetchOldBusinessTrackerListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["customer_name", "policy_number"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        disableResize: true,
        isFirstColumnSticky: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onViewClick={() =>
                setOpenView(row as IViewOldBusinessTrackerState)
              }
              onEditClick={() => {
                console.log(row, "rowowowow");
                setEditOldBusinessTracker(row as IViewOldBusinessTrackerState);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IViewOldBusinessTrackerState) => {
          return <LeadOppStatus status={row.status || ""} />;
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },

      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
      },
    ],
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns
    },
  };

  const handleClick = () => {
    navigate("/tasks");
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      TASK_OLD_BUSINESS,
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "old_business_tracker",
        "task_status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  return (
    <PageContainer
      title="Old Business Tracker"
      description="this is before create task"
    >
      {/* <IconicMultiColumnCard
        loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      /> */}

      <StandadCard heading="Old Business Tracker">
        <DataTableV2 {...opportunitesTableProps} />
      </StandadCard>

      {openView && (
        <ViewOldBusinessTracker
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
        />
      )}

      {editOldBusinessTracker && (
        <OldBusinessTrackerDialog
          open={true}
          onClose={() => setEditOldBusinessTracker(null)}
          data={editOldBusinessTracker}
          refreshList={fetchList}
        />
      )}
    </PageContainer>
  );
};
