import React from "react";
import { HabitationalRiskLocationForm } from "./HabitationalRiskLocation";
import { IHabitationalRiskLocation } from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocation.types";
import { RightPanel } from "../../../../../../components/RightPanel";

interface IHabitationalRiskLocationRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  onSaveSuccess: (riskLocation: IHabitationalRiskLocation) => void;
  staticStatusValue?: string;
  created_from?: string;
  readonly?: boolean;
  onClose: () => void;
  initialData?: IHabitationalRiskLocation;
  disableApiCalls?: boolean;
}

export const HabitationalRiskLocationRightPanel: React.FC<
  IHabitationalRiskLocationRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Risk Location"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="80%"
      >
        <HabitationalRiskLocationForm
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          incrementalNumber={incrementalNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          created_from={created_from}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
