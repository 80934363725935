import { IStoreState } from "../../initialStoreState";
import {
    CLEAR_HABITATIONAL_SCHEDULE_SUMMARY_STATE,
  CLEAR_HABITATIONAL_SCHEDULE_DETAIL,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_PROGRESS,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_SUCCESS,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_FAILED,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_PROGRESS,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_SUCCESS,
  FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_FAILED,

  CLEAR_HABITATIONAL_SCHEDULE_SUMMARY,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_PROGRESS,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_SUCCESS,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_FAILED,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_PROGRESS,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_SUCCESS,
  FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_FAILED,
} from "./habitationalPropertyScheduleActions";
import produce from "immer";
import { LoadState } from "../../../constants/enums";
import { HabitationalPropertyScheduleAction } from ".";
import { initialPropertyScheduleState } from "./habitationalPropertySchedule.types";

export const HabitationalPropertyScheduleReducer = (
  state: IStoreState["habitational"]["schedule"] = initialPropertyScheduleState,
  action: HabitationalPropertyScheduleAction
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.InProgress;
        draftState.schedule_detail.list = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.Loaded;
        draftState.schedule_detail.list = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.Failed;
        draftState.schedule_detail.list = [];
      });
      return newState;
    }

    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.InProgress;
        draftState.schedule_detail.data = initialPropertyScheduleState["schedule_detail"]["data"];
        draftState.schedule_detail.error = null;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.Loaded;
        draftState.schedule_detail.data = data;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_SCHEDULE_DETAIL_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.loading = LoadState.Failed;
        draftState.schedule_detail.data = initialPropertyScheduleState["schedule_detail"]["data"];
        draftState.schedule_detail.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_SCHEDULE_DETAIL: {
      const newState = produce(state, (draftState) => {
        draftState.schedule_detail.data = initialPropertyScheduleState["schedule_detail"]["data"];
      });
      return newState;
    }

    // ----------------- Schedule Summary -----------------

    case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
            
          draftState.schedule_summary.loading = LoadState.InProgress;
          draftState.schedule_summary.list = [];
        });
        return newState;
      }
      case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.loading = LoadState.Loaded;
          draftState.schedule_summary.list = data;
        });
        return newState;
      }
      case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.loading = LoadState.Failed;
          draftState.schedule_summary.list = [];
        });
        return newState;
      }
  
      case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.loading = LoadState.InProgress;
          draftState.schedule_summary.data = initialPropertyScheduleState["schedule_summary"]["data"];
          draftState.schedule_summary.error = null;
        });
        return newState;
      }
  
      case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.loading = LoadState.Loaded;
          draftState.schedule_summary.data = data;
        });
        return newState;
      }
  
      case FETCH_HABITATIONAL_SCHEDULE_SUMMARY_FAILED: {
        const { errorMessage } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.loading = LoadState.Failed;
          draftState.schedule_summary.data = initialPropertyScheduleState["schedule_summary"]["data"];
          draftState.schedule_summary.error = errorMessage;
        });
        return newState;
      }
  
      case CLEAR_HABITATIONAL_SCHEDULE_SUMMARY: {
        const newState = produce(state, (draftState) => {
          draftState.schedule_summary.data = initialPropertyScheduleState["schedule_summary"]["data"];
        });
        return newState;
      }

    case CLEAR_HABITATIONAL_SCHEDULE_SUMMARY_STATE: {
      return initialPropertyScheduleState;
    }
    default: {
      return state;
    }
  }
};
