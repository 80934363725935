import { Box, Button, Typography, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useEffect, useState } from "react";

const ThankYou: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10); // Initial countdown value

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      window.location.href = "https://www.aaxel.ca/join-our-team-2/"; // Redirect after 5 seconds
    }, 10000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        backgroundColor: "#F9F9F9",
        padding: "20px",
      }}
    >
      <Card
        sx={{
          padding: "50px",
          textAlign: "center",
          borderRadius: "15px",
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Envelope Icon */}
        <MailOutlineIcon
          sx={{
            fontSize: "60px",
            color: "purple",
            mb: 2,
          }}
        />

        {/* Thank You Text */}
        <Typography variant="h3" fontWeight="bold" color="primary" gutterBottom>
          🎉 Thank You!
        </Typography>
        <Typography
          variant="h5"
          fontWeight="medium"
          color="textSecondary"
          gutterBottom
        >
          Your application has been submitted successfully.
        </Typography>

        {/* Countdown Timer */}
        <Typography variant="body1" fontWeight="bold" color="secondary" mt={2}>
          Redirecting in{" "}
          <span style={{ fontSize: "1.5rem", color: "red" }}>{countdown}</span>{" "}
          seconds...
        </Typography>

        {/* Manual Redirect Button */}
        <Button
          variant="contained"
          sx={{
            mt: 3,
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            borderRadius: "30px",
            textTransform: "none",
            fontWeight: "bold",
            background: "linear-gradient(135deg, #FF00FF, #007BFF)",
            color: "white",
            "&:hover": {
              background: "linear-gradient(135deg, #E600E6, #0066CC)",
            },
          }}
          onClick={() =>
            (window.location.href = "https://www.aaxel.ca/join-our-team-2/")
          }
        >
          Go Home Now
        </Button>
      </Card>
    </Box>
  );
};

export default ThankYou;
