import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { IntrestedPartiesActions } from ".";
import { initialIntrestedPartiesState } from "./IntrestedParties.types";
import {
  ADD_INTERESTED_PARTY,
  ADD_INTERESTED_PARTY_PROGRESS,
  CLEAR_INTERESTED_PARTY_STATE,
  FETCH_INTERESTED_PARTY_LIST_FAILED,
  FETCH_INTERESTED_PARTY_LIST_PROGRESS,
  FETCH_INTERESTED_PARTY_LIST_SUCCESS,
  FETCH_INTERESTED_PARTY_PROGRESS,
  INTERESTED_PARTY_ERROR,
} from "../constants";
import {
  FETCH_INTRESTED_PARTIES_LIST_FAILED,
  FETCH_INTRESTED_PARTIES_LIST_PROGRESS,
  FETCH_INTRESTED_PARTIES_LIST_SUCCESS,
} from "./IntrestedPartiesActions";

export const IntrestedPartiesReducer = (
  state: IStoreState["borkerage"]["intrestedParties"] = initialIntrestedPartiesState,
  action: IntrestedPartiesActions
) => {
  switch (action.type) {
    case FETCH_INTERESTED_PARTY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.loading = LoadState.InProgress;
        draftState.intrestedParties.list = [];
      });
      return newState;
    }
    case FETCH_INTERESTED_PARTY_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.loading = LoadState.Loaded;
        draftState.intrestedParties.list = list;
      });
      return newState;
    }
    case FETCH_INTERESTED_PARTY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.loading = LoadState.InProgress;
        draftState.intrestedParties.list = [];
      });
      return newState;
    }
    case ADD_INTERESTED_PARTY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.saveLoading = true;
      });
      return newState;
    }
    case ADD_INTERESTED_PARTY: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.loading = LoadState.Loaded;
        draftState.intrestedParties.saveLoading = false;
        draftState.intrestedParties.data = data;
      });
      return newState;
    }

    case FETCH_INTERESTED_PARTY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.loading = LoadState.InProgress;
      });
      return newState;
    }

    case INTERESTED_PARTY_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.error = action.payload.message;
        draftState.intrestedParties.saveLoading = false;
        draftState.intrestedParties.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_INTRESTED_PARTIES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.listLoading = LoadState.InProgress;
        draftState.intrestedParties.list = [];
        // draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_INTRESTED_PARTIES_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.listLoading = LoadState.Loaded;
        draftState.intrestedParties.list = data;
        // draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INTRESTED_PARTIES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.intrestedParties.listLoading = LoadState.InProgress;
        draftState.intrestedParties.list = [];
        // draftState.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_INTERESTED_PARTY_STATE: {
      return initialIntrestedPartiesState;
    }

    default: {
      return state;
    }
  }
};
