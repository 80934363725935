import { Payment } from "@mui/icons-material";

export const INSURANCE_REF_DATA = {
  quotesFor: {
    "Auto Insurance": [
      "Automobile Insurance",
      "Commercial Automobile Insurance",
      "Motorcycle Insurance",
      "Bundle Insurance (Home+Auto)",
      "Classic Car Insurance",
      "Boat Insurance",
      "RV Insurance",
      "Private Client Insurance",
      "Snowmobile Insurance",
    ],
    "Commercial Automobile": ["Fleet", "IRCA", "Garage"],
    "Home Insurance": [
      "Home Insurance",
      "Rental Property Insurance",
      "Tenant Insurance",
      "Condo Insurance",
      "Cottage Insurance",
      "Bundle Insurance (Home+Auto)",
    ],
    "Bundle Insurance (Home+Auto)": [
      "Auto + Home Insurance",
      "Auto + Condo Insurance",
      "Auto + Tenant Insurance",
    ],
    "Business Insurance": [
      "Account Receivable Insurance",
      "Commercial Automobile Insurance",
      "General Liability Insurance",
      "Employee Liability Insurance",
      "Product Liability Insurance",
      "Professional Liability Insurance",
      "Commercial Property Insurance",
      "Contractors Insurance",
      "Corporate Insurance",
      "Cyber Liability Insurance",
      "Environmental Impairment Liability Insurance",
      "Home Based Business Insurance",
      "Small Medium Business Insurance",
      "Legal Expense Insurance",
      "Malpractice Insurance",
      "Private Client Insurance",
      "Specialty Insurance",
      "Garage Insurance",
      "Builder's Risk Insurance",
    ],
    "Life & Financial": [
      "Life Insurance",
      "Critical Illness Insurance",
      "Disability Insurance",
      "Mortgage Insurance",
      "Travel Insurance",
      "Long Term Care Insurance",
      "Group Benefits",
      "Investments",
    ],
  },
  dbMaps: {
    "Auto Insurance": "auto_insurance_type",
    "Home Insurance": "home_insurance_type",
    "Bundle Insurance (Home+Auto)": "bundle_insurance_type",
    "Business Insurance": "business_insurance_type",
    "Life & Financial": "life_financial_type",
  },
};

export const contactTimes = [
  { text: "Anytime Contact You", value: 1 },
  { text: "7:00 am to 9:00 am", value: 2 },
  { text: "9:00 am to 11:30 am", value: 3 },
  { text: "11:30 am to 1:00 pm", value: 4 },
  { text: "1:00 pm to 3:00 pm", value: 5 },
  { text: "3:00 pm to 5:00 pm", value: 6 },
  { text: "5:00 pm to 8:00 pm", value: 7 },
];

export const lineofBusiness = {
  Business: {
    list: [
      "Business",
      "Automobile",
      "Habitational",
      "Commercial Auto",
      "Life & Financial",
    ],
    subTypes: [
      "Retail",
      "Wholesale",
      "Garage",
      "Manufacturer",
      "Contractor",
      "Professional Services",
      "Office",
      "Restaurant & Hospitality",
      "Errors & Omissions",
      "CMP",
      "Cyber Liability",
      "Environment Liability",
      "CGL",
    ],
  },
  automobile: {
    list: [
      "Business",
      "Automobile",
      "Habitational",
      "Commercial Auto",
      "Life & Financial",
    ],
    subTypes: [
      "Retail",
      "Wholesale",
      "Garage",
      "Manufacturer",
      "Contractor",
      "Professional Services",
      "Office",
      "Restaurant & Hospitality",
      "Errors & Omissions",
      "CMP",
      "Cyber Liability",
      "Environment Liability",
      "CGL",
    ],
  },
};

export const VEHICLE_VALIDATION_MESSAGES = [
  { key: "year", message: "*Year is required." },
  { key: "make", message: "*Make is required." },
  { key: "model", message: "*Model is required." },
  { key: "owned", message: "*Owned is required." },
  { key: "leased", message: "*Leased is required." },
  { key: "puchase_condition", message: "*Purchase Condition is required." },
  { key: "purchase_price", message: "*Purchase Price is required." },
  { key: "body_type", message: "*Body Type is required." },
  { key: "gross_vehicle_weight", message: "*GVW is required." },
  { key: "fuel_type", message: "*Fuel Type is required." },
  { key: "purchase_country", message: "*Purchase Country is required." },
  {
    key: "vehicle_registration_province",
    message: "*Vehicle Registration is required.",
  },
  { key: "vehicle_use", message: "*Vehicle Use is required." },
  { key: "annual_distance", message: "*Annual distance is required." },
  { key: "commute", message: "*Commute One Way is required." },
  {
    key: "driver_assignment_primary",
    message: "*Primary Assignment is required.",
  },
  { key: "anti_theft_devices", message: "*Anti-Theft Devices is required." },
  {
    key: "anti_theft_engraving_dev_mfr_cd",
    message: "*Anti-Theft Engraving Device is required.",
  },
  { key: "device_type_code_one", message: "*Device Type Code is required." },
  { key: "vehicle_type", message: "*Vehicle Type is required." },
  { key: "purchase_date", message: "*Purchase Date is required." },
  { key: "vehicle_code_one", message: "*Vehicle Code is required." },
  {
    key: "rate_groups_or_class_coll_or_ap",
    message: "*Rate Groups or Class Coll or AP is a required.",
  },
  {
    key: "rate_groups_or_class_comp_or_sp",
    message: "*Rate Groups or Class Comp or SP is a required.",
  },
  {
    key: "rate_groups_or_class_ab",
    message: "*Rate Groups or Class AB is a required.",
  },
  {
    key: "rate_groups_or_class_dcpd",
    message: "*Rate Groups or Class DCPD is a required.",
  },
  {
    key: "rate_groups_or_class_tp",
    message: "*Rate Groups or Class TP is a required.",
  },
  {
    key: "rate_groups_or_class_rating_class",
    message: "*Rate Groups or Class Rating Class is a required.",
  },
];
export const VEHICLE_DIALOG_MESSAGES = [
  { key: "year", message: "*Year is required." },
  { key: "make", message: "*Make is required." },
  { key: "model", message: "*Model is required." },
  { key: "owned", message: "*Owned is required." },
  { key: "leased", message: "*Leased is required." },
  
  { key: "body_type", message: "*Body Type is required." },
  { key: "gross_vehicle_weight", message: "*GVW is required." },
  { key: "fuel_type", message: "*Fuel Type is required." },
  {
    key: "vehicle_registration_province",
    message: "*Vehicle Registration is required.",
  },
  { key: "vehicle_use", message: "*Vehicle Use is required." },
  { key: "annual_distance", message: "*Annual distance is required." },
  { key: "commute", message: "*Commute One Way is required." },
  {
    key: "anti_theft_engraving_dev_mfr_cd",
    message: "*Anti-Theft Engraving Device is required.",
  },
  { key: "device_type_code_one", message: "*Device Type Code is required." },
  { key: "vehicle_type", message: "*Vehicle Type is required." },
  { key: "purchase_date", message: "*Purchase Date is required." },
  { key: "vehicle_code_one", message: "*Vehicle Code is required." },
  { key: "odometer", message: "*Odometer is required." },
  { key: "annual_kms", message: "*Annual Kms is required." },
  {
    key: "rate_groups_or_class_coll_or_ap",
    message: "*Rate Groups or Class Coll or AP is a required.",
  },
  {
    key: "rate_groups_or_class_comp_or_sp",
    message: "*Rate Groups or Class Comp or SP is a required.",
  },
  {
    key: "rate_groups_or_class_ab",
    message: "*Rate Groups or Class AB is a required.",
  },
  {
    key: "rate_groups_or_class_dcpd",
    message: "*Rate Groups or Class DCPD is a required.",
  },
  {
    key: "rate_groups_or_class_tp",
    message: "*Rate Groups or Class TP is a required.",
  },
  {
    key: "rate_groups_or_class_rating_class",
    message: "*Rate Groups or Class Rating Class is a required.",
  },
];
export const VEHICLE_INTEREST_MESSAGES = [
  {
    key: "existing_unrepaired_damage_description",
    message: "*Damage Description is required.",
  },
  {
    key: "existing_unrepaired_damage",
    message: "*Existing Unrepaired Damage is required.",
  },
  {
    key: "vehicle_used_in_car_pools",
    message: "*Vehicle Used in Car Pools is required.",
  },
  {
    key: "vehicle_equipped_with_winter_tires",
    message: "*Vehicle Equiped With Winter Trees is required.",
  },
  {
    key: "explosive_or_radioactive_material",
    message: "*Explosive or Radioactive Material is required.",
  },
  {
    key: "carry_goods_for_compensation",
    message: "*Carry Goods for Compensation is required.",
  },
  { key: "interest", message: "*Interest is required." },
  { key: "annual_kms", message: "*Annual Kms is required." },
];

export const DRIVER_VALIDATION_MESSAGES = [
  { key: "driver_name", message: "Driver Name is required!" },
  { key: "given_name", message: "First Name is required!" },
  { key: "surname", message: "Last Name is required!" },
  { key: "date_of_birth", message: "Date of Birth is required!" },
  { 
    key: "age", 
    message: "Age is required!", 
    validate: (value: any) => isNaN(Number(value)) || Number(value) <= 0 ? "Please enter a valid age!" : null 
  },
  { key: "gender", message: "Gender is required!" },
  { key: "marital_status", message: "Marital Status is required!" },
 
  { 
    key: "license_number", 
    message: "License Number is required!", 
    validate: (value: any) => value && !/^[A-Z0-9]{5,15}$/.test(value) 
      ? "License Number must be between 5-15 characters (uppercase letters & numbers)." 
      : null 
  },
  {
    key: "expiry_date_of_license",
    message: "Expiry Date of License is required!",
  },
  { key: "province", message: "Province is required!" },
  { key: "license_status", message: "License Status is required!" },
  { key: "mvr_status", message: "MVR Status is required!" },
  { key: "mvr_type", message: "MVR Type is required!" },
  { key: "address", message: "Address is required!" },
  { key: "city", message: "City is required!" },
  { key: "postal_code", message: "Postal Code is required!" },
  { key: "state_or_province", message: "Province/State is required!" },
  { key: "last_mvr_date", message: "Last MVR Date is required!" },
  {
    key: "relationship_to_applicant",
    message: "Relationship to Applicant is required!",
  },
  {
    key: "continuously_insured_since",
    message: "Continuously Insured Since is required!",
  },
  { key: "hire_date", message: "Hire Date is required!" },
  { key: "termination_date", message: "Termination Date is required!" },
  {
    key: "insured_as_principal_operator_since",
    message: "Insured as Principal Operator Since is required!",
  },
  {
    key: "driver_occupation_code",
    message: "Driver Occupation Code is required!",
  },
  {
    key: "claims_experience_letter_date",
    message: "Claim Experience Letter Date is required!",
  },

  // license history

  { key: "license_history[].class", message: "License Class is required!" },
  {
    key: "license_history[].date_first_licensed",
    message: "Date First Licensed is required!",
  },
  
  {
    key: "license_history[].current_license_country",
    message: "Current License Country is required!",
  },
  {
    key: "license_history[].province_or_state",
    message: "Province/State is required!",
  },
  {
    key: "license_history[].license_number",
    message: "License Number is required!",
  },
  {
    key: "license_history[].license_type",
    message: "License Type is required!",
  },
  {
    key: "license_history[].license_status",
    message: "License Status is required!",
  },
];

export const DRIVER_DIALOG_MESSAGES = [
  { key: "given_name", message: "First Name is required!" },
  { key: "surname", message: "Last Name is required!" },
  { key: "date_of_birth", message: "Date of Birth is required!" },
  { 
    key: "age", 
    message: "Age is required!", 
    validate: (value: any) => isNaN(Number(value)) || Number(value) <= 0 ? "Please enter a valid age!" : null 
  },
  { key: "gender", message: "Gender is required!" },
  { key: "marital_status", message: "Marital Status is required!" },
 
  { 
    key: "license_number", 
    message: "License Number is required!", 
    validate: (value: any) => value && !/^[A-Z0-9]{5,15}$/.test(value) 
      ? "License Number must be between 5-15 characters (uppercase letters & numbers)." 
      : null 
  },
  { key: "address", message: "Address is required!" },
  { key: "city", message: "City is required!" },
  { key: "postal_code", message: "Postal Code is required!" },
  { key: "state_or_province", message: "Province/State is required!" },
  {
    key: "relationship_to_applicant",
    message: "Relationship to Applicant is required!",
  },

  // license history

  { key: "license_history[].class", message: "License Class is required!" },
  {
    key: "license_history[].date_first_licensed",
    message: "Date First Licensed is required!",
  },
  
  {
    key: "license_history[].current_license_country",
    message: "Current License Country is required!",
  },
  {
    key: "license_history[].province_or_state",
    message: "Province/State is required!",
  },
  {
    key: "license_history[].license_number",
    message: "License Number is required!",
  },
  {
    key: "license_history[].license_type",
    message: "License Type is required!",
  },
  {
    key: "license_history[].license_status",
    message: "License Status is required!",
  },
];

export const transactionTypes = [
  "New",
  // "Rewrite",
  "Remarket",
  // "Renewal",
  // "Reissue",
];

export const Template = [
  "Automobile",
  "Business",
  "Commercial Auto",
  "Habitational",
  "Life & Financial",
  "Farm",
  "Travel",
];
export const LineOfBusiness = [
  "Automobile",
  "Commercial Auto",
  "Home",
  "Tenants",
  "Condo",
  "CGL",
  "CMP",
  "E&O",
  "Garage",
];
export const pleasureBusiness = ["Pleasure", "Business"];

export const segment = [
  "Business & Professional Services",
  "Contractors",
  "Retail and Automotive",
  "Realty",
  "Hospitality and Restaurant",
  "Health Services",
  "Manufacturing/Processing",
  "Wholesale",
  "Warehousing",
  "Transportation",
  "Education",
  "Farming",
  "Government",
  "Member Organizations",
  "Mining",
  "Recreation",
  "Utilities",
];

export const automobileSegment = [
  "Auto & Home",
  "Auto, Home & Investment Property",
  "Auto & Condo",
  "Auto & Tenant",
];

export const typeOfPolicy = ["Individual", "Bordereau"];
export const cancellationReason = [
  "AC: Agent/Company Termination (Non renewal Only)",
  "CL: Cancelled for Policy Lapse",
  "CM: Cancelled for Material Misrepresentation",
  "CS: Cancelled Due to License Suspension",
  "DW: Driving Without Insurance",
  "IR: Insured's Request",
  "NP: Non-payment",
  "NT: Not Taken",
  "NV: No Vehicle, No Insurance Required",
  "OT: Other",
  "PR: Payment Received",
  "RW: Rewritten",
  "UR: Underwriting Reasons",
  "RM: Remarketed",
];

export const policyStatus = [
  "Cancelled",
  "Cancellation Pending",
  "Electronic Document Transfer",
  "Expired Policy Term",
  "Incomplete",
  "Lapsed",
  "Lapse Pending",
  "New Business (Active/In Force)",
  "New Business (Bound/Awaiting Issue)",
  "New Business (Quoted)",
  "New Business (Issued)",
  "New Business (Not Quoted)",
  "Policy Change (Draft)",
  "Policy Change (Quoted)",
  "Policy Change/Endorsement",
  "Renewed (Issued)",
  "Reinstated (Active/In Force)",
  "Renewed (Active/In Force)",
  "Renewal (Quoted)",
  "Reinstatement (Draft)",
  "Reinstatement (Quoted)",
  "Renewing (Draft)",
  "Locked/Suspended",
  "Withdrawn",
  "Cancellation (Flat)",
];

export const cancelType = ["Short-Rate", "Flat", "Pro-Rata"];

export const current_status = [
  "Cancelled by Insured",
  "Coverage Not Required",
  "Declined by Insurer",
  "In-force",
  "Non Renewed by Insurer",
  "Remarketed on Insured Request",
];

export const payment_method = [
  "1 Non Payment Cancellation in the last 5 years",
  "More than 1 Non Payment Cancellation in the last 3 years",
  "More than 2 Non Payment Cancellation in the last 5 years",
  "No, Non Payment Cancellation in the last 3 years",
];

export const form_of_buisness = [
  { label: "Corporation", value: "Corporation" },
  { label: "Association", value: "Association" },
  { label: "Charity , Non-registered", value: "Charity , Non-registered" },
  { label: "Charity , Registered", value: "Charity , Registered" },
  { label: "Church", value: "Church" },
  { label: "City", value: "City" },
  { label: "Country", value: "Country" },
  { label: "Estate of", value: "Estate of" },
  { label: "For-Profit Organization", value: "For-Profit Organization" },
  { label: "Government Agency", value: "Government Agency" },
  { label: "Hospital", value: "Hospital" },
  { label: "Individual", value: "Individual" },
  { label: "Joint Venture", value: "Joint Venture" },
  { label: "Limited Liability Company", value: "Limited Liability Company" },
  { label: "Municipality", value: "Municipality" },
  { label: "Non-Profit Organization", value: "Non-Profit Organization" },
  { label: "Non-Profit Association", value: "Non-Profit Association" },
  { label: "Organization", value: "Organization" },
  { label: "Other", value: "Other" },
  {
    label: "Partnership or Limited Partnership",
    value: "Partnership or Limited Partnership",
  },
  { label: "Proprietorship", value: "Proprietorship" },
  { label: "Region", value: "Region" },
  { label: "School", value: "School" },
  { label: "School Board", value: "School Board" },
  { label: "Select One", value: "Select One" },
  { label: "Town", value: "Town" },
  { label: "Township", value: "Township" },
  { label: "Trust", value: "Trust" },
  { label: "Unicorporated Association", value: "Unicorporated Association" },
  { label: "Village", value: "Village" },
];

export const knownToBroker = [
  "1931",
  "1950",
  "1958",
  "1960",
  "1965",
  "1967",
  "1968",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const yearsList = [
  "1931",
  "1950",
  "1958",
  "1960",
  "1965",
  "1967",
  "1968",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const intertestedPartyCode = [
  { label: "ACO", value: "1a61ab41-f3e9-065c-afed-62e9046ee83c" },
  { label: "ATT", value: "295df6a8-ea9f-a606-10c8-62e904aee620" },
  { label: "VFS", value: "3a507279-69cd-d1e7-67b2-62e904704ab5" },
  { label: "AEC", value: "4ae2e88c-2432-db46-89e2-62e9048c1877" },
  { label: "BOD", value: "705c64d4-71a8-9c41-a747-62e904124c7f" },
  { label: "BRO", value: "7991f741-2ed9-aea5-67bd-62e904378c0e" },
  { label: "FAU", value: "83808a11-09ea-e171-cedd-62e9045b85f3" },
  { label: "GTA", value: "8d36cde9-51ce-efae-b2ff-62e904c09779" },
  { label: "HER", value: "96b5695e-eb31-e9db-f409-62e904ae956c" },
  { label: "INN", value: "a09011bd-c587-50cc-4b08-62e904d0e57c" },
  { label: "PEL", value: "aa29aaae-7b44-dac0-a11c-62e904f858ce" },
  { label: "MAP", value: "b41a829f-aa22-3abe-da71-62e9041a631a" },
  { label: "RED", value: "be158033-9d1a-3544-9ef8-62e904675a40" },
  { label: "RCA", value: "c836bd01-0df0-8301-ca5b-62e904b3d0b8" },
  { label: "SMU", value: "d25b2a17-3fcc-4749-4c07-62e904253fa0" },
  { label: "BRA", value: "dcd73ff0-c449-7b84-9fa5-62e90426c42e" },
  { label: "NUC", value: "e7615a9f-537b-778f-8ffd-62e904010a49" },
  { label: "WE1", value: "f1ef26ff-953a-ef50-fd87-62e904c7f883" },
  { label: "WES", value: "8907052b-f6a9-1a9d-dd24-62e904432880" },
  { label: "WOO", value: "135dfb4f-77b3-f208-afa9-62e9047596d7" },
  { label: "YOR", value: "1e2a09af-aea4-8fe7-e0cc-62e9047edbe3" },
  { label: "CNE", value: "292eb9f0-c214-09e0-10d3-62e904ca8c69" },
  { label: "728", value: "342c128e-b476-c1d6-b322-62e90496cde6" },
  { label: "AON", value: "3f35c066-84e0-ad8c-4ad7-62e9041a83cd" },
  { label: "ASC", value: "4a5900a3-5154-e915-ec16-62e904ef315e" },
  { label: "BM", value: "55cf2df7-f21a-6123-9cdf-62e90432a65e" },
  { label: "BOM", value: "615ddd28-e566-a6ed-b6a2-62e9045ceb16" },
  { label: "BMO", value: "6ccb4eba-4071-8823-e1ee-62e9048e550a" },
  { label: "BM2", value: "78aa2091-7524-58ff-a8c4-62e9045e75cd" },
  { label: "BM3", value: "84576fdc-7501-0dde-346c-62e904cd91a8" },
  { label: "BM4", value: "90639b43-d1e0-dcad-dbef-62e904c81f79" },
  { label: "BEN", value: "9e9ebc99-dc0f-d8c4-206f-62e9046abe6b" },
  { label: "BCU", value: "aaf573ac-aa09-b3ab-4bb0-62e90401a8c4" },
  { label: "BDC", value: "b85bbf0c-c017-0cb3-56ed-62e90414a200" },
  { label: "BD1", value: "c518671f-24d0-ae42-df53-62e904deadc5" },
  { label: "BDB", value: "d1f3554b-7887-d550-e9e6-62e9043a1e75" },
  { label: "CT", value: "dee04942-7b7a-a4a0-afaf-62e9048b6abb" },
  { label: "CIB", value: "ebf9bf33-f4ea-1f7a-5c26-62e904a8920b" },
  { label: "FC", value: "54ce02d8-71cf-d80f-a8aa-62e904bebfd9" },
  { label: "CC", value: "13646af3-4614-6049-c6e9-62e9041bc2f8" },
  { label: "CHR", value: "214a87ce-2c88-a501-341a-62e9045d3ef8" },
  { label: "CITI", value: "2f4856e9-3dc0-c3ef-f703-62e904b6ec0c" },
  { label: "CMF", value: "3df48e03-1c1e-c2d9-8256-62e90447868f" },
  { label: "CCL", value: "4c91fae2-7c52-edbe-a1b7-62e904dd6210" },
  { label: "LES", value: "5b6616be-0320-8865-0408-62e904d44942" },
  { label: "DRE", value: "6a58297a-63a0-a364-f4b9-62e90414bcbb" },
  { label: "FIN", value: "796798c5-53c5-2fd3-f8d6-62e904e78929" },
  { label: "FDG", value: "887f250b-b4e0-1be6-f718-62e90425cff1" },
  { label: "FLM", value: "97b88393-087f-3d7f-db5b-62e904ebd9c5" },
  { label: "FNF", value: "a757710e-5511-13cd-e2ee-62e904a11ea1" },
  { label: "FIR", value: "b71002cb-4752-4eab-4ade-62e904d70b40" },
  { label: "FD", value: "c7488805-7424-7ec0-9a65-62e904ac915b" },
  { label: "FRD", value: "d7a2df6b-0f62-c094-6e99-62e90469c364" },
  { label: "FOR", value: "e8515aa8-4142-8497-5fe0-62e90401f052" },
  { label: "GA", value: "4e110f53-52c4-c43a-f425-62e904cf0c56" },
  { label: "GE", value: "162d631c-d780-2043-4819-62e9048bb56c" },
  { label: "GMA", value: "2753ae42-5283-32e9-b1a1-62e9045d06f9" },
  { label: "GMC", value: "39048697-8b68-73e9-f129-62e90481048e" },
  { label: "GM", value: "4a9b467e-9572-7e57-391e-62e904ec71f6" },
  { label: "GF", value: "5c47a70c-37d0-0cda-7e6f-62e904aeff8b" },
  { label: "QUE", value: "6cf46c12-4b3b-08a5-ab07-62e904210501" },
  { label: "HOM", value: "7ec0d768-4a51-35f7-a4fe-62e9044af685" },
  { label: "HCF", value: "90ff49a5-fc69-0264-d0b3-62e904d5b332" },
  { label: "HON", value: "a37e6fad-8ea6-62ca-12df-62e904a31e95" },
  { label: "HYU", value: "b683e4f5-f83f-906b-edc2-62e9049c65a0" },
  { label: "LA", value: "c90b417a-6304-7874-5c19-62e904fdd479" },
  { label: "ING", value: "dc6b2705-ae86-a5e5-7039-62e9047eb436" },
  { label: "IRW", value: "f0927d8b-7e4e-e0ee-6591-62e9049c8a2c" },
  { label: "LEA", value: "27ecca42-ca14-41d4-0848-62e904425f80" },
  { label: "LIT", value: "31b1d456-954e-ea3b-eca0-62e90440a94a" },
  { label: "MGD", value: "3b596b83-5c54-5706-3e21-62e904694465" },
  { label: "MAN", value: "4538c60c-c367-89c9-7171-62e904e49f82" },
  { label: "MTO", value: "4ef28b0d-efc7-d991-90d8-62e904490dd5" },
  { label: "MC", value: "58d74d2b-60f3-a651-6df3-62e904d635e3" },
  { label: "MZ", value: "62d10493-4c0d-eeac-a30e-62e904302902" },
  { label: "MCA", value: "6c2cae13-b247-f760-b66f-62e904436b67" },
  { label: "MER", value: "7671b272-5739-7bec-5137-62e9041903fe" },
  { label: "MT", value: "80b19c12-6337-826a-8a88-62e904406b5b" },
  { label: "MIL", value: "8afa5bef-25ef-3480-3ab5-62e904a7563a" },
  { label: "MVL", value: "956e2e10-fe41-2951-71d1-62e9042b2394" },
  { label: "NAT", value: "a0266f44-5de4-8061-7920-62e9048026b1" },
  { label: "NT", value: "aaf77896-1579-e8bf-b39d-62e904c4719d" },
  { label: "NC", value: "b672b64d-a3d4-f03c-a7b2-62e904a1e5d2" },
  { label: "N/A", value: "c1055f6d-97d4-687e-8c22-62e9046b6ada" },
  { label: "ORC", value: "cc40a783-3e6a-81a1-3e93-62e904308add" },
  { label: "ORF", value: "d7ef1475-35c0-d713-b06e-62e904116592" },
  { label: "PAC", value: "e35f7a35-7f3f-bccd-ab6d-62e9041f6fb0" },
  { label: "PET", value: "eea44155-13d1-6e3a-9ccd-62e9049e5e44" },
  { label: "POR", value: "63730d0e-9db9-f7a6-7bd0-62e9046ce13c" },
  { label: "PRI", value: "12c8193d-5c0b-7f14-a23f-62e9043176c5" },
  { label: "PRO", value: "1f2b19fc-bcd7-e10b-dc6e-62e90426066d" },
  { label: "REG", value: "2b6cc49b-6f8e-88db-5de8-62e90448a2c1" },
  { label: "RIO", value: "3757e2d6-7d8e-1c09-bdb7-62e9048bea59" },
  { label: "RB", value: "43625193-5d3d-8e63-81f4-62e904877c7e" },
  { label: "RT", value: "4f985c9f-5e3d-74b2-2d72-62e904499b86" },
  { label: "SBM", value: "5cf14ba8-725c-d51c-fd05-62e90427ba88" },
  { label: "NS", value: "6a5e8030-81da-81a1-b504-62e904789d67" },
  { label: "SMC", value: "77af0057-25b9-9c73-8e39-62e904820842" },
  { label: "SCO", value: "84dfd7ce-9d5a-1cc8-5888-62e9042d8e41" },
  { label: "1*", value: "942c1c4c-65ec-71f9-8b24-62e904558e03" },
  { label: "PIZ", value: "29665c78-dd19-abcb-0c47-62e904b0e3f2" },
  { label: "TDF", value: "38499309-0bd0-6a7e-893a-62e904112200" },
  { label: "TD", value: "452bcea9-4e99-ddbf-527b-62e904b351a5" },
  { label: "COR", value: "52617d4f-12d1-a1b6-65cc-62e904d41789" },
  { label: "EQU", value: "5f944881-0218-520a-051b-62e9042b8df8" },
  { label: "RB2", value: "6cf516bf-b8a7-1d36-2e79-62e9044911ee" },
  { label: "RB3", value: "7a2a41ea-924c-0b83-9c80-62e9043c0100" },
  {
    label: "TD1-4720 TAHOE BLVD",
    value: "883df914-1dd4-1fec-e2a9-62e9040d2a6f",
  },
  { label: "TOY", value: "9602fbff-1be3-8623-9d41-62e9049a4b6a" },
  { label: "VER", value: "a44e7712-71c3-fed5-4c6c-62e904580951" },
  { label: "VWC", value: "b2bad485-ab8e-f2ab-ed68-62e904572e1a" },
  { label: "VW1", value: "c12c815c-eb70-31fb-7977-62e904b6c2ed" },
  { label: "VW3", value: "d001ee27-4e6f-32cf-6f0a-62e904929a2e" },
  { label: "NEW", value: "dfd75af5-0ba5-2e01-1727-62e904933b8f" },
  { label: "MEREDIAN", value: "ef171d12-ead3-1695-4198-62e904d57c87" },
  { label: "SCOTIA-Calgary", value: "a35007d6-e018-109f-358c-62e9048cdde3" },
  { label: "NISSAN-ORBITOR DR", value: "195783b9-c8c6-be48-49b1-62e904e1774d" },
  { label: "NONE", value: "28076755-610a-5527-60d7-62e904510c76" },
  { label: "Wells", value: "38f8d623-5a7b-0f2d-1e33-62e904a9466e" },
  { label: "CIT", value: "488b47a9-cbde-4655-dbf8-62e90439a947" },
  { label: "TRA", value: "58b9ec94-143b-2076-cb4d-62e9043c22c9" },
  {
    label: "TD-Milverton Drive",
    value: "7972b97d-e20a-2123-58d8-62e90423034e",
  },
  { label: "Miscellaneous", value: "8962f313-4e5e-dff9-5426-62e9041ec5cc" },
];

export const TabSelectOptions = {
  "New (Upload)": [
    "Personal Auto",
    "Home",
    "Personal Auto & Home",
    "Business",
    "Commercial Auto",
  ],
  "Remarket (Upload)": [
    "Personal Auto",
    "Home",
    "Personal Auto & Home",
    "Business",
    "Commercial Auto",
  ],
  "Policy Change": [
    "General Change",
    "Vehicle Change",
    "Driver Change",
    "Coverage Change",
    "Garage Plate Change",
    "Interested Party Change",
    "Address Change",
    "Risk Location Change",
  ],
  "Payment Method": ["Payment Authorization Change"],

  "LOA/BOR": ["LOA/BOR"],
  "Letter of Experience": ["Letter of Experience"],
  "Cancellation/Reinstatement": ["Cancellation/Reinstatement"],
  "Quote Request": ["Quote Request"],
  "Certificate of Insurance": ["Certificate of Insurance"],
  // Other: ["Other"],
};

const commonSubTypeOptions = [
  { label: "Tax", value: "tax" },
  { label: "Customer", value: "customer" },
  { label: "Commission", value: "commission" },
  { label: "Insurer", value: "insurer" },
  { label: "Producer", value: "producer" },
  { label: "CSR", value: "csr" },
  { label: "Branch", value: "branch" },
  { label: "Other Vendor", value: "all" },
];

export const SubTypeOptions = {
  AR: [
    { label: "Customer", value: "customer" },
    { label: "Insurer", value: "insurer" },
    { label: "Producer", value: "producer" },
    { label: "Branch", value: "branch" },
    { label: "Other Vendor", value: "all" },
  ],
  AP: [
    { label: "Tax", value: "tax" },
    { label: "Customer", value: "customer" },
    { label: "Commission", value: "commission" },
    { label: "Insurer", value: "insurer" },
    { label: "Producer", value: "producer" },
    { label: "Branch", value: "branch" },
    { label: "Other Vendor", value: "all" },
  ],
  GL: [
    { label: "Insurer", value: "insurer" },
    { label: "Producer", value: "producer" },
    { label: "Branch", value: "branch" },
  ],
  SC: [
    { label: "Insurer", value: "insurer" },
    { label: "Producer", value: "producer" },
    { label: "Branch", value: "branch" },
  ],
  ST: [{ label: "Agency Bill Sales Tax", value: "agency_bill_sales_tax" }],
};

export const HabitationalPCRSubTypeOptions = {
  GeneralChange: [{ label: "General Change", value: "General Change" }],

  PolicyChange: [
    { label: "Name Change", value: "Name Change" },
    { label: "Address Change", value: "Address Change" },
    { label: "Risk Location Change", value: "Risk Location Change" },
    { label: "Coverage Change", value: "Coverage Change" },
    { label: "Interested Party Change", value: "Interested Party Change" },
    { label: "Schedule Item Change", value: "Schedule Item Change" },
  ],
  New: [
    { label: "Home (Upload)", value: "Home (Upload)" },
    { label: "Home (Remarket)", value: "Home (Remarket)" },
  ],
  PaymentMethod: [
    { label: "Payment Method Change", value: "Payment Method Change" },
  ],
};

export const BusinessPolicyChangeSubTypeOptions = {
  GeneralChange: [{ label: "General Change", value: "General Change" }],

  PolicyChange: [
    { label: "Name Change", value: "Name Change" },
    { label: "Address Change", value: "Address Change" },
    { label: "Risk Location Change", value: "Risk Location Change" },
    { label: "Coverage Change", value: "Coverage Change" },
    { label: "Interested Party Change", value: "Interested Party Change" },
  ],
  New: [
    { label: "Home (Upload)", value: "Home (Upload)" },
    { label: "Home (Remarket)", value: "Home (Remarket)" },
  ],
  PaymentMethod: [
    { label: "Payment Method Change", value: "Payment Method Change" },
  ],
  LiabilityInsurance: [
    { label: "Liability Insurance", value: "Liability Insurance" },
  ],
};

export const AutomobilePCRSubTypeOptions = {
  GeneralChange: [{ label: "General Change", value: "General Change" }],

  PolicyChange: [
    { label: "Name Change", value: "Name Change" },
    { label: "Address Change", value: "Address Change" },
    { label: "Vehicle Change", value: "Vehicle Change" },
    { label: "Driver Change", value: "Driver Change" },
    { label: "Coverage Change", value: "Coverage Change" },
    // { label: "Interested Party Change", value: "Interested Party Change" },
    // { label: "Schedule Item Change", value: "Schedule Item Change" },
  ],
  New: [
    { label: "Home (Upload)", value: "Home (Upload)" },
    { label: "Home (Remarket)", value: "Home (Remarket)" },
  ],
  PaymentMethod: [
    { label: "Payment Method Change", value: "Payment Method Change" },
  ],
};

export const ledgerReportOptions = {
  insurer: [
    {
      label: "Agency Bill Trust Payable",
      value: "agency_bill_trust_payable_code",
    },
    { label: "Agency Bill Revenue", value: "agency_bill_revenue_code" },
    {
      label: "Direct Bill Trust Payable",
      value: "direct_bill_trust_payable_code",
    },
    { label: "Direct Bill Receivable", value: "direct_bill_receivable_code" },
  ],
  producer: [
    { label: "Commission Liability", value: "commission_liability_code" },
    { label: "Commission Expense", value: "commission_expense_code" },
  ],
  customer: [{ label: "Customer Receivable", value: "customer_receivable" }],
  branch: [
    { label: "Sales Commission Account", value: "sales_commission_account" },
  ],
};

// const commonOptions = [
//   { label: "Invoice Date", value: "invoice_date" },
//   { label: "Producer Code", value: "producer_code" },
//   { label: "Commission Code", value: "commission_code" },
// ];

// const branchOptions = commonOptions.filter(
//   (option) =>
//     option.value !== "commission_code" && option.value !== "producer_code"
// );
// branchOptions.push(
//   { label: "Producer Code", value: "producer_one_code" },
//   { label: "Branch Code", value: "branch_code" }
// );

// const arorderby = [
//   { label: "Transaction Date", value: "transaction_date" },
//   { label: "Branch Code", value: "branch_code" },
//   { label: "Producer Code", value: "producer_one_code" },
// ]

// export const OrderByOptions = {
//   insurer: commonOptions,
//   producer: commonOptions,
//   branch: branchOptions,
//   customer: arorderby,
// };

export type OrderByOption = { label: string; value: string }[];

export interface OrderByOptionsType {
  [key: string]: {
    [key: string]: OrderByOption;
  };
}

const commonOptions: OrderByOption = [
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Producer Code", value: "producer_code" },
  { label: "Commission Code", value: "commission_code" },
];

const branchOptions: OrderByOption = [
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Producer Code", value: "producer_one_code" },
  { label: "Branch Code", value: "branch_code" },
];

const arCustomerOrderBy: OrderByOption = [
  { label: "Transaction Date", value: "transaction_date" },
  { label: "Branch Code", value: "branch_code" },
  { label: "Producer Code", value: "producer_one_code" },
];
const apInsurerOrderBy: OrderByOption = [
  { label: "Transaction Date", value: "transaction_date" },
  { label: "Policy Number", value: "policy_number" },
  { label: "Customer Name", value: "customer_name" },
];

export const OrderByOptions: OrderByOptionsType = {
  AR: {
    customer: arCustomerOrderBy,
  },
  SC: {
    insurer: commonOptions,
    producer: commonOptions,
    branch: branchOptions,
  },
  AP: {
    insurer: apInsurerOrderBy,
    producer: commonOptions,
    branch: branchOptions,
  },
};

export const STANDARD_APP_DATE_FORMAT = "MM/DD/YYYY";
export const STANDARD_APP_TIME_FORMAT = "hh:mm A";
export const STANDARD_APP_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";

export const add_marketing_history_quote = [
  "Accepted",
  "Declined",
  "Closed-Lost",
  "Recieved",
  "Closed-Won",
  "Submitted",
  "Waiting on Producer",
];

export const security_branch = [
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
];

export const related_to = [
  "Customers",
  "Accountants",
  "Insurer",
  "Underwriter",
  "Broker",
  "Branch",
  "Branch Manager",
  "Producer",
  "Marketer",
  "CSR",
];

export const task_priority = ["Low", "Medium", "High", "Highest"];

export const task_status = [
  "Active",
  "Assigned",
  "Waiting",
  "Overdue",
  "Cancelled",
  "Ready to Release",
  "Voided",
  "Incomplete",
  "Completed",
];

export const TASK_STATUS = [
  { label: "New", value: "Active", variant: "default" },
  { label: "Inactive", value: "INACTIVE", variant: "error" },
  { label: "Assigned", value: "Assigned", variant: "success" },
  { label: "Waiting", value: "Waiting", variant: "warning" },
  { label: "Overdue", value: "Overdue", variant: "error" },
  { label: "Cancelled", value: "Cancelled", variant: "error" },
  { label: "Ready to Release", value: "", variant: "success" },
  { label: "Voided", value: "Voided", variant: "error" },
  { label: "Incomplete", value: "Incomplete", variant: "warning" },
  { label: "Completed", value: "Completed", variant: "success" },
];

export const POLICY_STATUS = [
  { label: "New", value: "Active", variant: "default" },
  { label: "Completed", value: "Completed", variant: "success" },
];

export const TASK_OLD_BUSINESS = [
  { label: "New", value: "", variant: "default" },
  { label: "Assigned", value: "Assigned", variant: "success" },
  { label: "Completed", value: "completed", variant: "success" },
  { label: "ReadytoSubmit", value: "ReadytoSubmit", variant: "warning" },
  { label: "Submitted", value: "Submitted", variant: "primary" },
  { label: "Voided", value: "Voided", variant: "warning" },
];

export const create_opp_classification = [
  "Branch Manager",
  "Producer",
  "Business",
  "Partnership",
  "Other",
];

export const create_opp_source = [
  "Website",
  "Reference",
  "Email Marketing",
  "Social Media",
  "Campaign",
  "Cold Call",
  "Employee",
  "Trade Show",
  "Other",
];

export const structure_type = [
  "Apartment - Basement in Dwelling",
  "Apartment/Co-op",
  "Apartment including Mercantile",
  "Apartment",
  "Barn",
  "Boathouse",
  "DEPRECATED Basement",
  "Condo",
  "Chicken Coop",
  "Cottage",
  "Detached",
  "Dwelling",
  "Duplex",
  "Folding Home",
  "Garage",
  "Greenhouse",
  "High Rise",
  "Low Rise",
  "Modular Home",
  "Mobile Home",
  "Mini Home",
  "Multi-plex (4 units or more)",
  "Other",
  "Pre-Cut Home",
  "Pre fabricated - Unspecified",
  "Panelized Home",
  "Row House/Town House (End)",
  "Row House - Unspecified",
  "Row House/Town House (Inside)",
  "Sugar Camp",
  "Semi detached",
  "Servant's Quarters",
  "Storage",
  "Stable",
  "Town House - Unspecified",
  "Trailer (Stationary)",
  "Toolshed",
  "Triplex",
];

export const foundation_type = [
  "Concrete/Masonry",
  "On Blocks",
  "On Wheels",
  "On Timber",
  "Post/Pier on Slab",
  "Basement",
  "Crawlspace",
  "Slab/Concrete Slab",
  "Other",
  "Brick",
  "Concrete Blocks",
  "Poured Concrete",
  "Reinforced Concrete",
  "Stone",
];

export const exterior_wall_framing_type = [
  "Aluminum",
  "Aggregate",
  "Asbestos",
  "Brick",
  "Brick Veneer",
  "Cement",
  "Concrete Block / Masonary Frame",
  "Fibreglass",
  "Fire Resistive",
  "Frame (Wood)",
  "Log",
  "Masonite",
  "Masonary",
  "Masonary Veneer",
  "Monocoque",
  "Non-fire Resistive Apartment",
  "Steel",
  "Stone",
  "Stone Veneer",
  "Stucco",
  "Vinyl",
  "Wood Exterior",
];

export const interior_wall_construction_type = [
  "Acoustic Tile",
  "Carpet",
  "ceramic/Porcelain ",
  "Drywall",
  "Hardwood",
  "Laminate",
  "Plaster",
  "Vinyl",
  "Wood Panelling",
];

export const roof_covering_type = [
  "Aluminium",
  "Asphalt",
  "Asphalt Shingles",
  "Clay Tile",
  "Concrete Tile",
  "Corrugated Sterl",
  "Metal Tile",
  "Mineral Fibre Shakes",
  "Oter Tile",
  "Plastic",
  "Rubber",
  "Slay Tile",
  "Tar and Gravel",
  "Wood Shakes",
  "Wood Shingles",
];

export const electrical_wiring_type = [
  "Aluminium",
  "Copper",
  "Knob/Tube",
  "Mixed",
];

export const electrical_panel_type = ["Breakers", "Fuses", "Aluminium"];

export const apparatus = [
  "Baseboard Heaters",
  "Bi-energy/Combination",
  "Electric Furnace (Pipeless Warm Air Furnace/Recessed)",
  "Fireplace",
  "Fireplace Insert",
  "Floor Furnace",
  "Furnace (Central)",
  "Furnace (Central) With Add on Burning Unit",
  "Geo Thermal / Heat Pump",
  "Hot Water / Steam / Boiler Unit",
  "None",
  "Radiant Ceiling",
  "Radiant Floor",
  "Solid Fuel Heating Unit",
  "Space Heater",
  "Wall Furnace",
];

export const fuel = [
  "Butane",
  "Coal",
  "Combination",
  "Electric",
  "Electric/Oil",
  "Electric/Wood",
  "Ethanol/Alchohol",
  "Grain",
  "kerosene",
  "Liquified Petroleum Gas",
  "Natural Gas",
  "Naptha Gas",
  "Oil",
  "Oil/Wood",
  "Other Solid Fuel",
  "Pellets",
  "Propane",
  "Solar",
  "Wood",
  "Wind",
];

export const hibatational_location = [
  "Attic",
  "Barn",
  "Basement",
  "Crawalspace",
  "Garage",
  "Kitchen",
  "Main Floor",
  "Outside",
  "Rooftop",
  "Second Floor",
  "Unknown",
];

export const water_heater_apparatus = [
  "Combination Space-Water",
  "Heat Pump",
  "Solar",
  "Storage Tank",
  "Tankless (On Demand)",
];

export const water_pump_type = [
  "Floor Sucker (Electric)",
  "Pedestal (Electric)",
  "Submersible (Electric)",
  "Water Powered",
  "None",
];

export const water_auxiliary_power = ["Battery", "Generator", "None"];

export const water_back_up_value = ["Flapper", "Gate", "None"];

export const main_value_shout_off = [
  "Automatic",
  "Manual",
  "Monitored",
  "Unknown",
];

export const distance_to_hydrant = [
  { label: "Unprotected", values: ["Unprotected"] },
  { label: "Within 150m", values: ["Within 150m", "Within 150 metres"] },
  { label: "Within 300m", values: ["Within 300m", "Within 300 metres"] },
  { label: "Over 300m", values: ["Over 300m", "Over 300 metres"] },
];

export const hydrant_type = [
  "Standard",
  "Non-standard , Standpipe",
  "Tanker",
  "Superior Shuttle Tanker Service",
  "Lake, Pond, etc",
];

export const distance_to_reponding_fire_hall = [
  "Not Specified",
  "Within 5 kilometers",
  "Within 8 kilometers",
  "Over 8 kilometers",
  "Within 13 kilometers",
  "Over 13 kilometers",
  "1 kilometers",
  "2 kilometers",
  "3 kilometers",
  "4 kilometers",
  "5 kilometers",
  "6 kilometers",
  "7 kilometers",
  "8 kilometers",
  "9 kilometers",
  "10 kilometers",
  "11 kilometers",
  "12 kilometers",
  "13 kilometers",
  "14 kilometers",
  "15 kilometers",
  "16 kilometers",
  "17 kilometers",
  "18 kilometers",
  "19 kilometers",
  "20 kilometers",
  "21 kilometers",
  "22 kilometers",
  "23 kilometers",
  "24 kilometers",
  "25 kilometers",
  "26 kilometers and over",
];

export const fire_burglar_smoke = [
  "Local Complete",
  "Local Partial",
  "Monitored Complete",
  "Monitored Partial",
  "None",
];

export const smoke_detector_type = [
  "Ionization",
  "Photoelectric",
  "Ionization and Photoelectric",
  "unknown",
];

export const security_type = [
  "Intercom",
  "24 hour Security",
  "Gated Community Service",
  "Part Time Security & Intercom",
  "Secured Intrance",
  "Security Attendant",
  "None",
];

export const kitchen_security = [
  "Builder's Grade",
  "Customer",
  "Economy",
  "Luxury",
  "Standard",
];

export const garage_type = [
  "Attached - Frame",
  "Attached - Masonary",
  "Attached - Masonary or Masonary/Veneer",
  "Basement",
  "Basement Under Attached Garage",
  "Built-in",
  "Carport",
  "Carport with Storage",
  "Detached - Frame",
  "Detached - Masonary or Masonary/Veneer",
  "Detached Garage Masonary",
  "None",
];

export const pool_type = [
  "Indoor, Above Ground",
  "Indoor, In Ground",
  "Indoor, Semi In Ground",
  "Outdoor, Above Ground",
  "Outdoor, In Ground",
  "Outdoor, Semi In Ground",
];
export const swimming_pool_type = [
  "None",
  "Above Ground, With Fence",
  "Above Ground, Without Fence",
  "In Ground, With Fence",
  "In Ground, Without Fence",
];

export const mortgagee_nature_of_interest = [
  "Mortgage (Financial Institution)",
  "Mortgage (Other)",
  "Accounting Contact",
  "Additional Interest",
  "Attorney in Fact",
  "Additional Insured",
  "Additional Lessor",
  "Actual Owner",
  "Certificate Holder",
  "Canadian",
  "Corporate Owner",
  "Escrow Corporation",
  "Executor",
  "Finance Company",
  "Inspection Contact",
  "Line of Credit",
  "Lienholder",
  "Loss Payee",
  "Lessor",
  "Mortgage Servicing Agency",
  "Municipality",
  "Both Loss Payee and Additional Insured",
  "Registered Owner",
  "Secured Loss Payee",
];

export const city_province_state = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "ON",
  "PE",
  "QC",
  "SK",
  "NT",
  "NU",
  "YT",
];
export const attachment_status = ["Pending", "Completed", "Not Applicable"];

export const habitational_attachment = [
  "Appraisal",
  "Association/Membership",
  "Banking Authorization",
  "Bill of Sale",
  "Billing Notice",
  "Building Evaluator",
  "Certificate",
  "Claim Investigation Report",
  "Declaration for Retiree Discount Form",
  "Driver Training Certificate",
  "Driver's License",
  "Electronic Data Memorandum",
  "Evaluation Request Form",
  "Expiring Policy",
  "Financial Statement",
  "Inspection Report",
  "Letter of Authorization",
  "Letter of Experience",
  "Loss History",
  "Medical Report",
  "Motor Vehicle Report",
  "Non-smoker Form",
  "Ownership",
  "Payment Reminder Notice",
  "Photograph",
  "Policy Declaration",
  "Prior Policy History",
  "Proof of Purchase",
  "Questionnaire",
  "Record of Student Grades",
  "Release of Interest",
  "Replacement of Cost Worksheet(s)",
  "Scheule",
  "Signed Electronic Funds Transfer Form",
  "Void Checque",
];

export const breed_dogs = [
  "Akita",
  "Chow Chow",
  "Doberman Pinscher",
  "German Shepherd",
  "Mix - Doberman and Shepherd, etc",
  "Pit Bull",
  "Rotweiller",
  "Wolf Hybrid",
];

export const describe_business = [
  "Arcade",
  "Art Gallery",
  "Automobile (Repair)",
  "Automobile (Sales , Accessories)",
  "Accounting Service",
  "Actuary",
  "Adjuster",
  "Administrative Support Service",
  "Advertising/Marketing Service",
  "Antique Gallery Shop",
  "Appraiser",
  "Aquarium Sales and Service",
  "Arbitration Service",
  "Architect",
  "Artists Goods and Material Sales",
  "Audiologist",
  "Auditing",
  "Bakery, Pastry Shop",
  "Barber Shop and Hairdresser",
  "Book Shop and Stationary Shop",
  "Bronzing Salon",
  "Baked Goods",
  "Beauty Salon / Barber Shop",
  "Bed and Breakfast",
  "Bike Repair",
  "Book Binding",
  "Book Publishing",
  "Book/Magazine Distribution",
  "Bookkeeping Service",
  "Book/Magazines Sales",
  "Bridal Consultant",
  "Cleaner",
  "Calligrapher",
  "Camera Repair",
  "Candy/Nut Confections",
  "Catalagoue and Mail Orders",
  "Catering Service",
  "Ceramics and Pottery (No Kiln on Premises)",
  "Ceramics and Pottery (With Kiln)",
  "Chiropractor's Office",
  "Clock Repair",
  "Clothing and Accessories Sales",
  "Clown, Magician and Entertainer",
  "Computer Consulting/Programming",
  "Computer Lessons",
  "Computer Repair",
  "Copywriting",
  "Cosmetic and Personal Care Sales",
  "Costume Rental",
  "Craft Sales",
  "Cullinary Tutoring",
  "Curtain/Drapes, Manufacturing and Sales",
  "Drugstore",
  "Day Care Center",
  "Dress Maker (Sewing)",
  "Dance School Instructor",
  "Data Entry Clerk",
  "Day Care Service",
  "Dentist",
  "Dermatologist's Office",
  "Desktop Publishing",
  "Dietician (Consultant)",
  "Direct Advertising",
  "Doctor",
  "Dog Kennel",
  "Doll Making",
  "Drafting",
  "Dramatic Art & Speech Training",
  "Electrical Appliance (Retail)",
  "Editor/Proofreader",
  "Educational Councellor/Tutor",
  "Electronic Equipment Repair",
  "Employement Agency",
  "Engineer",
  "Engraver",
  "Esoteric Service",
  "Estate Planning",
  "Financial Institution",
  "Funeral Home",
  "Florist",
  "Fabric Painting, Inking, Dyeing",
  "Financial Planning/Consultant",
  "Firewood Sales & Delivery",
  "Fishing Tackle",
  "Flower Arranging",
  "Flower Sales",
  "Food Broker",
  "Food Supplements/Vitamins",
  "Foster Home",
  "Grocery Store",
  "Geneologist",
  "Gift Delivery Service (Balloons, Gun Baskets)",
  "Giftware Sales",
  "Glassware",
  "Golf Club Making & Repair",
  "Graphic Arts Designer",
  "Greenhouse",
  "Greeting Cards",
  "Group Home",
  "Handy Store",
  "Hardware Store",
  "Handyman",
  "Hearing Aid Outlets Sales and Repairs",
  "Hobby & Model Supplies & Accessories",
  "Hobby/Models and Accessories",
  "Home Care for the Elderly",
  "Home Party Sales (Household Goods, Clothing, Food Services)",
  "Household Products and Appliances",
  "Human Resource Consultant",
  "Insurance Agent",
  "Image Consultant",
  "Income Tax Consulting",
  "Insurance Agent/Broker/Consultant",
  "Insurance Valuation Inspection Services",
  "Insurance Valuation Inspection Services",
  "Interior Designer",
  "Jams/Preserves Sales",
  "Jewellery",
  "Kitchen Supplies",
  "Land Title Search",
  "Landscaping ",
  "Lawyer's Office",
  "Leather Craft",
  "Leather Goods Sales",
  "Lingerie Sales",
  "Locksmith",
  "Mail Receiving",
  "Mailing List Services",
  "Management Consultant",
  "Market Research and Analysis",
  "Massage Therapist",
  "Modelling Agency & School",
  "Mortgage Broker",
  "Music Lessons/Studio",
  "Musical Instruments Sales and Repairs",
  "Ninepins and Pool Room",
  "Needle Work (Embroidery, Knitting, Macrame, Crewel Work)",
  "Notary Public",
  "Office",
  "Office Designing & Planning",
  "Office Employee/Contract Worker",
  "Office Services (Including Faxing, Photocopying, Quick Printing)",
  "Office Supplies Sales",
  "Optician Sales and Repairs",
  "Optometrist",
  "Photography Studio",
  "Public House (Alcohol)",
  "Paper Mache, Paper Tole",
  "Paralegal Agent",
  "Party Planning/Rental Service",
  "Pet Grooming Service",
  "Pet/House Sitting",
  "Photographer, Photography Studio",
  "Piano, Tuning & Repairing",
  "Picture Framing",
  "Picture Painting",
  "Plants - Indoor Maintenance",
  "Podiatrist",
  "Psychologist",
  "Public Relations Service",
  "Public Speaking Service",
  "Repair Shop (Electrical Appliance)",
  "Restaurant",
  "Retail Store",
  "Retail Store (Electrical)",
  "Retail Store (Garments)",
  "Retail Store (Paint)",
  "Retail Store (Sport)",
  "Roomer",
  "Real Estate Sales",
  "Religious Goods",
  "Relocation Service",
  "Residential Inspection Service",
  "Resume Service",
  "Service Station",
  "Sales Representative",
  "Secretarial/Stenographic Service",
  "Sewing/Tailoring/Altering Service",
  "Sharpening Service",
  "Shoe Repair",
  "Sign Designer",
  "Small Appliance Repair",
  "Speech/Language Pathologist",
  "Stained Glass Craft",
  "Stencilling of Pictures",
  "Sun Tanning Bed",
  "Surveyors-land",
  "Tobacconist's Shop",
  "Taxidermist",
  "Telephone Answering Service",
  "Telephone Sales/Telemarketing",
  "Toy Sales",
  "Transcribing Service, Court Reporter",
  "Translation Service",
  "Travel Agent/Travel Planner",
  "Trophy Sales",
  "Tupperware Sales",
  "Upholstery",
  "Video Taping Service Including Editing",
  "Vocational Counsellor",
  "Window Display Decorator",
  "Woodworker",
  "Writer",
  "Other Commercial Occupation",
];

export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

export const LEAD_STATUS = [
  { label: "New", value: "ACTIVE" },
  { label: "In-Process", value: "INPROGRESS" },
  { label: "Spam", value: "SPAM" },
  { label: "Duplicate", value: "DUPLICATE" },
  { label: "Dead", value: "DEAD" },
  { label: "Recycle", value: "RECYCLE" },
];

export const OOP_STATUS = [
  { label: "In Process", value: "ACTIVE", variant: "warning" },
  { label: "Prospecting", value: "Prospecting", variant: "default" },
  { label: "Qualifications", value: "Qualifications", variant: "success" },
  { label: "Needs Analysis", value: "Needs Analysis", variant: "warning" },
  {
    label: "Value Proposition",
    value: "Value Proposition",
    variant: "success",
  },
  {
    label: "Decision Makers?",
    value: "Decision Makers?",
    variant: "success",
  },
  {
    label: "Perception Analysis",
    value: "Perception Analysis",
    variant: "error",
  },
  { label: "In Processing", value: "In Process", variant: "default" },
  { label: "Proposal", value: "Proposal", variant: "success" },
  { label: "Negotiation", value: "Negotiation", variant: "warning" },
  { label: "Closed Won", value: "Closed Won", variant: "error" },
  { label: "Closed Lost", value: "Closed Lost", variant: "error" },
  { label: "Opportunity", value: "OPPORTUNITY", variant: "success" },
];

export const CSIO_COMPANY_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];
export const consentOptions = ["Yes, Written", "Yes, Verbal", "No, Declined"];

export const COMMON_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];
export const EDOCS_STATUS = [
  { label: "Not Reviewed", value: "ACTIVE" },
  { label: "Reviewed", value: "DELETED" },
];
export const OPEN_CLOSE_STATUS = [
  { label: "Open", value: "ACTIVE" },
  { label: "Closed", value: "INACTIVE" },
];

export const liability_extension_exclusion = [
  { label: "Attack By Wild Animal", value: "Attack By Wild Animal" },
  { label: "Accident", value: "Accident" },
  {
    label: "Actual Cash Value Endorsement",
    value: "Actual Cash Value Endorsement",
  },
  {
    label: "Additional Coverages and Limits for High Value Homes",
    value: "Additional Coverages and Limits for High Value Homes",
  },
  {
    label: "Accidental Death and Dismemberment",
    value: "Accidental Death and Dismemberment",
  },
  { label: "Additional Exposures", value: "Additional Exposures" },
  { label: "Additional Named Insured", value: "Additional Named Insured" },
  {
    label: "Additional Rebuilding Cost ",
    value: "Additional Rebuilding Cost ",
  },
  {
    label: "Additional Residences/Properties/Acreage ",
    value: "Additional Residences/Properties/Acreage ",
  },
  {
    label: "Additional Locations Rented",
    value: "Additional Locations Rented",
  },
  { label: "Alarm Warranty", value: "Alarm Warranty" },
  {
    label: "All Terrain Vehicles - Basic",
    value: "All Terrain Vehicles - Basic",
  },
  { label: "All Terrain Vehicles", value: "All Terrain Vehicles" },
  {
    label: "All Terrain Vehicles - Passenger Hazard",
    value: "All Terrain Vehicles - Passenger Hazard",
  },
  {
    label: "All Terrain Vehicles - Underage Operator ",
    value: "All Terrain Vehicles - Underage Operator ",
  },
  {
    label:
      "Additional Living Expense (Homeowners, Tenant and Condominium Form)",
    value:
      "Additional Living Expense (Homeowners, Tenant and Condominium Form)",
  },
  {
    label: "Additional Miscellaneous Equipment - All Risks",
    value: "Additional Miscellaneous Equipment - All Risks",
  },
  {
    label: "Additional Miscellaneous Equipment - Named Perils",
    value: "Additional Miscellaneous Equipment - Named Perils",
  },
  {
    label: "Amphibious Vehicle Liability ",
    value: "Amphibious Vehicle Liability ",
  },
  { label: "Animal Exclusion", value: "Animal Exclusion" },
  { label: "Animal Collision", value: "Animal Collision" },
  { label: "Animal Liability Extension", value: "Animal Liability Extension" },
  { label: "Animal Birth", value: "Animal Birth" },
  { label: "Apiary", value: "Apiary" },
  {
    label: "Additional Amount of Personal Property Away From Premises",
    value: "Additional Amount of Personal Property Away From Premises",
  },
  { label: "Additional Units", value: "Additional Units" },
  {
    label: "All Risk - Unit Owners Additional Protection",
    value: "All Risk - Unit Owners Additional Protection",
  },
  {
    label:
      "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
    value:
      "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
  },
  {
    label: "All Risk - Loss Assessment Coverage",
    value: "All Risk - Loss Assessment Coverage",
  },
  {
    label: "All Risk Personal Articles Endorsement",
    value: "All Risk Personal Articles Endorsement",
  },
  {
    label: "All Risk - Personal Property ",
    value: "All Risk - Personal Property ",
  },
  { label: "All Risk - Produce ", value: "All Risk - Produce " },
  { label: "All Risk - Solar Panel", value: "All Risk - Solar Panel" },
  {
    label: "Antique Tractor Endorsement",
    value: "Antique Tractor Endorsement",
  },
  {
    label: "Antique Tractor Liability Extension",
    value: "Antique Tractor Liability Extension",
  },
  {
    label: "Miscellaneous Vehicles - All Risks",
    value: "Miscellaneous Vehicles - All Risks",
  },
  {
    label: "Miscellaneous Vehicles - Named Perils",
    value: "Miscellaneous Vehicles - Named Perils",
  },
  { label: "Auction", value: "Auction" },
  { label: "Base Internal Limits", value: "Base Internal Limits" },
  { label: "Bed and Breakfast", value: "Bed and Breakfast" },
  { label: "Bed & Breakfast", value: "Bed & Breakfast" },
  {
    label: "Building under construction",
    value: "Building under construction",
  },
  {
    label: "Building - Other than Residence for Homeowner",
    value: "Building - Other than Residence for Homeowner",
  },
  { label: "Builder's Risk Insurance", value: "Builder's Risk Insurance" },
  { label: "Day Care Extension", value: "Day Care Extension" },
  { label: "Boarding of Livestock", value: "Boarding of Livestock" },
  {
    label: "Building under Renovation Endorsement",
    value: "Building under Renovation Endorsement",
  },
  { label: "Residence Burglary", value: "Residence Burglary" },
  { label: "Burn Exclusion", value: "Burn Exclusion" },
  {
    label: "Incidental Business Pursuits Endorsement",
    value: "Incidental Business Pursuits Endorsement",
  },
  {
    label: "Business Property: On Property",
    value: "Business Property: On Property",
  },
  {
    label: "Business Property: Off Property",
    value: "Business Property: Off Property",
  },
  { label: "Bylaws Endorsement", value: "Bylaws Endorsement" },
  { label: "Cannabis Endorsement", value: "Cannabis Endorsement" },
  {
    label: "Care Facility Contents Extension",
    value: "Care Facility Contents Extension",
  },
  {
    label: "Condominium Bare Land Loss Assessment (Property Section)",
    value: "Condominium Bare Land Loss Assessment (Property Section)",
  },
  {
    label: "Credit Card Forgery and Counterfeit Money Coverage",
    value: "Credit Card Forgery and Counterfeit Money Coverage",
  },
  { label: "Bicycle", value: "Bicycle" },
  {
    label: "Books, Tools, and Instruments Pertaining to a Business",
    value: "Books, Tools, and Instruments Pertaining to a Business",
  },
  {
    label: "Increased Limit for Blanket Coverage",
    value: "Increased Limit for Blanket Coverage",
  },
  { label: "Business Increased Limits", value: "Business Increased Limits" },
  { label: "Coin Collection", value: "Coin Collection" },
  {
    label: "Compact Discs or Audio Tapes",
    value: "Compact Discs or Audio Tapes",
  },
  {
    label: "Collectibles and Treasures Increased Limits",
    value: "Collectibles and Treasures Increased Limits",
  },
  {
    label: "Family and Security Increased Limits",
    value: "Family and Security Increased Limits",
  },
  { label: "Furs", value: "Furs" },
  { label: "Golf Carts", value: "Golf Carts" },
  {
    label: "Home and Recreation Increased Limits",
    value: "Home and Recreation Increased Limits",
  },
  { label: "Jewellery/Watches/Gems", value: "Jewellery/Watches/Gems" },
  {
    label: "Condominium Contingent Legal Liability",
    value: "Condominium Contingent Legal Liability",
  },
  { label: "Money and Bullion", value: "Money and Bullion" },
  {
    label: "Professional Books Increased Limits",
    value: "Professional Books Increased Limits",
  },
  {
    label: "Personal Computer (Software)",
    value: "Personal Computer (Software)",
  },
  { label: "Sports Card Collection", value: "Sports Card Collection" },
  { label: "Securities", value: "Securities" },
  { label: "Stamps", value: "Stamps" },
  { label: "Silverware", value: "Silverware" },
  { label: "Theft from Vehicle", value: "Theft from Vehicle" },
  { label: "Tombstones (Grave Markers)", value: "Tombstones (Grave Markers)" },
  { label: "Windstorm", value: "Windstorm" },
  { label: "Watercraft", value: "Watercraft" },
  {
    label: "Condominium Deductible Assessment - Earthquake",
    value: "Condominium Deductible Assessment - Earthquake",
  },
  {
    label: "Common Elements Condominium Corporation Extension Endorsement",
    value: "Common Elements Condominium Corporation Extension Endorsement",
  },
  {
    label: "Endorsement for change in deductible notice",
    value: "Endorsement for change in deductible notice",
  },
  {
    label: "Chip and Fracture Exclusion",
    value: "Chip and Fracture Exclusion",
  },
  { label: "Claims advantage", value: "Claims advantage" },
  { label: "Crown Land", value: "Crown Land" },
  { label: "Claim Free Protection", value: "Claim Free Protection" },
  { label: "Collapse", value: "Collapse" },
  { label: "Commercial Stock", value: "Commercial Stock" },
  {
    label: "Public Employee Liability For Building Under Construction",
    value: "Public Employee Liability For Building Under Construction",
  },
  {
    label: "Building under construction liability",
    value: "Building under construction liability",
  },
  { label: "Consequential Loss  ", value: "Consequential Loss  " },
  { label: "Construction Liability", value: "Construction Liability" },
  { label: "Contamination", value: "Contamination" },
  {
    label: "Insured Acting as Own Contractor",
    value: "Insured Acting as Own Contractor",
  },
  {
    label: "Comprehensive Personal Liability Limitation",
    value: "Comprehensive Personal Liability Limitation",
  },
  {
    label:
      "Legal Liability - Other than Homeowners, Tenant and Condominium Form",
    value:
      "Legal Liability - Other than Homeowners, Tenant and Condominium Form",
  },
  {
    label:
      "Comprehensive Personal Liability - Product - Completed Operations Exclusions",
    value:
      "Comprehensive Personal Liability - Product - Completed Operations Exclusions",
  },
  { label: "criminal ", value: "criminal " },
  { label: "Cross Liability", value: "Cross Liability" },
  { label: "Custom Farming", value: "Custom Farming" },
  { label: "Custom Spraying", value: "Custom Spraying" },
  { label: "Personal Cyber Coverage", value: "Personal Cyber Coverage" },
  { label: "Day Care", value: "Day Care" },
  { label: "Daycare/Babysitting", value: "Daycare/Babysitting" },
  { label: "Debris Removal", value: "Debris Removal" },
  {
    label: "Deductible Assessment Buydown",
    value: "Deductible Assessment Buydown",
  },
  {
    label: "Decreasing Deductible Endorsement ",
    value: "Decreasing Deductible Endorsement ",
  },
  {
    label: "Deferred Loss Settlement Clause",
    value: "Deferred Loss Settlement Clause",
  },
  {
    label: "Demolition Increased Cost of Construction",
    value: "Demolition Increased Cost of Construction",
  },
  { label: "Dent Clause", value: "Dent Clause" },
  {
    label: "Dependents Coverage Endorsement",
    value: "Dependents Coverage Endorsement",
  },
  { label: "Wind/Hail Deductible", value: "Wind/Hail Deductible" },
  {
    label: "Diseases Exclusion Endorsement",
    value: "Diseases Exclusion Endorsement",
  },
  {
    label: "Discount - Electronic Documents",
    value: "Discount - Electronic Documents",
  },
  { label: "Discount - One Payment", value: "Discount - One Payment" },
  { label: "Domestic Fuel Exclusion", value: "Domestic Fuel Exclusion" },
  {
    label: "Detached Private Structures Exclusion Endorsement",
    value: "Detached Private Structures Exclusion Endorsement",
  },
  {
    label: "Detached Private Structures Limitation Endorsement",
    value: "Detached Private Structures Limitation Endorsement",
  },
  {
    label: "Damage Due to Illicit Narcotics Exclusion",
    value: "Damage Due to Illicit Narcotics Exclusion",
  },
  {
    label: "Dwelling - Residence for Homeowner",
    value: "Dwelling - Residence for Homeowner",
  },
  {
    label: "Day Care Activities Exclusion",
    value: "Day Care Activities Exclusion",
  },
  { label: "Earned Flat Premium", value: "Earned Flat Premium" },
  {
    label: "Elderly care Home Extension",
    value: "Elderly care Home Extension",
  },
  { label: "Exhibition of Equipment", value: "Exhibition of Equipment" },
  { label: "Elevator", value: "Elevator" },
  {
    label: "Electrical Power Interruption",
    value: "Electrical Power Interruption",
  },
  {
    label:
      "Declaration of Emergency Endorsement – Extension of Termination or Expiry Date",
    value:
      "Declaration of Emergency Endorsement – Extension of Termination or Expiry Date",
  },
  {
    label: "Environmentally Friendly Home Replacement Loss Settlement",
    value: "Environmentally Friendly Home Replacement Loss Settlement",
  },
  { label: "Enhanced Internal Limits", value: "Enhanced Internal Limits" },
  { label: "Entrapment", value: "Entrapment" },
  { label: "Earthquake", value: "Earthquake" },
  { label: "Post-earthquake Damage", value: "Post-earthquake Damage" },
  {
    label: "Estate Of - Permission for Unoccupancy",
    value: "Estate Of - Permission for Unoccupancy",
  },
  {
    label: "Mass Evacuation Endorsement",
    value: "Mass Evacuation Endorsement",
  },
  { label: "Exhibition of Animals", value: "Exhibition of Animals" },
  { label: "Unusual/Exotic Animal(s)", value: "Unusual/Exotic Animal(s)" },
  { label: "Extended Coverage Deletion", value: "Extended Coverage Deletion" },
  { label: "Extra Expense", value: "Extra Expense" },
  { label: "Excess Trailers", value: "Excess Trailers" },
  {
    label:
      "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
    value:
      "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
  },
  { label: "Farm Land", value: "Farm Land" },
  {
    label: "Farm Machinery Used for Snow Removal",
    value: "Farm Machinery Used for Snow Removal",
  },
  { label: "Foster Care Home Extension", value: "Foster Care Home Extension" },
  {
    label: "Farmers Comprehensive Personal Liability",
    value: "Farmers Comprehensive Personal Liability",
  },
  {
    label: "Fire Department Service Charge",
    value: "Fire Department Service Charge",
  },
  { label: "Fishing", value: "Fishing" },
  { label: "Flood Exclusion", value: "Flood Exclusion" },
  {
    label: "Flood Water - Damage caused by flood water.",
    value: "Flood Water - Damage caused by flood water.",
  },
  {
    label: "Flyboard Liability Extension",
    value: "Flyboard Liability Extension",
  },
  {
    label: "Forest and Prairie Protection Act",
    value: "Forest and Prairie Protection Act",
  },
  {
    label: "Functional Replacement Cost Loss Settlement",
    value: "Functional Replacement Cost Loss Settlement",
  },
  {
    label: "Home Freezer (Food Spoilage)",
    value: "Home Freezer (Food Spoilage)",
  },
  { label: "Foster Home Exclusion", value: "Foster Home Exclusion" },
  { label: "Hobby Farm", value: "Hobby Farm" },
  { label: "Furnished Rented Property", value: "Furnished Rented Property" },
  {
    label: "Limited Fungi or Other Microbes Remediation Coverage",
    value: "Limited Fungi or Other Microbes Remediation Coverage",
  },
  { label: "Garden Type Tractor", value: "Garden Type Tractor" },
  { label: "Geothermal Energy ", value: "Geothermal Energy " },
  { label: "Group Home Extension", value: "Group Home Extension" },
  { label: "Glass Breakage", value: "Glass Breakage" },
  {
    label: "Glass Deductible Endorsement",
    value: "Glass Deductible Endorsement",
  },
  { label: "Golf Cart", value: "Golf Cart" },
  { label: "Golf Package Endorsement", value: "Golf Package Endorsement" },
  { label: "Gravel Pit", value: "Gravel Pit" },
  {
    label:
      "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
    value:
      "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
  },
  {
    label:
      "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
    value:
      "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
  },
  {
    label: "Guarantee Replacement Cost Excluded",
    value: "Guarantee Replacement Cost Excluded",
  },
  {
    label: "Guaranteed Building Replacement Cost with Single Limit",
    value: "Guaranteed Building Replacement Cost with Single Limit",
  },
  {
    label:
      "Ground Water - Damage caused by infiltration of water through a foundation.",
    value:
      "Ground Water - Damage caused by infiltration of water through a foundation.",
  },
  {
    label: "Guaranteed Replacement Cost - Building",
    value: "Guaranteed Replacement Cost - Building",
  },
  {
    label: "Home Accessibility Extension",
    value: "Home Accessibility Extension",
  },
  { label: "Hail Coverage Endorsement", value: "Hail Coverage Endorsement" },
  { label: "Hay/Sleigh Rides", value: "Hay/Sleigh Rides" },
  {
    label: "Home-Based Business Liability Extension",
    value: "Home-Based Business Liability Extension",
  },
  {
    label: "Home Business Off-Premise Coverage",
    value: "Home Business Off-Premise Coverage",
  },
  {
    label: "Home Business On-Premise Coverage",
    value: "Home Business On-Premise Coverage",
  },
  {
    label: "Home Care Assistance Coverage",
    value: "Home Care Assistance Coverage",
  },
  { label: "Heat Prostration", value: "Heat Prostration" },
  {
    label: "Restrict Hail Damage to Punctures",
    value: "Restrict Hail Damage to Punctures",
  },
  { label: "Hull - All Risk", value: "Hull - All Risk" },
  {
    label: "Hull - Named Perils and Marine Perils",
    value: "Hull - Named Perils and Marine Perils",
  },
  { label: "Hull - Named Perils", value: "Hull - Named Perils" },
  { label: "Her Majesty the Queen", value: "Her Majesty the Queen" },
  { label: "Home Business Coverage", value: "Home Business Coverage" },
  { label: "Home Equipment Protection", value: "Home Equipment Protection" },
  { label: "Horse", value: "Horse" },
  { label: "Horse Pulls", value: "Horse Pulls" },
  {
    label: "Host Liquor Liability Exclusion",
    value: "Host Liquor Liability Exclusion",
  },
  { label: "Single Limit", value: "Single Limit" },
  { label: "Hurricane Deductible  ", value: "Hurricane Deductible  " },
  { label: "Stronger Home Endorsement", value: "Stronger Home Endorsement" },
  { label: "Identity Theft", value: "Identity Theft" },
  {
    label: "Identity Theft - Loss of Income",
    value: "Identity Theft - Loss of Income",
  },
  { label: "Illness/Disease", value: "Illness/Disease" },
  {
    label: "Inflation Guard Endorsement",
    value: "Inflation Guard Endorsement",
  },
  { label: "In Servant", value: "In Servant" },
  {
    label: "Increased Legal Liability, Homeowners, Tenants & Condominium Forms",
    value: "Increased Legal Liability, Homeowners, Tenants & Condominium Forms",
  },
  {
    label: "Waterjet Propulsion Personal Watercraft - Basic",
    value: "Waterjet Propulsion Personal Watercraft - Basic",
  },
  {
    label: "Waterjet Propulsion Personal Watercraft - Passenger Hazard",
    value: "Waterjet Propulsion Personal Watercraft - Passenger Hazard",
  },
  {
    label: "Waterjet Propulsion Personal Watercraft - Underage Operator",
    value: "Waterjet Propulsion Personal Watercraft - Underage Operator",
  },
  {
    label: "Leasehold Condominium Corporation Extension Endorsement",
    value: "Leasehold Condominium Corporation Extension Endorsement",
  },
  {
    label: "Leakage or Overflow of Domestic Fuel Oil",
    value: "Leakage or Overflow of Domestic Fuel Oil",
  },
  { label: "Legal Expense Insurance", value: "Legal Expense Insurance" },
  { label: "Legal Assistance", value: "Legal Assistance" },
  {
    label: "Limited Guaranteed Replacement Cost Percentage - Building ",
    value: "Limited Guaranteed Replacement Cost Percentage - Building ",
  },
  { label: "Lessons", value: "Lessons" },
  { label: "Liability", value: "Liability" },
  { label: "Limited Exterior", value: "Limited Exterior" },
  {
    label: "Limited to Structural Damage ",
    value: "Limited to Structural Damage ",
  },
  {
    label: "Limited Hidden Water Damage",
    value: "Limited Hidden Water Damage",
  },
  { label: "Life Lease Extension", value: "Life Lease Extension" },
  {
    label: "Limited Liability Extension ",
    value: "Limited Liability Extension ",
  },
  {
    label: "Legal Liability Loss Assessment",
    value: "Legal Liability Loss Assessment",
  },
  {
    label: "Legal Liability Loss Assessment Bare Land",
    value: "Legal Liability Loss Assessment Bare Land",
  },
  { label: "Life Lease Deductible", value: "Life Lease Deductible" },
  {
    label: "Life Lease Deductible - Earthquakes",
    value: "Life Lease Deductible - Earthquakes",
  },
  {
    label: "Life Lease Unit Owner Improvement",
    value: "Life Lease Unit Owner Improvement",
  },
  {
    label: "Life Lease Property Coverage",
    value: "Life Lease Property Coverage",
  },
  { label: "Life Lease Unit Coverage", value: "Life Lease Unit Coverage" },
  { label: "Loading/Unloading", value: "Loading/Unloading" },
  {
    label: "Log Construction Settlement Limitation",
    value: "Log Construction Settlement Limitation",
  },
  { label: "Lifestyle advantage", value: "Lifestyle advantage" },
  {
    label: "Livestock – Broad Named Perils",
    value: "Livestock – Broad Named Perils",
  },
  {
    label: "Livestock – Limited Named Perils",
    value: "Livestock – Limited Named Perils",
  },
  { label: "Limited Mortality", value: "Limited Mortality" },
  {
    label: "Limited Pollution Liability",
    value: "Limited Pollution Liability",
  },
  { label: "Locked Vehicle Warranty", value: "Locked Vehicle Warranty" },
  { label: "Waiver of Deductible", value: "Waiver of Deductible" },
  { label: "major", value: "major" },
  { label: "Maintenance Work Liability", value: "Maintenance Work Liability" },
  {
    label: "Matching of Undamaged Roof Surfacing Additional Coverage ",
    value: "Matching of Undamaged Roof Surfacing Additional Coverage ",
  },
  {
    label: "Matching of Undamaged Siding - Additional Coverage  ",
    value: "Matching of Undamaged Siding - Additional Coverage  ",
  },
  {
    label: "Modified Basis of Claims Payment",
    value: "Modified Basis of Claims Payment",
  },
  { label: "Voluntary Medical Payments", value: "Voluntary Medical Payments" },
  { label: "minor", value: "minor" },
  { label: "Mold Exclusion", value: "Mold Exclusion" },
  { label: "Modified Loss Settlement", value: "Modified Loss Settlement" },
  { label: "Motor - All Risks", value: "Motor - All Risks" },
  {
    label: "Motor - Named Perils and Marine Perils",
    value: "Motor - Named Perils and Marine Perils",
  },
  { label: "Motor - Named Perils", value: "Motor - Named Perils" },
  { label: "Non Owned LiveStock", value: "Non Owned LiveStock" },
  {
    label: "Single limit AND Guarantee Replacement Cost Excluded",
    value: "Single limit AND Guarantee Replacement Cost Excluded",
  },
  {
    label: "Non-Owned Business Equipment ",
    value: "Non-Owned Business Equipment ",
  },
  { label: "No Obligation to Rebuild", value: "No Obligation to Rebuild" },
  {
    label: "Named Perils - Unit Owners Additional Protection",
    value: "Named Perils - Unit Owners Additional Protection",
  },
  {
    label:
      "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
    value:
      "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
  },
  {
    label: "Named Perils - Loss Assessment Coverage",
    value: "Named Perils - Loss Assessment Coverage",
  },
  {
    label: "Named Perils Personal Articles Endorsement",
    value: "Named Perils Personal Articles Endorsement",
  },
  { label: "Named Perils - Solar Panel", value: "Named Perils - Solar Panel" },
  {
    label: "Office, Professional,Private School or Studio Use (Other Premises)",
    value: "Office, Professional,Private School or Studio Use (Other Premises)",
  },
  {
    label: "Office, Professional, Private School or Studio Use (Res. Premises)",
    value: "Office, Professional, Private School or Studio Use (Res. Premises)",
  },
  { label: "Oil Tank Heating", value: "Oil Tank Heating" },
  {
    label: "Oil Leakage on Premise Liability Extension",
    value: "Oil Leakage on Premise Liability Extension",
  },
  { label: "Out of Province Medical", value: "Out of Province Medical" },
  {
    label: "Other Structures - Homeowners Forms",
    value: "Other Structures - Homeowners Forms",
  },
  {
    label: "Specific Structures Away from the Residence Premises ",
    value: "Specific Structures Away from the Residence Premises ",
  },
  {
    label: "Other Structures Rented to Others (Residence Premises)",
    value: "Other Structures Rented to Others (Residence Premises)",
  },
  { label: "Other Alternative Energy ", value: "Other Alternative Energy " },
  { label: "Other", value: "Other" },
  {
    label: "Other Members of Your Household",
    value: "Other Members of Your Household",
  },
  {
    label: "Other (Appurtenant) Structures (Residence Premises)",
    value: "Other (Appurtenant) Structures (Residence Premises)",
  },
  {
    label: "Unit Owners Other Structures",
    value: "Unit Owners Other Structures",
  },
  { label: "Out Servant", value: "Out Servant" },
  {
    label: "Overland Water (Flood & Surface Water) Coverage",
    value: "Overland Water (Flood & Surface Water) Coverage",
  },
  { label: "Overland Water Exclusion", value: "Overland Water Exclusion" },
  {
    label: "Owned Kennel Liability Extension",
    value: "Owned Kennel Liability Extension",
  },
  { label: "Peril Adjustment - Hail", value: "Peril Adjustment - Hail" },
  { label: "Replacement Cost Package", value: "Replacement Cost Package" },
  { label: "Peril Adjustment - Snow", value: "Peril Adjustment - Snow" },
  { label: "Peril Adjustment - Water", value: "Peril Adjustment - Water" },
  { label: "Peril Adjustment - Wind", value: "Peril Adjustment - Wind" },
  {
    label:
      "Comprehensive Personal Liability - Bodily Injury and Property Damage (Coverage A)",
    value:
      "Comprehensive Personal Liability - Bodily Injury and Property Damage (Coverage A)",
  },
  {
    label: "Products Completed Operations Aggregate Limit",
    value: "Products Completed Operations Aggregate Limit",
  },
  {
    label: "Personal Records and Data Replacement",
    value: "Personal Records and Data Replacement",
  },
  {
    label: "Permission to Use Auxiliary Heating",
    value: "Permission to Use Auxiliary Heating",
  },
  {
    label: "Permission to Use Auxiliary Heating in Case of Electricity Failure",
    value: "Permission to Use Auxiliary Heating in Case of Electricity Failure",
  },
  {
    label: "Personal Effects Floater - Domiciled Endorsement",
    value: "Personal Effects Floater - Domiciled Endorsement",
  },
  {
    label: "Personal Liability (Umbrella Endorsement)",
    value: "Personal Liability (Umbrella Endorsement)",
  },
  { label: "Personal Liability", value: "Personal Liability" },
  {
    label: "Personal Liability (Umbrella Standalone)",
    value: "Personal Liability (Umbrella Standalone)",
  },
  { label: "Petting Zoo", value: "Petting Zoo" },
  {
    label: "Pick Your Own (fruits & vegetables)",
    value: "Pick Your Own (fruits & vegetables)",
  },
  {
    label: "Personal Injury Endorsement",
    value: "Personal Injury Endorsement",
  },
  {
    label: "Power Interruption (Livestock)",
    value: "Power Interruption (Livestock)",
  },
  {
    label: "Legal Liability - Homeowners, Tenant and Condominium",
    value: "Legal Liability - Homeowners, Tenant and Condominium",
  },
  { label: "Property Loss Assessment", value: "Property Loss Assessment" },
  {
    label: "Comprehensive Personal Liability - Personal Injury (Coverage B)",
    value: "Comprehensive Personal Liability - Personal Injury (Coverage B)",
  },
  {
    label: "Comprehensive Personal Liability - Medical Payments (Coverage C)",
    value: "Comprehensive Personal Liability - Medical Payments (Coverage C)",
  },
  {
    label: "Ponds, Rivers, Swimming Pools",
    value: "Ponds, Rivers, Swimming Pools",
  },
  {
    label: "Personal Property - Homeowners Form",
    value: "Personal Property - Homeowners Form",
  },
  {
    label:
      "Personal Property - Other than Homeowners, Tenant and Condominium Form",
    value:
      "Personal Property - Other than Homeowners, Tenant and Condominium Form",
  },
  {
    label: "Personal Property - Tenant and Condominium Unit Owners Form",
    value: "Personal Property - Tenant and Condominium Unit Owners Form",
  },
  {
    label: "Premises Coverage Limitation",
    value: "Premises Coverage Limitation",
  },
  { label: "Premises Extension", value: "Premises Extension" },
  { label: "Private Lake", value: "Private Lake" },
  { label: "Professional Activity", value: "Professional Activity" },
  { label: "Property Damage", value: "Property Damage" },
  {
    label: "Personal Property - Pollution",
    value: "Personal Property - Pollution",
  },
  { label: "Property in Storage", value: "Property in Storage" },
  {
    label: "Property of Students Coverage",
    value: "Property of Students Coverage",
  },
  {
    label:
      "Comprehensive Personal Liability- Tenants Legal Liability (Coverage D) ",
    value:
      "Comprehensive Personal Liability- Tenants Legal Liability (Coverage D) ",
  },
  { label: "Power Fluctuation", value: "Power Fluctuation" },
  {
    label: "Replacement Cost - Building",
    value: "Replacement Cost - Building",
  },
  { label: "Rebuilding Clause", value: "Rebuilding Clause" },
  {
    label: "Specialty Rebuilding Cost Endorsement ",
    value: "Specialty Rebuilding Cost Endorsement ",
  },
  { label: "Reduced Internal Limits", value: "Reduced Internal Limits" },
  {
    label: "Residence Employees Liability",
    value: "Residence Employees Liability",
  },
  { label: "Rental Income", value: "Rental Income" },
  {
    label: "Residence Premises - 2 to 6 Family Dwelling",
    value: "Residence Premises - 2 to 6 Family Dwelling",
  },
  {
    label: "Recreational Travel Trailers",
    value: "Recreational Travel Trailers",
  },
  { label: "Riding Arena", value: "Riding Arena" },
  {
    label: "Rental - Seasonal Dwelling ",
    value: "Rental - Seasonal Dwelling ",
  },
  {
    label: "Short-Term Rental Endorsement",
    value: "Short-Term Rental Endorsement",
  },
  {
    label: "Rental Watercraft (Rented to Others)",
    value: "Rental Watercraft (Rented to Others)",
  },
  { label: "Roadside Stand/Market", value: "Roadside Stand/Market" },
  {
    label: "Roof (Limited to Cash Value)",
    value: "Roof (Limited to Cash Value)",
  },
  { label: "Roof (Actual Cash Value)", value: "Roof (Actual Cash Value)" },
  { label: "Roof Exclusion - All", value: "Roof Exclusion - All" },
  { label: "Roof Exclusion", value: "Roof Exclusion" },
  { label: "Limited Roof Surface", value: "Limited Roof Surface" },
  {
    label: "Roof (Replacement Cost Value)",
    value: "Roof (Replacement Cost Value)",
  },
  {
    label: "Roof Surface - Basis of Settlement",
    value: "Roof Surface - Basis of Settlement",
  },
  {
    label: "Roof (Basis of Settlement) due to Windstorm/Hail",
    value: "Roof (Basis of Settlement) due to Windstorm/Hail",
  },
  { label: "Roomer", value: "Roomer" },
  {
    label: "Rented Unit Owners Endorsement",
    value: "Rented Unit Owners Endorsement",
  },
  {
    label: "Residence Voluntary Compensation - Chauffeur",
    value: "Residence Voluntary Compensation - Chauffeur",
  },
  {
    label: "Residence Voluntary Compensation - Resident Employee",
    value: "Residence Voluntary Compensation - Resident Employee",
  },
  {
    label:
      "Residence Voluntary Comp. (Obsolete: see RVCCH, RVCIN, RVCOC, RVCOU)",
    value:
      "Residence Voluntary Comp. (Obsolete: see RVCCH, RVCIN, RVCOC, RVCOU)",
  },
  {
    label: "Residence Voluntary Compensation - Occasional",
    value: "Residence Voluntary Compensation - Occasional",
  },
  {
    label: "Residence Voluntary Compensation - Non-resident Employee ",
    value: "Residence Voluntary Compensation - Non-resident Employee ",
  },
  { label: "Restricted Water Damage", value: "Restricted Water Damage" },
  { label: "Renewable Energy Income", value: "Renewable Energy Income" },
  { label: "Runway Liability Extension", value: "Runway Liability Extension" },
  { label: "Sales Barn", value: "Sales Barn" },
  { label: "Sauna ", value: "Sauna " },
  {
    label: "Actual Cash Value for Sewer Back Up",
    value: "Actual Cash Value for Sewer Back Up",
  },
  { label: "Self-contained Apartment", value: "Self-contained Apartment" },
  { label: "Seasonal Residence", value: "Seasonal Residence" },
  { label: "Seasonal Dwelling", value: "Seasonal Dwelling" },
  { label: "Seedman's E&O", value: "Seedman's E&O" },
  {
    label: "Enhanced coverage option for selected clients",
    value: "Enhanced coverage option for selected clients",
  },
  {
    label: "Water and Sewer Lines Coverage",
    value: "Water and Sewer Lines Coverage",
  },
  { label: "Sewer Back-up Coverage", value: "Sewer Back-up Coverage" },
  {
    label: "Exterior Sewer Line - Breakage of the exterior sewer water line.",
    value: "Exterior Sewer Line - Breakage of the exterior sewer water line.",
  },
  { label: "Sewer Back-up Limitation", value: "Sewer Back-up Limitation" },
  { label: "Solid Fuel Heat Warranty", value: "Solid Fuel Heat Warranty" },
  { label: "Show Animal(s)", value: "Show Animal(s)" },
  {
    label: "Service Line Extension Endorsement",
    value: "Service Line Extension Endorsement",
  },
  {
    label: "Solar Panel- Loss of Income",
    value: "Solar Panel- Loss of Income",
  },
  { label: "Small engine repair", value: "Small engine repair" },
  { label: "SmartHome Systems", value: "SmartHome Systems" },
  { label: "Snowmobiles - All Risks", value: "Snowmobiles - All Risks" },
  { label: "Snow Blower", value: "Snow Blower" },
  { label: "Snow Blower", value: "Snow Blower" },
  { label: "Snowmobiles - Named Perils", value: "Snowmobiles - Named Perils" },
  { label: "Weight of Snow and Ice", value: "Weight of Snow and Ice" },
  { label: "Snow Removal", value: "Snow Removal" },
  { label: "Solar Panel", value: "Solar Panel" },
  { label: "Spa Coverage", value: "Spa Coverage" },
  { label: "Spa  ", value: "Spa  " },
  { label: "Sprinkler Bylaw", value: "Sprinkler Bylaw" },
  {
    label: "Damage to Outdoor Swimming Pool & Attached Equipment",
    value: "Damage to Outdoor Swimming Pool & Attached Equipment",
  },
  { label: "Swimming Pool or Pond", value: "Swimming Pool or Pond" },
  { label: "Special Loss Settlement", value: "Special Loss Settlement" },
  { label: "Sporting Equipment", value: "Sporting Equipment" },
  {
    label: "Surface Water – Damage caused by surface water.",
    value: "Surface Water – Damage caused by surface water.",
  },
  {
    label: "Stacking of Limit Endorsement",
    value: "Stacking of Limit Endorsement",
  },
  { label: "Stableman's Liability", value: "Stableman's Liability" },
  {
    label: "Sugar Shack With Reception Hall",
    value: "Sugar Shack With Reception Hall",
  },
  { label: "Supplementary Lodging", value: "Supplementary Lodging" },
  { label: "Shared Water Well", value: "Shared Water Well" },
  {
    label: "Temporary Additional Location Endorsement",
    value: "Temporary Additional Location Endorsement",
  },
  { label: "Trust Endorsement", value: "Trust Endorsement" },
  {
    label: "One to Six Family Dwelling Premises Liability",
    value: "One to Six Family Dwelling Premises Liability",
  },
  {
    label: "Theft of Building Materials Under Construction",
    value: "Theft of Building Materials Under Construction",
  },
  { label: "Theft and Burglary", value: "Theft and Burglary" },
  { label: "Theft Exclusion", value: "Theft Exclusion" },
  { label: "Tires", value: "Tires" },
  {
    label: "Trailer - All Risks / Trailer (Non-Travel) - All Risk",
    value: "Trailer - All Risks / Trailer (Non-Travel) - All Risk",
  },
  { label: "Tracks/trails", value: "Tracks/trails" },
  { label: "Trail Rides", value: "Trail Rides" },
  {
    label: "Trailer - Named Perils / Trailer (Non-Travel)  - Named Perils",
    value: "Trailer - Named Perils / Trailer (Non-Travel)  - Named Perils",
  },
  {
    label: "Emergency Medical Travel Insurance",
    value: "Emergency Medical Travel Insurance",
  },
  { label: "Tree Removal Coverage   ", value: "Tree Removal Coverage   " },
  { label: "Trees, Plants & Shrubbery", value: "Trees, Plants & Shrubbery" },
  {
    label: "Travel Trailers Temporary Attachments - All Risks",
    value: "Travel Trailers Temporary Attachments - All Risks",
  },
  {
    label: "Travel Trailers Temporary Attachments - Named Perils",
    value: "Travel Trailers Temporary Attachments - Named Perils",
  },
  {
    label: "Travel Trailers - All Risks",
    value: "Travel Trailers - All Risks",
  },
  { label: "Travel Tailer Broad Form", value: "Travel Tailer Broad Form" },
  {
    label: "Travel Trailers Contents - All Risks",
    value: "Travel Trailers Contents - All Risks",
  },
  {
    label: "Travel Trailers Contents - Named Perils",
    value: "Travel Trailers Contents - Named Perils",
  },
  { label: "Emergency Living Expenses", value: "Emergency Living Expenses" },
  { label: "Emergency Road Service", value: "Emergency Road Service" },
  {
    label: "Travel Trailers - Named Perils",
    value: "Travel Trailers - Named Perils",
  },
  { label: "Personal Liability", value: "Personal Liability" },
  {
    label: "Unlicensed Snow Vehicle, Trail Bike or ATV",
    value: "Unlicensed Snow Vehicle, Trail Bike or ATV",
  },
  {
    label: "USA Products and/or Operations Exclusion ",
    value: "USA Products and/or Operations Exclusion ",
  },
  {
    label: "Utility Tractor Endorsement",
    value: "Utility Tractor Endorsement",
  },
  {
    label: "Utility Tractor Liability Extension",
    value: "Utility Tractor Liability Extension",
  },
  { label: "Vacant Land", value: "Vacant Land" },
  { label: "Vacancy Permit", value: "Vacancy Permit" },
  {
    label: "Backwater Valve Maintenance Clause",
    value: "Backwater Valve Maintenance Clause",
  },
  {
    label: "Vandalism / Theft by Tenants and Guest",
    value: "Vandalism / Theft by Tenants and Guest",
  },
  { label: "Vehicle/Boat Storage", value: "Vehicle/Boat Storage" },
  { label: "Vehicles Unlicensed", value: "Vehicles Unlicensed" },
  { label: "Voluntary Property Damage", value: "Voluntary Property Damage" },
  {
    label: "Malicious Damage - Building & Contents",
    value: "Malicious Damage - Building & Contents",
  },
  {
    label: "Malicious Damage on Contents",
    value: "Malicious Damage on Contents",
  },
  {
    label: "Malicious Damage on Building",
    value: "Malicious Damage on Building",
  },
  {
    label: "Vandalism on Seasonal Dwelling",
    value: "Vandalism on Seasonal Dwelling",
  },
  { label: "Voluntary Compensation ", value: "Voluntary Compensation " },
  {
    label: "Vandalism by Tenants Exclusion",
    value: "Vandalism by Tenants Exclusion",
  },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability ", value: "Watercraft Liability " },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Watercraft Liability", value: "Watercraft Liability" },
  { label: "Waived Premium", value: "Waived Premium" },
  { label: "Water Damage Endorsement", value: "Water Damage Endorsement" },
  { label: "Waterbed", value: "Waterbed" },
  { label: "Waterbed", value: "Waterbed" },
  {
    label:
      "Water Damage Deductible - Specified Deductible for loss caused by Water. ",
    value:
      "Water Damage Deductible - Specified Deductible for loss caused by Water. ",
  },
  { label: "Water Damage Exclusion", value: "Water Damage Exclusion" },
  { label: "Wharves and Docks", value: "Wharves and Docks" },
  { label: "Wind & Hail Exclusion", value: "Wind & Hail Exclusion" },
  { label: "Windstorm/Hail", value: "Windstorm/Hail" },
  { label: "Wind Coverage Endorsement", value: "Wind Coverage Endorsement" },
  { label: "Wind Energy ", value: "Wind Energy " },
  { label: "Damage Caused by Wild Life", value: "Damage Caused by Wild Life" },
  { label: "Wood/Maple Syrup Sales", value: "Wood/Maple Syrup Sales" },
  { label: "Wine & Spirits Endorsement", value: "Wine & Spirits Endorsement" },
  { label: "Wreckage Value", value: "Wreckage Value" },
  { label: "Removed Heat Warranty", value: "Removed Heat Warranty" },
  { label: "Wood Stove Limitation", value: "Wood Stove Limitation" },
  { label: "Wood Stove Warranty", value: "Wood Stove Warranty" },
  {
    label: "Extended Theft Away from Premises",
    value: "Extended Theft Away from Premises",
  },
  {
    label: "Property Pertaining to a Business (Not premises specific)",
    value: "Property Pertaining to a Business (Not premises specific)",
  },
  {
    label: "Damage Caused by Hail or Wind Excluding the Roof",
    value: "Damage Caused by Hail or Wind Excluding the Roof",
  },
  {
    label: "Accident Insurance: Limit per claim",
    value: "Accident Insurance: Limit per claim",
  },
  {
    label: "Liability Buy Back Coverage",
    value: "Liability Buy Back Coverage",
  },
  { label: "Theft", value: "Theft" },
  { label: "Cameras", value: "Cameras" },
  { label: "China/Crystal", value: "China/Crystal" },
  { label: "Fine Arts", value: "Fine Arts" },
  { label: "Firearms", value: "Firearms" },
  { label: "Musical Instruments", value: "Musical Instruments" },
  {
    label: "Property Loss Assessment/Contingency",
    value: "Property Loss Assessment/Contingency",
  },
  {
    label: "Cyber Incident Exclusion Endorsement",
    value: "Cyber Incident Exclusion Endorsement",
  },
  {
    label: "Undivided Right of Ownership - Unequal Percentages",
    value: "Undivided Right of Ownership - Unequal Percentages",
  },
  {
    label: "Undivided Right of Ownership - Equal Percentages",
    value: "Undivided Right of Ownership - Equal Percentages",
  },
  { label: "Home Settlement Benefit", value: "Home Settlement Benefit" },
  { label: "Benefits for Group Members", value: "Benefits for Group Members" },
  {
    label: "Deductible - Water Infiltrations Through The Roof",
    value: "Deductible - Water Infiltrations Through The Roof",
  },
  {
    label: "Water Damage - Above Ground Water ",
    value: "Water Damage - Above Ground Water ",
  },
  {
    label: "Deductible - Water Damage By The Water Heater",
    value: "Deductible - Water Damage By The Water Heater",
  },
  { label: "Pet Emergency Endorsement", value: "Pet Emergency Endorsement" },
  { label: "Marine Assistance", value: "Marine Assistance" },
  {
    label: "Personal Property – Watercraft",
    value: "Personal Property – Watercraft",
  },
  { label: "Emergency towing", value: "Emergency towing" },
  {
    label: "Navigational Territory Extension",
    value: "Navigational Territory Extension",
  },
  { label: "Annual Storage", value: "Annual Storage" },
  { label: "Named Storm Exclusion", value: "Named Storm Exclusion" },
  { label: "Propeller Exclusion", value: "Propeller Exclusion" },
  { label: "Boat Trailer Liability", value: "Boat Trailer Liability" },
  { label: "Accidental Death", value: "Accidental Death" },
  { label: " Wrapping Coverage", value: " Wrapping Coverage" },
  { label: "Tail Shaft Exclusion", value: "Tail Shaft Exclusion" },
];

export const deductible_type = [
  "Flat",
  "Percent",
  "Disappearing Flat",
  "Disappearing Perce",
];

export const discount_surcharge = [
  { label: "Automatic Generator", value: "Automatic Generator" },
  {
    label: "Premises Alarm (Fire or Intrusion) System",
    value: "Premises Alarm (Fire or Intrusion) System",
  },
  {
    label: "Discount - Association/Membership",
    value: "Discount - Association/Membership",
  },
  { label: "Broker Multi Line", value: "Broker Multi Line" },
  { label: "Broker Self Service", value: "Broker Self Service" },
  { label: "Business Use", value: "Business Use" },
  { label: "Combined Age-Mortgage-Free", value: "Combined Age-Mortgage-Free" },
  { label: "Discount - Combined Policy", value: "Discount - Combined Policy" },
  {
    label: "Discount - Credit Consent Received",
    value: "Discount - Credit Consent Received",
  },
  { label: "Risk Premium Cap", value: "Risk Premium Cap" },
  { label: "Risk Discount Cap", value: "Risk Discount Cap" },
  { label: "Discount - Deductible", value: "Discount - Deductible" },
  { label: "Discount - Diesel Engine", value: "Discount - Diesel Engine" },
  { label: "Employee", value: "Employee" },
  { label: "Evaluator Based", value: "Evaluator Based" },
  { label: "Female", value: "Female" },
  { label: "Claims Forgiveness", value: "Claims Forgiveness" },
  { label: "Group Rate Applied", value: "Group Rate Applied" },
  { label: "Green Home Discount", value: "Green Home Discount" },
  { label: "Good Payer Discount", value: "Good Payer Discount" },
  { label: "New Early Quote Discount", value: "New Early Quote Discount" },
  { label: "Heating", value: "Heating" },
  {
    label: "Discount - Higher Floor Unit",
    value: "Discount - Higher Floor Unit",
  },
  { label: "Hail Resistant", value: "Hail Resistant" },
  { label: "Home Security", value: "Home Security" },
  { label: "New Hot Water Tank", value: "New Hot Water Tank" },
  { label: "Long Term Occupancy", value: "Long Term Occupancy" },
  {
    label: "Mature Homeowner - In Premises 'n' Years",
    value: "Mature Homeowner - In Premises 'n' Years",
  },
  { label: "Miscellaneous", value: "Miscellaneous" },
  { label: "Multiple Family", value: "Multiple Family" },
  { label: "Mortgage Free", value: "Mortgage Free" },
  { label: "Mature Citizen", value: "Mature Citizen" },
  { label: "Multiple Line", value: "Multiple Line" },
  { label: "Multiple Property Risks", value: "Multiple Property Risks" },
  { label: "Discount - Multiple Units", value: "Discount - Multiple Units" },
  { label: "Maximum Security", value: "Maximum Security" },
  { label: "Discount New Business", value: "Discount New Business" },
  { label: "No - Claims", value: "No - Claims" },
  { label: "New Homeowner", value: "New Homeowner" },
  { label: "New Home", value: "New Home" },
  { label: "New Policy", value: "New Policy" },
  { label: "Non-smoker", value: "Non-smoker" },
  {
    label: "Neighbourhood Watch/Crime Prevention Programme",
    value: "Neighbourhood Watch/Crime Prevention Programme",
  },
  { label: "Occupation", value: "Occupation" },
  { label: "Discount - Owner Occupied", value: "Discount - Owner Occupied" },
  { label: "Cooperative (Co-op)", value: "Cooperative (Co-op)" },
  { label: "Preferred Construction", value: "Preferred Construction" },
  {
    label: "Discount - Protection Device",
    value: "Discount - Protection Device",
  },
  { label: "Professional", value: "Professional" },
  { label: "Quality Home", value: "Quality Home" },
  { label: "Retiree", value: "Retiree" },
  { label: "Renewal", value: "Renewal" },
  { label: "Discount - Self Bailing", value: "Discount - Self Bailing" },
  {
    label: "Senior Citizen (Age Related)",
    value: "Senior Citizen (Age Related)",
  },
  { label: "Smoke Detector", value: "Smoke Detector" },
  {
    label: "Discount - Safety Equipment",
    value: "Discount - Safety Equipment",
  },
  { label: "Sprinklered", value: "Sprinklered" },
  { label: "Septic System", value: "Septic System" },
  { label: "Discount - Stability", value: "Discount - Stability" },
  {
    label: "Sewer Back-up Prevention Discount",
    value: "Sewer Back-up Prevention Discount",
  },
  { label: "Mobile Home Tied Down", value: "Mobile Home Tied Down" },
  { label: "Territory Discount", value: "Territory Discount" },
  {
    label: "Tanker Shuttle Service (Fire Fighting Service)",
    value: "Tanker Shuttle Service (Fire Fighting Service)",
  },
  { label: "Tankless Water Heater", value: "Tankless Water Heater" },
  { label: "Total Discount Applied", value: "Total Discount Applied" },
  {
    label: "Discount - Unfinished basement",
    value: "Discount - Unfinished basement",
  },
  { label: "Valued/Preferred Customer", value: "Valued/Preferred Customer" },
  { label: "24-hour Video Surveillance", value: "24-hour Video Surveillance" },
  {
    label: "Watercraft (Maximum Conditions)",
    value: "Watercraft (Maximum Conditions)",
  },
  {
    label: "Discount - Watercraft Operator Certificate",
    value: "Discount - Watercraft Operator Certificate",
  },
  {
    label: "Discount - Water Flow Device",
    value: "Discount - Water Flow Device",
  },
  { label: "Water Sensor", value: "Water Sensor" },
  {
    label: "Year Built (Other than New Home)",
    value: "Year Built (Other than New Home)",
  },
  { label: "Years of Experience", value: "Years of Experience" },
  {
    label: "Absentee Landlord (Rented Dwelling)",
    value: "Absentee Landlord (Rented Dwelling)",
  },
  { label: "Occupancy", value: "Occupancy" },
  { label: "Age of Structure", value: "Age of Structure" },
  {
    label: "Secondary/Auxiliary Heating",
    value: "Secondary/Auxiliary Heating",
  },
  { label: "Business Use", value: "Business Use" },
  { label: "Prior Policy Cancellation", value: "Prior Policy Cancellation" },
  { label: "Commercial Exposure", value: "Commercial Exposure" },
  { label: "Coverage Ineligible", value: "Coverage Ineligible" },
  { label: "Claims", value: "Claims" },
  {
    label: "Surcharge - Criminal Record",
    value: "Surcharge - Criminal Record",
  },
  {
    label: "Surcharge - Risk Premium Cap ",
    value: "Surcharge - Risk Premium Cap ",
  },
  { label: "Electrical surcharges", value: "Electrical surcharges" },
  { label: "Commercial Exposure High", value: "Commercial Exposure High" },
  { label: "Commercial Exposure Low", value: "Commercial Exposure Low" },
  { label: "Commercial Exposure Medium", value: "Commercial Exposure Medium" },
  { label: "Hail Damage Susceptibility", value: "Hail Damage Susceptibility" },
  { label: "Primary Heating", value: "Primary Heating" },
  { label: "Surcharge - High Value", value: "Surcharge - High Value" },
  {
    label: "Inconsistent Insurance History",
    value: "Inconsistent Insurance History",
  },
  {
    label: "Surcharge – Discretionary Liability",
    value: "Surcharge – Discretionary Liability",
  },
  {
    label: "Location (For loss history or geographic)",
    value: "Location (For loss history or geographic)",
  },
  { label: "Log Home", value: "Log Home" },
  { label: "Miscellaneous", value: "Miscellaneous" },
  { label: "Multiple Family", value: "Multiple Family" },
  { label: "Surcharge - Mobile Home", value: "Surcharge - Mobile Home" },
  { label: "Mono-line Policy", value: "Mono-line Policy" },
  { label: "Surcharge - Multiple Units", value: "Surcharge - Multiple Units" },
  { label: "Non-payment", value: "Non-payment" },
  { label: "Age/Condition of Home", value: "Age/Condition of Home" },
  { label: "Plumbing surcharges", value: "Plumbing surcharges" },
  { label: "No Previous Insurance", value: "No Previous Insurance" },
  {
    label: "Surcharge - Personal Watercraft",
    value: "Surcharge - Personal Watercraft",
  },
  { label: "Roof surcharges", value: "Roof surcharges" },
  { label: "Surcharge - Below Grade", value: "Surcharge - Below Grade" },
  { label: "Risk Surcharge Cap", value: "Risk Surcharge Cap" },
  {
    label: "Surcharge - Standalone Dwelling",
    value: "Surcharge - Standalone Dwelling",
  },
  {
    label: "Surcharge - Seasonal Occupancy",
    value: "Surcharge - Seasonal Occupancy",
  },
  {
    label: "Surcharge - Short-Term Rental",
    value: "Surcharge - Short-Term Rental",
  },
  {
    label: "Surcharge - Under Construction",
    value: "Surcharge - Under Construction",
  },
  { label: "Water Claim Surcharge", value: "Water Claim Surcharge" },
  { label: "High Theft Risk", value: "High Theft Risk" },
];

export const coverage_description = [
  "Accidental Death and Dismemberment",
  "Actual Cash Value Endorsement",
  "Actual Cash Value for Sewer Back-up",
  "Additional Amount of Personal Property Away From Premises",
  "Additional Living Expense (Homeowners, Tenant and Condominium Forms)",
  "Additional Rebuilding Cost",
  "Alarm Warranty",
  "All Risk - Loss Assessment Coverage",
  "All Risk - Personal Property",
  "All Risk - Solar Panel",
  "All Risk - Unit Owners Additional Protection",
  "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
  "Backwater Valve Maintenance Clause",
  "Builder's Risk Insurance",
  "Building - Other than Residence for Homeowner",
  "Business Property",
  "Bylaws Endorsement",
  "Care Facility Contents Extension",
  "Claim Free Protection",
  "Commercial Stock",
  "Condominium Bare Land Loss Assessment (Property Section)",
  "Contamination",
  "Credit Card Forgery and Counterfeit Money Coverage",
  "Damage Caused by Wild Life",
  "Damage Due to Illicit Narcotics Exclusion",
  "Damage to Outdoor Swimming Pool & Attached Equipment",
  "Day Care",
  "Debris Removal",
  "Declaration of Emergency Endorsement - Extension of Termination or Expiry Date",
  "Deductible Assessment Buydown",
  "Deferred Loss Settlement Clause",
  "Demolition Increased Cost of Construction",
  "Dependents Coverage Endorsement",
  "Detached Private Structures Exclusion Endorsement",
  "Detached Private Structures Limitation Endorsement",
  "Dwelling - Residence for Homeowner",
  "Earthquake",
  "Emergency Medical Travel Insurance",
  "Endorsement for change in deductible notice",
  "Enhanced coverage option for selected clients",
  "Enhanced Internal Limits",
  "Extended Coverage Deletion",
  "Extended Theft Away from Premises",
  "Farmers Comprehensive Personal Liability",
  "Fire Department Service Charge",
  "Flood Damage (Surface Water)",
  "Furnished Rented Property",
  "Glass Breakage",
  "Glass Deductible Endorsement",
  "Golf Package Endorsement",
  "Guaranteed Replacement Cost - Building",
  "Hail Coverage Endorsement",
  "Hobby Farm",
  "Home Accessibility Extension",
  "Home Business Coverage",
  "Home Business Off-Premise",
  "Home Business On-Premise",
  "Home Freezer",
  "Identity Theft",
  "Inflation Guard Endorsement",
  "Leakage or Overflow of Domestic Fuel Oil",
  "Legal Assistance",
  "Legal Fees",
  "Legal Liability Other than Homeowners, Tenant and Condominium Forms",
  "Legal Liability - Homeowners, Tenant and Condominium",
  "Livestock - Broad Named Perils",
  "Livestock - Limited Named Perils",
  "Locked Vehicle Warranty",
  "Log Construction Settlement Limitation",
  "Malicious Damage - Building & Contents",
  "Malicious Damage on Building",
  "Malicious Damage on Contents",
  "Mass Evacuation Endorsement",
  "Mould Exclusion",
  "Named Perils - Loss Assessment Coverage",
  "Named Perils - Solar Panel",
  "Named Perils - Unit Owners Additional Protection",
  "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
  "Other (Appurtenant) Structures (Residence Premises)",
  "Other Structures - Homeowners Forms",
  "Other Structures Rented to Others (Residence Premises)",
  "Out of Province Medical",
  "Personal Effects Floater - Domiciled Endorsement",
  "Personal Liability (Umbrella Endorsement)",
  "Personal Liability (Umbrella Standalone)",
  "Personal Property - Homeowners Form",
  "Personal Property - Other than Homeowners, Tenant and Condominium Forms",
  "Personal Property - Tenant and Condominium Unit Owners Form",
  "Post-earthquake Damage",
  "Power Fluctuation",
  "Property of Students Coverage",
  "Renewable Energy Income",
  "Rental Income",
  "Rented Unit Owners Endorsement",
  "Replacement Cost - Building",
  "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
  "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
  "Replacement Cost Package",
  "Residence Burglary",
  "Restricted Water Damage",
  "Roof (Limited to Cash Value)",
  "Roof (Replacement Cost Value)",
  "Roof (Settlement Basis) due to Windstorm/Hail",
  "Self-contained Apartment",
  "Service Line Extension Endorsement",
  "Sewer Back-up Coverage",
  "Sewer Back-up Limitation",
  "Single Limit",
  "Special Loss Settlement",
  "Sprinkler Bylaw",
  "Theft and Burglary",
  "Theft Exclusion",
  "Unit Owners Other Structures",
  "Unlicensed Snow Vehicle, Trail Bike or ATV",
  "Vacancy Permit",
  "Voluntary Medical Payments",
  "Voluntary Property Damage",
  "Waived Premium",
  "Water Damage Deductible",
  "Water Damage Endorsement",
  "Water Damage Exclusion",
  "Waterbed",
  "Weight of Snow and Ice",
  "Wind Coverage Endorsement",
  "Wind/Hail Deductible Endorsement",
  "Wind/Hail Exclusion",
  "Windstorm/Hail",
  "Wood Stove Limitation",
  "Wood Stove Warranty",
  "Wreckage Value",
  "Special Limit- Bicycle",
  "Special Limit-Books, Tools, and Instruments Pertaining to a Business",
  "Special Limit- Coin Collection",
  "Special Limit-Compact Discs or Audio Tapes",
  "Special Limit-Furs",
  "Special Limit-Golf Carts",
  "Special Limit- Increased Limit for Blanket Coverage",
  "Special Limit- Jewellery/Watches/Gems",
  "Special Limit- Money and Bullion",
  "Special Limit- Personal Computer (Software)",
  "Special Limit- Securities",
  "Special Limit- Silverware",
  "Special Limit-Sports Card Collection",
  "Special Limit-Stamps",
  "Special Limit-Theft from Vehicle",
  "Special Limit- Tombstones (Grave Markers)",
  "Special Limit- Watercraft",
  "Special Limit-Windstorm",
  "Special Limit- Windstorm",
  "Miscellaneous Vehicles- All Risks",
  "Miscellaneous Vehicles- Named Perils",
  "Snowmobiles- All Risks",
  "Snowmobiles- Named Perils",
  "Travel Trailers- All Risks",
  "Travel Trailers-Broad Form",
  "Travel Trailers-Named Perils",
  "Travel Trailers-Contents - All Risks",
  "Travel Trailers-Contents - Named Perils",
  "Travel Trailers- Emergency Living Expenses",
  "Travel Trailers-Emergency Road Service",
  "Travel Trailers-Temporary Attachments - All Risks",
  "Travel Trailers-Temporary Attachments - Named Perils",
  "Watercraft- Additional Miscellaneous Equipment - All Risks",
  "Watercraft- Additional Miscellaneous Equipment - Named Perils",
  "Watercraft-Hull - All Risk",
  "Watercraft-Hull - Named Perils",
  "Watercraft-Hull - Named Perils and Marine Perils",
  "Snowmobiles- All Risks",
  "Snowmobiles- Named Perils",
  "Travel Trailers- All Risks",
  "Travel Trailers-Broad Form",
  "Travel Trailers-Named Perils",
  "Travel Trailers-Contents - All Risks",
  "Travel Trailers-Contents - Named Perils",
  "Travel Trailers-Emergency Living Expenses",
  "Travel Trailers-Emergency Road Service",
  "Travel Trailers-Temporary Attachments - All Risks",
  "Travel Trailers-Temporary Attachments - Named Perils",
  "Watercraft-Additional Miscellaneous Equipment - All Risks",
  "Watercraft-Additional Miscellaneous Equipment - Named Perils",
  "Watercraft-Hull - All Risk",
  "Watercraft-Hull - Named Perils",
  "Watercraft-Hull - Named Perils and Marine Perils",
  "Watercraft- Motor - All Risks",
  "Watercraft-Motor - Named Perils",
  "Watercraft-Motor - Named Perils and Marine Perils",
];

export const type_of_payment = [
  "Monthly",
  "Annual",
  "2 Payments(Equal) - 50% per payment",
  "3 Pay (Equal) - 33.3% per payment",
  "3 Payments (40% 1st Payment, 30% for the next 2 Payments)",
  "Payments (50% 1st Payment, 25% for the next 2 Payments)",
  "3 Year (Annual Installments)",
  "3 Year (Lump Sum Payments)",
  "4 Payments (Equal) - 25% per payment",
  "Bi-monthly (every 2 months)",
  "Bi-weekly",
  "Down Payment (indicated in PAY04) -10 Monthly Payments",
  "Non-standard (See Data Elements PAYO3 - PAYO8)",
  "Quarterly",
  "Semi-annual",
  "Six-month Policy Four Equal Payments",
  "Six-month Policy Single Payment",
  "Six-Month Policy Six Equal Payments",
  "Six-month Policy Three Equal Payment",
  "Six-month Policy Two Equal Payments Weekly",
];

export const applicant_occupation = [
  "Accountant",
  "Actuary",
  "Administrator/Director",
  "Agronomist",
  "Airline Personnel",
  "Architect",
  "Armed Forces",
  "Artist (Without Other Profession)",
  "Athlete - Professional",
  "Automobile Mechanic",
  "Automobile Sales/Dealer",
  "Bank/Financial Institution - Administration",
  "Bank/Financial Institution - Clerical",
  "Bank/Financial Institution - Management",
  "Barber, Hairdresser",
  "Carpenter",
  "Cashier",
  "Casino Worker",
  "Chartered Accountant",
  "Civil Service",
  "Clergy",
  "Coach",
  "Computer - Analyst, Consultant, Programmer, etc.",
  "Consultant - _____",
  "Consultant - Human Resources",
  "Consultant - Training",
  "Courts e.g. Bailiff, Clerk, Reporter, Stenographer, etc.",
  "Craftsman",
  "Crown Corporation Employee",
  "Daycare Worker, Provider",
  "Dental Hygienist",
  "Dentist, Orthodontist, Denturologist",
  "Dietician, Nutritionist",
  "Doctor - General Practitioner",
  "Driver - Local Delivery",
  "Driver - Long Haul, Mover",
  "Driver - Public Transportation",
  "Driver - Taxi, Chauffeur, Instructor",
  "Electrician",
  "Engineer - Professional",
  "Entertainment Field e.g. Actor, Musician, etc.",
  "Executive",
  "Factory Worker, Machine Operator, Tool & Die Worker",
  "Farmer",
  "Financial Advisor, Broker, Economist",
  "Firefighter",
  "Fisherman",
  "Fitness, Health Employee",
  "Flight Attendant",
  "Food Industry - Other e.g. Butcher, Slaughterer",
  "Forest Employee",
  "General Contractor",
  "Graphic Designer, Printer",
  "Homemaker (not Spouse at Home), Caregiver",
  "Horticulture",
  "Hotel, Motel Worker",
  "Insurance - Adjuster",
  "Insurance - Agent, Broker",
  "Insurance - Underwriter",
  "Labourer - Unskilled",
  "Lawyer",
  "Librarian",
  "Maintenance, Janitor, Cleaner",
  "Manager",
  "Marketing Employee",
  "Medical Specialist e.g. Anaesthetist, Chiropractor, Podiatrist, etc.",
  "Miner",
  "Notary",
  "Nurse",
  "Office Worker",
  "Optician",
  "Optometrist, Ophthalmologist",
  "Painter, Plasterer",
  "Pharmacist",
  "Photographer, Photography Studio",
  "Pilot",
  "Police Officer",
  "Postal Worker",
  "Property Manager",
  "Psychologist",
  "Public Transportation Worker - Other Than Airline",
  "Real Estate Agent",
  "Restaurant, Bar - Owner, Employee e.g. Waiter, Bartender, etc.",
  "Restaurant, Bar - Chef, Cook, Caterer",
  "Retail Sales Person",
  "Retired",
  "Salesperson - Commercial",
  "Scientist, Researcher e.g. Biochemist, Biologist, Chemist, etc.",
  "Seamstress",
  "Security Officer, Worker",
  "Self-Employed - ____",
  "Self-Employed - Services e.g. Chimney Sweep, Lawn Care, Window Cleaner",
  "Spouse at Home",
  "Student",
  "Supervisor, Foreman",
  "Surveyor",
  "Teacher, Principal, Professor, Lecturer",
  "Technicians - Research, Medical",
  "Therapist",
  "Tradesman",
  "Unemployed",
  "Urbanist (Professional Urban Planner)",
  "Veterinarian",
  "Writer, Publisher",
];

export const cause_of_loss_history = [
  "Appliance - Malfunction",
  "Bodily Injury",
  "Burglary",
  "Collapse - Above Ground Pool",
  "Collapse - Building",
  "Collapse - Inground Pool",
  "Collapse - Unspecified",
  "Collapse - Weight of Ice/Snow",
  "Consequential Loss",
  "Credit Card Forgery and Counterfeit Money",
  "Domestic Pets",
  "Earthquake",
  "Electrical Power Interruption/Power Surge",
  "Employee Fraud",
  "Entrapment",
  "Explosion - Electric Arcing",
  "Explosion - Propane/Natural Gas Appliance",
  "Explosion - Unspecified",
  "Falling Object",
  "Financial Fraud - Identity Theft",
  "Fire - Arson/Arson Suspected",
  "Fire-Careless Smoking",
  "Fire - Careless use of Candles/Matches",
  "Fire - Cooking Operations/Grease Fire",
  "Fire - Electrical",
  "Fire - Fireplace/Chimney",
  "Fire - Improper Storage & Handling of Chemicals/Fuels",
  "Fire - Portable Heater",
  "Fire - Unspecified",
  "Fire - Woodstove/Chimney",
  "Fire Department Charges",
  "Fire-Spread from Third Party Property",
  "Forgery",
  "Freezer Contents",
  "Fuel Leakage - Exterior Oil Storage Tank",
  "Fuel Leakage - Interior Oil Storage Tank",
  "Glass Breakage - Accidental",
  "Glass Breakage - Unspecified",
  "Glass Breakage - Vandalism",
  "Hail Damage",
  "Impact - Aircraft",
  "Impact - Land Vehicle, Insured Operator",
  "Impact - Land Vehicle, Third Party Operator",
  "Lightning - Antenna/Dish",
  "Lightning - Chimney",
  "Lightning - Electrical Equipment",
  "Lightning - Unspecified",
  "Loss Assessment Coverage",
  "Lost/Stolen Keys",
  "Mass Evacuation",
  "Mysterious Disappearance",
  "Pollution Damage",
  "Pollution Liability",
  "Prohibited Access by Civil Authority",
  "Property Damage",
  "Riot",
  "Robbery",
  "Sewer Backup",
  "Smoke - Fire in Adjacent Premises/Building",
  "Smoke - Heating/Ventilation Malfunction",
  "Smoke - Unspecified",
  "Tenants Legal Liability",
  "Theft/Attempted Theft - Off Premises, Non-vehicle",
  "Theft/Attempted Theft - Off Premises, Vehicle",
  "Theft/Attempted Theft - On Premises, Non-forced Entry",
  "Transportation - Collision/Upset/Overturn",
  "Transportation - Unspecified",
  "Vandalism/Malicious Damage - Building Vacant",
  "Vandalism/Malicious Damage - Explosion",
  "Vandalism/Malicious Damage - Illegal Substance Cultivation & Manufacturing",
  "Vandalism/Malicious Damage - Insured Suspect",
  "Vandalism/Malicious Damage - Unspecified",
  "Voluntary Compensation",
  "Voluntary Medical Payment",
  "Voluntary Property Damage",
  "Water Damage - Ice Build-up on Roof",
  "Water Damage - Infiltration",
  "Water Damage - Unspecified",
  "Water Escape/Rupture - Building",
  "Water Escape/Rupture - Contents",
  "Water Escape/Rupture - Eavestrough or Downspout",
  "Water Escape/Rupture - Freezing Building",
  "Water Escape/Rupture - Freezing, Unheated Building",
  "Water Escape/Rupture - Plumbing System",
  "Water Escape/Rupture - Seepage/Leakage",
  "Water Escape/Rupture - Sewer/Drain",
  "Water Escape/Rupture - Sprinkler System",
  "Water Escape/Rupture - Sump Pump Failure",
  "Water Escape/Rupture - Swimming Pool/Equipment",
  "Water Escape/Rupture - Unspecified",
  "Water Escape/Rupture - Waterbed",
  "Windstorm Damage",
];

export const reason_for_ending = [
  "Cancelled by Insurer",
  "Declined by Insurer",
  "Cancelled by Insured",
  "Non-Renewed by Insurer",
  "Non-Renewed by Insured",
  "Insurer Restricted Coverage",
];

export const terminated_by_insurer = [
  "Insurance Fraud",
  "Loss History",
  "Material Misrepresentation",
  "No Prior Insurance",
  "Non-disclosure",
  "Non-payment",
];

export const cross_ref_information = [
  "Private Passenger Auto",
  "Commercial Autos, Fleets, Trucks",
  "Farm Owners, Fire, Liability",
  "Habitational",
  "Miscellaneous Commercial Lines",
  "Miscellaneous Life and Health",
  "Miscellaneous Personal Lines",
  "Package Policy",
  "Personal Lines Umbrella",
];

export const coverage_claim_paid_under = [
  "Bodily Injury",
  "Property Damage",
  "Accident Benefits",
  "Direct Compensation - Property Damage",
  "Uninsured Automobile",
  "Coll-Collision/All Perils",
  "Comprehensive/Specified Perils",
];

export const policies_coverage_type = [
  "Term",
  "Whole Life",
  "Critical Illness",
  "Disability",
  "Long Term Care",
];

export const conviction_code = [
  "99999 Other (Attach SRMK-Remarks Group)",
  "ACCOM Excess blood alcohol while accomp. class G1 driver",
  "AIIP Alcohol Ignition Interlock Program",
  "ALC Blood Alcohol over .08",
  "ALCOP Open liquor in vehicle",
  "BACK Improper backing-up",
  "BIKE Interfering with bicyclist",
  "BL Improper driving in a Bus Lane",
  "BUS Public vehicle infraction",
  "CC Careless driving (Highway Traffic Act - Alberta only)",
  "CD Careless driving, without due care or attention (Criminal C)",
  "CN Criminal Negligence",
  "CPV Cellular Phone Violation",
  "DAB License class (restricted) drive with alcohol in blood",
  "DANGR Dangerous goods transport",
  "DB Defective Brakes",
  "DBW Driving on a bet or wager",
  "DD Dangerous Driving",
  "DDC Driving during curfew",
  "DEP License class (restricted) drive with excess passengers",
  "DFPB License class G1 accomp. driver - failrefuse provide breatt",
  "DFSP Class G1 drive with front seat passenger",
  "DFTI License class G1/G2 accompanying driver - failure to identify",
  "DH Improper use of divided highway",
  "DITA Driver has been involved in traffic accident (non-chargeable)",
  "DLC Drive left of centre",
  "DMP Class M1 drive motorcycle with passenger",
  "DMPH Class M1 drive motorcycle on prohibited highway",
  "DMU Driving motorcycle - usage violation",
  "DOSW Drive on sidewalk/footpath/ditch/median/closed road",
  "DPH Class G1 drive on prohibited highway",
  "DRD Driver distraction",
  "DS Crowding Drivers Seat",
  "DSV Drive a suspended vehicle (fail to meet safety requirements)",
  "DUH Glass G1/M1 drive at unlawful hour",
  "DUQD Class G1 drive unaccompanied by qualified driver",
  "DUS Driving while under suspension",
  "DWA Driving while Unauthorized",
  "DWID Driving without an interlock device",
  "EPH Entering prohibited highway",
  "EPHRV Drive recreational vehicle on prohibited roadway",
  "EXSPD Exceed speed for established time period",
  "FALST False statement re insurance",
  "FCIC Failure to carry or have insurance card",
  "FCRMS Failure to complete remedial programme FDH Failure to report damage to highway property FDP Failure to disclose particulars",
  "FP (Use code: 'FTSPO' instead)",
  "FPBT Failure to pass breath or blood test",
  "FPEI Failure to produce evidence of insurance",
  "FPHS Failure to proceed onto highway safely FPRV Failure to produce - recreational vehicle FRA Failing to report accident",
  "FRSL Failure or refusal to surrender licence",
  "FSCEV Failure to slow/change lane for stationary emergency vehicle FSLC Failure to stop at level crossing when driving school bus",
  "FSR Failing to share road",
  "FTC Following too closely",
  "FTGN Failure to give name/address information",
  "FTPA Failure to provide access",
  "FTPP Failure to permit passing",
  "FTR Failing to remain",
  "FTRA Failure to render all possible assistance",
  "FTS Failing to signal",
  "FTSLS Failure to stop for police resulting in license suspension",
  "FTSPO Failure to stop at the request of a police officer",
  "FTSSA Failure to stop at the scene of an accident",
  "FTSTP Failure to stop",
  "FTT Failure to turn",
  "FTY Failing to yield",
  "G1VL License class G1 violation",
  "G2VL License class G2 violation",
  "HELMT Failure of motorcyclist to wear helmet",
  "HL Headlight Offenses",
  "ILC Improper Lane change",
  "IMP Impaired driving",
  "IMPRU Imprudent Driver",
  "INS *Formerly Insurance Offence 'Use Specific Code'",
  "INSRV No insurance - recreational vehicle",
  "IOD Improper opening of door",
  "IP Improper passing",
  "ISBV Insufficient space between vehicles (if no accident ensues) IT Improper turn",
  "IUEE Improper use of emergency equipment or markings",
  "LD Insecure load",
  "LEFT Drive commercial vehicle in the left lane",
  "LIC Drivers License Violations",
  "LICPL License plate offence",
  "M1VL License class M1 violation",
  "M2VL License class M2 violation",
  "MAJOR Major Conviction (not specified)",
  "MINOR Minor Conviction (not specified) MKFS Make a false statement",
  "MM Motor manslaughter",
  "MOPED Motor-assisted bicycle infraction",
  "NDICL No driver's license or improper class of driver's license",
  "NDRV No driver's license - recreational vehicle",
  "NODRI Novice driver fail/refuse breath sample",
  "NSPP No special permit or placard",
  "OLO Overload",
  "OMVNI Operate motor vehicle - no insurance",
  "OT Obstructing traffic",
  "OW Wrong way on a one-way",
  "PARK Parking, standing or stopping",
  "PERNO Permit novice drive in contravention of restrict.",
  "PFI Produce false insurance",
  "PRE Produce false evidence",
  "PROC Intersect procession in motion",
  "PSB School bus - improper passing or failure to stop",
  "PSG School playground - improper passing",
  "PUT Prohibited use of tunnel by a vehicle carrying dangerous substances",
  "PX Fail to yield to pedestrian",
  "RAC Racing",
  "RADR Radar warning device in the vehicle",
  "RB Refuse breathalyzer",
  "REUV Refusal examination of an unsafe vehicle",
  "RI Reinstated",
  "RRX Railway crossing",
  "SB Seat belt violation",
  "SBP Driver failure to ensure a passenger under 16 yr/23 kg secured",
  "SCBUS School bus infractions",
  "SCS School crossing stop signal-failure to obey",
  "SD Unnecessarily slow driving",
  "SECTD Serious Conviction (not specified)",
  "SOLIC Soliciting offence",
  "SP Speeding (enter the number of km over the limit) SPEED Speeding-in alley/crown property/flashing light SPOVR Speeding over (Company Major Conviction)",
  "SS Stop sign",
  "SSZ Speed-school zone/playground",
  "STN Stunting",
  "SUP Suspended (enter the number of months suspended) SUP Suspendu (entrer le nombre de mois de suspension) SUPLF Suspended for life",
  "TLV Traffic Lane violation TP Trailer passenger",
  "TRLR Trailer - offence regarding",
  "TS Disobey traffic sign or signal",
  "TSL (Use code: 'TS' instead)",
  "TW Improper towing, persons on sled, bicycle, etc. UDW Unsafe Driving due to weather/road conditions UM Unsafe move",
  "UN Unnecessary noise",
  "UT Unsafe or prohibited turn",
  "UV Unsafe Vehicle",
  "VIV Vehicle inspection",
  "VOWI Vehicle owner without insurance",
  "VRO Vehicle Registration Offense",
  "WIN View obstructed",
  "XOVER Pedestrian violation",
];

export const coverage_type_one = [
  "Cross Liability",
  "Waiver of Subrogation",
  "Pollution Liability Extension",
  "Broad Form - Blanket Over Complex",
  "Replacement Cost - Same Site Clause Deleted",
  "Stated Amount Co-Insurance",
  "Building By Law-Extension",
];

export const coverage_type_four = [
  "Actual Loss Sustained",
  "Profits (Board/Named)",
  "Gross Earnings - Mercantile or Non-Manufacturing",
  "Gross Earnings - Manufacturing Business Income",
  "Extended Business Income",
  "Extended Rental Income",
  "Gross Rentals",
  "Extra Expense",
];

export const coverage_type_five = [
  "Contractor's Equipment",
  "Equipment Floater",
  "Tools Floater",
  "Installation Floater",
  "Sign Floater (Board)",
  "Exhibition Floater",
  "Fine Arts Floater (Board)",
  "Miscellaneous Property Floater",
];

export const machinery_breakdown = [
  "Machinery Breakdown/Boiler & Machinery",
  "Business Interruption (Actual Loss)",
  "Business Interruption (Loss of Profit)",
  "Business Interruption (Gross Earnings)",
  "Business Interruption (Rent or Rental Value)",
  "Business Interruption (Extra Expense)",
];

export const crime_insurance_coverage = [
  "Comprehensive 3D",
  "Employee Dishonesty Coverage Form A",
  "Loss Inside the Premises Coverage",
  "Loss Outside the Premises Coverage",
  "Money & Securities",
  "Money Orders & Counterfeit Paper Currency Coverage",
  "Depositor's Forgery Coverage",
];

export const interested_interest = [
  "1st Mortgagee",
  "2nd Mortgagee",
  "3rd Mortgagee",
  "Additional Insured",
  "Additional Named Insured",
  "First Mortgagee",
  "First Mortgagee and Additional Insured",
  "First Mortgagee and First Loss Payee",
  "Landlord",
  "Lessor",
  "Lienholder",
  "Loss Payee",
  "Mortgagee",
  "NA",
];

export const detached_structure_type = [
  "Barn",
  "Boathouse",
  "Chicken Coop",
  "Cottage",
  "Garage",
  "Greenhouse",
  "Sugar Camp",
  "Servant's Quaters",
  "Storage",
  "Stable",
  "Toolshed",
];

export const best_time_to_contact = [
  "Anytime",
  "7:00 am to 9:00 am",
  "9:00 am to 11:30 am",
  "11:30 am to 1:00 pm",
  "1:00 pm to 3:00 pm",
  "3:00 pm to 5:00 pm",
  "5:00 pm to 8:00 pm",
];

export const Protection_One_Two_Three_and_Four = [
  "Doors-deadbolt",
  "Breakage resistant glass",
  "Windows-barred",
  "Windows-wire mesh",
  "Steel bars on openings",
  "Surveillance cameras",
  "Watchmen/security guards",
  "Fence",
  "Guard Dog",
  "Additional combination lock",
  "Additional key",
  "Alarm 'fine wire' protecting openings",
  "Camera with concealed VCR recording on film",
  "Common tenant walls reinforced with steel mesh",
  "Electronic lock",
  "Entrance visible from street",
  "Exterior doors of sturdy construction, inside hinges",
  "Exterior lighting",
  "Hold-up buttons",
  "Metal loading doors, secured internally",
  "Motion-sensitive lighting",
  "Multiple lock styles",
  "Perimeter fence and lockable gate",
  "Property/lot illuminated at night",
  "Skylight/Roof AC openings blocked off",
  "Steel post (front & rear) to prevent vehicle entry",
  "Stock secured - separate enclosure",
  "Stockroom ceiling entirely covered by motion sensor",
  "Warehouse area alarmed separately from office",
  "Warning signs",
  "Windows - Glass breakage detectors",
  "Other",
];

export const suspension_reason = [
  { label: "Accident Related", value: "A" },
  { label: "Criminal Code Conviction", value: "C" },
  { label: "Driving while under the Influence (Drugs or Alcohol)", value: "D" },
  { label: "Operating without Insurance", value: "I" },
  { label: "Operating without a license", value: "L" },
  { label: "Other (Attach a Remarks Group explaining)", value: "O" },
  { label: "Points Accumulation", value: "P" },
  { label: "Speeding", value: "S" },
  { label: "Unpaid Ticket", value: "T" },
];

export const automobile_suspension_reason = [
  { label: "Accident Related", value: "A" },
  { label: "Criminal Code Conviction", value: "C" },
  { label: "Driving while under the Influence (Drugs or Alcohol)", value: "D" },
  { label: "Operating without Insurance", value: "I" },
  { label: "Operating without a license", value: "L" },
  { label: "Other (Attach a Remarks Group explaining)", value: "O" },
  { label: "Points Accumulation", value: "P" },
  { label: "Speeding", value: "S" },
  { label: "Unpaid Ticket", value: "T" },
  { label: "Administrative Suspension", value: "Administrative Suspension" },
  {
    label: "Non-Administrative Suspension",
    value: "Non-Administrative Suspension",
  },
];

export const type_code_options = [
  { label: "None", value: "0" },
  { label: "Electronic Alarm (Audible)", value: "1" },
  { label: "Electronic Alarm plus One Other", value: "2" },
  { label: "Engraving", value: "3" },
  { label: "Engraving plus One Other", value: "4" },
  { label: "Startup Blockage Mechanism With Deactivator", value: "5" },
  {
    label: "Startup Blockage Mechanism With Deactivator and engraving",
    value: "6",
  },
  { label: "Steering Blocking Bar", value: "7" },
  { label: "Satellite Monitored", value: "8" },
  { label: "Other (Attach a 5RMK-Remarks Group to specify)", value: "9" },
  { label: "Ignition, Siren and Sensor", value: "A" },
  { label: "Steering Column Armoured Collars", value: "B" },
  { label: "Monitored", value: "D" },
  { label: "Fuel Disable", value: "F" },
  { label: "Impact", value: "I" },
  { label: "Original Manufacturer Anti-Theft Package", value: "M" },
  { label: "Manual Arming Independent of Central Locking System", value: "N" },
  { label: "Motion Sensor", value: "S" },
  { label: "Tire Locks", value: "T" },
];

export const product_type_options = [
  {
    label: "Vehicle Manufacturer/Original Equipment Manufacturer",
    value: "01",
  },
  { label: "PRO-TG", value: "02" },
  { label: "Sherlock", value: "03" },
  { label: "SPAV", value: "04" },
  { label: "Super Duro-garde", value: "05" },
  { label: "Doc", value: "06" },
  { label: "Auto-garde", value: "07" },
  { label: "RAMCO", value: "08" },
  { label: "Apple", value: "09" },
  { label: "Boomerang", value: "10" },
  { label: "Duro VIP", value: "11" },
  { label: "VSS 150", value: "12" },
  { label: "Automate", value: "13" },
  { label: "Theftbuster TB300V (VICC approved)", value: "14" },
  { label: "Autowatch 329TI (VICC approved)", value: "15" },
  { label: "Magtec 6000 (VICC approved)", value: "16" },
  { label: "Powerlock Canada (VICC approved)", value: "17" },
  { label: "Alpine", value: "18" },
  { label: "Autostart", value: "19" },
  { label: "Auto Gravure", value: "1A" },
  { label: "Auto Tatou", value: "1B" },
  { label: "Autoblock", value: "1C" },
  { label: "Autographe", value: "1D" },
  { label: "Autotrack", value: "1E" },
  { label: "CelluTrak Peace", value: "1F" },
  { label: "CelluTrak Recovery Centraxx", value: "1G" },
  { label: "11 Club", value: "1H" },
  { label: "Factory", value: "1J" },
  { label: "Harisson Starter", value: "1K" },
  { label: "Invisiguard", value: "1L" },
  { label: "Kill", value: "1M" },
  { label: "Mobicom", value: "1N" },
  { label: "Mobiguard", value: "10" },
  { label: "Ranger", value: "1P" },
  { label: "Tag", value: "1Q" },
  { label: "Autowatch", value: "20" },
  { label: "Boomerang I", value: "21" },
  { label: "Boomerang II", value: "22" },
  { label: "Carbine", value: "23" },
  { label: "Clifford", value: "24" },
  { label: "Duro", value: "25" },
  { label: "Intercepter", value: "26" },
  { label: "Magtec", value: "27" },
  { label: "Marksman", value: "28" },
  { label: "Mobilus", value: "29" },
  { label: "Onstar", value: "30" },
  { label: "Powerstart", value: "31" },
  { label: "Prestige", value: "32" },
  { label: "Pursuit", value: "33" },
  { label: "Python", value: "34" },
  { label: "SPAVTrack", value: "35" },
  { label: "SSN", value: "36" },
  { label: "SSN (Mul-T-Lock)", value: "37" },
  { label: "Tiger", value: "38" },
  { label: "Ultracar", value: "39" },
  { label: "Ungo", value: "40" },
  { label: "Vigil", value: "41" },
  { label: "Viper", value: "42" },
  { label: "Other Cellular Technology bases system", value: "43" },
  { label: "Other GPS bases system", value: "44" },
  { label: "Astrolock", value: "45" },
  { label: "Audiovox", value: "46" },
  { label: "Collard Steadfast", value: "47" },
  { label: "Doc 4000", value: "48" },
  { label: "Duro Garde", value: "49" },
  { label: "Gemni (Skylink GPS)", value: "50" },
  { label: "Hornet", value: "51" },
  { label: "Investiguard", value: "52" },
  { label: "Lynx", value: "53" },
  { label: "Micro-protec", value: "54" },
  { label: "Otomax", value: "55" },
  { label: "Planet Track", value: "56" },
  { label: "Pro-Gard", value: "57" },
  { label: "Stealstopper", value: "58" },
  { label: "Tiger 1000", value: "59" },
  { label: "Autograph", value: "60" },
  { label: "Vinlock", value: "61" },
  { label: "MasterGard M6000", value: "62" },
  { label: "MasterGard TK", value: "63" },
  { label: "Canam", value: "64" },
  { label: "Pro-TG X000", value: "65" },
  { label: "Spay 06", value: "66" },
  { label: "Doc 1000/20000", value: "67" },
  { label: "Doc X000", value: "68" },
  { label: "Otoprotec", value: "69" },
  { label: "Pass-Key", value: "70" },
  { label: "Nav-Lynx", value: "71" },
  { label: "Boomerang Espion", value: "72" },
  { label: "Boomerang Espion Alert", value: "73" },
  { label: "Onstar", value: "74" },
  { label: "Viper 200Max", value: "75" },
  { label: "Viper 220Max", value: "76" },
  { label: "Triumph with Siren", value: "77" },
  { label: "Merlin 2000", value: "78" },
  { label: "Harley with Siren", value: "79" },
  { label: "BMW", value: "80" },
  { label: "Scorpio SR1500E", value: "81" },
  { label: "Scorpio SR1500S", value: "82" },
  { label: "Tridion T2", value: "83" },
  { label: "Autoluck", value: "84" },
  { label: "Datadot", value: "85" },
  { label: "Guardian", value: "86" },
  { label: "Larlock", value: "87" },
  { label: "Kolombo URB 1500", value: "88" },
  { label: "Kolombo URB 2000", value: "89" },
  { label: "Boomerang Express", value: "90" },
  { label: "Econotrack", value: "91" },
  {
    label: "Boomerang / LoJack Espion (radio frequency technology)",
    value: "92",
  },
  {
    label: "Boomerang / LoJack Espion Alert (radio frequency technology)",
    value: "93",
  },
  {
    label: "Boomerang / LoJack Espion Alert Plus (radio frequency technology)",
    value: "94",
  },
  { label: "3rd Eye Tracking", value: "95" },
  { label: "Orca", value: "96" },
  { label: "M-Link", value: "97" },
  { label: "Astus", value: "98" },
  { label: "Other (Attach a 5RMK - Remarks Group to specify)", value: "99" },
  { label: "1 step to deactivate", value: "1" },
  { label: "2 or more steps to deactivate", value: "2" },
  { label: "Not recognized", value: "3" },
  { label: "Recognized", value: "4" },
  { label: "Not approved by IBC's Vehicle Information Centre", value: "5" },
  { label: "Approved by IBC's Vehicle Information Centre", value: "6" },
  { label: "Other (Attach a 5RMK - Remarks Group to specify)", value: "9" },
  { label: "None", value: "00" },
  { label: "Autograph", value: "01" },
  { label: "Automobile Manufacturer", value: "02" },
  { label: "Sherlock", value: "03" },
  { label: "Autoluck", value: "04" },
  { label: "DataDot", value: "05" },
  { label: "Guardian", value: "06" },
  { label: "Larlock", value: "07" },
  { label: "Microdot", value: "08" },
  { label: "Otoprotec", value: "09" },
  { label: "Tag", value: "10" },
  { label: "Vin Lock", value: "11" },
  { label: "Other (Attach a 5RMK - Remarks Group to specify)", value: "99" },
];

export const vehicle_schedule_dropdown = [
  "O.E.F. 82: Liability for Damage to Non-Owned Automobiles and Drive Other Automobiles - Named Persons Endorsement",
  "O.E.F. 83: Automobile Transportation Endorsement (for Ontario Garage Automobile Policy O.A.P. 4)",
  "O.E.F. 84: Owned Automobiles - Agreed Limit for Automobile Electronic Accessories and Equipment",
  "O.E.F. 85: Final Premium Computation Endorsement (for Ontario Garage Automobile Policy O.A.P. 4)",
  "O.E.F. 86: Customer's Automobiles - Fire & Theft Deductible Endorsement",
  "O.E.F. 87: Added Coverage to Offset Tort Deductible",
  "O.E.F. 98A: Excluded Driver (for attachment only to the Standard Non-Owned Automobile Policy S.P.F. No. 6)",
  "O.E.F. 98B: Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement (for attachment only to the Standard Non-Owned Automobile Policy S.P.F. No. 6)",
  "O.E.F. 110: Reduced Coverage for Lessees or Drivers of Leased Vehicles Endorsement (for attachment only to the Standard Excess Automobile Policy S.P.F. No.7)",
  "O.E.F. 111 Electronic addition endorsement (for attachment only to the Standard Excess Automobile Policy S.P.F. No. 7)",
  "O.E.F. 120: Reduction of Coverage for Lessees or Drivers of Leased vehicles Endorsement (for attachment only to the Standard Lessor’s Contingent Automobile Policy S.P.F. No. 8)​",
];

export const select_vehicle_coverage_dropdown = [
  "All vehicles owned by or leased by insured",
  "All vehicles owned by and licensed, or leased, in the name of insured",
  "All vehicles owned by or operated by the named insured",
  "All customer vehicles operated by named insured",
  "All vehicles owned by, registered to, or leased by the named insured",
  "As per standard garage policy wording",
  "As per OPCF 21B Blanket Basis Fleet Endorsement",
  "All vehicles owned, operated, and/or leased to named insured",
];

export const business_history_values = [
  { label: "Policy", value: "policy" },
  {
    label: "Add Marketing History",
    value: "policy_business_add_marketing_history",
  },
  {
    label: "Additional Interest",
    value: "policy_business_additional_interest",
  },
  { label: "Attachments", value: "policy_business_attachments" },
  { label: "Binder", value: "policy_business_binder" },
  { label: "Claims", value: "policy_business_claims_dim" },
  { label: "Contractor", value: "policy_business_contractor" },
  {
    label: "Coverage Summary",
    value: "policy_business_coverage_summary",
  },
  {
    label: "Customer Info",
    value: "policy_business_customer_info_dim",
  },
  { label: "Equipment", value: "policy_business_equipment" },
  { label: "Fine Art", value: "policy_business_fine_art" },
  {
    label: "Garage Insurance Certificate",
    value: "policy_business_garage_insurance_certificate",
  },
  {
    label: "Interested Parties",
    value: "policy_business_interested_parties",
  },
  {
    label: "Liability Insurance Certificate",
    value: "policy_business_liability_insurance_certificate",
  },
  {
    label: "Policy Admin",
    value: "policy_business_policy_admin_dim",
  },
  {
    label: "Pre Authorization Form",
    value: "policy_business_pre_authorization_form",
  },
  { label: "Premium", value: "policy_business_premium_dim" },
  {
    label: "Premium Finance Agreement",
    value: "policy_business_premium_finance_agreement",
  },
  {
    label: "Prior Policies",
    value: "policy_business_prior_policies_dim",
  },
  {
    label: "Property Insurance Certificate",
    value: "policy_business_property_insurance_certificate",
  },
  { label: "Restaurant", value: "policy_business_restaurant" },
  { label: "Risk Location", value: "policy_business_risk_location" },
  { label: "Subscription", value: "policy_business_subscription" },
  { label: "Tool Floater", value: "policy_business_tool_floater" },
  {
    label: "Underlying Insurance",
    value: "policy_business_underlying_insurance",
  },
  { label: "Underwriting", value: "policy_business_underwriting" },
  { label: "Commission", value: "policy_commission" },
  {
    label: "No Policy Driver Insurance Reports",
    value: "policy_no_policy_driver_insurance_reports",
  },
  { label: "Underlying Insurance", value: "policy_underlying_insurance" },
];

export const automobile_history_values = [
  {
    label: "Additional Information",
    value: "policy_automobile_additional_information",
  },
  {
    label: "Attachments",
    value: "policy_automobile_attachments_dim",
  },
  {
    label: "Application",
    value: "policy_automobile_automobile_application",
  },
  {
    label: "Customer Info",
    value: "policy_automobile_customer_info_dim",
  },
  {
    label: "Driver Claims",
    value: "policy_automobile_driver_claims_dim",
  },
  {
    label: "Driver Convictions",
    value: "policy_automobile_driver_convictions_dim",
  },
  { label: "Driver", value: "policy_automobile_driver_dim" },
  {
    label: "Driver Insurance Reports",
    value: "policy_automobile_driver_insurance_reports_dim",
  },
  {
    label: "Interested Party",
    value: "policy_automobile_interested_party",
  },
  {
    label: "Liability Insurance Certificate",
    value: "policy_automobile_liability_insurance_certificate",
  },
  {
    label: "Liability Slip Certificate",
    value: "policy_automobile_liability_slip_certificate",
  },
  {
    label: "OPCF 17",
    value: "policy_automobile_opcf_seventeen_certificate",
  },
  {
    label: "OPCF 16",
    value: "policy_automobile_opcf_sixteen_certificate",
  },
  {
    label: "OPCF 28A",
    value: "policy_automobile_opcf_twenty_eight_a_certificate",
  },
  { label: "PAF", value: "policy_automobile_paf" },
  {
    label: "Policy Admin",
    value: "policy_automobile_policy_admin_dim",
  },
  { label: "Premium", value: "policy_automobile_premium_dim" },
  {
    label: "Prior Policies",
    value: "policy_automobile_prior_policies_dim",
  },
  {
    label: "CVS Schedule A",
    value: "policy_automobile_vcs_schedule_a",
  },
  {
    label: "Vehicle Information",
    value: "policy_automobile_vehicle_information",
  },
];

export const commauto_history_values = [
  {
    label: "Add Marketing History",
    value: "policy_commauto_add_marketing_history",
  },
  {
    label: "Additional Information",
    value: "policy_commauto_additional_information",
  },
  {
    label: "Attachments",
    value: "policy_commauto_attachments",
  },
  {
    label: "Automobile Application",
    value: "policy_commauto_automobile_application",
  },
  {
    label: "Customer Info",
    value: "policy_commauto_customer_info",
  },
  {
    label: "CVS Automobile Use",
    value: "policy_commauto_cvs_automobile_use",
  },
  {
    label: "CVS Details",
    value: "policy_commauto_cvs_details",
  },
  {
    label: "CVS Required",
    value: "policy_commauto_cvs_required",
  },
  { label: "Driver", value: "policy_commauto_driver" },
  {
    label: "Driver Claims",
    value: "policy_commauto_driver_claims",
  },
  {
    label: "Driver Convictions",
    value: "policy_commauto_driver_convictions",
  },
  {
    label: "Driver Insurance Reports",
    value: "policy_commauto_driver_insurance_reports",
  },
  {
    label: "Garage Application",
    value: "policy_commauto_garage_application",
  },
  {
    label: "Garage Insurance Certificate",
    value: "policy_commauto_garage_insurance_certificate",
  },
  {
    label: "Garage Plates",
    value: "policy_commauto_garage_plates",
  },
  {
    label: "Interested Party",
    value: "policy_commauto_interested_party",
  },
  {
    label: "Liability Insurance Certificate",
    value: "policy_commauto_liability_insurance_certificate",
  },
  {
    label: "Liability Slip Certificate",
    value: "policy_commauto_liability_slip_certificate",
  },
  {
    label: "OPCF 17",
    value: "policy_commauto_opcf_seventeen_certificate",
  },
  {
    label: "OPCF 16",
    value: "policy_commauto_opcf_sixteen_certificate",
  },
  {
    label: "OPCF 28A",
    value: "policy_commauto_opcf_twenty_eight_a_certificate",
  },
  { label: "PAF", value: "policy_commauto_paf" },
  {
    label: "Policy Admin",
    value: "policy_commauto_policy_admin",
  },
  { label: "Premium", value: "policy_commauto_premium" },
  {
    label: "Prior Policies",
    value: "policy_commauto_prior_policies",
  },
  {
    label: "Property Insurance Certificate",
    value: "policy_commauto_property_insurance_certificate",
  },
  {
    label: "CVS Schedule A",
    value: "policy_commauto_vcs_schedule_a",
  },
  {
    label: "CVS Schedule B",
    value: "policy_commauto_vcs_schedule_b",
  },
  {
    label: "CVS Schedule C",
    value: "policy_commauto_vcs_schedule_c",
  },
  {
    label: "Vehicle Information",
    value: "policy_commauto_vehicle_information",
  },
];

export const habitational_history_values = [
  {
    label: "Application",
    value: "policy_habitational_application",
  },
  {
    label: "Attachments",
    value: "policy_habitational_attachments",
  },
  { label: "Binder", value: "policy_habitational_binder" },
  {
    label: "Binder Non CSIO",
    value: "policy_habitational_binder_non_csio",
  },
  { label: "Claims", value: "policy_habitational_claims" },
  {
    label: "Customer Info",
    value: "policy_habitational_customer_info",
  },
  {
    label: "Liability Insurance Certificate",
    value: "policy_habitational_liability_insurance_certificate",
  },
  {
    label: "Payment Authorization",
    value: "policy_habitational_payment_authorization",
  },
  {
    label: "Policy Admin",
    value: "policy_habitational_policy_admin",
  },
  { label: "Premium", value: "policy_habitational_premium" },
  {
    label: "Prior Policies",
    value: "policy_habitational_prior_policies",
  },
];

export const life_history_values = [
  {
    label: "Existing Property",
    value: "policy_life_additional_information_existing_property",
  },
  {
    label: "Family Tree",
    value: "policy_life_additional_information_family_tree",
  },
  {
    label: "Attachment",
    value: "policy_life_basic_details_attachment",
  },
  {
    label: "Claim",
    value: "policy_life_basic_details_claim",
  },
  {
    label: "Customer Info",
    value: "policy_life_basic_details_customer_info",
  },
  {
    label: "Policy Admin",
    value: "policy_life_basic_details_policy_admin",
  },
  {
    label: "Premium",
    value: "policy_life_basic_details_premium",
  },
  {
    label: "Coverage Needs",
    value: "policy_life_insurance_policies_coverage_needs",
  },
  { label: "Policies", value: "policy_life_policies" },
  {
    label: "Compliance Checklist",
    value: "policy_life_policies_compliance_checklist",
  },
];

export const jobTitle = [
  "CSR",
  "Marketer",
  "Producer",
  "Branch Manager",
  "Administartor",
  "President",
  "CEO",
  "COO",
  "Manager",
  "Manager-PL",
  "Manager-CL",
  "Account Executive",
  "Account Manager",
  "Managing Partner",
  "Accountant",
  "Sr. Accountant",
  "Vice President",
  "TSR",
  "Account Manager-CL",
  "Reception",
];

export const gl_account_options = [
  "G/L Code - Agency Bill Receivables",
  "G/L Code - Direct Bill Receivables",
  "G/L Code - Net Income",
  "G/L Code - Retained Earnings",
  "G/L Code - Report Heading Account",
  "G/L Code - Cash Disbursements Asset Account",
  "G/L Code - Agency Bill Trust Account",
  "G/L Code - General Cash Receipts Account",
  "G/L Code - Agency Bill Tax Payable Account",
  "G/L Code - Agency Bill Tax #2 Payable Account",
  "G/L Code - VAT on Direct Bill Commissions Payable Account",
  "G/L Code - VAT on Agency Bill Earned Commissions Expense Account",
  "G/L Code - Agency Bill Tax Trust Account",
  "G/L Code - Direct Bill Bonus Commission Account",
  "G/L Code - Direct Bill Charge Back Commission Account",
  "G/L Code - Direct Bill Charge Back Bonus Account",
  "G/L Code - Finance and Service Charge Income Account",
  "G/L Code - Automatic Writeoff / Waiver Account",
];

export const g4SignStatusList = [
  {
    label: "Draft",
    value: "DRAFT",
    variant: "default",
  },
  {
    label: "Pending",
    value: "PENDING",
    variant: "error",
  },
  {
    label: "Completed",
    value: "COMPLETED",
    variant: "success",
  },
  {
    label: "Voided",
    value: "VOIDED",
    variant: "default",
  },
];

export const CSIO_POLICY_NAME: { [key: string]: string } = {
  "csio:RWL": "Renewal",
  "csio:CAN": "Cancelled",
  "csio:CAP": "Cancellation Pending",
  "csio:EDT": "Electronic Document Transfer",
  "csio:EPT": "Expired Policy Term",
  "csio:INC": "Incomplete",
  "csio:LAP": "Lapsed",
  "csio:LPE": "Lapse Pending",
  "csio:NBA": "New Business (Active/In Force)",
  "csio:NBB": "New Business (Bound/Awaiting Issue)",
  "csio:NBQ": "New Business (Quoted)",
  "csio:NBS": "New Business (Issued)",
  "csio:NNQ": "New Business (Not Quoted)",
  "csio:PCD": "Policy Change (Draft)",
  "csio:PCE": "Policy Change (Quoted)",
  "csio:PCH": "Policy Change/Endorsement ",
  "csio:RED": "Renewed (Issued) ",
  "csio:REI": "Reinstated (Active/In Force)",
  "csio:REN": "Renewed (Active/In Force)",
  "csio:REQ": "Renewal (Quoted)",
  "csio:RET": "Reinstatement (Draft)",
  "csio:REU": "Reinstatement (Quoted)",
  "csio:RND": "Renewing (Draft)",
  "csio:SUS": "Locked/Suspended",
  "csio:WIT": "Withdrawn ",
  "csio:XLN": "Cancellation (Flat)",
};

export const AUTOMOBILE_CSIO_BILLING_METHOD: { [key: string]: string } = {
  "csio:A": "Agency Billed",
  "csio:C": "Company Account Billed",
  "csio:P": "Company Policy Billed ('Direct Bill Account')",
};

export const AUTOMOBILE_CSIO_VEHCILE_BODY_TYPE: { [key: string]: string } = {
  "csio:AB": "Ambulance",
  "csio:AM": "Ambulance (DEPRECATED)",
  "csio:BU": "Bus",
  "csio:CG": "Catering Truck",
  "csio:CP": "Compactor",
  "csio:CW": "Casket Wagon",
  "csio:DU": "Dump Truck",
  "csio:FL": "Fork Lift",
  "csio:HE": "Hearse",
  "csio:LG": "Lugger",
  "csio:LI": "Limousine",
  "csio:MV": "Mini Van",
  "csio:OT": "Other/Multi-Purpose Vehicle",
  "csio:PV": "Pickup Truck",
  "csio:RM": "Ready Mix Truck",
  "csio:RO": "Rolloff Truck",
  "csio:RT": "Road Tractor Only",
  "csio:RW": "Road Tractor with Trailer",
  "csio:SP": "Stake or Platform Truck",
  "csio:ST": "Stake or Platform Truck (DEPRECATED)",
  "csio:SU": "Sport Utility Vehicle - Small and Medium (Hard Top)",
  "csio:SW": "Sport Utility Vehicle - Large",
  "csio:T1": "Truck - Heavy",
  "csio:T2": "Truck - Medium",
  "csio:T3": "Truck - Light",
  "csio:TA": "Tank Truck Non-pressurized",
  "csio:TK": "Tank Truck Pressurized",
  "csio:TP": "Tow Truck with Platform",
  "csio:TR": "Farm Tractor",
  "csio:TW": "Tow Truck with Wrecker",
  "csio:TX": "Taxi",
  "csio:VH": "Van - Heavy",
  "csio:VL": "Van - Light",
  "csio:28": "3 Wheel Motorcycle",
  "csio:29": "3 Wheel Scooter",
  "csio:30": "Adventure",
  "csio:31": "Cabin/Home - Fifth Wheel",
  "csio:32": "Class A",
  "csio:33": "Class B",
  "csio:34": "Class C",
  "csio:35": "Cruiser",
  "csio:36": "Deep snow / Mountain",
  "csio:37": "Dual Purpose",
  "csio:38": "Enduro",
  "csio:39": "Heavy Duty",
  "csio:40": "Hybrid / Crossover",
  "csio:41": "Junior",
  "csio:42": "Lite",
  "csio:43": "Monoski",
  "csio:44": "Motard / Super Motard",
  "csio:45": "Naked Sport",
  "csio:46": "Off Road",
  "csio:47": "Off Road Motocross",
  "csio:48": "Off Road Trials",
  "csio:49": "Performance",
  "csio:50": "Recreational",
  "csio:51": "Recreational Utility",
  "csio:52": "Sport",
  "csio:53": "Sport Touring",
  "csio:54": "Standard",
  "csio:55": "Standard Sidecar",
  "csio:56": "Touring",
  "csio:57": "Trail / Sport",
  "csio:58": "Unknown",
  "csio:59": "Utility",
  "csio:64": "Entry Sport",
  "csio:65": "Entry Naked Sport",
  "csio:66": "Super Sport",
  "csio:67": "Naked Super Sport",
  "csio:A2": "All Terrain Cycle",
  "csio:A3": "All Terrain Tricycle",
  "csio:A4": "All Terrain Vehicle (Four Wheel)",
  "csio:AA": "Antique Auto (Regular Plate)",
  "csio:AH": "Antique Auto (Historic Plate)",
  // "csio:AM": "Amphibious Vehicle",
  "csio:AT": "All Terrain Vehicle",
  "csio:CB": "Detachable Camper Body",
  "csio:CL": "Classic/Customized Auto",
  "csio:CV": "Camper Van",
  "csio:DB": "Dune Buggy",
  "csio:GC": "Golf Cart",
  "csio:KT": "Kit Car",
  "csio:MB": "Motorized Bicycle",
  "csio:MC": "Motorcycles over 50 cc",
  "csio:MH": "Motor Home (Recreational Use)",
  "csio:MK": "Mini-bike, Trail Bike (Off Road)",
  "csio:MP": "Moped",
  "csio:MS": "Motorized Scooter",
  "csio:MT": "Motorized Tricycle",
  "csio:MW": "Motorcycle with Sidecar",
  "csio:RC": "Race Car",
  "csio:SM": "Snowmobile",
  "csio:SS": "Snowmobile with Sled",
  "csio:TM": "Travelling Motor Home (Primary Residence)",
  "csio:JP": "Jeep",
  "csio:P1": "Private Passenger - Convertible",
  "csio:P2": "Private Passenger - 2 Door Sedan/Hard Top",
  "csio:P3": "Private Passenger - 3 Door Hatchback",
  "csio:P4": "Private Passenger - 4 Door Sedan/Hard Top",
  "csio:P5": "Private Passenger - 4 Door Hatchback",
  "csio:P6": "Private Passenger - Station Wagon",
  // "csio:ST": "Sport Utility Vehicle - Small Convertible (Soft Top)",
  // "csio:SU": "Sport Utility Vehicle - Small and Medium (Hard Top)",
  // "csio:SW": "Sport Utility Vehicle - Large",
  // "csio:VL": "Van - Light",
  "csio:1": "Bike / Mini-Bike Trailer",
  "csio:11": "Mix In Transit Trailer",
  "csio:12": "Office Trailer",
  "csio:13": "Pup Trailer",
  "csio:14": "Semi-trailer Extra Trailer",
  "csio:15": "Stake/Platform Trailer Hauled",
  "csio:16": "Stake/Platform Trailer Train",
  "csio:17": "Tank Trailer Hauled As Train",
  "csio:18": "Tank Trailer Hauled Separately",
  "csio:20": "Trailer - Other Load Carrying",
  "csio:21": "Trailer With Premises Exposure (e.g. Showroom, etc.)",
  "csio:23": "Van Trailer Hauled As Train",
  "csio:24": "Van Trailer Hauled Separately",
  "csio:25": "Watercraft Trailer",
  "csio:26": "Snowmobile Trailer",
  "csio:27": "Livestock Trailer",
  "csio:3": "Converter Dolly",
  "csio:4": "Dump Trailer Hauled Separately",
  "csio:5": "Dump Trailer Hauled As Train",
  "csio:6": "Equipment Trailer Hauled Separately",
  "csio:7": "Equipment Trailer Hauled As Train",
  "csio:8": "Gooseneck Trailer",
  "csio:9": "Horse Trailer",
  "csio:99": "Other Trailer",
  "csio:CT": "Cabin or Home Trailer",
  "csio:FT": "Farm Trailer",
  "csio:NT": "Common Trailer (uses tongue and/or hitch)",
  "csio:TT": "Tent Trailer",
  "csio:UT": "Utility Trailer",
};
export const AUTOMOBILE_CSIO_VEHICLE_USE: { [key: string]: string } = {
  "csio:P": "Pleasure",
  "csio:C": "Commercial",
  "csio:W": "Commute",
  "csio:B": "Business",
  "csio:F": "Farm",
};

// export const vehicleType = [
//   { label: "Private Passenger", value: "Private Passenger" },
//   {
//     label: "Private Passenger with Driver",
//     value: "Private Passenger with Driver",
//   },
//   { label: "Commercial", value: "Commercial" },
//   { label: "Commercial with Driver", value: "Commercial with Driver" },
//   { label: "Courier", value: "Courier" },
//   { label: "Trailer -Open", value: "Trailer -Open" },
//   {
//     label: "Trailer -Closed, Non-refrigerated",
//     value: "Trailer -Closed, Non-refrigerated",
//   },
//   {
//     label: "Trailer -Closed, Refrigerated",
//     value: "Trailer -Closed, Refrigerated",
//   },
//   { label: "Trailer -Pup", value: "Trailer -Pup" },
//   { label: "Trailer -Dump", value: "Trailer -Dump" },
//   { label: "Trailer -Tanker", value: "Trailer -Tanker" },
//   { label: "Trailer -Flatbed", value: "Trailer -Flatbed" },
//   {
//     label: "Light Commercial (up to 4600 kg)",
//     value: "Light Commercial (up to 4600 kg)",
//   },
//   { label: "Unlicensed Auto", value: "Unlicensed Auto" },
//   {
//     label: "Unlicensed Recreational Vehicle",
//     value: "Unlicensed Recreational Vehicle",
//   },
//   { label: "Unlicensed Trailer", value: "Unlicensed Trailer" },
//   { label: "Unlicensed Truck", value: "Unlicensed Truck" },
//   { label: "Bus", value: "Bus" },
//   { label: "Commercial Vehicle", value: "Commercial Vehicle" },
//   {
//     label: "Medium Commercial (4601 kg to 11340 kg)",
//     value: "Medium Commercial (4601 kg to 11340 kg)",
//   },
//   {
//     label: "Heavy Commercial (over 11340 kg)",
//     value: "Heavy Commercial (over 11340 kg)",
//   },
//   { label: "Tractor", value: "Tractor" },
//   { label: "Private Passenger -2 Door", value: "Private Passenger -2 Door" },
//   { label: "Private Passenger -4 Door", value: "Private Passenger -4 Door" },
//   { label: "Truck -Closed", value: "Truck -Closed" },
//   { label: "Truck -Open", value: "Truck -Open" },
//   { label: "Unknown", value: "Unknown" },
//   { label: "None", value: "None" },
//   { label: "Other", value: "Other" },
// ];
export const vehicle_type = [
  "Private Passenger",
  "Private Passenger with Driver",
  "Commercial",
  "Commercial with Driver",
  "Courier",
  "Trailer -Open",
  "Trailer -Closed, Non-refrigerated",
  "Trailer -Closed, Refrigerated",
  "Trailer -Pup",
  "Trailer -Dump",
  "Trailer -Tanker",
  "Trailer -Flatbed",
  "Light Commercial (up to 4600 kg)",
  "Unlicensed Auto",
  "Unlicensed Recreational Vehicle",
  "Unlicensed Trailer",
  "Unlicensed Truck",
  "Bus",
  "Commercial Vehicle",
  "Medium Commercial (4601 kg to 11340 kg)",
  "Heavy Commercial (over 11340 kg)",
  "Tractor",
  "Private Passenger -2 Door",
  "Private Passenger -4 Door",
  "Truck -Closed",
  "Truck -Open",
  "Unknown",
  "None",
  "Other",
];

export const vehicle_use = [
  "Pleasure",
  "Commercial",
  "Commute",
  "Business",
  "Farm",
];

export const CSIO_INSURED_PRINCIPAL_ROLE: { [key: string]: string } = {
  "csio:1": "Insurance Contact",
  "csio:2": "Additional Interest",
  "csio:3": "Authorized Official",
  "csio:4": "Financial Institution",
  "csio:5": "Insured",
  "csio:6": "Insurance Beneficiary",
  "csio:7": "Broker",
  "csio:8": "Claims Contact",
  "csio:9": "Producer",
  "csio:10": "Insurance Company",
  "csio:11": "Joint Insured",
  "csio:12": "Lender",
  "csio:13": "Named Insured",
  "csio:14": "Occupant",
  "csio:15": "Parent Company",
  "csio:16": "Prior Insurer",
  "csio:17": "Contact Party",
  "csio:18": "Payor",
  "csio:19": "Reinsurance Company",
  "csio:20": "Subsidiary Company",
  "csio:21": "Principal/Partner",
  "csio:22": "Previous Operating Name",
  "csio:23": "Additional Insured",
  "csio:24": "Employee",
  "csio:25": "Client/Customer of Insured",
  "csio:26": "Operating Name",
  "csio:27": "Production Company",
  "csio:28": "President",
  "csio:29": "Vice-president",
  "csio:30": "Secretary",
  "csio:31": "Treasurer",
  "csio:32": "Director of Organization",
  "csio:33": "Production Manager",
  "csio:34": "Architect",
  "csio:35": "Engineer",
  "csio:36": "Auditor -External",
  "csio:37": "Actuary",
  "csio:38": "Doctor",
  "csio:39": "Nurse",
  "csio:40": "Healthcare Professional",
  "csio:41": "Student",
  "csio:42": "Visitor",
  "csio:43": "Daycare Child",
  "csio:44": "Site Owner",
  "csio:45": "Volunteer",
  "csio:46": "Agent of Applicant/Insured",
  "csio:47": "Officer of Organization",
  "csio:48": "Paymaster",
  "csio:49": "Collector",
  "csio:50": "Delivery Person (Independent)",
  "csio:51": "Salesperson",
  "csio:52": "Messenger (Independent)",
  "csio:53": "Auditor -Internal",
  "csio:54": "Accountant",
  "csio:55": "Inventory Firm",
  "csio:56": "Bookkeeper",
  "csio:57": "Appraiser",
  "csio:58": "Adjuster",
  "csio:59": "Clerical",
  "csio:60": "Cashier",
  "csio:61": "Cashier -Assistant",
  "csio:62": "Branch Manager",
  "csio:63": "Administrative Assistant",
  "csio:64": "Supervisor",
  "csio:65": "Department Manager",
  "csio:66": "Class I Employee",
  "csio:67": "Class II Employee",
  "csio:68": "Professional",
  "csio:69": "Members",
  "csio:70": "Board",
  "csio:71": "Authorized Inspection Company",
  "csio:72": "Inspector",
  "csio:73": "Fire Department",
  "csio:74": "WETT Inspector",
  "csio:900": "Boarder",
  "csio:901": "Foster Child",
  "csio:902": "Group Home Individual",
  "csio:903": "Employee-Permanent Resident",
  "csio:909": "Tourist",
  "csio:997": "Unknown",
  "csio:998": "None",
  "csio:999": "Other",
};

export const InsuredOrPrincipal = [
  "Insurance Contact",
  "Additional Interest",
  "Authorized Official",
  "Financial Institution",
  "Insured",
  "Insurance Beneficiary",
  "Broker",
  "Claims Contact",
  "Producer",
  "Insurance Company",
  "Joint Insured",
  "Lender",
  "Named Insured",
  "Occupant",
  "Parent Company",
  "Prior Insurer",
  "Contact Party",
  "Payor",
  "Reinsurance Company",
  "Subsidiary Company",
  "Principal/Partner",
  "Previous Operating Name",
  "Additional Insured",
  "Employee",
  "Client/Customer of Insured",
  "Operating Name",
  "Production Company",
  "President",
  "Vice-president",
  "Secretary",
  "Treasurer",
  "Director of Organization",
  "Production Manager",
  "Architect",
  "Engineer",
  "Auditor - External",
  "Actuary",
  "Doctor",
  "Nurse",
  "Healthcare Professional",
  "Student",
  "Visitor",
  "Daycare Child",
  "Site Owner",
  "Volunteer",
  "Agent of Applicant/Insured",
  "Officer of Organization",
  "Paymaster",
  "Collector",
  "Delivery Person (Independent)",
  "Salesperson",
  "Messenger (Independent)",
  "Auditor - Internal",
  "Accountant",
  "Inventory Firm",
  "Bookkeeper",
  "Appraiser",
  "Adjuster",
  "Clerical",
  "Cashier",
  "Cashier - Assistant",
  "Branch Manager",
  "Administrative Assistant",
  "Supervisor",
  "Department Manager",
  "Class I Employee",
  "Class II Employee",
  "Professional",
  "Members",
  "Board",
  "Authorized Inspection Company",
  "Inspector",
  "Fire Department",
  "WETT Inspector",
  "Boarder",
  "Foster Child",
  "Group Home Individual",
  "Employee-Permanent Resident",
  "Tourist",
  "Unknown",
  "None",
  "Other",
];

export const AUTOMOBILE_CSIO_BOOLEAN_MATCH: { [key: string]: string } = {
  "0": "No", // leased
  "1": "Yes", // owned
};

export const AUTOMOBILE_CSIO_NEWUSED: { [key: string]: string } = {
  "1": "New",
  "2": "Used",
  "3": "Demonstrator",
  "4": "Reconstructed",
};

export const AUTOMOBILE_CSIO_ENGINE: { [key: string]: string } = {
  "csio:1": "Diesel",
  "csio:2": "Electric (Battery)",
  "csio:3": "Gasoline",
  "csio:4": "Natural Gas",
  "csio:5": "Propane (Not-factory Installed)",
  "csio:6": "Propane (Factory Installed)",
  "csio:7": "Solar",
  "csio:8": "Alcohol",
  "csio:9": "Electric (Fuel Cell)",
  "csio:A": "Gasoline and Electric",
  "csio:Z": "Other",
};

export const AUTOMOBILE_CSIO_MERTIAL_STATUS: { [key: string]: string } = {
  "csio:C": "ommon-law",
  "csio:D": "Divorced",
  "csio:M": "Married",
  "csio:P": "Separated",
  "csio:S": "Single",
  "csio:W": "Widowed",
};

export const AUTOMOBILE_CSIO_GENDER: { [key: string]: string } = {
  F: "Female",
  M: "Male",
  U: "Unknown",
  X: "Unspecified",
};

export const gender = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Unknown",
    value: "U",
  },
  {
    label: "Unspecified",
    value: "X",
  },
];

export const AUTOMOBILE_CSIO_COVERAGE_CLAIMS: { [key: string]: string } = {
  "csio:TPBI": "BI",
  "csio:TPPD": "PD",
  "csio:AB": "AB",
  "csio:TPDC": "DCPD",
  "csio:UA": "UA",
  "csio:COL": "COL",
  "csio:CMP": "COMP",
};

export const AUTOMOBILE_CSIO_LOSS: { [key: string]: string } = {
  "csio:501": "Appliance - Malfunction",
  "csio:502": "Bodily Injury",
  "csio:503": "Burglary",
  "csio:504": "Collapse - Above Ground Pool",
  "csio:505": "Collapse - Building",
  "csio:506": "Collapse - Inground Pool",
  "csio:507": "Collapse - Weight of Ice/Snow",
  "csio:508": "Collapse - Unspecified",
  "csio:509": "Consequential Loss",
  "csio:510": "Credit Card Forgery and Counterfeit Money",
  "csio:521": "Earthquake",
  "csio:522": "Electrical Power Interruption/Power Surge",
  "csio:524": "Entrapment",
  "csio:537": "Fire - Arson/Arson Suspected",
  "csio:538": "Fire - Careless use of Candles/Matches",
  "csio:539": "Fire - Careless Smoking",
  "csio:540": "Fire - Cooking Operations/Grease Fire",
  "csio:541": "Fire - Electrical",
  "csio:542": "Fire - Fireplace/Chimney",
  "csio:543": "Fire - Portable Heater",
  "csio:544": "Fire - Woodstove/Chimney",
  "csio:545": "Fire Department Charges",
  "csio:546": "Fire - Improper Storage & Handling of Chemicals/Fuels",
  "csio:547": "Fire - Unspecified",
  "csio:548": "Forgery",
  "csio:549": "Freezer Contents",
  "csio:550": "Fuel Leakage - Exterior Oil Storage Tank",
  "csio:551": "Fuel Leakage - Interior Oil Storage Tank",
  "csio:561": "Glass Breakage - Accidental",
  "csio:562": "Glass Breakage - Vandalism",
  "csio:563": "Glass Breakage - Unspecified",
  "csio:564": "Hail Damage",
  "csio:565": "Impact - Aircraft",
  "csio:566": "Impact - Land Vehicle, Insured Operator",
  "csio:567": "Impact - Land Vehicle, Third Party Operator",
  "csio:568": "Lightning - Antenna/Dish",
  "csio:569": "Lightning - Chimney",
  "csio:570": "Lightning - Electrical Equipment",
  "csio:571": "Lightning - Unspecified",
  "csio:572": "Loss Assessment Coverage",
  "csio:573": "Lost/Stolen Keys",
  "csio:583": "Mass Evacuation",
  "csio:584": "Mysterious Disappearance",
  "csio:585": "Pollution Damage",
  "csio:586": "Pollution Liability",
  "csio:587": "Prohibited Access by Civil Authority",
  "csio:588": "Property Damage",
  "csio:589": "Riot",
  "csio:590": "Robbery",
  "csio:591": "Sewer Backup",
  "csio:592": "Smoke - Fire in Adjacent Premises/Building",
  "csio:593": "Smoke - Heating/Ventilation Malfunction",
  "csio:594": "Smoke - Unspecified",
  "csio:595": "Tenants Legal Liability",
  "csio:596": "Theft/Attempted Theft - Off Premises, Non-vehicle",
  "csio:597": "Theft/Attempted Theft - Off Premises, Vehicle",
  "csio:598": "Theft/Attempted Theft - On Premises, Non-forced Entry",
  "csio:599": "Transportation - Collision/Upset/Overturn",
  "csio:600": "Transportation - Unspecified",
  "csio:610": "Vandalism/Malicious Damage - Building Vacant",
  "csio:611": "Vandalism/Malicious Damage - Explosion",
  "csio:612": "Vandalism/Malicious Damage - Insured Suspect",
  "csio:613": "Vandalism/Malicious Damage - Unspecified",
  "csio:614": "Voluntary Compensation",
  "csio:615": "Voluntary Medical Payment",
  "csio:616": "Voluntary Property Damage",
  "csio:626": "Water Damage - Ice Build-up on Roof",
  "csio:627": "Water Damage - Unspecified",
  "csio:628": "Water Escape/Rupture - Building",
  "csio:629": "Water Escape/Rupture - Contents",
  "csio:630": "Water Escape/Rupture - Eavestrough or Downspout",
  "csio:631": "Water Escape/Rupture - Freezing Building",
  "csio:632": "Water Escape/Rupture - Freezing, Unheated Building",
  "csio:633": "Water Escape/Rupture - Plumbing System",
  "csio:634": "Water Escape/Rupture - Seepage/Leakage",
  "csio:635": "Water Escape/Rupture - Sewer/Drain",
  "csio:636": "Water Escape/Rupture - Sprinkler System",
  "csio:637": "Water Escape/Rupture - Sump Pump Failure",
  "csio:638": "Water Escape/Rupture - Swimming Pool/Equipment",
  "csio:639": "Water Escape/Rupture - Waterbed",
  "csio:640": "Water Escape/Rupture - Unspecified",
  "csio:641": "Windstorm Damage",
  "csio:648": "Floater",
  "csio:649": "Home Based Business",
  "csio:650": "Identity theft",
  "csio:651": "Personal accident",
  "csio:652": "Wildfire",
  "csio:653": "Mechanical Breakdown",
  "csio:654": "Glass Scratching or Marring",
  "csio:999": "Other",
  "csio:1": "Backed Into Car",
  "csio:10": "Disobeyed Stop Sign",
  "csio:11": "Disobeyed Yield Sign",
  "csio:12": "Disobeyed Red Light",
  "csio:13": "Exits Parking Lot - Road/Street",
  "csio:14": "Explosion",
  "csio:15": "Fire Damage - Partial",
  "csio:16": "Fire Damage - Total Loss",
  "csio:17": "Flood",
  "csio:18": "Glass/Windshield",
  "csio:19": "Hit & Run",
  "csio:2": "Changed Lanes",
  "csio:20": "Hit Pedestrian",
  "csio:21": "Lightning",
  "csio:22": "Lost Control",
  "csio:23": "Neutral Intersection",
  "csio:24": "Opened Door",
  "csio:25": "Parking Lot",
  "csio:26": "Passing On Shoulder",
  "csio:27": "Pulled Out From Curb",
  "csio:28": "Riot/Civil Commotion",
  "csio:29": "Sideswipe",
  "csio:3": "Collision with Animal",
  "csio:30": "Theft - Entire Vehicle",
  "csio:31": "Theft - Partial/Attempted",
  "csio:32": "Turned Left - Car Approaching",
  "csio:33": "Turned Left - Car Passing",
  "csio:34": "Vandalism",
  "csio:35": "Wind/Hail Carried Spray",
  "csio:36": "Windstorm/Hail",
  "csio:37": "Wrong Way - One-way Street",
  "csio:38": "Minor At-Fault Collision",
  "csio:39": "Collision - Single Vehicle",
  "csio:4": "Collision - Head-on",
  "csio:40": "Collision - Cyclist",
  "csio:41": "Collision – Not at Fault",
  "csio:5": "Collision - Rear-ended",
  "csio:520": "Domestic Pets",
  "csio:523": "Employee Fraud",
  "csio:525": "Explosion - Propane/Natural Gas Appliance",
  "csio:526": "Explosion - Unspecified",
  "csio:527": "Explosion - Electric Arcing",
  "csio:534": "Financial Fraud - Identity Theft",
  "csio:535": "Fire- Spread from Third Party Property",
  "csio:536": "Falling Object",
  "csio:6": "Collision - Multi-vehicle",
  "csio:617":
    "Vandalism/Malicious Damage - Illegal Substance Cultivation and Manufacturing",
  "csio:642": "Water Damage - Infiltration",
  "csio:643":
    "Surface Water - Surface water enters structure at a point at surface of ground from accumulation of heavy rain, etc.",
  "csio:644":
    "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
  "csio:645": "Exterior Sewer Line - Breakage of the exterior sewer line.",
  "csio:646":
    "Ground Water  - Sudden and accidental infiltration of water through a foundation",
  "csio:647":
    "Flood Water - Flood water enters structure at a point at surface of ground from inland water overflow, etc.",
  "csio:7": "Collision - Insured Vehicle Parked",
  "csio:8": "Collision - Other",
  "csio:9": "Damage Caused by Children/Animals",
};

export const payment_plan = [
  "1 Payment",
  "2 Payments (Equal) - 50% per payment",
  "3 Payments (Equal) - 33.3% per payment",
  "4 Payments (Equal) - 25% per payment",
  "3 Payments (50% 1st Payment, 25% for the next 2 Payments)",
  "3 Payments (40% 1st Payment, 30% for the next 2 Payments)",
  "Down Payment",
  "3 Year (Annual Installments)",
  "3 Year (Lump Sum Payments)",
  "Job by Job Basis",
  "As Required",
  "Holiday",
  "Daily",
  "Weekend",
  "Single Event",
  "Any Policy Change",
  "Hourly",
  "Bi-annually",
  "Infrequent",
  "Irregular",
  "More Than Once a Day",
  "At the Same Time Each Day",
  "Varied Each Day",
  "Nightly",
  "Per Trip",
  "Unannounced (Visits)",
  "Continuous",
  "Not Known",
  "Always (any change)",
  "Annual",
  "Bi-monthly (every 2 months)",
  "Bi-weekly",
  "When Issued Only",
  "Monthly",
  "Never",
  "Non standard",
  "Occasional",
  "Other",
  "Power Failure",
  "Quarterly",
  "Renewal Only",
  "Regularly",
  "Seasonal",
  "Six month Policy Single Payment",
  "Six month Policy Two Equal Payments",
  "Six month Policy Three Equal Payments",
  "Six month Policy Four Equal Payments",
  "Six Month Policy Six Equal Payments",
  "Semi annual",
  "Weekly",
];

export const AUTOMOBILE_CSIO_PAYMENT_COVRAGE: { [key: string]: string } = {
  "csio:1": "1 Payment",
  "csio:2": "2 Payments (Equal) - 50% per payment",
  "csio:3": "3 Payments (Equal) - 33.3% per payment",
  "csio:4": "4 Payments (Equal) - 25% per payment",
  "csio:5": "3 Payments (50% 1st Payment, 25% for the next 2 Payments)",
  "csio:6": "3 Payments (40% 1st Payment, 30% for the next 2 Payments)",
  "csio:7": "Down Payment",
  "csio:8": "3 Year (Annual Installments)",
  "csio:9": "3 Year (Lump Sum Payments)",
  "csio:10": "Job by Job Basis",
  "csio:11": "As Required",
  "csio:12": "Holiday",
  "csio:13": "Daily",
  "csio:14": "Weekend",
  "csio:15": "Single Event",
  "csio:16": "Any Policy Change",
  "csio:17": "Hourly",
  "csio:18": "Bi-annually",
  "csio:19": "Infrequent",
  "csio:20": "Irregular",
  "csio:21": "More Than Once a Day",
  "csio:22": "At the Same Time Each Day",
  "csio:23": "Varied Each Day",
  "csio:24": "Nightly",
  "csio:25": "Per Trip",
  "csio:26": "Unannounced (Visits)",
  "csio:27": "Continuous",
  "csio:997": "Not Known",
  "csio:A": "Always (any change)",
  "csio:AN": "Annual",
  "csio:BM": "Bi-monthly (every 2 months)",
  "csio:BW": "Bi-weekly",
  "csio:I": "When Issued Only",
  "csio:MO": "Monthly",
  "csio:N": "Never",
  "csio:NS": "Non standard",
  "csio:OC": "Occasional",
  "csio:OT": "Other",
  "csio:PF": "Power Failure",
  "csio:QU": "Quarterly",
  "csio:R": "Renewal Only",
  "csio:RG": "Regularly",
  "csio:S": "Seasonal",
  "csio:S1": "Six month Policy Single Payment",
  "csio:S2": "Six month Policy Two Equal Payments",
  "csio:S3": "Six month Policy Three Equal Payments",
  "csio:S4": "Six month Policy Four Equal Payments",
  "csio:S5": "Six Month Policy Six Equal Payments",
  "csio:SA": "Semi annual",
  "csio:WK": "Weekly",
};

export const AUTOMOBILE_CSIO_COVERAGE: { [key: string]: string } = {
  "csio:TPBI": "Bodily Injury",
  "csio:TPPD": "Property Damage",
  "csio:AB": "Accident Benefits (Standard Benefits)",
  "csio:WIB": "Income Replacement ($600/$800/$1,000)",
  "csio:MRB": "Medical Rehabilitation & Attendant Care ($130,000/$1,000,000)",
  "csio:CATIM":
    "Optional Catastrophic Impairment(additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care Benefit)",
  "csio:CHHMB": "Caregiver, Housekeeping & Home Maintenance",
  "csio:DFB": "Death & Funeral",
  "csio:DCB": "Dependent Care",
  "csio:IDB": "Indexation Benefit (Consumer Price Index)",
  "csio:UA": "Uninsured Automobile",
  "csio:TPDC": "Direct Compensation-Property Damage,",
  "csio:SP": "Specified Perils (excluding Collision or Upset)",
  "csio:CMP": "Comprehensive (excluding Collision or Upset)",
  "csio:COL": "Collision or Upset",
  "csio:AP": "All Perils",
  "csio:44R":
    "Policy Change Forms (Name & No.)Family Protection Coverage - OPCF 44R",
};

export const csioOtherCoverages = [
  {
    label: `NBEF2 - Drive Other Automobiles - Named Person(s), OPCF2 - Providing Coverage When Named Persons Drive Other Automobiles, QEF2 - Drive Other Automobiles - Named Person(s), SEF2 - Drive Other Automobiles - Named Person(s), AB-SEF2 - Designated Operator(s) Drive Other Automobiles Endorsement`,
    value: `NBEF2 - Drive Other Automobiles - Named Person(s), OPCF2 - Providing Coverage When Named Persons Drive Other Automobiles, QEF2 - Drive Other Automobiles - Named Person(s), SEF2 - Drive Other Automobiles - Named Person(s), AB-SEF2 - Designated Operator(s) Drive Other Automobiles Endorsement`,
  },
  {
    label:
      "NBEF3 - Drive Government Automobiles, OPCF3 - Drive Government Automobiles, QEF3 - Drive Government Automobiles, SEF3 - Drive Government Automobiles, AB-SEF3 - Drive Government Automobile Endorsement",
    value:
      "NBEF3 - Drive Government Automobiles, OPCF3 - Drive Government Automobiles, QEF3 - Drive Government Automobiles, SEF3 - Drive Government Automobiles, AB-SEF3 - Drive Government Automobile Endorsement",
  },
  {
    label:
      "ASEF5 - Permission to Rent or Lease (Specified Lessee), OPCF5 - Permission to Rent or Lease Automobiles and Extending Coverage to the Specific Lessee(s), SEF5 - Permission to Rent or Lease, EEF5 Permission to Rent or Lease",
    value:
      "ASEF5 - Permission to Rent or Lease (Specified Lessee), OPCF5 - Permission to Rent or Lease Automobiles and Extending Coverage to the Specific Lessee(s), SEF5 - Permission to Rent or Lease, EEF5 Permission to Rent or Lease",
  },
  {
    label:
      "NBEF7 - Separate Limits (Third Party Liability), OPCF7 - Separate Limits (Liability), SEF7 - Separate Limits (Third Party Liability)",
    value:
      "NBEF7 - Separate Limits (Third Party Liability), OPCF7 - Separate Limits (Liability), SEF7 - Separate Limits (Third Party Liability)",
  },
  {
    label:
      "ASEF8 - Property Damage Reimbursement (Section A Only), OPCF8 - Property Damage Reimbursement, QEF8 - Deductible Property Damage, SEF8 - Property Damage Reimbursement (Section A Only)",
    value:
      "ASEF8 - Property Damage Reimbursement (Section A Only), OPCF8 - Property Damage Reimbursement, QEF8 - Deductible Property Damage, SEF8 - Property Damage Reimbursement (Section A Only)",
  },
  {
    label:
      "NBEF9 - Marine Use Excluded (Amphibious Vehicles), OPCF9 - Marine Use Excluded (Amphibious Automobiles), QEF9 - Marine Use Excluded (Amphibious Automobiles), SEF9 - Marine Use Excluded (Amphibious Vehicles), AB-SEF9 - Marine Use Excluded Endorsement (Amphibious Vehicles)",
    value:
      "NBEF9 - Marine Use Excluded (Amphibious Vehicles), OPCF9 - Marine Use Excluded (Amphibious Automobiles), QEF9 - Marine Use Excluded (Amphibious Automobiles), SEF9 - Marine Use Excluded (Amphibious Vehicles), AB-SEF9 - Marine Use Excluded Endorsement (Amphibious Vehicles)",
  },
  {
    label: "SEF10 - Limitation of coverage",
    value: "SEF10 - Limitation of coverage",
  },
  {
    label: "SEF11 - Change of Coverages (Section C)",
    value: "SEF11 - Change of Coverages (Section C)",
  },
  {
    label: "SEF12 - Additional Coverage",
    value: "SEF12 - Additional Coverage",
  },
  {
    label: "SEF14 - Addition of Automobile",
    value: "SEF14 - Addition of Automobile",
  },
  {
    label:
      "NBEF16 - Agreement for Suspension of Coverage, OPCF16 - Suspension of Coverage, QEF16 - Suspension for Storage, SEF16 - Agreement for Suspension of Coverage, AB-SEF16 - Suspension of Coverages Endorsement",
    value:
      "NBEF16 - Agreement for Suspension of Coverage, OPCF16 - Suspension of Coverage, QEF16 - Suspension for Storage, SEF16 - Agreement for Suspension of Coverage, AB-SEF16 - Suspension of Coverages Endorsement",
  },
  {
    label:
      "NBEF17 - Reinstatement of Coverage, OPCF17 - Reinstatement of Coverage, QEF17 - Reinstatement After Storage, SEF17 - Reinstatement of Coverage, AB-SEF17 - Reinstatement of Coverages Endorsement",
    value:
      "NBEF17 - Reinstatement of Coverage, OPCF17 - Reinstatement of Coverage, QEF17 - Reinstatement After Storage, SEF17 - Reinstatement of Coverage, AB-SEF17 - Reinstatement of Coverages Endorsement",
  },
  {
    label:
      "NBEF19 - Limitation of Amount, OPCF19 - Limitation of Amount Paid forLoss or Damage Coverages, QEF19 - Section B - Limitation of Amount, SEF19 - Limitation of Amount, EEF19 Limitation of Amount",
    value:
      "NBEF19 - Limitation of Amount, OPCF19 - Limitation of Amount Paid forLoss or Damage Coverages, QEF19 - Section B - Limitation of Amount, SEF19 - Limitation of Amount, EEF19 Limitation of Amount",
  },
  {
    label:
      "NBEF20 - Loss of Use, OPCF20 - Coverage for Transportation Replacement, QEF20 - Travel Expenses, SEF20 - Loss of Use, EEF20 Loss of Use Coverage, AB-SEF20 - Loss of Use Endorsement",
    value:
      "NBEF20 - Loss of Use, OPCF20 - Coverage for Transportation Replacement, QEF20 - Travel Expenses, SEF20 - Loss of Use, EEF20 Loss of Use Coverage, AB-SEF20 - Loss of Use Endorsement",
  },
  {
    label:
      "NBEF22 - Damage to Property of Passengers , OPCF22 - Damage to Property of Passengers, SEF22 - Damage to Property of Passengers, AB-SEF22 - Damage to Property of Passengers Endorsement",
    value:
      "NBEF22 - Damage to Property of Passengers , OPCF22 - Damage to Property of Passengers, SEF22 - Damage to Property of Passengers, AB-SEF22 - Damage to Property of Passengers Endorsement",
  },
  {
    label:
      "NBEF24 - Fire Apparatus , OPCF24 - Fire Apparatus, QEF24 - Fire Apparatus Limitation, SEF24 - Fire Apparatus, AB-SEF24 - Fire and Rescue Equipment Endorsement",
    value:
      "NBEF24 - Fire Apparatus , OPCF24 - Fire Apparatus, QEF24 - Fire Apparatus Limitation, SEF24 - Fire Apparatus, AB-SEF24 - Fire and Rescue Equipment Endorsement",
  },
  {
    label:
      "NBEF25 - Alteration Endorsement, OPCF25 - Replaced by OPCF25A, QEF25 - Modified Declarations, SEF25 - Alteration Endorsement (endorsement changing or correcting statement(s) in the application or changing the rating classification)",
    value:
      "NBEF25 - Alteration Endorsement, OPCF25 - Replaced by OPCF25A, QEF25 - Modified Declarations, SEF25 - Alteration Endorsement (endorsement changing or correcting statement(s) in the application or changing the rating classification)",
  },
  {
    label:
      "NBEF26 - Disappearing Deductible (All Perils or Collision), OPCF26 - There is no such endorsement, SEF26 - Disappearing Deductible (All Perils or Collision)",
    value:
      "NBEF26 - Disappearing Deductible (All Perils or Collision), OPCF26 - There is no such endorsement, SEF26 - Disappearing Deductible (All Perils or Collision)",
  },
  {
    label:
      "ASEF27 - Legal Liability for Damage to Non-owned Automobile, OPCF27 - Liability for Damage to Non-owned Automobile(s) and Providing Other Coverages When Insured Persons Drive Other Automobiles, QEF27 - Civil Liability Resulting from Damage to Non Owned Automobiles INCLUDING Automobiles Provided by an Employer, SEF27 - Legal Liability for Damage to Non owned Automobiles",
    value:
      "ASEF27 - Legal Liability for Damage to Non-owned Automobile, OPCF27 - Liability for Damage to Non-owned Automobile(s) and Providing Other Coverages When Insured Persons Drive Other Automobiles, QEF27 - Civil Liability Resulting from Damage to Non Owned Automobiles INCLUDING Automobiles Provided by an Employer, SEF27 - Legal Liability for Damage to Non owned Automobiles",
  },
  {
    label:
      "NBEF28 - Reduction of Coverage as Respects Operation by Named Person(s), OPCF28 - Reduction of Coverage for Named Persons, QEF28 - Reduction of Coverage for Named Drivers, SEF28 - Reduction of Coverage as Respects Operation by Named Person(s), EEF28 Excluded Named Person",
    value:
      "NBEF28 - Reduction of Coverage as Respects Operation by Named Person(s), OPCF28 - Reduction of Coverage for Named Persons, QEF28 - Reduction of Coverage for Named Drivers, SEF28 - Reduction of Coverage as Respects Operation by Named Person(s), EEF28 Excluded Named Person",
  },
  {
    label:
      "NBEF29 - Additional Coverage as Respects Operation by Named Person(s), OPCF29 - Additional Coverage for Named Persons , QEF29 Additional Coverage for Named Drivers, SEF29 - Additional Coverage as Respects Operation by Named Person(s), AB-SEF29 - Named Person(s) Additional Coverage Endorsement",
    value:
      "NBEF29 - Additional Coverage as Respects Operation by Named Person(s), OPCF29 - Additional Coverage for Named Persons , QEF29 Additional Coverage for Named Drivers, SEF29 - Additional Coverage as Respects Operation by Named Person(s), AB-SEF29 - Named Person(s) Additional Coverage Endorsement",
  },
  {
    label:
      "NBEF30 - Excluding Operation of Attached Machinery, OPCF30 - Excluding Operation of Attached Machinery, QEF30 - Attached Machinery Limitation, SEF30 - Excluding Operation of Attached Machinery",
    value:
      "NBEF30 - Excluding Operation of Attached Machinery, OPCF30 - Excluding Operation of Attached Machinery, QEF30 - Attached Machinery Limitation, SEF30 - Excluding Operation of Attached Machinery",
  },
  {
    label:
      "NBEF31 - Non-owned Equipment, OPCF31 - Non owned Equipment, QEF31 - Non-owned Equipment, SEF31 - Non owned Equipment, AB-SEF31 - Non-Owned Equipment Endorsement ",
    value:
      "NBEF31 - Non-owned Equipment, OPCF31 - Non owned Equipment, QEF31 - Non-owned Equipment, SEF31 - Non owned Equipment, AB-SEF31 - Non-Owned Equipment Endorsement ",
  },
  {
    label:
      "NBEF32 - Recreational Vehicle, OPCF32 - Use of Recreational Vehicles by Unlicensed Operators, QEF32 - Recreational Vehicle, SEF32 - Recreational Vehicle, AB-SEF32 - Off-Highway Vehicle Endorsement",
    value:
      "NBEF32 - Recreational Vehicle, OPCF32 - Use of Recreational Vehicles by Unlicensed Operators, QEF32 - Recreational Vehicle, SEF32 - Recreational Vehicle, AB-SEF32 - Off-Highway Vehicle Endorsement",
  },
  {
    label:
      "QEF33 - Emergency Service Expense Coverage, SEF33 - Accident Benefits Pedestrian Coverage",
    value:
      "QEF33 - Emergency Service Expense Coverage, SEF33 - Accident Benefits Pedestrian Coverage",
  },
  {
    label:
      "QEF34 - Accident Benefits, SEF34 - Accident Benefits Coverage - Insured Person Defined - Custodians Endorsement",
    value:
      "QEF34 - Accident Benefits, SEF34 - Accident Benefits Coverage - Insured Person Defined - Custodians Endorsement",
  },
  {
    label:
      "ASEF35 - Emergency Service Expense, OPCF35 - Coverage for Emergency Road Service, SEF35 - Emergency Service Expense, AB-SEF35 - Emergency Service Expense Endorsement",
    value:
      "ASEF35 - Emergency Service Expense, OPCF35 - Coverage for Emergency Road Service, SEF35 - Emergency Service Expense, AB-SEF35 - Emergency Service Expense Endorsement",
  },
  {
    label:
      "NBEF36 - Commercial Automobiles Used Exclusively for Pleasure, SEF36 - Commercial Automobile Used Exclusively for Pleasure",
    value:
      "NBEF36 - Commercial Automobiles Used Exclusively for Pleasure, SEF36 - Commercial Automobile Used Exclusively for Pleasure",
  },
  {
    label:
      "ASEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, SEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, AB-SEF37 - Limitation to Automobile Electronic Accessories and Electronic Equipment Endorsement",
    value:
      "ASEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, SEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, AB-SEF37 - Limitation to Automobile Electronic Accessories and Electronic Equipment Endorsement",
  },
  {
    label:
      "ASEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, OPCF38 - Agreed Limit for Automobile Electronic Accessories and Equipment, SEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, AB-SEF38 - Specified Limit(s) – Automobile Electronic Accessories and Electronic Equipment Endorsement",
    value:
      "ASEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, OPCF38 - Agreed Limit for Automobile Electronic Accessories and Equipment, SEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, AB-SEF38 - Specified Limit(s) – Automobile Electronic Accessories and Electronic Equipment Endorsement",
  },
  {
    label: "SEF39 - Accident Rating Waiver",
    value: "SEF39 - Accident Rating Waiver",
  },
  {
    label:
      "NBEF40 - Fire and Theft Deductible, OPCF40 - Fire and Theft Deductible, QEF40 - Fire Deductible, SEF40 - Fire and Theft Deductible, AB-SEF40 - Fire and Theft Deductible Endorsement",
    value:
      "NBEF40 - Fire and Theft Deductible, OPCF40 - Fire and Theft Deductible, QEF40 - Fire Deductible, SEF40 - Fire and Theft Deductible, AB-SEF40 - Fire and Theft Deductible Endorsement",
  },
  {
    label:
      "QEF41      Endorsement Modifying the Deductibles indicated in the Declarations, OPCF41 - Endorsement Modifying the Deductibles indicated in the Declarations,SEF41 - Endorsement Modifying the Deductibles indicated in the Declarations",
    value:
      "QEF41      Endorsement Modifying the Deductibles indicated in the Declarations, OPCF41 - Endorsement Modifying the Deductibles indicated in the Declarations,SEF41 - Endorsement Modifying the Deductibles indicated in the Declarations",
  },
  {
    label:
      "OPCF43 - Removing Depreciation Deduction, EEF43 Replacement Cost Coverage",
    value:
      "OPCF43 - Removing Depreciation Deduction, EEF43 Replacement Cost Coverage",
  },
  {
    label:
      "NBEF44 - Family Protection, QEF44 - Territory Extension, SEF44 - Family Protection, EEF44 Family Protection Coverage, AB-SEF44 - Family Protection Endorsement ",
    value:
      "NBEF44 - Family Protection, QEF44 - Territory Extension, SEF44 - Family Protection, EEF44 Family Protection Coverage, AB-SEF44 - Family Protection Endorsement ",
  },
  {
    label: "QEF45 - Warranty Applicable to Theft of the Entire Automobile",
    value: "QEF45 - Warranty Applicable to Theft of the Entire Automobile",
  },
  {
    label:
      "OPCF46 - Pre-determined Income from Self-employment Agreement, EEF46 Notice of Cancellation",
    value:
      "OPCF46 - Pre-determined Income from Self-employment Agreement, EEF46 Notice of Cancellation",
  },
  {
    label: "OPCF47 - Agreement Not to Rely on SABS Priority of Payment Rules",
    value: "OPCF47 - Agreement Not to Rely on SABS Priority of Payment Rules",
  },
  {
    label:
      "SEF48 - Optional Supplementary Benefits Section B - Accident Benefits (Nova Scotia only)Added Coverage to Offset Tort Deductibles Endorsment (Ontarioonly)",
    value:
      "SEF48 - Optional Supplementary Benefits Section B - Accident Benefits (Nova Scotia only)Added Coverage to Offset Tort Deductibles Endorsment (Ontarioonly)",
  },
  {
    label:
      "OPCF 49 - Agreement Not to Recover for Loss or Damage from an Automobile Collision (for Ontario Automobile Policy OAP 1)",
    value:
      "OPCF 49 - Agreement Not to Recover for Loss or Damage from an Automobile Collision (for Ontario Automobile Policy OAP 1)",
  },
  {
    label: "ASEF62 - Agreement to Provide Coverage Under Section C",
    value: "ASEF62 - Agreement to Provide Coverage Under Section C",
  },
  {
    label:
      "ASEF63 - Loss of Use Motor Home and Vacation Travel Trailer Emergency Accommodation Expense ",
    value:
      "ASEF63 - Loss of Use Motor Home and Vacation Travel Trailer Emergency Accommodation Expense ",
  },
  {
    label: "EEF103 Excess Value Endorsement",
    value: "EEF103 Excess Value Endorsement",
  },
  {
    label: "EEF114 Restoration Endorsement",
    value: "EEF114 Restoration Endorsement",
  },
  { label: "EEF115 Repair Endorsement", value: "EEF115 Repair Endorsement" },
  {
    label: "EEF110 Environmental Liability Limitations",
    value: "EEF110 Environmental Liability Limitations",
  },
  {
    label: "EEF113 Approved Drivers Warranty",
    value: "EEF113 Approved Drivers Warranty",
  },
  {
    label: "SEF13a - Deletion of Automobile",
    value: "SEF13a - Deletion of Automobile",
  },
  { label: "SEF13b - Deleted Coverage ", value: "SEF13b - Deleted Coverage " },
  {
    label:
      "NBEF13c - Comprehensive Cover - Deletion of Glass, OPCF13c - Restricting Glass Coverage, QEF13c - Comprehensive Cover - Deletion of Glass,SEF13c - Comprehensive Cover Deletion of Glass",
    value:
      "NBEF13c - Comprehensive Cover - Deletion of Glass, OPCF13c - Restricting Glass Coverage, QEF13c - Comprehensive Cover - Deletion of Glass,SEF13c - Comprehensive Cover Deletion of Glass",
  },
  {
    label:
      "SEF13d - Comprehensive Cover Limited Glass, AB-SEF13(D) - Limitation of Glass Coverage Endorsement",
    value:
      "SEF13d - Comprehensive Cover Limited Glass, AB-SEF13(D) - Limitation of Glass Coverage Endorsement",
  },
  {
    label:
      "OPCF13h - Deletion of Hail Coverage, SEF13h - Section C - Deletion Hail Coverage, AB-SEF13(H) - Existing Hail Damage - Deletion of Hail Coverage Endorsement",
    value:
      "OPCF13h - Deletion of Hail Coverage, SEF13h - Section C - Deletion Hail Coverage, AB-SEF13(H) - Existing Hail Damage - Deletion of Hail Coverage Endorsement",
  },
  { label: "SEF13S Glass Deleted", value: "SEF13S Glass Deleted" },
  {
    label: "SEF15a - Substitution of Automobile (no change in coverage)",
    value: "SEF15a - Substitution of Automobile (no change in coverage)",
  },
  {
    label: "SEF15b - Combined Substitution of Automobile and Deleted Coverage",
    value: "SEF15b - Combined Substitution of Automobile and Deleted Coverage",
  },
  {
    label:
      "SEF15c - Combined Substitution of Automobile and Additional Coverage",
    value:
      "SEF15c - Combined Substitution of Automobile and Additional Coverage",
  },
  {
    label:
      "SEF18 - Replacement Cost Endorsement, AB-SEF18 - Replacement Cost Endorsement",
    value:
      "SEF18 - Replacement Cost Endorsement, AB-SEF18 - Replacement Cost Endorsement",
  },
  {
    label:
      "NBEF19a - Valued Automobile(s), OPCF19a - Agreed Value of Automobiles, SEF19a - Valued Automobile(s), EEF19a Stated Value, AB-SEF19(A) - Valued Automobile Endorsement",
    value:
      "NBEF19a - Valued Automobile(s), OPCF19a - Agreed Value of Automobiles, SEF19a - Valued Automobile(s), EEF19a Stated Value, AB-SEF19(A) - Valued Automobile Endorsement",
  },
  {
    label:
      "QEF20a - Travel Expenses (Broad Form), EEF20a Liability for Loss of Use",
    value:
      "QEF20a - Travel Expenses (Broad Form), EEF20a Liability for Loss of Use",
  },
  {
    label:
      "OPCF21b - Blanket Fleet Coverage for Ontario Licensed Automobiles, QEF21b - Blanket Basis Fleet (Annual Premium Adjustment), SEF21b - Blanket Basis Fleet, EEF21b Blanket Basis Fleet, AB-SEF21(B) - Blanket Basis Fleet Endorsement",
    value:
      "OPCF21b - Blanket Fleet Coverage for Ontario Licensed Automobiles, QEF21b - Blanket Basis Fleet (Annual Premium Adjustment), SEF21b - Blanket Basis Fleet, EEF21b Blanket Basis Fleet, AB-SEF21(B) - Blanket Basis Fleet Endorsement",
  },
  {
    label:
      "SEF21d - Excess Coverage for Blanket Basis Fleet, EEF21d Blanket Excess Value, AB-SEF21(D) - Blanket Basis Fleet Omission Endorsement",
    value:
      "SEF21d - Excess Coverage for Blanket Basis Fleet, EEF21d Blanket Excess Value, AB-SEF21(D) - Blanket Basis Fleet Omission Endorsement",
  },
  {
    label:
      "NBEF23a - Mortgage Endorsement, OPCF23a - Lienholder - For use with OAP No. 1 Owner's Form, QEF23a - Notice to Creditor, SEF23a - Mortgage Endorsement, AB-SEF23(A) - Lienholder, Mortgagee or Assignee Endorsement",
    value:
      "NBEF23a - Mortgage Endorsement, OPCF23a - Lienholder - For use with OAP No. 1 Owner's Form, QEF23a - Notice to Creditor, SEF23a - Mortgage Endorsement, AB-SEF23(A) - Lienholder, Mortgagee or Assignee Endorsement",
  },
  {
    label:
      "NBEF23b - Mortgage Endorsement (Broad Form), OPCF23b - Mortgage (Broad Form) - For use with OAP No. 1 Owner's Form , QEF23b - Creditor Coverage, SEF23b - Mortgage Endorsement (Broad Form), AB-SEF23(B) - Lienholder, Mortgagee Or Assignee Endorsement (Broad Form)",
    value:
      "NBEF23b - Mortgage Endorsement (Broad Form), OPCF23b - Mortgage (Broad Form) - For use with OAP No. 1 Owner's Form , QEF23b - Creditor Coverage, SEF23b - Mortgage Endorsement (Broad Form), AB-SEF23(B) - Lienholder, Mortgagee Or Assignee Endorsement (Broad Form)",
  },
  {
    label:
      "NBEF25a - Alteration Endorsement, OPCF25a - Alteration Endorsement, SEF25a - Alteration Endorsement, AB-SEF25(A) - Alteration Endorsement",
    value:
      "NBEF25a - Alteration Endorsement, OPCF25a - Alteration Endorsement, SEF25a - Alteration Endorsement, AB-SEF25(A) - Alteration Endorsement",
  },
  {
    label:
      "QEF27a - Civil Liability Resulting from Damage to Non owned Automobiles EXCLUDING Automobiles Provided by an Employer, EEF27a Legal Liability for Damage to Non-Owned Automobiles",
    value:
      "QEF27a - Civil Liability Resulting from Damage to Non owned Automobiles EXCLUDING Automobiles Provided by an Employer, EEF27a Legal Liability for Damage to Non-Owned Automobiles",
  },
  {
    label:
      "ASEF27B - Legal Liability for Damage to Non-owned Automobile, OPCF27b - Business Operations - Liability for Damage to Non-owned Automobile(s) in Your Care, Custody or Control",
    value:
      "ASEF27B - Legal Liability for Damage to Non-owned Automobile, OPCF27b - Business Operations - Liability for Damage to Non-owned Automobile(s) in Your Care, Custody or Control",
  },
  {
    label:
      "OPCF28a - Excluded Driver, SEF28a - Reduction of Coverage as Respects to Operation on Airport Property, AB-SEF28(A) - Reduction of Coverage when Automobile Operated on Airport Property Endorsement",
    value:
      "OPCF28a - Excluded Driver, SEF28a - Reduction of Coverage as Respects to Operation on Airport Property, AB-SEF28(A) - Reduction of Coverage when Automobile Operated on Airport Property Endorsement",
  },
  {
    label:
      "SEF30a - Excluding Attached Machinery, AB-SEF30(A) Attached Machinery, Apparatus or Equipment Exclusion Endorsement: Sections A.1 and C",
    value:
      "SEF30a - Excluding Attached Machinery, AB-SEF30(A) Attached Machinery, Apparatus or Equipment Exclusion Endorsement: Sections A.1 and C",
  },
  {
    label: "EEF32a Snowmobile Underage Operator",
    value: "EEF32a Snowmobile Underage Operator",
  },
  { label: "ATV Underage Operator", value: "ATV Underage Operator" },
  {
    label: "Motorcycle Underage Operator",
    value: "Motorcycle Underage Operator",
  },
  {
    label:
      "QEF34a - Accident Benefits (Change of Amounts or insured persons - when the insured is an Individual)",
    value:
      "QEF34a - Accident Benefits (Change of Amounts or insured persons - when the insured is an Individual)",
  },
  {
    label:
      "DEPRECATED QEF34a-b - Accident Benefits (Change of Amounts or insured persons)",
    value:
      "DEPRECATED QEF34a-b - Accident Benefits (Change of Amounts or insured persons)",
  },
  {
    label:
      "QEF34b Accident Benefits (Change of Amounts or insured persons - when the insured is a  Legal Person, Partnership or Association",
    value:
      "QEF34b Accident Benefits (Change of Amounts or insured persons - when the insured is a  Legal Person, Partnership or Association",
  },
  {
    label:
      "QEF37a - Modified Coverage on Electronic Equipment - Limitation of Amount",
    value:
      "QEF37a - Modified Coverage on Electronic Equipment - Limitation of Amount",
  },
  {
    label:
      "QEF37b - Modified Coverage on Electronic Equipment - Exclusion of Equipment",
    value:
      "QEF37b - Modified Coverage on Electronic Equipment - Exclusion of Equipment",
  },
  {
    label:
      "SEF39B - Designated Operator Traffic Safety Conviction Rating Waiver Endorsement, AB-SEF39(B) - Designated Operator Minor Traffic Safety Conviction Rating Waiver Endorsement",
    value:
      "SEF39B - Designated Operator Traffic Safety Conviction Rating Waiver Endorsement, AB-SEF39(B) - Designated Operator Minor Traffic Safety Conviction Rating Waiver Endorsement",
  },
  {
    label:
      "OPCF43a - Removing Depreciation Deduction for Specified Lessee(s), QEF43a - Change to Loss Payment – Partial Loss – New Parts",
    value:
      "OPCF43a - Removing Depreciation Deduction for Specified Lessee(s), QEF43a - Change to Loss Payment – Partial Loss – New Parts",
  },
  {
    label: "Change to Loss Payment - Partial Loss - Waiver of Depreciation",
    value: "Change to Loss Payment - Partial Loss - Waiver of Depreciation",
  },
  {
    label: "Change to Loss Payment - Total Loss - Agreed Amount",
    value: "Change to Loss Payment - Total Loss - Agreed Amount",
  },
  {
    label: "Change to Loss Payment - Total Loss - Waiver of Depreciation",
    value: "Change to Loss Payment - Total Loss - Waiver of Depreciation",
  },
  {
    label: "Change to Loss Payment - Total Loss - Replacement Cost",
    value: "Change to Loss Payment - Total Loss - Replacement Cost",
  },
  {
    label: "Change to Loss Payment - Total Loss - Increased Settlement",
    value: "Change to Loss Payment - Total Loss - Increased Settlement",
  },
  {
    label: "EEF43l Limited Waiver of Depreciation",
    value: "EEF43l Limited Waiver of Depreciation",
  },
  { label: "Replacement Cost (Owned)", value: "Replacement Cost (Owned)" },
  {
    label:
      "NBEF43r - Limited Waiver of Depreciation, SEF43r - Limited Waiver of Depreciation, AB-SEF43R - Limited Waiver of Depreciation",
    value:
      "NBEF43r - Limited Waiver of Depreciation, SEF43r - Limited Waiver of Depreciation, AB-SEF43R - Limited Waiver of Depreciation",
  },
  {
    label:
      "ASEFr(L) - Limited Waiver of Depreciation (Specified Lessee), OPCF43r(L) - Limited Waiver of Depreciation (Specified Lessee), SEF43r(L) - Limited Waiver of Depreciation (Specified Lessee), AB-SEF43R(L) Specified Lessee Limited Waiver of Depreciation Endorsement",
    value:
      "ASEFr(L) - Limited Waiver of Depreciation (Specified Lessee), OPCF43r(L) - Limited Waiver of Depreciation (Specified Lessee), SEF43r(L) - Limited Waiver of Depreciation (Specified Lessee), AB-SEF43R(L) Specified Lessee Limited Waiver of Depreciation Endorsement",
  },
  {
    label: "Replacement Cost (Specified Lessee)",
    value: "Replacement Cost (Specified Lessee)",
  },
  {
    label:
      "QEF 4-41 Endorsement Modifying the Deductibles indicated in the Declarations",
    value:
      "QEF 4-41 Endorsement Modifying the Deductibles indicated in the Declarations",
  },
  {
    label: "OPCF44r - Family Protection Coverage",
    value: "OPCF44r - Family Protection Coverage",
  },
  {
    label:
      "NBEF4a - Permission to Carry Explosives, OPCF4a - Permission to Carry Explosives, QEF4a - Transportation of Explosives, SEF4a - Permission to Carry Explosives, EEF4a Permission to Carry Explosives, AB-SEF4(A) - Permission to Carry Explosives Endorsement",
    value:
      "NBEF4a - Permission to Carry Explosives, OPCF4a - Permission to Carry Explosives, QEF4a - Transportation of Explosives, SEF4a - Permission to Carry Explosives, EEF4a Permission to Carry Explosives, AB-SEF4(A) - Permission to Carry Explosives Endorsement",
  },
  {
    label:
      "NBEF4B - Permission to Carry Radioactive Material, OPCF4b - Permission to Carry Radioactive Material, QEF4b - Transportation of Radioactive Material, SEF4b - Permission to Carry Radioactive Material, EEF4b Permission to Carry Radioactive Material, AB-SEF4(B) - Permission to Carry Radioactive Materials Endorsement",
    value:
      "NBEF4B - Permission to Carry Radioactive Material, OPCF4b - Permission to Carry Radioactive Material, QEF4b - Transportation of Radioactive Material, SEF4b - Permission to Carry Radioactive Material, EEF4b Permission to Carry Radioactive Material, AB-SEF4(B) - Permission to Carry Radioactive Materials Endorsement",
  },
  {
    label:
      "OPCF5a - Replaced by OPCF #5, QEF5a - Lease or Leasing - Amended Q.P.F. No. 1 When Owner and One Lessee Shown as Named Insureds",
    value:
      "OPCF5a - Replaced by OPCF #5, QEF5a - Lease or Leasing - Amended Q.P.F. No. 1 When Owner and One Lessee Shown as Named Insureds",
  },
  {
    label:
      "OPCF5b - Permission to Rent or Lease (Specified Lessee and Modified Declarations - Master Policy)",
    value:
      "OPCF5b - Permission to Rent or Lease (Specified Lessee and Modified Declarations - Master Policy)",
  },
  {
    label:
      "NBEF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), OPCF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), QEF5c - Rented Automobile (Unspecified Lessees - Short Term Only), SEF5c - Permission to Rent or Lease, AB-SEF5(C) - Permission to Rent Endorsement",
    value:
      "NBEF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), OPCF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), QEF5c - Rented Automobile (Unspecified Lessees - Short Term Only), SEF5c - Permission to Rent or Lease, AB-SEF5(C) - Permission to Rent Endorsement",
  },
  {
    label: "Permission To Rent Or Lease The Vehicle For Carsharing (5CS)",
    value: "Permission To Rent Or Lease The Vehicle For Carsharing (5CS)",
  },
  {
    label:
      "NBEF5d - Conversion Coverage (Rented or Leased Automobiles), OPCF5d - Conversion Coverage (Rented or Leased Automobiles), QEF5d - Conversion Coverage (Rented or Leased Automobiles), SEF5d - Conversion Coverage, AB-SEF5(D) - Conversion Coverage Endorsement",
    value:
      "NBEF5d - Conversion Coverage (Rented or Leased Automobiles), OPCF5d - Conversion Coverage (Rented or Leased Automobiles), QEF5d - Conversion Coverage (Rented or Leased Automobiles), SEF5d - Conversion Coverage, AB-SEF5(D) - Conversion Coverage Endorsement",
  },
  {
    label:
      "NBEF6a - Permission to Carry Passengers for Compensation, OPCF6a - Permission to Carry Paying Passengers, SEF6a - Permission to Carry Passengers for Compensation, EEF6a Permission to Carry Passengers, AB-SEF6(A) - Carry Passengers for Compensation or Hire Endorsement",
    value:
      "NBEF6a - Permission to Carry Passengers for Compensation, OPCF6a - Permission to Carry Paying Passengers, SEF6a - Permission to Carry Passengers for Compensation, EEF6a Permission to Carry Passengers, AB-SEF6(A) - Carry Passengers for Compensation or Hire Endorsement",
  },
  {
    label:
      "NBEF6b - School Bus, OPCF6b - School Bus, SEF6b - School Bus - For use on SPF No. 11, AB-SEF6(B) - School Transportation Endorsement",
    value:
      "NBEF6b - School Bus, OPCF6b - School Bus, SEF6b - School Bus - For use on SPF No. 11, AB-SEF6(B) - School Transportation Endorsement",
  },
  {
    label:
      "OPCF6c - Public Passenger Vehicles, SEF6c - Public Passenger Vehicles, AB-SEF6(C) - Public Passenger Automobile Enhanced Coverage Endorsement",
    value:
      "OPCF6c - Public Passenger Vehicles, SEF6c - Public Passenger Vehicles, AB-SEF6(C) - Public Passenger Automobile Enhanced Coverage Endorsement",
  },
  {
    label:
      "NBEF6d - Driver Training School, OPCF6d - Driver Training School, SEF6d - Driver Training School, AB-SEF6(D) - Driver Training Services Endorsement",
    value:
      "NBEF6d - Driver Training School, OPCF6d - Driver Training School, SEF6d - Driver Training School, AB-SEF6(D) - Driver Training Services Endorsement",
  },
  {
    label: "OPCF6e - No longer in use, SEF6e - Employees Business Use",
    value: "OPCF6e - No longer in use, SEF6e - Employees Business Use",
  },
  {
    label:
      "NBEF6f - Public Passenger Vehicles, OPCF6f - Public Passenger Vehicles - Combined Limits, SEF6f - Public Passenger Vehicles, AB-SEF6(F) - Public Passenger Automobile Endorsement",
    value:
      "NBEF6f - Public Passenger Vehicles, OPCF6f - Public Passenger Vehicles - Combined Limits, SEF6f - Public Passenger Vehicles, AB-SEF6(F) - Public Passenger Automobile Endorsement",
  },
  {
    label:
      "O.E.F. 87 - Added Coverage to Offset Tort Deductibles (for Ontario Garage Automobile Policy O.A.P. 4)",
    value:
      "O.E.F. 87 - Added Coverage to Offset Tort Deductibles (for Ontario Garage Automobile Policy O.A.P. 4)",
  },
  {
    label:
      "ASEF8a - Property Damage Reimbursement for Operation by Named Person - (Section A Only), QEF8a - Deductible Civil Liability, SEF8a - Property Damage Reimbursement for Operation by Named Person, AB-SEF8(A) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
    value:
      "ASEF8a - Property Damage Reimbursement for Operation by Named Person - (Section A Only), QEF8a - Deductible Civil Liability, SEF8a - Property Damage Reimbursement for Operation by Named Person, AB-SEF8(A) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
  },
  {
    label:
      "SEF98B- Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement ",
    value:
      "SEF98B- Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement ",
  },
  {
    label:
      "SEF99- Excluding Long Term Leased Vehicle Endorsement, AB-SEF99- Leased Vehicle Exclusion Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6)",
    value:
      "SEF99- Excluding Long Term Leased Vehicle Endorsement, AB-SEF99- Leased Vehicle Exclusion Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6)",
  },
  { label: "Accident Benefits", value: "Accident Benefits" },
  {
    label: "Accident Benefits Occasional Driver",
    value: "Accident Benefits Occasional Driver",
  },
  { label: "Attendant Care Benefits", value: "Attendant Care Benefits" },
  {
    label: "Attendant Care for Occasional Driver",
    value: "Attendant Care for Occasional Driver",
  },
  { label: "Accidental Death Benefits", value: "Accidental Death Benefits" },
  { label: "After-marketAccessories", value: "After-marketAccessories" },
  {
    label: "Attached Machinery - Property Damage Reimbursement",
    value: "Attached Machinery - Property Damage Reimbursement",
  },
  {
    label: "Animal or Bird Collision Deductible",
    value: "Animal or Bird Collision Deductible",
  },
  { label: "All Perils", value: "All Perils" },
  {
    label: "All Perils Occasional Driver",
    value: "All Perils Occasional Driver",
  },
  {
    label: "Deletion of Hail Coverage – without pre-existing damage",
    value: "Deletion of Hail Coverage – without pre-existing damage",
  },
  { label: "Hail Deductible", value: "Hail Deductible" },
  { label: "CATIM, CHHMB", value: "CATIM, CHHMB" },
  { label: "CATIM, DCB", value: "CATIM, DCB" },
  { label: "Catastrophic Impairment", value: "Catastrophic Impairment" },
  {
    label: "Catastrophic Impairment (Occasional Driver)",
    value: "Catastrophic Impairment (Occasional Driver)",
  },
  {
    label:
      "Coverage under section C for contents of described Motorhome or Vacation Trailer. ",
    value:
      "Coverage under section C for contents of described Motorhome or Vacation Trailer. ",
  },
  {
    label: "Cancellation/Deletion of Coverage Endorsement",
    value: "Cancellation/Deletion of Coverage Endorsement",
  },
  {
    label: "Endorsement for change in deductible notice ",
    value: "Endorsement for change in deductible notice ",
  },
  { label: "Cellular Telephone", value: "Cellular Telephone" },
  {
    label:
      "Caregiver, Housekeeping & Home Maintenance Benefits, and Dependant Care Benefits",
    value:
      "Caregiver, Housekeeping & Home Maintenance Benefits, and Dependant Care Benefits",
  },
  {
    label: "Caregiver, Housekeeping & Home Maintenance Benefits",
    value: "Caregiver, Housekeeping & Home Maintenance Benefits",
  },
  {
    label: "Caregiver, Housekeeping & Home Maintenance for Occasional Driver",
    value: "Caregiver, Housekeeping & Home Maintenance for Occasional Driver",
  },
  {
    label:
      "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care (Occasional Driver)",
    value:
      "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care (Occasional Driver)",
  },
  {
    label:
      "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care",
    value:
      "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care",
  },
  { label: "Claims advantage", value: "Claims advantage" },
  { label: "Classic Vehicle", value: "Classic Vehicle" },
  { label: "Comprehensive", value: "Comprehensive" },
  { label: "Collision", value: "Collision" },
  {
    label: "Collision Occasional Driver",
    value: "Collision Occasional Driver",
  },
  { label: "Collector Vehicle", value: "Collector Vehicle" },
  { label: "Claims Protection Plan", value: "Claims Protection Plan" },
  {
    label: "Claims Protection Plan - Extended",
    value: "Claims Protection Plan - Extended",
  },
  {
    label: "Claims Protection Plan - Occasional Driver",
    value: "Claims Protection Plan - Occasional Driver",
  },
  {
    label: "Claims Protection Plan - Used",
    value: "Claims Protection Plan - Used",
  },
  { label: "Dependant Care Benefits", value: "Dependant Care Benefits" },
  {
    label: "Dependant Care for Occasional Driver",
    value: "Dependant Care for Occasional Driver",
  },
  { label: "Declared Value", value: "Declared Value" },
  { label: "Death and Funeral Benefits", value: "Death and Funeral Benefits" },
  {
    label: "Death & Funeral for Occasional Driver",
    value: "Death & Funeral for Occasional Driver",
  },
  {
    label: "Death and Funeral Income Replacement",
    value: "Death and Funeral Income Replacement",
  },
  { label: "Abstain from Alcohol", value: "Abstain from Alcohol" },
  {
    label: "Discount - Autonomous Emergency Braking System",
    value: "Discount - Autonomous Emergency Braking System",
  },
  {
    label: "Discount - Anti Theft Device Automatically Activated (non alarms)",
    value: "Discount - Anti Theft Device Automatically Activated (non alarms)",
  },
  { label: "Discount - Alarm System", value: "Discount - Alarm System" },
  {
    label: "Discount - Association/Membership",
    value: "Discount - Association/Membership",
  },
  {
    label: "Discount - Away - at - School",
    value: "Discount - Away - at - School",
  },
  { label: "Discount - Antique Vehicle", value: "Discount - Antique Vehicle" },
  {
    label: "Discount - Authorized Vendors",
    value: "Discount - Authorized Vendors",
  },
  { label: "Broker Multi Line Discount", value: "Broker Multi Line Discount" },
  { label: "Basic Policy (No Frills)", value: "Basic Policy (No Frills)" },
  { label: "Broker Self Service", value: "Broker Self Service" },
  { label: "Discount - Conviction Free", value: "Discount - Conviction Free" },
  {
    label: "Discount - Religious Clerical",
    value: "Discount - Religious Clerical",
  },
  {
    label: "Discount - No. of Comprehensive Claims ",
    value: "Discount - No. of Comprehensive Claims ",
  },
  {
    label: "Discount - Conviction Free (Occasional Driver)",
    value: "Discount - Conviction Free (Occasional Driver)",
  },
  { label: "Discount - Combined Policy", value: "Discount - Combined Policy" },
  {
    label: "Discount - Credit Consent Received",
    value: "Discount - Credit Consent Received",
  },
  { label: "Risk Premium Cap", value: "Risk Premium Cap" },
  { label: "Discount - Driver Age", value: "Discount - Driver Age" },
  { label: "Risk Discount Cap", value: "Risk Discount Cap" },
  {
    label: "Discount - Anti Theft Device Manually Activated (non alarms)",
    value: "Discount - Anti Theft Device Manually Activated (non alarms)",
  },
  {
    label: "Discount - Group Rate Applied for Occasional Driver",
    value: "Discount - Group Rate Applied for Occasional Driver",
  },
  {
    label: "Discount - Insured's Driving Record",
    value: "Discount - Insured's Driving Record",
  },
  {
    label: "Discount - Driving Simulator",
    value: "Discount - Driving Simulator",
  },
  { label: "Discount - Driver Training", value: "Discount - Driver Training" },
  {
    label: "Discount - Experienced Driver",
    value: "Discount - Experienced Driver",
  },
  { label: "Discount for Electric car", value: "Discount for Electric car" },
  { label: "Discount - Employee", value: "Discount - Employee" },
  { label: "Discount – Excess Trailer", value: "Discount – Excess Trailer" },
  { label: "Discount - Facility", value: "Discount - Facility" },
  {
    label: "Discount - Farm Comprehensive",
    value: "Discount - Farm Comprehensive",
  },
  { label: "Discount - Female", value: "Discount - Female" },
  {
    label: "Discount - Claims Forgiveness",
    value: "Discount - Claims Forgiveness",
  },
  { label: "Discount - Farm", value: "Discount - Farm" },
  { label: "Discount- Family ", value: "Discount- Family " },
  {
    label: "Discount - Group Rate Applied",
    value: "Discount - Group Rate Applied",
  },
  {
    label:
      "Discount - Underage classes provided a parents’ private passenger automobile(s) is insured with the same company and at least one private passenger automobile carries mandatory coverages.",
    value:
      "Discount - Underage classes provided a parents’ private passenger automobile(s) is insured with the same company and at least one private passenger automobile carries mandatory coverages.",
  },
  {
    label: "Discount - Graduated License for an Occasional Driver",
    value: "Discount - Graduated License for an Occasional Driver",
  },
  {
    label: "Discount - Graduated License Holder",
    value: "Discount - Graduated License Holder",
  },
  { label: "Discount - Good Student", value: "Discount - Good Student" },
  {
    label: "Discount - Higher Limit Purchased",
    value: "Discount - Higher Limit Purchased",
  },
  { label: "Discount - Hybrid", value: "Discount - Hybrid" },
  {
    label:
      "Discount - I Promise Program-Parent/Teen Mutual Safe Driving Contract",
    value:
      "Discount - I Promise Program-Parent/Teen Mutual Safe Driving Contract",
  },
  {
    label: "Discount - Low Commute Distance",
    value: "Discount - Low Commute Distance",
  },
  {
    label: "Discount - Low Annual Mileage",
    value: "Discount - Low Annual Mileage",
  },
  { label: "Discount - Miscellaneous", value: "Discount - Miscellaneous" },
  {
    label: "Discount - Mortgage Free Home/Condominium ",
    value: "Discount - Mortgage Free Home/Condominium ",
  },
  { label: "Discount - Motor Home", value: "Discount - Motor Home" },
  { label: "Motor Home", value: "Motor Home" },
  { label: "Discount - Mature Driver", value: "Discount - Mature Driver" },
  { label: "Multi Motorcycle Discount", value: "Multi Motorcycle Discount" },
  {
    label: "Motorcycle Improvement Course",
    value: "Motorcycle Improvement Course",
  },
  { label: "Discount - Multiple Line ", value: "Discount - Multiple Line " },
  { label: "Discount - Marital Status", value: "Discount - Marital Status" },
  { label: "Discount - Multi Vehicle", value: "Discount - Multi Vehicle" },
  {
    label: "Discount - Non Authorized Vendors",
    value: "Discount - Non Authorized Vendors",
  },
  { label: "Discount New Business", value: "Discount New Business" },
  { label: "Discount - No Claims", value: "Discount - No Claims" },
  { label: "Discount - New Driver", value: "Discount - New Driver" },
  {
    label: "Discount - No Claims (Occasional Driver)",
    value: "Discount - No Claims (Occasional Driver)",
  },
  { label: "Discount - Non-smoker", value: "Discount - Non-smoker" },
  { label: "Discount - New Vehicle", value: "Discount - New Vehicle" },
  { label: "Discount - Occupation", value: "Discount - Occupation" },
  {
    label: "Discount - Occasional Driver",
    value: "Discount - Occasional Driver",
  },
  {
    label: "Discount - Facility (Occasional Driver)",
    value: "Discount - Facility (Occasional Driver)",
  },
  { label: "Discount - Out of Province", value: "Discount - Out of Province" },
  {
    label:
      "Discount - Facility-Occasional Driver (Remedial Driver Improvement Course)",
    value:
      "Discount - Facility-Occasional Driver (Remedial Driver Improvement Course)",
  },
  {
    label: "Discount - Owner of Vehicle",
    value: "Discount - Owner of Vehicle",
  },
  { label: "Discount - Private Parking", value: "Discount - Private Parking" },
  { label: "Discount - Professional", value: "Discount - Professional" },
  {
    label: "Discount - Partial workweek commute",
    value: "Discount - Partial workweek commute",
  },
  {
    label: "Discount - Range of Operations",
    value: "Discount - Range of Operations",
  },
  {
    label:
      "Discount - Recreational vehicle combined with a private passenger vehicle",
    value:
      "Discount - Recreational vehicle combined with a private passenger vehicle",
  },
  { label: "Discount - Retiree", value: "Discount - Retiree" },
  {
    label:
      "Discount - Facility - Regular Driver (Remedial Driver Improvement Course)",
    value:
      "Discount - Facility - Regular Driver (Remedial Driver Improvement Course)",
  },
  { label: "Discount - Renewal", value: "Discount - Renewal" },
  {
    label: "Discount - Renewal (Occasional)",
    value: "Discount - Renewal (Occasional)",
  },
  {
    label: "Discount – Multi Recreational Vehicle",
    value: "Discount – Multi Recreational Vehicle",
  },
  {
    label: "Discount - Senior Citizen (Age Related)",
    value: "Discount - Senior Citizen (Age Related)",
  },
  { label: "Discount - Snow Tires", value: "Discount - Snow Tires" },
  { label: "Select Rider Discount ", value: "Select Rider Discount " },
  { label: "Discount - Stability", value: "Discount - Stability" },
  {
    label: "Discount - Electronic Stability Control System (Factory Equipped)",
    value: "Discount - Electronic Stability Control System (Factory Equipped)",
  },
  { label: "Discount - Trail Permit", value: "Discount - Trail Permit" },
  { label: "Discount - Territory", value: "Discount - Territory" },
  { label: "Total Discount Applied", value: "Total Discount Applied" },
  {
    label: "Discount - Valued/Preferred Customer",
    value: "Discount - Valued/Preferred Customer",
  },
  {
    label: "Discount-Vehicle to Operator Ratio",
    value: "Discount-Vehicle to Operator Ratio",
  },
  { label: "Discount Young Driver", value: "Discount Young Driver" },
  {
    label: "Driving Record Guarantee After Loss",
    value: "Driving Record Guarantee After Loss",
  },
  {
    label: "Driving Record Guarantee Before Loss",
    value: "Driving Record Guarantee Before Loss",
  },
  { label: "Flatbed Towing Expense", value: "Flatbed Towing Expense" },
  {
    label: "Restrict Hail Damage to Punctures",
    value: "Restrict Hail Damage to Punctures",
  },
  { label: "Health Services Levy", value: "Health Services Levy" },
  { label: "Indexation", value: "Indexation" },
  {
    label: "Indexation Benefit for Occasional Driver",
    value: "Indexation Benefit for Occasional Driver",
  },
  {
    label:
      "Loss of Use Motorhome and Vacation Trailer Emergency Accommodation Expense Endorsement",
    value:
      "Loss of Use Motorhome and Vacation Trailer Emergency Accommodation Expense Endorsement",
  },
  { label: "Waiver of Deductible", value: "Waiver of Deductible" },
  {
    label:
      "Caregiver, Housekeeping & Home Maintenance Benefits, Medical & Rehabilitation Benefits, and Attendant Care Benefits",
    value:
      "Caregiver, Housekeeping & Home Maintenance Benefits, Medical & Rehabilitation Benefits, and Attendant Care Benefits",
  },
  {
    label: "Manitoba only - Cancellation/Deletion of Coverage Endorsement",
    value: "Manitoba only - Cancellation/Deletion of Coverage Endorsement",
  },
  {
    label: "Manitoba only - Loss of Rental Income",
    value: "Manitoba only - Loss of Rental Income",
  },
  {
    label: "Manitoba only - Value Plus ",
    value: "Manitoba only - Value Plus ",
  },
  {
    label: "Manitoba only - Vehicle Lay-up",
    value: "Manitoba only - Vehicle Lay-up",
  },
  {
    label: "Manitoba only - Value Plus - Specified Lessee",
    value: "Manitoba only - Value Plus - Specified Lessee",
  },
  {
    label: "Minor Conviction Protection",
    value: "Minor Conviction Protection",
  },
  {
    label: "Minor Conviction Protection for Occasional Driver",
    value: "Minor Conviction Protection for Occasional Driver",
  },
  {
    label: "Minor Conviction Protection for Occasional Driver - Used",
    value: "Minor Conviction Protection for Occasional Driver - Used",
  },
  {
    label: "Minor Conviction Protection - Used",
    value: "Minor Conviction Protection - Used",
  },
  { label: "Medical Expenses", value: "Medical Expenses" },
  {
    label: "Medical & Rehabilitation Benefits",
    value: "Medical & Rehabilitation Benefits",
  },
  {
    label:
      "Medical & Rehabilitation Benefits, and Attendant Care Benefits, and Dependant Care Benefits",
    value:
      "Medical & Rehabilitation Benefits, and Attendant Care Benefits, and Dependant Care Benefits",
  },
  {
    label: "Agreed Limit for Motorcycle Accessories and Equipment",
    value: "Agreed Limit for Motorcycle Accessories and Equipment",
  },
  {
    label: "Motorcycle - Vacation Expense",
    value: "Motorcycle - Vacation Expense",
  },
  {
    label: "Medical, Rehabilitation & Attendant Care",
    value: "Medical, Rehabilitation & Attendant Care",
  },
  {
    label: "Medical, Rehabilitation and Attendant Care for Occasional Driver",
    value: "Medical, Rehabilitation and Attendant Care for Occasional Driver",
  },
  {
    label: "Medical & Rehabilitation for Occasional Driver",
    value: "Medical & Rehabilitation for Occasional Driver",
  },
  {
    label: "Driving Record Guarantee Before After (Occasional Driver)",
    value: "Driving Record Guarantee Before After (Occasional Driver)",
  },
  {
    label: "Driving Record Guarantee Before Loss (Occasional Driver)",
    value: "Driving Record Guarantee Before Loss (Occasional Driver)",
  },
  { label: "Off Road Use", value: "Off Road Use" },
  { label: "One Deductible Endorsement", value: "One Deductible Endorsement" },
  { label: "Overnight Accommodation", value: "Overnight Accommodation" },
  {
    label: "20 (or 20A), 35, 43 (or 43A or 43R or 43RL)",
    value: "20 (or 20A), 35, 43 (or 43A or 43R or 43RL)",
  },
  {
    label: "27, 35, 43 (or 43A or 43R or 43RL) ",
    value: "27, 35, 43 (or 43A or 43R or 43RL) ",
  },
  {
    label: "20 (or 20A), 43 (or 43A or 43R or 43RL)",
    value: "20 (or 20A), 43 (or 43A or 43R or 43RL)",
  },
  { label: "20 (or 20A), 35 ", value: "20 (or 20A), 35 " },
  {
    label: "20 (or 20A), 27, 43 (or 43A or 43R or 43RL) ",
    value: "20 (or 20A), 27, 43 (or 43A or 43R or 43RL) ",
  },
  { label: "20 (or 20A), 27 ", value: "20 (or 20A), 27 " },
  { label: "20 (or 20A), 27, 34 ", value: "20 (or 20A), 27, 34 " },
  {
    label: "20 (or 20A), 27, 35, 43 (or 43A or 43R or 43RL)",
    value: "20 (or 20A), 27, 35, 43 (or 43A or 43R or 43RL)",
  },
  { label: "20 (or 20A), 27, 35 ", value: "20 (or 20A), 27, 35 " },
  {
    label: "2, 20 (or 20A), 43 (or 43A or 43R or 43RL)",
    value: "2, 20 (or 20A), 43 (or 43A or 43R or 43RL)",
  },
  { label: "2, 20A, 27 and 34 ", value: "2, 20A, 27 and 34 " },
  { label: "2, 20, 27 and 34 ", value: "2, 20, 27 and 34 " },
  { label: "2, 20 (or 20A), 27 ", value: "2, 20 (or 20A), 27 " },
  {
    label: "2, 20 (or 20A), 27 and 43 (or 43A or 43R or 43RL)",
    value: "2, 20 (or 20A), 27 and 43 (or 43A or 43R or 43RL)",
  },
  { label: "18, 20 (or 20A), 27, 35", value: "18, 20 (or 20A), 27, 35" },
  { label: "SRAP, 20, 27, 43", value: "SRAP, 20, 27, 43" },
  { label: "	SRAP, 20, 27", value: "	SRAP, 20, 27" },
  {
    label: "Premium Adjustment Reporting Form",
    value: "Premium Adjustment Reporting Form",
  },
  {
    label: "Personal Protective Equipment (excluding personal effects)",
    value: "Personal Protective Equipment (excluding personal effects)",
  },
  {
    label: "Personal Effects (excluding Personal Protective Equipment)",
    value: "Personal Effects (excluding Personal Protective Equipment)",
  },
  { label: "License/Permit Charge", value: "License/Permit Charge" },
  {
    label: "Death Benefits and Dismemberment",
    value: "Death Benefits and Dismemberment",
  },
  {
    label: "Restoration while in Storage",
    value: "Restoration while in Storage",
  },
  { label: "Road Hazard Glass", value: "Road Hazard Glass" },
  { label: "Renewal Rate Protection", value: "Renewal Rate Protection" },
  { label: "Specified Perils", value: "Specified Perils" },
  { label: "Spare Parts", value: "Spare Parts" },
  {
    label: "Enhanced Roadside Assistance Program",
    value: "Enhanced Roadside Assistance Program",
  },
  {
    label: "Short Term Cancellation Table",
    value: "Short Term Cancellation Table",
  },
  {
    label: "Combined Driver Age/Risk Type Surcharge",
    value: "Combined Driver Age/Risk Type Surcharge",
  },
  {
    label: "Surcharge - Antique Vehicle",
    value: "Surcharge - Antique Vehicle",
  },
  { label: "Surcharge - Business Use", value: "Surcharge - Business Use" },
  {
    label: "Surcharge – Conviction/Claim",
    value: "Surcharge – Conviction/Claim",
  },
  {
    label: "Surcharge - Prior Policy Cancellation",
    value: "Surcharge - Prior Policy Cancellation",
  },
  {
    label: "Surcharge - Claims for Occasional Driver (class 06/05)",
    value: "Surcharge - Claims for Occasional Driver (class 06/05)",
  },
  {
    label: "Surcharge - Convictions for Occasional Driver (class 06/05)",
    value: "Surcharge - Convictions for Occasional Driver (class 06/05)",
  },
  {
    label: "Surcharge - Commercial Exposure",
    value: "Surcharge - Commercial Exposure",
  },
  { label: "Surcharge - Claims", value: "Surcharge - Claims" },
  {
    label: "Surcharge - No. of Comprehensive Claims",
    value: "Surcharge - No. of Comprehensive Claims",
  },
  { label: "Surcharge - Convictions", value: "Surcharge - Convictions" },
  {
    label:
      "Surcharge - Conviction/Claim for an Occasional Driver (class 06/05)",
    value:
      "Surcharge - Conviction/Claim for an Occasional Driver (class 06/05)",
  },
  { label: "Surcharge - Car Pooling", value: "Surcharge - Car Pooling" },
  {
    label: "Surcharge - Risk Premium Cap ",
    value: "Surcharge - Risk Premium Cap ",
  },
  {
    label: "Surcharge - Experience Operator",
    value: "Surcharge - Experience Operator",
  },
  {
    label: "Surcharge - Explosive/Radioactive Material",
    value: "Surcharge - Explosive/Radioactive Material",
  },
  { label: "Surcharge - Facility ", value: "Surcharge - Facility " },
  {
    label: "Surcharge - Filing Certificate",
    value: "Surcharge - Filing Certificate",
  },
  {
    label: "Surcharge - High Kilometres Driven",
    value: "Surcharge - High Kilometres Driven",
  },
  {
    label: "Surcharge - High Performance",
    value: "Surcharge - High Performance",
  },
  { label: "Surcharge - High Value", value: "Surcharge - High Value" },
  {
    label: "Surcharge - Inconsistent Insurance History",
    value: "Surcharge - Inconsistent Insurance History",
  },
  {
    label: "Surcharge - License Suspension",
    value: "Surcharge - License Suspension",
  },
  { label: "Surcharge Leased Vehicle", value: "Surcharge Leased Vehicle" },
  { label: "Surcharge - Miscellaneous", value: "Surcharge - Miscellaneous" },
  {
    label: "Surcharge - Mono-line Policy",
    value: "Surcharge - Mono-line Policy",
  },
  {
    label: "Surcharge - Multiple Operators",
    value: "Surcharge - Multiple Operators",
  },
  { label: "Surcharge - Non Payment", value: "Surcharge - Non Payment" },
  {
    label: "Surcharge - Oversize Engine",
    value: "Surcharge - Oversize Engine",
  },
  {
    label: "Surcharge - Facility (Occasional Driver)",
    value: "Surcharge - Facility (Occasional Driver)",
  },
  {
    label: "Surcharge - Out of Province",
    value: "Surcharge - Out of Province",
  },
  {
    label: "Surcharge - No Previous Insurance",
    value: "Surcharge - No Previous Insurance",
  },
  { label: "Surcharge - Radius", value: "Surcharge - Radius" },
  {
    label: "Surcharge - Right Hand Drive",
    value: "Surcharge - Right Hand Drive",
  },
  {
    label: "Surcharge - Recreational Vehicle ",
    value: "Surcharge - Recreational Vehicle ",
  },
  { label: "Risk Surcharge Cap", value: "Risk Surcharge Cap" },
  {
    label: "Surcharge - Sand & Gravel Operations",
    value: "Surcharge - Sand & Gravel Operations",
  },
  {
    label: "Surcharge - Tows Non-Owned Trailer",
    value: "Surcharge - Tows Non-Owned Trailer",
  },
  { label: "Surcharge - Telematics", value: "Surcharge - Telematics" },
  {
    label: "Surcharge - U.S. & Non-Canadian Exposure",
    value: "Surcharge - U.S. & Non-Canadian Exposure",
  },
  { label: "Transportation Network", value: "Transportation Network" },
  {
    label: "Third Party Liability / Civil Liability",
    value: "Third Party Liability / Civil Liability",
  },
  {
    label: "Third Party Liability – Bodily Injury",
    value: "Third Party Liability – Bodily Injury",
  },
  {
    label: "Third Party Liability – Direct Compensation Property Damage",
    value: "Third Party Liability – Direct Compensation Property Damage",
  },
  {
    label: "Third Party Liability – Bodily Injury (Occasional Driver)",
    value: "Third Party Liability – Bodily Injury (Occasional Driver)",
  },
  {
    label: "Third Party Liability / Civil Liability (Occasional Driver)",
    value: "Third Party Liability / Civil Liability (Occasional Driver)",
  },
  {
    label:
      "Third Party Liability – Direct Compensation Property Damage (Occasional Driver)",
    value:
      "Third Party Liability – Direct Compensation Property Damage (Occasional Driver)",
  },
  {
    label: "Third Party Liability – Property Damage (Occasional Driver)",
    value: "Third Party Liability – Property Damage (Occasional Driver)",
  },
  {
    label: "Third Party Liability – Property Damage",
    value: "Third Party Liability – Property Damage",
  },
  {
    label: "Third Party Liability - Passenger Hazard",
    value: "Third Party Liability - Passenger Hazard",
  },
  {
    label: "Uninsured Automobile Coverage",
    value: "Uninsured Automobile Coverage",
  },
  {
    label: "Uninsured Automobile Coverage (Occasional Driver)",
    value: "Uninsured Automobile Coverage (Occasional Driver)",
  },
  {
    label: "Underinsured Motorist Protection",
    value: "Underinsured Motorist Protection",
  },
  { label: "Usage Based Insurance", value: "Usage Based Insurance" },
  { label: "Waived Nominal Premium ", value: "Waived Nominal Premium " },
  {
    label: `Caregiver & Dependant Care Benefits "No Longer used as of Sep 1/2010"`,
    value: `Caregiver & Dependant Care Benefits "No Longer used as of Sep 1/2010"`,
  },
  {
    label: "Total Disability Max. Weekly Benefit",
    value: "Total Disability Max. Weekly Benefit",
  },
  {
    label: "Income Replacement Benefits",
    value: "Income Replacement Benefits",
  },
  {
    label: "Income Replacement for Occasional Driver",
    value: "Income Replacement for Occasional Driver",
  },
  {
    label: "DEPRECATED Worldwide Travel Life Insurance",
    value: "DEPRECATED Worldwide Travel Life Insurance",
  },
  { label: "High Theft Risk", value: "High Theft Risk" },
  { label: "OEM Replacement Parts", value: "OEM Replacement Parts" },
  {
    label:
      "AB-SEF8(B) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
    value:
      "AB-SEF8(B) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
  },
  {
    label: "AB-SEF19(B) - Limitation of Amount Endorsement",
    value: "AB-SEF19(B) - Limitation of Amount Endorsement",
  },
  {
    label: "AB-SEF28(C) - Named Person(s) Reduction of Coverage Endorsement",
    value: "AB-SEF28(C) - Named Person(s) Reduction of Coverage Endorsement",
  },
  {
    label:
      "AB-SEF30(B) - Attached Machinery, Apparatus or Equipment Exclusion Endorsement Section A- Third Party Liability and Section B- Accident Benefits",
    value:
      "AB-SEF30(B) - Attached Machinery, Apparatus or Equipment Exclusion Endorsement Section A- Third Party Liability and Section B- Accident Benefits",
  },
  {
    label: "AB-SEF39(A) - At-Fault Accident Waiver Endorsement ",
    value: "AB-SEF39(A) - At-Fault Accident Waiver Endorsement ",
  },
  {
    label: "AB-SEF49(A) DCPD Deletion Endorsement",
    value: "AB-SEF49(A) DCPD Deletion Endorsement",
  },
  {
    label: "AB-SEF49(B) Blanket Basis DCPD Deletion Endorsement",
    value: "AB-SEF49(B) Blanket Basis DCPD Deletion Endorsement",
  },
];

export const AUTOMOBILE_CSIO_OTHER_COVERAGE: { [key: string]: string } = {
  "csio:2": `NBEF2 - Drive Other Automobiles - Named Person(s), OPCF2 - Providing Coverage When Named Persons Drive Other Automobiles, QEF2 - Drive Other Automobiles - Named Person(s), SEF2 - Drive Other Automobiles - Named Person(s), AB-SEF2 - Designated Operator(s) Drive Other Automobiles Endorsement`,
  "csio:3":
    "NBEF3 - Drive Government Automobiles, OPCF3 - Drive Government Automobiles, QEF3 - Drive Government Automobiles, SEF3 - Drive Government Automobiles, AB-SEF3 - Drive Government Automobile Endorsement",
  "csio:5":
    "ASEF5 - Permission to Rent or Lease (Specified Lessee), OPCF5 - Permission to Rent or Lease Automobiles and Extending Coverage to the Specific Lessee(s), SEF5 - Permission to Rent or Lease, EEF5 Permission to Rent or Lease",
  "csio:7":
    "NBEF7 - Separate Limits (Third Party Liability), OPCF7 - Separate Limits (Liability), SEF7 - Separate Limits (Third Party Liability)",
  "csio:8":
    "ASEF8 - Property Damage Reimbursement (Section A Only), OPCF8 - Property Damage Reimbursement, QEF8 - Deductible Property Damage, SEF8 - Property Damage Reimbursement (Section A Only)",
  "csio:9":
    "NBEF9 - Marine Use Excluded (Amphibious Vehicles), OPCF9 - Marine Use Excluded (Amphibious Automobiles), QEF9 - Marine Use Excluded (Amphibious Automobiles), SEF9 - Marine Use Excluded (Amphibious Vehicles), AB-SEF9 - Marine Use Excluded Endorsement (Amphibious Vehicles)",
  "csio:10": "SEF10 - Limitation of coverage",
  "csio:11": "SEF11 - Change of Coverages (Section C)",
  "csio:12": "SEF12 - Additional Coverage",
  "csio:14": "SEF14 - Addition of Automobile",
  "csio:16":
    "NBEF16 - Agreement for Suspension of Coverage, OPCF16 - Suspension of Coverage, QEF16 - Suspension for Storage, SEF16 - Agreement for Suspension of Coverage, AB-SEF16 - Suspension of Coverages Endorsement",
  "csio:17":
    "NBEF17 - Reinstatement of Coverage, OPCF17 - Reinstatement of Coverage, QEF17 - Reinstatement After Storage, SEF17 - Reinstatement of Coverage, AB-SEF17 - Reinstatement of Coverages Endorsement",
  "csio:19":
    "NBEF19 - Limitation of Amount, OPCF19 - Limitation of Amount Paid forLoss or Damage Coverages, QEF19 - Section B - Limitation of Amount, SEF19 - Limitation of Amount, EEF19 Limitation of Amount",
  "csio:20":
    "NBEF20 - Loss of Use, OPCF20 - Coverage for Transportation Replacement, QEF20 - Travel Expenses, SEF20 - Loss of Use, EEF20 Loss of Use Coverage, AB-SEF20 - Loss of Use Endorsement",
  "csio:22":
    "NBEF22 - Damage to Property of Passengers , OPCF22 - Damage to Property of Passengers, SEF22 - Damage to Property of Passengers, AB-SEF22 - Damage to Property of Passengers Endorsement",
  "csio:24":
    "NBEF24 - Fire Apparatus , OPCF24 - Fire Apparatus, QEF24 - Fire Apparatus Limitation, SEF24 - Fire Apparatus, AB-SEF24 - Fire and Rescue Equipment Endorsement",
  "csio:25":
    "NBEF25 - Alteration Endorsement, OPCF25 - Replaced by OPCF25A, QEF25 - Modified Declarations, SEF25 - Alteration Endorsement (endorsement changing or correcting statement(s) in the application or changing the rating classification)",
  "csio:26":
    "NBEF26 - Disappearing Deductible (All Perils or Collision), OPCF26 - There is no such endorsement, SEF26 - Disappearing Deductible (All Perils or Collision)",
  "csio:27":
    "ASEF27 - Legal Liability for Damage to Non-owned Automobile, OPCF27 - Liability for Damage to Non-owned Automobile(s) and Providing Other Coverages When Insured Persons Drive Other Automobiles, QEF27 - Civil Liability Resulting from Damage to Non Owned Automobiles INCLUDING Automobiles Provided by an Employer, SEF27 - Legal Liability for Damage to Non owned Automobiles",
  "csio:28":
    "NBEF28 - Reduction of Coverage as Respects Operation by Named Person(s), OPCF28 - Reduction of Coverage for Named Persons, QEF28 - Reduction of Coverage for Named Drivers, SEF28 - Reduction of Coverage as Respects Operation by Named Person(s), EEF28 Excluded Named Person",
  "csio:29":
    "NBEF29 - Additional Coverage as Respects Operation by Named Person(s), OPCF29 - Additional Coverage for Named Persons , QEF29 Additional Coverage for Named Drivers, SEF29 - Additional Coverage as Respects Operation by Named Person(s), AB-SEF29 - Named Person(s) Additional Coverage Endorsement",
  "csio:30":
    "NBEF30 - Excluding Operation of Attached Machinery, OPCF30 - Excluding Operation of Attached Machinery, QEF30 - Attached Machinery Limitation, SEF30 - Excluding Operation of Attached Machinery",
  "csio:31":
    "NBEF31 - Non-owned Equipment, OPCF31 - Non owned Equipment, QEF31 - Non-owned Equipment, SEF31 - Non owned Equipment, AB-SEF31 - Non-Owned Equipment Endorsement ",
  "csio:32":
    "NBEF32 - Recreational Vehicle, OPCF32 - Use of Recreational Vehicles by Unlicensed Operators, QEF32 - Recreational Vehicle, SEF32 - Recreational Vehicle, AB-SEF32 - Off-Highway Vehicle Endorsement",
  "csio:33":
    "QEF33 - Emergency Service Expense Coverage, SEF33 - Accident Benefits Pedestrian Coverage",
  "csio:34":
    "QEF34 - Accident Benefits, SEF34 - Accident Benefits Coverage - Insured Person Defined - Custodians Endorsement",
  "csio:35":
    "ASEF35 - Emergency Service Expense, OPCF35 - Coverage for Emergency Road Service, SEF35 - Emergency Service Expense, AB-SEF35 - Emergency Service Expense Endorsement",
  "csio:36":
    "NBEF36 - Commercial Automobiles Used Exclusively for Pleasure, SEF36 - Commercial Automobile Used Exclusively for Pleasure",
  "csio:37":
    "ASEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, SEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, AB-SEF37 - Limitation to Automobile Electronic Accessories and Electronic Equipment Endorsement",
  "csio:38":
    "ASEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, OPCF38 - Agreed Limit for Automobile Electronic Accessories and Equipment, SEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, AB-SEF38 - Specified Limit(s) – Automobile Electronic Accessories and Electronic Equipment Endorsement",
  "csio:39": "SEF39 - Accident Rating Waiver",
  "csio:40":
    "NBEF40 - Fire and Theft Deductible, OPCF40 - Fire and Theft Deductible, QEF40 - Fire Deductible, SEF40 - Fire and Theft Deductible, AB-SEF40 - Fire and Theft Deductible Endorsement",
  "csio:41":
    "QEF41      Endorsement Modifying the Deductibles indicated in the Declarations, OPCF41 - Endorsement Modifying the Deductibles indicated in the Declarations,SEF41 - Endorsement Modifying the Deductibles indicated in the Declarations",
  "csio:43":
    "OPCF43 - Removing Depreciation Deduction, EEF43 Replacement Cost Coverage",
  "csio:44":
    "NBEF44 - Family Protection, QEF44 - Territory Extension, SEF44 - Family Protection, EEF44 Family Protection Coverage, AB-SEF44 - Family Protection Endorsement ",
  "csio:45": "QEF45 - Warranty Applicable to Theft of the Entire Automobile",
  "csio:46":
    "OPCF46 - Pre-determined Income from Self-employment Agreement, EEF46 Notice of Cancellation",
  "csio:47": "OPCF47 - Agreement Not to Rely on SABS Priority of Payment Rules",
  "csio:48":
    "SEF48 - Optional Supplementary Benefits Section B - Accident Benefits (Nova Scotia only)Added Coverage to Offset Tort Deductibles Endorsment (Ontarioonly)",
  "csio:49":
    "OPCF 49 - Agreement Not to Recover for Loss or Damage from an Automobile Collision (for Ontario Automobile Policy OAP 1)",
  "csio:62": "ASEF62 - Agreement to Provide Coverage Under Section C",
  "csio:63":
    "ASEF63 - Loss of Use Motor Home and Vacation Travel Trailer Emergency Accommodation Expense ",
  "csio:103": "EEF103 Excess Value Endorsement",
  "csio:114": "EEF114 Restoration Endorsement",
  "csio:115": "EEF115 Repair Endorsement",
  "csio:110": "EEF110 Environmental Liability Limitations",
  "csio:113": "EEF113 Approved Drivers Warranty",
  "csio:13A": "SEF13a - Deletion of Automobile",
  "csio:13B": "SEF13b - Deleted Coverage ",
  "csio:13C":
    "NBEF13c - Comprehensive Cover - Deletion of Glass, OPCF13c - Restricting Glass Coverage, QEF13c - Comprehensive Cover - Deletion of Glass,SEF13c - Comprehensive Cover Deletion of Glass",
  "csio:13D":
    "SEF13d - Comprehensive Cover Limited Glass, AB-SEF13(D) - Limitation of Glass Coverage Endorsement",
  "csio:13H":
    "OPCF13h - Deletion of Hail Coverage, SEF13h - Section C - Deletion Hail Coverage, AB-SEF13(H) - Existing Hail Damage - Deletion of Hail Coverage Endorsement",
  "csio:13S": "SEF13S Glass Deleted",
  "csio:15A": "SEF15a - Substitution of Automobile (no change in coverage)",
  "csio:15B":
    "SEF15b - Combined Substitution of Automobile and Deleted Coverage",
  "csio:15C":
    "SEF15c - Combined Substitution of Automobile and Additional Coverage",
  "csio:18":
    "SEF18 - Replacement Cost Endorsement, AB-SEF18 - Replacement Cost Endorsement",
  "csio:19A":
    "NBEF19a - Valued Automobile(s), OPCF19a - Agreed Value of Automobiles, SEF19a - Valued Automobile(s), EEF19a Stated Value, AB-SEF19(A) - Valued Automobile Endorsement",
  "csio:20A":
    "QEF20a - Travel Expenses (Broad Form), EEF20a Liability for Loss of Use",
  "csio:21B":
    "OPCF21b - Blanket Fleet Coverage for Ontario Licensed Automobiles, QEF21b - Blanket Basis Fleet (Annual Premium Adjustment), SEF21b - Blanket Basis Fleet, EEF21b Blanket Basis Fleet, AB-SEF21(B) - Blanket Basis Fleet Endorsement",
  "csio:21D":
    "SEF21d - Excess Coverage for Blanket Basis Fleet, EEF21d Blanket Excess Value, AB-SEF21(D) - Blanket Basis Fleet Omission Endorsement",
  "csio:23A":
    "NBEF23a - Mortgage Endorsement, OPCF23a - Lienholder - For use with OAP No. 1 Owner's Form, QEF23a - Notice to Creditor, SEF23a - Mortgage Endorsement, AB-SEF23(A) - Lienholder, Mortgagee or Assignee Endorsement",
  "csio:23B":
    "NBEF23b - Mortgage Endorsement (Broad Form), OPCF23b - Mortgage (Broad Form) - For use with OAP No. 1 Owner's Form , QEF23b - Creditor Coverage, SEF23b - Mortgage Endorsement (Broad Form), AB-SEF23(B) - Lienholder, Mortgagee Or Assignee Endorsement (Broad Form)",
  "csio:25A":
    "NBEF25a - Alteration Endorsement, OPCF25a - Alteration Endorsement, SEF25a - Alteration Endorsement, AB-SEF25(A) - Alteration Endorsement",
  "csio:27A":
    "QEF27a - Civil Liability Resulting from Damage to Non owned Automobiles EXCLUDING Automobiles Provided by an Employer, EEF27a Legal Liability for Damage to Non-Owned Automobiles",
  "csio:27B":
    "ASEF27B - Legal Liability for Damage to Non-owned Automobile, OPCF27b - Business Operations - Liability for Damage to Non-owned Automobile(s) in Your Care, Custody or Control",
  "csio:28A":
    "OPCF28a - Excluded Driver, SEF28a - Reduction of Coverage as Respects to Operation on Airport Property, AB-SEF28(A) - Reduction of Coverage when Automobile Operated on Airport Property Endorsement",
  "csio:30A":
    "SEF30a - Excluding Attached Machinery, AB-SEF30(A) Attached Machinery, Apparatus or Equipment Exclusion Endorsement: Sections A.1 and C",
  "csio:32A": "EEF32a Snowmobile Underage Operator",
  "csio:32B": "ATV Underage Operator",
  "csio:32C": "Motorcycle Underage Operator",
  "csio:34A":
    "QEF34a - Accident Benefits (Change of Amounts or insured persons - when the insured is an Individual)",
  "csio:34A-B":
    "DEPRECATED QEF34a-b - Accident Benefits (Change of Amounts or insured persons)",
  "csio:34B":
    "QEF34b Accident Benefits (Change of Amounts or insured persons - when the insured is a  Legal Person, Partnership or Association",
  "csio:37A":
    "QEF37a - Modified Coverage on Electronic Equipment - Limitation of Amount",
  "csio:37B":
    "QEF37b - Modified Coverage on Electronic Equipment - Exclusion of Equipment",
  "csio:39B":
    "SEF39B - Designated Operator Traffic Safety Conviction Rating Waiver Endorsement, AB-SEF39(B) - Designated Operator Minor Traffic Safety Conviction Rating Waiver Endorsement",
  "csio:43A":
    "OPCF43a - Removing Depreciation Deduction for Specified Lessee(s), QEF43a - Change to Loss Payment – Partial Loss – New Parts",
  "csio:43B": "Change to Loss Payment - Partial Loss - Waiver of Depreciation",
  "csio:43C": "Change to Loss Payment - Total Loss - Agreed Amount",
  "csio:43D": "Change to Loss Payment - Total Loss - Waiver of Depreciation",
  "csio:43E": "Change to Loss Payment - Total Loss - Replacement Cost",
  "csio:43F": "Change to Loss Payment - Total Loss - Increased Settlement",
  "csio:43L": "EEF43l Limited Waiver of Depreciation",
  "csio:43O": "Replacement Cost (Owned)",
  "csio:43R":
    "NBEF43r - Limited Waiver of Depreciation, SEF43r - Limited Waiver of Depreciation, AB-SEF43R - Limited Waiver of Depreciation",
  "csio:43RL":
    "ASEFr(L) - Limited Waiver of Depreciation (Specified Lessee), OPCF43r(L) - Limited Waiver of Depreciation (Specified Lessee), SEF43r(L) - Limited Waiver of Depreciation (Specified Lessee), AB-SEF43R(L) Specified Lessee Limited Waiver of Depreciation Endorsement",
  "csio:43S": "Replacement Cost (Specified Lessee)",
  "csio:4-41":
    "QEF 4-41 Endorsement Modifying the Deductibles indicated in the Declarations",
  "csio:44R": "OPCF44r - Family Protection Coverage",
  "csio:4A":
    "NBEF4a - Permission to Carry Explosives, OPCF4a - Permission to Carry Explosives, QEF4a - Transportation of Explosives, SEF4a - Permission to Carry Explosives, EEF4a Permission to Carry Explosives, AB-SEF4(A) - Permission to Carry Explosives Endorsement",
  "csio:4B":
    "NBEF4B - Permission to Carry Radioactive Material, OPCF4b - Permission to Carry Radioactive Material, QEF4b - Transportation of Radioactive Material, SEF4b - Permission to Carry Radioactive Material, EEF4b Permission to Carry Radioactive Material, AB-SEF4(B) - Permission to Carry Radioactive Materials Endorsement",
  "csio:5A":
    "OPCF5a - Replaced by OPCF #5, QEF5a - Lease or Leasing - Amended Q.P.F. No. 1 When Owner and One Lessee Shown as Named Insureds",
  "csio:5B":
    "OPCF5b - Permission to Rent or Lease (Specified Lessee and Modified Declarations - Master Policy)",
  "csio:5C":
    "NBEF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), OPCF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), QEF5c - Rented Automobile (Unspecified Lessees - Short Term Only), SEF5c - Permission to Rent or Lease, AB-SEF5(C) - Permission to Rent Endorsement",
  "csio:5CS": "Permission To Rent Or Lease The Vehicle For Carsharing (5CS)",
  "csio:5D":
    "NBEF5d - Conversion Coverage (Rented or Leased Automobiles), OPCF5d - Conversion Coverage (Rented or Leased Automobiles), QEF5d - Conversion Coverage (Rented or Leased Automobiles), SEF5d - Conversion Coverage, AB-SEF5(D) - Conversion Coverage Endorsement",
  "csio:6A":
    "NBEF6a - Permission to Carry Passengers for Compensation, OPCF6a - Permission to Carry Paying Passengers, SEF6a - Permission to Carry Passengers for Compensation, EEF6a Permission to Carry Passengers, AB-SEF6(A) - Carry Passengers for Compensation or Hire Endorsement",
  "csio:6B":
    "NBEF6b - School Bus, OPCF6b - School Bus, SEF6b - School Bus - For use on SPF No. 11, AB-SEF6(B) - School Transportation Endorsement",
  "csio:6C":
    "OPCF6c - Public Passenger Vehicles, SEF6c - Public Passenger Vehicles, AB-SEF6(C) - Public Passenger Automobile Enhanced Coverage Endorsement",
  "csio:6D":
    "NBEF6d - Driver Training School, OPCF6d - Driver Training School, SEF6d - Driver Training School, AB-SEF6(D) - Driver Training Services Endorsement",
  "csio:6E": "OPCF6e - No longer in use, SEF6e - Employees Business Use",
  "csio:6F":
    "NBEF6f - Public Passenger Vehicles, OPCF6f - Public Passenger Vehicles - Combined Limits, SEF6f - Public Passenger Vehicles, AB-SEF6(F) - Public Passenger Automobile Endorsement",
  "csio:87":
    "O.E.F. 87 - Added Coverage to Offset Tort Deductibles (for Ontario Garage Automobile Policy O.A.P. 4)",
  "csio:8A":
    "ASEF8a - Property Damage Reimbursement for Operation by Named Person - (Section A Only), QEF8a - Deductible Civil Liability, SEF8a - Property Damage Reimbursement for Operation by Named Person, AB-SEF8(A) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
  "csio:98B":
    "SEF98B- Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement ",
  "csio:99":
    "SEF99- Excluding Long Term Leased Vehicle Endorsement, AB-SEF99- Leased Vehicle Exclusion Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6)",
  "csio:AB": "Accident Benefits",
  "csio:ABOD": "Accident Benefits Occasional Driver",
  "csio:ACB": "Attendant Care Benefits",
  "csio:ACBOD": "Attendant Care for Occasional Driver",
  "csio:ADB": "Accidental Death Benefits",
  "csio:AFMAC": "After-marketAccessories",
  "csio:AMPD": "Attached Machinery - Property Damage Reimbursement",
  "csio:AOBCL": "Animal or Bird Collision Deductible",
  "csio:AP": "All Perils",
  "csio:APOD": "All Perils Occasional Driver",
  "csio:AUDHC": "Deletion of Hail Coverage – without pre-existing damage",
  "csio:AUHDE": "Hail Deductible",
  "csio:CATCH": "CATIM, CHHMB",
  "csio:CATDC": "CATIM, DCB",
  "csio:CATIM": "Catastrophic Impairment",
  "csio:CATOD": "Catastrophic Impairment (Occasional Driver)",
  "csio:CCMVT":
    "Coverage under section C for contents of described Motorhome or Vacation Trailer. ",
  "csio:CDCE": "Cancellation/Deletion of Coverage Endorsement",
  "csio:CEDED": "Endorsement for change in deductible notice ",
  "csio:CELLP": "Cellular Telephone",
  "csio:CHD":
    "Caregiver, Housekeeping & Home Maintenance Benefits, and Dependant Care Benefits",
  "csio:CHHMB": "Caregiver, Housekeeping & Home Maintenance Benefits",
  "csio:CHHOD":
    "Caregiver, Housekeeping & Home Maintenance for Occasional Driver",
  "csio:CIMOD":
    "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care (Occasional Driver)",
  "csio:CIMRB":
    "Catastrophic Impairment - Medical, Rehabilitation and Attendant Care",
  "csio:CLADV": "Claims advantage",
  "csio:CLVEH": "Classic Vehicle",
  "csio:CMP": "Comprehensive",
  "csio:COL": "Collision",
  "csio:COLOD": "Collision Occasional Driver",
  "csio:COVEH": "Collector Vehicle",
  "csio:CPP": "Claims Protection Plan",
  "csio:CPPE": "Claims Protection Plan - Extended",
  "csio:CPPO": "Claims Protection Plan - Occasional Driver",
  "csio:CPPX": "Claims Protection Plan - Used",
  "csio:DCB": "Dependant Care Benefits",
  "csio:DCBOD": "Dependant Care for Occasional Driver",
  "csio:DECVL": "Declared Value",
  "csio:DFB": "Death and Funeral Benefits",
  "csio:DFBOD": "Death & Funeral for Occasional Driver",
  "csio:DFP": "Death and Funeral Income Replacement",
  "csio:DISAA": "Abstain from Alcohol",
  "csio:DISAB": "Discount - Autonomous Emergency Braking System",
  "csio:DISAD":
    "Discount - Anti Theft Device Automatically Activated (non alarms)",
  "csio:DISAL": "Discount - Alarm System",
  "csio:DISAM": "Discount - Association/Membership",
  "csio:DISAS": "Discount - Away - at - School",
  "csio:DISAT": "Discount - Antique Vehicle",
  "csio:DISAV": "Discount - Authorized Vendors",
  "csio:DISBM": "Broker Multi Line Discount",
  "csio:DISBP": "Basic Policy (No Frills)",
  "csio:DISBS": "Broker Self Service",
  "csio:DISCF": "Discount - Conviction Free",
  "csio:DISCL": "Discount - Religious Clerical",
  "csio:DISCM": "Discount - No. of Comprehensive Claims ",
  "csio:DISCO": "Discount - Conviction Free (Occasional Driver)",
  "csio:DISCP": "Discount - Combined Policy",
  "csio:DISCR": "Discount - Credit Consent Received",
  "csio:DISCX": "Risk Premium Cap",
  "csio:DISDA": "Discount - Driver Age",
  "csio:DISDC": "Risk Discount Cap",
  "csio:DISDM": "Discount - Anti Theft Device Manually Activated (non alarms)",
  "csio:DISDO": "Discount - Group Rate Applied for Occasional Driver",
  "csio:DISDR": "Discount - Insured's Driving Record",
  "csio:DISDS": "Discount - Driving Simulator",
  "csio:DISDT": "Discount - Driver Training",
  "csio:DISED": "Discount - Experienced Driver",
  "csio:DISEL": "Discount for Electric car",
  "csio:DISEM": "Discount - Employee",
  "csio:DISET": "Discount – Excess Trailer",
  "csio:DISFA": "Discount - Facility",
  "csio:DISFC": "Discount - Farm Comprehensive",
  "csio:DISFM": "Discount - Female",
  "csio:DISFO": "Discount - Claims Forgiveness",
  "csio:DISFR": "Discount - Farm",
  "csio:DISFY": "Discount- Family ",
  "csio:DISGD": "Discount - Group Rate Applied",
  "csio:DISGN":
    "Discount - Underage classes provided a parents’ private passenger automobile(s) is insured with the same company and at least one private passenger automobile carries mandatory coverages.",
  "csio:DISGO": "Discount - Graduated License for an Occasional Driver",
  "csio:DISGR": "Discount - Graduated License Holder",
  "csio:DISGS": "Discount - Good Student",
  "csio:DISHL": "Discount - Higher Limit Purchased",
  "csio:DISHY": "Discount - Hybrid",
  "csio:DISIP":
    "Discount - I Promise Program-Parent/Teen Mutual Safe Driving Contract",
  "csio:DISLC": "Discount - Low Commute Distance",
  "csio:DISLM": "Discount - Low Annual Mileage",
  "csio:DISMC": "Discount - Miscellaneous",
  "csio:DISMG": "Discount - Mortgage Free Home/Condominium ",
  "csio:DISMH": "Discount - Motor Home",
  "csio:DISMH ": "Motor Home",
  "csio:DISMI": "Discount - Mature Driver",
  "csio:DISMM": "Multi Motorcycle Discount",
  "csio:DISMO": "Motorcycle Improvement Course",
  "csio:DISMP": "Discount - Multiple Line ",
  "csio:DISMS": "Discount - Marital Status",
  "csio:DISMV": "Discount - Multi Vehicle",
  "csio:DISNA": "Discount - Non Authorized Vendors",
  "csio:DISNB": "Discount New Business",
  "csio:DISNC": "Discount - No Claims",
  "csio:DISND": "Discount - New Driver",
  "csio:DISNO": "Discount - No Claims (Occasional Driver)",
  "csio:DISNS": "Discount - Non-smoker",
  "csio:DISNV": "Discount - New Vehicle",
  "csio:DISOC": "Discount - Occupation",
  "csio:DISOD": "Discount - Occasional Driver",
  "csio:DISOF": "Discount - Facility (Occasional Driver)",
  "csio:DISOP": "Discount - Out of Province",
  "csio:DISOR":
    "Discount - Facility-Occasional Driver (Remedial Driver Improvement Course)",
  "csio:DISOW": "Discount - Owner of Vehicle",
  "csio:DISPP": "Discount - Private Parking",
  "csio:DISPR": "Discount - Professional",
  "csio:DISPW": "Discount - Partial workweek commute",
  "csio:DISRA": "Discount - Range of Operations",
  "csio:DISRC":
    "Discount - Recreational vehicle combined with a private passenger vehicle",
  "csio:DISRD": "Discount - Retiree",
  "csio:DISRM":
    "Discount - Facility - Regular Driver (Remedial Driver Improvement Course)",
  "csio:DISRN": "Discount - Renewal",
  "csio:DISRO": "Discount - Renewal (Occasional)",
  "csio:DISRV": "Discount – Multi Recreational Vehicle",
  "csio:DISSC": "Discount - Senior Citizen (Age Related)",
  "csio:DISSN": "Discount - Snow Tires",
  "csio:DISSR": "Select Rider Discount ",
  "csio:DISST": "Discount - Stability",
  "csio:DISSV":
    "Discount - Electronic Stability Control System (Factory Equipped)",
  "csio:DISTP": "Discount - Trail Permit",
  "csio:DISTR": "Discount - Territory",
  "csio:DISTX": "Total Discount Applied",
  "csio:DISVC": "Discount - Valued/Preferred Customer",
  "csio:DISVO": "Discount-Vehicle to Operator Ratio",
  "csio:DISYD": "Discount Young Driver",
  "csio:DRALX": "Driving Record Guarantee After Loss",
  "csio:DRBLO": "Driving Record Guarantee Before Loss",
  "csio:FBTEX": "Flatbed Towing Expense",
  "csio:HLDNT": "Restrict Hail Damage to Punctures",
  "csio:HSL": "Health Services Levy",
  "csio:IDB": "Indexation",
  "csio:IDBOD": "Indexation Benefit for Occasional Driver",
  "csio:LSMVT":
    "Loss of Use Motorhome and Vacation Trailer Emergency Accommodation Expense Endorsement",
  "csio:LWD": "Waiver of Deductible",
  "csio:MAC":
    "Caregiver, Housekeeping & Home Maintenance Benefits, Medical & Rehabilitation Benefits, and Attendant Care Benefits",
  "csio:MBCDE": "Manitoba only - Cancellation/Deletion of Coverage Endorsement",
  "csio:MBLRI ": "Manitoba only - Loss of Rental Income",
  "csio:MBVLP": "Manitoba only - Value Plus ",
  "csio:MBVLU": "Manitoba only - Vehicle Lay-up",
  "csio:MBVPL": "Manitoba only - Value Plus - Specified Lessee",
  "csio:MCP": "Minor Conviction Protection",
  "csio:MCPOD": "Minor Conviction Protection for Occasional Driver",
  "csio:MCPOX": "Minor Conviction Protection for Occasional Driver - Used",
  "csio:MCPX": "Minor Conviction Protection - Used",
  "csio:MED": "Medical Expenses",
  "csio:MEDRH": "Medical & Rehabilitation Benefits",
  "csio:MID":
    "Medical & Rehabilitation Benefits, and Attendant Care Benefits, and Dependant Care Benefits",
  "csio:MOACE": "Agreed Limit for Motorcycle Accessories and Equipment",
  "csio:MOVAE": "Motorcycle - Vacation Expense",
  "csio:MRB": "Medical, Rehabilitation & Attendant Care",
  "csio:MRBOD":
    "Medical, Rehabilitation and Attendant Care for Occasional Driver",
  "csio:MRHOD": "Medical & Rehabilitation for Occasional Driver",
  "csio:ODRAX": "Driving Record Guarantee Before After (Occasional Driver)",
  "csio:ODRBL": "Driving Record Guarantee Before Loss (Occasional Driver)",
  "csio:OFFRD": "Off Road Use",
  "csio:ONEDE": "One Deductible Endorsement",
  "csio:OVRAC": "Overnight Accommodation",
  "csio:PAK01": "20 (or 20A), 35, 43 (or 43A or 43R or 43RL)",
  "csio:PAK02": "27, 35, 43 (or 43A or 43R or 43RL) ",
  "csio:PAK03": "20 (or 20A), 43 (or 43A or 43R or 43RL)",
  "csio:PAK04": "20 (or 20A), 35 ",
  "csio:PAK05": "20 (or 20A), 27, 43 (or 43A or 43R or 43RL) ",
  "csio:PAK06": "20 (or 20A), 27 ",
  "csio:PAK07": "20 (or 20A), 27, 34 ",
  "csio:PAK08": "20 (or 20A), 27, 35, 43 (or 43A or 43R or 43RL)",
  "csio:PAK09": "20 (or 20A), 27, 35 ",
  "csio:PAK10": "2, 20 (or 20A), 43 (or 43A or 43R or 43RL)",
  "csio:PAK11": "2, 20A, 27 and 34 ",
  "csio:PAK12": "2, 20, 27 and 34 ",
  "csio:PAK13": "2, 20 (or 20A), 27 ",
  "csio:PAK14": "2, 20 (or 20A), 27 and 43 (or 43A or 43R or 43RL)",
  "csio:PAK15": "18, 20 (or 20A), 27, 35",
  "csio:PAK16": "SRAP, 20, 27, 43",
  "csio:PAK17": "	SRAP, 20, 27",
  "csio:PARF": "Premium Adjustment Reporting Form",
  "csio:PEPRE": "Personal Protective Equipment (excluding personal effects)",
  "csio:PERSE": "Personal Effects (excluding Personal Protective Equipment)",
  "csio:PRM": "License/Permit Charge",
  "csio:PS": "Death Benefits and Dismemberment",
  "csio:RESTO": "Restoration while in Storage",
  "csio:RHG": "Road Hazard Glass",
  "csio:RRP": "Renewal Rate Protection",
  "csio:SP": "Specified Perils",
  "csio:SPPTS": "Spare Parts",
  "csio:SRAP": "Enhanced Roadside Assistance Program",
  "csio:STCTG": "Short Term Cancellation Table",
  "csio:SURAT": "Combined Driver Age/Risk Type Surcharge",
  "csio:SURAV": "Surcharge - Antique Vehicle",
  "csio:SURBU": "Surcharge - Business Use",
  "csio:SURC2": "Surcharge – Conviction/Claim",
  "csio:SURCA": "Surcharge - Prior Policy Cancellation",
  "csio:SURCC": "Surcharge - Claims for Occasional Driver (class 06/05)",
  "csio:SURCD": "Surcharge - Convictions for Occasional Driver (class 06/05)",
  "csio:SURCE": "Surcharge - Commercial Exposure",
  "csio:SURCL": "Surcharge - Claims",
  "csio:SURCM": "Surcharge - No. of Comprehensive Claims",
  "csio:SURCN": "Surcharge - Convictions",
  "csio:SURCO":
    "Surcharge - Conviction/Claim for an Occasional Driver (class 06/05)",
  "csio:SURCP": "Surcharge - Car Pooling",
  "csio:SURCX": "Surcharge - Risk Premium Cap ",
  "csio:SUREO": "Surcharge - Experience Operator",
  "csio:SURER": "Surcharge - Explosive/Radioactive Material",
  "csio:SURFA": "Surcharge - Facility ",
  "csio:SURFC": "Surcharge - Filing Certificate",
  "csio:SURHK": "Surcharge - High Kilometres Driven",
  "csio:SURHP": "Surcharge - High Performance",
  "csio:SURHV": "Surcharge - High Value",
  "csio:SURIH": "Surcharge - Inconsistent Insurance History",
  "csio:SURLS": "Surcharge - License Suspension",
  "csio:SURLV": "Surcharge Leased Vehicle",
  "csio:SURMC": "Surcharge - Miscellaneous",
  "csio:SURML": "Surcharge - Mono-line Policy",
  "csio:SURMO": "Surcharge - Multiple Operators",
  "csio:SURNP": "Surcharge - Non Payment",
  "csio:SUROE": "Surcharge - Oversize Engine",
  "csio:SUROF": "Surcharge - Facility (Occasional Driver)",
  "csio:SUROP": "Surcharge - Out of Province",
  "csio:SURPN": "Surcharge - No Previous Insurance",
  "csio:SURRA": "Surcharge - Radius",
  "csio:SURRH": "Surcharge - Right Hand Drive",
  "csio:SURRV": "Surcharge - Recreational Vehicle ",
  "csio:SURSC": "Risk Surcharge Cap",
  "csio:SURSG": "Surcharge - Sand & Gravel Operations",
  "csio:SURTN ": "Surcharge - Tows Non-Owned Trailer",
  "csio:SURTE": "Surcharge - Telematics",
  "csio:SURUS": "Surcharge - U.S. & Non-Canadian Exposure",
  "csio:TNC": "Transportation Network",
  "csio:TP": "Third Party Liability / Civil Liability",
  "csio:TPBI": "Third Party Liability – Bodily Injury",
  "csio:TPDC": "Third Party Liability – Direct Compensation Property Damage",
  "csio:TPOBI": "Third Party Liability – Bodily Injury (Occasional Driver)",
  "csio:TPOD": "Third Party Liability / Civil Liability (Occasional Driver)",
  "csio:TPODC":
    "Third Party Liability – Direct Compensation Property Damage (Occasional Driver)",
  "csio:TPOPD": "Third Party Liability – Property Damage (Occasional Driver)",
  "csio:TPPD": "Third Party Liability – Property Damage",
  "csio:TPPHZ": "Third Party Liability - Passenger Hazard",
  "csio:UA": "Uninsured Automobile Coverage",
  "csio:UAOD": "Uninsured Automobile Coverage (Occasional Driver)",
  "csio:UNDMP": "Underinsured Motorist Protection",
  "csio:USBIE": "Usage Based Insurance",
  "csio:WAPRE": "Waived Nominal Premium ",
  "csio:WCB": `Caregiver & Dependant Care Benefits "No Longer used as of Sep 1/2010"`,
  "csio:WI": "Total Disability Max. Weekly Benefit",
  "csio:WIB": "Income Replacement Benefits",
  "csio:WIBOD": "Income Replacement for Occasional Driver",
  "csio:WLIFE": "DEPRECATED Worldwide Travel Life Insurance",
  "csio:SURHT": "High Theft Risk",
  "csio:OEMA": "OEM Replacement Parts",
  "csio:8B":
    "AB-SEF8(B) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)",
  "csio:19B": "AB-SEF19(B) - Limitation of Amount Endorsement",
  "csio:28C": "AB-SEF28(C) - Named Person(s) Reduction of Coverage Endorsement",
  "csio:30B":
    "AB-SEF30(B) - Attached Machinery, Apparatus or Equipment Exclusion Endorsement Section A- Third Party Liability and Section B- Accident Benefits",
  "csio:39A": "AB-SEF39(A) - At-Fault Accident Waiver Endorsement ",
  "csio:49A": "AB-SEF49(A) DCPD Deletion Endorsement",
  "csio:49B": "AB-SEF49(B) Blanket Basis DCPD Deletion Endorsement",
};

export const HABITATIONAL_CSIO_COVERAGE: { [key: string]: string } = {
  "csio:DWELL": `Dwelling Buildings`,
  "csio:OS": `Detached Private Structures`,
  "csio:PPTC": `Personal Property`,
  "csio:ALVE": `Additional Living Expenses`,
  "csio:PL": `Legal Liability`,
  "csio:MEDPM": `Voluntary Medical Payments`,
  "csio:VLPD": `Voluntary Property Damage`,
  "csio:SEWER": `Sewer Backup`,
  "csio:HSL": `Single Limit`,
  "csio:GUARR": `Guaranteed Replacement Cost-Building`,
  "csio:GRCE": `Replacement Cost - Contents/Extended`,
  "csio:ARIB": `Unit Owners Building Improvements and Betterments`,
  // "csio:NPIB": `Unit Owners Building Improvements and Betterments`,
  "csio:ARLA": `Loss Assessments`,
  // "csio:NPLA": `Loss Assessments`,
  "csio:CCLA": `Condominium Contingent Legal Liability`,
  "csio:ARAP": `Unit Owners Additional Protection`,
  "csio:IDTFT": `Identity Theft`,
  "csio:ERQK": `Earthquake`,
  "csio:ERQKF": `Post-Earthquake Damage`,
};

export const HABITATIONAL_CSIO_OTHER_COVERAGE: { [key: string]: string } = {
  "csio:ACV": "Actual Cash Value Endorsement",
  "csio:ACLHV": "Additional Coverages and Limits for High Value Homes",
  "csio:ADD": "Accidental Death and Dismemberment",
  "csio:ADDRC": "Additional Rebuilding Cost ",
  "csio:ALARM": "Alarm Warranty",
  "csio:ALVE":
    "Additional Living Expense (Homeowners, Tenant and Condominium Form)",
  "csio:APPAW": "Additional Amount of Personal Property Away From Premises",
  "csio:ARAP": "All Risk - Unit Owners Additional Protection",
  "csio:ARIB":
    "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
  "csio:ARLA": "All Risk - Loss Assessment Coverage",
  "csio:ARPP": "All Risk - Personal Property ",
  "csio:ARSP": "All Risk - Solar Panel",
  "csio:ATRAC": "Antique Tractor Endorsement",
  "csio:BSEIN": "Base Internal Limits",
  "csio:BLDG": "Building - Other than Residence for Homeowner",
  "csio:BLDRS": "Builder's Risk Insurance",
  "csio:BUNOV": "Building under Renovation Endorsement",
  "csio:BURG": "Residence Burglary",
  "csio:BURN": "Burn Exclusion",
  "csio:BUSPR": "Business Property: On Property",
  "csio:BUSPO": "Business Property: Off Property",
  "csio:BYLAW": "Bylaws Endorsement",
  "csio:CANNA": "Cannabis Endorsement",
  "csio:CARE": "Care Facility Contents Extension",
  "csio:CBLLA": "Condominium Bare Land Loss Assessment (Property Section)",
  "csio:CCARD": "Credit Card Forgery and Counterfeit Money Coverage",
  "csio:CCBUS": "Business Increased Limits",
  "csio:CCCOL": "Collectibles and Treasures Increased Limits",
  "csio:CCFAM": "Family and Security Increased Limits",
  "csio:CCHRC": "Home and Recreation Increased Limits",
  "csio:CCPBK": "Professional Books Increased Limits",
  "csio:CDEDA": "Condominium Deductible Assessment - Earthquake",
  "csio:CECCE": "Common Elements Condominium Corporation Extension Endorsement",
  "csio:CEDED": "Endorsement for change in deductible notice",
  "csio:CFEXC": "Chip and Fracture Exclusion",
  "csio:CLADV": "Claims advantage",
  "csio:CLFRE": "Claim Free Protection",
  "csio:COLLP": "Collapse",
  "csio:COMST": "Commercial Stock",
  "csio:CONSE": "Building under construction liability",
  "csio:CONTM": "Contamination",
  "csio:COPLL": "Comprehensive Personal Liability Limitation",
  "csio:CPL":
    "Legal Liability - Other than Homeowners, Tenant and Condominium Form",
  "csio:CPLCO":
    "Comprehensive Personal Liability - Product - Completed Operations Exclusions",
  "csio:CYBER": "Personal Cyber Coverage",
  "csio:DAYC": "Day Care",
  "csio:DEBRI": "Debris Removal",
  "csio:DEDAB": "Deductible Assessment Buydown",
  "csio:DEDED": "Decreasing Deductible Endorsement ",
  "csio:DEFER": "Deferred Loss Settlement Clause",
  "csio:DEMO": "Demolition Increased Cost of Construction",
  "csio:DEPEN": "Dependents Coverage Endorsement",
  "csio:DEWHA": "Wind/Hail Deductible",
  "csio:DISAG": "Automatic Generator",
  "csio:DISAL": "Premises Alarm (Fire or Intrusion) System",
  "csio:DISAM": "Discount - Association/Membership",
  "csio:DISBM": "Broker Multi Line",
  "csio:DISBS": "Broker Self Service",
  "csio:DISBU": "Business Use",
  "csio:DISCA": "Combined Age-Mortgage-Free",
  "csio:DISCP": "Discount - Combined Policy",
  "csio:DISCR": "Discount - Credit Consent Received",
  "csio:DISCX": "Risk Premium Cap",
  "csio:DISDC": "Risk Discount Cap",
  "csio:DISDD": "Discount - Deductible",
  "csio:DISDE": "Discount - Diesel Engine",
  "csio:EXDIS": "Diseases Exclusion Endorsement",
  "csio:DISEM": "Employee",
  "csio:DISEV": "Evaluator Based",
  "csio:DISFM": "Female",
  "csio:DISFO": "Claims Forgiveness",
  "csio:DISGD": "Group Rate Applied",
  "csio:DISGH": "Green Home Discount",
  "csio:DISGP": "Good Payer Discount",
  "csio:DISEQ": "New Early Quote Discount",
  "csio:DISHE": "Heating",
  "csio:DISHF": "Discount - Higher Floor Unit",
  "csio:DISHR": "Hail Resistant",
  "csio:DISHS": "Home Security",
  "csio:DISHW": "New Hot Water Tank",
  "csio:DISLT": "Long Term Occupancy",
  "csio:DISMA": "Mature Homeowner - In Premises 'n' Years",
  "csio:DISMC": "Miscellaneous",
  "csio:DISMF": "Multiple Family",
  "csio:DISMG": "Mortgage Free",
  "csio:DISMI": "Mature Citizen",
  "csio:DISMP": "Multiple Line",
  "csio:DISMR": "Multiple Property Risks",
  "csio:DISMU": "Discount - Multiple Units",
  "csio:DISMX": "Maximum Security",
  "csio:DISNB": "Discount New Business",
  "csio:DISNC": "No - Claims",
  "csio:DISNE": "New Homeowner",
  "csio:DISNH": "New Home",
  "csio:DISNP": "New Policy",
  "csio:DISNS": "Non-smoker",
  "csio:DISNW": "Neighbourhood Watch/Crime Prevention Programme",
  "csio:DISOC": "Occupation",
  "csio:DISOO": "Discount - Owner Occupied",
  "csio:DISOP": "Cooperative (Co-op)",
  "csio:DISPC": "Preferred Construction",
  "csio:DISPD": "Discount - Protection Device",
  "csio:DISPR": "Professional",
  "csio:DISQH": "Quality Home",
  "csio:DISRD": "Retiree",
  "csio:DISRN": "Renewal",
  "csio:DISSB": "Discount - Self Bailing",
  "csio:DISSC": "Senior Citizen (Age Related)",
  "csio:DISSD": "Smoke Detector",
  "csio:DISSE": "Discount - Safety Equipment",
  "csio:DISSK": "Sprinklered",
  "csio:DISSS": "Septic System",
  "csio:DISST": "Discount - Stability",
  "csio:DISSW": "Sewer Back-up Prevention Discount",
  "csio:DISTD": "Mobile Home Tied Down",
  "csio:DISTR": "Territory Discount",
  "csio:DISTS": "Tanker Shuttle Service (Fire Fighting Service)",
  "csio:DISTW": "Tankless Water Heater",
  "csio:DISTX": "Total Discount Applied",
  "csio:DISUB": "Discount - Unfinished basement",
  "csio:DISVC": "Valued/Preferred Customer",
  "csio:DISVS": "24-hour Video Surveillance",
  "csio:DISWA": "Watercraft (Maximum Conditions)",
  "csio:DISWC": "Discount - Watercraft Operator Certificate",
  "csio:DISWF": "Discount - Water Flow Device",
  "csio:DISWS": "Water Sensor",
  "csio:DISYB": "Year Built (Other than New Home)",
  "csio:DISYE": "Years of Experience",
  "csio:DOFXC": "Domestic Fuel Exclusion",
  "csio:DPSEX": "Detached Private Structures Exclusion Endorsement",
  "csio:DPSLE": "Detached Private Structures Limitation Endorsement",
  "csio:DRGEX": "Damage Due to Illicit Narcotics Exclusion",
  "csio:DWELL": "Dwelling - Residence for Homeowner",
  "csio:EARFP": "Earned Flat Premium",
  "csio:EMERG":
    "Declaration of Emergency Endorsement – Extension of Termination or Expiry Date",
  "csio:ENVLS": "Environmentally Friendly Home Replacement Loss Settlement",
  "csio:ENHIN": "Enhanced Internal Limits",
  "csio:ERQK": "Earthquake",
  "csio:ERQKF": "Post-earthquake Damage",
  "csio:ESOPU": "Estate Of - Permission for Unoccupancy",
  "csio:EVAC": "Mass Evacuation Endorsement",
  "csio:EXTCD": "Extended Coverage Deletion",
  "csio:EXTEX": "Extra Expense",
  "csio:EXWAT":
    "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
  "csio:FCPL": "Farmers Comprehensive Personal Liability",
  "csio:FDC": "Fire Department Service Charge",
  "csio:FLDEX": "Flood Exclusion",
  "csio:FLOOD": "Flood Water - Damage caused by flood water.",
  "csio:FRCLS": "Functional Replacement Cost Loss Settlement",
  "csio:FREEZ": "Home Freezer (Food Spoilage)",
  "csio:FRMHB": "Hobby Farm",
  "csio:FRTPR": "Furnished Rented Property",
  "csio:FURMC": "Limited Fungi or Other Microbes Remediation Coverage",
  "csio:GLABR": "Glass Breakage",
  "csio:GLDED": "Glass Deductible Endorsement",
  "csio:GOLFP": "Golf Package Endorsement",
  "csio:GRCB":
    "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
  "csio:GRCE":
    "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
  "csio:GRCEX": "Guarantee Replacement Cost Excluded",
  "csio:GRCSL": "Guaranteed Building Replacement Cost with Single Limit",
  "csio:GRNDW":
    "Ground Water - Damage caused by infiltration of water through a foundation.",
  "csio:GUARR": "Guaranteed Replacement Cost - Building",
  "csio:HAEXT": "Home Accessibility Extension",
  "csio:HAILC": "Hail Coverage Endorsement",
  "csio:HBOFF": "Home Business Off-Premise Coverage",
  "csio:HBON": "Home Business On-Premise Coverage",
  "csio:HCAC": "Home Care Assistance Coverage",
  "csio:HLDNT": "Restrict Hail Damage to Punctures",
  "csio:HMTQ": "Her Majesty the Queen",
  "csio:HOBUS": "Home Business Coverage",
  "csio:HOEQP": "Home Equipment Protection",
  "csio:HSL": "Single Limit",
  "csio:HUDED": "Hurricane Deductible  ",
  "csio:STHE": "Stronger Home Endorsement",
  "csio:IDTFT": "Identity Theft",
  "csio:IDTLI": "Identity Theft - Loss of Income",
  "csio:INFGD": "Inflation Guard Endorsement",
  "csio:LCCEE": "Leasehold Condominium Corporation Extension Endorsement",
  "csio:LEAKF": "Leakage or Overflow of Domestic Fuel Oil",
  "csio:LEFEE": "Legal Expense Insurance",
  "csio:LEGAL": "Legal Assistance",
  "csio:LGUAP": "Limited Guaranteed Replacement Cost Percentage - Building ",
  "csio:LIMET": "Limited Exterior",
  "csio:LIMSD": "Limited to Structural Damage ",
  "csio:LIMWD": "Limited Hidden Water Damage",
  "csio:LLEA": "Life Lease Extension",
  "csio:LLDD": "Life Lease Deductible",
  "csio:LLDE": "Life Lease Deductible - Earthquakes",
  "csio:LLOI": "Life Lease Unit Owner Improvement",
  "csio:LLPC": "Life Lease Property Coverage",
  "csio:LLUC": "Life Lease Unit Coverage",
  "csio:LOGSL": "Log Construction Settlement Limitation",
  "csio:LSADV": "Lifestyle advantage",
  "csio:LSBNP": "Livestock – Broad Named Perils",
  "csio:LSLNP": "Livestock – Limited Named Perils",
  "csio:LVWAR": "Locked Vehicle Warranty",
  "csio:MARFC": "Matching of Undamaged Roof Surfacing Additional Coverage ",
  "csio:MASDC": "Matching of Undamaged Siding - Additional Coverage  ",
  "csio:MBCP": "Modified Basis of Claims Payment",
  "csio:MEDPM": "Voluntary Medical Payments",
  "csio:MLDEX": "Mold Exclusion",
  "csio:MODLS": "Modified Loss Settlement",
  "csio:NGRCE": "Single limit AND Guarantee Replacement Cost Excluded",
  "csio:NOBEQ": "Non-Owned Business Equipment ",
  "csio:NOR": "No Obligation to Rebuild",
  "csio:NPAP": "Named Perils - Unit Owners Additional Protection",
  "csio:NPIB":
    "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
  "csio:NPLA": "Named Perils - Loss Assessment Coverage",
  "csio:NPSP": "Named Perils - Solar Panel",
  "csio:OPM": "Out of Province Medical",
  "csio:OS": "Other Structures - Homeowners Forms",
  "csio:OSAWP": "Specific Structures Away from the Residence Premises ",
  "csio:OSROR": "Other Structures Rented to Others (Residence Premises)",
  "csio:OTHMH": "Other Members of Your Household",
  "csio:OTSRP": "Other (Appurtenant) Structures (Residence Premises)",
  "csio:OTSTR": "Unit Owners Other Structures",
  "csio:OVWAT": "Overland Water (Flood & Surface Water) Coverage",
  "csio:OVWEX": "Overland Water Exclusion",
  "csio:PAKRC": "Replacement Cost Package",
  "csio:PBIPD":
    "Comprehensive Personal Liability - Bodily Injury and Property Damage (Coverage A)",
  "csio:PERDR": "Personal Records and Data Replacement",
  "csio:PEREF": "Personal Effects Floater - Domiciled Endorsement",
  "csio:PERLI": "Personal Liability (Umbrella Endorsement)",
  "csio:PERUS": "Personal Liability (Umbrella Standalone)",
  "csio:PL": "Legal Liability - Homeowners, Tenant and Condominium",
  "csio:PLASS": "Property Loss Assessment",
  "csio:PLIJ":
    "Comprehensive Personal Liability - Personal Injury (Coverage B)",
  "csio:PLMP":
    "Comprehensive Personal Liability - Medical Payments (Coverage C)",
  "csio:PP": "Personal Property - Homeowners Form",
  "csio:PPOTH":
    "Personal Property - Other than Homeowners, Tenant and Condominium Form",
  "csio:PPTC": "Personal Property - Tenant and Condominium Unit Owners Form",
  "csio:PROPO": "Personal Property - Pollution",
  "csio:PROS": "Property in Storage",
  "csio:PROST": "Property of Students Coverage",
  "csio:PTLL":
    "Comprehensive Personal Liability- Tenants Legal Liability (Coverage D) ",
  "csio:PWFL": "Power Fluctuation",
  "csio:RCBLD": "Replacement Cost - Building",
  "csio:REBCL": "Rebuilding Clause",
  "csio:REBUC": "Specialty Rebuilding Cost Endorsement ",
  "csio:REDIN": "Reduced Internal Limits",
  "csio:RENT": "Rental Income",
  "csio:RNTDT": "Short-Term Rental Endorsement",
  "csio:ROOF": "Roof (Limited to Cash Value)",
  "csio:ROOFA": "Roof (Actual Cash Value)",
  "csio:ROOFC": "Roof Exclusion - All",
  "csio:ROOFE": "Roof Exclusion",
  "csio:ROOFL": "Limited Roof Surface",
  "csio:ROOFR": "Roof (Replacement Cost Value)",
  "csio:ROOFS": "Roof Surface - Basis of Settlement",
  "csio:ROOFW": "Roof (Basis of Settlement) due to Windstorm/Hail",
  "csio:RUOE": "Rented Unit Owners Endorsement",
  "csio:RWDAM": "Restricted Water Damage",
  "csio:RWEI": "Renewable Energy Income",
  "csio:SBAC": "Actual Cash Value for Sewer Back Up",
  "csio:SCAPT": "Self-contained Apartment",
  "csio:SELCL": "Enhanced coverage option for selected clients",
  "csio:SEWAT": "Water and Sewer Lines Coverage",
  "csio:SEWER": "Sewer Back-up Coverage",
  "csio:SEWLI":
    "Exterior Sewer Line - Breakage of the exterior sewer water line.",
  "csio:SEWLM": "Sewer Back-up Limitation",
  "csio:SFHW": "Solid Fuel Heat Warranty",
  "csio:SLEXT": "Service Line Extension Endorsement",
  "csio:SLPLI": "Solar Panel- Loss of Income",
  "csio:SMART": "SmartHome Systems",
  "csio:SNOBL": "Snow Blower",
  "csio:SNOW": "Weight of Snow and Ice",
  "csio:SPA": "Spa Coverage",
  "csio:SPBYL": "Sprinkler Bylaw",
  "csio:SPDAM": "Damage to Outdoor Swimming Pool & Attached Equipment",
  "csio:SPLS": "Special Loss Settlement",
  "csio:SPTEQ": "Sporting Equipment",
  "csio:SRFCW": "Surface Water – Damage caused by surface water.",
  "csio:STALE": "Stacking of Limit Endorsement",
  "csio:SURAB": "Absentee Landlord (Rented Dwelling)",
  "csio:SURAI": "Occupancy",
  "csio:SURAS": "Age of Structure",
  "csio:SURAU": "Secondary/Auxiliary Heating",
  "csio:SURBU": "Business Use",
  "csio:SURCA": "Prior Policy Cancellation",
  "csio:SURCE": "Commercial Exposure",
  "csio:SURCI": "Coverage Ineligible",
  "csio:SURCL": "Claims",
  "csio:SURCR": "Surcharge - Criminal Record",
  "csio:SURCX": "Surcharge - Risk Premium Cap ",
  "csio:SUREC": "Electrical surcharges",
  "csio:SUREH": "Commercial Exposure High",
  "csio:SUREL": "Commercial Exposure Low",
  "csio:SUREM": "Commercial Exposure Medium",
  "csio:SURHD": "Hail Damage Susceptibility",
  "csio:SURHE": "Primary Heating",
  "csio:SURHV": "Surcharge - High Value",
  "csio:SURIH": "Inconsistent Insurance History",
  "csio:SURLB": "Surcharge – Discretionary Liability",
  "csio:SURLC": "Location (For loss history or geographic)",
  "csio:SURLG": "Log Home",
  "csio:SURMC": "Miscellaneous",
  "csio:SURMF": "Multiple Family",
  "csio:SURMH": "Surcharge - Mobile Home",
  "csio:SURML": "Mono-line Policy",
  "csio:SURMU": "Surcharge - Multiple Units",
  "csio:SURNP": "Non-payment",
  "csio:SUROH": "Age/Condition of Home",
  "csio:SURPB": "Plumbing surcharges",
  "csio:SURPR": "No Previous Insurance",
  "csio:SURPW": "Surcharge - Personal Watercraft",
  "csio:SURRF": "Roof surcharges",
  "csio:SURRL": "Surcharge - Below Grade",
  "csio:SURSC": "Risk Surcharge Cap",
  "csio:SURSD": "Surcharge - Standalone Dwelling",
  "csio:SURSO": "Surcharge - Seasonal Occupancy",
  "csio:SURSR": "Surcharge - Short-Term Rental",
  "csio:SURUC": "Surcharge - Under Construction",
  "csio:SURWA": "Water Claim Surcharge",
  "csio:TEC": "Trust Endorsement",
  "csio:THFBM": "Theft of Building Materials Under Construction",
  "csio:THFBU": "Theft and Burglary",
  "csio:THFTX": "Theft Exclusion",
  "csio:TIRES": "Tires",
  "csio:TRAVL": "Emergency Medical Travel Insurance",
  "csio:TRC": "Tree Removal Coverage   ",
  "csio:TRPLS": "Trees, Plants & Shrubbery",
  "csio:UNLIC": "Unlicensed Snow Vehicle, Trail Bike or ATV",
  "csio:UTRAC": "Utility Tractor Endorsement",
  "csio:VACPR": "Vacancy Permit",
  "csio:VALVE": "Backwater Valve Maintenance Clause",
  "csio:VANTH": "Vandalism / Theft by Tenants and Guest",
  "csio:VLPD": "Voluntary Property Damage",
  "csio:VMBC": "Malicious Damage - Building & Contents",
  "csio:VMC": "Malicious Damage on Contents",
  "csio:VMDB": "Malicious Damage on Building",
  "csio:VNDSD": "Vandalism on Seasonal Dwelling",
  "csio:WAPRE": "Waived Premium",
  "csio:WATER": "Water Damage Endorsement",
  "csio:WBED": "Waterbed",
  "csio:WDDED":
    "Water Damage Deductible - Specified Deductible for loss caused by Water. ",
  "csio:WDE": "Water Damage Exclusion",
  "csio:WHEXC": "Wind & Hail Exclusion",
  "csio:WIND": "Windstorm/Hail",
  "csio:WINDC": "Wind Coverage Endorsement",
  "csio:WLIFE": "Damage Caused by Wild Life",
  "csio:WNSPT": "Wine & Spirits Endorsement",
  "csio:WRECK": "Wreckage Value",
  "csio:WSLIM": "Wood Stove Limitation",
  "csio:WSWAR": "Wood Stove Warranty",
  "csio:XTFT": "Extended Theft Away from Premises",
  "csio:BUSPP": "Property Pertaining to a Business (Not premises specific)",
  "csio:WINDX": "Damage Caused by Hail or Wind Excluding the Roof",
  "csio:ACCLC": "Accident Insurance: Limit per claim",
  "csio:LEADB": "Liability Buy Back Coverage",
  "csio:THEFT": "Theft",
  "csio:PLAC": "Property Loss Assessment/Contingency",
  "csio:CIEE": "Cyber Incident Exclusion Endorsement",
  "csio:SURHT": "High Theft Risk",
  "csio:UROUP": "Undivided Right of Ownership - Unequal Percentages",
  "csio:UROEP": "Undivided Right of Ownership - Equal Percentages",
  "csio:HSB": "Home Settlement Benefit",
  "csio:BNGRP": "Benefits for Group Members",
  "csio:WRDED": "Deductible - Water Infiltrations Through The Roof",
  "csio:WDAGW": "Water Damage - Above Ground Water ",
  "csio:WHDED": "Deductible - Water Damage By The Water Heater",
  "csio:PET": "Pet Emergency Endorsement",
  "csio:ADE": "Accidental Death",
  "csio:WRAP": " Wrapping Coverage",
};

export const HABITATIONAL_CSIO_DISCOUNT_COVERAGE: { [key: string]: string } = {
  "csio:DISAG": "Automatic Generator",
  "csio:DISAL": "Premises Alarm (Fire or Intrusion) System",
  "csio:DISAM": "Discount - Association/Membership",
  "csio:DISBM": "Broker Multi Line",
  "csio:DISBS": "Broker Self Service",
  "csio:DISBU": "Business Use",
  "csio:DISCA": "Combined Age-Mortgage-Free",
  "csio:DISCP": "Discount - Combined Policy",
  "csio:DISCR": "Discount - Credit Consent Received",
  "csio:DISCX": "Risk Premium Cap",
  "csio:DISDC": "Risk Discount Cap",
  "csio:DISDD": "Discount - Deductible",
  "csio:DISDE": "Discount - Diesel Engine",
  "csio:DISEM": "Employee",
  "csio:DISEV": "Evaluator Based",
  "csio:DISFM": "Female",
  "csio:DISFO": "Claims Forgiveness",
  "csio:DISGD": "Group Rate Applied",
  "csio:DISGH": "Green Home Discount",
  "csio:DISGP": "Good Payer Discount",
  "csio:DISEQ": "New Early Quote Discount",
  "csio:DISHE": "Heating",
  "csio:DISHF": "Discount - Higher Floor Unit",
  "csio:DISHR": "Hail Resistant",
  "csio:DISHS": "Home Security",
  "csio:DISHW": "New Hot Water Tank",
  "csio:DISLT": "Long Term Occupancy",
  "csio:DISMA": "Mature Homeowner - In Premises 'n' Years",
  "csio:DISMC": "Miscellaneous",
  "csio:DISMF": "Multiple Family",
  "csio:DISMG": "Mortgage Free",
  "csio:DISMI": "Mature Citizen",
  "csio:DISMP": "Multiple Line",
  "csio:DISMR": "Multiple Property Risks",
  "csio:DISMU": "Discount - Multiple Units",
  "csio:DISMX": "Maximum Security",
  "csio:DISNB": "Discount New Business",
  "csio:DISNC": "No - Claims",
  "csio:DISNE": "New Homeowner",
  "csio:DISNH": "New Home",
  "csio:DISNP": "New Policy",
  "csio:DISNS": "Non-smoker",
  "csio:DISNW": "Neighbourhood Watch/Crime Prevention Programme",
  "csio:DISOC": "Occupation",
  "csio:DISOO": "Discount - Owner Occupied",
  "csio:DISOP": "Cooperative (Co-op)",
  "csio:DISPC": "Preferred Construction",
  "csio:DISPD": "Discount - Protection Device",
  "csio:DISPR": "Professional",
  "csio:DISQH": "Quality Home",
  "csio:DISRD": "Retiree",
  "csio:DISRN": "Renewal",
  "csio:DISSB": "Discount - Self Bailing",
  "csio:DISSC": "Senior Citizen (Age Related)",
  "csio:DISSD": "Smoke Detector",
  "csio:DISSE": "Discount - Safety Equipment",
  "csio:DISSK": "Sprinklered",
  "csio:DISSS": "Septic System",
  "csio:DISST": "Discount - Stability",
  "csio:DISSW": "Sewer Back-up Prevention Discount",
  "csio:DISTD": "Mobile Home Tied Down",
  "csio:DISTR": "Territory Discount",
  "csio:DISTS": "Tanker Shuttle Service (Fire Fighting Service)",
  "csio:DISTW": "Tankless Water Heater",
  "csio:DISTX": "Total Discount Applied",
  "csio:DISUB": "Discount - Unfinished basement",
  "csio:DISVC": "Valued/Preferred Customer",
  "csio:DISVS": "24-hour Video Surveillance",
  "csio:DISWA": "Watercraft (Maximum Conditions)",
  "csio:DISWC": "Discount - Watercraft Operator Certificate",
  "csio:DISWF": "Discount - Water Flow Device",
  "csio:DISWS": "Water Sensor",
  "csio:DISYB": "Year Built (Other than New Home)",
  "csio:DISYE": "Years of Experience",
  "csio:SURAB": "Absentee Landlord (Rented Dwelling)",
  "csio:SURAI": "Occupancy",
  "csio:SURAS": "Age of Structure",
  "csio:SURAU": "Secondary/Auxiliary Heating",
  "csio:SURBU": "Business Use",
  "csio:SURCA": "Prior Policy Cancellation",
  "csio:SURCE": "Commercial Exposure",
  "csio:SURCI": "Coverage Ineligible",
  "csio:SURCL": "Claims",
  "csio:SURCR": "Surcharge - Criminal Record",
  "csio:SURCX": "Surcharge - Risk Premium Cap ",
  "csio:SUREC": "Electrical surcharges",
  "csio:SUREH": "Commercial Exposure High",
  "csio:SUREL": "Commercial Exposure Low",
  "csio:SUREM": "Commercial Exposure Medium",
  "csio:SURHD": "Hail Damage Susceptibility",
  "csio:SURHE": "Primary Heating",
  "csio:SURHV": "Surcharge - High Value",
  "csio:SURIH": "Inconsistent Insurance History",
  "csio:SURLB": "Surcharge – Discretionary Liability",
  "csio:SURLC": "Location (For loss history or geographic)",
  "csio:SURLG": "Log Home",
  "csio:SURMC": "Miscellaneous",
  "csio:SURMF": "Multiple Family",
  "csio:SURMH": "Surcharge - Mobile Home",
  "csio:SURML": "Mono-line Policy",
  "csio:SURMU": "Surcharge - Multiple Units",
  "csio:SURNP": "Non-payment",
  "csio:SUROH": "Age/Condition of Home",
  "csio:SURPB": "Plumbing surcharges",
  "csio:SURPR": "No Previous Insurance",
  "csio:SURPW": "Surcharge - Personal Watercraft",
  "csio:SURRF": "Roof surcharges",
  "csio:SURRL": "Surcharge - Below Grade",
  "csio:SURSC": "Risk Surcharge Cap",
  "csio:SURSD": "Surcharge - Standalone Dwelling",
  "csio:SURSO": "Surcharge - Seasonal Occupancy",
  "csio:SURSR": "Surcharge - Short-Term Rental",
  "csio:SURUC": "Surcharge - Under Construction",
  "csio:SURWA": "Water Claim Surcharge",
  "csio:SURHT": "High Theft Risk",
};

export const HABITATIONAL_CSIO_PACKAGE_OTHER: { [key: string]: string } = {
  "csio:ABWA": "Attack By Wild Animal",
  "csio:ACCDNT": "Accident",
  "csio:ACV": "Actual Cash Value Endorsement",
  "csio:ACLHV": "Additional Coverages and Limits for High Value Homes",
  "csio:ADD": "Accidental Death and Dismemberment",
  "csio:AdditionalExp": "Additional Exposures",
  "csio:ADDNI": "Additional Named Insured",
  "csio:ADDRC": "Additional Rebuilding Cost ",
  "csio:ADDRR": "Additional Residences/Properties/Acreage ",
  "csio:ADRNT": "Additional Locations Rented",
  "csio:ALARM": "Alarm Warranty",
  "csio:ALLTB": "All Terrain Vehicles - Basic",
  "csio:AllTerrainVehicles": "All Terrain Vehicles",
  "csio:ALLTP": "All Terrain Vehicles - Passenger Hazard",
  "csio:ALLTU": "All Terrain Vehicles - Underage Operator ",
  "csio:ALVE":
    "Additional Living Expense (Homeowners, Tenant and Condominium Form)",
  "csio:AMEAR": "Additional Miscellaneous Equipment - All Risks",
  "csio:AMENP": "Additional Miscellaneous Equipment - Named Perils",
  "csio:AMPHL": "Amphibious Vehicle Liability ",
  "csio:ANEXC": "Animal Exclusion",
  "csio:ANIM": "Animal Collision",
  "csio:ANLE": "Animal Liability Extension",
  "csio:ANMLBIRTH": "Animal Birth",
  "csio:Apiary": "Apiary",
  "csio:APPAW": "Additional Amount of Personal Property Away From Premises",
  "csio:APPTH": "Additional Units",
  "csio:ARAP": "All Risk - Unit Owners Additional Protection",
  "csio:ARIB":
    "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
  "csio:ARLA": "All Risk - Loss Assessment Coverage",
  "csio:ARPAE": "All Risk Personal Articles Endorsement",
  "csio:ARPP": "All Risk - Personal Property ",
  "csio:ARPR": "All Risk - Produce ",
  "csio:ARSP": "All Risk - Solar Panel",
  "csio:ATRAC": "Antique Tractor Endorsement",
  "csio:ATRLI": "Antique Tractor Liability Extension",
  "csio:ATVAR": "Miscellaneous Vehicles - All Risks",
  "csio:ATVNP": "Miscellaneous Vehicles - Named Perils",
  "csio:Auction": "Auction",
  "csio:BSEIN": "Base Internal Limits",
  "csio:BedAndBreakfast": "Bed and Breakfast",
  "csio:BEDBR": "Bed & Breakfast",
  "csio:BLDCR": "Building under construction",
  "csio:BLDG": "Building - Other than Residence for Homeowner",
  "csio:BLDRS": "Builder's Risk Insurance",
  "csio:BOCH": "Day Care Extension",
  "csio:BOL": "Boarding of Livestock",
  "csio:BUNOV": "Building under Renovation Endorsement",
  "csio:BURG": "Residence Burglary",
  "csio:BURN": "Burn Exclusion",
  "csio:BUSNS": "Incidental Business Pursuits Endorsement",
  "csio:BUSPR": "Business Property: On Property",
  "csio:BUSPO": "Business Property: Off Property",
  "csio:BYLAW": "Bylaws Endorsement",
  "csio:CANNA": "Cannabis Endorsement",
  "csio:CARE": "Care Facility Contents Extension",
  "csio:CBLLA": "Condominium Bare Land Loss Assessment (Property Section)",
  "csio:CCARD": "Credit Card Forgery and Counterfeit Money Coverage",
  "csio:CCBIK": "Bicycle",
  "csio:CCBK": "Books, Tools, and Instruments Pertaining to a Business",
  "csio:CCBLK": "Increased Limit for Blanket Coverage",
  "csio:CCBUS": "Business Increased Limits",
  "csio:CCC": "Coin Collection",
  "csio:CCCD": "Compact Discs or Audio Tapes",
  "csio:CCCOL": "Collectibles and Treasures Increased Limits",
  "csio:CCFAM": "Family and Security Increased Limits",
  "csio:CCFR": "Furs",
  "csio:CCGC": "Golf Carts",
  "csio:CCHRC": "Home and Recreation Increased Limits",
  "csio:CCJW": "Jewellery/Watches/Gems",
  "csio:CCLA": "Condominium Contingent Legal Liability",
  "csio:CCMOP": "Money and Bullion",
  "csio:CCPBK": "Professional Books Increased Limits",
  "csio:CCPS": "Personal Computer (Software)",
  "csio:CCSCC": "Sports Card Collection",
  "csio:CCSED": "Securities",
  "csio:CCST": "Stamps",
  "csio:CCSV": "Silverware",
  "csio:CCTFV": "Theft from Vehicle",
  "csio:CCTS": "Tombstones (Grave Markers)",
  "csio:CCWND": "Windstorm",
  "csio:CCWT": "Watercraft",
  "csio:CDEDA": "Condominium Deductible Assessment - Earthquake",
  "csio:CECCE": "Common Elements Condominium Corporation Extension Endorsement",
  "csio:CEDED": "Endorsement for change in deductible notice",
  "csio:CFEXC": "Chip and Fracture Exclusion",
  "csio:CLADV": "Claims advantage",
  "csio:CLAND": "Crown Land",
  "csio:CLFRE": "Claim Free Protection",
  "csio:COLLP": "Collapse",
  "csio:COMST": "Commercial Stock",
  "csio:CONPE": "Public Employee Liability For Building Under Construction",
  "csio:CONSE": "Building under construction liability",
  "csio:CONSQLOSS": "Consequential Loss  ",
  "csio:CONST": "Construction Liability",
  "csio:CONTM": "Contamination",
  "csio:CONTR": "Insured Acting as Own Contractor",
  "csio:COPLL": "Comprehensive Personal Liability Limitation",
  "csio:CPL":
    "Legal Liability - Other than Homeowners, Tenant and Condominium Form",
  "csio:CPLCO":
    "Comprehensive Personal Liability - Product - Completed Operations Exclusions",
  "csio:CR": "criminal ",
  "csio:CRL": "Cross Liability",
  "csio:CustomFarming": "Custom Farming",
  "csio:CustomSpraying": "Custom Spraying",
  "csio:CYBER": "Personal Cyber Coverage",
  "csio:DAYC": "Day Care",
  "csio:Daycare": "Daycare/Babysitting",
  "csio:DEBRI": "Debris Removal",
  "csio:DEDAB": "Deductible Assessment Buydown",
  "csio:DEDED": "Decreasing Deductible Endorsement ",
  "csio:DEFER": "Deferred Loss Settlement Clause",
  "csio:DEMO": "Demolition Increased Cost of Construction",
  "csio:DENT": "Dent Clause",
  "csio:DEPEN": "Dependents Coverage Endorsement",
  "csio:DEWHA": "Wind/Hail Deductible",
  "csio:EXDIS": "Diseases Exclusion Endorsement",
  "csio:DISER": "Discount - Electronic Documents",
  "csio:DISON": "Discount - One Payment",
  "csio:DOFXC": "Domestic Fuel Exclusion",
  "csio:DPSEX": "Detached Private Structures Exclusion Endorsement",
  "csio:DPSLE": "Detached Private Structures Limitation Endorsement",
  "csio:DRGEX": "Damage Due to Illicit Narcotics Exclusion",
  "csio:DWELL": "Dwelling - Residence for Homeowner",
  "csio:DYCAR": "Day Care Activities Exclusion",
  "csio:EARFP": "Earned Flat Premium",
  "csio:ECARE": "Elderly care Home Extension",
  "csio:EEQPT": "Exhibition of Equipment",
  "csio:ELEV": "Elevator",
  "csio:ELPOWINT": "Electrical Power Interruption",
  "csio:EMERG":
    "Declaration of Emergency Endorsement – Extension of Termination or Expiry Date",
  "csio:ENVLS": "Environmentally Friendly Home Replacement Loss Settlement",
  "csio:ENHIN": "Enhanced Internal Limits",
  "csio:ENTR": "Entrapment",
  "csio:ERQK": "Earthquake",
  "csio:ERQKF": "Post-earthquake Damage",
  "csio:ESOPU": "Estate Of - Permission for Unoccupancy",
  "csio:EVAC": "Mass Evacuation Endorsement",
  "csio:EXAN": "Exhibition of Animals",
  "csio:ExoticAnimal": "Unusual/Exotic Animal(s)",
  "csio:EXTCD": "Extended Coverage Deletion",
  "csio:EXTEX": "Extra Expense",
  "csio:EXTR": "Excess Trailers",
  "csio:EXWAT":
    "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
  "csio:FARM": "Farm Land",
  "csio:FARSN": "Farm Machinery Used for Snow Removal",
  "csio:FCHOM": "Foster Care Home Extension",
  "csio:FCPL": "Farmers Comprehensive Personal Liability",
  "csio:FDC": "Fire Department Service Charge",
  "csio:Fishing": "Fishing",
  "csio:FLDEX": "Flood Exclusion",
  "csio:FLOOD": "Flood Water - Damage caused by flood water.",
  "csio:FLYBD": "Flyboard Liability Extension",
  "csio:FORPA": "Forest and Prairie Protection Act",
  "csio:FRCLS": "Functional Replacement Cost Loss Settlement",
  "csio:FREEZ": "Home Freezer (Food Spoilage)",
  "csio:FRHEX": "Foster Home Exclusion",
  "csio:FRMHB": "Hobby Farm",
  "csio:FRTPR": "Furnished Rented Property",
  "csio:FURMC": "Limited Fungi or Other Microbes Remediation Coverage",
  "csio:GARTT": "Garden Type Tractor",
  "csio:GEOTH": "Geothermal Energy ",
  "csio:GHOME": "Group Home Extension",
  "csio:GLABR": "Glass Breakage",
  "csio:GLDED": "Glass Deductible Endorsement",
  "csio:GOLFC": "Golf Cart",
  "csio:GOLFP": "Golf Package Endorsement",
  "csio:GravelPit": "Gravel Pit",
  "csio:GRCB":
    "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
  "csio:GRCE":
    "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
  "csio:GRCEX": "Guarantee Replacement Cost Excluded",
  "csio:GRCSL": "Guaranteed Building Replacement Cost with Single Limit",
  "csio:GRNDW":
    "Ground Water - Damage caused by infiltration of water through a foundation.",
  "csio:GUARR": "Guaranteed Replacement Cost - Building",
  "csio:HAEXT": "Home Accessibility Extension",
  "csio:HAILC": "Hail Coverage Endorsement",
  "csio:HaySleighRides": "Hay/Sleigh Rides",
  "csio:HBBLI": "Home-Based Business Liability Extension",
  "csio:HBOFF": "Home Business Off-Premise Coverage",
  "csio:HBON": "Home Business On-Premise Coverage",
  "csio:HCAC": "Home Care Assistance Coverage",
  "csio:HEATPR": "Heat Prostration",
  "csio:HLDNT": "Restrict Hail Damage to Punctures",
  "csio:HLLAR": "Hull - All Risk",
  "csio:HLLMP": "Hull - Named Perils and Marine Perils",
  "csio:HLLNP": "Hull - Named Perils",
  "csio:HMTQ": "Her Majesty the Queen",
  "csio:HOBUS": "Home Business Coverage",
  "csio:HOEQP": "Home Equipment Protection",
  "csio:HORSE": "Horse",
  "csio:HorsePulls": "Horse Pulls",
  "csio:HOSTL": "Host Liquor Liability Exclusion",
  "csio:HSL": "Single Limit",
  "csio:HUDED": "Hurricane Deductible  ",
  "csio:STHE": "Stronger Home Endorsement",
  "csio:IDTFT": "Identity Theft",
  "csio:IDTLI": "Identity Theft - Loss of Income",
  "csio:ILLDISEASE": "Illness/Disease",
  "csio:INFGD": "Inflation Guard Endorsement",
  "csio:INSVT": "In Servant",
  "csio:IPL":
    "Increased Legal Liability, Homeowners, Tenants & Condominium Forms",
  "csio:JPPWB": "Waterjet Propulsion Personal Watercraft - Basic",
  "csio:JPPWP": "Waterjet Propulsion Personal Watercraft - Passenger Hazard",
  "csio:JPPWU": "Waterjet Propulsion Personal Watercraft - Underage Operator",
  "csio:LCCEE": "Leasehold Condominium Corporation Extension Endorsement",
  "csio:LEAKF": "Leakage or Overflow of Domestic Fuel Oil",
  "csio:LEFEE": "Legal Expense Insurance",
  "csio:LEGAL": "Legal Assistance",
  "csio:LGUAP": "Limited Guaranteed Replacement Cost Percentage - Building ",
  "csio:Lessons": "Lessons",
  "csio:Liability": "Liability",
  "csio:LIMET": "Limited Exterior",
  "csio:LIMSD": "Limited to Structural Damage ",
  "csio:LIMWD": "Limited Hidden Water Damage",
  "csio:LLEA": "Life Lease Extension",
  "csio:LLEXT": "Limited Liability Extension ",
  "csio:LLLA": "Legal Liability Loss Assessment",
  "csio:LLLAB": "Legal Liability Loss Assessment Bare Land",
  "csio:LLDD": "Life Lease Deductible",
  "csio:LLDE": "Life Lease Deductible - Earthquakes",
  "csio:LLOI": "Life Lease Unit Owner Improvement",
  "csio:LLPC": "Life Lease Property Coverage",
  "csio:LLUC": "Life Lease Unit Coverage",
  "csio:LOADUNLOAD": "Loading/Unloading",
  "csio:LOGSL": "Log Construction Settlement Limitation",
  "csio:LSADV": "Lifestyle advantage",
  "csio:LSBNP": "Livestock – Broad Named Perils",
  "csio:LSLNP": "Livestock – Limited Named Perils",
  "csio:LTDMOR": "Limited Mortality",
  "csio:LtdPollutionLiab": "Limited Pollution Liability",
  "csio:LVWAR": "Locked Vehicle Warranty",
  "csio:LWD": "Waiver of Deductible",
  "csio:MA": "major",
  "csio:MAINL": "Maintenance Work Liability",
  "csio:MARFC": "Matching of Undamaged Roof Surfacing Additional Coverage ",
  "csio:MASDC": "Matching of Undamaged Siding - Additional Coverage  ",
  "csio:MBCP": "Modified Basis of Claims Payment",
  "csio:MEDPM": "Voluntary Medical Payments",
  "csio:MI": "minor",
  "csio:MLDEX": "Mold Exclusion",
  "csio:MODLS": "Modified Loss Settlement",
  "csio:MOTAR": "Motor - All Risks",
  "csio:MOTMP": "Motor - Named Perils and Marine Perils",
  "csio:MOTNP": "Motor - Named Perils",
  "csio:NOL": "Non Owned LiveStock",
  "csio:NGRCE": "Single limit AND Guarantee Replacement Cost Excluded",
  "csio:NOBEQ": "Non-Owned Business Equipment ",
  "csio:NOR": "No Obligation to Rebuild",
  "csio:NPAP": "Named Perils - Unit Owners Additional Protection",
  "csio:NPIB":
    "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
  "csio:NPLA": "Named Perils - Loss Assessment Coverage",
  "csio:NPPAE": "Named Perils Personal Articles Endorsement",
  "csio:NPSP": "Named Perils - Solar Panel",
  "csio:OFFNR":
    "Office, Professional,Private School or Studio Use (Other Premises)",
  "csio:OFFRP":
    "Office, Professional, Private School or Studio Use (Res. Premises)",
  "csio:OHEAT": "Oil Tank Heating",
  "csio:OLEAK": "Oil Leakage on Premise Liability Extension",
  "csio:OPM": "Out of Province Medical",
  "csio:OS": "Other Structures - Homeowners Forms",
  "csio:OSAWP": "Specific Structures Away from the Residence Premises ",
  "csio:OSROR": "Other Structures Rented to Others (Residence Premises)",
  "csio:OTALE": "Other Alternative Energy ",
  "csio:OTH": "Other",
  "csio:OTHMH": "Other Members of Your Household",
  "csio:OTSRP": "Other (Appurtenant) Structures (Residence Premises)",
  "csio:OTSTR": "Unit Owners Other Structures",
  "csio:OUTSV": "Out Servant",
  "csio:OVWAT": "Overland Water (Flood & Surface Water) Coverage",
  "csio:OVWEX": "Overland Water Exclusion",
  "csio:OWKEN": "Owned Kennel Liability Extension",
  "csio:PAHAI": "Peril Adjustment - Hail",
  "csio:PAKRC": "Replacement Cost Package",
  "csio:PASNO": "Peril Adjustment - Snow",
  "csio:PAWAT": "Peril Adjustment - Water",
  "csio:PAWIN": "Peril Adjustment - Wind",
  "csio:PBIPD":
    "Comprehensive Personal Liability - Bodily Injury and Property Damage (Coverage A)",
  "csio:PCOAL": "Products Completed Operations Aggregate Limit",
  "csio:PERDR": "Personal Records and Data Replacement",
  "csio:PERAH": "Permission to Use Auxiliary Heating",
  "csio:PERCE":
    "Permission to Use Auxiliary Heating in Case of Electricity Failure",
  "csio:PEREF": "Personal Effects Floater - Domiciled Endorsement",
  "csio:PERLI": "Personal Liability (Umbrella Endorsement)",
  "csio:PERSL": "Personal Liability",
  "csio:PERUS": "Personal Liability (Umbrella Standalone)",
  "csio:PettingZoo": "Petting Zoo",
  "csio:PickYourOwn": "Pick Your Own (fruits & vegetables)",
  "csio:PIHOM": "Personal Injury Endorsement",
  "csio:PILS": "Power Interruption (Livestock)",
  "csio:PL": "Legal Liability - Homeowners, Tenant and Condominium",
  "csio:PLASS": "Property Loss Assessment",
  "csio:PLIJ":
    "Comprehensive Personal Liability - Personal Injury (Coverage B)",
  "csio:PLMP":
    "Comprehensive Personal Liability - Medical Payments (Coverage C)",
  "csio:PondsRiversPools": "Ponds, Rivers, Swimming Pools",
  "csio:PP": "Personal Property - Homeowners Form",
  "csio:PPOTH":
    "Personal Property - Other than Homeowners, Tenant and Condominium Form",
  "csio:PPTC": "Personal Property - Tenant and Condominium Unit Owners Form",
  "csio:PRCVL": "Premises Coverage Limitation",
  "csio:PREXT": "Premises Extension",
  "csio:PRLAK": "Private Lake",
  "csio:PROAC": "Professional Activity",
  "csio:PropertyDmge": "Property Damage",
  "csio:PROPO": "Personal Property - Pollution",
  "csio:PROS": "Property in Storage",
  "csio:PROST": "Property of Students Coverage",
  "csio:PTLL":
    "Comprehensive Personal Liability- Tenants Legal Liability (Coverage D) ",
  "csio:PWFL": "Power Fluctuation",
  "csio:RCBLD": "Replacement Cost - Building",
  "csio:REBCL": "Rebuilding Clause",
  "csio:REBUC": "Specialty Rebuilding Cost Endorsement ",
  "csio:REDIN": "Reduced Internal Limits",
  "csio:RELIA": "Residence Employees Liability",
  "csio:RENT": "Rental Income",
  "csio:RESPR": "Residence Premises - 2 to 6 Family Dwelling",
  "csio:RETRA": "Recreational Travel Trailers",
  "csio:RidingArena": "Riding Arena",
  "csio:RNTCG": "Rental - Seasonal Dwelling ",
  "csio:RNTDT": "Short-Term Rental Endorsement",
  "csio:RNTWC": "Rental Watercraft (Rented to Others)",
  "csio:RoadStandMrkt": "Roadside Stand/Market",
  "csio:ROOF": "Roof (Limited to Cash Value)",
  "csio:ROOFA": "Roof (Actual Cash Value)",
  "csio:ROOFC": "Roof Exclusion - All",
  "csio:ROOFE": "Roof Exclusion",
  "csio:ROOFL": "Limited Roof Surface",
  "csio:ROOFR": "Roof (Replacement Cost Value)",
  "csio:ROOFS": "Roof Surface - Basis of Settlement",
  "csio:ROOFW": "Roof (Basis of Settlement) due to Windstorm/Hail",
  "csio:ROOMR": "Roomer",
  "csio:RUOE": "Rented Unit Owners Endorsement",
  "csio:RVCCH": "Residence Voluntary Compensation - Chauffeur",
  "csio:RVCIN": "Residence Voluntary Compensation - Resident Employee",
  "csio:RVCMP":
    "Residence Voluntary Comp. (Obsolete: see RVCCH, RVCIN, RVCOC, RVCOU)",
  "csio:RVCOC": "Residence Voluntary Compensation - Occasional",
  "csio:RVCOU": "Residence Voluntary Compensation - Non-resident Employee ",
  "csio:RWDAM": "Restricted Water Damage",
  "csio:RWEI": "Renewable Energy Income",
  "csio:RWLIA": "Runway Liability Extension",
  "csio:SalesBarn": "Sales Barn",
  "csio:SAUNA": "Sauna ",
  "csio:SBAC": "Actual Cash Value for Sewer Back Up",
  "csio:SCAPT": "Self-contained Apartment",
  "csio:SEARS": "Seasonal Residence",
  "csio:SEDWG": "Seasonal Dwelling",
  "csio:SeedmanEO": "Seedman's E&O",
  "csio:SELCL": "Enhanced coverage option for selected clients",
  "csio:SEWAT": "Water and Sewer Lines Coverage",
  "csio:SEWER": "Sewer Back-up Coverage",
  "csio:SEWLI":
    "Exterior Sewer Line - Breakage of the exterior sewer water line.",
  "csio:SEWLM": "Sewer Back-up Limitation",
  "csio:SFHW": "Solid Fuel Heat Warranty",
  "csio:ShowAnimal": "Show Animal(s)",
  "csio:SLEXT": "Service Line Extension Endorsement",
  "csio:SLPLI": "Solar Panel- Loss of Income",
  "csio:SmallEngineRepair": "Small engine repair",
  "csio:SMART": "SmartHome Systems",
  "csio:SNOAR": "Snowmobiles - All Risks",
  "csio:SNOBL": "Snow Blower",
  "csio:SNOLI": "Snow Blower",
  "csio:SNONP": "Snowmobiles - Named Perils",
  "csio:SNOW": "Weight of Snow and Ice",
  "csio:SnowRemoval": "Snow Removal",
  "csio:SOLP": "Solar Panel",
  "csio:SPA": "Spa Coverage",
  "csio:SPALE": "Spa  ",
  "csio:SPBYL": "Sprinkler Bylaw",
  "csio:SPDAM": "Damage to Outdoor Swimming Pool & Attached Equipment",
  "csio:SPLIA": "Swimming Pool or Pond",
  "csio:SPLS": "Special Loss Settlement",
  "csio:SPTEQ": "Sporting Equipment",
  "csio:SRFCW": "Surface Water – Damage caused by surface water.",
  "csio:STALE": "Stacking of Limit Endorsement",
  "csio:STL": "Stableman's Liability",
  "csio:SUGS": "Sugar Shack With Reception Hall",
  "csio:SUPLD": "Supplementary Lodging",
  "csio:SWELL": "Shared Water Well",
  "csio:TADLE": "Temporary Additional Location Endorsement",
  "csio:TEC": "Trust Endorsement",
  "csio:TFFDP": "One to Six Family Dwelling Premises Liability",
  "csio:THFBM": "Theft of Building Materials Under Construction",
  "csio:THFBU": "Theft and Burglary",
  "csio:THFTX": "Theft Exclusion",
  "csio:TIRES": "Tires",
  "csio:TRAAR": "Trailer - All Risks / Trailer (Non-Travel) - All Risk",
  "csio:TracksTrails": "Tracks/trails",
  "csio:TrailRides": "Trail Rides",
  "csio:TRANP": "Trailer - Named Perils / Trailer (Non-Travel)  - Named Perils",
  "csio:TRAVL": "Emergency Medical Travel Insurance",
  "csio:TRC": "Tree Removal Coverage   ",
  "csio:TRPLS": "Trees, Plants & Shrubbery",
  "csio:TTAAR": "Travel Trailers Temporary Attachments - All Risks",
  "csio:TTANP": "Travel Trailers Temporary Attachments - Named Perils",
  "csio:TTAR": "Travel Trailers - All Risks",
  "csio:TTBF": "Travel Tailer Broad Form",
  "csio:TTCAR": "Travel Trailers Contents - All Risks",
  "csio:TTCNP": "Travel Trailers Contents - Named Perils",
  "csio:TTEML": "Emergency Living Expenses",
  "csio:TTEMR": "Emergency Road Service",
  "csio:TTNP": "Travel Trailers - Named Perils",
  "csio:TTPL": "Personal Liability",
  "csio:UNLIC": "Unlicensed Snow Vehicle, Trail Bike or ATV",
  "csio:USEXC": "USA Products and/or Operations Exclusion ",
  "csio:UTRAC": "Utility Tractor Endorsement",
  "csio:UTRLI": "Utility Tractor Liability Extension",
  "csio:VACLD": "Vacant Land",
  "csio:VACPR": "Vacancy Permit",
  "csio:VALVE": "Backwater Valve Maintenance Clause",
  "csio:VANTH": "Vandalism / Theft by Tenants and Guest",
  "csio:VehBoatStorage": "Vehicle/Boat Storage",
  "csio:VEHUNL": "Vehicles Unlicensed",
  "csio:VLPD": "Voluntary Property Damage",
  "csio:VMBC": "Malicious Damage - Building & Contents",
  "csio:VMC": "Malicious Damage on Contents",
  "csio:VMDB": "Malicious Damage on Building",
  "csio:VNDSD": "Vandalism on Seasonal Dwelling",
  "csio:VoluntaryComp": "Voluntary Compensation ",
  "csio:VTEXC": "Vandalism by Tenants Exclusion",
  "csio:WALI2": "Watercraft Liability",
  "csio:WALI3": "Watercraft Liability",
  "csio:WALI4": "Watercraft Liability",
  "csio:WALI5": "Watercraft Liability ",
  "csio:WALI6": "Watercraft Liability",
  "csio:WALI7": "Watercraft Liability",
  "csio:WALI8": "Watercraft Liability",
  "csio:WALI9": "Watercraft Liability",
  "csio:WALIA": "Watercraft Liability",
  "csio:WAPRE": "Waived Premium",
  "csio:WATER": "Water Damage Endorsement",
  "csio:WBED": "Waterbed",
  "csio:WBLIA": "Waterbed",
  "csio:WDDED":
    "Water Damage Deductible - Specified Deductible for loss caused by Water. ",
  "csio:WDE": "Water Damage Exclusion",
  "csio:WHADO": "Wharves and Docks",
  "csio:WHEXC": "Wind & Hail Exclusion",
  "csio:WIND": "Windstorm/Hail",
  "csio:WINDC": "Wind Coverage Endorsement",
  "csio:WINDL": "Wind Energy ",
  "csio:WLIFE": "Damage Caused by Wild Life",
  "csio:WMapleSyrup": "Wood/Maple Syrup Sales",
  "csio:WNSPT": "Wine & Spirits Endorsement",
  "csio:WRECK": "Wreckage Value",
  "csio:WSHWA": "Removed Heat Warranty",
  "csio:WSLIM": "Wood Stove Limitation",
  "csio:WSWAR": "Wood Stove Warranty",
  "csio:XTFT": "Extended Theft Away from Premises",
  "csio:BUSPP": "Property Pertaining to a Business (Not premises specific)",
  "csio:WINDX": "Damage Caused by Hail or Wind Excluding the Roof",
  "csio:ACCLC": "Accident Insurance: Limit per claim",
  "csio:LEADB": "Liability Buy Back Coverage",
  "csio:THEFT": "Theft",
  "csio:CCCM": "Cameras",
  "csio:CCCC": "China/Crystal",
  "csio:CCFA": "Fine Arts",
  "csio:CCFM": "Firearms",
  "csio:CCMI": "Musical Instruments",
  "csio:PLAC": "Property Loss Assessment/Contingency",
  "csio:CIEE": "Cyber Incident Exclusion Endorsement",
  "csio:UROUP": "Undivided Right of Ownership - Unequal Percentages",
  "csio:UROEP": "Undivided Right of Ownership - Equal Percentages",
  "csio:HSB": "Home Settlement Benefit",
  "csio:BNGRP": "Benefits for Group Members",
  "csio:WRDED": "Deductible - Water Infiltrations Through The Roof",
  "csio:WDAGW": "Water Damage - Above Ground Water ",
  "csio:WHDED": "Deductible - Water Damage By The Water Heater",
  "csio:PET": "Pet Emergency Endorsement",
  "csio:MRNAS": "Marine Assistance",
  "csio:PPWAT": "Personal Property – Watercraft",
  "csio:ETW": "Emergency towing",
  "csio:NGTR": "Navigational Territory Extension",
  "csio:STORG": "Annual Storage",
  "csio:NMSTM": "Named Storm Exclusion",
  "csio:PRPEX": "Propeller Exclusion",
  "csio:TRLIA": "Boat Trailer Liability",
  "csio:ADE": "Accidental Death",
  "csio:WRAP": " Wrapping Coverage",
  "csio:TSHEX": "Tail Shaft Exclusion",
};

export const CSIO_POLICY_TERMINATED: { [Key: string]: string } = {
  "csio:1": "Declined by Insurer",
  "csio:2": "Non-renewed by Insurer",
  "csio:3": "Cancelled by Insurer",
  "csio:4": "Non-renewed by Insured",
  "csio:5": "Cancelled by Insured",
  "csio:6": "Insurer restriced coverage",
  "csio:9": "Other",
};
export const CSIO_OCCUPANCT_TYPE: { [Key: string]: string } = {
  "csio:1": "Primary Residence",
  "csio:2": "Secondary, Non-seasonal",
  "csio:3": "Secondary, Seasonal",
  "csio:4": "Rental",
  "csio:6": "Not Applicable",
  "csio:7": "Unoccupied",
  "csio:8": "Vacant",
  "csio:9": "Rented to Third Party",
  "csio:A": "Under Construction",
  "csio:B": "Employee Occupied",
  "csio:D": "Major Renovation",
  "csio:OT": "Other",
};
export const CSIO_INSTALLATION_TYPE: { [Key: string]: string } = {
  "csio:1": "Professional Installation",
  "csio:2": "Non-Professional Installation",
  "csio:9": "Unknown Installer",
};
export const CSIO_CERTIFYING_ENTITY: { [Key: string]: string } = {
  "csio:1": "Canadian Standards Association (CSA)",
  "csio:2": "Warnock-Hersey Professional Service Ltd.",
  "csio:3": "Underwriters' Laboratories of Canada",
  "csio:9": "Other",
  "csio:4": "American National Standards Institute (ANSI)",
};
export const CSIO_STRUCTURE_TYPE: { [Key: string]: string } = {
  "csio:AB": "Apartment - Basement in Dwelling",
  "csio:AC": "Apartment/Co-op",
  "csio:AM": "Apartment including Mercantile",
  "csio:AP": "Apartment",
  "csio:BA": "Barn",
  "csio:BO": "Boathouse",
  "csio:BS": "DEPRECATED Basement",
  "csio:CD": "Condo",
  "csio:CH": "Chicken Coop",
  "csio:CO": "Cottage",
  "csio:DT": "Detached",
  "csio:DW": "Dwelling",
  "csio:DX": "Duplex",
  "csio:FM": "Folding Home",
  "csio:GA": "Garage",
  "csio:GR": "Greenhouse",
  "csio:HR": "High Rise",
  "csio:LR": "Low Rise",
  "csio:MD": "Modular Home",
  "csio:MH": "Mobile Home",
  "csio:MI": "Mini Home",
  "csio:MX": "Multi-plex (4 units or more)",
  "csio:OT": "Other",
  "csio:PC": "Pre-Cut Home",
  "csio:PF": "Pre fabricated - Unspecified",
  "csio:PH": "Panelized Home",
  "csio:RE": "Row House/Town House (End)",
  "csio:RH": "Row House - Unspecified",
  "csio:RI": "Row House/Town House (Inside)",
  "csio:SC": "Sugar Camp",
  "csio:SD": "Semi detached",
  "csio:SE": "Servant's Quarters",
  "csio:SG": "Storage",
  "csio:ST": "Stable",
  "csio:TH": "Town House - Unspecified",
  "csio:TR": "Trailer (Stationary)",
  "csio:TS": "Toolshed",
  "csio:TX": "Triplex",
};
export const CSIO_FOUNDATION_TYPE: { [Key: string]: string } = {
  "csio:1": "Concrete/Masonry",
  "csio:2": "On Blocks",
  "csio:3": "On Wheels",
  "csio:4": "On Timber",
  "csio:5": "Post/Pier on Slab",
  "csio:6": "Basement",
  "csio:7": "Crawlspace",
  "csio:8": "Slab/Concrete Slab",
  "csio:9": "Other",
  "csio:B": "Brick",
  "csio:C": "Concrete Blocks",
  "csio:P": "Poured Concrete",
  "csio:R": "Reinforced Concrete",
  "csio:S": "Stone",
};
export const CSIO_DRIVER_RELATIONSHIP: { [Key: string]: string } = {
  "csio:1": "Partner, Same-sex",
  "csio:2": "Common Law Spouse",
  "csio:B": "Brother/Sister",
  "csio:C": "Child",
  "csio:E": "Employee",
  "csio:H": "Co habitant",
  "csio:I": "Insured",
  "csio:L": "Lessee",
  "csio:O": "Other",
  "csio:P": "Parent",
  "csio:R": "Other Relative",
  "csio:S": "Spouse",
  "csio:T": "Third Party",
};
export const CSIO_PLUMBING_TYPE: { [Key: string]: string } = {
  "csio:1": "Copper",
  "csio:2": "Plastic",
  "csio:3": "Other",
  "csio:4": "Cast Iron",
  "csio:5": "Galvanized Steel",
  "csio:6": "Wood",
  "csio:7": "Lead",
  "csio:8": "Stainless Steel",
  "csio:9": "Steel",
  "csio:10": "PVC (polyvinyl chloride)",
  "csio:11": "ABS (acrylonitrile-butadiene-styrene)",
  "csio:12": "PEX (crosslinked polyethylene)",
  "csio:13": "POLB (polybutylene)",
  "csio:98": "No Plumbing",
  "csio:A": "Aluminum",
  "csio:B": "Brass",
  "csio:C": "Galvanized Iron",
};
export const CSIO_FIRE_PROTECTION_TYPE: { [Key: string]: string } = {
  "csio:1": "Standard",
  "csio:2": "Non standard, Standpipe",
  "csio:3": "Lake, Pond, etc.",
  "csio:4": "Tanker",
  "csio:5": "Superior Shuttle Tanker Service",
};

export const CSIO_POOL_CONSTRUCTION_TYPE: { [Key: string]: string } = {
  "csio:1": "Reinforced Concrete",
  "csio:2": "Vinyl Liner",
  "csio:3": "Fiberglass",
  "csio:4": "Above Ground",
  "csio:9": "Other",
  "csio:5": "In Ground",
  "csio:6": "Semi In Ground",
};

export const CSIO_GARAGE_TYPE: { [Key: string]: string } = {
  "csio:0": "None",
  "csio:1": "Built in",
  "csio:2": "Basement",
  "csio:3": "Carport",
  "csio:4": "Attached Frame",
  "csio:5": "Attached Masonry or Masonry/Veneer",
  "csio:6": "Detached Frame",
  "csio:7": "Detached Masonry or Masonry/Veneer",
  "csio:8": "Attached - Masonry",
  "csio:9": "Basement Under Attached Garage",
  "csio:A": "Carport With Storage",
  "csio:B": "Detached Garage - Masonry",
  "csio:O": "Other",
  "csio:C": "Attached Garage - Other",
  "csio:D": "Detached Garage - Other",
};

export const CSIO_CONSTRUCTION_TYPE: { [Key: string]: string } = {
  "csio:0": "Unknown",
  "csio:1": "Log",
  "csio:2": "Monocoque",
  "csio:3": "Fiberglass",
  "csio:4": "DEPRECATED  Metal",
  "csio:5": "Frame (Post and Beam)",
  "csio:6": "Frame (Timber)",
  "csio:9": "Other",
  "csio:A": "DEPRECATED Asbestos",
  "csio:B": "Brick",
  "csio:C": "DEPRECATED Cement",
  "csio:D": "Concrete Block ",
  "csio:E": "DEPRECATED Cement Fiber Board",
  "csio:F": "Frame (Wood)",
  "csio:G": "DEPRECATED Aggregate",
  "csio:H": "DEPRECATED Cement Fiber Shingle",
  "csio:I": "Masonite",
  "csio:J": "DEPRECATED Masonry Non-Combustible ",
  "csio:K": "DEPRECATED Cedar Siding",
  "csio:L": "Aluminium",
  "csio:M": "DEPRECATED Masonry",
  "csio:N": "Stone",
  "csio:O": "DEPRECATED Stucco",
  "csio:P": "DEPRECATED Log Siding",
  "csio:R": "DEPRECATED Fire Resistive",
  "csio:S": "DEPRECATED Stone Veneer",
  "csio:T": "Steel",
  "csio:U": "DEPRECATED Wood Exterior",
  "csio:V": "DEPRECATED Masonry Veneer",
  "csio:W": "DEPRECATED Brick Veneer",
  "csio:X": "DEPRECATED Non-fire Resistive Apartment",
  "csio:Y": "DEPRECATED Vinyl",
  "csio:Z": "DEPRECATED Non-Combustible",
};
export const CSIO_CONSTRUCTION_QUALITY: { [Key: string]: string } = {
  "csio:1": "Luxury",
  "csio:2": "Custom",
  "csio:3": "Standard",
  "csio:4": "Economy",
  "csio:5": "Other",
  "csio:6": "Builder's Grade",
};
export const CSIO_ALARM_TYPE: { [Key: string]: string } = {
  "csio:1": "Fire",
  "csio:2": "Burglar",
  "csio:4": "Humidity",
  "csio:10": "Smoke",
  "csio:15": "Temperature",
  "csio:16": "Sprinkler Alarm",
};
export const CSIO_ALARM_DESC_TYPE: { [Key: string]: string } = {
  "csio:1": "Fire",
  "csio:2": "Burglar",
  "csio:4": "Humidity",
  "csio:10": "Smoke",
  "csio:15": "Temperature",
  "csio:16": "Sprinkler Alarm",
};
export const CSIO_SMOKE_DETECTION_TYPE: { [Key: string]: string } = {
  "csio:99": "Other",
  "csio:0": "Unknown ",
  "csio:1": "Ionization",
  "csio:2": "Photoelectric",
  "csio:3": "Ionization & Photoelectric",
};
export const CSIO_PREMISES_SECURITY_TYPE: { [Key: string]: string } = {
  "csio:0": "None",
  "csio:1": "Intercom",
  "csio:2": "24 Hour Security",
  "csio:3": "Part Time Security and Intercom",
  "csio:4": "Gated Community Security",
  "csio:5": "Security Attendant",
  "csio:99": "Other",
  "csio:6": "Secured Entrance",
};
export const CSIO_RESIDENCE_TYPE: { [Key: string]: string } = {
  "csio:BA": "Barn",
  "csio:BO": "Boathouse",
  "csio:BS": "DEPRECATED Basement",
  "csio:CH": "Chicken Coop",
  "csio:CO": "Cottage",
  "csio:GA": "Garage",
  "csio:GR": "Greenhouse",
  "csio:SC": "Sugar Camp",
  "csio:SE": "Servant's Quarters",
  "csio:SG": "Storage",
  "csio:ST": "Stable",
  "csio:TS": "Toolshed",
};

export const CSIO_FUEL_TYPE: { [Key: string]: string } = {
  "csio:A": "Oil/Wood",
  "csio:B": "Butane",
  "csio:C": "Coal",
  "csio:D": "Wind",
  "csio:E": "Electric/Wood",
  "csio:F": "Ethanol/Alcohol",
  "csio:G": "Naphta Gas",
  "csio:H": "Recycled Oil",
  "csio:I": "Oil",
  "csio:K": "Kerosene",
  "csio:L": "Liquefied Petroleum Gas",
  "csio:M": "Combination - other",
  "csio:N": "Natural Gas",
  "csio:O": "Other",
  "csio:P": "Propane",
  "csio:R": "Solar",
  "csio:S": "Other Solid Fuel",
  "csio:T": "Pellet",
  "csio:U": "Grain",
  "csio:V": "Steam",
  "csio:W": "Wood",
  "csio:X": "Electric/Oil",
  "csio:Y": "Electric",
  "csio:Z": "Diesel",
};
export const CSIO_HEATING_TYPE: { [Key: string]: string } = {
  "csio:1":
    "DEPRECATED Combined (Primary (Outside Unit of Risk) and inside Auxiliary)",
  "csio:2": "Solid Fuel Heating Unit",
  "csio:3": "DEPRECATED Slow Wood Burning/Air Tight Stove",
  "csio:4": "Radiant Ceiling",
  "csio:5": "DEPRECATED Pellet Stove",
  "csio:6": "Recessed Heater",
  "csio:7": "Pipeless Warm Air Furnace",
  "csio:8": "Radiant Floor",
  "csio:10": "Solar Panels",
  "csio:11": "Gravity Furnace",
  "csio:9": "HVAC system",
  "csio:A":
    "DEPRECATED ACORN Stove, Franklin or Potbelly Stove (loose fitting or no doors)",
  "csio:B": "Furnace (Central) with Add-on Wood Burning Unit",
  "csio:C": "Hot Water/Steam/Boiler Unit",
  "csio:D": "Fireplace Insert",
  "csio:E": "Baseboard Heater",
  "csio:F": "Furnace (Central)",
  "csio:G": "Bi-energy/Combination",
  "csio:H": "DEPRECATED Cookstove",
  "csio:I": "Fireplace",
  "csio:J": "DEPRECATED Solid Fuel Heating Unit -Indoor",
  "csio:K":
    "Electric Furnace (Pipeless Warm Air Furnace / Recessed Heater / Gravity Furnace)",
  "csio:L": "Floor Furnace",
  "csio:M": "Water Heaters",
  "csio:N": "None",
  "csio:O": "Other",
  "csio:P": "Geo Thermal/Heat Pump",
  "csio:Q": "Wall Furnace",
  "csio:R": "DEPRECATED Woodstove, not Airtight",
  "csio:S": "Space Heater",
  "csio:T": "DEPRECATED Wood Furnace",
  "csio:U": "DEPRECATED Wood Furnace Add On",
  "csio:V": "DEPRECATED Stove",
  "csio:W": "DEPRECATED Wood Burning Unit -Indoor",
  "csio:X": "DEPRECATED Solid Fuel Heating Unit-Outdoor",
  "csio:Y": "DEPRECATED Wood/Oil Combination",
  "csio:Z": "Coal Burning Stove, Magazine Type",
};
export const CSIO_ROOF_MATERIAL: { [Key: string]: string } = {
  "csio:997": "Unknown",
  "csio:998": "None/Not Applicable",
  "csio:999": "Other",
  "csio:A": "Asphalt Shingles",
  "csio:B": "Aluminium",
  "csio:C": "Clay Tile",
  "csio:D": "Laminated Shingles",
  "csio:E": "Elastomeric",
  "csio:F": "Mineral Fiber Shakes",
  "csio:G": "Green Roof",
  "csio:L": "Corrugated Steel",
  "csio:M": "Metal Tile",
  "csio:N": "Concrete Tile",
  "csio:O": "Other Tile",
  "csio:P": "Plastic",
  "csio:R": "Asphalt Roll",
  "csio:S": "Slate Tile",
  "csio:T": "Tar and Gravel",
  "csio:U": "Rubber",
  "csio:W": "Wood Shakes",
  "csio:X": "Wood Shingles",
};
export const CSIO_WIRING_TYPE: { [Key: string]: string } = {
  "csio:1": "Aluminium",
  "csio:2": "Copper",
  "csio:3": "Knob and Tube",
  "csio:4": "Mixed",
  "csio:9": "Other ",
  "csio:998": "None - Not Wired",
  "csio:0": "Unknown",
  "csio:CMB": "Combination - Aluminum and Copper",
  "csio:MCD": "Metal Clad",
  "csio:NMS": "Non-metallic Sheathed",
};
export const CSIO_ELECTRICAL_PANEL: { [Key: string]: string } = {
  "csio:1": "Aluminium",
  "csio:2": "Copper",
  "csio:3": "Knob and Tube",
  "csio:4": "Mixed",
  "csio:9": "Other ",
  "csio:998": "None - Not Wired",
  "csio:0": "Unknown",
  "csio:CMB": "Combination - Aluminum and Copper",
  "csio:MCD": "Metal Clad",
  "csio:NMS": "Non-metallic Sheathed",
};

export const CSIO_NO_OF_STOREYS: { [Key: string]: string } = {
  "csio:10": "(1.0) 1 Storey",
  "csio:15": "(1.5) 1 Storeys",
  "csio:20": "(2.0) 2 Storeys",
  "csio:25": "(2.5) 2 Storeys",
  "csio:30": "(3.0) 3 Storeys",
  "csio:BL": "Bi-level",
  "csio:DX": "Duplex",
  "csio:HR": "High Rise",
  "csio:IR": "Inside Row",
  "csio:OT": "Other",
  "csio:RH": "Row Housing/Townhouse End Row",
  "csio:SD": "Semi-detached",
  "csio:TL": "Tri-level",
  "csio:TX": "Triplex",
};
export const CSIO_NATURE_OF_INTEREST: { [Key: string]: string } = {
  "csio:AC": "Accounting Contact",
  "csio:AD": "Additional Interest",
  "csio:AF": "Attorney in Fact",
  "csio:AI": "Additional Insured",
  "csio:AL": "Additional Lessor",
  "csio:AO": "Actual Owner",
  "csio:AS": "Assignee",
  "csio:BD": "Broker/Dispatcher",
  "csio:BO": "Building Owner",
  "csio:CH": "Certificate Holder",
  "csio:CN": "Canadian",
  "csio:CO": "Corporate Owner",
  "csio:COH": "Cohabitant/Co-Occupant",
  "csio:ES": "Escrow Corporation",
  "csio:EX": "Executor",
  "csio:FC": "Finance Company",
  "csio:IC": "Inspection Contact",
  "csio:LC": "Line of Credit",
  "csio:LH": "Lienholder",
  "csio:LP": "Loss Payee",
  "csio:LR": "Lessor",
  "csio:MG": "Mortgagee (Financial Institution)",
  "csio:MO": "Mortgagee (Other)",
  "csio:MS": "Mortgage Servicing Agency",
  "csio:MU": "Municipality",
  "csio:OT": "Other",
  "csio:PI": "Both Loss Payee and Additional Insured",
  "csio:RO": "Registered Owner",
  "csio:SL": "Secured Loss Payee",
  "csio:TN": "Tenant",
  "csio:TO": "Taxi Plate Owner",
};

export const CSIO_LINE_OF_BUSINESS_SUBCODE: { [Key: string]: string } = {
  "csio:AREC": "Accounts Receivable",
  "csio:BAIL": "Bailees Risk",
  "csio:BOAT": "Boat, Yacht, Watercraft & Equipment",
  "csio:BUSI": "Business Interruption",
  "csio:COND": "Condominium",
  "csio:CRIM": "Crime",
  "csio:CROP": "Crop",
  "csio:EDP": "Computer/EDP",
  "csio:ELIA": "Employer's Liability",
  "csio:EQUI": "Equipment Floater",
  "csio:FIDE": "Fidelity",
  "csio:FIRE": "Fire and EC (including Rental Dwelling)",
  "csio:FLOO": "Flood",
  "csio:GA": "Group Life and Health",
  "csio:GARA": "Garage Keeper's Legal Liability",
  "csio:GDIS": "Group Disability",
  "csio:GLAS": "Blanket Exterior Glass",
  "csio:GLIF": "Group Life ",
  "csio:GMED": "Group Medical",
  "csio:HLDTR": "Holiday Trailers",
  "csio:HOME": "Homeowners",
  "csio:IA": "Individual Accident & Health",
  "csio:IDIS": "Individual Disability",
  "csio:IMED": "Individual Medical",
  "csio:INST": "Installation Floater",
  "csio:LIQV": "Liquor Liability",
  "csio:LIVE": "Livestock",
  "csio:MISC": "Miscellaneous Property Floater",
  "csio:MOBHO": "Mini-Mobile Homes",
  "csio:MOBL": "Mobile Home",
  "csio:OCA": "Ocean Marine Cargo",
  "csio:OHU": "Ocean Marine Hull",
  "csio:OLI": "Ocean Marine Liability",
  "csio:OTHR": "Other",
  "csio:PAF": "Personal Articles Floater",
  "csio:PROD": "Products Liability",
  "csio:PROF": "Professional Liability",
  "csio:RNTCN": "Rented Condo",
  "csio:SCDHO": "Secondary Homeowners",
  "csio:SCHG": "Scheduled Glass",
  "csio:SEAS": "Seasonal Dwelling",
  "csio:STOC": "Stock (Reporting Form)",
  "csio:SURE": "Surety Bond",
  "csio:TENA": "Tenant",
  "csio:TERM": "Term Life, Credit Life, Mortgage Redemption",
  "csio:TRAN": "Transportation and Cargo",
  "csio:VALP": "Valuable Papers and Records",
  "csio:WARE": "Bailees Risk",
  "csio:WHOL": "Whole Life",
};
export const CSIO_LINE_OF_BUSINESS: { [Key: string]: string } = {
  "csio:1": "DEPRECATED Direct Damage (Property)",
  "csio:3": "DEPRECATED Non-owned Automobile",
  "csio:4":
    "DEPRECATED Property and Liability (Excluding Non-owned Automobile)",
  "csio:5":
    "DEPRECATED  Property and Liability (Including Non-owned Automobile)",
  "csio:6": "DEPRECATED Glass",
  "csio:7": "DEPRECATED Crime",
  "csio:8": "DEPRECATED Fidelity",
  "csio:9": "DEPRECATED Umbrella Liability - Commercial",
  "csio:10": "Deprecated Umbrella Liability - Personal",
  "csio:11": "DEPRECATED Errors and Omissions",
  "csio:12": "DEPRECATED Directors and Officers",
  "csio:13": "DEPRECATED - Automobile",
  "csio:14": "DEPRECATED Surety",
  "csio:15": "DEPRECATED Inland Marine",
  "csio:16": "DEPRECATED Wrap-up Liability",
  "csio:17": "DEPRECATED Builders Risk",
  "csio:18": "DEPRECATED Computer/EDP",
  "csio:19": "DEPRECATED Farm - Personal",
  "csio:20": "DEPRECATED Farm - Commercial",
  "csio:21": "DEPRECATED Commercial Fleet Automobile",
  "csio:22": "DEPRECATED Commercial Garage Automobile",
  "csio:23": "DEPRECATED Business Interruption",
  "csio:24": "DEPRECATED Equipment Breakdown",
  "csio:2": "DEPRECATED General Liability",
  "csio:AUTO": "Private Passenger Auto",
  "csio:BI": "Business Interruption",
  "csio:Cargo": "DEPRECATED Cargo",
  "csio:CAUTO": "Individually Rated Commercial Automobile",
  "csio:CGL": "Commercial General Liability",
  "csio:COMM": "Commercial Lines - Miscellaneous ",
  "csio:CPROP": "Property - Commercial",
  "csio:CRIME": "Crime",
  "csio:CUMB": "Umbrella Liability - Commercial",
  "csio:DANDO": "Directors and Officers",
  "csio:EANDO": "Errors and Omissions",
  "csio:FARM": "Farm Owners, Fire, Liability",
  "csio:FIDEL": "Fidelity",
  "csio:FLEET": "Commercial Fleet Automobile",
  "csio:GARG": "Commercial Garage Automobile",
  "csio:GL": "General Liability",
  "csio:HABL": "Habitational",
  "csio:Liability": "DEPRECATED Liability",
  "csio:LIFE": "Life and Health - Miscellaneous",
  "csio:MARIN": "Inland Marine",
  "csio:NOA": "Non-owned Automobile",
  "csio:PACK": "Package Policy - Commercial",
  "csio:PERS": "Personal Lines - Miscellaneous ",
  "csio:Property": "DEPRECATED Property",
  "csio:PUMB": " Umbrella Liability - Personal",
  "csio:SURTY": "Surety  ",
  "csio:WUP": "Wrap-up Liability",
};
export const CSIO_INSURANCE_COMPANY_TYPE: { [Key: string]: string } = {
  ABI: "Promutuel L'Abitibienne",
  ABS: "Abstainers",
  ACAS: "Aetna Canadian C & S",
  ACEIN: "ACE INA Insurance",
  ADV: "DEPRECATED Advocate",
  AEG: "Aegon (UK)",
  AET: "Aetna Casualty (Laurentian)",
  AETN: "Aetna Ins.(CIGNA)",
  AFM: "Antigonish Farmers' Mutual",
  AG: "Advocate General",
  AGR: "Allianz Global Risks US Insurance Company ",
  AIM: "Amherst Island Mutual",
  ALB: "Albion",
  ALG: "Algoma Mutual",
  ALH: "L'Alpha ",
  ALL: "Allstate",
  ALP: "Alpina",
  ALZ: "Allianz",
  AMA: "Alberta Motor",
  AMH: "American Home",
  AMM: "American Mutual",
  AMR: "American Road",
  ANAT: "Assurnat",
  ANG: "Anglo-Gibraltar",
  APP: "Promutuel Appalaches-St-François",
  APR: "APRIL Canada Inc.",
  ARC: "Arch Insurance Company Ltd.",
  ASC: "Ascentus",
  ASN: "Ass. Nationale (National Ins.)",
  ATL: "Atlantic",
  AXA: "AXA",
  AXL: "AXA XL",
  AYR: "Ayr Mutual",
  BAG: "Promutuel Bagot ",
  BAY: "DEPRECATED Bay City",
  BCAA: "BCAA",
  BCIC: "British Columbia",
  BCM: "Bertie & Clinton Mutual",
  BEA: "Promutuel Beauce-Etchemins",
  BEH: "Promutuel Haut St-Laurent (formerly Beauharnois Mutual)",
  BEL: "BELAIRdirect / Belair",
  BEM: "Promutuel Bellechasse Lévis",
  BEO: "Beothic General",
  BEN: "Beneva Insurance",
  BIC: "Promutuel de l'Est (formerly Mutuelle Bic)",
  BII: "Boiler Inspection & Insurance",
  BKF: "American Bankers of Florida",
  BLAN: "Blanchard Mutual",
  BOI: "Promutuel Bois-Francs ",
  BOR: "Boreal",
  BOREA: "Promutuel Boreale (formerly Promutuel l'Abitibienne)",
  BOUC: "Bouclier Laurentien",
  BQM: "Bay of Quinte Mutual",
  BRANT: "Brant Mutual",
  CAA: "CAA",
  CAB: "Cabot",
  CALF: "Calvert Fire",
  CAMIM: "Canadian Millers Mutual",
  CAN: "Canassurance",
  CANA: "Canada A & F",
  CANL: "Primmum (formerly Canada Life Casualty)",
  CANN: "Canners Exchange",
  CANW: "Canada West",
  CAP: "La Capitale",
  CARD: "DEPRECATED Caradoc Delaware Mutual",
  CARL: "Carleton Mutual",
  CAS: "Casualty",
  CAY: "Cayuga Mutual",
  CC: "Canadian Commerce",
  CCICO: "Continental Insurance Company",
  CCINC: "Continental Insurance Company of Canada Inc.",
  CCLTD: "Continental Insurance Ltd.",
  CDES: "Caisses Desjardins",
  CDI: "Canadian Direct",
  CENT: "Century",
  CG: "Canadian General",
  CGUI: "Aviva ",
  CHAPP:
    "Promutuel Chaudière - Appalaches (formerly Promutuel Beauce - Etchemins)",
  CHAT: "Chateau",
  CHL: "Promutuel Charlevoix-Montmorency",
  CHM: "Canadian Home",
  CHR: "DaimlerChrysler",
  CHUB: "Chubb",
  CI: "Canadian Indemnity",
  CIBC: "Certas Direct ",
  CIC: "Travelers Essential",
  CIGN: "Cigna",
  CIR: "Canadian Industrial Risk",
  CIT: "Citadel",
  CLA: "DEPRECATED Clairvoyants Mutual",
  CLAW: "Canadian Lawyers Liability Assurance Society",
  CM: "Chartis Insurance Company of Canada (formerly known as Commerce and Industry Insurance Company of Canada and later known as AIG Commerical Insurance Company of Canada)",
  CMF: "Clare Mutual Fire",
  CNA: "Continental Casualty Company ",
  CNPIE: "Community Newspapers Reciprocal Insurance Exchange",
  CNS: "Canadian Northern Shield",
  CNT: "Centennial",
  CNU: "Canadian Universal",
  COA: "Coachman",
  COAT: "Promutuel Coaticook-Sherbrooke",
  COFC: "Co-Operative F & C",
  COG: "COGECO",
  COL: "RSA (formerly Colonial)",
  COMGRP: "The Commonwell Mutual Insurance Group",
  COMM: "Northbridge Indemnity Insurance Corporation (formerly Commonwealth)",
  COMS: "Promutuel Coaticook-Sherbrooke (formerly Compton-Sherbrooke Mutual)",
  CON: "Contingency",
  CONC: "La Concorde",
  CONS: "Constitution",
  CONT: "Continental Canada",
  COOP: "Co-operators",
  COOS: "Les Cooperants",
  CORN: "Cornhill",
  CORO: "Coronation",
  COSE: "Coseco",
  CP: "Canadian Provincial",
  CRUM: "Crum & Forster",
  CTD: "CT Direct (Canada Trust)",
  CTIS: "Canadian Tire Insurance Services",
  CTSUD: "Promutuel Centre-Sud (formerly Promutuel Coaticook-Sherbrooke)",
  CU: "Commercial Union (now Aviva)",
  CUL: "Culross Mutual",
  CUM: "Cumis",
  DESJ: "Groupe Desjardins",
  DEUX: "Promutuel Deux-Montagnes ",
  DRA: "Dave Rochon Assurances Inc.",
  DIC: "Dominion Ins. Corp.",
  DIRP: "Direct Protect",
  DMI: "Halwell Dumfries Mutual Insurance Company (Sheffield)",
  DOC: "Dominion of Canada",
  DOR: "Promutuel Dorchester",
  DOW: "Downie Mutual",
  DRUM: "Promutuel Drummond ",
  DUF: "Dufferin Mutual Insurance Company",
  EAG: "Eagle Star",
  EAST: "DEPRECATED East Mutual (preferred code value is EST)",
  ECC: "Ecclesiastical",
  ECHE: "Echelon",
  ECON: "Economical (formerly Economical Mutual)",
  EGI: "Echelon Insurance",
  EKF: "Ekfrid Mutual",
  ELEC: "Electric Company",
  ELM: "Elma Mutual",
  ELT: "Elite",
  ENAM: "English & American",
  ENCON: "ENCON Group",
  ENG: "English & American",
  EQ: "Equitable General",
  ERI: "Erie Mutual",
  EST: "Promutuel de L'Est ",
  ESTR: "Estrie-Richelieu",
  ESTU: "Promutuel de l'Estuaire",
  ETN: "Eaton",
  EVER: "Everest",
  FA: "Facility - The Association",
  FAAL: "Facility - Allstate",
  FAALZ: "Facility - Allianz",
  FACG: "Facility - Canadian General",
  FACP: "Facility - Co-operators",
  FACS: "Facility - Canadian Surety",
  FACT: "Factory Ins. Association",
  FACU: "Facility - Commercial Union",
  FACW: "Facility - Canada West",
  FADOC: "Facility - Dominion of Canada",
  FAGU: "Facility - Guardian",
  FAHAL: "Facility - Nordic (formerly  Facility - Halifax)",
  FALLD: "Facility - Lloyd's",
  FALM: "Facility - Liberty Mutual",
  FAM: "Factory Mutual",
  FANFL: "Facility - Insurance Corp.of Newfoundland",
  FANS: "Facility - Nova Scotia General",
  FAPRU: "Facility - Prudential",
  FAR: "Farm Mutual Reinsurance",
  FARM: "Farmers Mutual (Lindsay)",
  FARO: "Facility - Royal & SunAlliance",
  FASA: "Facility - Sun Alliance",
  FASE: "Facility - Simcoe & Erie",
  FASF: "Facility - State Farm",
  FAUN: "Facility - Unifund",
  FAWL: "DEPRECATED Facility - Wellington Facility - Nordic Western Region (formerly Facility - Western Union)",
  FAWU: "Facility - Nordic Western Region (formerly Facility - Western Union)",
  FED: "Federal",
  FEDC: "Federation",
  FEDM: "Federated",
  FF: "Fireman's Fund",
  FPI: "Four Points Insurance",
  FIC: "Family Insurance Company",
  FIRE: "The Fire",
  FIRST: "First North American",
  FM: "Trillium Mutual (formerly Formosa Mutual)",
  FNAT: "First National",
  FOR: "Foresters Indemnity",
  FRO: "Promutuel Monts et Rives (formerly Frontenac Mutual)",
  FROY: "Royal Facility",
  FSCA: "Groupement des Assureurs (F.S.C.A.)",
  FUND: "Fundy Mutual",
  GA: "General Accident (now Aviva)",
  GAN: "Gan Incendie",
  GAR: "Garante",
  GAS: "Promutuel Gaspésie - les Îles",
  GAUM: "Global Aerospace Underwriting Managers",
  GBM: "Grey & Bruce Mutual",
  GC: "Intact Insurance Company (formerly Intact Insurance Company (Quebec division))",
  GCA: "General Ins. Company of America",
  GECC: "GE Capital Casualty",
  GEN: "General",
  GENK: "Genmark Insurance",
  GER: "Gerling Global",
  GERM: "Germania",
  GIB: "Gibraltar",
  GIS: "Gisco",
  GLEN: "Glengarry Farmers Mutual",
  GMI: "Grenville Mutual",
  GNA: "Intact Insurance (Formerly The Guarantee Company of North America) ",
  GNB: "General Ins. Corp. of New Brunswick",
  GOLD: "Gold Circle",
  GORE: "Gore",
  GRAN: "Grain",
  GRE: "Great Eastern",
  GRP: "Novex Group Insurance",
  GRT: "Great American ",
  GUA: "Guardian",
  HAGR: "Hagerty Canada",
  HAL: "Intact Insurance Company (formerly Intact Insurance Company (Central Atlantic Division)",
  HALW: "Halwell Dumfries Mutual Insurance Company (Guelph)",
  HAM: "Hamilton Twp.",
  HAN: "Hanover",
  HAUT: "Promutuel Haut St-Laurent",
  HAY: "Hay Mutual",
  HELV: "Helvetia Swiss",
  HER: "DEPRECATED Herald",
  HIGH: "Highlands",
  HIROC: "Healthcare Insurance Reciprocal",
  HMI: "Salus Mutual Insurance Company",
  HOME: "Home",
  HOML: "Homeland",
  HOW: "Howick Mutual",
  HRT: "Hartford",
  IAC: "Promutuel Charlevoix-Montmorency (formerly L'Île-aux-Coudres Mutual)",
  ICBC: "Insurance Co. of B.C.",
  ICI: "Ins. Corp. of Ireland",
  ICNA: "Ins. Co. of North America",
  ICNW: "Ins. Corp. of Newfoundland",
  ICPEI: "Ins. Co. of Prince Edward Island",
  IIM: "Independent Ins. Managers",
  IN: "L'Industrielle (Industrial General)",
  INA: "Industrial Alliance",
  ING: "Industrial General",
  INGNB: "DEPRECATED North Brook",
  INNOV: "Innovassur",
  INS: "Promutuel L'Islet ",
  JEI: "JEVCO Insurance Company",
  JEV: "Jevco",
  KAM: "Promutuel Kamouraska-Côte-Nord",
  KAN: "Kansa",
  KE: "Kent & Essex Mutual",
  KEM: "Kemper",
  KENT: "Kent General",
  KEY: "United General",
  KING: "Kings Mutual",
  KNGN: "Kingsway General",
  LA: "L & A Mutual",
  LABI: "Portage La Biche",
  LAC: "Laurentian Casualty",
  LACFL: "Promutuel Du Lac au Fleuve (formerly Promutuel Du Littoral)",
  LAG: "Laurentian General",
  LAM: "Lambton Mutual",
  LAN: "Promutuel Lanaudière ",
  LANA: "Lanark Mutual",
  LANG: "Langdon",
  LAP: "Laurentian Pacific",
  LAU: "Laurentienne Agricole (Laurentian Agricultural)",
  LBM: "Liberty Mutual",
  LEDOR: "Group Ledor Inc. Mutual Insurance",
  LEG: "Legacy",
  LEV: "Promutuel Lévisienne-Orléans ",
  LEX: "L'Exclusive",
  LEY: "L.E. Yingst",
  LGI: "Northbridge Insurance - Commercial (NGIC - Northbridge General Insurance Corporation)",
  LITTO: "Promutuel du Littoral (formerly Promutuel Kamouraska)",
  LLD: "Lloyds, Non-marine",
  LLU: "Lloyd's Underwriters ",
  LNX: "Lennox & Addington",
  LOB: "Lobo Mutual",
  LOC: "London-Canada",
  LOM: "London & Midland",
  LOMB: "Northbridge Personal Insurance Corporation (formerly Lombard)",
  LOMG: "Promutuel Monts et Rives (formerly Lotbinière and Mégantic Mutual)",
  LON: "London Assurance",
  LOT: "Promutuel Lotbinière ",
  LOY: "Loyalist",
  LSJ: "Promutuel Lac au Fjord (formerly Lac St-Jean Mutual)",
  LSP: "Promutuel Lac St-Pierre - Les Forges",
  LTM: "MutualONE Insurance Company (formerly Middlesex Mutual)",
  LUM: "Lumbermens Mutual",
  MAA: "Marine Office of America Corp.",
  MAPL: "Maplex General",
  MAR: "Northbridge Commercial Insurance Corporation (formerly Markel)",
  MARK: "Markham General",
  MAU: "Promutuel Portneuf-Champlain (formerly Mauricienne Mutual)",
  MAX: "MAX Insurance",
  MCG: "MutualONE Insurance Company (formerly Middlesex Mutual)",
  MCK: "MutualONE Insurance Company (formerly McKillop)",
  MENN: "Mennonite Aid Union",
  MET: "Metropolitan",
  METG: "Metro General",
  MILL: "Millennium",
  MISS: "Economical QC (formerly Missisquoi)",
  MITS: "Mitsui Sumitomo ",
  MMA: "Mennonite Mutual (Alberta)",
  MMI: "My Mutual Insurance",
  MON: "Promutuel Montmagny ",
  MOT: "Motors",
  MEAL: "Morin Elliott Associés Ltée.",
  MPIC: "Manitoba Public Ins. Corp.",
  MUF: "Mutual Fire Insurance Co. of B.C.",
  MUN: "Municipal Electric Assoc. Reciprocal Exchange",
  NAP: "Promutuel Les Prairies (formerly Napierville & Laprairie Mutual)",
  NATE: "National Employers Mutual",
  NATG: "National Group",
  NATS: "National Society (Société Nationale)",
  NBG: "National Bank General Insurance Inc.",
  NBM: "North Blenheim Mutual",
  NEWH: "New Hampshire Ins. Group",
  NIA: "Niagara Fire",
  NIPP: "Nippon",
  NKM: "Maple Mutual Insurance",
  NOM: "Norfolk Mutual",
  NONE: "No Previous Insurer",
  NORD: "Nordic Insurance of Canada",
  NORF: "Optimum Insurance Company Inc.",
  NORG: "NorGroupe",
  NORM: "Norman ",
  NORT: "Northumberland",
  NS: "Nova Scotia General",
  NWIDE: "Nationwide",
  NZ: "New Zealand",
  OASI: "Oasis Insurance",
  OC: "Outside Canada",
  OCEAN: "Oceanic Underwriters Ltd.",
  OGA: "Omega",
  OM: "Ontario Municipal Insurance Exchange",
  ONT: "Ontario Mutual",
  OPA: "Optimum Société d'Assurance",
  OPAA: "Optimum Assurance Agricole",
  OPTI: "Optiom Inc.",
  OPTW: "Optimum West",
  OR: "DEPRECATED Orion",
  OREP: "Old Republic",
  OSB: "Ontario School Boards Ins. Exchange",
  OT: "Otter Dorchester",
  OT1: "Miscellaneous - US Company",
  OT2: "Miscellaneous - European Company",
  OT3: "Other",
  OUT: "Promutuel L'Outaouais ",
  OXF: "Heartland Mutual (formerly Oxford Mutual)",
  PAF: "Pafco",
  PAI: "Promutuel Assurance Inc",
  PAS: "Pacific Employers",
  PCF: "President's Choice Financial",
  PCFM: "Pictou County Farmers' Mutual",
  PEA: "Peace Hills",
  PEEL: "Peel Mutual",
  PEI: "PEI Mutual",
  PEMB: "Pembridge",
  PER: "Personal",
  PERT: "Perth",
  PHOE: "DEPRECATED Phoenix",
  PIL: "Pilot",
  PMM: "Edge Mutual Insurance Company (formerly Peel Maryborough Mutual)       ",
  PONF: "Promutuel Portneuf-Champlain (formerly Portneuvienne Mutual)",
  PORT: "Portage La Prairie",
  PPLUS: "PeoplePlus",
  PRA: "Prudential of America",
  PRE: "DEPRECATED Premier",
  PREAM: "Premier Canada Assurance Managers",
  PREC: "Prévoyance",
  PREM: "Premier Marine Insurance Managers",
  PRES: "Prestcott",
  PRET: "Prévoyants",
  PRIME: "Primeguard",
  PRM: "Prairies Mutual",
  PRO: "Protective ",
  PROG: "Progressive",
  PROMR: "Promutuel Monts et Rives",
  PROPC: "Promutuel Portneuf-Champlain",
  PROR: "Promutuel Réassurance",
  PROT: "Protection Universelle (Universal Protection)",
  PRU: "Prudential",
  PRUE: "Prudential of England",
  PRUO: "Prudasco",
  PRYS: "Prysm General Insurance inc.",
  QBE: "QBE Services Inc.",
  QUE: "Compagnie d'Assurance du Québec",
  QUIN: "Bay of Quinte",
  RBC: "RBC Insurance (Royal Bank Canada)",
  REL: "Reliance",
  RICH: "Promutuel Appalaches - St-François (formerly Richmond & Val St-François Mutual)",
  RIV: "Promutuel Rivière-du-Loup",
  RIVER: "Promutuel Des Riverains",
  ROU: "Promutuel Rouyn-Noranda-Témiscamingue ",
  ROY: "Royal & SunAlliance",
  RRVM: "Red River Valley Mutual",
  RVSUD: "Promutuel Rive-Sud (formerly Promutuel Bellechasse Lévis)",
  SAFE: "Safeco",
  SAG: "Promutuel du Lac Fjord (formerly Saguenay Mutual)",
  SAL: "Promutuel Haut St-Laurent (formerly Salaberry Mutual)",
  SAP: "St-Anne des Plaines Mutual",
  SAY: "Scottish & York (formerly S&R)",
  SCOT: "Scottish Dominion",
  SDQ: "Societe Mutuelle Reass. du P.Q.",
  SEA: "Seaboard",
  SEC: "La Sécurité",
  SECN: "Security National",
  SEHM: "South Easthope Mutual",
  SEM: "Southeastern Mutual",
  SEN: "Sentry",
  SENT: "Sécurité Nationale",
  SG: "Scotia General",
  SGI: "SGI CANADA",
  SGIS: "SGI CANADA Insurance Services Ltd.",
  SIMC: "Simcoe & Erie",
  SKM: "Sandbox Mutual Insurance",
  SNA: "Optimum Société d'Assurance ",
  SOC: "Society Mutual Reinsurance",
  SONNE: "Sonnet Insurance",
  SOU: "Promutuel Soulanges ",
  SOV: "Sovereign",
  SSQ: "SSQ, Societe d'ass.",
  STAN: "Stanley Mutual Insurance Company",
  STARR: "Starr Insurance & Reinsurance Limited",
  STEW: "Stewart Smith",
  STF: "State Farm Fire and Casualty",
  STFM: "State Farm Mutual Auto",
  STM: "DEPRECATED La St-Maurice",
  STOM: "Promutuel Coaticook-Sherbrooke (formerly Stanstead-Orford Mutual)",
  STP: "St. Paul",
  STSH: "Stanstead & Sherbrooke",
  SUA: "Stratford Underwriting Agency Inc.",
  SUMI: "Refer to code MITS",
  SUN: "Sun Alliance",
  SURE: "Canadian Surety",
  SWEST: "South Western Insurance Group",
  SWI: "Switzerland",
  SYI: "S&Y Insurance Company",
  SYM: "Symons General",
  TDG: "TD General",
  TEM: "Promutuel Témiscouata ",
  TEMP: "Temple",
  TOG: "Toronto General",
  TOK: "Tokio",
  TOWC: "Town and Country Mutual",
  TOWN: "Caradoc Townsend Mutual Insurance Company",
  TRAC: "Trans Canada Risk",
  TRAD: "Tradition Mutual Insurance Company",
  TRAF: "Trafalgar",
  TRAM: "Trans-America",
  TRAN: "Transit",
  TRAV: "Travelers",
  TRCK: "Truck Ins. Exchange",
  TRCM: "Trans Canada Risk Management",
  TRG: "Traders General",
  TRUH: "TruShield Insurance",
  TRP: "Transport Ins. Co.",
  "TWIG    ": "The Wholesale Insurance Group",
  TWO: "DEPRECATED Two Mountains Mutual (Preferred code value is DEUX)",
  UC: "United Canada",
  UG: "United General ",
  UHM: "Usborne and Hibbert Mutual",
  UNCN: "RSA (formerly L'Union Canadienne)",
  UNI: "Universal Protection",
  UNIF: "Unifund ",
  UNIQ: "L'Unique",
  UNIT: "DEPRECATED Unity",
  UNK: "Unknown (only for use in PPH when unknown)",
  UQ: "Union Québecoise Mutual",
  USA: "A U.S. Company",
  USFG: "USF&G",
  USFI: "United States Fire",
  UTIC: "Utica",
  VAU: "Promutuel Vaudreuil ",
  VER: "Promutuel Verchères",
  VFORG:
    "Promutuel Verchères - Les Forges (formerly Promutuel Lac St-Pierre - Les Forges)",
  VIC: "Victoria",
  VLM: "Promutuel La Vallée",
  VMM: "Promutuel Valmont",
  VSF: "Promutuel Appalaches-St-François (formerly Val St-François)",
  VSL: "Promutuel Vallée du St-Laurent",
  WAB: "Wabisa Mutual",
  WAT: "Economical Select (formerly Waterloo)",
  WAU: "Wausau",
  WAUS: "Employers Ins. of Wausau",
  WAWA: "Wawanesa",
  WELL: "Intact Insurance Company (Specialty Solutions)",
  WEM: "West Elgin Mutual",
  WEP: "Westpar",
  WEST: "Western Assurance ",
  WESW: "West Wawanosh Mutual",
  WFIEL: "Westfield",
  WGMI: "Western General",
  WM: "Westminster Mutual",
  WSL: "Westland",
  WU: "Intact Insurance Company (formerly Intact Insurance Company (Western Division)",
  WUM: "Western Underwriting Managers Ltd",
  XL: "XL",
  XLS: "XL Speciality Insurance Company",
  YAM: "Yamaska Mutual",
  YAR: "Yarmouth Mutual",
  YAS: "Yasuda",
  YORK: "Unica Insurance (formerly York Fire & Casualty)",
  ZEN: "Northbridge Insurance – Personal (Zenith Insurance Company)",
  ZUR: "Zurich",
  WYN: "Wynward Insurance Group",
};
export const CSIO_BUSINESS_TYPE: {
  [key: string]: { type: string; value: string };
} = {
  // POLICY codes
  "csio:ACR": { type: "Policy", value: "ACR - Account Current Reconciliation" },
  "csio:ADJ": { type: "Policy", value: "ADJ - Premium Adjustment" },
  "csio:ARR": { type: "Policy", value: "ARR - Anniversary Re-rating" },
  "csio:BIL": { type: "Policy", value: "BIL - Billing Notice" },
  "csio:BIR": { type: "Policy", value: "BIR - Billing Information Required" },
  "csio:BPR": { type: "Policy", value: "BPR - Billing Payment Reminder" },
  "csio:BRN": { type: "Policy", value: "BRN - Billing Rejection Notice" },
  "csio:CLI": { type: "Policy", value: "CLI - Claim Information" },
  "csio:CLS": { type: "Policy", value: "CLS - Claim Status Information" },
  "csio:CSQ": { type: "Policy", value: "CSQ - Claims Inquiry" },
  "csio:DBR": { type: "Policy", value: "DBR - Direct Bill Reconciliation" },
  "csio:DSP": { type: "Policy", value: "DSP - Download Setup" },
  "csio:EDT": { type: "Policy", value: "EDT - Electronic Document Transfer" },
  "csio:ERC": { type: "Policy", value: "ERC - Error Correction Notification" },
  "csio:ERP": { type: "Policy", value: "ERP - Error/Purged" },
  "csio:ERR": { type: "Policy", value: "ERR - Error Message" },
  "csio:ERS": { type: "Policy", value: "ERS - Error Suspended Notification" },
  "csio:IVI": { type: "Policy", value: "IVI - Insured Vehicle Information" },
  "csio:LAP": { type: "Policy", value: "LAP - Lapse" },
  "csio:LNT": { type: "Policy", value: "LNT - Loss Notice" },
  "csio:MEM": { type: "Policy", value: "MEM - Electronic Memo" },
  "csio:NBQ": { type: "Policy", value: "NBQ - New Business Quote" },
  "csio:NBS": { type: "Policy", value: "NBS - New Business" },
  "csio:NPD": { type: "Policy", value: "NPD - Non Policy Document" },
  "csio:PAD": { type: "Policy", value: "PAD - Premium Audit" },
  "csio:PCH": { type: "Policy", value: "PCH - Policy Change" },
  "csio:PCQ": { type: "Policy", value: "PCQ - Policy Change Quote" },
  "csio:PMT": { type: "Policy", value: "PMT - Payment" },
  "csio:PNQ": { type: "Policy", value: "PNQ - Policy Inquiry" },
  "csio:POL": { type: "Policy", value: "POL - Policy" },
  "csio:PRT": { type: "Policy", value: "PRT - Print Transaction" },
  "csio:REI": { type: "Policy", value: "REI - Reinstatement" },
  "csio:REW": { type: "Policy", value: "REW - Rewrite" },
  "csio:RIC": { type: "Policy", value: "RIC - Reissue (Change Format)" },
  "csio:RII": { type: "Policy", value: "RII - Reissue (Image Format)" },
  "csio:RNC": { type: "Policy", value: "RNC - Renewal (Change Format)" },
  "csio:RNI": { type: "Policy", value: "RNI - Renewal (Image Format)" },
  "csio:RNR": { type: "Policy", value: "RNR - Reversal of Non-Renewal" },
  "csio:RRC": {
    type: "Policy",
    value: "RRC - Reissue Request (Change Format)",
  },
  "csio:RRQ": { type: "Policy", value: "RRQ - Reissue Request" },
  "csio:RWC": { type: "Policy", value: "RWC - Renewal Change" },
  "csio:RWI": { type: "Policy", value: "RWI - Renewal Notice (Image Format)" },
  "csio:RWL": { type: "Policy", value: "RWL - Renewal" },
  "csio:RWN": { type: "Policy", value: "RWN - Renewal Notice (Change Format)" },
  "csio:RWQ": { type: "Policy", value: "RWQ - Renewal Quote" },
  "csio:RWX": { type: "Policy", value: "RWX - Non-renewal" },
  "csio:SYI": { type: "Policy", value: "SYI - Synchronization - Initial" },
  "csio:SYN": { type: "Policy", value: "SYN - Synchronization Image" },
  "csio:SYR": { type: "Policy", value: "SYR - Synchronization Request" },
  "csio:TBL": {
    type: "Policy",
    value: "TBL - Consolidated/Industry Code Table Maintenance",
  },
  "csio:TRI": {
    type: "Policy",
    value: "TRI - Transaction Image (for information purposes)",
  },
  "csio:WTX": { type: "Policy", value: "WTX - Withdrawal Transaction" },
  "csio:XLN": { type: "Policy", value: "XLN - Cancellation" },
  "csio:XLQ": { type: "Policy", value: "XLQ - Policy Cancellation Quote" },
  "csio:NBQI": { type: "Policy", value: "NBQI - New Business Quote Inquiry" },
  "csio:PCQI": { type: "Policy", value: "PCQI - Policy Change Quote Inquiry" },
  "csio:RQI": { type: "Policy", value: "RQI - Renewal Quote Inquiry" },

  // BILLING EDOCS codes...
  "csio:NBP": {
    type: "Billing",
    value: "NBP - eDoc New Business Payment Schedule",
  },
  "csio:PCP": {
    type: "Billing",
    value: "PCP - eDoc Policy Change Payment Schedule",
  },
  "csio:REP": { type: "Billing", value: "REP - eDoc Renewal Payment Schedule" },
  "csio:RSP": { type: "Billing", value: "RSP - eDoc Reissue Payment Schedule" },
  "csio:RRP": { type: "Billing", value: "RRP - eDoc Rewrite Payment Schedule" },
  "csio:RNP": {
    type: "Billing",
    value: "RNP - eDoc Reinstatement Payment Schedule",
  },
  "csio:NTP": {
    type: "Billing",
    value: "NTP - eDoc Payment Schedule No Policy Transaction",
  },
  "csio:BWR": {
    type: "Billing",
    value: "BWR - eDoc Billing Withdrawal Rejection Notice",
  },
  "csio:BOP": {
    type: "Billing",
    value: "BOP - eDoc Billing Overdue Payment Notice",
  },
  "csio:BFB": {
    type: "Billing",
    value: "BFB - eDoc Billing Final Bill Notice",
  },
  "csio:BRI": { type: "Billing", value: "BRI - eDoc Billing Refund Issued" },
  "csio:BRE": {
    type: "Billing",
    value: "BRE - eDoc Payment Received and Policy Reinstated",
  },
  "csio:BOB": {
    type: "Billing",
    value: "BOB - eDoc Payment Required Outstanding Balance",
  },
  "csio:BCN": {
    type: "Billing",
    value: "BCN - eDoc Billing Collection Notice",
  },
  "csio:BCE": {
    type: "Billing",
    value: "BCE - eDoc Billing Credit Card Expired",
  },

  // CANCELLATION LAPSE EDOCS codes...
  "csio:XRW": {
    type: "Cancellation Lapse",
    value: "XRW - eDoc Insurer Non-Renewal",
  },
  "csio:XOR": {
    type: "Cancellation Lapse",
    value: "XOR - eDoc Insurer Cancellation with Option to Reinstate",
  },
  "csio:XNR": {
    type: "Cancellation Lapse",
    value: "XNR - eDoc Insurer Cancellation with No Option to Reinstate",
  },
  "csio:XRI": {
    type: "Cancellation Lapse",
    value: "XRI - eDoc Cancellation Request by Insured",
  },
  "csio:XIN": {
    type: "Cancellation Lapse",
    value: "XIN - eDoc Cancelled by Insurer",
  },

  // POLICY Transaction EDOCS codes...
  "csio:NBD": {
    type: "Policy Transaction",
    value: "NBD - eDoc New Business Policy Declaration",
  },
  "csio:NBL": {
    type: "Policy Transaction",
    value: "NBL - eDoc New Business Liability Certificates",
  },
  "csio:PCD": {
    type: "Policy Transaction",
    value: "PCD - eDoc Policy Change Policy Declaration",
  },
  "csio:PCL": {
    type: "Policy Transaction",
    value: "PCL - eDoc Policy Change Liability Certificates",
  },
  "csio:RED": {
    type: "Policy Transaction",
    value: "RED - eDoc Renewal Policy Declaration",
  },
  "csio:REL": {
    type: "Policy Transaction",
    value: "REL - eDoc Renewal Liability Certificates",
  },
  "csio:RSD": {
    type: "Policy Transaction",
    value: "RSD - eDoc Reissue Policy Declaration",
  },
  "csio:RSL": {
    type: "Policy Transaction",
    value: "RSL - eDoc Reissue Liability Certificates",
  },
  "csio:RRD": {
    type: "Policy Transaction",
    value: "RRD - eDoc Rewrite Policy Declaration",
  },
  "csio:RRL": {
    type: "Policy Transaction",
    value: "RRL - eDoc Rewrite Liability Certificates",
  },
  "csio:RND": {
    type: "Policy Transaction",
    value: "RND - eDoc Reinstatement Policy Declaration",
  },
  "csio:RNL": {
    type: "Policy Transaction",
    value: "RNL - eDoc Reinstatement Liability Certificates",
  },

  // UNDERWRITING EDOCS codes...
  "csio:UWR": {
    type: "Underwriting",
    value: "UWR - eDoc Underwriting Request",
  },

  // CLAIM EDOCS codes...
  "csio:CON": { type: "Claim", value: "CON - eDoc Claim Opening Notice" },
  "csio:CCN": { type: "Claim", value: "CCN - eDoc Claim Closing Notice" },
  "csio:CAA": { type: "Claim", value: "CAA - eDoc Claim Adjuster Assignment" },
  "csio:CRN": { type: "Claim", value: "CRN - eDoc Claim Auto Rental Notice" },
  "csio:CTL": { type: "Claim", value: "CTL - eDoc Claim Total Loss Notice" },
  "csio:CFD": {
    type: "Claim",
    value: "CFD - eDoc Claim Auto Fault Determination Notice",
  },
  "csio:CPN": { type: "Claim", value: "CPN - eDoc Claim Payment Notice" },
};

export const application_language = [
  { label: "E", value: "E" },
  { label: "F", value: "F" },
];

export const agency_direct_bill = [
  { label: "A", value: "Agency Billed" },
  {
    label: "D",
    value: [
      "Company Policy Billed ('Direct Bill Account')",
      "Company Account Billed",
    ],
  },
];

export const CSIO_DATA_LIST = {
  GenderCd: [
    { label: "Female", value: "F" },
    { label: "Male", value: "M" },
    { label: "Unknown", value: "U" },
    { label: "Unspecified", value: "X" },
  ],
  MaritalStatusCd: [
    { label: "Common-law", value: "csio:C" },
    { label: "Divorced", value: "csio:D" },
    { label: "Married", value: "csio:M" },
    { label: "Separated", value: "csio:P" },
    { label: "Single", value: "csio:S" },
    { label: "Widowed", value: "csio:W" },
  ],
  VehBodyTypeCd: [
    { label: "Ambulance", value: "csio:AB" },
    { label: "Ambulance (DEPRECATED)", value: "csio:AM" },
    { label: "Bus", value: "csio:BU" },
    { label: "Catering Truck", value: "csio:CG" },
    { label: "Compactor", value: "csio:CP" },
    { label: "Casket Wagon", value: "csio:CW" },
    { label: "Dump Truck", value: "csio:DU" },
    { label: "Fork Lift", value: "csio:FL" },
    { label: "Hearse", value: "csio:HE" },
    { label: "Lugger", value: "csio:LG" },
    { label: "Limousine", value: "csio:LI" },
    { label: "Mini Van", value: "csio:MV" },
    { label: "Other/Multi-Purpose Vehicle", value: "csio:OT" },
    { label: "Pickup Truck", value: "csio:PV" },
    { label: "Ready Mix Truck", value: "csio:RM" },
    { label: "Rolloff Truck", value: "csio:RO" },
    { label: "Road Tractor Only", value: "csio:RT" },
    { label: "Road Tractor with Trailer", value: "csio:RW" },
    { label: "Stake or Platform Truck", value: "csio:SP" },
    { label: "Stake or Platform Truck (DEPRECATED)", value: "csio:ST" },
    {
      label: "Sport Utility Vehicle - Small and Medium (Hard Top)",
      value: "csio:SU",
    },
    { label: "Sport Utility Vehicle - Large", value: "csio:SW" },
    { label: "Truck - Heavy", value: "csio:T1" },
    { label: "Truck - Medium", value: "csio:T2" },
    { label: "Truck - Light", value: "csio:T3" },
    { label: "Tank Truck Non-pressurized", value: "csio:TA" },
    { label: "Tank Truck Pressurized", value: "csio:TK" },
    { label: "Tow Truck with Platform", value: "csio:TP" },
    { label: "Farm Tractor", value: "csio:TR" },
    { label: "Tow Truck with Wrecker", value: "csio:TW" },
    { label: "Taxi", value: "csio:TX" },
    { label: "Van - Heavy", value: "csio:VH" },
    { label: "Van - Light", value: "csio:VL" },
    { label: "3 Wheel Motorcycle", value: "csio:28" },
    { label: "3 Wheel Scooter", value: "csio:29" },
    { label: "Adventure", value: "csio:30" },
    { label: "Cabin/Home - Fifth Wheel", value: "csio:31" },
    { label: "Class A", value: "csio:32" },
    { label: "Class B", value: "csio:33" },
    { label: "Class C", value: "csio:34" },
    { label: "Cruiser", value: "csio:35" },
    { label: "Deep snow / Mountain", value: "csio:36" },
    { label: "Dual Purpose", value: "csio:37" },
    { label: "Enduro", value: "csio:38" },
    { label: "Heavy Duty", value: "csio:39" },
    { label: "Hybrid / Crossover", value: "csio:40" },
    { label: "Junior", value: "csio:41" },
    { label: "Lite", value: "csio:42" },
    { label: "Monoski", value: "csio:43" },
    { label: "Motard / Super Motard", value: "csio:44" },
    { label: "Naked Sport", value: "csio:45" },
    { label: "Off Road", value: "csio:46" },
    { label: "Off Road Motocross", value: "csio:47" },
    { label: "Off Road Trials", value: "csio:48" },
    { label: "Performance", value: "csio:49" },
    { label: "Recreational", value: "csio:50" },
    { label: "Recreational Utility", value: "csio:51" },
    { label: "Sport", value: "csio:52" },
    { label: "Sport Touring", value: "csio:53" },
    { label: "Standard", value: "csio:54" },
    { label: "Standard Sidecar", value: "csio:55" },
    { label: "Touring", value: "csio:56" },
    { label: "Trail / Sport", value: "csio:57" },
    { label: "Unknown", value: "csio:58" },
    { label: "Utility", value: "csio:59" },
    { label: "Entry Sport", value: "csio:64" },
    { label: "Entry Naked Sport", value: "csio:65" },
    { label: "Super Sport", value: "csio:66" },
    { label: "Naked Super Sport", value: "csio:67" },
    { label: "All Terrain Cycle", value: "csio:A2" },
    { label: "All Terrain Tricycle", value: "csio:A3" },
    { label: "All Terrain Vehicle (Four Wheel)", value: "csio:A4" },
    { label: "Antique Auto (Regular Plate)", value: "csio:AA" },
    { label: "Antique Auto (Historic Plate)", value: "csio:AH" },
    { label: "Amphibious Vehicle", value: "csio:AM" },
    { label: "All Terrain Vehicle", value: "csio:AT" },
    { label: "Detachable Camper Body", value: "csio:CB" },
    { label: "Classic/Customized Auto", value: "csio:CL" },
    { label: "Camper Van", value: "csio:CV" },
    { label: "Dune Buggy", value: "csio:DB" },
    { label: "Golf Cart", value: "csio:GC" },
    { label: "Kit Car", value: "csio:KT" },
    { label: "Motorized Bicycle", value: "csio:MB" },
    { label: "Motorcycles over 50 cc", value: "csio:MC" },
    { label: "Motor Home (Recreational Use)", value: "csio:MH" },
    { label: "Mini-bike, Trail Bike (Off Road)", value: "csio:MK" },
    { label: "Moped", value: "csio:MP" },
    { label: "Motorized Scooter", value: "csio:MS" },
    { label: "Motorized Tricycle", value: "csio:MT" },
    { label: "Motorcycle with Sidecar", value: "csio:MW" },
    { label: "Race Car", value: "csio:RC" },
    { label: "Snowmobile", value: "csio:SM" },
    { label: "Snowmobile with Sled", value: "csio:SS" },
    { label: "Travelling Motor Home (Primary Residence)", value: "csio:TM" },
    { label: "Jeep", value: "csio:JP" },
    { label: "Mini Van/Private Passenger", value: "csio:MV" },
    { label: "Private Passenger - Convertible", value: "csio:P1" },
    { label: "Private Passenger - 2 Door Sedan/Hard Top", value: "csio:P2" },
    { label: "Private Passenger - 3 Door Hatchback", value: "csio:P3" },
    { label: "Private Passenger - 4 Door Sedan/Hard Top", value: "csio:P4" },
    { label: "Private Passenger - 4 Door Hatchback", value: "csio:P5" },
    { label: "Private Passenger - Station Wagon", value: "csio:P6" },
    { label: "Pickup Truck", value: "csio:PV" },
    {
      label: "Sport Utility Vehicle - Small Convertible (Soft Top)",
      value: "csio:ST",
    },
    {
      label: "Sport Utility Vehicle - Small and Medium (Hard Top)",
      value: "csio:SU",
    },
    { label: "Sport Utility Vehicle - Large", value: "csio:SW" },
    { label: "Van - Light", value: "csio:VL" },
    { label: "Bike / Mini-Bike Trailer", value: "csio:1" },
    { label: "Mix In Transit Trailer", value: "csio:11" },
    { label: "Office Trailer", value: "csio:12" },
    { label: "Pup Trailer", value: "csio:13" },
    { label: "Semi-trailer Extra Trailer", value: "csio:14" },
    { label: "Stake/Platform Trailer Hauled", value: "csio:15" },
    { label: "Stake/Platform Trailer Train", value: "csio:16" },
    { label: "Tank Trailer Hauled As Train", value: "csio:17" },
    { label: "Tank Trailer Hauled Separately", value: "csio:18" },
    { label: "Trailer - Other Load Carrying", value: "csio:20" },
    {
      label: "Trailer With Premises Exposure (e.g. Showroom, etc.)",
      value: "csio:21",
    },
    { label: "Van Trailer Hauled As Train", value: "csio:23" },
    { label: "Van Trailer Hauled Separately", value: "csio:24" },
    { label: "Watercraft Trailer", value: "csio:25" },
    { label: "Snowmobile Trailer", value: "csio:26" },
    { label: "Livestock Trailer", value: "csio:27" },
    { label: "Converter Dolly", value: "csio:3" },
    { label: "Dump Trailer Hauled Separately", value: "csio:4" },
    { label: "Dump Trailer Hauled As Train", value: "csio:5" },
    { label: "Equipment Trailer Hauled Separately", value: "csio:6" },
    { label: "Equipment Trailer Hauled As Train", value: "csio:7" },
    { label: "Gooseneck Trailer", value: "csio:8" },
    { label: "Horse Trailer", value: "csio:9" },
    { label: "Other Trailer", value: "csio:99" },
    { label: "Cabin or Home Trailer", value: "csio:CT" },
    { label: "Farm Trailer", value: "csio:FT" },
    { label: "Common Trailer (uses tongue and/or hitch)", value: "csio:NT" },
    { label: "Tent Trailer", value: "csio:TT" },
    { label: "Utility Trailer", value: "csio:UT" },
  ],
  PolicyStatusCd: [
    { label: "New Business Quote", value: "csio:NBQ" },
    { label: "New Business ", value: "csio:NBS" },
    { label: "Renewal", value: "csio:RWL" },
  ],
  BillingMethodCd: [
    { label: "Agence/Broker âge Bille", value: "csio:A" },
    { label: "Company Account Billed", value: "csio:C" },
    { label: `Company Policy Billed ('Direct Bill Account')`, value: "csio:P" },
  ],
  VehUseCd: [
    { label: "Pleasure", value: "csio:P" },
    { label: "Commercial", value: "csio:C" },
    { label: "Commute", value: "csio:W" },
    { label: "Business", value: "csio:B" },
    { label: "Farm", value: "csio:F" },
  ],
  BooleanValue: [
    { label: "NO", value: "0" },
    { label: "YES", value: "1" },
  ],
  NewUsedCd: [
    { label: "New", value: "1" },
    { label: "Used", value: "2" },
    { label: "Demonstrator", value: "3" },
    { label: "Reconstructed", value: "4" },
  ],
  LossCauseCd: [
    { label: "Appliance - Malfunction", value: "csio:501" },
    { label: "Bodily Injury", value: "csio:502" },
    { label: "Burglary", value: "csio:503" },
    { label: "Collapse - Above Ground Pool", value: "csio:504" },
    { label: "Collapse - Building", value: "csio:505" },
    { label: "Collapse - Inground Pool", value: "csio:506" },
    { label: "Collapse - Weight of Ice/Snow", value: "csio:507" },
    { label: "Collapse - Unspecified", value: "csio:508" },
    { label: "Consequential Loss", value: "csio:509" },
    { label: "Credit Card Forgery and Counterfeit Money", value: "csio:510" },
    { label: "Earthquake", value: "csio:521" },
    { label: "Electrical Power Interruption/Power Surge", value: "csio:522" },
    { label: "Entrapment", value: "csio:524" },
    { label: "Fire - Arson/Arson Suspected", value: "csio:537" },
    { label: "Fire - Careless use of Candles/Matches", value: "csio:538" },
    { label: "Fire - Careless Smoking", value: "csio:539" },
    { label: "Fire - Cooking Operations/Grease Fire", value: "csio:540" },
    { label: "Fire - Electrical", value: "csio:541" },
    { label: "Fire - Fireplace/Chimney", value: "csio:542" },
    { label: "Fire - Portable Heater", value: "csio:543" },
    { label: "Fire - Woodstove/Chimney", value: "csio:544" },
    { label: "Fire Department Charges", value: "csio:545" },
    {
      label: "Fire - Improper Storage & Handling of Chemicals/Fuels",
      value: "csio:546",
    },
    { label: "Fire - Unspecified", value: "csio:547" },
    { label: "Forgery", value: "csio:548" },
    { label: "Freezer Contents", value: "csio:549" },
    { label: "Fuel Leakage - Exterior Oil Storage Tank", value: "csio:550" },
    { label: "Fuel Leakage - Interior Oil Storage Tank", value: "csio:551" },
    { label: "Glass Breakage - Accidental", value: "csio:561" },
    { label: "Glass Breakage - Vandalism", value: "csio:562" },
    { label: "Glass Breakage - Unspecified", value: "csio:563" },
    { label: "Hail Damage", value: "csio:564" },
    { label: "Impact - Aircraft", value: "csio:565" },
    { label: "Impact - Land Vehicle, Insured Operator", value: "csio:566" },
    { label: "Impact - Land Vehicle, Third Party Operator", value: "csio:567" },
    { label: "Lightning - Antenna/Dish", value: "csio:568" },
    { label: "Lightning - Chimney", value: "csio:569" },
    { label: "Lightning - Electrical Equipment", value: "csio:570" },
    { label: "Lightning - Unspecified", value: "csio:571" },
    { label: "Loss Assessment Coverage", value: "csio:572" },
    { label: "Lost/Stolen Keys", value: "csio:573" },
    { label: "Mass Evacuation", value: "csio:583" },
    { label: "Mysterious Disappearance", value: "csio:584" },
    { label: "Pollution Damage", value: "csio:585" },
    { label: "Pollution Liability", value: "csio:586" },
    { label: "Prohibited Access by Civil Authority", value: "csio:587" },
    { label: "Property Damage", value: "csio:588" },
    { label: "Riot", value: "csio:589" },
    { label: "Robbery", value: "csio:590" },
    { label: "Sewer Backup", value: "csio:591" },
    { label: "Smoke - Fire in Adjacent Premises/Building", value: "csio:592" },
    { label: "Smoke - Heating/Ventilation Malfunction", value: "csio:593" },
    { label: "Smoke - Unspecified", value: "csio:594" },
    { label: "Tenants Legal Liability", value: "csio:595" },
    {
      label: "Theft/Attempted Theft - Off Premises, Non-vehicle",
      value: "csio:596",
    },
    {
      label: "Theft/Attempted Theft - Off Premises, Vehicle",
      value: "csio:597",
    },
    {
      label: "Theft/Attempted Theft - On Premises, Non-forced Entry",
      value: "csio:598",
    },
    { label: "Transportation - Collision/Upset/Overturn", value: "csio:599" },
    { label: "Transportation - Unspecified", value: "csio:600" },
    {
      label: "Vandalism/Malicious Damage - Building Vacant",
      value: "csio:610",
    },
    { label: "Vandalism/Malicious Damage - Explosion", value: "csio:611" },
    {
      label: "Vandalism/Malicious Damage - Insured Suspect",
      value: "csio:612",
    },
    { label: "Vandalism/Malicious Damage - Unspecified", value: "csio:613" },
    { label: "Voluntary Compensation", value: "csio:614" },
    { label: "Voluntary Medical Payment", value: "csio:615" },
    { label: "Voluntary Property Damage", value: "csio:616" },
    { label: "Water Damage - Ice Build-up on Roof", value: "csio:626" },
    { label: "Water Damage - Unspecified", value: "csio:627" },
    { label: "Water Escape/Rupture - Building", value: "csio:628" },
    { label: "Water Escape/Rupture - Contents", value: "csio:629" },
    {
      label: "Water Escape/Rupture - Eavestrough or Downspout",
      value: "csio:630",
    },
    { label: "Water Escape/Rupture - Freezing Building", value: "csio:631" },
    {
      label: "Water Escape/Rupture - Freezing, Unheated Building",
      value: "csio:632",
    },
    { label: "Water Escape/Rupture - Plumbing System", value: "csio:633" },
    { label: "Water Escape/Rupture - Seepage/Leakage", value: "csio:634" },
    { label: "Water Escape/Rupture - Sewer/Drain", value: "csio:635" },
    { label: "Water Escape/Rupture - Sprinkler System", value: "csio:636" },
    { label: "Water Escape/Rupture - Sump Pump Failure", value: "csio:637" },
    {
      label: "Water Escape/Rupture - Swimming Pool/Equipment",
      value: "csio:638",
    },
    { label: "Water Escape/Rupture - Waterbed", value: "csio:639" },
    { label: "Water Escape/Rupture - Unspecified", value: "csio:640" },
    { label: "Windstorm Damage ", value: "csio:641" },
    { label: "Floater", value: "csio:648" },
    { label: "Home Based Business", value: "csio:649" },
    { label: "Identity theft", value: "csio:650" },
    { label: "Personal accident", value: "csio:651" },
    { label: "Wildfire", value: "csio:652" },
    { label: "Mechanical Breakdown", value: "csio:653" },
    { label: "Glass Scratching or Marring", value: "csio:654" },
    { label: "Other", value: "csio:999" },
    { label: "Backed Into Car", value: "csio:1" },
    { label: "Disobeyed Stop Sign", value: "csio:10" },
    { label: "Disobeyed Yield Sign", value: "csio:11" },
    { label: "Disobeyed Red Light", value: "csio:12" },
    { label: "Exits Parking Lot - Road/Street", value: "csio:13" },
    { label: "Explosion", value: "csio:14" },
    { label: "Fire Damage - Partial", value: "csio:15" },
    { label: "Fire Damage - Total Loss", value: "csio:16" },
    { label: "Flood", value: "csio:17" },
    { label: "Glass/Windshield", value: "csio:18" },
    { label: "Hit & Run", value: "csio:19" },
    { label: "Changed Lanes", value: "csio:2" },
    { label: "Hit Pedestrian", value: "csio:20" },
    { label: "Lightning", value: "csio:21" },
    { label: "Lost Control", value: "csio:22" },
    { label: "Neutral Intersection", value: "csio:23" },
    { label: "Opened Door", value: "csio:24" },
    { label: "Parking Lot", value: "csio:25" },
    { label: "Passing On Shoulder", value: "csio:26" },
    { label: "Pulled Out From Curb", value: "csio:27" },
    { label: "Riot/Civil Commotion", value: "csio:28" },
    { label: "Sideswipe", value: "csio:29" },
    { label: "Collision with Animal", value: "csio:3" },
    { label: "Theft - Entire Vehicle", value: "csio:30" },
    { label: "Theft - Partial/Attempted", value: "csio:31" },
    { label: "Turned Left - Car Approaching", value: "csio:32" },
    { label: "Turned Left - Car Passing", value: "csio:33" },
    { label: "Vandalism", value: "csio:34" },
    { label: "Wind/Hail Carried Spray", value: "csio:35" },
    { label: "Windstorm/Hail", value: "csio:36" },
    { label: "Wrong Way - One-way Street", value: "csio:37" },
    { label: "Minor At-Fault Collision", value: "csio:38" },
    { label: "Collision - Single Vehicle", value: "csio:39" },
    { label: "Collision - Head-on", value: "csio:4" },
    { label: "Collision - Cyclist", value: "csio:40" },
    { label: "Collision – Not at Fault", value: "csio:41" },
    { label: "Collision - Rear-ended", value: "csio:5" },
    { label: "Domestic Pets ", value: "csio:520" },
    { label: "Employee Fraud", value: "csio:523" },
    { label: "Explosion - Propane/Natural Gas Appliance", value: "csio:525" },
    { label: "Explosion - Unspecified", value: "csio:526" },
    { label: "Explosion - Electric Arcing", value: "csio:527" },
    { label: "Financial Fraud - Identity Theft", value: "csio:534" },
    { label: "Fire- Spread from Third Party Property", value: "csio:535" },
    { label: "Falling Object", value: "csio:536" },
    { label: "Collision - Multi-vehicle", value: "csio:6" },
    {
      label:
        "Vandalism/Malicious Damage - Illegal Substance Cultivation and Manufacturing",
      value: "csio:617",
    },
    { label: "Water Damage - Infiltration", value: "csio:642" },
    {
      label:
        "Surface Water - Surface water enters structure at a point at surface of ground from accumulation of heavy rain, etc.",
      value: "csio:643",
    },
    {
      label:
        "Exterior Water Line - Breakage of the exterior fresh/potable water line.",
      value: "csio:644",
    },
    {
      label: "Exterior Sewer Line - Breakage of the exterior sewer line.",
      value: "csio:645",
    },
    {
      label:
        "Ground Water  - Sudden and accidental infiltration of water through a foundation",
      value: "csio:646",
    },
    {
      label:
        "Flood Water - Flood water enters structure at a point at surface of ground from inland water overflow, etc.",
      value: "csio:647",
    },
    { label: "Collision - Insured Vehicle Parked", value: "csio:7" },
    { label: "Collision - Other", value: "csio:8" },
    { label: "Damage Caused by Children/Animals", value: "csio:9" },
  ],
  EngineTypeCd: [
    { label: "Diesel", value: "csio:1" },
    { label: "Electric (Battery)", value: "csio:2" },
    { label: "Gasoline", value: "csio:3" },
    { label: "Natural Gas", value: "csio:4" },
    { label: "Propane (Not-factory Installed)", value: "csio:5" },
    { label: "Propane (Factory Installed)", value: "csio:6" },
    { label: "Solar", value: "csio:7" },
    { label: "Alcohol", value: "csio:8" },
    { label: "Electric (Fuel Cell)", value: "csio:9" },
    { label: "Gasoline and Electric", value: "csio:A" },
    { label: "Other", value: "csio:Z" },
  ],
  PaymentPlanCd: [
    { label: "1 Payment", value: "csio:1" },
    { label: "2 Payments (Equal) - 50% per payment", value: "csio:2" },
    { label: "3 Payments (Equal) - 33.3% per payment", value: "csio:3" },
    { label: "4 Payments (Equal) - 25% per payment", value: "csio:4" },
    {
      label: "3 Payments (50% 1st Payment, 25% for the next 2 Payments)",
      value: "csio:5",
    },
    {
      label: "3 Payments (40% 1st Payment, 30% for the next 2 Payments)",
      value: "csio:6",
    },
    { label: "Down Payment", value: "csio:7" },
    { label: "3 Year (Annual Installments)", value: "csio:8" },
    { label: "3 Year (Lump Sum Payments)", value: "csio:9" },
    { label: "Job by Job Basis", value: "csio:10" },
    { label: "As Required", value: "csio:11" },
    { label: "Holiday", value: "csio:12" },
    { label: "Daily", value: "csio:13" },
    { label: "Weekend", value: "csio:14" },
    { label: "Single Event", value: "csio:15" },
    { label: "Any Policy Change", value: "csio:16" },
    { label: "Hourly", value: "csio:17" },
    { label: "Bi-annually", value: "csio:18" },
    { label: "Infrequent", value: "csio:19" },
    { label: "Irregular", value: "csio:20" },
    { label: "More Than Once a Day", value: "csio:21" },
    { label: "At the Same Time Each Day", value: "csio:22" },
    { label: "Varied Each Day", value: "csio:23" },
    { label: "Nightly", value: "csio:24" },
    { label: "Per Trip", value: "csio:25" },
    { label: "Unannounced (Visits)", value: "csio:26" },
    { label: "Continuous", value: "csio:27" },
    { label: "Not Known", value: "csio:997" },
    { label: "Always (any change)", value: "csio:A" },
    { label: "Annual", value: "csio:AN" },
    { label: "Bi-monthly (every 2 months)", value: "csio:BM" },
    { label: "Bi-weekly", value: "csio:BW" },
    { label: "When Issued Only", value: "csio:I" },
    { label: "Monthly", value: "csio:MO" },
    { label: "Never", value: "csio:N" },
    { label: "Non standard", value: "csio:NS" },
    { label: "Occasional", value: "csio:OC" },
    { label: "Other", value: "csio:OT" },
    { label: "Power Failure", value: "csio:PF" },
    { label: "Quarterly", value: "csio:QU" },
    { label: "Renewal Only", value: "csio:R" },
    { label: "Regularly", value: "csio:RG" },
    { label: "Seasonal", value: "csio:S" },
    { label: "Six month Policy Single Payment", value: "csio:S1" },
    { label: "Six month Policy Two Equal Payments", value: "csio:S2" },
    { label: "Six month Policy Three Equal Payments", value: "csio:S3" },
    { label: "Six month Policy Four Equal Payments", value: "csio:S4" },
    { label: "Six Month Policy Six Equal Payments", value: "csio:S5" },
    { label: "Semi annual", value: "csio:SA" },
    { label: "Weekly", value: "csio:WK" },
  ],
  Coverages: [
    { label: `Code Description`, value: "csio:XML CodeValue" },
    {
      label: `NBEF2 - Drive Other Automobiles - Named Person(s), OPCF2 - Providing Coverage When Named Persons Drive Other Automobiles, QEF2 - Drive Other Automobiles - Named Person(s), SEF2 - Drive Other Automobiles - Named Person(s), AB-SEF2 - Designated Operator(s) Drive Other Automobiles Endorsement`,
      value: "csio:2",
    },
    {
      label: `NBEF3 - Drive Government Automobiles, OPCF3 - Drive Government Automobiles, QEF3 - Drive Government Automobiles, SEF3 - Drive Government Automobiles, AB-SEF3 - Drive Government Automobile Endorsement`,
      value: "csio:3",
    },
    {
      label: `ASEF5 - Permission to Rent or Lease (Specified Lessee), OPCF5 - Permission to Rent or Lease Automobiles and Extending Coverage to the Specific Lessee(s), SEF5 - Permission to Rent or Lease, EEF5 Permission to Rent or Lease`,
      value: "csio:5",
    },
    {
      label: `NBEF7 - Separate Limits (Third Party Liability), OPCF7 - Separate Limits (Liability), SEF7 - Separate Limits (Third Party Liability)`,
      value: "csio:7",
    },
    {
      label: `ASEF8 - Property Damage Reimbursement (Section A Only), OPCF8 - Property Damage Reimbursement, QEF8 - Deductible Property Damage, SEF8 - Property Damage Reimbursement (Section A Only)`,
      value: "csio:8",
    },
    {
      label: `NBEF9 - Marine Use Excluded (Amphibious Vehicles), OPCF9 - Marine Use Excluded (Amphibious Automobiles), QEF9 - Marine Use Excluded (Amphibious Automobiles), SEF9 - Marine Use Excluded (Amphibious Vehicles), AB-SEF9 - Marine Use Excluded Endorsement (Amphibious Vehicles)`,
      value: "csio:9",
    },
    { label: `SEF10 - Limitation of coverage`, value: "csio:10" },
    { label: `SEF11 - Change of Coverages (Section C)`, value: "csio:11" },
    { label: `SEF12 - Additional Coverage`, value: "csio:12" },
    { label: `SEF14 - Addition of Automobile`, value: "csio:14" },
    {
      label: `NBEF16 - Agreement for Suspension of Coverage, OPCF16 - Suspension of Coverage, QEF16 - Suspension for Storage, SEF16 - Agreement for Suspension of Coverage, AB-SEF16 - Suspension of Coverages Endorsement`,
      value: "csio:16",
    },
    {
      label: `NBEF17 - Reinstatement of Coverage, OPCF17 - Reinstatement of Coverage, QEF17 - Reinstatement After Storage, SEF17 - Reinstatement of Coverage, AB-SEF17 - Reinstatement of Coverages Endorsement`,
      value: "csio:17",
    },
    {
      label: `NBEF19 - Limitation of Amount, OPCF19 - Limitation of Amount Paid forLoss or Damage Coverages, QEF19 - Section B - Limitation of Amount, SEF19 - Limitation of Amount, EEF19 Limitation of Amount`,
      value: "csio:19",
    },
    {
      label: `NBEF20 - Loss of Use, OPCF20 - Coverage for Transportation Replacement, QEF20 - Travel Expenses, SEF20 - Loss of Use, EEF20 Loss of Use Coverage, AB-SEF20 - Loss of Use Endorsement`,
      value: "csio:20",
    },
    {
      label: `NBEF22 - Damage to Property of Passengers , OPCF22 - Damage to Property of Passengers, SEF22 - Damage to Property of Passengers, AB-SEF22 - Damage to Property of Passengers Endorsement`,
      value: "csio:22",
    },
    {
      label: `NBEF24 - Fire Apparatus , OPCF24 - Fire Apparatus, QEF24 - Fire Apparatus Limitation, SEF24 - Fire Apparatus, AB-SEF24 - Fire and Rescue Equipment Endorsement`,
      value: "csio:24",
    },
    {
      label: `NBEF25 - Alteration Endorsement, OPCF25 - Replaced by OPCF25A, QEF25 - Modified Declarations, SEF25 - Alteration Endorsement (endorsement changing or correcting statement(s) in the application or changing the rating classification)`,
      value: "csio:25",
    },
    {
      label: `NBEF26 - Disappearing Deductible (All Perils or Collision), OPCF26 - There is no such endorsement, SEF26 - Disappearing Deductible (All Perils or Collision)`,
      value: "csio:26",
    },
    {
      label: `ASEF27 - Legal Liability for Damage to Non-owned Automobile, OPCF27 - Liability for Damage to Non-owned Automobile(s) and Providing Other Coverages When Insured Persons Drive Other Automobiles, QEF27 - Civil Liability Resulting from Damage to Non Owned Automobiles INCLUDING Automobiles Provided by an Employer, SEF27 - Legal Liability for Damage to Non owned Automobiles`,
      value: "csio:27",
    },
    {
      label: `NBEF28 - Reduction of Coverage as Respects Operation by Named Person(s), OPCF28 - Reduction of Coverage for Named Persons, QEF28 - Reduction of Coverage for Named Drivers, SEF28 - Reduction of Coverage as Respects Operation by Named Person(s), EEF28 Excluded Named Person`,
      value: "csio:28",
    },
    {
      label: `NBEF29 - Additional Coverage as Respects Operation by Named Person(s), OPCF29 - Additional Coverage for Named Persons , QEF29 Additional Coverage for Named Drivers, SEF29 - Additional Coverage as Respects Operation by Named Person(s), AB-SEF29 - Named Person(s) Additional Coverage Endorsement`,
      value: "csio:29",
    },
    {
      label: `NBEF30 - Excluding Operation of Attached Machinery, OPCF30 - Excluding Operation of Attached Machinery, QEF30 - Attached Machinery Limitation, SEF30 - Excluding Operation of Attached Machinery`,
      value: "csio:30",
    },
    {
      label: `NBEF31 - Non-owned Equipment, OPCF31 - Non owned Equipment, QEF31 - Non-owned Equipment, SEF31 - Non owned Equipment, AB-SEF31 - Non-Owned Equipment Endorsement `,
      value: "csio:31",
    },
    {
      label: `NBEF32 - Recreational Vehicle, OPCF32 - Use of Recreational Vehicles by Unlicensed Operators, QEF32 - Recreational Vehicle, SEF32 - Recreational Vehicle, AB-SEF32 - Off-Highway Vehicle Endorsement`,
      value: "csio:32",
    },
    {
      label: `QEF33 - Emergency Service Expense Coverage, SEF33 - Accident Benefits Pedestrian Coverage`,
      value: "csio:33",
    },
    {
      label: `QEF34 - Accident Benefits,SEF34 - Accident Benefits Coverage - Insured Person Defined - Custodians Endorsement`,
      value: "csio:34",
    },
    {
      label: `ASEF35 - Emergency Service Expense, OPCF35 - Coverage for Emergency Road Service, SEF35 - Emergency Service Expense, AB-SEF35 - Emergency Service Expense Endorsement`,
      value: "csio:35",
    },
    {
      label: `NBEF36 - Commercial Automobiles Used Exclusively for Pleasure, SEF36 - Commercial Automobile Used Exclusively for Pleasure`,
      value: "csio:36",
    },
    {
      label: `ASEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, SEF37 - Limitation to Automobile Sound and Electronic Communication Equipment, AB-SEF37 - Limitation to Automobile Electronic Accessories and Electronic Equipment Endorsement`,
      value: "csio:37",
    },
    {
      label: `ASEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, OPCF38 - Agreed Limit for Automobile Electronic Accessories and Equipment, SEF38 - Increased Limit, Automobile Sound and Electronic Communication Equipment, AB-SEF38 - Specified Limit(s) – Automobile Electronic Accessories and Electronic Equipment Endorsement`,
      value: "csio:38",
    },
    { label: `SEF39 - Accident Rating Waiver`, value: "csio:39" },
    {
      label: `NBEF40 - Fire and Theft Deductible, OPCF40 - Fire and Theft Deductible, QEF40 - Fire Deductible, SEF40 - Fire and Theft Deductible, AB-SEF40 - Fire and Theft Deductible Endorsement`,
      value: "csio:40",
    },
    {
      label: `QEF41      Endorsement Modifying the Deductibles indicated in the Declarations, OPCF41 - Endorsement Modifying the Deductibles indicated in the Declarations,SEF41 - Endorsement Modifying the Deductibles indicated in the Declarations`,
      value: "csio:41",
    },
    {
      label: `OPCF43 - Removing Depreciation Deduction, EEF43 Replacement Cost Coverage`,
      value: "csio:43",
    },
    {
      label: `NBEF44 - Family Protection, QEF44 - Territory Extension, SEF44 - Family Protection, EEF44 Family Protection Coverage, AB-SEF44 - Family Protection Endorsement `,
      value: "csio:44",
    },
    {
      label: `QEF45 - Warranty Applicable to Theft of the Entire Automobile`,
      value: "csio:45",
    },
    {
      label: `OPCF46 - Pre-determined Income from Self-employment Agreement, EEF46 Notice of Cancellation`,
      value: "csio:46",
    },
    {
      label: `OPCF47 - Agreement Not to Rely on SABS Priority of Payment Rules`,
      value: "csio:47",
    },
    {
      label: `SEF48 - Optional Supplementary Benefits Section B - Accident Benefits (Nova Scotia only)Added Coverage to Offset Tort Deductibles Endorsment (Ontario only)`,
      value: "csio:48",
    },
    {
      label: `OPCF 49 - Agreement Not to Recover for Loss or Damage from an Automobile Collision (for Ontario Automobile Policy OAP 1)`,
      value: "csio:49",
    },
    {
      label: `ASEF62 - Agreement to Provide Coverage Under Section C`,
      value: "csio:62",
    },
    {
      label: `ASEF63 - Loss of Use Motor Home and Vacation Travel Trailer Emergency Accommodation Expense `,
      value: "csio:63",
    },
    { label: `SEF70 - Named Chauffeur Endorsement`, value: "csio:70" },
    {
      label: `AB-SEF71 Owned Automobiles Exclusion Endorsement`,
      value: "csio:71",
    },
    { label: `AB-SEF72 Multiple Alteration Endorsement`, value: "csio:72" },
    {
      label: `AB-SEF73 Financed Automobiles Exclusion Endorsement`,
      value: "csio:73",
    },
    {
      label: `AB-SEF74 Open Lot Pilferage Endorsement - Owned Automobiles`,
      value: "csio:74",
    },
    {
      label: `AB-SEF75 Open Lot Pilferage Endorsement – Customers Automobiles`,
      value: "csio:75",
    },
    {
      label: `AB-SEF76 Additional Insured Endorsement Broad Form`,
      value: "csio:76",
    },
    {
      label: `AB-SEF77 Legal Liability for Comprehensive Damage to Customers’ Automobiles Endorsement (Including Open Lot Pilferage)`,
      value: "csio:77",
    },
    {
      label: `AB-SEF78 Named Person(s) Reduction of Coverage Endorsement`,
      value: "csio:78",
    },
    {
      label: `AB-SEF79 Fire and Theft Deductible Endorsement`,
      value: "csio:79",
    },
    {
      label: `AB-SEF80 Specified Owned Automobile Physical Damage Coverage Endorsement`,
      value: "csio:80",
    },
    { label: `AB-SEF81 Garage Family Protection`, value: "csio:81" },
    { label: `EEF103 Excess Value Endorsement`, value: "csio:103" },
    { label: `EEF114 Restoration Endorsement`, value: "csio:114" },
    { label: `EEF115 Repair Endorsement`, value: "csio:115" },
    { label: `EEF110 Environmental Liability Limitations`, value: "csio:110" },
    { label: `EEF113 Approved Drivers Warranty`, value: "csio:113" },
    { label: `SEF13a - Deletion of Automobile`, value: "csio:13A" },
    { label: `SEF13b - Deleted Coverage `, value: "csio:13B" },
    {
      label: `NBEF13c - Comprehensive Cover - Deletion of Glass, OPCF13c - Restricting Glass Coverage, QEF13c - Comprehensive Cover - Deletion of Glass, SEF13c - Comprehensive Cover Deletion of Glass`,
      value: "csio:13C",
    },
    {
      label: `SEF13d - Comprehensive Cover Limited Glass, AB-SEF13(D) - Limitation of Glass Coverage Endorsement`,
      value: "csio:13D",
    },
    {
      label: `OPCF13h - Deletion of Hail Coverage, SEF13h - Section C - Deletion Hail Coverage, AB-SEF13(H) - Existing Hail Damage - Deletion of Hail Coverage Endorsement`,
      value: "csio:13H",
    },
    { label: `SEF13S Glass Deleted`, value: "csio:13S" },
    {
      label: `SEF15a - Substitution of Automobile (no change in coverage)`,
      value: "csio:15A",
    },
    {
      label: `SEF15b - Combined Substitution of Automobile and Deleted Coverage`,
      value: "csio:15B",
    },
    {
      label: `SEF15c - Combined Substitution of Automobile and Additional Coverage`,
      value: "csio:15C",
    },
    {
      label: `SEF18 - Replacement Cost Endorsement, AB-SEF18 - Replacement Cost Endorsement`,
      value: "csio:18",
    },
    {
      label: `NBEF19a - Valued Automobile(s), OPCF19a - Agreed Value of Automobiles, SEF19a - Valued Automobile(s), EEF19a Stated Value, AB-SEF19(A) - Valued Automobile Endorsement`,
      value: "csio:19A",
    },
    {
      label: `QEF20a - Travel Expenses (Broad Form), EEF20a Liability for Loss of Use`,
      value: "csio:20A",
    },
    {
      label: `OPCF21b - Blanket Fleet Coverage for Ontario Licensed Automobiles, QEF21b - Blanket Basis Fleet (Annual Premium Adjustment), SEF21b - Blanket Basis Fleet, EEF21b Blanket Basis Fleet, AB-SEF21(B) - Blanket Basis Fleet Endorsement`,
      value: "csio:21B",
    },
    {
      label: `SEF21d - Excess Coverage for Blanket Basis Fleet, EEF21d Blanket Excess Value, AB-SEF21(D) - Blanket Basis Fleet Omission Endorsement`,
      value: "csio:21D",
    },
    {
      label: `NBEF23a - Mortgage Endorsement, OPCF23a - Lienholder - For use with OAP No. 1 Owners Form, QEF23a - Notice to Creditor, SEF23a - Mortgage Endorsement, AB-SEF23(A) - Lienholder, Mortgagee or Assignee Endorsement`,
      value: "csio:23A",
    },
    {
      label: `NBEF23b - Mortgage Endorsement (Broad Form), OPCF23b - Mortgage (Broad Form) - For use with OAP No. 1 Owners Form , QEF23b - Creditor Coverage, SEF23b - Mortgage Endorsement (Broad Form), AB-SEF23(B) - Lienholder, Mortgagee Or Assignee Endorsement (Broad Form)`,
      value: "csio:23B",
    },
    {
      label: `NBEF25a - Alteration Endorsement, OPCF25a - Alteration Endorsement, SEF25a - Alteration Endorsement, AB-SEF25(A) - Alteration Endorsement`,
      value: "csio:25A",
    },
    {
      label: `QEF27a - Civil Liability Resulting from Damage to Non owned Automobiles EXCLUDING Automobiles Provided by an Employer, EEF27a Legal Liability for Damage to Non-Owned Automobiles`,
      value: "csio:27A",
    },
    {
      label: `ASEF27B - Legal Liability for Damage to Non-owned Automobile, OPCF27b - Business Operations - Liability for Damage to Non-owned Automobile(s) in Your Care, Custody or Control`,
      value: "csio:27B",
    },
    {
      label: `OPCF28a - Excluded Driver, SEF28a - Reduction of Coverage as Respects to Operation on Airport Property, AB-SEF28(A) - Reduction of Coverage when Automobile Operated on Airport Property Endorsement`,
      value: "csio:28A",
    },
    {
      label: `SEF30a - Excluding Attached Machinery, AB-SEF30(A) Attached Machinery, Apparatus or Equipment Exclusion Endorsement: Sections A.1 and C`,
      value: "csio:30A",
    },
    { label: `EEF32a Snowmobile Underage Operator`, value: "csio:32A" },
    { label: `ATV Underage Operator`, value: "csio:32B" },
    { label: `Motorcycle Underage Operator`, value: "csio:32C" },
    {
      label: `QEF34a - Accident Benefits (Change of Amounts or insured persons - when the insured is an Individual)`,
      value: "csio:34A",
    },
    {
      label: `DEPRECATED QEF34a-b - Accident Benefits (Change of Amounts or insured persons)`,
      value: "csio:34A-B",
    },
    {
      label: `QEF34b Accident Benefits (Change of Amounts or insured persons - when the insured is a  Legal Person, Partnership or Association`,
      value: "csio:34B",
    },
    {
      label: `QEF37a - Modified Coverage on Electronic Equipment - Limitation of Amount`,
      value: "csio:37A",
    },
    {
      label: `QEF37b - Modified Coverage on Electronic Equipment - Exclusion of Equipment`,
      value: "csio:37B",
    },
    {
      label: `SEF39B - Designated Operator Traffic Safety Conviction Rating Waiver Endorsement, AB-SEF39(B) - Designated Operator Minor Traffic Safety Conviction Rating Waiver Endorsement`,
      value: "csio:39B",
    },
    {
      label: `OPCF43a - Removing Depreciation Deduction for Specified Lessee(s), QEF43a - Change to Loss Payment – Partial Loss – New Parts`,
      value: "csio:43A",
    },
    {
      label: `Change to Loss Payment - Partial Loss - Waiver of Depreciation`,
      value: "csio:43B",
    },
    {
      label: `Change to Loss Payment - Total Loss - Agreed Amount`,
      value: "csio:43C",
    },
    {
      label: `Change to Loss Payment - Total Loss - Waiver of Depreciation`,
      value: "csio:43D",
    },
    {
      label: `Change to Loss Payment - Total Loss - Replacement Cost`,
      value: "csio:43E",
    },
    {
      label: `Change to Loss Payment - Total Loss - Increased Settlement`,
      value: "csio:43F",
    },
    { label: `EEF43l Limited Waiver of Depreciation`, value: "csio:43L" },
    { label: `Replacement Cost (Owned)`, value: "csio:43O" },
    {
      label: `NBEF43r - Limited Waiver of Depreciation, SEF43r - Limited Waiver of Depreciation, AB-SEF43R - Limited Waiver of Depreciation`,
      value: "csio:43R",
    },
    {
      label: `ASEFr(L) - Limited Waiver of Depreciation (Specified Lessee), OPCF43r(L) - Limited Waiver of Depreciation (Specified Lessee), SEF43r(L) - Limited Waiver of Depreciation (Specified Lessee), AB-SEF43R(L) Specified Lessee Limited Waiver of Depreciation Endorsement`,
      value: "csio:43RL",
    },
    { label: `Replacement Cost (Specified Lessee)`, value: "csio:43S" },
    {
      label: `QEF 4-41 Endorsement Modifying the Deductibles indicated in the Declarations`,
      value: "csio:4-41",
    },
    // { label: `OPCF44r - Family Protection Coverage`, value: "csio:44R" },
    {
      label: `NBEF4a - Permission to Carry Explosives, OPCF4a - Permission to Carry Explosives, QEF4a - Transportation of Explosives, SEF4a - Permission to Carry Explosives, EEF4a Permission to Carry Explosives, AB-SEF4(A) - Permission to Carry Explosives Endorsement`,
      value: "csio:4A",
    },
    {
      label: `NBEF4B - Permission to Carry Radioactive Material, OPCF4b - Permission to Carry Radioactive Material, QEF4b - Transportation of Radioactive Material, SEF4b - Permission to Carry Radioactive Material, EEF4b Permission to Carry Radioactive Material, AB-SEF4(B) - Permission to Carry Radioactive Materials Endorsement`,
      value: "csio:4B",
    },
    {
      label: `OPCF5a - Replaced by OPCF #5, QEF5a - Lease or Leasing - Amended Q.P.F. No. 1 When Owner and One Lessee Shown as Named Insureds`,
      value: "csio:5A",
    },
    {
      label: `OPCF5b - Permission to Rent or Lease (Specified Lessee and Modified Declarations - Master Policy)`,
      value: "csio:5B",
    },
    {
      label: `NBEF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), OPCF5c - Permission to Rent or Lease (Unspecified Lessees - Short Term Leases Only), QEF5c - Rented Automobile (Unspecified Lessees - Short Term Only), SEF5c - Permission to Rent or Lease, AB-SEF5(C) - Permission to Rent Endorsement`,
      value: "csio:5C",
    },
    {
      label: `Permission To Rent Or Lease The Vehicle For Carsharing (5CS)`,
      value: "csio:5CS",
    },
    {
      label: `NBEF5d - Conversion Coverage (Rented or Leased Automobiles), OPCF5d - Conversion Coverage (Rented or Leased Automobiles), QEF5d - Conversion Coverage (Rented or Leased Automobiles), SEF5d - Conversion Coverage, AB-SEF5(D) - Conversion Coverage Endorsement`,
      value: "csio:5D",
    },
    {
      label: `NBEF6a - Permission to Carry Passengers for Compensation, OPCF6a - Permission to Carry Paying Passengers, SEF6a - Permission to Carry Passengers for Compensation, EEF6a Permission to Carry Passengers, AB-SEF6(A) - Carry Passengers for Compensation or Hire Endorsement`,
      value: "csio:6A",
    },
    {
      label: `NBEF6b - School Bus, OPCF6b - School Bus, SEF6b - School Bus - For use on SPF No. 11, AB-SEF6(B) - School Transportation Endorsement`,
      value: "csio:6B",
    },
    {
      label: `OPCF6c - Public Passenger Vehicles, SEF6c - Public Passenger Vehicles, AB-SEF6(C) - Public Passenger Automobile Enhanced Coverage Endorsement`,
      value: "csio:6C",
    },
    {
      label: `NBEF6d - Driver Training School, OPCF6d - Driver Training School, SEF6d - Driver Training School, AB-SEF6(D) - Driver Training Services Endorsement`,
      value: "csio:6D",
    },
    {
      label: `OPCF6e - No longer in use, SEF6e - Employees Business Use`,
      value: "csio:6E",
    },
    {
      label: `NBEF6f - Public Passenger Vehicles, OPCF6f - Public Passenger Vehicles - Combined Limits, SEF6f - Public Passenger Vehicles, AB-SEF6(F) - Public Passenger Automobile Endorsement`,
      value: "csio:6F",
    },
    {
      label: `O.E.F. 87 - Added Coverage to Offset Tort Deductibles (for Ontario Garage Automobile Policy O.A.P. 4)`,
      value: "csio:87",
    },
    {
      label: `ASEF8a - Property Damage Reimbursement for Operation by Named Person - (Section A Only), QEF8a - Deductible Civil Liability, SEF8a - Property Damage Reimbursement for Operation by Named Person, AB-SEF8(A) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)`,
      value: "csio:8A",
    },
    {
      label: `SEF98B- Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement `,
      value: "csio:98B",
    },
    {
      label: `SEF99- Excluding Long Term Leased Vehicle Endorsement, AB-SEF99- Leased Vehicle Exclusion Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6)`,
      value: "csio:99",
    },
    // { label: `Accident Benefits`, value: "csio:AB" },
    // { label: `Accident Benefits Occasional Driver`, value: "csio:ABOD" },
    { label: `Abuse & Molestation Exclusion`, value: "csio:AbuseMolestExcl" },
    { label: `Attack By Wild Animal`, value: "csio:ABWA" },
    { label: `Attendant Care Benefits`, value: "csio:ACB" },
    { label: `Attendant Care for Occasional Driver`, value: "csio:ACBOD" },
    { label: `Accident`, value: "csio:ACCDNT" },
    { label: `Accounts Receivable`, value: "csio:ACCTS" },
    { label: `Accounts Receivable`, value: "csio:AcctsReceivable" },
    { label: `Accountants, Fees`, value: "csio:ACF" },
    { label: `Actual Cash Value Endorsement`, value: "csio:ACV" },
    {
      label: `Additional Coverages and Limits for High Value Homes`,
      value: "csio:ACLHV",
    },
    { label: `Accidental Death Benefits`, value: "csio:ADB" },
    { label: `Accidental Death and Dismemberment`, value: "csio:ADD" },
    { label: `Additional Exposures`, value: "csio:AdditionalExp" },
    { label: `Additional Insured s`, value: "csio:AdditionalInsured" },
    { label: `Additional Named Insured`, value: "csio:ADDNI" },
    { label: `Additional Rebuilding Cost `, value: "csio:ADDRC" },
    { label: `Additional Residences/Properties/Acreage `, value: "csio:ADDRR" },
    { label: `Additional Locations Rented`, value: "csio:ADRNT" },
    {
      label: `Advertising Liability Extension Endorsement`,
      value: "csio:AdvertisingEnd",
    },
    { label: `After-market Accessories`, value: "csio:AFMAC" },
    {
      label: `Insuring Agreement I - Employee Dishonesty - Form A`,
      value: "csio:AgreementI",
    },
    {
      label: `Insuring Agreement I - Employee Dishonesty - Form B`,
      value: "csio:AgreementIB",
    },
    {
      label: `Insuring Agreement II - Loss Inside the Premises - Broad Form`,
      value: "csio:AgreementII",
    },
    {
      label: `Insuring Agreement II - Loss Inside the Premises - Limited Form`,
      value: "csio:AgreementIIB",
    },
    {
      label: `Insuring Agreement III - Loss Outside the Premises - Broad Form`,
      value: "csio:AgreementIII",
    },
    {
      label: `Insuring Agreement III - Loss Outside the Premises - Limited Form`,
      value: "csio:AgreementIIIB",
    },
    {
      label: `Insuring Agreement IV - Money Orders & Counterfeit Paper Currency`,
      value: "csio:AgreementIV",
    },
    {
      label: `Insuring Agreement V - Depositors Forgery Coverage`,
      value: "csio:AgreementV",
    },
    {
      label: `Insuring Agreement VI - Credit Card Forgery Coverage`,
      value: "csio:AgreementVI",
    },
    {
      label: `Insuring Agreement VII - Safe Deposit Box - Property in Safe Deposit Boxes`,
      value: "csio:AgreementVII",
    },
    {
      label: `Insuring Agreement VII - Safe Deposit Box - Securities in Safe Deposit Boxes`,
      value: "csio:AgreementVIIB",
    },
    { label: `Auto Liability`, value: "csio:AL " },
    { label: `Alarm Warranty`, value: "csio:ALARM" },
    { label: `All Property`, value: "csio:AllProperty" },
    { label: `All Terrain Vehicles - Basic`, value: "csio:ALLTB" },
    { label: `All Terrain Vehicles`, value: "csio:AllTerrainVehicles" },
    { label: `All Terrain Vehicles - Passenger Hazard`, value: "csio:ALLTP" },
    { label: `All Terrain Vehicles - Underage Operator `, value: "csio:ALLTU" },
    { label: `Actual Loss Sustained`, value: "csio:ALS" },
    {
      label: `Additional Living Expense (Homeowners, Tenant and Condominium Form)`,
      value: "csio:ALVE",
    },
    {
      label: `Additional Miscellaneous Equipment - All Risks`,
      value: "csio:AMEAR",
    },
    {
      label: `Additional Miscellaneous Equipment - Named Perils`,
      value: "csio:AMENP",
    },
    {
      label: `Attached Machinery - Property Damage Reimbursement`,
      value: "csio:AMPD",
    },
    { label: `Amphibious Vehicle Liability `, value: "csio:AMPHL" },
    { label: `Annual Adjustment`, value: "csio:ANAD" },
    { label: `Animal Exclusion`, value: "csio:ANEXC" },
    { label: `Animal Collision`, value: "csio:ANIM" },
    { label: `Animal Liability Extension`, value: "csio:ANLE" },
    { label: `Animal Birth`, value: "csio:ANMLBIRTH" },
    { label: `Animal or Bird Collision Deductible`, value: "csio:AOBCL" },
    // { label: `All Perils`, value: "csio:AP" },
    { label: `Apiary`, value: "csio:Apiary" },
    { label: `Accidental Pollution Liability`, value: "csio:APL" },
    // { label: `All Perils Occasional Driver`, value: "csio:APOD" },
    {
      label: `Additional Amount of Personal Property Away From Premises`,
      value: "csio:APPAW",
    },
    { label: `Additional Units`, value: "csio:APPTH" },
    {
      label: `All Risk - Unit Owners Additional Protection`,
      value: "csio:ARAP",
    },
    { label: `Property in Transit - Armored Car`, value: "csio:ARCAR" },
    {
      label: `All Risk - Unit Owners Building Improvements and Betterments Increased Limits`,
      value: "csio:ARIB",
    },
    { label: `All Risk - Loss Assessment Coverage`, value: "csio:ARLA" },
    { label: `All Risk Personal Articles Endorsement`, value: "csio:ARPAE" },
    { label: `All Risk - Personal Property `, value: "csio:ARPP" },
    { label: `All Risk - Produce `, value: "csio:ARPR" },
    { label: `All Risk - Solar Panel`, value: "csio:ARSP" },
    { label: `Asbestos, Mould and Fungi Exclusion`, value: "csio:Asbestos" },
    {
      label: `All Show Windows and Outside Showcases While Closed For Business`,
      value: "csio:ASWCL",
    },
    {
      label: `All Show Windows and Outside Showcases While Open For Business`,
      value: "csio:ASWOP",
    },
    { label: `Antique Tractor Endorsement`, value: "csio:ATRAC" },
    { label: `Antique Tractor Liability Extension`, value: "csio:ATRLI" },
    { label: `Miscellaneous Vehicles - All Risks`, value: "csio:ATVAR" },
    { label: `Miscellaneous Vehicles - Named Perils`, value: "csio:ATVNP" },
    { label: `Auction`, value: "csio:Auction" },
    {
      label: `Deletion of Hail Coverage – without pre-existing damage`,
      value: "csio:AUDHC",
    },
    { label: `DEPRECATED Auditors Fees`, value: "csio:AuditorsFees" },
    { label: `Hail Deductible`, value: "csio:AUHDE" },
    { label: `Average Values`, value: "csio:AVVA" },
    { label: `Bailees Customers`, value: "csio:BAIFL" },
    { label: `Bailees Articles Coverage`, value: "csio:BAILA" },
    { label: `DEPRECATED Bailees Customers Goods`, value: "csio:Bailees" },
    {
      label: `Boiler And Machinery - Actual Loss Sustained`,
      value: "csio:BALS",
    },
    {
      label: `Boiler And Machinery - Ammonia Contamination`,
      value: "csio:BAMO",
    },
    { label: `Base Internal Limits`, value: "csio:BSEIN" },
    { label: `Basic Group I`, value: "csio:BASI" },
    { label: `Basic`, value: "csio:BASIC" },
    { label: `Basic`, value: "csio:BASIC" },
    { label: `Basic Group II`, value: "csio:BASII" },
    { label: `By-Laws - Increase In Cost of Construction`, value: "csio:BCC" },
    {
      label: `By-Laws - Demolition and Debris Removal - Undamaged Portion of Building`,
      value: "csio:BDD",
    },
    { label: `Beauty Parlour`, value: "csio:BeautyParlours" },
    { label: `Bed and Breakfast`, value: "csio:BedAndBreakfast" },
    { label: `Bed & Breakfast`, value: "csio:BEDBR" },
    { label: `Boiler And Machinery - Expediting Expense`, value: "csio:BEXP" },
    { label: `Money and Securities`, value: "csio:BFMS" },
    { label: `Broad Form Property Damage`, value: "csio:BFPropertyDamage" },
    { label: `Accountants’ Fees`, value: "csio:BIACF" },
    { label: `Business Income ALS form`, value: "csio:BIALS" },
    { label: `Contingent Business Interruption`, value: "csio:BICBI" },
    { label: `E-Business Interruption and Extra Expense`, value: "csio:BIEE" },
    { label: `Extra Expenses`, value: "csio:BIEEX" },
    { label: `Business Income Extended form (Profits)`, value: "csio:BIETF" },
    { label: `Fines and Penalties`, value: "csio:BIFAP" },
    { label: `Leasehold Interest`, value: "csio:BILHI" },
    { label: `Mortgage rate guarantee`, value: "csio:BIMRG" },
    { label: `Newly acquired Location`, value: "csio:BINAL" },
    { label: `Negative Publicity`, value: "csio:BINPU" },
    { label: `Off-premises service interruption`, value: "csio:BIOPI" },
    { label: `Ordinary Payroll`, value: "csio:BIOPR" },
    {
      label: `Coverage A - Bodily Injury and Property Damage`,
      value: "csio:BIPD",
    },
    { label: `Restricted Access`, value: "csio:BIRAC" },
    { label: `Rental Income`, value: "csio:BIRIC" },
    {
      label: `Business Income Standard form (Gross Earnings)`,
      value: "csio:BISTF",
    },
    { label: `Utility Service Interruption`, value: "csio:BIUSI" },
    { label: `Blanket Contractual`, value: "csio:BlanketContractual" },
    { label: `Building under construction`, value: "csio:BLDCR" },
    {
      label: `Building - Other than Residence for Homeowner`,
      value: "csio:BLDG",
    },
    { label: `Builders Risk Insurance`, value: "csio:BLDRS" },
    {
      label: `BMC-32 Endorsement for Motor Common Carrier Policies of Insurance for Cargo Liability`,
      value: "csio:BMC32",
    },
    {
      label: `Boiler And Machinery - Consequential Damage`,
      value: "csio:BMCD",
    },
    { label: `Boiler And Machinery - Data and Media`, value: "csio:BMDM" },
    { label: `Data Restoration`, value: "csio:BMDR" },
    { label: `Boiler And Machinery - Extra Expense`, value: "csio:BMEE" },
    { label: `Boiler And Machinery - Hazardous Substance`, value: "csio:BMHS" },
    { label: `Boiler And Machinery - Professional Fees`, value: "csio:BMPF" },
    { label: `Boiler and Machinery - Rental Income`, value: "csio:BMRI" },
    { label: `Rental Income`, value: "csio:BMRI" },
    {
      label: `Boiler And Machinery - Standard Comprehensive - incl production machines`,
      value: "csio:BMSC",
    },
    { label: `Boiler And Machinery - Water Damage`, value: "csio:BMWD" },
    { label: `Day Care Extension`, value: "csio:BOCH" },
    { label: `Boiler and Machinery`, value: "csio:Boiler" },
    { label: `Boarding of Livestock`, value: "csio:BOL" },
    { label: `Boom`, value: "csio:BOOM" },
    { label: `Brands / Labels`, value: "csio:BRAND" },
    { label: `Broad`, value: "csio:BRD" },
    { label: `Broad`, value: "csio:BRD" },
    { label: `Breakdown`, value: "csio:Breakdown" },
    { label: `DEPRECATED Builders Risk`, value: "csio:BuildersRisk" },
    { label: `Building`, value: "csio:Building" },
    { label: `Building under Renovation Endorsement`, value: "csio:BUNOV" },
    {
      label: `By-Laws - Value of Undamaged Portion of Building`,
      value: "csio:BUP",
    },
    { label: `Residence Burglary`, value: "csio:BURG" },
    { label: `Burn Exclusion`, value: "csio:BURN" },
    { label: `Business Income`, value: "csio:BUS" },
    { label: `DEPRECATED Business Income`, value: "csio:BusinessIncome" },
    { label: `Incidental Business Pursuits Endorsement`, value: "csio:BUSNS" },
    { label: `Business Property: On Property`, value: "csio:BUSPR" },
    { label: `Business Property: Off Property`, value: "csio:BUSPO" },
    {
      label: `Additional Insured - Blanket Vendors Broad Form`,
      value: "csio:BVAI",
    },
    { label: `Bylaws Endorsement`, value: "csio:BYLAW" },
    { label: `By-Laws - Blanket`, value: "csio:ByLaws" },
    { label: `Camera`, value: "csio:CAMRA" },
    { label: `Cancellation Clause - 60 Days`, value: "csio:Cancellation60" },
    { label: `Cancellation Clause - 90 Days`, value: "csio:Cancellation90" },
    { label: `Cannabis Endorsement`, value: "csio:CANNA" },
    { label: `Care Facility Contents Extension`, value: "csio:CARE" },
    { label: `DEPRECATED Carriers`, value: "csio:Carriers" },
    // {
    //   label: `Catastrophe (other than certified acts of terrorism)`,
    //   value: "csio:CAT",
    // },
    { label: `CATIM, CHHMB`, value: "csio:CATCH" },
    { label: `CATIM, DCB`, value: "csio:CATDC" },
    { label: `Catastrophic Impairment`, value: "csio:CATIM" },
    // {
    //   label: `Catastrophic Impairment (Occasional Driver)`,
    //   value: "csio:CATOD",
    // },
    { label: `Contingent Business Income`, value: "csio:CBI" },
    {
      label: `Condominium Bare Land Loss Assessment (Property Section)`,
      value: "csio:CBLLA",
    },
    { label: `Contingent Business Interruption`, value: "csio:CBN" },
    { label: `Commercial Fine Arts`, value: "csio:CCAFL" },
    {
      label: `Credit Card Forgery and Counterfeit Money Coverage`,
      value: "csio:CCARD",
    },
    { label: `Bicycle`, value: "csio:CCBIK" },
    {
      label: `Books, Tools, and Instruments Pertaining to a Business`,
      value: "csio:CCBK",
    },
    { label: `Increased Limit for Blanket Coverage`, value: "csio:CCBLK" },
    { label: `Business Increased Limits`, value: "csio:CCBUS" },
    { label: `Coin Collection`, value: "csio:CCC" },
    { label: `Compact Discs or Audio Tapes`, value: "csio:CCCD" },
    {
      label: `Collectibles and Treasures Increased Limits`,
      value: "csio:CCCOL",
    },
    { label: `Credit Card Forgery`, value: "csio:CCF" },
    { label: `Family and Security Increased Limits`, value: "csio:CCFAM" },
    { label: `Furs`, value: "csio:CCFR" },
    { label: `Golf Carts`, value: "csio:CCGC" },
    { label: `Home and Recreation Increased Limits`, value: "csio:CCHRC" },
    { label: `Jewellery/Watches/Gems`, value: "csio:CCJW" },
    { label: `Condominium Contingent Legal Liability`, value: "csio:CCLA" },
    { label: `Money and Bullion`, value: "csio:CCMOP" },
    {
      label: `Coverage under section C for contents of described Motorhome or Vacation Trailer. `,
      value: "csio:CCMVT",
    },
    { label: `Professional Books Increased Limits`, value: "csio:CCPBK" },
    { label: `Personal Computer (Software)`, value: "csio:CCPS" },
    { label: `Sports Card Collection`, value: "csio:CCSCC" },
    { label: `Securities`, value: "csio:CCSED" },
    { label: `Stamps`, value: "csio:CCST" },
    { label: `Silverware`, value: "csio:CCSV" },
    { label: `Theft from Vehicle`, value: "csio:CCTFV" },
    { label: `Tombstones (Grave Markers)`, value: "csio:CCTS" },
    { label: `Condominium Unit Owners - Condominium Unit`, value: "csio:CCU" },
    { label: `Windstorm`, value: "csio:CCWND" },
    { label: `Watercraft`, value: "csio:CCWT" },
    {
      label: `Cancellation/Deletion of Coverage Endorsement`,
      value: "csio:CDCE",
    },
    {
      label: `DEPRECATED Comprehensive Dishonesty, Disappearance and Destruction`,
      value: "csio:CDDD",
    },
    {
      label: `Condominium Deductible Assessment - Earthquake`,
      value: "csio:CDEDA",
    },
    {
      label: `Common Elements Condominium Corporation Extension Endorsement`,
      value: "csio:CECCE",
    },
    {
      label: `Endorsement for change in deductible notice `,
      value: "csio:CEDED",
    },
    {
      label: `Endorsement for change in deductible notice`,
      value: "csio:CEDED",
    },
    {
      label: `Computer and Electronic Equipment Income Changes`,
      value: "csio:CEEBI",
    },
    { label: `Cellular Telephone`, value: "csio:CELLP" },
    { label: `Contractors Equipment - Other`, value: "csio:CEO" },
    {
      label: `Contractors Equipment Rented Equipment Coverage Endorsement`,
      value: "csio:CERC",
    },
    {
      label: `Replacement Cost Endorsement Applicable to Contractors' Equipment `,
      value: "csio:CERE",
    },
    {
      label: `Contractors' Equipment - Rental Reimbursement`,
      value: "csio:CERR",
    },
    { label: `Contractors Equipment - Contractors Tools`, value: "csio:CET" },
    { label: `Chip and Fracture Exclusion`, value: "csio:CFEXC" },
    { label: `Computer Fraud and Funds Transfer Fraud`, value: "csio:CFFTC" },
    {
      label: `Carrier for Hire - Shipper's Interest Contingent`,
      value: "csio:CFHSC",
    },
    { label: `Customer's Goods - Appliance Repair`, value: "csio:CGA" },
    {
      label: `Customer's Goods - Dry Cleaners and Laundries`,
      value: "csio:CGD",
    },
    { label: `Customer's Goods - Garment Contractors`, value: "csio:CGG" },
    { label: `Customer's Goods - Other`, value: "csio:CGO" },
    { label: `Customer's Goods - Rug and Carpet Cleaners`, value: "csio:CGR" },
    {
      label: `Caregiver, Housekeeping & Home Maintenance Benefits, and Dependant Care Benefits`,
      value: "csio:CHD",
    },
    { label: `Contractors Equipment - Heavy Equipment`, value: "csio:CHE" },
    // {
    //   label: `Caregiver, Housekeeping & Home Maintenance Benefits`,
    //   value: "csio:CHHMB",
    // },
    // {
    //   label: `Caregiver, Housekeeping & Home Maintenance for Occasional Driver`,
    //   value: "csio:CHHOD",
    // },
    {
      label: `Condominium Unit Owners - Unit Improvements and Betterments`,
      value: "csio:CIB",
    },
    {
      label: `Cigarettes and Tobacco Limitation Endorsement`,
      value: "csio:CigarettesEnd",
    },
    // {
    //   label: `Catastrophic Impairment - Medical, Rehabilitation and Attendant Care (Occasional Driver)`,
    //   value: "csio:CIMOD",
    // },
    // {
    //   label: `Catastrophic Impairment - Medical, Rehabilitation and Attendant Care`,
    //   value: "csio:CIMRB",
    // },
    { label: `Claims advantage`, value: "csio:CLADV" },
    { label: `Claims advantage`, value: "csio:CLADV" },
    { label: `Crown Land`, value: "csio:CLAND" },
    { label: `Cyber Loss of Business Income`, value: "csio:CLBI" },
    { label: `Contractors Equipment - Light Equipment`, value: "csio:CLE" },
    { label: `Claim Free Protection`, value: "csio:CLFRE" },
    { label: `Cyber Liability`, value: "csio:CLIAB" },
    { label: `Classic Vehicle`, value: "csio:CLVEH" },
    {
      label: `Contractors Equipment - Municipal Contractors Equipment`,
      value: "csio:CMC",
    },
    {
      label: `Contractors Equipment - Miscellaneous Equipment`,
      value: "csio:CME",
    },
    // { label: `Comprehensive`, value: "csio:CMP" },
    { label: `Currency, Money and Stamps`, value: "csio:CMS" },
    { label: `Contamination and Pollution Removal`, value: "csio:CNPOL" },
    {
      label: `DEPRECATED COED - Contents of Every Description`,
      value: "csio:COED",
    },
    // { label: `Collision`, value: "csio:COL" },
    { label: `Collapse`, value: "csio:COLLA" },
    { label: `Collapse`, value: "csio:COLLP" },
    // { label: `Collision Occasional Driver`, value: "csio:COLOD" },
    {
      label: `Computer Software Errors and Omissions Exclusion`,
      value: "csio:CompSoftEOExcl",
    },
    { label: `Commercial Stock`, value: "csio:COMST" },
    {
      label: `Condominium Directors and Officers`,
      value: "csio:CondoDirectorsOfficers",
    },
    { label: `Contractors Equipment`, value: "csio:CONEQ" },
    {
      label: `Public Employee Liability For Building Under Construction`,
      value: "csio:CONPE",
    },
    { label: `Building under construction liability`, value: "csio:CONSE" },
    { label: `Consequential Loss`, value: "csio:ConsequentialLoss" },
    { label: `Consequential Loss  `, value: "csio:CONSQLOSS" },
    { label: `Construction Liability`, value: "csio:CONST" },
    { label: `Contents`, value: "csio:Contents" },
    { label: `Contractors Equipment`, value: "csio:ContEquipment" },
    { label: `Contingent Employer's Liability`, value: "csio:ContingentEL" },
    { label: `Contamination`, value: "csio:CONTM" },
    { label: `Insured Acting as Own Contractor`, value: "csio:CONTR" },
    {
      label: `Contracted Cleaners Endorsement`,
      value: "csio:ContractedCleaners",
    },
    {
      label: `Comprehensive Personal Liability Limitation`,
      value: "csio:COPLL",
    },
    { label: `Collector Vehicle`, value: "csio:COVEH" },
    { label: `Computer Attack`, value: "csio:CPATK" },
    { label: `Contractors Equipment - Portable Equipment`, value: "csio:CPE" },
    {
      label: `Legal Liability - Other than Homeowners, Tenant and Condominium Form`,
      value: "csio:CPL",
    },
    {
      label: `Comprehensive Personal Liability - Product - Completed Operations Exclusions`,
      value: "csio:CPLCO",
    },
    { label: `Claims Protection Plan`, value: "csio:CPP" },
    { label: `Claims Protection Plan - Extended`, value: "csio:CPPE" },
    { label: `Claims Protection Plan - Occasional Driver`, value: "csio:CPPO" },
    { label: `Claims Protection Plan - Used`, value: "csio:CPPX" },
    { label: `Virus and Hacking`, value: "csio:CPVIR" },
    { label: `criminal `, value: "csio:CR" },
    { label: `Crisis Management Expenses`, value: "csio:CRIS" },
    { label: `Cross Liability`, value: "csio:CRL" },
    { label: `Customer Service Extension Bond`, value: "csio:CSB" },
    { label: `Cyber Terrorism`, value: "csio:CTRIA" },
    { label: `Construction Trailers - Owned`, value: "csio:CTRLO" },
    { label: `Commercial Umbrella`, value: "csio:CUMBR " },
    { label: `Condominium Unit Owners`, value: "csio:CUO" },
    { label: `Custom Farming`, value: "csio:CustomFarming" },
    { label: `Custom Spraying`, value: "csio:CustomSpraying" },
    { label: `Contractors Equipment - Waste Compactor`, value: "csio:CWC" },
    { label: `Personal Cyber Coverage`, value: "csio:CYBER" },
    { label: `Cyber Terrorism Exclusion`, value: "csio:CyberTerrorism" },
    { label: `Cyber Extortion`, value: "csio:CYEXT" },
    { label: `Cyber Investigations`, value: "csio:CYINV" },
    { label: `Cyber Theft`, value: "csio:CYTFT" },
    { label: `Cyber Data Re-creation`, value: "csio:DAREC" },
    { label: `Data Compromise`, value: "csio:DATAC" },
    { label: `Day Care`, value: "csio:DAYC" },
    { label: `Daycare/Babysitting`, value: "csio:Daycare" },
    // { label: `Dependant Care Benefits`, value: "csio:DCB" },
    // { label: `Dependant Care for Occasional Driver`, value: "csio:DCBOD" },
    { label: `Debris Removal`, value: "csio:DEBRI" },
    { label: `Debris Removal`, value: "csio:DEBRL" },
    { label: `DE / Consolidation`, value: "csio:DECON" },
    { label: `Declared Value`, value: "csio:DECVL" },
    { label: `Deductible Assessment Buydown`, value: "csio:DEDAB" },
    { label: `Decreasing Deductible Endorsement `, value: "csio:DEDED" },
    { label: `DEPRECATED Deductible`, value: "csio:Deductible" },
    { label: `Defense Cost Limit`, value: "csio:DEFCO" },
    { label: `Deferred Loss Settlement Clause`, value: "csio:DEFER" },
    { label: `Demolition Increased Cost of Construction`, value: "csio:DEMO" },
    { label: `Pollution Clean-up`, value: "csio:DEN" },
    { label: `Dent Clause`, value: "csio:DENT" },
    { label: `Dependents Coverage Endorsement`, value: "csio:DEPEN" },
    { label: `DEPRECATED Depositors Forgery`, value: "csio:DepositorsForgery" },
    { label: `Wind/Hail Deductible`, value: "csio:DEWHA" },
    // { label: `Death and Funeral Benefits`, value: "csio:DFB" },
    // { label: `Death & Funeral for Occasional Driver`, value: "csio:DFBOD" },
    { label: `Death and Funeral Income Replacement`, value: "csio:DFP" },
    { label: `Difference In Conditions`, value: "csio:DIC" },
    { label: `Directors and Officers`, value: "csio:DirectorsOfficers" },
    { label: `Abstain from Alcohol`, value: "csio:DISAA" },
    {
      label: `Discount - Autonomous Emergency Braking System`,
      value: "csio:DISAB",
    },
    {
      label: `Discount - Anti Theft Device Automatically Activated (non alarms)`,
      value: "csio:DISAD",
    },
    { label: `Automatic Generator`, value: "csio:DISAG" },
    { label: `Discount - Alarm System`, value: "csio:DISAL" },
    { label: `Premises Alarm (Fire or Intrusion) System`, value: "csio:DISAL" },
    { label: `Discount - Association/Membership`, value: "csio:DISAM" },
    { label: `Discount - Association/Membership`, value: "csio:DISAM" },
    { label: `Discount - Away - at - School`, value: "csio:DISAS" },
    { label: `Discount - Antique Vehicle`, value: "csio:DISAT" },
    { label: `Discount - Authorized Vendors`, value: "csio:DISAV" },
    { label: `Broker Multi Line Discount`, value: "csio:DISBM" },
    { label: `Broker Multi Line`, value: "csio:DISBM" },
    { label: `Basic Policy (No Frills)`, value: "csio:DISBP" },
    { label: `Basic Policy (No Frills)`, value: "csio:DISBP" },
    { label: `Broker Self Service`, value: "csio:DISBS" },
    { label: `Broker Self Service`, value: "csio:DISBS" },
    { label: `Business Use`, value: "csio:DISBU" },
    { label: `Combined Age-Mortgage-Free`, value: "csio:DISCA" },
    { label: `Discount - Conviction Free`, value: "csio:DISCF" },
    { label: `Discount - Religious Clerical`, value: "csio:DISCL" },
    { label: `Discount - No. of Comprehensive Claims `, value: "csio:DISCM" },
    {
      label: `Discount - Conviction Free (Occasional Driver)`,
      value: "csio:DISCO",
    },
    { label: `Discount - Combined Policy`, value: "csio:DISCP" },
    { label: `Discount - Combined Policy`, value: "csio:DISCP" },
    { label: `Discount - Credit Consent Received`, value: "csio:DISCR" },
    { label: `Discount - Credit Consent Received`, value: "csio:DISCR" },
    { label: `Risk Premium Cap`, value: "csio:DISCX" },
    { label: `Risk Premium Cap`, value: "csio:DISCX" },
    { label: `Discount - Driver Age`, value: "csio:DISDA" },
    { label: `Risk Discount Cap`, value: "csio:DISDC" },
    { label: `Risk Discount Cap`, value: "csio:DISDC" },
    { label: `Discount - Deductible`, value: "csio:DISDD" },
    { label: `Discount - Diesel Engine`, value: "csio:DISDE" },
    {
      label: `Discount - Anti Theft Device Manually Activated (non alarms)`,
      value: "csio:DISDM",
    },
    {
      label: `Discount - Group Rate Applied for Occasional Driver`,
      value: "csio:DISDO",
    },
    { label: `Discount - Insured's Driving Record`, value: "csio:DISDR" },
    { label: `Discount - Driving Simulator`, value: "csio:DISDS" },
    { label: `Discount - Driver Training`, value: "csio:DISDT" },
    { label: `Discount - Experienced Driver`, value: "csio:DISED" },
    { label: `Diseases Exclusion Endorsement`, value: "csio:EXDIS" },
    { label: `Discount for Electric car`, value: "csio:DISEL" },
    { label: `Discount - Employee`, value: "csio:DISEM" },
    { label: `Employee`, value: "csio:DISEM" },
    { label: `Discount - Electronic Documents`, value: "csio:DISER" },
    { label: `Discount – Excess Trailer`, value: "csio:DISET" },
    { label: `Evaluator Based`, value: "csio:DISEV" },
    { label: `Discount - Facility`, value: "csio:DISFA" },
    { label: `Discount - Farm Comprehensive`, value: "csio:DISFC" },
    { label: `Discount - Female`, value: "csio:DISFM" },
    { label: `Female`, value: "csio:DISFM" },
    { label: `Discount - Claims Forgiveness`, value: "csio:DISFO" },
    { label: `Claims Forgiveness`, value: "csio:DISFO" },
    { label: `Discount - Farm`, value: "csio:DISFR" },
    { label: `Discount- Family `, value: "csio:DISFY" },
    { label: `Discount - Group Rate Applied`, value: "csio:DISGD" },
    { label: `Group Rate Applied`, value: "csio:DISGD" },
    { label: `Green Home Discount`, value: "csio:DISGH" },
    { label: `Good Payer Discount`, value: "csio:DISGP" },
    { label: `New Early Quote Discount`, value: "csio:DISEQ" },
    {
      label: `Discount - Underage classes provided a parents’ private passenger automobile(s) is insured with the same company and at least one private passenger automobile carries mandatory coverages.`,
      value: "csio:DISGN",
    },
    {
      label: `Discount - Graduated License for an Occasional Driver`,
      value: "csio:DISGO",
    },
    { label: `Discount - Graduated License Holder`, value: "csio:DISGR" },
    { label: `Discount - Good Student`, value: "csio:DISGS" },
    { label: `Heating`, value: "csio:DISHE" },
    { label: `Discount - Higher Floor Unit`, value: "csio:DISHF" },
    { label: `Discount - Higher Limit Purchased`, value: "csio:DISHL" },
    { label: `Hail Resistant`, value: "csio:DISHR" },
    { label: `Home Security`, value: "csio:DISHS" },
    { label: `New Hot Water Tank`, value: "csio:DISHW" },
    { label: `Discount - Hybrid`, value: "csio:DISHY" },
    {
      label: `Discount - I Promise Program-Parent/Teen Mutual Safe Driving Contract`,
      value: "csio:DISIP",
    },
    { label: `Discount - Low Commute Distance`, value: "csio:DISLC" },
    { label: `Discount - Low Annual Mileage`, value: "csio:DISLM" },
    { label: `Long Term Occupancy`, value: "csio:DISLT" },
    { label: `Mature Homeowner - In Premises 'n' Years`, value: "csio:DISMA" },
    { label: `Discount - Miscellaneous`, value: "csio:DISMC" },
    { label: `Miscellaneous`, value: "csio:DISMC" },
    { label: `Multiple Family`, value: "csio:DISMF" },
    {
      label: `Discount - Mortgage Free Home/Condominium `,
      value: "csio:DISMG",
    },
    { label: `Mortgage Free`, value: "csio:DISMG" },
    { label: `Discount - Motor Home`, value: "csio:DISMH" },
    { label: `Motor Home`, value: "csio:DISMH " },
    { label: `Discount - Mature Driver`, value: "csio:DISMI" },
    { label: `Mature Citizen`, value: "csio:DISMI" },
    { label: `Multi Motorcycle Discount`, value: "csio:DISMM" },
    { label: `Motorcycle Improvement Course`, value: "csio:DISMO" },
    { label: `Discount - Multiple Line `, value: "csio:DISMP" },
    { label: `Multiple Line`, value: "csio:DISMP" },
    { label: `Multiple Property Risks`, value: "csio:DISMR" },
    { label: `Discount - Marital Status`, value: "csio:DISMS" },
    { label: `Discount - Multiple Units`, value: "csio:DISMU" },
    { label: `Discount - Multi Vehicle`, value: "csio:DISMV" },
    { label: `Maximum Security`, value: "csio:DISMX" },
    { label: `Discount - Non Authorized Vendors`, value: "csio:DISNA" },
    { label: `Discount New Business`, value: "csio:DISNB" },
    { label: `Discount New Business`, value: "csio:DISNB" },
    { label: `Discount - No Claims`, value: "csio:DISNC" },
    { label: `No - Claims`, value: "csio:DISNC" },
    { label: `Discount - New Driver`, value: "csio:DISND" },
    { label: `New Homeowner`, value: "csio:DISNE" },
    { label: `New Home`, value: "csio:DISNH" },
    { label: `Discount - No Claims (Occasional Driver)`, value: "csio:DISNO" },
    { label: `New Policy`, value: "csio:DISNP" },
    { label: `Discount - Non-smoker`, value: "csio:DISNS" },
    { label: `Non-smoker`, value: "csio:DISNS" },
    { label: `Discount - New Vehicle`, value: "csio:DISNV" },
    {
      label: `Neighbourhood Watch/Crime Prevention Programme`,
      value: "csio:DISNW",
    },
    { label: `Discount - Occupation`, value: "csio:DISOC" },
    { label: `Occupation`, value: "csio:DISOC" },
    { label: `Discount - Occasional Driver`, value: "csio:DISOD" },
    { label: `Discount - Facility (Occasional Driver)`, value: "csio:DISOF" },
    { label: `Discount - One Payment`, value: "csio:DISON" },
    { label: `Discount - Owner Occupied`, value: "csio:DISOO" },
    { label: `Discount - Out of Province`, value: "csio:DISOP" },
    { label: `Cooperative (Co-op)`, value: "csio:DISOP" },
    {
      label: `Discount - Facility-Occasional Driver (Remedial Driver Improvement Course)`,
      value: "csio:DISOR",
    },
    { label: `Discount - Owner of Vehicle`, value: "csio:DISOW" },
    { label: `Preferred Construction`, value: "csio:DISPC" },
    { label: `Discount - Protection Device`, value: "csio:DISPD" },
    { label: `Discount - Private Parking`, value: "csio:DISPP" },
    { label: `Discount - Professional`, value: "csio:DISPR" },
    { label: `Professional`, value: "csio:DISPR" },
    { label: `Discount - Partial workweek commute`, value: "csio:DISPW" },
    { label: `Quality Home`, value: "csio:DISQH" },
    { label: `Discount - Range of Operations`, value: "csio:DISRA" },
    {
      label: `Discount - Recreational vehicle combined with a private passenger vehicle`,
      value: "csio:DISRC",
    },
    { label: `Discount - Retiree`, value: "csio:DISRD" },
    { label: `Retiree`, value: "csio:DISRD" },
    {
      label: `Discount - Facility - Regular Driver (Remedial Driver Improvement Course)`,
      value: "csio:DISRM",
    },
    { label: `Discount - Renewal`, value: "csio:DISRN" },
    { label: `Renewal`, value: "csio:DISRN" },
    { label: `Discount - Renewal (Occasional)`, value: "csio:DISRO" },
    { label: `Discount – Multi Recreational Vehicle`, value: "csio:DISRV" },
    { label: `Discount - Self Bailing`, value: "csio:DISSB" },
    { label: `Discount - Senior Citizen (Age Related)`, value: "csio:DISSC" },
    { label: `Senior Citizen (Age Related)`, value: "csio:DISSC" },
    { label: `Smoke Detector`, value: "csio:DISSD" },
    { label: `Discount - Safety Equipment`, value: "csio:DISSE" },
    { label: `Sprinklered`, value: "csio:DISSK" },
    { label: `Discount - Snow Tires`, value: "csio:DISSN" },
    { label: `Select Rider Discount `, value: "csio:DISSR" },
    { label: `Septic System`, value: "csio:DISSS" },
    { label: `Discount - Stability`, value: "csio:DISST" },
    { label: `Discount - Stability`, value: "csio:DISST" },
    {
      label: `Discount - Electronic Stability Control System (Factory Equipped)`,
      value: "csio:DISSV",
    },
    { label: `Sewer Back-up Prevention Discount`, value: "csio:DISSW" },
    { label: `Mobile Home Tied Down`, value: "csio:DISTD" },
    { label: `Discount - Trail Permit`, value: "csio:DISTP" },
    { label: `Discount - Territory`, value: "csio:DISTR" },
    { label: `Territory Discount`, value: "csio:DISTR" },
    {
      label: `Tanker Shuttle Service (Fire Fighting Service)`,
      value: "csio:DISTS",
    },
    { label: `Tankless Water Heater`, value: "csio:DISTW" },
    { label: `Total Discount Applied`, value: "csio:DISTX" },
    { label: `Total Discount Applied`, value: "csio:DISTX" },
    { label: `Discount - Unfinished basement`, value: "csio:DISUB" },
    { label: `Discount - Valued/Preferred Customer`, value: "csio:DISVC" },
    { label: `Valued/Preferred Customer`, value: "csio:DISVC" },
    { label: `Discount-Vehicle to Operator Ratio`, value: "csio:DISVO" },
    { label: `24-hour Video Surveillance`, value: "csio:DISVS" },
    { label: `Watercraft (Maximum Conditions)`, value: "csio:DISWA" },
    {
      label: `Discount - Watercraft Operator Certificate`,
      value: "csio:DISWC",
    },
    { label: `Discount - Water Flow Device`, value: "csio:DISWF" },
    { label: `Water Sensor`, value: "csio:DISWS" },
    { label: `Year Built (Other than New Home)`, value: "csio:DISYB" },
    { label: `Discount Young Driver`, value: "csio:DISYD" },
    { label: `Years of Experience`, value: "csio:DISYE" },
    { label: `Damage to Building`, value: "csio:DmgToBuilding" },
    { label: `Domestic Fuel Exclusion`, value: "csio:DOFXC" },
    {
      label: `Detached Private Structures Exclusion Endorsement`,
      value: "csio:DPSEX",
    },
    {
      label: `Detached Private Structures Limitation Endorsement`,
      value: "csio:DPSLE",
    },
    { label: `Driving Record Guarantee After Loss`, value: "csio:DRALX" },
    { label: `Driving Record Guarantee Before Loss`, value: "csio:DRBLO" },
    { label: `Damage Due to Illicit Narcotics Exclusion`, value: "csio:DRGEX" },
    { label: `Debris Removal`, value: "csio:DRL" },
    { label: `Druggists`, value: "csio:Druggists" },
    { label: `Duplicate Records`, value: "csio:DUPRE" },
    { label: `Duty`, value: "csio:DUTY" },
    { label: `Dwelling - Residence for Homeowner`, value: "csio:DWELL" },
    { label: `Day Care Activities Exclusion`, value: "csio:DYCAR" },
    { label: `DEPRECATED Each Occurrence`, value: "csio:EachOccurrence" },
    { label: `Earned Flat Premium`, value: "csio:EARFP" },
    { label: `Earthquake`, value: "csio:Earthquake" },
    { label: `Employee Benefits Liability`, value: "csio:EBLIA " },
    { label: `Extended Business Income - Ordinary Payroll`, value: "csio:EBO" },
    { label: `Elderly care Home Extension`, value: "csio:ECARE" },
    { label: `Incompatible Hardware and Media`, value: "csio:EDIHM" },
    { label: `Electronic Data Liability`, value: "csio:EDLIA" },
    { label: `Educator's Legal Liability`, value: "csio:EDLL " },
    {
      label: `Electronic Data Processing (EDP) Media/Data (Software) In Transit`,
      value: "csio:EDMIN",
    },
    {
      label: `DEPRECATED Electronic Data Processing Systems`,
      value: "csio:EDP",
    },
    { label: `Electronic Data Processing (EDP) Equipment`, value: "csio:EDP" },
    {
      label: `Electronic Data Processing (EDP) - Business Interruption`,
      value: "csio:EDPBI",
    },
    {
      label: `Electronic Data Processing Systems - Breakdown`,
      value: "csio:EDPBreakdown",
    },
    {
      label: `Electronic Data Processing (EDP) - Extra Expense`,
      value: "csio:EDPEE",
    },
    {
      label: `Electronic Data Processing (EDP) Equipment (Hardware) Owned`,
      value: "csio:EDPEQ",
    },
    {
      label: `Electronic Data Processing Systems - Equipment`,
      value: "csio:EDPEquipment",
    },
    {
      label: `Electronic Data Processing (EDP) Property in Transit`,
      value: "csio:EDPIN",
    },
    {
      label: `Electronic Data Processing (EDP) Equipment (Hardware) - Leased`,
      value: "csio:EDPLE",
    },
    {
      label: `Electronic Data Processing (EDP) - Mechanical Breakdown`,
      value: "csio:EDPMB",
    },
    {
      label: `Electronic Data Processing (EDP) Media/Data (Software)`,
      value: "csio:EDPMD",
    },
    {
      label: `Electronic Data Processing Systems - Media`,
      value: "csio:EDPMedia",
    },
    {
      label: `Electronic Data Processing (EDP) - Protection and Control System`,
      value: "csio:EDPPC",
    },
    {
      label: `Electronic Data Processing (EDP) Property on Premises`,
      value: "csio:EDPPP",
    },
    { label: `Telecommunications Equipment`, value: "csio:EDPTE" },
    {
      label: `Electronic Data Processing (EDP) Equipment (Hardware) In Transit`,
      value: "csio:EDQIN",
    },
    { label: `Extra Expense`, value: "csio:EE" },
    { label: `Exhibition of Equipment`, value: "csio:EEQPT" },
    { label: `Equipment Floater`, value: "csio:EFLTR" },
    { label: `Employer's Liability`, value: "csio:EL " },
    {
      label: `Coverage for Elected or Appointed Officers`,
      value: "csio:ElectedOfficers",
    },
    { label: `Elevator`, value: "csio:ELEV" },
    { label: `Electrical Power Interruption`, value: "csio:ELPOWINT" },
    {
      label: `Declaration of Emergency Endorsement – Extension of Termination or Expiry Date`,
      value: "csio:EMERG",
    },
    { label: `Emergency Removal Expenses`, value: "csio:EMERL" },
    {
      label: `Employees As Additional Insureds`,
      value: "csio:EmpAdditionalInsured",
    },
    { label: `Employee Benefits Liability`, value: "csio:EmpBenefits" },
    { label: `DEPRECATED Employee Dishonesty`, value: "csio:EmpDishonesty" },
    { label: `Employer's Bodily Injury`, value: "csio:EmployersBI" },
    { label: `Employment Practices Liability Rider`, value: "csio:EmpltPract" },
    { label: `Employees Tools`, value: "csio:EMT" },
    { label: `Exclusion of Named Customers`, value: "csio:ENC" },
    { label: `Earnings`, value: "csio:ENG" },
    {
      label: `Environmentally Friendly Home Replacement Loss Settlement`,
      value: "csio:ENVLS",
    },
    { label: `Enhanced Internal Limits`, value: "csio:ENHIN" },
    { label: `Entrapment`, value: "csio:ENTR" },
    { label: `Earthquake`, value: "csio:EQ" },
    { label: `Earthquake`, value: "csio:EQ" },
    { label: `Equipment Leased From Others`, value: "csio:EQPLE" },
    { label: `Equipment Loaned To Others`, value: "csio:EQPLO" },
    { label: `Earthquake Sprinkler Leakage`, value: "csio:EQSL" },
    { label: `Equipment`, value: "csio:Equipment" },
    { label: `Equipment Breakdown`, value: "csio:EquipmentBreakdown" },
    { label: `Earthquake`, value: "csio:ERQK" },
    { label: `Post-earthquake Damage`, value: "csio:ERQKF" },
    {
      label: `Incidental Errors and Omissions`,
      value: "csio:ErrorsAndOmissions",
    },
    { label: `Estate Of - Permission for Unoccupancy`, value: "csio:ESOPU" },
    { label: `E-Threat Expenses`, value: "csio:ETHRT" },
    { label: `Mass Evacuation Endorsement`, value: "csio:EVAC" },
    { label: `Electronic Vandalism`, value: "csio:EVM" },
    { label: `Exhibition of Animals`, value: "csio:EXAN" },
    { label: `Exhibition`, value: "csio:EXBFL" },
    { label: `Extra Expense`, value: "csio:ExExpense" },
    { label: `Exhibition Floater`, value: "csio:Exhibition" },
    { label: `Unusual/Exotic Animal(s)`, value: "csio:ExoticAnimal" },
    { label: `Property in Transit - Express Carriers`, value: "csio:EXPRS" },
    { label: `Extended Coverage Deletion`, value: "csio:EXTCD" },
    { label: `Extended Business Income`, value: "csio:ExtendedForm" },
    { label: `Extra Expense`, value: "csio:EXTEX" },
    { label: `Excess Trailers`, value: "csio:EXTR" },
    {
      label: `Exterior Water Line - Breakage of the exterior fresh/potable water line.`,
      value: "csio:EXWAT",
    },
    { label: `Farm Land`, value: "csio:FARM" },
    { label: `Farm Machinery Used for Snow Removal`, value: "csio:FARSN" },
    { label: `Fraudulent Bill of Lading & Receipt`, value: "csio:FBOL" },
    { label: `Flatbed Towing Expense`, value: "csio:FBTEX" },
    { label: `Foster Care Home Extension`, value: "csio:FCHOM" },
    { label: `Farmers Comprehensive Personal Liability`, value: "csio:FCPL" },
    { label: `Fire Department Service Charge`, value: "csio:FDC" },
    { label: `Fire Department Service Charges`, value: "csio:FDC" },
    { label: `Free Form Business Interruption Endorsement`, value: "csio:FFB" },
    { label: `Free Form Crime Endorsement`, value: "csio:FFC" },
    { label: `Free Form Liability Endorsement`, value: "csio:FFL" },
    { label: `Furniture, Fixtures and Office Supplies`, value: "csio:FFOS" },
    { label: `Free Form Property Endorsement`, value: "csio:FFP" },
    { label: `Fine Arts Floater`, value: "csio:FineArts" },
    { label: `Fire`, value: "csio:FIRE" },
    {
      label: `Forest Fire Fighting Expense Extension`,
      value: "csio:FireFighting",
    },
    { label: `Fishing`, value: "csio:Fishing" },
    { label: `Forensic IT Review`, value: "csio:FITRV" },
    { label: `Flood Exclusion`, value: "csio:FLDEX" },
    {
      label: `Flood Water - Damage caused by flood water.`,
      value: "csio:FLOOD",
    },
    { label: `Flood`, value: "csio:Flood" },
    { label: `Flood`, value: "csio:FLOOD" },
    { label: `Flood`, value: "csio:FLOOD" },
    { label: `Flyboard Liability Extension`, value: "csio:FLYBD" },
    { label: `Free On Board Shipments`, value: "csio:FOB" },
    { label: `Employee Dishonesty - Form A`, value: "csio:FormA" },
    { label: `Employee Dishonesty - Form B`, value: "csio:FormB" },
    { label: `Forest and Prairie Protection Act`, value: "csio:FORPA" },
    { label: `Foreign Transit and Location Coverage`, value: "csio:FORTL" },
    { label: `Fire Protection Device Recharge`, value: "csio:FPDR" },
    {
      label: `Functional Replacement Cost Loss Settlement`,
      value: "csio:FRCLS",
    },
    { label: `Fraud and Deceipt`, value: "csio:FRDEC" },
    { label: `Home Freezer (Food Spoilage)`, value: "csio:FREEZ" },
    { label: `Foster Home Exclusion`, value: "csio:FRHEX" },
    { label: `Hobby Farm`, value: "csio:FRMHB" },
    { label: `Furnished Rented Property`, value: "csio:FRTPR" },
    { label: `Funeral Directors`, value: "csio:FuneralDirectors" },
    { label: `Fungi Sublimit`, value: "csio:Fungi" },
    { label: `Fungi, Wet Rot, Dry Rot and Bacteria`, value: "csio:FUNGI" },
    {
      label: `Limited Fungi or Other Microbes Remediation Coverage`,
      value: "csio:FURMC",
    },
    { label: `Garage Extension Endorsement`, value: "csio:GarageExt" },
    { label: `Garden Type Tractor`, value: "csio:GARTT" },
    { label: `Gross Earnings`, value: "csio:GEA" },
    { label: `Gross Earnings`, value: "csio:GEA" },
    { label: `DEPRECATED General Aggregate`, value: "csio:GenAggregate" },
    {
      label: `DEPRECATED Commercial General Liability`,
      value: "csio:GeneralLiability",
    },
    { label: `Geothermal Energy `, value: "csio:GEOTH" },
    { label: `Group Home Extension`, value: "csio:GHOME" },
    { label: `Glass Breakage`, value: "csio:GLABR" },
    { label: `Glass`, value: "csio:Glass" },
    { label: `Glass Deductible Endorsement`, value: "csio:GLDED" },
    { label: `Golf Cart`, value: "csio:GOLFC" },
    { label: `Golf Package Endorsement`, value: "csio:GOLFP" },
    { label: `Gross Earnings - Ordinary Payroll`, value: "csio:GOP" },
    { label: `Gravel Pit`, value: "csio:GravelPit" },
    {
      label: `Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)`,
      value: "csio:GRCB",
    },
    {
      label: `Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)`,
      value: "csio:GRCE",
    },
    { label: `Guarantee Replacement Cost Excluded`, value: "csio:GRCEX" },
    {
      label: `Guaranteed Building Replacement Cost with Single Limit`,
      value: "csio:GRCSL",
    },
    { label: `Gross Rentals`, value: "csio:GRE" },
    {
      label: `Ground Water - Damage caused by infiltration of water through a foundation.`,
      value: "csio:GRNDW",
    },
    { label: `Gross Receipts Reporting Form`, value: "csio:GRRF" },
    { label: `Guaranteed Replacement Cost - Building`, value: "csio:GUARR" },
    { label: `Home Accessibility Extension`, value: "csio:HAEXT" },
    { label: `Hail Coverage Endorsement`, value: "csio:HAILC" },
    { label: `Hay/Sleigh Rides`, value: "csio:HaySleighRides" },
    { label: `Home-Based Business Liability Extension`, value: "csio:HBBLI" },
    { label: `Home Business Off-Premise Coverage`, value: "csio:HBOFF" },
    { label: `Home Business On-Premise Coverage`, value: "csio:HBON" },
    { label: `Home Care Assistance Coverage`, value: "csio:HCAC" },
    { label: `Heat Prostration`, value: "csio:HEATPR" },
    { label: `Restrict Hail Damage to Punctures`, value: "csio:HLDNT" },
    { label: `Restrict Hail Damage to Punctures`, value: "csio:HLDNT" },
    { label: `Hull - All Risk`, value: "csio:HLLAR" },
    { label: `Hull - Named Perils and Marine Perils`, value: "csio:HLLMP" },
    { label: `Hull - Named Perils`, value: "csio:HLLNP" },
    { label: `Her Majesty the Queen`, value: "csio:HMTQ" },
    { label: `Home Business Coverage`, value: "csio:HOBUS" },
    { label: `Home Equipment Protection`, value: "csio:HOEQP" },
    { label: `Horse`, value: "csio:HORSE" },
    { label: `Horse Pulls`, value: "csio:HorsePulls" },
    { label: `Host Liquor Liability Exclusion`, value: "csio:HOSTL" },
    { label: `Health Services Levy`, value: "csio:HSL" },
    { label: `Single Limit`, value: "csio:HSL" },
    { label: `Hurricane Deductible  `, value: "csio:HUDED" },
    { label: `Stronger Home Endorsement`, value: "csio:STHE" },
    // { label: `Indexation`, value: "csio:IDB" },
    // { label: `Indexation Benefit for Occasional Driver`, value: "csio:IDBOD" },
    { label: `Identity Recovery`, value: "csio:IDRC" },
    { label: `Identity Theft`, value: "csio:IDTFT" },
    { label: `Identity Theft - Loss of Income`, value: "csio:IDTLI" },
    { label: `Illness/Disease`, value: "csio:ILLDISEASE" },
    { label: `Improvements and Betterments`, value: "csio:IMP" },
    { label: `DEPRECATED Inside and Outside Robbery`, value: "csio:InAndOut" },
    { label: `Independent Contractors`, value: "csio:IndContractors" },
    { label: `Inflation Guard Endorsement`, value: "csio:INFGD" },
    { label: `Inflation Protection`, value: "csio:Inflation" },
    { label: `Innkeeper's Liability`, value: "csio:Innkeeper" },
    { label: `DEPRECATED Installation Form`, value: "csio:Installation" },
    { label: `In Servant`, value: "csio:INSVT" },
    {
      label: `Increased Legal Liability, Homeowners, Tenants & Condominium Forms`,
      value: "csio:IPL",
    },
    { label: `International Transit`, value: "csio:ITRAN" },
    { label: `Jewelers Block`, value: "csio:JEWBL" },
    {
      label: `Waterjet Propulsion Personal Watercraft - Basic`,
      value: "csio:JPPWB",
    },
    {
      label: `Waterjet Propulsion Personal Watercraft - Passenger Hazard`,
      value: "csio:JPPWP",
    },
    {
      label: `Waterjet Propulsion Personal Watercraft - Underage Operator`,
      value: "csio:JPPWU",
    },
    {
      label: `Extension of Coverage - Keys, Locks and Access Cards`,
      value: "csio:KLAC",
    },
    { label: `Additional Insured - Lessor`, value: "csio:LEAI" },
    {
      label: `Leasehold Condominium Corporation Extension Endorsement`,
      value: "csio:LCCEE",
    },
    { label: `Leakage or Overflow of Domestic Fuel Oil`, value: "csio:LEAKF" },
    { label: `Legal Expense Insurance`, value: "csio:LEFEE" },
    { label: `Legal Fees`, value: "csio:LEFEE" },
    { label: `Legal Assistance`, value: "csio:LEGAL" },
    {
      label: `Limited Guaranteed Replacement Cost Percentage - Building `,
      value: "csio:LGUAP",
    },
    {
      label: `CAE19 - Legends Motorcycle Endorsement (includes all  `,
      value: "csio:LEGMO",
    },
    { label: `Legal Review`, value: "csio:LEGRV" },
    { label: `Lessons`, value: "csio:Lessons" },
    { label: `Liability`, value: "csio:Liability" },
    { label: `Limited Exterior`, value: "csio:LIMET" },
    {
      label: `Limited Environmental Liability`,
      value: "csio:LimitedEnvironmental",
    },
    { label: `Limited to Structural Damage `, value: "csio:LIMSD" },
    { label: `Limited Hidden Water Damage`, value: "csio:LIMWD" },
    { label: `Host Liquor Liability`, value: "csio:LiquorLiability" },
    { label: `Life Lease Extension`, value: "csio:LLEA" },
    { label: `Limited Liability Extension `, value: "csio:LLEXT" },
    { label: `Legal Liability Loss Assessment`, value: "csio:LLLA" },
    { label: `Legal Liability Loss Assessment Bare Land`, value: "csio:LLLAB" },
    { label: `Life Lease Deductible`, value: "csio:LLDD" },
    { label: `Life Lease Deductible - Earthquakes`, value: "csio:LLDE" },
    { label: `Life Lease Unit Owner Improvement`, value: "csio:LLOI" },
    { label: `Life Lease Property Coverage`, value: "csio:LLPC" },
    { label: `Life Lease Unit Coverage`, value: "csio:LLUC" },
    { label: `Loading/Unloading`, value: "csio:LOADUNLOAD" },
    { label: `Log Construction Settlement Limitation`, value: "csio:LOGSL" },
    { label: `DEPRECATED Inside Loss`, value: "csio:LossInside" },
    { label: `DEPRECATED Outside Loss`, value: "csio:LossOutside" },
    { label: `Additional Insured - Leased Premises`, value: "csio:LPAI" },
    { label: `Lifestyle advantage`, value: "csio:LSADV" },
    { label: `Livestock – Broad Named Perils`, value: "csio:LSBNP" },
    { label: `Livestock – Limited Named Perils`, value: "csio:LSLNP" },
    {
      label: `Loss of Use Motorhome and Vacation Trailer Emergency Accommodation Expense Endorsement`,
      value: "csio:LSMVT",
    },
    { label: `Limited Mortality`, value: "csio:LTDMOR" },
    { label: `Limited Pollution Liability`, value: "csio:LtdPollutionLiab" },
    { label: `Loading Unloading`, value: "csio:LUL" },
    { label: `Locked Vehicle Warranty`, value: "csio:LVWAR" },
    { label: `Waiver of Deductible`, value: "csio:LWD" },
    { label: `Waiver of Deductible`, value: "csio:LWD" },
    { label: `major`, value: "csio:MA" },
    {
      label: `Caregiver, Housekeeping & Home Maintenance Benefits, Medical & Rehabilitation Benefits, and Attendant Care Benefits`,
      value: "csio:MAC",
    },
    { label: `Maintenance Work Liability`, value: "csio:MAINL" },
    { label: `Incidental Malpractice`, value: "csio:Malpractice" },
    {
      label: `Matching of Undamaged Roof Surfacing Additional Coverage `,
      value: "csio:MARFC",
    },
    {
      label: `Matching of Undamaged Siding - Additional Coverage  `,
      value: "csio:MASDC",
    },
    { label: `Mechanical breakdown`, value: "csio:MB" },
    {
      label: `Manitoba only - Cancellation/Deletion of Coverage Endorsement`,
      value: "csio:MBCDE",
    },
    { label: `Modified Basis of Claims Payment`, value: "csio:MBCP" },
    { label: `Manuscript Business Interruption Coverage`, value: "csio:MBI" },
    { label: `Manitoba only - Loss of Rental Income`, value: "csio:MBLRI " },
    { label: `Manitoba only - Value Plus `, value: "csio:MBVLP" },
    { label: `Manitoba only - Vehicle Lay-up`, value: "csio:MBVLU" },
    {
      label: `Manitoba only - Value Plus - Specified Lessee`,
      value: "csio:MBVPL",
    },
    { label: `Minor Conviction Protection`, value: "csio:MCP" },
    {
      label: `Minor Conviction Protection for Occasional Driver`,
      value: "csio:MCPOD",
    },
    {
      label: `Minor Conviction Protection for Occasional Driver - Used`,
      value: "csio:MCPOX",
    },
    { label: `Minor Conviction Protection - Used`, value: "csio:MCPX" },
    { label: `Medical Expenses`, value: "csio:MED" },
    { label: `Media`, value: "csio:Media" },
    { label: `Coverage C - Medical Payments`, value: "csio:MedicalPayments" },
    { label: `Voluntary Medical Payments`, value: "csio:MEDPM" },
    { label: `Medical & Rehabilitation Benefits`, value: "csio:MEDRH" },
    { label: `minor`, value: "csio:MI" },
    {
      label: `Medical & Rehabilitation Benefits, and Attendant Care Benefits, and Dependant Care Benefits`,
      value: "csio:MID",
    },
    {
      label: `DEPRECATED Miscellaneous Boiler and Machinery Coverage Form`,
      value: "csio:MiscBoilerForm",
    },
    { label: `Miscellaneous Crime Coverage Form`, value: "csio:MiscCrimeForm" },
    {
      label: `Miscellaneous Liability Coverage Form`,
      value: "csio:MiscLiabilityForm",
    },
    {
      label: `Miscellaneous Professional Liability Endorsement `,
      value: "csio:MiscProfessional",
    },
    { label: `Miscellaneous Property`, value: "csio:MiscProperty" },
    { label: `Manuscript Property Coverage`, value: "csio:MiscPropertyForm" },
    { label: `Mold Exclusion`, value: "csio:MLDEX" },
    {
      label: `Agreed Limit for Motorcycle Accessories and Equipment`,
      value: "csio:MOACE",
    },
    { label: `DEPRECATED Mobile Tool Floater`, value: "csio:MobileTools" },
    { label: `Modified Loss Settlement`, value: "csio:MODLS" },
    { label: `Money`, value: "csio:MONEY" },
    {
      label: `DEPRECATED Money Orders and Counterfeit Paper Currency`,
      value: "csio:MoneyOrders",
    },
    { label: `Money In Locked Safe`, value: "csio:MONLS" },
    {
      label: `CAE20 - Motorcycle replacement cost endorsement`,
      value: "csio:MOREP",
    },
    {
      label: `Morticians' Professional Liability Endorsement`,
      value: "csio:Morticians",
    },
    { label: `Motor - All Risks`, value: "csio:MOTAR" },
    { label: `Motor - Named Perils and Marine Perils`, value: "csio:MOTMP" },
    { label: `Motor - Named Perils`, value: "csio:MOTNP" },
    { label: `Motor Truck Cargo - Owner's`, value: "csio:MotorTruckCargo" },
    { label: `Motorcycle - Vacation Expense`, value: "csio:MOVAE" },
    { label: `Miscellaneous Property - Cameras`, value: "csio:MPC" },
    {
      label: `Property in Transit - Merchants Parcel Delivery Service`,
      value: "csio:MPDS",
    },
    { label: `Miscellaneous Property`, value: "csio:MPFL" },
    { label: `Miscellaneous Property - Gas Pumps`, value: "csio:MPG" },
    { label: `Miscellaneous Property - Lawn Tractors`, value: "csio:MPL" },
    { label: `Miscellaneous Property - Laptop Computers`, value: "csio:MPLC" },
    {
      label: `Miscellaneous Property - Musical Instruments`,
      value: "csio:MPM",
    },
    { label: `Miscellaneous Property - Other`, value: "csio:MPO" },
    { label: `Miscellaneous Property - Cell Phones`, value: "csio:MPP" },
    // { label: `Medical, Rehabilitation & Attendant Care`, value: "csio:MRB" },
    // {
    //   label: `Medical, Rehabilitation and Attendant Care for Occasional Driver`,
    //   value: "csio:MRBOD",
    // },
    {
      label: `Medical & Rehabilitation for Occasional Driver`,
      value: "csio:MRHOD",
    },
    { label: `Motor Truck Cargo`, value: "csio:MTC" },
    { label: `Machinery, Tools and Fittings`, value: "csio:MTF" },
    { label: `Motor Truck Cargo - Truckmens`, value: "csio:MTT" },
    { label: `Newly Acquired Locations`, value: "csio:NAL" },
    {
      label: `Named Uncompensated Directors or Trustees Endorsement`,
      value: "csio:NamedDirectors",
    },
    { label: `Newly Acquired Property`, value: "csio:NAP" },
    { label: `No-CoInsurance Earnings`, value: "csio:NCE" },
    { label: `Network Security and Privacy Liability`, value: "csio:NETSP" },
    { label: `Newly Acquired Property`, value: "csio:NEWAP" },
    { label: `Named Malware`, value: "csio:NMALW" },
    { label: `Non Owned LiveStock`, value: "csio:NOL" },
    {
      label: `Single limit AND Guarantee Replacement Cost Excluded`,
      value: "csio:NGRCE",
    },
    { label: `Non-Owned Business Equipment `, value: "csio:NOBEQ" },
    { label: `No Obligation to Rebuild`, value: "csio:NOR" },
    {
      label: `Named Perils - Unit Owners Additional Protection`,
      value: "csio:NPAP",
    },
    {
      label: `Named Perils - Unit Owners Building Improvements and Betterments Increased Limits`,
      value: "csio:NPIB",
    },
    { label: `Named Perils - Loss Assessment Coverage`, value: "csio:NPLA" },
    {
      label: `Named Perils Personal Articles Endorsement`,
      value: "csio:NPPAE",
    },
    { label: `Named Perils - Solar Panel`, value: "csio:NPSP" },
    {
      label: `Driving Record Guarantee Before After (Occasional Driver)`,
      value: "csio:ODRAX",
    },
    {
      label: `Driving Record Guarantee Before Loss (Occasional Driver)`,
      value: "csio:ODRBL",
    },
    {
      label: `O.E.F. No 98A Excluded Driver Endorsement`,
      value: "csio:OEF98A",
    },
    {
      label: `O.E.F. No 98B Reduction of Coverage for Lessees/Drivers of Leased Vehicles`,
      value: "csio:OEF98B",
    },
    { label: `Office Contents`, value: "csio:OfficeContents" },
    {
      label: `Office, Professional,Private School or Studio Use (Other Premises)`,
      value: "csio:OFFNR",
    },
    { label: `Off-Premises Power`, value: "csio:OffPremisesPower" },
    { label: `Off Road Use`, value: "csio:OFFRD" },
    {
      label: `Office, Professional, Private School or Studio Use (Res. Premises)`,
      value: "csio:OFFRP",
    },
    { label: `Oil Tank Heating`, value: "csio:OHEAT" },
    { label: `DEPRECATED  Outside Loss - Custodial Home`, value: "csio:OLC" },
    {
      label: `Additional Insured - Owners, Lessees or Contractor`,
      value: "csio:OLCAI",
    },
    {
      label: `Oil Leakage on Premise Liability Extension`,
      value: "csio:OLEAK",
    },
    { label: `DEPRECATED Outside Loss - Paymaster`, value: "csio:OLP" },
    { label: `One Deductible Endorsement`, value: "csio:ONEDE" },
    { label: `Out of Province Medical`, value: "csio:OPM" },
    { label: `Other Structures - Homeowners Forms`, value: "csio:OS" },
    {
      label: `Specific Structures Away from the Residence Premises `,
      value: "csio:OSAWP",
    },
    {
      label: `Any One Outside Showcase While Closed For Business`,
      value: "csio:OSCCL",
    },
    {
      label: `Any One Outside Showcase While Open For Business`,
      value: "csio:OSCOP",
    },
    {
      label: `Other Structures Rented to Others (Residence Premises)`,
      value: "csio:OSROR",
    },
    {
      label: `Any One Show Window While Closed For Business`,
      value: "csio:OSWCL",
    },
    {
      label: `Any One Show Window While Open For Business`,
      value: "csio:OSWOP",
    },
    { label: `Other`, value: "csio:OT" },
    { label: `Other Alternative Energy `, value: "csio:OTALE" },
    { label: `Other`, value: "csio:OTH" },
    { label: `Other Members of Your Household`, value: "csio:OTHMH" },
    {
      label: `Other (Appurtenant) Structures (Residence Premises)`,
      value: "csio:OTSRP",
    },
    { label: `Unit Owners Other Structures`, value: "csio:OTSTR" },
    { label: `Out Servant`, value: "csio:OUTSV" },
    { label: `Overnight Accommodation`, value: "csio:OVRAC" },
    {
      label: `Overland Water (Flood & Surface Water) Coverage`,
      value: "csio:OVWAT",
    },
    { label: `Overland Water Exclusion`, value: "csio:OVWEX" },
    { label: `Owned Kennel Liability Extension`, value: "csio:OWKEN" },
    { label: `DEPRECATED Owner's`, value: "csio:Owners" },
    { label: `Property Away From Premises`, value: "csio:PAF" },
    { label: `Peril Adjustment - Hail`, value: "csio:PAHAI" },
    { label: `Pairs / Sets`, value: "csio:PAIRS" },
    {
      label: `20 (or 20A), 35, 43 (or 43A or 43R or 43RL)`,
      value: "csio:PAK01",
    },
    { label: `27, 35, 43 (or 43A or 43R or 43RL) `, value: "csio:PAK02" },
    { label: `20 (or 20A), 43 (or 43A or 43R or 43RL)`, value: "csio:PAK03" },
    { label: `20 (or 20A), 35 `, value: "csio:PAK04" },
    {
      label: `20 (or 20A), 27, 43 (or 43A or 43R or 43RL) `,
      value: "csio:PAK05",
    },
    { label: `20 (or 20A), 27 `, value: "csio:PAK06" },
    { label: `20 (or 20A), 27, 34 `, value: "csio:PAK07" },
    {
      label: `20 (or 20A), 27, 35, 43 (or 43A or 43R or 43RL)`,
      value: "csio:PAK08",
    },
    { label: `20 (or 20A), 27, 35 `, value: "csio:PAK09" },
    {
      label: `2, 20 (or 20A), 43 (or 43A or 43R or 43RL)`,
      value: "csio:PAK10",
    },
    { label: `2, 20A, 27 and 34 `, value: "csio:PAK11" },
    { label: `2, 20, 27 and 34 `, value: "csio:PAK12" },
    { label: `2, 20 (or 20A), 27 `, value: "csio:PAK13" },
    {
      label: `2, 20 (or 20A), 27 and 43 (or 43A or 43R or 43RL)`,
      value: "csio:PAK14",
    },
    { label: `18, 20 (or 20A), 27, 35`, value: "csio:PAK15" },
    { label: `SRAP, 20, 27, 43`, value: "csio:PAK16" },
    { label: `SRAP, 20, 27`, value: "csio:PAK17" },
    { label: `Replacement Cost Package`, value: "csio:PAKRC" },
    { label: `Parcel Post`, value: "csio:PAP" },
    { label: `Valuable Papers`, value: "csio:PAPER" },
    { label: `Premium Adjustment Reporting Form`, value: "csio:PARF" },
    { label: `Partnership Endorsement`, value: "csio:Partnership" },
    { label: ` Peril Adjustment - Snow`, value: "csio:PASNO" },
    { label: `Peril Adjustment - Water`, value: "csio:PAWAT" },
    { label: `Peril Adjustment - Wind`, value: "csio:PAWIN" },
    {
      label: `Comprehensive Personal Liability - Bodily Injury and Property Damage (Coverage A)`,
      value: "csio:PBIPD",
    },
    {
      label: `Products Completed Operations Aggregate Limit`,
      value: "csio:PCOAL",
    },
    { label: `Patterns, Dies, Molds and Models`, value: "csio:PDMM" },
    { label: `Peak Season`, value: "csio:PeakSeason" },
    { label: `Personal Records and Data Replacement`, value: "csio:PERDR" },
    { label: `Personal Effects`, value: "csio:PEF" },
    {
      label: `Personal Protective Equipment (excluding personal effects)`,
      value: "csio:PEPRE",
    },
    { label: `Permission to Use Auxiliary Heating`, value: "csio:PERAH" },
    {
      label: `Permission to Use Auxiliary Heating in Case of Electricity Failure`,
      value: "csio:PERCE",
    },
    {
      label: `Personal Effects Floater - Domiciled Endorsement`,
      value: "csio:PEREF",
    },
    { label: `Personal Liability (Umbrella Endorsement)`, value: "csio:PERLI" },
    {
      label: `Personal Effects (excluding Personal Protective Equipment)`,
      value: "csio:PERSE",
    },
    { label: `Personal Liability`, value: "csio:PERSL" },
    {
      label: `Coverage B - Personal and Advertising Injury Liability`,
      value: "csio:PersonalAdvertising",
    },
    { label: `Personal Liability (Umbrella Standalone)`, value: "csio:PERUS" },
    { label: `Petting Zoo`, value: "csio:PettingZoo" },
    { label: `Personal And Advertising Injury`, value: "csio:PIADV " },
    { label: `Pick Your Own (fruits & vegetables)`, value: "csio:PickYourOwn" },
    { label: `Personal Injury Endorsement`, value: "csio:PIHOM" },
    { label: `Power Interruption (Livestock)`, value: "csio:PILS" },
    { label: `Property In Transit`, value: "csio:PIT" },
    { label: `Project Site`, value: "csio:PJS" },
    {
      label: `Legal Liability - Homeowners, Tenant and Condominium`,
      value: "csio:PL",
    },
    { label: `Property Loss Assessment`, value: "csio:PLASS" },
    { label: `Property In Transit - Laptop Computers`, value: "csio:PLC" },
    {
      label: `Comprehensive Personal Liability - Personal Injury (Coverage B)`,
      value: "csio:PLIJ",
    },
    {
      label: `Comprehensive Personal Liability - Medical Payments (Coverage C)`,
      value: "csio:PLMP",
    },
    {
      label: `Property In Transit - Miscellaneous Equipment`,
      value: "csio:PME",
    },
    { label: `Property at Newly Acquired Locations`, value: "csio:PNA" },
    {
      label: `DEPRECATED POED - Property of Every Description`,
      value: "csio:POED",
    },
    {
      label: `Portable Electronic Data Processing (EDP) equipment`,
      value: "csio:POEDP",
    },
    { label: `Property at any One Location`, value: "csio:POL" },
    { label: `Pollution Liability`, value: "csio:Pollution" },
    { label: `Ponds, Rivers, Swimming Pools`, value: "csio:PondsRiversPools" },
    { label: `Profits - Ordinary Payroll`, value: "csio:POP" },
    { label: `Personal Property - Homeowners Form`, value: "csio:PP" },
    { label: `Personal Property of Officers and Employees`, value: "csio:PPO" },
    {
      label: `Personal Property - Other than Homeowners, Tenant and Condominium Form`,
      value: "csio:PPOTH",
    },
    {
      label: `Personal Property - Tenant and Condominium Unit Owners Form`,
      value: "csio:PPTC",
    },
    { label: `Property Away From Premises`, value: "csio:PRAW" },
    { label: `Property Covered`, value: "csio:PRCO" },
    { label: `Property Covered`, value: "csio:PRCO" },
    {
      label: `Property In Custody of Selling Agent or Salesperson`,
      value: "csio:PRCSA",
    },
    { label: `Premises Coverage Limitation`, value: "csio:PRCVL" },
    { label: `Products/Completed Operations`, value: "csio:PRDCO " },
    {
      label: `Property On Premises of any Dealer or Similar Bailee in the Jewelry Trade`,
      value: "csio:PRDJT",
    },
    { label: `Premises Operations`, value: "csio:PREM " },
    { label: `Premises Liability`, value: "csio:Premises" },
    { label: `Premises Extension`, value: "csio:PREXT" },
    { label: `Property In Safe`, value: "csio:PRINS" },
    {
      label: `Printing Service Errors and Omissions Exclusion Endorsement`,
      value: "csio:PrintEOExcl",
    },
    { label: `Private Lake`, value: "csio:PRLAK" },
    { label: `License/Permit Charge`, value: "csio:PRM" },
    { label: `Property Not Covered`, value: "csio:PRNCO" },
    { label: `Profits`, value: "csio:PRO" },
    { label: `Professional Activity`, value: "csio:PROAC" },
    {
      label: `Product Extension - Outside of Canada Exposure`,
      value: "csio:ProdExtOutsideCda",
    },
    {
      label: `Coverage A - Bodily Injury and Property Damage - Products-Completed Operations`,
      value: "csio:ProductsCompletedOps",
    },
    { label: `Property On Premises`, value: "csio:PRONP" },
    { label: `Property Damage`, value: "csio:PropertyDmge" },
    { label: `Personal Property - Pollution`, value: "csio:PROPO" },
    {
      label: `Proprietor and Partners Extension Endorsement`,
      value: "csio:ProprietorPartner",
    },
    { label: `Property in Storage`, value: "csio:PROS" },
    { label: `Property of Students Coverage`, value: "csio:PROST" },
    {
      label: `Cyber Public Relations (as related to PR response)`,
      value: "csio:PRRES",
    },
    { label: `PR Services`, value: "csio:PRSVS" },
    { label: `Privacy Notification Expenses`, value: "csio:PRVCY" },
    { label: `Death Benefits and Dismemberment`, value: "csio:PS" },
    { label: `Profits - Standing Charges`, value: "csio:PSC" },
    { label: `Property at Temporary Locations`, value: "csio:PTL" },
    { label: `Permission To Occupy`, value: "csio:PTO" },
    { label: `Property Temporarily Removed`, value: "csio:PTR" },
    {
      label: `Comprehensive Personal Liability- Tenants Legal Liability (Coverage D) `,
      value: "csio:PTLL",
    },
    { label: `Power Fluctuation`, value: "csio:PWFL" },
    {
      label: `QEF99 - Excluding Long Term Leased Vehicle`,
      value: "csio:QEF99",
    },
    { label: `QPF6 - Quebec Non-owned Automobile`, value: "csio:QPF6" },
    {
      label: `Property in Transit - Railroad, Passenger Bus Lines, Waterborne or Air Carriers`,
      value: "csio:RBWAC",
    },
    { label: `Replacement Cost - Building`, value: "csio:RCBLD" },
    {
      label: `Residential Condominium Corporation Broad Form Endorsement`,
      value: "csio:RCCBF",
    },
    { label: `Rebuilding Clause`, value: "csio:REBCL" },
    { label: `Specialty Rebuilding Cost Endorsement `, value: "csio:REBUC" },
    { label: `Reciprocal Liability Clause`, value: "csio:Reciprocol" },
    { label: `Reduced Internal Limits`, value: "csio:REDIN" },
    { label: `Refrigeration Breakdown`, value: "csio:REFBR" },
    { label: `Residence Employees Liability`, value: "csio:RELIA" },
    {
      label: `Religious Institution Counselling`,
      value: "csio:ReligiousConselling",
    },
    { label: `Renovations`, value: "csio:RENOV" },
    { label: `Rental Income`, value: "csio:RENT" },
    { label: `Rental Income`, value: "csio:RentalIncome" },
    { label: `Replacement Cost`, value: "csio:REP" },
    { label: `Reproduction Equipment`, value: "csio:REPEQ" },
    {
      label: `Residence Premises - 2 to 6 Family Dwelling`,
      value: "csio:RESPR",
    },
    { label: `Restoration while in Storage`, value: "csio:RESTO" },
    { label: `Recreational Travel Trailers`, value: "csio:RETRA" },
    { label: `Returned / Refused`, value: "csio:RETRF" },
    { label: `Road Hazard Glass`, value: "csio:RHG" },
    { label: `Riding Arena`, value: "csio:RidingArena" },
    {
      label: `Rip and Tear Extension Endorsement, Faulty Workmanship`,
      value: "csio:RipTear",
    },
    { label: `Property in Transit - Registered Mail`, value: "csio:RMAIL" },
    { label: `Rental - Seasonal Dwelling `, value: "csio:RNTCG" },
    { label: `Short-Term Rental Endorsement`, value: "csio:RNTDT" },
    { label: `Rental Watercraft (Rented to Others)`, value: "csio:RNTWC" },
    { label: `Roadside Stand/Market`, value: "csio:RoadStandMrkt" },
    { label: `Roof (Limited to Cash Value)`, value: "csio:ROOF" },
    { label: `Roof (Actual Cash Value)`, value: "csio:ROOFA" },
    { label: `Roof Exclusion - All`, value: "csio:ROOFC" },
    { label: `Roof Exclusion`, value: "csio:ROOFE" },
    { label: `Limited Roof Surface`, value: "csio:ROOFL" },
    { label: `Roof (Replacement Cost Value)`, value: "csio:ROOFR" },
    { label: `Roof Surface - Basis of Settlement`, value: "csio:ROOFS" },
    {
      label: `Roof (Basis of Settlement) due to Windstorm/Hail`,
      value: "csio:ROOFW",
    },
    { label: `Roomer`, value: "csio:ROOMR" },
    { label: `Rental Reimbursement`, value: "csio:RREIM" },
    { label: `Renewal Rate Protection`, value: "csio:RRP" },
    { label: `Rent or Rental Value`, value: "csio:RRV" },
    { label: `Rented Unit Owners Endorsement`, value: "csio:RUOE" },
    {
      label: `Residence Voluntary Compensation - Chauffeur`,
      value: "csio:RVCCH",
    },
    {
      label: `Residence Voluntary Compensation - Resident Employee`,
      value: "csio:RVCIN",
    },
    {
      label: `Residence Voluntary Comp. (Obsolete: see RVCCH, RVCIN, RVCOC, RVCOU)`,
      value: "csio:RVCMP",
    },
    {
      label: `Residence Voluntary Compensation - Occasional`,
      value: "csio:RVCOC",
    },
    {
      label: `Residence Voluntary Compensation - Non-resident Employee `,
      value: "csio:RVCOU",
    },
    { label: `Restricted Water Damage`, value: "csio:RWDAM" },
    { label: `Renewable Energy Income`, value: "csio:RWEI" },
    { label: `Runway Liability Extension`, value: "csio:RWLIA" },
    { label: `Reward Expenses`, value: "csio:RWRD" },
    { label: `Safe Burglary`, value: "csio:SafeBurglary" },
    { label: `Sales Barn`, value: "csio:SalesBarn" },
    { label: `Sauna `, value: "csio:SAUNA" },
    { label: `Actual Cash Value for Sewer Back Up`, value: "csio:SBAC" },
    { label: `Standard Business Income - Ordinary Payroll`, value: "csio:SBO" },
    { label: `Self-contained Apartment`, value: "csio:SCAPT" },
    { label: `Seasonal Residence`, value: "csio:SEARS" },
    { label: `Second Hand Goods Clause`, value: "csio:SecHandGoods" },
    { label: `Seasonal Dwelling`, value: "csio:SEDWG" },
    { label: `Seedman's E&O`, value: "csio:SeedmanEO" },
    {
      label: `SEF94 - Damage to Hired Automobiles - All Perils, AB-SEF94 - Legal Liability for Damage to Hired Automobiles Endorsement (For attachment only to a Non-Owned Automobile Policy S.P.F. No. 6) - All Perils`,
      value: "csio:SEF94",
    },
    {
      label: `SEF94B - Damage to Hired Automobiles - Collision or Upset, AB-SEF94B - Legal Liability for Damage to Hired Automobiles Endorsement (For attachment only to a Non-Owned Automobile Policy S.P.F. No. 6) - Collision or Upset`,
      value: "csio:SEF94B",
    },
    {
      label: `SEF94C - Damage to Hired Automobiles - Comprehensive, AB-SEF94C - Legal Liability for Damage to Hired Automobiles Endorsement (For attachment only to a Non-Owned Automobile Policy S.P.F. No. 6) - Comprehensive`,
      value: "csio:SEF94C",
    },
    {
      label: `SEF94D - Damage to Hired Automobiles - Specified Perils, AB-SEF94D - Legal Liability for Damage to Hired Automobiles Endorsement (For attachment only to a Non-Owned Automobile Policy S.P.F. No. 6) - Specified Perils`,
      value: "csio:SEF94D",
    },
    {
      label: `SEF96 - Contractual Liability Non-owned, AB-SEF96 - Contractual Liability Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6)`,
      value: "csio:SEF96",
    },
    {
      label: `SEF97 - Operation by Individual Named Insured Non-owned, AB-SEF97 - Operation by Individual Named Insured Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No.6) `,
      value: "csio:SEF97",
    },
    {
      label: `SEF99 - Excluding Long Term Leased Vehicle, AB-SEF99 - Leased Vehicle Exclusion Endorsement (For attachment only to a Non-Owned Automobile Insurance Policy S.P.F. No. 6) `,
      value: "csio:SEF99",
    },
    {
      label: `Enhanced coverage option for selected clients`,
      value: "csio:SELCL",
    },
    { label: `Water and Sewer Lines Coverage`, value: "csio:SEWAT" },
    { label: `Sewer Back-up Coverage`, value: "csio:SEWER" },
    { label: `Sewer Back-up`, value: "csio:SewerBackup" },
    {
      label: `Exterior Sewer Line - Breakage of the exterior sewer water line.`,
      value: "csio:SEWLI",
    },
    { label: `Sewer Back-up Limitation`, value: "csio:SEWLM" },
    { label: `Solid Fuel Heat Warranty`, value: "csio:SFHW" },
    { label: `Shipper Contingent`, value: "csio:SHCO" },
    { label: `Show Animal(s)`, value: "csio:ShowAnimal" },
    { label: `Sign Floater`, value: "csio:Sign" },
    { label: `Sign`, value: "csio:SIGN" },
    { label: `Service Line Extension Endorsement`, value: "csio:SLEXT" },
    { label: `Solar Panel- Loss of Income`, value: "csio:SLPLI" },
    { label: `Small engine repair`, value: "csio:SmallEngineRepair" },
    { label: `SmartHome Systems`, value: "csio:SMART" },
    { label: `Snowmobiles - All Risks`, value: "csio:SNOAR" },
    { label: `Snow Blower`, value: "csio:SNOBL" },
    { label: `Snow Blower`, value: "csio:SNOLI" },
    { label: `Snowmobiles - Named Perils`, value: "csio:SNONP" },
    { label: `Weight of Snow and Ice`, value: "csio:SNOW" },
    { label: `Snow Removal`, value: "csio:SnowRemoval" },
    { label: `Soft Cost`, value: "csio:SOFTC" },
    { label: `Solar Panel`, value: "csio:SOLP" },
    // { label: `Specified Perils`, value: "csio:SP" },
    { label: `Spa Coverage`, value: "csio:SPA" },
    { label: `Spa  `, value: "csio:SPALE" },
    { label: `Spare Parts and Fuel`, value: "csio:SPARE" },
    { label: `Sprinkler Bylaw`, value: "csio:SPBYL" },
    { label: `Special`, value: "csio:SPC" },
    { label: `Special`, value: "csio:SPC" },
    {
      label: `Damage to Outdoor Swimming Pool & Attached Equipment`,
      value: "csio:SPDAM",
    },
    { label: `SPF6 - Standard Non-owned Automobile`, value: "csio:SPF6" },
    { label: `Swimming Pool or Pond`, value: "csio:SPLIA" },
    { label: `Special Loss Settlement`, value: "csio:SPLS" },
    { label: `Spare Parts`, value: "csio:SPPTS" },
    { label: `Selling Price`, value: "csio:SPR" },
    { label: `Sporting Equipment`, value: "csio:SPTEQ" },
    { label: `Enhanced Roadside Assistance Program`, value: "csio:SRAP" },
    { label: `Sales Representative`, value: "csio:SRE" },
    {
      label: `Surface Water – Damage caused by surface water.`,
      value: "csio:SRFCW",
    },
    { label: `Separate or Sub-contractor`, value: "csio:SSC" },
    { label: `Separate or Sub-contractor Exclusion`, value: "csio:SSCEX" },
    { label: `Stacking of Limit Endorsement`, value: "csio:STALE" },
    { label: `Standard Business Income`, value: "csio:StandardForm" },
    { label: `Stated Amount`, value: "csio:StatedAmount" },
    { label: `Short Term Cancellation Table`, value: "csio:STCTG" },
    { label: `Structures`, value: "csio:STCTR" },
    { label: `Standard Excess Automobile Policy`, value: "csio:StdExcessAuto" },
    { label: `Stableman's Liability`, value: "csio:STL" },
    { label: `Stock`, value: "csio:Stock" },
    { label: `Sugar Shack With Reception Hall`, value: "csio:SUGS" },
    { label: `Supplementary Lodging`, value: "csio:SUPLD" },
    { label: `Absentee Landlord (Rented Dwelling)`, value: "csio:SURAB" },
    { label: `Occupancy`, value: "csio:SURAI" },
    { label: `Age of Structure`, value: "csio:SURAS" },
    { label: `Combined Driver Age/Risk Type Surcharge`, value: "csio:SURAT" },
    { label: `Secondary/Auxiliary Heating`, value: "csio:SURAU" },
    { label: `Surcharge - Antique Vehicle`, value: "csio:SURAV" },
    { label: `Surcharge - Business Use`, value: "csio:SURBU" },
    { label: `Business Use`, value: "csio:SURBU" },
    { label: `Surcharge – Conviction/Claim`, value: "csio:SURC2" },
    { label: `Surcharge - Prior Policy Cancellation`, value: "csio:SURCA" },
    { label: `Prior Policy Cancellation`, value: "csio:SURCA" },
    {
      label: `Surcharge - Claims for Occasional Driver (class 06/05)`,
      value: "csio:SURCC",
    },
    {
      label: `Surcharge - Convictions for Occasional Driver (class 06/05)`,
      value: "csio:SURCD",
    },
    { label: `Surcharge - Commercial Exposure`, value: "csio:SURCE" },
    { label: `Commercial Exposure`, value: "csio:SURCE" },
    { label: `Coverage Ineligible`, value: "csio:SURCI" },
    { label: `Surcharge - Claims`, value: "csio:SURCL" },
    { label: `Claims`, value: "csio:SURCL" },
    { label: `Surcharge - No. of Comprehensive Claims`, value: "csio:SURCM" },
    { label: `Surcharge - Convictions`, value: "csio:SURCN" },
    {
      label: `Surcharge - Conviction/Claim for an Occasional Driver (class 06/05)`,
      value: "csio:SURCO",
    },
    { label: `Surcharge - Car Pooling`, value: "csio:SURCP" },
    { label: `Surcharge - Criminal Record`, value: "csio:SURCR" },
    { label: `Surcharge - Risk Premium Cap `, value: "csio:SURCX" },
    { label: `Surcharge - Risk Premium Cap `, value: "csio:SURCX" },
    { label: `Electrical surcharges`, value: "csio:SUREC" },
    { label: `Commercial Exposure High`, value: "csio:SUREH" },
    { label: `Commercial Exposure Low`, value: "csio:SUREL" },
    { label: `Commercial Exposure Medium`, value: "csio:SUREM" },
    { label: `Surcharge - Experience Operator`, value: "csio:SUREO" },
    {
      label: `Surcharge - Explosive/Radioactive Material`,
      value: "csio:SURER",
    },
    { label: `Surcharge - Facility `, value: "csio:SURFA" },
    { label: `Surcharge - Filing Certificate`, value: "csio:SURFC" },
    { label: `Hail Damage Susceptibility`, value: "csio:SURHD" },
    { label: `Primary Heating`, value: "csio:SURHE" },
    { label: `Surcharge - High Kilometres Driven`, value: "csio:SURHK" },
    { label: `Surcharge - High Performance`, value: "csio:SURHP" },
    { label: `Surcharge - High Value`, value: "csio:SURHV" },
    { label: `Surcharge - High Value`, value: "csio:SURHV" },
    {
      label: `Surcharge - Inconsistent Insurance History`,
      value: "csio:SURIH",
    },
    { label: `Inconsistent Insurance History`, value: "csio:SURIH" },
    { label: `Surcharge – Discretionary Liability`, value: "csio:SURLB" },
    { label: `Location (For loss history or geographic)`, value: "csio:SURLC" },
    { label: `Log Home`, value: "csio:SURLG" },
    { label: `Surcharge - License Suspension`, value: "csio:SURLS" },
    { label: `Surcharge Leased Vehicle`, value: "csio:SURLV" },
    { label: `Surcharge - Miscellaneous`, value: "csio:SURMC" },
    { label: `Miscellaneous`, value: "csio:SURMC" },
    { label: `Multiple Family`, value: "csio:SURMF" },
    { label: `Surcharge - Mobile Home`, value: "csio:SURMH" },
    { label: `Surcharge - Mono-line Policy`, value: "csio:SURML" },
    { label: `Mono-line Policy`, value: "csio:SURML" },
    { label: `Surcharge - Multiple Operators`, value: "csio:SURMO" },
    { label: `Surcharge - Multiple Units`, value: "csio:SURMU" },
    { label: `Surcharge - Non Payment`, value: "csio:SURNP" },
    { label: `Non-payment`, value: "csio:SURNP" },
    { label: `Surcharge - Oversize Engine`, value: "csio:SUROE" },
    { label: `Surcharge - Facility (Occasional Driver)`, value: "csio:SUROF" },
    { label: `Age/Condition of Home`, value: "csio:SUROH" },
    { label: `Surcharge - Out of Province`, value: "csio:SUROP" },
    { label: `Plumbing surcharges`, value: "csio:SURPB" },
    { label: `Surcharge - No Previous Insurance`, value: "csio:SURPN" },
    { label: `No Previous Insurance`, value: "csio:SURPR" },
    { label: `Surcharge - Personal Watercraft`, value: "csio:SURPW" },
    { label: `Surcharge - Radius`, value: "csio:SURRA" },
    { label: `Roof surcharges`, value: "csio:SURRF" },
    { label: `Surcharge - Right Hand Drive`, value: "csio:SURRH" },
    { label: `Surcharge - Below Grade`, value: "csio:SURRL" },
    { label: `Surcharge - Recreational Vehicle `, value: "csio:SURRV" },
    { label: `Risk Surcharge Cap`, value: "csio:SURSC" },
    { label: `Risk Surcharge Cap`, value: "csio:SURSC" },
    { label: `Surcharge - Standalone Dwelling`, value: "csio:SURSD" },
    { label: `Surcharge - Sand & Gravel Operations`, value: "csio:SURSG" },
    { label: `Surcharge - Seasonal Occupancy`, value: "csio:SURSO" },
    { label: `Surcharge - Short-Term Rental`, value: "csio:SURSR" },
    { label: `Surcharge - Tows Non-Owned Trailer`, value: "csio:SURTN " },
    { label: `Surcharge - Telematics`, value: "csio:SURTE" },
    { label: `Surcharge - Under Construction`, value: "csio:SURUC" },
    { label: `Surcharge - U.S. & Non-Canadian Exposure`, value: "csio:SURUS" },
    { label: `Water Claim Surcharge`, value: "csio:SURWA" },
    { label: `Shared Water Well`, value: "csio:SWELL" },
    { label: `Temporary Additional Location Endorsement`, value: "csio:TADLE" },
    { label: `Target Commodities`, value: "csio:TARCO" },
    { label: `Theft Damage To Buildings`, value: "csio:TDB" },
    { label: `Trust Endorsement`, value: "csio:TEC" },
    {
      label: `Coverage D - Tenants Legal Liability`,
      value: "csio:TenantsLegal",
    },
    { label: `Termination Endorsement`, value: "csio:Termination" },
    { label: `Terrorism Exclusion`, value: "csio:Terrorism" },
    { label: `Testing`, value: "csio:TEST" },
    {
      label: `One to Six Family Dwelling Premises Liability`,
      value: "csio:TFFDP",
    },
    { label: `Theft`, value: "csio:THEFT" },
    {
      label: `Theft of Building Materials Under Construction`,
      value: "csio:THFBM",
    },
    { label: `Theft and Burglary`, value: "csio:THFBU" },
    { label: `Theft Exclusion`, value: "csio:THFTX" },
    { label: `Third Party Endorsement`, value: "csio:ThirdParty" },
    { label: `Tenants Improvements and Betterments`, value: "csio:TIB" },
    { label: `Tires`, value: "csio:TIRES" },
    { label: `Temporary Locations`, value: "csio:TLO" },
    { label: `Transportation Network`, value: "csio:TNC" },
    { label: `Tools - Employee's`, value: "csio:TOOLE" },
    { label: `Hand Tools Coverage`, value: "csio:TOOLH" },
    { label: `Tools - Leased`, value: "csio:TOOLL" },
    { label: `Tools - Owned`, value: "csio:TOOLO" },
    { label: `Total Pollution Exclusion`, value: "csio:TotalPollutionExcl" },
    // { label: `Third Party Liability / Civil Liability`, value: "csio:TP" },
    // { label: `Third Party Liability - Bodily Injury`, value: "csio:TPBI" },
    // {
    //   label: `Third Party Liability - Direct Compensation Property Damage`,
    //   value: "csio:TPDC",
    // },
    // {
    //   label: `Third Party Liability - Bodily Injury (Occasional Driver)`,
    //   value: "csio:TPOBI",
    // },
    // {
    //   label: `Third Party Liability / Civil Liability (Occasional Driver)`,
    //   value: "csio:TPOD",
    // },
    // {
    //   label: `Third Party Liability – Direct Compensation Property Damage (Occasional Driver)`,
    //   value: "csio:TPODC",
    // },
    // {
    //   label: `Third Party Liability – Property Damage (Occasional Driver)`,
    //   value: "csio:TPOPD",
    // },
    // { label: `Third Party Liability – Property Damage`, value: "csio:TPPD" },
    { label: `Third Party Liability - Passenger Hazard`, value: "csio:TPPHZ" },
    { label: `Trailer - All Risks`, value: "csio:TRAAR" },
    { label: `Tracks/trails`, value: "csio:TracksTrails" },
    { label: `Trail Rides`, value: "csio:TrailRides" },
    { label: `Trailer - Named Perils`, value: "csio:TRANP" },
    { label: `Transit`, value: "csio:TRANS" },
    { label: `Transit`, value: "csio:Transit" },
    { label: `Transportation Floater`, value: "csio:Transportation" },
    { label: `Emergency Medical Travel Insurance`, value: "csio:TRAVL" },
    { label: `Tree Removal Coverage   `, value: "csio:TRC" },
    { label: `Trip Transit (Commercial Inland Marine)`, value: "csio:TRNFC" },
    { label: `Trees, Plants & Shrubbery`, value: "csio:TRPLS" },
    {
      label: `Travel Trailers Temporary Attachments - All Risks`,
      value: "csio:TTAAR",
    },
    {
      label: `Travel Trailers Temporary Attachments - Named Perils`,
      value: "csio:TTANP",
    },
    { label: `Travel Trailers - All Risks`, value: "csio:TTAR" },
    { label: `Travel Tailer Broad Form`, value: "csio:TTBF" },
    { label: `Travel Trailers Contents - All Risks`, value: "csio:TTCAR" },
    { label: `Travel Trailers Contents - Named Perils`, value: "csio:TTCNP" },
    { label: `Emergency Living Expenses`, value: "csio:TTEML" },
    { label: `Emergency Road Service`, value: "csio:TTEMR" },
    { label: `Travel Trailers - Named Perils`, value: "csio:TTNP" },
    { label: `Personal Liability`, value: "csio:TTPL" },
    // { label: `Uninsured Automobile Coverage`, value: "csio:UA" },
    // {
    //   label: `Uninsured Automobile Coverage (Occasional Driver)`,
    //   value: "csio:UAOD",
    // },
    { label: `Umbrella Judgment Modification Factor`, value: "csio:UMF " },
    {
      label: `Uninsured Motorists and Underinsured Motorists Combined`,
      value: "csio:UMUIM ",
    },
    { label: `Underinsured Motorist Protection`, value: "csio:UNDMP" },
    {
      label: `Unlicensed Snow Vehicle, Trail Bike or ATV`,
      value: "csio:UNLIC",
    },
    { label: `Unnamed Locations`, value: "csio:UnnamedLocations" },
    { label: `Usage Based Insurance`, value: "csio:USBIE" },
    { label: `USA Products and/or Operations Exclusion `, value: "csio:USEXC" },
    { label: `Utility Tractor Endorsement`, value: "csio:UTRAC" },
    { label: `Utility Tractor Liability Extension`, value: "csio:UTRLI" },
    { label: `Vacant Land`, value: "csio:VACLD" },
    { label: `Vacancy Permit`, value: "csio:VACPR" },
    { label: `Vacancy Permit`, value: "csio:VACPR" },
    { label: `Valuable Papers and Records`, value: "csio:ValuablePapers" },
    { label: `Backwater Valve Maintenance Clause`, value: "csio:VALVE" },
    { label: `Vandalism / Theft by Tenants and Guest`, value: "csio:VANTH" },
    { label: `Vehicle/Boat Storage`, value: "csio:VehBoatStorage" },
    { label: `Vehicles Unlicensed`, value: "csio:VEHUNL" },
    { label: `Veterinarians`, value: "csio:Veterinarians" },
    { label: `Voluntary Property Damage`, value: "csio:VLPD" },
    { label: `Malicious Damage - Building & Contents`, value: "csio:VMBC" },
    { label: `Malicious Damage on Contents`, value: "csio:VMC" },
    { label: `Malicious Damage on Building`, value: "csio:VMDB" },
    { label: `Vandalism on Seasonal Dwelling`, value: "csio:VNDSD" },
    {
      label: `Volunteers As Additional Insureds`,
      value: "csio:VolAdditionalInsured",
    },
    {
      label: `Voluntary Compensation Rider ($100 Weekly Indemnity)`,
      value: "csio:VoluntaryComp",
    },
    { label: `Voluntary Compensation `, value: "csio:VoluntaryComp" },
    { label: `Vacancy Permit`, value: "csio:VPE" },
    { label: `Vandalism by Tenants Exclusion`, value: "csio:VTEXC" },
    { label: `Watercraft Liability`, value: "csio:WALI2" },
    { label: `Watercraft Liability`, value: "csio:WALI3" },
    { label: `Watercraft Liability`, value: "csio:WALI4" },
    { label: `Watercraft Liability `, value: "csio:WALI5" },
    { label: `Watercraft Liability`, value: "csio:WALI6" },
    { label: `Watercraft Liability`, value: "csio:WALI7" },
    { label: `Watercraft Liability`, value: "csio:WALI8" },
    { label: `Watercraft Liability`, value: "csio:WALI9" },
    { label: `Watercraft Liability`, value: "csio:WALIA" },
    { label: `Waived Nominal Premium `, value: "csio:WAPRE" },
    { label: `Waived Premium`, value: "csio:WAPRE" },
    { label: `War Risk Liability`, value: "csio:WAR" },
    { label: `Water Damage Endorsement`, value: "csio:WATER" },
    { label: `Water Damage Deductible Endorsement`, value: "csio:WaterDmgDed" },
    { label: `Waterbed`, value: "csio:WBED" },
    { label: `Waterbed`, value: "csio:WBLIA" },
    {
      label: `Caregiver & Dependant Care Benefits "No Longer used as of Sep 1/2010"`,
      value: "csio:WCB",
    },
    {
      label: `Water Damage Deductible - Specified Deductible for loss caused by Water. `,
      value: "csio:WDDED",
    },
    { label: `Water Damage Exclusion`, value: "csio:WDE" },
    {
      label: `Welding Cutting and Open Flame Warranty Endorsement`,
      value: "csio:WeldingEnd",
    },
    { label: `Wharves and Docks`, value: "csio:WHADO" },
    { label: `Wind & Hail Exclusion`, value: "csio:WHEXC" },
    { label: `Total Disability Max. Weekly Benefit`, value: "csio:WI" },
    // { label: `Income Replacement Benefits`, value: "csio:WIB" },
    // { label: `Income Replacement for Occasional Driver`, value: "csio:WIBOD" },
    { label: `Windstorm/Hail`, value: "csio:WIND" },
    { label: `Wind Coverage Endorsement`, value: "csio:WINDC" },
    { label: `Wind Energy `, value: "csio:WINDL" },
    {
      label: `DEPRECATED Worldwide Travel Life Insurance`,
      value: "csio:WLIFE",
    },
    { label: `Damage Caused by Wild Life`, value: "csio:WLIFE" },
    { label: `Warehouseman's Legal Liability`, value: "csio:WLL" },
    { label: `Wood/Maple Syrup Sales`, value: "csio:WMapleSyrup" },
    { label: `Wine & Spirits Endorsement`, value: "csio:WNSPT" },
    { label: `World Wide Coverage`, value: "csio:WorldWideCoverage" },
    { label: `Wreckage Value`, value: "csio:WRECK" },
    { label: `Removed Heat Warranty`, value: "csio:WSHWA" },
    { label: `Wood Stove Limitation`, value: "csio:WSLIM" },
    { label: `Wood Stove Warranty`, value: "csio:WSWAR" },
    { label: `Wreckage Value`, value: "csio:WVA" },
    { label: `Breakage Exclusion`, value: "csio:XBRK" },
    {
      label: `Marring, Denting, Chipping & Scratching Exclusion`,
      value: "csio:XDENT",
    },
    { label: `Discoloration & Oxidation Exclusion`, value: "csio:XDISC" },
    { label: `Mechanical / Electrical Exclusion`, value: "csio:XMECH" },
    { label: `Rust Exclusion`, value: "csio:XRUST" },
    { label: `Extended Theft Away from Premises`, value: "csio:XTFT" },
    {
      label: `Automatic Fire Suppression System - Accidental Discharge`,
      value: "csio:AFSS",
    },
    { label: `Eco-Friendly Enhancement`, value: "csio:ECO" },
    { label: `Electronic Data Processing Research Costs`, value: "csio:EDPRC" },
    { label: `Exterior Paving`, value: "csio:EXPAV" },
    { label: `Home Office`, value: "csio:HOFF" },
    { label: `Lost Key`, value: "csio:LKY" },
    { label: `Preservation of Property`, value: "csio:POP" },
    { label: `Professional Fees`, value: "csio:PF" },
    { label: `Property Manager's Claim Expense`, value: "csio:PMCE" },
    { label: `Business Income from Dependent Properties`, value: "csio:BIDP" },
    { label: `Business Income from Neighbouring Property`, value: "csio:BINP" },
    { label: `Business Income - Professional Fees`, value: "csio:BIPF" },
    {
      label: `Increased Period of Restoration Due to By-Laws`,
      value: "csio:PRDRB",
    },
    { label: `Loss of Use Blanket`, value: "csio:LSBLK" },
    {
      label: `Internet Service Provider Breakdown`,
      value: "csio:ISPBreakdown",
    },
    {
      label: `Property Pertaining to a Business (Not premises specific)`,
      value: "csio:BUSPP",
    },
    {
      label: `Damage Caused by Hail or Wind Excluding the Roof`,
      value: "csio:WINDX",
    },
    { label: `Accident Insurance: Limit per claim`, value: "csio:ACCLC" },
    { label: `Liability Buy Back Coverage`, value: "csio:LEADB" },
    { label: `Theft`, value: "csio:THEFT" },
    { label: `Cameras`, value: "csio:CCCM" },
    { label: `China/Crystal`, value: "csio:CCCC" },
    { label: `Fine Arts`, value: "csio:CCFA" },
    { label: `Firearms`, value: "csio:CCFM" },
    { label: `Musical Instruments`, value: "csio:CCMI" },
    { label: `Property Loss Assessment/Contingency`, value: "csio:PLAC" },
    { label: `Cyber Incident Exclusion Endorsement`, value: "csio:CIEE" },
    {
      label: `Elevator Collision Extension/Hoist Collision Extension`,
      value: "csio:ElevatorHoistExt",
    },
    { label: `High Theft Risk`, value: "csio:SURHT" },
    { label: `High Theft Risk`, value: "csio:SURHT" },
    { label: `OEM Replacement Parts`, value: "csio:OEMA" },
    {
      label: `Undivided Right of Ownership - Unequal Percentages`,
      value: "csio:UROUP",
    },
    {
      label: `Undivided Right of Ownership - Equal Percentages`,
      value: "csio:UROEP",
    },
    {
      label: `AB-SEF8(B) - Named Person Property Damage Reimbursement Endorsement (Section A – Third Party Liability)`,
      value: "csio:8B",
    },
    {
      label: `AB-SEF19(B) - Limitation of Amount Endorsement`,
      value: "csio:19B",
    },
    {
      label: `AB-SEF28(C) - Named Person(s) Reduction of Coverage Endorsement`,
      value: "csio:28C",
    },
    {
      label: `AB-SEF30(B) - Attached Machinery, Apparatus or Equipment Exclusion Endorsement Section A- Third Party Liability and Section B- Accident Benefits`,
      value: "csio:30B",
    },
    {
      label: `AB-SEF39(A) - At-Fault Accident Waiver Endorsement `,
      value: "csio:39A",
    },
    { label: `AB-SEF49(A) DCPD Deletion Endorsement`, value: "csio:49A" },
    {
      label: `AB-SEF49(B) Blanket Basis DCPD Deletion Endorsement`,
      value: "csio:49B",
    },
    { label: `Trailer (Non-Travel)  - Named Perils`, value: "csio:TRANP" },
    { label: `Trailer (Non-Travel) - All Risk`, value: "csio:TRAAR" },
    { label: `Home Settlement Benefit`, value: "csio:HSB" },
    { label: `Benefits for Group Members`, value: "csio:BNGRP" },
    {
      label: `Deductible - Water Infiltrations Through The Roof`,
      value: "csio:WRDED",
    },
    { label: `Water Damage - Above Ground Water `, value: "csio:WDAGW" },
    {
      label: `Deductible - Water Damage By The Water Heater`,
      value: "csio:WHDED",
    },
    { label: `Pet Emergency Endorsement`, value: "csio:PET" },
    { label: `Marine Assistance`, value: "csio:MRNAS" },
    { label: `Personal Property – Watercraft`, value: "csio:PPWAT" },
    { label: `Emergency towing`, value: "csio:ETW" },
    { label: `Navigational Territory Extension`, value: "csio:NGTR" },
    { label: `Annual Storage`, value: "csio:STORG" },
    { label: `Named Storm Exclusion`, value: "csio:NMSTM" },
    { label: `Propeller Exclusion`, value: "csio:PRPEX" },
    { label: `Boat Trailer Liability`, value: "csio:TRLIA" },
    { label: `Accidental Death`, value: "csio:ADE" },
    { label: ` Wrapping Coverage`, value: "csio:WRAP" },
    { label: `Tail Shaft Exclusion`, value: "csio:TSHEX" },
  ],
  CoverageClaim: [
    { label: "BI", value: "csio:TPBI" },
    { label: "PD", value: "csio:TPPD" },
    { label: "AB", value: "csio:AB" },
    { label: "DCPD", value: "csio:TPDC" },
    { label: "UA", value: "csio:UA" },
    { label: "COL", value: "csio:COL" },
    { label: "COMP", value: "csio:CMP" },
  ],
};
//Coverage Codes
export const validCodes = [
  "csio:2",
  "csio:3",
  "csio:5",
  "csio:7",
  "csio:8",
  "csio:9",
  "csio:10",
  "csio:11",
  "csio:12",
  "csio:14",
  "csio:16",
  "csio:17",
  "csio:19",
  "csio:20",
  "csio:22",
  "csio:24",
  "csio:25",
  "csio:26",
  "csio:27",
  "csio:28",
  "csio:29",
  "csio:30",
  "csio:31",
  "csio:32",
  "csio:33",
  "csio:34",
  "csio:35",
  "csio:36",
  "csio:37",
  "csio:38",
  "csio:39",
  "csio:40",
  "csio:41",
  "csio:43",
  "csio:44",
  "csio:45",
  "csio:46",
  "csio:47",
  "csio:48",
  "csio:49",
  "csio:62",
  "csio:63",
  "csio:103",
  "csio:114",
  "csio:115",
  "csio:110",
  "csio:113",
  "csio:13A",
  "csio:13B",
  "csio:13C",
  "csio:13D",
  "csio:13H",
  "csio:13S",
  "csio:15A",
  "csio:15B",
  "csio:15C",
  "csio:18",
  "csio:19A",
  "csio:20A",
  "csio:21B",
  "csio:21D",
  "csio:23A",
  "csio:23B",
  "csio:25A",
  "csio:27A",
  "csio:27B",
  "csio:28A",
  "csio:30A",
  "csio:32A",
  "csio:32B",
  "csio:32C",
  "csio:34A",
  "csio:34A-B",
  "csio:34B",
  "csio:37A",
  "csio:37B",
  "csio:39B",
  "csio:43A",
  "csio:43B",
  "csio:43C",
  "csio:43D",
  "csio:43E",
  "csio:43F",
  "csio:43L",
  "csio:43O",
  "csio:43R",
  "csio:43RL",
  "csio:43S",
  "csio:4-41",
  // 'csio:44R',
  "csio:4A",
  "csio:4B",
  "csio:5A",
  "csio:5B",
  "csio:5C",
  "csio:5CS",
  "csio:5D",
  "csio:6A",
  "csio:6B",
  "csio:6C",
  "csio:6D",
  "csio:6E",
  "csio:6F",
  "csio:87",
  "csio:8A",
  "csio:98B",
  "csio:99",
  // 'csio:AB',
  // 'csio:ABOD',
  "csio:ACB",
  "csio:ACBOD",
  "csio:ADB",
  "csio:AFMAC",
  "csio:AMPD",
  "csio:AOBCL",
  // 'csio:AP',
  // 'csio:APOD',
  "csio:AUDHC",
  "csio:AUHDE",
  "csio:CATCH",
  "csio:CATDC",
  "csio:CATIM",
  // 'csio:CATOD',
  "csio:CCMVT",
  "csio:CDCE",
  "csio:CEDED",
  "csio:CELLP",
  "csio:CHD",
  // 'csio:CHHMB',
  // 'csio:CHHOD',
  // 'csio:CIMOD',
  "csio:CIMRB",
  "csio:CLADV",
  "csio:CLVEH",
  // 'csio:CMP',
  // 'csio:COL',
  // 'csio:COLOD',
  "csio:COVEH",
  "csio:CPP",
  "csio:CPPE",
  "csio:CPPO",
  "csio:CPPX",
  // 'csio:DCB',
  // 'csio:DCBOD',
  "csio:DECVL",
  // 'csio:DFB',
  // 'csio:DFBOD',
  "csio:DFP",
  "csio:DISAA",
  "csio:DISAB",
  "csio:DISAD",
  "csio:DISAL",
  "csio:DISAM",
  "csio:DISAS",
  "csio:DISAT",
  "csio:DISAV",
  "csio:DISBM",
  "csio:DISBP",
  "csio:DISBP",
  "csio:DISBS",
  "csio:DISCF",
  "csio:DISCL",
  "csio:DISCM",
  "csio:DISCO",
  "csio:DISCP",
  "csio:DISCR",
  "csio:DISCX",
  "csio:DISDA",
  "csio:DISDC",
  "csio:DISDM",
  "csio:DISDO",
  "csio:DISDR",
  "csio:DISDS",
  "csio:DISDT",
  "csio:DISED",
  "csio:DISEL",
  "csio:DISEM",
  "csio:DISET",
  "csio:DISFA",
  "csio:DISFC",
  "csio:DISFM",
  "csio:DISFO",
  "csio:DISFR",
  "csio:DISFY",
  "csio:DISGD",
  "csio:DISGN",
  "csio:DISGO",
  "csio:DISGR",
  "csio:DISGS",
  "csio:DISHL",
  "csio:DISHY",
  "csio:DISIP",
  "csio:DISLC",
  "csio:DISLM",
  "csio:DISMC",
  "csio:DISMG",
  "csio:DISMH",
  "csio:DISMH ",
  "csio:DISMI",
  "csio:DISMM",
  "csio:DISMO",
  "csio:DISMP",
  "csio:DISMS",
  "csio:DISMV",
  "csio:DISNA",
  "csio:DISNB",
  "csio:DISNC",
  "csio:DISND",
  "csio:DISNO",
  "csio:DISNS",
  "csio:DISNV",
  "csio:DISOC",
  "csio:DISOD",
  "csio:DISOF",
  "csio:DISOP",
  "csio:DISOR",
  "csio:DISOW",
  "csio:DISPP",
  "csio:DISPR",
  "csio:DISPW",
  "csio:DISRA",
  "csio:DISRC",
  "csio:DISRD",
  "csio:DISRM",
  "csio:DISRN",
  "csio:DISRO",
  "csio:DISRV",
  "csio:DISSC",
  "csio:DISSN",
  "csio:DISSR",
  "csio:DISST",
  "csio:DISSV",
  "csio:DISTP",
  "csio:DISTR",
  "csio:DISTX",
  "csio:DISVC",
  "csio:DISVO",
  "csio:DISYD",
  "csio:DRALX",
  "csio:DRBLO",
  "csio:FBTEX",
  "csio:HLDNT",
  "csio:HSL",
  // 'csio:IDB',
  // 'csio:IDBOD',
  "csio:LSMVT",
  "csio:LWD",
  "csio:MAC",
  "csio:MBCDE",
  "csio:MBLRI ",
  "csio:MBVLP",
  "csio:MBVLU",
  "csio:MBVPL",
  "csio:MCP",
  "csio:MCPOD",
  "csio:MCPOX",
  "csio:MCPX",
  "csio:MED",
  "csio:MEDRH",
  "csio:MID",
  "csio:MOACE",
  "csio:MOVAE",
  // 'csio:MRB',
  // 'csio:MRBOD',
  "csio:MRHOD",
  "csio:ODRAX",
  "csio:ODRBL",
  "csio:OFFRD",
  "csio:ONEDE",
  "csio:OVRAC",
  "csio:PAK01",
  "csio:PAK02",
  "csio:PAK03",
  "csio:PAK04",
  "csio:PAK05",
  "csio:PAK06",
  "csio:PAK07",
  "csio:PAK08",
  "csio:PAK09",
  "csio:PAK10",
  "csio:PAK11",
  "csio:PAK12",
  "csio:PAK13",
  "csio:PAK14",
  "csio:PAK15",
  "csio:PAK16",
  "csio:PAK17",
  "csio:PARF",
  "csio:PEPRE",
  "csio:PERSE",
  "csio:PRM",
  "csio:PS",
  "csio:RESTO",
  "csio:RHG",
  "csio:RRP",
  // 'csio:SP',
  "csio:SPPTS",
  "csio:SRAP",
  "csio:STCTG",
  "csio:SURAT",
  "csio:SURAV",
  "csio:SURBU",
  "csio:SURC2",
  "csio:SURCA",
  "csio:SURCC",
  "csio:SURCD",
  "csio:SURCE",
  "csio:SURCL",
  "csio:SURCM",
  "csio:SURCN",
  "csio:SURCO",
  "csio:SURCP",
  "csio:SURCX",
  "csio:SUREO",
  "csio:SURER",
  "csio:SURFA",
  "csio:SURFC",
  "csio:SURHK",
  "csio:SURHP",
  "csio:SURHV",
  "csio:SURIH",
  "csio:SURLS",
  "csio:SURLV",
  "csio:SURMC",
  "csio:SURML",
  "csio:SURMO",
  "csio:SURNP",
  "csio:SUROE",
  "csio:SUROF",
  "csio:SUROP",
  "csio:SURPN",
  "csio:SURRA",
  "csio:SURRH",
  "csio:SURRV",
  "csio:SURSC",
  "csio:SURSG",
  "csio:SURTN ",
  "csio:SURTE",
  "csio:SURUS",
  "csio:TNC",
  "csio:TP",
  // 'csio:TPBI',
  // 'csio:TPDC',
  // 'csio:TPOBI',
  "csio:TPOD",
  // 'csio:TPODC',
  // 'csio:TPOPD',
  // 'csio:TPPD',
  "csio:TPPHZ",
  // 'csio:UA',
  // 'csio:UAOD',
  "csio:UNDMP",
  "csio:USBIE",
  "csio:WAPRE",
  "csio:WCB",
  "csio:WI",
  // 'csio:WIB',
  // 'csio:WIBOD',
  "csio:WLIFE",
  "csio:SURHT",
  "csio:OEMA",
  "csio:8B",
  "csio:19B",
  "csio:28C",
  "csio:30B",
  "csio:39A",
  "csio:49A",
  "csio:49B",

  // Add more valid codes here if needed
];

export const SHARED_EMAILS = [
  { label: "Aaxel Insurance", value: "aaxelmarketing@aaxelinsurance.com" },
  {
    label: "Aaxel Insurance - Accounts Team",
    value: "accounts@aaxelinsurance.com",
  },
  { label: "Aaxel Insurance - CL", value: "cl@aaxelinsurance.com" },
  { label: "Aaxel Insurance - PL", value: "pl@aaxelinsurance.com" },
  {
    label: "Aaxel Insurance - Claims",
    value: "aaxel.claims@aaxelinsurance.com",
  },
  {
    label: "Aaxel Insurance - Agency Bill",
    value: "agencybill@aaxelinsurance.com",
  },
];

export const TABLE_CACHING_KEYS = {
  LEADS: "LEADS",
  CUSTOMER: "CUSTOMER",
  OPPORTUNITY: "OPPORTUNITY",
  ALL_POLICY: "ALL_POLICY",
  G4_SIGN: "G4_SIGN",
  TASKS: "TASKS",
  NO_POLICY_INSURANCE_REPORTS: "NO_POLICY_INSURANCE_REPORTS",
  TASK_NOTICES: "TASK_NOTICES",
  CSIO: "CSIO",
  AUTOMATION: "AUTOMATION",
  ENV_CONFIG: "ENV_CONFIG",
  REPORTS: "REPORTS",
  TAX: "TAX",
  INVOICE: "INVOICE",
  AGENCY_BILL: "AGENCY_BILL",
  DIRECT_BILL: "DIRECT_BILL",
  RECONCILIATION: "RECONCILIATION",
  GROUP_PLANS: "GROUP_PLANS",
  QUOTE_SETTINGS: "QUOTE_SETTINGS",
  SUPPORT_TICKET: "SUPPORT_TICKET",
  BROKERAGE: {
    INSURER: "INSURER",
    CSIO_COMPANY: "CSIO_COMPANY",
    SUB_INSURER: "SUB_INSURER",
    POLICY_COMMISSION: "POLICY_COMMISSION",
    UNDER_WRITER: "UNDER_WRITER",
    CONTRACT_CODE: "CONTRACT_CODE",
    BROKER_CONTRACTS: "BROKER_CONTRACTS",
    BRANCH_OFFICE: "BRANCH_OFFICE",
    INTERESTED_PARTY: "INTERESTED_PARTY",
    ADDITIONAL_INTEREST: "ADDITIONAL_INTEREST",
    APPS_FORMS: "APPS_FORMS",
    LEGAL_PLUS: "LEGAL_PLUS",
    COMPANY_EQUIPMENT: "COMPANY_EQUIPMENT",
  },
  OLD_BUSINESS_TRACKER: "OLD_BUSINESS_TRACKER",
  OLD_INSURANCE_REPORTS: "OLD_INSURANCE_REPORTS",
  NOTICES: "NOTICES",
  RENWALS: "RENWALS",
  ALL_BINDERS: "ALL_BINDERS",
  NOTES: "NOTES",
  ALL_USERS: "ALL_USERS",
  STAFF: "STAFF",
  CHARTS: "CHARTS",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  CHEQUE: "CHEQUE",
  INVOICE_PAYMENT: "INVOICE_PAYMENT",
  TRANSACTION_DESCRIPTION: "TRANSACTION_DESCRIPTION",
  BILL: "BILL",
  BILL_PAYMENT: "BILL_PAYMENT",
  VENDOR: "VENDOR",
  CSIO_EDOCS: "CSIO_EDOCS",
  TRANSACTION_TYPE: "TRANSACTION_TYPE",
  ALL_APPLICATIONS: "ALL_APPLICATIONS",
  UTILITY: "UTILITY",
  UTILITY_HISTORY_PREVIEW: "UTILITY_HISTORY_PREVIEW",
  REQUEST_FORMS: "REQUEST_FORMS",
  TEMPLATE: "TEMPLATE",
  AUTOMOBILE: {
    INVOICE_PAYMENT: "INVOICE_PAYMENT",
    CSIO_EDOCS: "CSIO_EDOCS",
    DRIVER_INFO: "DRIVER_INFO",
    DRIVER_CLAIM_REPORT: "DRIVER_CLAIM_REPORT",
    POLICY_CHANGE_REQ: "POLICY_CHANGE_REQ",
  },
  HABITATIONAL: {
    INVOICE_PAYMENT: "INVOICE_PAYMENT",
    CSIO_EDOCS: "CSIO_EDOCS",
    POLICY_CHANGE_REQ: "POLICY_CHANGE_REQ",
  },
  COMM_AUTO: {
    INVOICE_PAYMENT: "INVOICE_PAYMENT",
    CSIO_EDOCS: "CSIO_EDOCS",
  },
  BUSINESS: {
    INVOICE_PAYMENT: "INVOICE_PAYMENT",
    CSIO_EDOCS: "CSIO_EDOCS",
  },
  CSIO_EDI: "CSIO_EDI",
  CSIO_EDI_MAPPING_LOGS_FORM: "CSIO_EDI_MAPPING_LOGS_FORM",
  AI_RECONCILLIATION_DETAILS: "AI_RECONCILLIATION_DETAILS",
  POLICY_CHANGE_REQ: "POLICY_CHANGE_REQ",
};
