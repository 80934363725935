import React from "react";
import { IBusinessPolicyLiability } from "../../../../../../redux/businessPolicy/businessPolicyForms/businessPolicyForms.types";
import { FormikErrors } from "formik";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomTextField } from "../../../../../../components/formsComponents";
import produce from "immer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { Add, Remove } from "@mui/icons-material";
import { getUniqueId } from "../../../../../../helpers";

interface ICSIOOtherLiabilitySectionProps {
  values: IBusinessPolicyLiability["other_liability_section_two"];

  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<IBusinessPolicyLiability>> | Promise<void>;
}

export const CSIOOtherLiabilitySection: React.FC<
  ICSIOOtherLiabilitySectionProps
> = ({ values, setFieldValue }) => {
  const handleOtherLiabilityChange =
    (rowIndex: number) => (columnKey: string, value: any) => {
      const finalLines = produce(
        values,
        (draftLines) => {
          draftLines[rowIndex][columnKey as "coverage"] = value;
        }
      );
      setFieldValue("other_liability_section_two", finalLines);
    };

  const handleOtherLiabilityCheckBoxChange =
    (rowIndex: number) => (checked: boolean) => {
      const newValues = produce(
        values,
        (draftvalues) => {
          draftvalues[rowIndex].type_of_insurance_check = checked ? "Y" : "N";
        }
      );

      setFieldValue("other_liability_section_two", newValues);
    };

  const handleOtherLiabilityAdd = () => {
    const finalLines = [...values];
    finalLines.push({
      key: getUniqueId(),
      type_of_insurance_check: "",
      type_of_insurance: "",
      coverage: "",
      deductible: "",
      amount: "",
    });
    setFieldValue("other_liability_section_two", finalLines);
  };

  const handleOtherLiabilityRemove = () => {
    const finalLines = [...values];
    finalLines.pop();
    setFieldValue("other_liability_section_two", finalLines);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Type of Insurance</TableCell>
              <TableCell>Coverage</TableCell>
              <TableCell>Deductible</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>
                <Box display={"flex"}>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={handleOtherLiabilityRemove}
                  >
                    <Remove fontSize="small" />
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOtherLiabilityAdd}
                  >
                    <Add fontSize="small" />
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((item, index) => {
              return (
                <Row
                  key={index}
                  row={item}
                  onCommericalAddMoreCheckBoxChange={handleOtherLiabilityCheckBoxChange(
                    index
                  )}
                  onCommericalAddMoreChange={handleOtherLiabilityChange(index)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Row: React.FC<{
  row: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  };
  onCommericalAddMoreChange: (columnKey: string, value: any) => void;
  onCommericalAddMoreCheckBoxChange: (value: boolean) => void;
}> = ({
  row,
  onCommericalAddMoreChange,
  onCommericalAddMoreCheckBoxChange,
}) => {
  const handleCommericalAddMoreChange =
    (columnKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onCommericalAddMoreChange(columnKey, e.target.value);
    };

  const handleCommericalAddMoreCheckBoxChange = (e: any, checked: boolean) => {
    onCommericalAddMoreCheckBoxChange(checked);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCommericalAddMoreCheckBoxChange}
              control={
                <Checkbox
                  name="send_eDocs_via_email"
                  checked={row.type_of_insurance_check === "Y"}
                />
              }
              label={
                <CustomTextField
                  fullWidth
                  value={row.type_of_insurance}
                  onChange={handleCommericalAddMoreChange("type_of_insurance")}
                />
              }
              sx={{ fontSize: 15 }}
            />
          </Box>
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.coverage}
            onChange={handleCommericalAddMoreChange("coverage")}
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.deductible}
            onChange={handleCommericalAddMoreChange("deductible")}
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.amount}
            onChange={handleCommericalAddMoreChange("amount")}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
