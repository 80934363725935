import React, { useState, useEffect, ChangeEvent } from "react";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { Box, Button, FormHelperText, Grid, Stack } from "@mui/material";

interface IBinderNumberProps {
  value: string | null;
  girdSize?: number;
  policyId?: number | null;
  error?: boolean;
  helperText?: string;
  onSave: (
    binderNumber: string | null,
    onCallBack: (isSucess: boolean) => void
  ) => void;
}

export const BinderNumber: React.FC<IBinderNumberProps> = (props) => {
  const { value, girdSize = 2.4, onSave, error, helperText } = props;
  const [binderNumber, setBinderNumber] = useState<string | null>(value);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBinderNumber(e.target.value);
  };
  const handleSaveClick = async () => {
    setIsSaving(true);
    onSave(binderNumber, (isSuccess) => {
      if (isSuccess) {
        setIsEditing(false);
      }
      setIsSaving(false);
    });
  };

  React.useEffect(() => {
    setBinderNumber(value);
  }, [value]);

  if (!props.policyId) {
    return null;
  }

  return (
    <Grid item xs={12} lg={girdSize}>
      <CustomFormLabel>Binder Number</CustomFormLabel>
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: "start", minHeight: "56px" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CustomTextField
            value={binderNumber}
            onChange={handleChange}
            disabled={!isEditing}
            fullWidth
            error={error}
          />
          {helperText && (
            <FormHelperText
              sx={{ color: error ? "error.main" : "text.secondary" }}
            >
              {helperText}
            </FormHelperText>
          )}
        </Box>

        {!isEditing ? (
          <Button
            variant="contained"
            sx={{ marginLeft: "5px", whiteSpace: "nowrap" }}
            onClick={handleEditClick}
            disabled={isSaving}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ marginLeft: "5px", whiteSpace: "nowrap" }}
            onClick={handleSaveClick}
            disabled={isSaving}
          >
            Save
          </Button>
        )}
      </Stack>
    </Grid>
  );
};
