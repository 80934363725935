import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getUserAuth } from "../../helpers";
import { action } from "typesafe-actions";
import {
  ADD_POLICY_COMMISSION,
  ADD_POLICY_COMMISSION_PROGRESS,
  CLEAR_POLICY_COMMISSION_STATE,
  FETCH_POLICY_COMMISSION_LIST_FAILED,
  FETCH_POLICY_COMMISSION_LIST_PROGRESS,
  FETCH_POLICY_COMMISSION_LIST_SUCCESS,
  FETCH_POLICY_COMMISSION_PROGRESS,
  FETCH_POLICY_COMMISSION_SUCCESS,
} from "../constants";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IPolicyCommission } from "./policyCommission.types";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { initialPolicyCommissioneState } from "./policyCommissionState";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const POLICY_COMMISSION_ERROR = "POLICY_COMMISSION_ERROR";
export const ADD_POLICY_COMMISSION_ERROR = "ADD_POLICY_COMMISSION_ERROR";

export const addPolicyCommissionProgress = () =>
  action(ADD_POLICY_COMMISSION_PROGRESS);

export const addPolicyCommission = (data: IPolicyCommission) =>
  action(ADD_POLICY_COMMISSION, { data: data });

export const addPolicyCommissionAsync =
  (
    data: IPolicyCommission,
    initialData: IPolicyCommission,
    isUpdate: boolean,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      const { create_ts, insert_ts, ...rest } = data;
      dispatch(saveLoaderProgress());
      if (token) {
        dispatch(addPolicyCommissionProgress());
        await makeApiCall(
          {
            url: endPoints.policyComission.upsertPolicyComission,
            method: "POST",
            automation: {
              isUpdate: isUpdate,
              primaryFieldName: "policy_commission_primary_id",
            },
          },
          initialData,
          rest
        );

        dispatch(addPolicyCommission(data));
        dispatch(
          showMessage({
            type: "success",
            message: "Policy Commission saved successfully!",
            displayAs: "snackbar",
          })
        );
        onSuccess();
      }
    } catch (err: any) {
      dispatch(policyCommissionError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchPolicyCommissionProgress = () =>
  action(FETCH_POLICY_COMMISSION_PROGRESS);

export const fetchPolicyCommissionSuccess = (data: IPolicyCommission) =>
  action(FETCH_POLICY_COMMISSION_SUCCESS, { data: data });

export const fetchPolicyCommissionAsync =
  (code: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchPolicyCommissionProgress());
        const res = await api.get(
          `insurance/get-policy-commission?policy_commission_primary_id=${code}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IPolicyCommission[] = res.data.data;
        if (data.length > 0) {
          dispatch(addPolicyCommission(data[0]));
        } else {
          dispatch(
            policyCommissionError(
              "Oops! We couldn't find any records associated with your policy commission at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchPolicyCommissionByCode =
  (
    code: string,
    onCallback: (data: IPolicyCommission | null) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPolicyCommissionProgress());
      const res = await api.get(
        `insurance/get-policy-commission?policy_code=${code}`
      );
      const data: IPolicyCommission[] = res.data.data;
      if (data.length > 0) {
        onCallback(data[0]);
      } else {
        onCallback(null);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchPolicyCommissionListProgress = () =>
  action(FETCH_POLICY_COMMISSION_LIST_PROGRESS);
export const fetchPolicyCommissionListSuccess = (list: IPolicyCommission[]) =>
  action(FETCH_POLICY_COMMISSION_LIST_SUCCESS, { list });
export const fetchPolicyCommissionListFailed = () =>
  action(FETCH_POLICY_COMMISSION_LIST_FAILED);

export const fetchPolicyCommissionListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPolicyCommissionListProgress());
      let finalUrl = `/insurance/get-policy-commission?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/insurance/get-policy-commission?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IPolicyCommission[] = res.data.data;

      dispatch(fetchPolicyCommissionListSuccess(data));
    } catch (err: any) {
      dispatch(fetchPolicyCommissionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const policyCommissionError = (message: string | null) =>
  action(POLICY_COMMISSION_ERROR, {
    message: message,
  });

export const clearPolicyCommissionState = () =>
  action(CLEAR_POLICY_COMMISSION_STATE);
