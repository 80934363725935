/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  isAlphaNumeric,
  renderDropdownValue,
} from "../../../../../../../helpers";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import {
  addAutomobileVehicleAsync,
  fetchAutomobileVehicleAsync,
} from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import {
  clearAutomobileDriverState,
  fetchAutomobileDriverListAsync,
} from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import {
  COMMON_STATUS,
  product_type_options,
  type_code_options,
  vehicle_type,
  vehicle_use,
  VEHICLE_VALIDATION_MESSAGES,
} from "../../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import {
  IAutomobileVehicleInformation,
  IAutomobileVehicleState,
} from "../../../../../../../redux/automobileVehicle/automobileVehicle.types";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import { useAutomobilePolicyDriverInfo } from "../../hooks/useAutomobilePolicyDriverInfo";

export const AutomobileVehicleInformation: React.FC<{
  customerId?: number;
  customerPolicyId?: number;
  vehicleNumber?: number;
  changeStep?: (value: number) => void;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  showOnlySaveButton?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess?: (data: IAutomobileVehicleInformation) => void;
  initialData?: IAutomobileVehicleInformation;
  disableApiCalls?: boolean;
}> = (props) => {
  const {
    customerId,
    customerPolicyId,
    vehicleNumber,
    readOnly = false,
    fromRightPanel = false,
    showOnlySaveButton = false,
    onSaveSuccess,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
  } = props;

  const { VehicleInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle
  );

  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.basicDetails.customerInfo.data
  );

  const { driverInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver
  );
  const {driverDropDown} = useAutomobilePolicyDriverInfo(customerPolicyId);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialData ? initialData : VehicleInformation?.data,

    validate: (values) => {
      const requiredFields: {
        key: keyof IAutomobileVehicleInformation;
        message: string;
      }[] = VEHICLE_VALIDATION_MESSAGES as {
        key: keyof IAutomobileVehicleInformation;
        message: string;
      }[];

      const errors: any = {};
      if (!values.vin) {
        errors.vin = "VIN is required field.";
      } else if (values.vin.length < 17 || values.vin.length > 17) {
        errors.vin = "VIN number must be 17 digits.";
      } else if (!isAlphaNumeric(values.vin)) {
        errors.vin = "VIN number must be alpha numeric.";
      }
      requiredFields.forEach(({ key, message }) => {
        if (!values[key]) {
          errors[key] = message;
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      if (disableApiCalls && onSaveSuccess) {
        onSaveSuccess(values);
      } else {
        if (customerId && customerPolicyId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setLoading(true);
          dispatch(
            addAutomobileVehicleAsync(
              {
                ...values,
                customer_id: customerId,
                customer_policy_id: customerPolicyId,
                status: statusValue,
                created_from: created_from || "Vehicle",
              },
              (isSuccess, data) => {
                if (onSaveSuccess && isSuccess && data) {
                  onSaveSuccess(data);
                } else {
                  if (isSuccess) {
                    if (buttonClicked === "submit") {
                      navigate(
                        `/automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}?tab=0`
                      );
                    } else {
                      window.scrollTo(0, 0);
                      if (props.changeStep) {
                        props.changeStep(1);
                      }
                    }
                  }
                }

                setLoading(false);
              }
            )
          );
        }
      }
    },
  });

  // const getLeasedValue = (owned: string | null): string | null => {
  //   if (owned === "No") return "No";
  //   if (owned === "Yes") return "Yes";
  //   return null;
  // };

  // const getOwnedValue = (leased: string | null): string | null => {
  //   if (leased === "No") return "No";
  //   if (leased === "Yes") return "Yes";
  //   return null;
  // };

  // Set initial form values with correct leased and owned logic
  React.useEffect(() => {
    if (!VehicleInformation?.data) return; // Prevents errors when data is undefined
  
    const { ...rest } = VehicleInformation.data;
  
    setValues((prevValues) => ({
      ...prevValues, // Keep previous values
      ...rest,
      customer_name: customerInfo?.customer_name || "",
      policy_number: customerInfo?.policy_number || "",
    }));
  }, [VehicleInformation?.data, customerInfo]);
  
  React.useEffect(() => {
    if (customerPolicyId && vehicleNumber) {
      dispatch(fetchAutomobileVehicleAsync(customerPolicyId, vehicleNumber));
    }
  }, [customerPolicyId, vehicleNumber]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverListAsync(customerPolicyId));
    }
    return () => {
      dispatch(clearAutomobileDriverState());
    };
  }, []);

 

  return (
    <PageLoader
      loading={VehicleInformation.loading === LoadState.InProgress}
      error={
        VehicleInformation.error
          ? { message: VehicleInformation.error }
          : undefined
      }
    >
      <form onSubmit={handleSubmit}>
        <FieldSet disabled={readOnly}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Number</CustomFormLabel>
              <CustomTextField
                name="incremental_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="customer_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.customer_name}
                disabled
                //onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Unit Number</CustomFormLabel>
              <CustomTextField
                name="unit_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.unit_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Year</CustomFormLabel>
              <CustomDatePicker
                views={["year"]}
                fullWidth
                value={values.year}
                inputFormat="YYYY"
                onChange={(newValue, a, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue("year", momentObject ? newDate : null);
                }}
                errorMessage={errors.year}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Make</CustomFormLabel>
              <CustomTextField
                name="make"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.make}
                error={errors.make ? true : false}
                helperText={errors.make}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Model</CustomFormLabel>
              <CustomTextField
                name="model"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.model}
                error={errors.model ? true : false}
                helperText={errors.model}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>VIN</CustomFormLabel>
              <CustomTextField
                name="vin"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.vin}
                error={errors.vin ? true : false}
                helperText={errors.vin}
                inputProps={{ maxLength: 17 }}
                onChange={(e) =>
                  setFieldValue("vin", e.target.value.toUpperCase())
                }
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Actual Cash Value</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.acutal_cash_value}
                onChange={(value) => {
                  setFieldValue("acutal_cash_value", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>List Price New</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.list_price_new}
                onChange={(value) => {
                  setFieldValue("list_price_new", value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Owned</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.owned}
                name="owned"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                error={errors.owned ? true : false}
                helperText={errors.owned}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Leased</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.leased}
                name="leased"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                error={errors.leased ? true : false}
                helperText={errors.leased}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Purchase Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.purchase_date || ""}
                onChange={(newValue) => {
                  setFieldValue("purchase_date", newValue);
                }}
                errorMessage={errors.purchase_date}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Purchase Condition</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.puchase_condition}
                name="puchase_condition"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["New", "Used", "Demo"].map((template) => {
                  return { label: template, value: template };
                })}
                error={errors.puchase_condition ? true : false}
                helperText={errors.puchase_condition}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Purchase price ($)</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.purchase_price}
                onChange={(value) => {
                  setFieldValue("purchase_price", value);
                }}
                error={errors.purchase_price ? true : false}
                helperText={errors.purchase_price}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Plate Number</CustomFormLabel>
              <CustomTextField
                name="plate_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.plate_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Code</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.vehicle_code_one}
                name="vehicle_code_one"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["Option 1", "Option 2"].map((template) => {
                  return { label: template, value: template };
                })}
                error={errors.vehicle_code_one ? true : false}
                helperText={errors.vehicle_code_one}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Code*</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.vehicle_code_two}
                name="vehicle_code_two"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["Option 1", "Option 2"].map((template) => {
                  return { label: template, value: template };
                })}
                // error={errors.vehicle_code_two ? true : false}
                // helperText={errors.vehicle_code_two}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="vehicle_type"
                fullWidth
                value={values.vehicle_type}
                onChange={handleChange}
                displayEmpty
                placeholder="Select one"
                options={vehicle_type.map((transactionType) => {
                  return { label: transactionType, value: transactionType };
                })}
                 error={errors.vehicle_type ? true : false}
                helperText={errors.vehicle_type}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Body Type</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.body_type}
                options={[
                  "Tow Truck",
                  "Tractor",
                  "Van",
                  "Trailer(Dry van)",
                  "Trailer(Reefer)",
                  "Chassis",
                  "Container",
                  "PPV",
                  "Pickup Truck",
                  "Service Van/ Truck",
                  "Trailer-Office Use",
                ].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("body_type", newValue);
                }}
                errorMessage={errors.body_type}
                error={errors.body_type ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Gross Vehicle Weight</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="gross_vehicle_weight"
                value={values.gross_vehicle_weight}
                onChange={handleChange}
                error={errors.gross_vehicle_weight ? true : false}
                helperText={errors.gross_vehicle_weight}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Location Code</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="location_code"
                value={values.location_code}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Fleet Identity</CustomFormLabel>
              <CustomTextField
                name="fleet_identity"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.fleet_identity}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Anti-Lock Brakes</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="anti_lock_brakes"
                value={values.anti_lock_brakes}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Fuel Type</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="fuel_type"
                value={values.fuel_type}
                onChange={handleChange}
                error={errors.fuel_type ? true : false}
                helperText={errors.fuel_type}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Territory Code</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="territory_code"
                value={values.territory_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Odometer</CustomFormLabel>
              <CustomTextField
                name="odometer"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.odometer}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Purchase Country</CustomFormLabel>
              <CustomTextField
                name="purchase_country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.purchase_country}
                onChange={handleChange}
                error={errors.purchase_country ? true : false}
                helperText={errors.purchase_country}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Registration Province</CustomFormLabel>
              <CustomTextField
                name="vehicle_registration_province"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.vehicle_registration_province}
                onChange={handleChange}
                error={errors.vehicle_registration_province ? true : false}
                helperText={errors.vehicle_registration_province}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Ownership of Vehicle</CustomFormLabel>
              <CustomTextField
                id="ownership"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.ownership}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Owner Operator or Lessee</CustomFormLabel>
              <ControlledCustomSelect
                name="owner_operator_or_lessee"
                value={values.owner_operator_or_lessee}
                onChange={handleChange}
                fullWidth
                displayEmpty
                placeholder="Select one"
                options={[
                  { label: "Owner Operator", value: "Owner Operator" },
                  { label: "Lessee", value: "Lessee" },
                ]}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Use of Vehicle" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Vehicle Use</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.vehicle_use}
                options={vehicle_use.map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("vehicle_use", newValue);
                }}
                errorMessage={errors.vehicle_use}
                error={errors.vehicle_use ? true : false}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Annual Distance</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                name="annual_distance"
                value={values.annual_distance}
                onChange={handleChange}
                error={errors.annual_distance ? true : false}
                helperText={errors.annual_distance}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Radius of Operation</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.radius_of_operation}
                options={[
                  "35",
                  "40",
                  "50",
                  "60",
                  "80",
                  "90",
                  "120",
                  "160",
                  "200",
                  "250",
                  "400",
                  "400+",
                ].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("radius_of_operation", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Commute One-way (Kms)</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="commute"
                value={values.commute}
                onChange={handleChange}
                error={errors.commute ? true : false}
                helperText={errors.commute}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Annual kms (Business)</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                name="annual_kms"
                value={values.annual_kms}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Business Use %</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="business_use_percentage"
                value={values.business_use_percentage}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Driver Assignment" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Driver Assignment Primary</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="driver_assignment_primary"
                value={Number(values.driver_assignment_primary)}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={driverDropDown}
                error={errors.driver_assignment_primary ? true : false}
                helperText={errors.driver_assignment_primary}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Driver Assignment Occassional</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="driver_assignment_occassional"
                value={Number(values.driver_assignment_occassional)}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={driverDropDown}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Driver Assignment Secondary One</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="driver_assignment_secondary_one"
                value={Number(values.driver_assignment_secondary_one)}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={driverDropDown}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Driver Assignment Secondary Two</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="driver_assignment_secondary_two"
                value={Number(values.driver_assignment_secondary_two)}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={driverDropDown}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Vehicle - Rate Groups/Class" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Coll/AP</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="rate_groups_or_class_coll_or_ap"
                value={values.rate_groups_or_class_coll_or_ap}
                onChange={handleChange}
                error={errors.rate_groups_or_class_coll_or_ap ? true : false}
                helperText={errors.rate_groups_or_class_coll_or_ap}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Comp/SP</CustomFormLabel>
              <CustomTextField
                name="rate_groups_or_class_comp_or_sp"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.rate_groups_or_class_comp_or_sp}
                onChange={handleChange}
                error={errors.rate_groups_or_class_comp_or_sp ? true : false}
                helperText={errors.rate_groups_or_class_comp_or_sp}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>AB</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="rate_groups_or_class_ab"
                value={values.rate_groups_or_class_ab}
                onChange={handleChange}
                error={errors.rate_groups_or_class_ab ? true : false}
                helperText={errors.rate_groups_or_class_ab}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>DCPD</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="rate_groups_or_class_dcpd"
                value={values.rate_groups_or_class_dcpd}
                onChange={handleChange}
                error={errors.rate_groups_or_class_dcpd ? true : false}
                helperText={errors.rate_groups_or_class_dcpd}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>TP</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="rate_groups_or_class_tp"
                value={values.rate_groups_or_class_tp}
                onChange={handleChange}
                error={errors.rate_groups_or_class_tp ? true : false}
                helperText={errors.rate_groups_or_class_tp}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Rating Class</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="rate_groups_or_class_rating_class"
                value={values.rate_groups_or_class_rating_class}
                onChange={handleChange}
                error={errors.rate_groups_or_class_rating_class ? true : false}
                helperText={errors.rate_groups_or_class_rating_class}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Driving Record" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>BI</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="driving_record_bi"
                value={values.driving_record_bi}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>PD</CustomFormLabel>
              <CustomTextField
                name="driving_record_pd"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.driving_record_pd}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>AB</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="driving_record_ab"
                value={values.driving_record_ab}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>DCPD</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="driving_record_dcpd"
                value={values.driving_record_dcpd}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Coll/AP</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                name="driving_record_coll_or_ap"
                value={values.driving_record_coll_or_ap}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Anti-Theft Devices" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Anti-Theft Devices</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.anti_theft_devices}
                options={["Y", "N"].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("anti_theft_devices", newValue);
                }}
                errorMessage={errors.anti_theft_devices}
                error={errors.anti_theft_devices ? true : false}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Anti-Theft Engraving Device MFR Code
              </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.anti_theft_engraving_dev_mfr_cd}
                options={["Y", "N"].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("anti_theft_engraving_dev_mfr_cd", newValue);
                }}
                errorMessage={errors.anti_theft_engraving_dev_mfr_cd}
                error={errors.anti_theft_engraving_dev_mfr_cd ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4}></Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Device Type Code One</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.device_type_code_one}
                options={type_code_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("device_type_code_one", newValue);
                }}
                errorMessage={errors.device_type_code_one}
                error={errors.device_type_code_one ? true : false}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Device Type Code Two</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.device_type_code_two}
                options={type_code_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("device_type_code_two", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Device Type Code Three</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.device_type_code_three}
                options={type_code_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("device_type_code_three", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Product Type Code One</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.product_type_code_one}
                options={product_type_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("product_type_code_one", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Product Type Code Two</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.product_type_code_two}
                options={product_type_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("product_type_code_two", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Product Type Code Three</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.product_type_code_three}
                options={product_type_options.map((template) => {
                  return { label: template.label, value: template.value };
                })}
                onChange={(newValue) => {
                  setFieldValue("product_type_code_three", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Characteristics Code One</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.characteristics_code_one}
                options={["Option 1", "Option 2"].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("characteristics_code_one", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Characteristics Code Two</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.characteristics_code_two}
                options={["Option 1", "Option 2"].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("characteristics_code_two", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Characteristics Code Three</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.characteristics_code_three}
                options={["Option 1", "Option 2"].map((policyType) => {
                  return { label: policyType, value: policyType };
                })}
                onChange={(newValue) => {
                  setFieldValue("characteristics_code_three", newValue);
                }}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Vehicle Modifications" />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Modification</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.modification}
                name="modification"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Value of Modification</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.value_of_modification}
                onChange={(value) => {
                  setFieldValue("value_of_modification", value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Engine</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="engine"
                value={values.engine}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Ground Clearance</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="ground_clearance"
                value={values.ground_clearance}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Interior Rollcage</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="interior_rollcage"
                value={values.interior_rollcage}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Tires</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="tires"
                value={values.tires}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Coversion for Disabled Persons</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="coversion_for_disabled_persons"
                value={values.coversion_for_disabled_persons}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Sound Equipment</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="sound_equipment"
                value={values.sound_equipment}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Grid Rated</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="grid_rated"
                value={values.grid_rated}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Number of Cylinders</CustomFormLabel>
              <CustomTextField
                name="number_of_cylinders"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.number_of_cylinders}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Interior Custom Seats</CustomFormLabel>
              <CustomTextField
                name="interior_custom_seats"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.interior_custom_seats}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>No Frills</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="no_frills"
                value={values.no_frills}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
          </Grid>
          {!staticStatusValue && (
            <Grid container>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={COMMON_STATUS}
                />
              </Grid>
            </Grid>
          )}
          {showOnlySaveButton && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("submit")}
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          )}
          {!showOnlySaveButton && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("submit")}
              >
                Save & Exit
              </RoleBasedCustomButton>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("save")}
              >
                Save & Next
              </RoleBasedCustomButton>
            </Box>
          )}
        </FieldSet>
      </form>
    </PageLoader>
  );
};
