import React from "react";
import {
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import {
  IAutomobileDriverInformation,
  IDriverLicenseHistory,
} from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import produce from "immer";
import { FormikErrors } from "formik";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { CustomTextField } from "../../../../../../../components/formsComponents";
import { Add, Remove } from "@mui/icons-material";

interface LicenseHistoryTableProps {
  values: IAutomobileDriverInformation;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<IAutomobileDriverInformation>> | any;
  onAddNewLicenseHistory: (currentIndex: number) => void;
  onRemoveLicenseHistory: (index: number) => void;
  onLicenseHistoryRowChange: (
    key: keyof IDriverLicenseHistory,
    rowIndex: number,
    value: string
  ) => void;
  errors?: any;
}

const LicenseHistoryTable: React.FC<LicenseHistoryTableProps> = ({
  values,
  setFieldValue,
  errors,
  onAddNewLicenseHistory,
  onRemoveLicenseHistory,
  onLicenseHistoryRowChange,
}) => {
  const handleLicenseHistoryRowChange =
    (key: keyof IDriverLicenseHistory, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onLicenseHistoryRowChange(key, rowIndex, event.target.value);
    };

  const handleLicenseHistorySelectChange =
    (key: keyof IDriverLicenseHistory, rowIndex: number) =>
    (event: SelectChangeEvent<any>) => {
      onLicenseHistoryRowChange(key, rowIndex, event.target.value);
    };

  // const handleCurrencyRowChange =
  //   (key: keyof IInsuranceLapse, rowIndex: number) => (newValue: string) => {
  //     onInsuranceLapseRowChange(key, rowIndex, newValue);
  //   };

  const handleRemoveLicenseHistoryRow = (index: number) => () => {
    onRemoveLicenseHistory(index);
  };

  const handleLicenseHistoryNewRow = (currentRowIndex: number) => () => {
    onAddNewLicenseHistory(currentRowIndex);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TableContainer>
          <Table
            aria-label="collapsible table"
            sx={{
              whiteSpace: {
                xs: "nowrap",
                sm: "unset",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h5">
                    #
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h5">
                    Class Name
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="center">
                  <Typography fontWeight={600} variant="h5">
                    Date First Licensed
                  </Typography>
                </TableCell>

                <TableCell sx={{ width: "15%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Years Licensed
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Current License Country
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Province/State
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    License Number
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    License Expiry Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    License Type
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    License Status
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display={"flex"}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLicenseHistoryNewRow(
                        values.license_history.length + 1
                      )}
                    >
                      <Add fontSize="small" />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.license_history.map((item, index) => {
                return (
                  <TableRow key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography fontWeight={600} variant="h5">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ExtendableSelect
                        value={item.class}
                        onChange={(newValue) => {
                          handleLicenseHistorySelectChange(
                            "class",
                            index
                          )({
                            target: {
                              value: newValue,
                            },
                          } as any);
                        }}
                        placeholder="Select"
                        options={["AZ", "B", "D", "G", "G1", "G2"].map(
                          (template) => {
                            return { label: template, value: template };
                          }
                        )}
                        errorMessage={errors?.license_history?.[index]?.class}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.date_first_licensed || ""}
                        onChange={(newValue) =>
                          onLicenseHistoryRowChange(
                            "date_first_licensed",
                            index,
                            newValue
                          )
                        }
                        errorMessage={
                          errors?.license_history?.[index]?.date_first_licensed
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.year_licensed || ""}
                        onChange={(newValue) =>
                          onLicenseHistoryRowChange(
                            "year_licensed",
                            index,
                            newValue
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        id="country"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={item.current_license_country}
                        onChange={handleLicenseHistoryRowChange(
                          "current_license_country",
                          index
                        )}
                        helperText={
                          errors?.license_history?.[index]
                            ?.current_license_country
                        }
                        error={
                          errors?.license_history?.[index]
                            ?.current_license_country
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        id="country"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={item.province_or_state}
                        onChange={handleLicenseHistoryRowChange(
                          "province_or_state",
                          index
                        )}
                        helperText={
                          errors?.license_history?.[index]?.province_or_state
                        }
                        error={
                          errors?.license_history?.[index]?.province_or_state
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        id="country"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={item.license_number}
                        onChange={handleLicenseHistoryRowChange(
                          "license_number",
                          index
                        )}
                        helperText={
                          errors?.license_history?.[index]?.license_number
                        }
                        error={
                          errors?.license_history?.[index]?.license_number
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.license_expiry_date || ""}
                        onChange={(newValue) =>
                          onLicenseHistoryRowChange(
                            "license_expiry_date",
                            index,
                            newValue
                          )
                        }
                        // errorMessage={errors?.license_history?.[index]?.license_expiry_date}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField
                        id="country"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={item.license_type}
                        onChange={handleLicenseHistoryRowChange(
                          "license_type",
                          index
                        )}
                        helperText={
                          errors?.license_history?.[index]?.license_type
                        }
                        error={
                          errors?.license_history?.[index]?.license_type
                            ? true
                            : false
                        }
                      />
                    </TableCell>{" "}
                    <TableCell>
                      <CustomTextField
                        id="country"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={item.license_status}
                        onChange={handleLicenseHistoryRowChange(
                          "license_status",
                          index
                        )}
                        helperText={
                          errors?.license_history?.[index]?.license_status
                        }
                        error={
                          errors?.license_history?.[index]?.license_status
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"}>
                        <IconButton
                          color="error"
                          onClick={handleRemoveLicenseHistoryRow(
                            index
                            // handleRemoveInsuranceLapseRow(value.column.rowIndex
                          )}
                        >
                          <Remove fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={handleLicenseHistoryNewRow(index)}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default LicenseHistoryTable;
