/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";

import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { CustomerBaiscDetailsLayout } from "../../../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import { Breadcrumb } from "../../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import {
  addAutomobilAdditionalInfoAsync,
  fetchAutomobilAdditionalInfoVehicleAsync,
} from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../../../../components/Cards";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../../constants/enums";
import { IAutomobileAdditionalInformation } from "../../../../../../../redux/automobileVehicle/automobileVehicle.types";
import { VEHICLE_INTEREST_MESSAGES } from "../../../../../../../constants/constants";

export const AutomobileAdditionalInformation: React.FC<{
  customerId: number;
  customerPolicyId: number;
  vehcileNumber: number;
  changeStep: (value: number) => void;
}> = (props) => {
  const { customerId, customerPolicyId, vehcileNumber } = props;

  const { additionalInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: additionalInformation.data,
    validate: (values) => {
      const requiredFields: {
        key: keyof IAutomobileAdditionalInformation;
        message: string;
      }[] = VEHICLE_INTEREST_MESSAGES as {
        key: keyof IAutomobileAdditionalInformation;
        message: string;
      }[];
      const errors: any = {};

      if(values.vehicle_used_outside_canada === "Y"){
        if(!values.no_of_months){
          errors.no_of_months = "*No of months is required."
        }
      }


      requiredFields.forEach(({ key, message }) => {
        if (!values[key]) {
          errors[key] = message;
        }
      });
      return errors;
    },
    onSubmit: async (values) => {
      if (customerId && customerPolicyId) {
        setLoading(true);
        dispatch(
          addAutomobilAdditionalInfoAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            vehcileNumber,
            (isSuccess) => {
              if (isSuccess) {
                if (buttonClicked === "submit") {
                  navigate(
                    `/automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}?tab=0`
                  );
                } else {
                  window.scrollTo(0, 0);
                  props.changeStep(2);
                }
              }
              setLoading(false);
            }
          )
        );
      }
    },
  });

  React.useEffect(() => {
    setValues(additionalInformation.data);
  }, [additionalInformation.data]);

  React.useEffect(() => {
    if (customerPolicyId && vehcileNumber) {
      dispatch(
        fetchAutomobilAdditionalInfoVehicleAsync(
          customerPolicyId,
          vehcileNumber
        )
      );
    }
  }, [customerPolicyId, vehcileNumber]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Facility Indicator</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="facility_indicator"
              value={values.facility_indicator}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Rent/Lease to Others</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="rent_or_lease_to_others"
              value={values.rent_or_lease_to_others}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Explosive/Radioactive Material</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.explosive_or_radioactive_material}
              name="explosive_or_radioactive_material"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.explosive_or_radioactive_material ? true : false}
              helperText={errors.explosive_or_radioactive_material}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>
              Existing Unrepaired Damage Description
            </CustomFormLabel>
            <CustomTextField
              name="existing_unrepaired_damage_description"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.existing_unrepaired_damage_description}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Multi Car Discount</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="multi_car_discount"
              value={values.multi_car_discount}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Pre Inspection Code</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.pre_inspection_code}
              name="pre_inspection_code"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Used Outside Canada</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_used_outside_canada"
              value={values.vehicle_used_outside_canada}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          {values.vehicle_used_outside_canada === "Y" && (
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>No. of Months</CustomFormLabel>
              <CustomTextField
                id="no_of_months"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.no_of_months}
                onChange={handleChange}
                error={errors.no_of_months ? true : false}
                helperText={errors.no_of_months}
              />
            </Grid>
          )}

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Used in Car Pools</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.vehicle_used_in_car_pools}
              name="vehicle_used_in_car_pools"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.vehicle_used_in_car_pools ? true : false}
              helperText={errors.vehicle_used_in_car_pools}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>No. of Car Pool Passengers</CustomFormLabel>
            <CustomTextField
              id="no_of_car_pool_passengers"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.no_of_car_pool_passengers}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>No. of Car Pool Uses</CustomFormLabel>
            <CustomTextField
              id="no_of_car_pool_uses"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.no_of_car_pool_uses}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Time Peroid</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.time_period}
              name="time_period"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Salvaged</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.vehicle_salvaged}
              name="vehicle_salvaged"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.vehicle_salvaged ? true : false}
              helperText={errors.vehicle_salvaged}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Carry Goods for Compensation</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.carry_goods_for_compensation}
              name="carry_goods_for_compensation"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.carry_goods_for_compensation ? true : false}
              helperText={errors.carry_goods_for_compensation}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Carry Passengers for Compensation</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.carry_passengers_for_compensation}
              name="carry_passengers_for_compensation"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.carry_passengers_for_compensation ? true : false}
              helperText={errors.carry_passengers_for_compensation}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Existing Unrepaired Damage</CustomFormLabel>

            <ControlledCustomSelect
              fullWidth
              value={values.existing_unrepaired_damage}
              name="existing_unrepaired_damage"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.existing_unrepaired_damage ? true : false}
              helperText={errors.existing_unrepaired_damage}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Equiped With Winter Tires</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.vehicle_equipped_with_winter_tires}
              name="vehicle_equipped_with_winter_tires"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
              error={errors.vehicle_equipped_with_winter_tires ? true : false}
              helperText={errors.vehicle_equipped_with_winter_tires}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>
              Have a Copy of the Winter Tire Invoice
            </CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_the_winter_tire_invoice"
              value={values.have_a_copy_of_the_winter_tire_invoice}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Winter Tire Purchase Date</CustomFormLabel>
            <CustomDatePicker
              value={values.winter_tire_purchase_date}
              onChange={(newvalue) => {
                setFieldValue("winter_tire_purchase_date", newvalue);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>
              Reason Pre-Inspection Code not Required
            </CustomFormLabel>
            <CustomTextField
              id="reason_pre_inspection_code_not_required"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.reason_pre_inspection_code_not_required}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3.2}>
            <CustomFormLabel>Have Photos of this Vehicle</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_photos_of_this_vehicle"
              value={values.have_photos_of_this_vehicle}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Have a Copy of Vehicle Ownership</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_vehicle_ownership"
              value={values.have_a_copy_of_vehicle_ownership}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Have a Copy of the bill of Sale</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_the_bill_of_sale"
              value={values.have_a_copy_of_the_bill_of_sale}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Exposures</CustomFormLabel>
            <CustomTextField
              name="exposures"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.exposures}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Attached Equipment</CustomFormLabel>
            <CustomTextField
              name="attached_equipment"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.attached_equipment}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Damaged Windshield</CustomFormLabel>
            <CustomTextField
              name="damaged_windshield"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.damaged_windshield}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Damaged Windshield Description</CustomFormLabel>
            <CustomTextField
              name="damaged_windshield_description"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.damaged_windshield_description}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>
              Driving Record Protector Convictions Since
            </CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={
                values.driving_record_protector_convictions_eff_since || ""
              }
              onChange={(newValue) => {
                setFieldValue(
                  "driving_record_protector_convictions_eff_since",
                  newValue
                );
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Driving Record Protector Since</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.driving_record_protector_eff_since || ""}
              onChange={(newValue) => {
                setFieldValue("driving_record_protector_eff_since", newValue);
              }}
            />
          </Grid>

          {/* <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Engine or Electrical Mods</CustomFormLabel>
            <CustomTextField
              name="engine_or_electrical_mods"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.engine_or_electrical_mods}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Engraving Manufacturer</CustomFormLabel>
            <CustomTextField
              name="engraving_manufacturer"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.engraving_manufacturer}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Garage Inspection Report MVI Date</CustomFormLabel>
            <CustomTextField
              name="garage_inspection_report_mvi_date"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.garage_inspection_report_mvi_date}
              onChange={handleChange}
            />
          </Grid> */}

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Garaging Location</CustomFormLabel>
            <CustomTextField
              name="garaging_location"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.garaging_location}
              onChange={handleChange}
            />
          </Grid>

          {/* <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Goods for Comp</CustomFormLabel>
            <CustomTextField
              name="goods_for_comp"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.goods_for_comp}
              onChange={handleChange}
            />
          </Grid> */}

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Group Discount</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.group_discount}
              name="group_discount"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Hail</CustomFormLabel>
            <CustomTextField
              name="hail"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.hail}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Hand Controls Left Foot Pedals</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.hand_controls_left_foot_pedals}
              name="hand_controls_left_foot_pedals"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Left", "Right"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>In Storage</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.in_storage}
              name="in_storage"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Inspected</CustomFormLabel>
            <CustomTextField
              name="inspected"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.inspected}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Interest</CustomFormLabel>
            <CustomTextField
              name="interest"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.interest}
              onChange={handleChange}
              helperText={errors.interest}
              error={errors.interest ? true : false}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Annual Kms</CustomFormLabel>
            <CustomTextField
              name="annual_kms"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.annual_kms}
              onChange={handleChange}
              helperText={errors.annual_kms}
              error={errors.annual_kms ? true : false}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <RoleBasedCustomButton
            moduleKey={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("submit")}
          >
            Save & Exit
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            moduleKey={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Box>
      </form>
    </>
  );
};
