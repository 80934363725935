import React from "react";
import { RightPanel } from "../../../../../../components/RightPanel";
import { HabitationalScheduleDetail } from "./HabitationalScheduleDetail";
import { IHabitationalScheduleDetail } from "../../../../../../redux/habitationalPolicy/habitationalPropertySchedule/habitationalPropertySchedule.types";

interface IHabitationalScheduleRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  onSaveSuccess: (interestedParty: IHabitationalScheduleDetail) => void;
  readonly?: boolean;
  onClose: () => void;
  staticStatusValue?: string;
  createdFrom?: string;
    initialData?: IHabitationalScheduleDetail;
    disableApiCalls?: boolean;
}

export const HabitationalScheduleDetailRightPanel: React.FC<
IHabitationalScheduleRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    createdFrom,
    disableApiCalls,
    open,
    initialData
  } = props;

  return (
    <>
      <RightPanel
        heading="Property Schedule Detail"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="90%"
      >
        <HabitationalScheduleDetail
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          incrementalNumber={incrementalNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          createdFrom={createdFrom}
          disableApiCalls={disableApiCalls}
          initialData={initialData}
        
        />
      </RightPanel>
    </>
  );
};
