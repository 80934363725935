import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IBusinessPolicyCoverageState {
  data: IBusinessPolicyCoverage[];
  loading: LoadState;
  coverage: IBusinessPolicyCoverage;
  coverageLoading: LoadState;
  error: string | null;
}

export interface IBusinessPolicyCoverage {
  customer_policy_id: number | null;
  coverage_number: number | null;
  risk_location: string | null;
  property: ICoverageRow[];
  property_dropdown: string | null;
  business_interruption: ICoverageRow[];
  machinery_breakdown: ICoverageRow[];
  crime: ICoverageRow[];
  liability: ICoverageRow[];
  umbrella_liability: ICoverageRow[];
  excess_liability: ICoverageRow[];
  other: ICoverageRow[];
  status: string;
  created_from: string | null;
}

export interface ICoverageRow {
  key: string;
  type: string;
  deductible: string | null;
  co_insurance: string | null;
  limit: string | null;
  rate: string | null;
  premium: string | null;
}

export const defaultBusinessPolicyCoverageState: IBusinessPolicyCoverageState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    coverage: {
      customer_policy_id: 0,
      coverage_number: null,
      created_from: null,
      risk_location: "",
      property_dropdown: null,
      property: [
        {
          key: getUniqueId(),
          type: "",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },

        {
          key: getUniqueId(),
          type: "Building",
          co_insurance: "90%",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      
        {
          key: getUniqueId(),
          type: "Equipment",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Stock",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Tenant’s Improvements",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Unit Owner’s Improvements",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },

        {
          key: getUniqueId(),
          type: "Product Recall",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Electronic Data Processing Systems (Broad)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },

        {
          key: getUniqueId(),
          type: "Commercial Condo Loss Assessment",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Contractor’s Equipment",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Equipment Floater",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Tools Floater",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Valuable Papers & records",
          co_insurance: "",
          deductible: "",
          limit: "25,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Accounts Receivables",
          co_insurance: "",
          deductible: "",
          limit: "25,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Professional Fees",
          co_insurance: "",
          deductible: "",
          limit: "25,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Sewer Backup",
          co_insurance: "",
          deductible: "5,000",
          limit: "Include",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Flood",
          co_insurance: "",
          deductible: "25,000",
          limit: "Include",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Earthquake Endorsement",
          co_insurance: "",
          deductible: "3% or $100,000",
          limit: "Include",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Motor Truck Cargo – Trackmen’s",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "By Laws Coverage",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Warehouseman’s Legal Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Installation Floater",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Sign Floater (Broad)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Exhibition Floater",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Fine Arts Floater (Broad)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Miscellaneous Property Floater",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Package Extensions",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        }
      ],
      business_interruption: [
        {
          key: getUniqueId(),
          type: "Actual Loss Sustained",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Profits (Broad/Named)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },

        {
          key: getUniqueId(),
          type: "Gross Earnings–Mercantile or Non-Manufacturing",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Gross Earnings–Manufacturing",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Income",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Extended Business Income",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Extended Rental Income",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Gross Rentals",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Extra Expense",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      machinery_breakdown: [
        {
          key: getUniqueId(),
          type: "Machinery Breakdown",
          co_insurance: "",
          deductible: "",
          limit: "Include",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Interruption (Actual Loss)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Interruption (Loss of Profit)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Interruption (Gross Earnings)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Interruption (Rent or Rental Value)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Business Interruption (Extra Expense)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      crime: [
        {
          key: getUniqueId(),
          type: "Comprehensive 3D",
          co_insurance: "",
          deductible: "",
          limit: "5000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Employee Dishonesty Coverage Form A",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Loss Inside the Premises Coverage",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Loss outside the Premises Coverage",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Money & Securities",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Money Orders & Counterfeit Paper Currency Coverage",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Depositor’s Forgery Coverage",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      liability: [
        {
          key: getUniqueId(),
          type: "Commercial General Liability (Occurrence Form)",
          co_insurance: "",
          deductible: "1,000",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "General Aggregate",
          co_insurance: "",
          deductible: "",
          limit: "5,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Products & Completed Operations Liability (Aggregate Limit)	",
          co_insurance: "",
          deductible: "",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },

        {
          key: getUniqueId(),
          type: "Employer’s Bodily Injury Liability",
          co_insurance: "",
          deductible: "",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Personal Injury and Advertising Liability",
          co_insurance: "",
          deductible: "",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Tenant’s Legal Liability",
          co_insurance: "",
          deductible: "1,000",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "S.P.F. 6 Non-Owned Automobile Liability",
          co_insurance: "",
          deductible: "",
          limit: "2,000,000",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "O.E.F. 98B - Reduction of Coverage for Lessees or",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Drivers of Leased Vehicles Endorsement",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Voluntary Compensation",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Medical Payment",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Professional Liability (E&O)",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Director’s and Officer’s Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Wrap-up Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Pollution Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      umbrella_liability: [
        {
          key: getUniqueId(),
          type: "Umbrella Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Misc. Wording",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      excess_liability: [
        {
          key: getUniqueId(),
          type: "Excess Liability",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "Misc. Wording",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      other: [
        {
          key: getUniqueId(),
          type: "",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
        {
          key: getUniqueId(),
          type: "",
          co_insurance: "",
          deductible: "",
          limit: "",
          rate: "",
          premium: "",
        },
      ],
      status: "ACTIVE",
    },
    coverageLoading: LoadState.NotLoaded,
    error: null,
  };
