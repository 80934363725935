import React from "react";
import { api } from "../api/api";
import { useDispatchWrapper } from "./useDispatch";

import { fetchQuotedefaultCoverageList } from "../redux/QuoteSettings/quoteSettingsActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";
import { LoadState } from "../constants/enums";

export const useDefaultQuote = () => {
  const {coverageList, loading} = useSelector(
    (state: IStoreState) => state.quoteConfig.defaultCoverage
  );
  const dispatch = useDispatchWrapper();

  const fetchDefaultCoverageByCode = async (defaultCoverageCode: string) => {
    const data = coverageList.find(x=>x.default_coverage_code === defaultCoverageCode);
    if(data){
      return data;
    }
  };

    React.useEffect(() => {
      dispatch(fetchQuotedefaultCoverageList());
    }, []);
 

  return {
    coverageList,
    fetchDefaultCoverageByCode,
    coverageLoading: loading === LoadState.InProgress,
    
  };
};
