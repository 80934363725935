import React from "react";
import { Dialog } from "../../components/Dialogs/Dialog";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { Button, Typography } from "@mui/material";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";
import { IDraftSave, useDraft } from "./DraftProvider";

interface IAutoSaveDraftDialogProps {
  open: boolean;
  onSelect: (data: IDraftSave) => void;
  onClose: () => void;
}

export const AutoSaveDraftDialog: React.FC<IAutoSaveDraftDialogProps> = ({
  open,
  onSelect,
  onClose,
}) => {
  const { autoSaveDataList,getAutoSavedDraft } = useDraft();

  const handleSelect = (refId: any) => () => {
     const item = getAutoSavedDraft(refId);
     if(item){
        onSelect(item);
     }
  };

  const dataTableProps: IDataTableV2Props = {
    masterColumns: [
      {
        key: "#",
        headerName: "#",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <Typography variant="body1" fontWeight={600}>
              {value.column.rowIndex + 1}
            </Typography>
          );
        },
      },
      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "select",
        headerName: "Select",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IDraftSave) => {
          return (
            <Button variant="contained" onClick={handleSelect(row.draft_code)}>
              Select
            </Button>
          );
        },
      },
    ],
    rows: autoSaveDataList,
  };

  return (
    <Dialog
      open={open}
      title="Drafts List"
      onClose={onClose}
      size="md"
     
    >
   
<Typography variant="body1" fontWeight={500} fontSize={'1.1rem'} mb={2}>
Your saved drafts are listed below.
</Typography>
      <DataTableV2 {...dataTableProps} />
    </Dialog>
  );
};
