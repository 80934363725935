
import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialBusinessPolicyChangeState } from "./businessPolicyChane.types";
import { BusinessPolicyChangeActions } from ".";
import { CLEAR_BUSINESS_POLICY_CHANGE_STATE, FETCH_BUSINESS_POLICY_CHANGE_FAILED, FETCH_BUSINESS_POLICY_CHANGE_LIST_FAILED, FETCH_BUSINESS_POLICY_CHANGE_LIST_PROGRESS, FETCH_BUSINESS_POLICY_CHANGE_LIST_SUCCESS, FETCH_BUSINESS_POLICY_CHANGE_PROGRESS, FETCH_BUSINESS_POLICY_CHANGE_SUCCESSS } from "./businessPolicyChangeActions";

export const businessPolicyChangeReducer = (
  state: IStoreState["business"]["policyChange"] = initialBusinessPolicyChangeState,
  action: BusinessPolicyChangeActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_POLICY_CHANGE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CHANGE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CHANGE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_CHANGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.error = null;
        draftState.policyChangeData = initialBusinessPolicyChangeState["policyChangeData"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CHANGE_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.policyChangeData = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_CHANGE_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = error || null;
        draftState.policyChangeData = initialBusinessPolicyChangeState["policyChangeData"];
      });
      return newState;
    }

    case CLEAR_BUSINESS_POLICY_CHANGE_STATE: {
      return initialBusinessPolicyChangeState;
    }

    default: {
      return state;
    }
  }
};
