import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";
import {
  APP_FILE_LEADS_ROUTE,
  APP_FILE_MANAGE_LEADS_Id_ROUTE,
  APP_FILE_MANAGE_LEADS_ROUTE,
} from "./Leads.constants";

const Leads = Loadable(
  lazy(() =>
    import("./LeadsTab").then(({ LeadsTabs }) => ({
      default: LeadsTabs,
    }))
  )
);

const ManageLead = Loadable(
  lazy(() =>
    import("./ManageLeadTabs").then(({ ManageLeadTabs }) => ({
      default: ManageLeadTabs,
    }))
  )
);

export const leadsRoutes = [
  {
    path: `${APP_FILE_LEADS_ROUTE}`,
    exact: true,
    element: (
      <AuthorizedRoute moduleKeys={[MODULE_IDS.LEADS]}>
        <Leads />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_FILE_MANAGE_LEADS_ROUTE}`,
    exact: true,
    element: (
      <AuthorizedRoute moduleKeys={[MODULE_IDS.LEADS]}>
        <ManageLead />
      </AuthorizedRoute>
    ),
  },

  {
    path: `${APP_FILE_MANAGE_LEADS_Id_ROUTE}`,
    exact: true,
    element: (
      <AuthorizedRoute moduleKeys={[MODULE_IDS.LEADS]}>
        <ManageLead />
      </AuthorizedRoute>
    ),
  },
];
