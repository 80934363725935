import { LoadState } from "../../constants/enums";

export interface ICSIOEDIState {
  list: ICSIOEDI[];
  loading: LoadState;
  data: ICSIOEDIMapping[];
  dataLoading: LoadState;
  error: string | null;
}

export interface ICSIOEDI {
  csio_edi_log_id: number;
  csio_logs_code: string | null;
  process_name: string | null;
  message_guid: string | null;
  table_name: string | null;
  total_records: string | null;
  successfull_records: string | null;
  failed_records: string | null;
  partailly_failed_columns_records_id: {
    columns: string[];
    record_id: number;
  }[];
  error_logs: {
    incurance_coverage_applied_for: string;
  }[];
  status: string;
  create_ts?: string;
  insert_ts?: string;
}

export interface  ICSIOEDIMapping {
  
    "csio_policy_mapping_log_id": number;
    "csio_log_code": string;
    "message_guid":  string;
    "customer_policy_id": string;
    "policy_number": string;
    "process_name":  string;
    "mapping_status":  string;
    "mapping_error": string;
    "execution_type":  string;
    "status":  string;
    "created_by_id":  string;
    "modified_by_id":  string;
    "create_ts":string;
    "insert_ts": string;

}

export const initialCSIOEDIState: ICSIOEDIState = {
    list: [],
    loading: LoadState.NotLoaded,
    data: [],
    dataLoading: LoadState.NotLoaded,
    error: null,
}
