/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";

import {
  IBusinessPolicyCoverage,
  ICoverageRow,
} from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import produce from "immer";
import {
  addBusinesPolicyCoverageAsync,
  clearBusinessPolicyCoverage,
  fetchBusinessPolicyCoverageAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businesPolicyCoverageActions";
import { CoveragePropertyTable } from "./CoveragePropertyTable";
import { CoverageBusinessInterruptionTable } from "./CoverageBusinessInterruption";
import { CoverageMachineryBreakdownTable } from "./CoverageMachineryBreakdown";
import { CoverageCrimeTable } from "./CoverageCrime";
import { CoverageLiability } from "./CoverageLiability";
import { CoverageUmbrellaLiability } from "./CoverageUmbrellaLiability";
import { CoverageExcessLiability } from "./CoverageExcesssLiability";
import { CoverageOther } from "./CoverageOther";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { getUniqueId } from "../../../../../../helpers";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { batch } from "react-redux";
import { fetchBusinessPolicyRiskListAsync } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface ICoverageProps {
  customerId?: number;
  customerPolicyId?: number;
  coverageId?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (riskLocation: IBusinessPolicyCoverage) => void;
  initialData?: IBusinessPolicyCoverage;
  disableApiCalls?: boolean;
}

export const Coverage: React.FC<ICoverageProps> = (props) => {
  const {
    customerId,
    customerPolicyId,
    coverageId,
    onSaveSuccess,
    created_from = "Coverage",
    fromRightPanel = false,
    readOnly = false,
    staticStatusValue,
    initialData,
    disableApiCalls = false,
  } = props;

  const { coverageLoading, coverage, error } = useSelector(
    (storeState: IStoreState) => storeState.business.coverage
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(0);
  const riskLocations = useSelector(
    (storeState: IStoreState) => storeState.business.riskLocation.data
  );

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: initialData ? initialData : coverage,
      validate: (values) => {},
      onSubmit: (values) => {
        if (disableApiCalls) {
          onSaveSuccess(values);
        } else {
          if (customerPolicyId && customerId) {
            let finalStatus = values.status;
            if (staticStatusValue) {
              finalStatus = staticStatusValue;
            }
            setSaveLoading(true);
            dispatch(
              addBusinesPolicyCoverageAsync(
                {
                  ...values,
                  customer_policy_id: customerPolicyId,
                  status: finalStatus,
                  created_from: created_from,
                },
                (isSuccess, data) => {
                  if (isSuccess && data) {
                    window.scrollTo(0, 0);
                    onSaveSuccess(data);
                  }
                  setSaveLoading(false);
                }
              )
            );
          }
        }
      },
    });

  React.useEffect(() => {
    if (customerPolicyId && coverageId) {
      dispatch(fetchBusinessPolicyCoverageAsync(customerPolicyId, coverageId));
    }
  }, [customerPolicyId, coverageId]);

  React.useEffect(() => {
    setValues({ ...coverage, coverage_number: coverageId || null });
  }, [coverage]);

  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyCoverage());
    };
  }, []);

  const onTabChange = (value: number) => {
    window.scrollTo(0, 400);
    setTab(value);
  };

  const handlePropertyRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.property, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setFieldValue("property", finalRows);
      // setValues({ ...values, property: finalRows });
    }
  };

  const handleAddNewProperty = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.property];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, property: finalValues });
  };

  const handleRemovePropertyRow = (index: number) => {
    const finalRows = [...values.property];
    finalRows.splice(index, 1);
    setValues({ ...values, property: finalRows });
  };

  const handleBusinessInterruptionRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.business_interruption, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, business_interruption: finalRows });
    }
  };

  const handleAddNewBusinessInterruption = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.business_interruption];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, business_interruption: finalValues });
  };

  const handleRemoveBusinessInterruptionRow = (index: number) => {
    const finalRows = [...values.business_interruption];
    finalRows.splice(index, 1);
    setValues({ ...values, business_interruption: finalRows });
  };

  const handleMachineryBreakdownRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.machinery_breakdown, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, machinery_breakdown: finalRows });
    }
  };

  const handleAddNewMachineryBreakdown = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.machinery_breakdown];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, machinery_breakdown: finalValues });
  };

  const handleRemoveMachineryBreakdownRow = (index: number) => {
    const finalRows = [...values.machinery_breakdown];
    finalRows.splice(index, 1);
    setValues({ ...values, machinery_breakdown: finalRows });
  };

  const handleCrimeRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.crime, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, crime: finalRows });
    }
  };

  const handleAddNewCrimeRow = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.crime];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, crime: finalValues });
  };

  const handleRemoveCrimeRow = (index: number) => {
    const finalRows = [...values.crime];
    finalRows.splice(index, 1);
    setValues({ ...values, crime: finalRows });
  };

  const handleLiabilityRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.liability, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, liability: finalRows });
    }
  };

  const handleAddNewLiabilityRow = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.liability];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, liability: finalValues });
  };

  const handleRemoveLiabilityRow = (index: number) => {
    const finalRows = [...values.crime];
    finalRows.splice(index, 1);
    setValues({ ...values, liability: finalRows });
  };

  const handleUmbrellaLiabilityRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.umbrella_liability, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, umbrella_liability: finalRows });
    }
  };

  const handleAddNewUmbrellaLiabilityRow = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.umbrella_liability];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, umbrella_liability: finalValues });
  };

  const handleRemoveUmbrellaLiabilityRow = (index: number) => {
    const finalRows = [...values.umbrella_liability];
    finalRows.splice(index, 1);
    setValues({ ...values, umbrella_liability: finalRows });
  };

  const handleExcesssLiabilityRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.excess_liability, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, excess_liability: finalRows });
    }
  };

  const handleAddNewExcessLiabilityRow = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.excess_liability];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, excess_liability: finalValues });
  };

  const handleRemoveExcessLiabilityRow = (index: number) => {
    const finalRows = [...values.excess_liability];
    finalRows.splice(index, 1);
    setValues({ ...values, excess_liability: finalRows });
  };

  const handleOtherRowChange = (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => {
    if (values.property.length > rowIndex) {
      const finalRows = produce(values.other, (draftRows) => {
        draftRows[rowIndex][key] = value;
      });
      setValues({ ...values, other: finalRows });
    }
  };

  const handleAddNewOherRow = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      type: "",
      co_insurance: "",
      deductible: "",
      limit: "",
      rate: "",
      premium: "",
    };
    const finalValues = [...values.other];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, other: finalValues });
  };

  const handleRemoveOtherRow = (index: number) => {
    const finalRows = [...values.other];
    finalRows.splice(index, 1);
    setValues({ ...values, other: finalRows });
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      batch(() => {
        dispatch(fetchBusinessPolicyRiskListAsync(customerPolicyId));
      });
    }
  }, []);
  const riskLocationsDropDown = React.useMemo(() => {
    return riskLocations.map((item) => {
      return {
        label:
          item.location_number +
          ` (${item.address}, ${item.city}, ${item.province_or_state}, ${item.postal_code})`,
        value: item.location_number || "",
      };
    });
  }, [riskLocations]);

  if (!customerPolicyId) {
    return <Navigate to={"/customers-list"} />;
  }

  return (
    <PageLoader
      loading={coverageLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Coverage Summary" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Coverage Number</CustomFormLabel>
                <CustomTextField
                  name="coverage_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.coverage_number}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>Risk Location</CustomFormLabel>

                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location"
                  fullWidth
                  displayEmpty
                  value={values.risk_location}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={riskLocationsDropDown}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={COMMON_STATUS}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} md={12}>
                <Stepper
                  sx={{ mb: 3, mt: 3 }}
                  activeStep={tab}
                  alternativeLabel
                >
                  {[
                    "PROPERTY",
                    "BUSINESS INTERUPTION",
                    "MACHINERY BREAKDOWN/ B&M",
                    "CRIME",
                    "LIABILITY",
                    "UMBRELLA LIABILITY",
                    "EXCESS LIABILITY",
                    "OTHER",
                  ].map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography variant="body1">{label}</Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              <TabPanel value={tab} index={0}>
                <Grid item xs={12} md={12}>
                  <CoveragePropertyTable
                    values={values}
                    setFieldValue={setFieldValue}
                    property={values.property}
                    onAddNewProperty={handleAddNewProperty}
                    onRemovePropertyRow={handleRemovePropertyRow}
                    onPropertyRowChange={handlePropertyRowChange}
                    changeTab={onTabChange}
                  />
                </Grid>
              </TabPanel>

              <TabPanel value={tab} index={1}>
                <Grid item xs={12} md={12}>
                  <CoverageBusinessInterruptionTable
                    businessInterruption={values.business_interruption}
                    onAddNewRow={handleAddNewBusinessInterruption}
                    onRemoveRow={handleRemoveBusinessInterruptionRow}
                    onRowChange={handleBusinessInterruptionRowChange}
                    changeTab={onTabChange}
                  />
                </Grid>
              </TabPanel>

              <TabPanel value={tab} index={2}>
                <CoverageMachineryBreakdownTable
                  machineryBreakdown={values.machinery_breakdown}
                  onAddNewRow={handleAddNewMachineryBreakdown}
                  onRemoveRow={handleRemoveMachineryBreakdownRow}
                  onRowChange={handleMachineryBreakdownRowChange}
                  changeTab={onTabChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <CoverageCrimeTable
                  crime={values.crime}
                  onAddNewRow={handleAddNewCrimeRow}
                  onRemoveRow={handleRemoveCrimeRow}
                  onRowChange={handleCrimeRowChange}
                  changeTab={onTabChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <CoverageLiability
                  coverageLiability={values.liability}
                  onAddNewRow={handleAddNewLiabilityRow}
                  onRemoveRow={handleRemoveLiabilityRow}
                  onRowChange={handleLiabilityRowChange}
                  changeTab={onTabChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={5}>
                <CoverageUmbrellaLiability
                  coverageUmbrellaLiability={values.umbrella_liability}
                  onAddNewRow={handleAddNewUmbrellaLiabilityRow}
                  onRemoveRow={handleRemoveUmbrellaLiabilityRow}
                  onRowChange={handleUmbrellaLiabilityRowChange}
                  changeTab={onTabChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={6}>
                <CoverageExcessLiability
                  excessLiability={values.excess_liability}
                  onAddNewRow={handleAddNewExcessLiabilityRow}
                  onRemoveRow={handleRemoveExcessLiabilityRow}
                  onRowChange={handleExcesssLiabilityRowChange}
                  changeTab={onTabChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={7}>
                <CoverageOther
                  other={values.other}
                  onAddNewRow={handleAddNewOherRow}
                  onRemoveRow={handleRemoveOtherRow}
                  onRowChange={handleOtherRowChange}
                  changeTab={onTabChange}
                  saveLoading={saveLoading}
                />
              </TabPanel>
            </Grid>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
