/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { IBusinesPolicyBasicDetailsProps } from "../BusinessPolicyBasicDetails.types";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  USER_ROLES,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import {
  addBusinessPolicyAdminManager,
  fetchBusinessPolicyAdminManagerAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";

export const BusinessPolicyAdminManager: React.FC<
  IBusinesPolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.business.basicDetails.policyAdminManager
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.producer_1) {
        errors.producer_1 = "Producer 1 is requried";
      }
      //  else if (
      //   Number(values.house_percentage) +
      //     Number(values.branch_percentage) +
      //     Number(values.producer_1_percentage) +
      //     Number(values.producer_2_percentage) +
      //     Number(values.producer_3_percentage) !==
      //   100
      // ) {
      //   errors.producer_3_percentage = "*Total should be 100%";
      // }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addBusinessPolicyAdminManager(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if (buttonClicked === "saveAndNext" && isSuccess) {
              changeStep(4);
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyAdminManagerAsync(customerpolicyId));
  }, [customerpolicyId]);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
      producer_1: data.producer_1 || customerProspect.data.producer,
    });
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Commission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <CustomFormLabel>House %</CustomFormLabel>
            <CustomTextField
              name="house_percentage"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.house_percentage}
              onChange={handleChange}
            />
          </Grid>

          <Grid item lg={4}></Grid>
          <Grid item xs={12} lg={4}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_1}
              onSelect={(data) => {
                setFieldValue("producer_1", data.user_fact_id);
                setFieldValue("producer_1_code", data.user_code);
              }}
              errorMessage={errors.producer_1}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>%</CustomFormLabel>
            <CustomTextField
              name="producer_1_percentage"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.producer_1_percentage}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4}></Grid>
          <Grid item xs={12} lg={4}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_2}
              onSelect={(data) => {
                setFieldValue("producer_2", data.user_fact_id);
                setFieldValue("producer_2_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>%</CustomFormLabel>
            <CustomTextField
              name="producer_2_percentage"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.producer_2_percentage}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4}></Grid>
          <Grid item xs={12} lg={4}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_3}
              onSelect={(data) => {
                setFieldValue("producer_3", data.user_fact_id);
                setFieldValue("producer_3_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>%</CustomFormLabel>
            <CustomTextField
              name="producer_3_percentage"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.producer_3_percentage}
              error={errors.producer_3_percentage ? true : false}
              helperText={errors.producer_3_percentage}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4}></Grid> */}
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={2} md={3}>
                <Typography>Producers</Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography>New %</Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography>Renewal %</Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography>Endorsement %</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={2} md={2}>
                  <Typography fontWeight={600}>House</Typography>
                </Grid>
                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="house_new_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.house_new_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="house_renewal_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.house_renewal_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="house_endorsement_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.house_endorsement_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2} />
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={4} md={2}>
                  <Typography fontWeight={600}>Branch</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomTextField
                    fullWidth
                    value={values.branch_code}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="branch_new_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.branch_new_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="branch_renewal_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.branch_renewal_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="branch_endorsement_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.branch_endorsement_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}></Grid>
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={2} md={2}>
                  <Typography fontWeight={600}>Producer 1</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <UsersAutoSearchByRole
                    // label="Producer 1"
                    value={values.producer_1}
                    errorMessage={errors.producer_1}
                    onSelect={(data) => {
                      setFieldValue("producer_1", data.user_fact_id);
                      setFieldValue("producer_1_code", data.user_code);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_one_new_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_one_new_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_one_renewal_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_one_renewal_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_one_endorsement_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_one_endorsement_percentage}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={4} md={2}>
                  <Typography fontWeight={600}>Producer 2</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <UsersAutoSearchByRole
                    // label="Producer 1"
                    value={values.producer_2}
                    errorMessage={errors.producer_2}
                    onSelect={(data) => {
                      setFieldValue("producer_2", data.user_fact_id);
                      setFieldValue("producer_2_code", data.user_code);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_two_new_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_two_new_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_two_renewal_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_two_renewal_percentage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {/* <CustomFormLabel>%</CustomFormLabel> */}
                  <CustomTextField
                    name="producer_two_endorsement_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_two_endorsement_percentage}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={4} md={2}>
                  <Typography fontWeight={600}>Producer 3</Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <UsersAutoSearchByRole
                    // label="Producer 3"
                    value={values.producer_3}
                    onSelect={(data) => {
                      setFieldValue("producer_3", data.user_fact_id);
                      setFieldValue("producer_3_code", data.user_code);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="producer_three_new_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_three_new_percentage}
                    // error={errors.producer_3_percentage ? true : false}
                    // helperText={errors.producer_3_percentage}
                    // disabled={isUpdateLead}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="producer_three_renewal_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_three_renewal_percentage}
                    // error={errors.producer_3_percentage ? true : false}
                    // helperText={errors.producer_3_percentage}
                    // disabled={isUpdateLead}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    name="producer_three_endorsement_percentage"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={values.producer_three_endorsement_percentage}
                    // error={errors.producer_3_percentage ? true : false}
                    // helperText={errors.producer_3_percentage}
                    // disabled={isUpdateLead}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={4} md={2}>
                  <Typography fontWeight={600}>Marketer</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <UsersAutoSearchByRole
                    value={values.marketer}
                    onSelect={(data) => {
                      setFieldValue("marketer", data.user_fact_id);
                      setFieldValue("marketer_code", data.user_code);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
              </Grid>
              <Grid container spacing={1} marginTop={2}>
                <Grid item xs={4} md={2}>
                  <Typography fontWeight={600}>CSR</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <UsersAutoSearchByRole
                    value={values.csr}
                    onSelect={(value) => {
                      setFieldValue("csr", value.user_fact_id);
                      setFieldValue("csr_code", value.user_code);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleKey={MODULE_IDS.BUSINESS_POLICY}
            disabled={saveLoading}
            variant="contained"
            onClick={() => changeStep(2)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.BUSINESS_POLICY}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.BUSINESS_POLICY}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
  );
};
