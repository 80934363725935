import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearAutomobileVehicleState, fetchAutomobileVehicleListAsync } from "../../../../../../redux/automobileVehicle/automobileVehicleActions";

export const useAutomobilePolicyVehcileInfo = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const {vehiclesList} = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.vehicle.VehicleInformation
  );

  const vehcileDropdown = React.useMemo(() => {
    return vehiclesList.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (VIN: ${
          item.vin || "N/A"
        } )`,
        value: item.incremental_number || "",
      };
    });
  }, [vehiclesList]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileVehicleListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearAutomobileVehicleState());
    };
  }, [customerPolicyId]);

  return { vehcileDropdown };
};
