import React from "react";
import {
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import {
  IAutomobileDriverInformation,
  IInsuranceLapse,
} from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import produce from "immer";
import { FormikErrors } from "formik";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import {
  ControlledCustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { Add, Remove } from "@mui/icons-material";

interface InsuranceLapseTableProps {
  values: IAutomobileDriverInformation;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<IAutomobileDriverInformation>> | any;
  onAddNewInsuranceLapse: (currentIndex: number) => void;
  onRemoveInsuranceLapse: (index: number) => void;
  onInsuranceLapseRowChange: (
    key: keyof IInsuranceLapse,
    rowIndex: number,
    value: string
  ) => void;
}

const InsuranceLapseTable: React.FC<InsuranceLapseTableProps> = ({
  values,
  setFieldValue,
  onAddNewInsuranceLapse,
  onRemoveInsuranceLapse,
  onInsuranceLapseRowChange,
}) => {
  const handleInsuranceLapseRowChange =
    (key: keyof IInsuranceLapse, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInsuranceLapseRowChange(key, rowIndex, event.target.value);
    };

  const handleInsuranceLapseSelectChange =
    (key: keyof IInsuranceLapse, rowIndex: number) =>
    (event: SelectChangeEvent<any>) => {
      onInsuranceLapseRowChange(key, rowIndex, event.target.value);
    };

  // const handleCurrencyRowChange =
  //   (key: keyof IInsuranceLapse, rowIndex: number) => (newValue: string) => {
  //     onInsuranceLapseRowChange(key, rowIndex, newValue);
  //   };

  const handleRemoveInsuranceLapseRow = (index: number) => () => {
    onRemoveInsuranceLapse(index);
  };

  const handleInsuranceLapseNewRow = (currentRowIndex: number) => () => {
    onAddNewInsuranceLapse(currentRowIndex);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TableContainer>
          <Table
            aria-label="collapsible table"
            sx={{
              whiteSpace: {
                xs: "nowrap",
                sm: "unset",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h5">
                    #
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600} variant="h5">
                    Reinstated
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "30%" }} align="center">
                  <Typography fontWeight={600} variant="h5">
                    Description
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display={"flex"}>
                    <Button
                        variant="contained"
                      color="primary"
                      onClick={handleInsuranceLapseNewRow(
                        values.insurance_lapse.length + 1
                      )}
                    >
                      <Add fontSize="small" />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.insurance_lapse.map((item, index) => {
                return (
                  <TableRow key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography fontWeight={600} variant="h5">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.reinstated || ""}
                        onChange={(newValue) =>
                          onInsuranceLapseRowChange(
                            "reinstated",
                            index,
                            newValue
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <ControlledCustomSelect
                        value={item.description}
                        fullWidth
                        onChange={handleInsuranceLapseSelectChange(
                          "description",
                          index
                        )}
                        placeholder="Select"
                        displayEmpty
                        name="description"
                        options={[
                          "Cancelled due to Defaulting Policy Payment",
                          "Cancelled due to Licence Suspension",
                          "Cancelled due to Material Misrepresentation",
                          "Cancelled due to Non Disclosure",
                          "Driving Without Insurance",
                          "No Vehicle Hence No Insurance Required",
                          "Other",
                        ].map((template) => {
                          return { label: template, value: template };
                        })}
                      />
                    </TableCell>

                    <TableCell>
                      <CustomDatePicker
                        fullWidth
                        value={item.date || ""}
                        onChange={(newValue) =>
                          onInsuranceLapseRowChange(
                            "date",
                            index,
                            newValue
                          )
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Box display={"flex"}>
                        <IconButton
                          color="error"
                          onClick={handleRemoveInsuranceLapseRow(
                            index
                            // handleRemoveInsuranceLapseRow(value.column.rowIndex
                          )}
                        >
                          <Remove fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={handleInsuranceLapseNewRow(index)}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default InsuranceLapseTable;
