import {
  AppRegistration,
  NotificationsActive,
  ThumbUpAlt,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { IUserProfile } from "../../redux/UserProfileList/userProfile.types";
import { BeforeProfileTypes } from "../Profile/UsersList/UsersList.types";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { fetchUsersListAsync } from "../../redux/UserProfileList/userProfileActions";
import { CustomSelect } from "../../components/formsComponents";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../helpers";
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { CustomChip } from "../../components/CustomChip";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IconicMultiColumnCard } from "../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandadCard } from "../../components/Cards";
import { DataTable } from "../../components/Table/DataTable";
import { AddNewUserDialog } from "../Profile/UsersList/AddNewUser";
import { ViewProfileDialog } from "../Profile/ViewProfile/ViewProfileDialog";
import { useRoles } from "../../security/RolesProvider/RolesProvider";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import { ITableTab } from "../../components/Table/components/TableTabs/TableTabs.types";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import { TABLE_CACHING_KEYS } from "../../constants/constants";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";

export const StaffDirectory: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "staff directory",
    },
  ];

  const { data, totalNumberOfRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.userprofile.users
  );
  const [openCreateUser, setOpenCreateUser] = React.useState(false);
  const [openProfileDialog, setOpenProfileDialog] =
    React.useState<IUserProfile | null>(null);

  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeProfileTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination ,masterColumnResize },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.STAFF,
    },
  });

  const fetchList = async () => {
    dispatch(
      fetchUsersListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const userRole = useRoles();

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose Bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const usersTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["first_name", "user_code"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        isFirstColumnSticky: true,
        fieldName: "",
        disableResize: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return (
            <StandardTableActions
              onViewClick={() => {
                if (userRole.roles[0].role_name === "Admin") {
                  navigate("/users/view-profile/" + row.user_fact_id);
                } else {
                  setOpenProfileDialog(row as IUserProfile);
                }
              }}
            />
          );
        },
      },
      {
        key: "first_name",
        headerName: "Name",
        fieldName: "first_name",
        // exportCellWidth: 25,
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              {value.value && (
                <>
                  <Avatar
                    sx={{
                      bgcolor:
                        value.column.rowIndex % 2 === 0
                          ? deepOrange[400]
                          : deepPurple[400],
                    }}
                  >
                    {value.value.substring(0, 1)}
                  </Avatar>
                  <Typography variant="body2" fontWeight={"700"}>
                    {row.first_name} {row.last_name}
                  </Typography>
                </>
              )}
            </Stack>
          );
        },
      },

      {
        key: "user_code",
        headerName: "User Code",
        fieldName: "user_code",
        renderType: DataTableV2RowRenderType.CHIP_ERROR,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "mobile",
        headerName: "Mobile",
        fieldName: "mobile",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        onRowCellRender: (value, row: IUserProfile) => {
          if (row.mobile) {
            return (
              <RoleBasedMessages
                phone={row.mobile}
                moduleKey={MODULE_IDS.STAFF_USER as string}
              />
            );
          }

          return "--";
        },
      },

      {
        key: "assigned_phone_number",
        headerName: "Phone",
        fieldName: "assigned_phone_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        onRowCellRender: (value, row: IUserProfile) => {
          if (row.assigned_phone_number) {
            return (
              <RoleBasedMessages
                phone={row.assigned_phone_number || "--"}
                moduleKey={MODULE_IDS.STAFF_USER as string}
              />
            );
          }

          return "--";
        },
      },

      {
        key: "phone_ext",
        headerName: "Ext",
        fieldName: "phone_ext",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
      },

      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleKey={MODULE_IDS.STAFF_USER as string}
            />
          );
        },
      },

      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
        enableSorting: true,
        // exportCellWidth: 30,
      },

      {
        key: "role_name",
        headerName: "Role",
        fieldName: "role_name",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return <StatusRenderer status={row.status || ""} />;
        },
      },
    ],
    rows: data,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: BeforeProfileTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns
    }
  };

  const handleToggleCreate = () => {
    setOpenCreateUser(!openCreateUser);
    // navigate("/users/manage");
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Active", value: "ACTIVE", variant: "success" },
        { label: "Inactive", value: "INACTIVE", variant: "error" },
        { label: "Remote Work", value: "REMOTE WORK", variant: "grey" },
        { label: "On-Leave", value: "ON-LEAVE", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_user",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  return (
    <PageContainer
      title="Staff Directory List"
      description="this is Staff Directory list"
    >
      <Breadcrumb title="" items={BCrumb} />

      <PageTitleBar heading="Staff Directory" />

      <DataTableV2 {...usersTableProps} />

      {openCreateUser && (
        <AddNewUserDialog open={openCreateUser} onClose={handleToggleCreate} />
      )}
      {openProfileDialog && (
        <ViewProfileDialog
          open={true}
          onClose={() => setOpenProfileDialog(null)}
          information={openProfileDialog}
        />
      )}
    </PageContainer>
  );
};
