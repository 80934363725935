import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IHabitationalClaimState,
  IhabitationalClaims,
} from "./habitationalClaim.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS =
  "FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS";
export const FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS =
  "FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED =
  "FETCH_HABITATIONAL_CLAIM_LIST_FAILED";
export const CLEAR_HABITATIONAL_CLAIM_STATE = "CLEAR_HABITATIONAL_CLAIM_STATE";

export const clearHabitationalClaimState = () =>
  action(CLEAR_HABITATIONAL_CLAIM_STATE);

export const addHabitationalClaimAsync =
  (
    data: IhabitationalClaims,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-claims", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Claim saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalClaimListProgress = () =>
  action(FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS);

export const fetchHabitationalClaimListSuccess = (
  data: IhabitationalClaims[]
) =>
  action(FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS, {
    data,
  });

export const fetchHanitationalClaimListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalClaimListProgress());

      let finalUrl = `/policy/get-habitational-claims?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IhabitationalClaims[] = res.data.data;
      dispatch(fetchHabitationalClaimListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_CLAIM_PROGRESS =
  "FETCH_HABITATIONAL_CLAIM_PROGRESS";
export const FETCH_HABITATIONAL_CLAIM_SUCCESS =
  "FETCH_HABITATIONAL_CLAIM_SUCCESS";
export const FETCH_HABITATIONAL_CLAIM_FAILED =
  "FETCH_HABITATIONAL_CLAIM_FAILED";

export const fetchHabitationalClaimProgress = () =>
  action(FETCH_HABITATIONAL_CLAIM_PROGRESS);
export const fetchHabitationalClaimSuccess = (data: IhabitationalClaims) =>
  action(FETCH_HABITATIONAL_CLAIM_SUCCESS, {
    data,
  });
export const fetchHabitationalClaimFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_CLAIM_FAILED, { errorMessage });

export const fetchHabitationalClaimAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalClaimProgress());
      const res = await api.get(
        `/policy/get-habitational-claims?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IhabitationalClaims[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchHabitationalClaimSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchHabitationalClaimFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchHabitationalClaimFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


