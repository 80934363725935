import { LoadState } from "../../constants/enums";
import { ICsioComapnyState } from "./csioCompany.types";

export const initialCsioCompanyReducerState: ICsioComapnyState = {
  csioCompany: {
    list: [],
    data: {
      csio_company_id : null,
      csio_company_primary_id: null,
      insurer_code_id: 2,
      csio_company_code: "",
      csio_company: "",
      machine_address: "",
      unit_or_suite: "",
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      contract_number: null,
      multiple_contracts: null,
      user_password: "",
      automobile_upload: "No",
      habitational_upload: "No",
      create_download_charges: "No",
      base_download_charges_on_schg: "No",
      change_rewrites_to_policy: "No",
      change_for_download_charges: "No",
      create_downlaod_charges_on_sync_transactions: "No",
      send_multiple_edocs: "No",
      csio_code_2: "",
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
