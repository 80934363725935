import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IAutomobilePolicyState } from "./automobilePolicyChangeReq.types";
import { uploadTaskFile } from "../../helpers";

export const FETCH_AUTOMOBILE_POLICY_REQ_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_REQ_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_REQ_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_REQ_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_REQ_LIST_FAILED =
  "FETCH_AUTOMOBILE_POLICY_REQ_LIST_FAILED";

export const fetchAutomobilePolicyReqListProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_REQ_LIST_PROGRESS);
export const fetchAutomobilePolicyReqListSuccess = (
  list: IAutomobilePolicyState[],
  totalRecords: number
) =>
  action(FETCH_AUTOMOBILE_POLICY_REQ_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchAutomobilePolicyReqListFailed = () =>
  action(FETCH_AUTOMOBILE_POLICY_REQ_LIST_FAILED);

export const fetchAutomobilePolicyReqListAsync =
  (
    customerpolicyId: number,
    status: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilePolicyReqListProgress());
      let finalUrl = `/policy/get-policy-auto-policy-change-request?customer_policy_id=${customerpolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobilePolicyState[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchAutomobilePolicyReqListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAutomobilePolicyReqListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_FAILED";

export const fetchAutomobilePolicyChangeReqProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_PROGRESS);
export const fetchAutomobilePolicyChangeReqSuccess = (
  data: IAutomobilePolicyState
) =>
  action(FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_SUCCESSS, { data });
export const fetchAutomobilePolicyChangeReqFailed = (error?: string) =>
  action(FETCH_AUTOMOBILE_POLICY_CHANGE_REQ_APPLICATION_FAILED, { error });

export const fetchAutomobilePolicyChangeReqAsync =
  (customerPolicyId: number,  incrementalNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilePolicyChangeReqProgress());
      const res = await api.get(
        `/policy/get-policy-auto-policy-change-request?customer_policy_id=${customerPolicyId}&incremental_number=${incrementalNumber}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobilePolicyChangeReqSuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchAutomobilePolicyChangeReqFailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobilePolicyChangeReqFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobilePolicyChange =
  (
    data: IAutomobilePolicyState,
    onCallback: (isSuccess: boolean, data?: IAutomobilePolicyState) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: data.customer_id,
        policy_type: data.policy_type,
        customer_policy_id: data.customer_policy_id,
        page_name: "Automobile Policy Change",
     };

      const updateFilesJson = await uploadTaskFile(
        data.attachments as any,
        "POLICY",
        asPayload
      );
      const res = await api.post("/policy/edit-policy-auto-policy-change-request", {
        ...data,
        attachments: updateFilesJson,
      });
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Changed successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobilePolicyChangeReqSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_AUTOMOBILE_POLICY_REQ_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_POLICY_REQ_APPLICATION_STATE";

export const clearAutomobilePolicyReqState = () =>
  action(CLEAR_AUTOMOBILE_POLICY_REQ_APPLICATION_STATE);
