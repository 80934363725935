import React from "react";
import { Avatar, Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";

import { ICustomerContact } from "../../../../../../../redux/customer/customer.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { fetchCustomerContactsListAsync } from "../../../../../../../redux/customer/customerActions";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { RoleBasedMessages } from "../../../../../../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import {
  ControlledCustomSelect,
  CustomSelect,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  clearAutomobileBasicPolicyState,
  fetchAutomobileAdditionalInsuredListAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { AdditionalInsuredForm } from "./AdditionalInsuredDialog/AdditionalInsuredDialog";
import { IAutomobileAdditionalInsured } from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetails.types";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";
export const INITIAL_STATE: IAutomobileAdditionalInsured = {
  customer_policy_id: 0,
  incremental_number: null,
  customer_id: 0,
  type: null,
  customer_name: null,
  address: null,
  unit_or_suite: null,
  city: null,
  province_or_state: null,
  country: null,
  postal_code: null,
  phone_number: null,
  mobile_number: null,
  gender: null,
  birth_date: null,
  marital_status: null,
  status: "ACTIVE",
};

export const AutomobileAdditioanlInsured: React.FC<{
  customerpolicyId: number;
}> = (props) => {
  const { customerpolicyId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();

  const { list, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.basicDetails.additional_insured
  );

  const [openContact, setOpenContact] =
    React.useState<IAutomobileAdditionalInsured | null>(null);

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(
        fetchAutomobileAdditionalInsuredListAsync(customerpolicyId, status)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    // return () => {
    //   dispatch(clearAutomobileBasicPolicyState());
    // };
  }, [customerpolicyId, status]);

  const handleComplete = () => {
    dispatch(fetchAutomobileAdditionalInsuredListAsync(customerpolicyId, status));
    setOpenContact(null);
  };

  const contactsTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "type",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [
          
        ],
      },
    },

    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          return (
            <StandardTableActions
              // onViewClick={() => setOpenViewontacts(row)}
              onEditClick={() => {
                setOpenContact(row);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenContact(row);
              }}
            >
              {row.type}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "mobile_number",
        headerName: "Mobile",
        fieldName: "mobile_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "phone_number",
        headerName: "Phone",
        fieldName: "phone_number",
        // exportCellWidth: 25,
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
        onRowCellRender: (value, row: IAutomobileAdditionalInsured) => {
          if (row.phone_number) {
            return (
              <RoleBasedMessages
                phone={row.phone_number}
                moduleKey={MODULE_IDS.CUSTOMER}
              />
            );
          }

          return "--";
        },
      },
      {
        key: "gender",
        headerName: "Gender",
        fieldName: "gender",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "birth_date",
        headerName: "DOB",
        fieldName: "birth_date",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "marital_status",
        headerName: "Marital Status",
        fieldName: "marital_status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
      },
    ],
    rows: list,
  };

  return (
    <>
      <PageTitleBar
        heading="Insurer Contact"
        rightHeading={
          <Button
            variant="contained"
            onClick={() =>
              setOpenContact({
                ...INITIAL_STATE,
                customer_policy_id: customerpolicyId,
              })
            }
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...contactsTableProps} />
      {openContact && (
        <AdditionalInsuredForm
          open={true}
          insured={openContact}
          onClose={() => setOpenContact(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
