import { Avatar, Box, Button, Link, MenuItem, Typography } from "@mui/material";
import React from "react";
import { StandadCard } from "../../../../../../../components/Cards";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import {
  useDispatchWrapper,
  useRecordCounts,
} from "../../../../../../../hooks";
import { ILegal } from "../../../../../../../redux/legals/legals.types";

import { RoleBasedCSVExportPlugin } from "../../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../../components/Table/hooks/useDateFilter";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../../components/Table/plugins";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
// import { AddEDocsDialog } from "./AddEDocsDialog/AddEDocsDialog";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { PageContainer } from "../../../../../../../components/container/PageContainer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { CustomLink } from "../../../../../../../components/CustomLink/CustomLink";
import { ICSIORaw } from "../../../../../../../redux/csio/csio.types";
import { CSIOMapRightPanel } from "../../../../../../csio/CSIOMapRightPanel";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  clearAutomobileCSIOApplicationState,
  fetchCSIOPolicyChangeListAsync,
} from "../../../../../../../redux/automobileCSIOApplication/automobileCSIOApplicationActions";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";

export const AutomobileCSIOPolicyEndorsementList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.automobile.csioapplication
  );
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const navigate = useNavigate();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchCSIOPolicyChangeListAsync(customerpolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearAutomobileCSIOApplicationState());
    };
  }, [customerpolicyId, status]);

  const csioTableProps: IDataTableV2Props = {
    isPagination: false,
    totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio-tab/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button
              variant="text"
              onClick={() =>
                navigate(
                  `/automobile-policy/${customerId}/automobile-csio-application-tab/${customerpolicyId}/policy-change/${row.message_guid}`
                )
              }
            >
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio-tab/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Map",
        fieldName: "is_map",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return row.is_map === 1 ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    rows: list,
  };


  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <DataTableV2 {...csioTableProps} />
      </PageContainer>
    </>
  );
};
