import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  clearBusinessPolicyRiskState,
  fetchBusinessPolicyRiskListAsync,
} from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";

export const useBusinessPolicyRiskLocation = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const riskLocations = useSelector(
    (storeState: IStoreState) => storeState.business.riskLocation.data
  );

  const riskLocationsDropDown = React.useMemo(() => {
    return riskLocations.map((item) => {
      return {
        label:
          item.location_number +
          ` (${item.address}, ${item.city}, ${item.province_or_state}, ${item.postal_code})`,
        value: item.location_number || "",
      };
    });
  }, [riskLocations]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchBusinessPolicyRiskListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearBusinessPolicyRiskState());
    };
  }, []);

  return { riskLocationsDropDown };
};
