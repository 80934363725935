import {
    Box,
    Button,
    Grid,
    MenuItem,
    Stack,
    Typography,
  } from "@mui/material";
  import { useFormik } from "formik";
  import React from "react";
  import {
    ControlledCustomSelect,
    CustomFormLabel,
    CustomSelect,
    CustomTextField,
  } from "../../../../../../../components/formsComponents";
  import { useDispatchWrapper } from "../../../../../../../hooks";
  import { useSelector } from "react-redux";
  import { IStoreState } from "../../../../../../../redux/initialStoreState";
  import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
  import { LoadState, MODULE_IDS, SaveState } from "../../../../../../../constants/enums";
  import { renderDropdownValue } from "../../../../../../../helpers";
import { addAutomobilePremiumAsync, fetchAutomobilePremiumAsync } from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { IHabitationalPolicyBasicDetailsProps } from "../HabitationalBasicDetails.types";
import { addHabitationalPremiumAsync, fetchHabitationalPremiumAsync } from "../../../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { useNavigate } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { IDataTableProps, RenderType } from "../../../../../../../components/Table/interfaces/IDataTableProps";
import produce from "immer";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { agency_direct_bill, payment_plan } from "../../../../../../../constants/constants";
import { IHabitationalPremiumPaymentSchedule } from "../../../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplicationNew.types";
  
  export const HabitationalPremium: React.FC<IHabitationalPolicyBasicDetailsProps> = (props) => {
    const { customerpolicyId } = props;
    const { data, loading } = useSelector(
      (storeState: IStoreState) => storeState.habitational.basicDetails.premium
    );

  const { data: customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.customerInfo
  );
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
    const {changeStep} = useTabsUncontrolledContext();
    const navigate = useNavigate();
    const dispatch = useDispatchWrapper();
    const {
      values,
      handleChange,
      handleSubmit,
      setValues,
      setFieldValue,
    } = useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          addHabitationalPremiumAsync({
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if(buttonClicked === "saveAndNext" && isSuccess){
              navigate(`/habitational-policy/${customerInfo.customer_id}/claims-tab/${customerpolicyId}?tab=0`);
            }
            setSaveLoading(false);
          }
          )
        );
      },
    });

    const updatePaymentScheduleTextChange = (
      key: string,
      rowIndex: number,
      value: any
    ) => {
      const newValues = produce(values.payment_schedule, (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      });
      setFieldValue("payment_schedule", newValues);
    };
  
    const handlePaymentScheduleTextChange =
      (key: string, rowIndex: number) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        updatePaymentScheduleTextChange(key, rowIndex, e.target.value);
      };
  
    const paymentScheduleTableProps: IDataTableProps = {
      isPagination: false,
      rowsPerPageOptions: 5,
      uniqueRowKeyName: "id",
      columns: [
        {
          key: "installment_number",
          headerName: "Installement Number",
          fieldName: "installment_number",
          renderType: RenderType.CUSTOM_RENDER,
          exportCellWidth: 15,
          onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
            return (
              <CustomTextField
                fullWidth
                value={row.installment_number}
                onChange={handlePaymentScheduleTextChange(
                  "installment_number",
                  value.column.rowIndex
                )}
              />
            );
          },
        },
        {
          key: "installment_amount",
          headerName: "Installment Amount",
          fieldName: "installment_amount",
          renderType: RenderType.CUSTOM_RENDER,
          exportCellWidth: 15,
          onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
            return (
              <CustomTextField
                fullWidth
                value={row.installment_amount}
                onChange={handlePaymentScheduleTextChange(
                  "installment_amount",
                  value.column.rowIndex
                )}
              />
            );
          },
        },
  
        {
          key: "installment_date",
          headerName: "Installment Date",
          fieldName: "installment_date",
          renderType: RenderType.CUSTOM_RENDER,
          exportCellWidth: 15,
          onRowCellRender: (value, row: IHabitationalPremiumPaymentSchedule) => {
            return (
              <CustomDatePicker
                fullWidth
                value={row.installment_date}
                // onChange={updatePaymentScheduleTextChange(
                //   "installment_date",
                //   index,
                // )}
                onChange={handlePaymentScheduleTextChange(
                  "installment_date",
                  value.column.rowIndex
                )}
              />
            );
          },
        },
      ],
      items: values.payment_schedule || [],
    };
  
    React.useEffect(() => {
 
        dispatch(fetchHabitationalPremiumAsync(customerpolicyId));
      
    }, []);
  
    React.useEffect(() => {
      setValues(data);
    }, [data]);


    const normalizeValue = (backendValue: any) => {
      if (agency_direct_bill[1].value.includes(backendValue)) {
        return "D";
      }
      return backendValue;
    };
  
    return (
      <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.policy_premium}
              onChange={(value) => {
                setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>

            <ControlledCustomSelect
              fullWidth
              value={normalizeValue(values.agency_bill_or_direct_bill)}
              name="agency_bill_or_direct_bill"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={agency_direct_bill.map((option: any) => ({
                label: option.label,
                value: option.label,
              }))}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.payment_plan}
              name="payment_plan"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={payment_plan.map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Finance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.premium_finance_contract_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Financed?</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.premium_finance}
              name="premium_finance"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={[
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ]}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.financed_by}
              name="financed_by"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Defaut Tax Region</CustomFormLabel>
            <ControlledCustomSelect
                fullWidth
                value={values.default_tax_region}
                name="default_tax_region"
                onChange={handleChange}
                placeholder="Select One"
                displayEmpty
                options={[
                  "AB",
                  "BC",
                  "MB",
                  "NB",
                  "NL",
                  "NS",
                  "NT",
                  "NU",
                  "ON",
                  "PE",
                  "QC",
                  "SK",
                  "YT",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1</CustomFormLabel>
            <CustomTextField
              name="tax_one"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_one}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2</CustomFormLabel>
            <CustomTextField
              name="tax_two"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_two}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel> Tax Exempt </CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.tax_exempt}
              name="tax_exempt"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.reason}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid xs={12} marginTop={2}>
            <DataTable {...paymentScheduleTableProps} />
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleKey={MODULE_IDS.HABITATIONAL}
            disabled={saveLoading}
            variant="contained"
            onClick={() => changeStep(4)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.HABITATIONAL}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.HABITATIONAL}
            disabled={saveLoading}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
    );
  };
  