import React from "react";
import { Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IBusinessPolicyCoverage } from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  clearBusinessPolicyCoverageState,
  fetchBusinessPolicyCoverageListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businesPolicyCoverageActions";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const CoverageList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.coverage
  );
  const dispatch = useDispatchWrapper();
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchBusinessPolicyCoverageListAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPolicyCoverageState());
    };
  }, [customerPolicyId, status]);

  const coverageTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.coverage_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "coverage_number",
        headerName: "#",
        fieldName: "coverage_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.coverage_number}`
                );
              }}
            >
              {row.coverage_number}
            </Link>
          );
        },
      },
      {
        key: "risk_location",
        headerName: "Risk Location",
        fieldName: "risk_location",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: IBusinessPolicyRiskLocation) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.location_number}`
                );
              }}
            >
              {row.location_number} {row.address} {row.city}{" "}
              {row.province_or_state} {row.country} {row.unit_or_suite}{" "}
              {row.postal_code}
            </Link>
          );
        },
      },

      {
        key: "created_date",
        headerName: "Created Date",
        fieldName: "insert_ts",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE,
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Coverage"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...coverageTableProps} />
    </>
  );
};
