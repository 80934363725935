/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomShrinkTextField,
  CustomTextField,
} from "../../../../../../../components/formsComponents";

import produce from "immer";
import {
  addAutomobileScheduleAAsync,
  clearAutomobileScheduleState,
  fetchAutomobileScheduleAAsync,
} from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { AutoComplete } from "../../../../../../../components/AutoCompleteSearches/AutoComplete";
import {
  COMMON_STATUS,
  csioOtherCoverages,
} from "../../../../../../../constants/constants";
import { fetchQuotedefaultCoverageList } from "../../../../../../../redux/QuoteSettings/quoteSettingsActions";
import { useDefaultQuote } from "../../../../../../../hooks/useDefaultQuote";
import { IAutomobileScheduleA } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import { useAutomobilePolicyVehcileInfo } from "../../hooks/useAutomobileVehicleInfo";

interface IAutomobileScheduleAProps {
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (data: IAutomobileScheduleA) => void;
  initialData?: IAutomobileScheduleA;
  disableApiCalls?: boolean;
  vehicleNumber?: number;
}

export const AutomobileScheduleA: React.FC<IAutomobileScheduleAProps> = (
  props
) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onSaveSuccess,
    fromRightPanel = false,
    readOnly = false,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
    vehicleNumber,
  } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.schedule.scheduleA
  );

  const isUpdate = incrementalNumber ? true : false;

  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);

  const { coverageList, fetchDefaultCoverageByCode } = useDefaultQuote();

  const { vehcileDropdown } = useAutomobilePolicyVehcileInfo(customerPolicyId);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialData ? initialData : data,
    validate: (values) => {
      const errors: any = {};
      if (!values.vehicle_number) {
        errors.vehicle_number = "Vehicle is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setSaveLoading(true);
          dispatch(
            addAutomobileScheduleAAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                status: statusValue,
                created_from: created_from || "Coverage",
              },
              (isSuccess, data) => {
                if (isSuccess && data) {
                  onSaveSuccess(data);
                  window.scrollTo(0, 0);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const [deductibleStates, setDeductibleStates] = React.useState<boolean[]>(
    () => values.coverage_details.map(() => false)
  );

  const handleTextChange =
    (index: number, key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = e.target.value;
      });
      setValues(newValues);
    };

  const handleSelectChange =
    (index: number, key: string) => (selectedOption: any) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = selectedOption
          ? selectedOption
          : "";
      });
      setValues(newValues);
    };

  const handleCoverageChange = async (e: SelectChangeEvent<unknown>) => {
    const coverageCode = e.target.value as string;

    const CoverageInfo = await fetchDefaultCoverageByCode(coverageCode);

    if (CoverageInfo) {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_name = coverageCode;
        draftValues.coverage_details = CoverageInfo.coverage_details;
      });
      setValues(newValues);
    }
  };

  const handleCheckboxChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setDeductibleStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = isChecked;
        return newStates;
      });
    };

  React.useEffect(() => {
    setValues({ ...data });
  }, [data]);

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && vehicleNumber) {
      dispatch(
        fetchAutomobileScheduleAAsync(
          customerPolicyId,
          incrementalNumber,
          vehicleNumber
        )
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, []);

  const total = React.useMemo(() => {
    return values.coverage_details.reduce((item, currentValue) => {
      return item + (Number(currentValue.premimum) || 0);
    }, 0);
  }, [values.coverage_details]);

  const totalTwo = React.useMemo(() => {
    return values.coverage_details.reduce((item, currentValue) => {
      return item + (Number(currentValue.description) || 0);
    }, 0);
  }, [values.coverage_details]);

  const defaultCoverageList = React.useMemo(() => {
    return coverageList.map((item) => {
      return {
        label: `${item.default_coverage_name || "--"} ( ${
          item.default_coverage_code || "N/A"
        } 
        )`,
        value: item.default_coverage_code || "",
      };
    });
  }, [coverageList]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Vehicle Coverage Summary" : ""}
        rightHeading={
          !incrementalNumber && (
            <FieldSet disabled={readOnly}>
              <Stack
                direction={"row"}
                spacing={3}
                width={"100%"}
                alignItems={"center"}
              >
                <Typography
                  display={"block"}
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Default Coverage{" "}
                </Typography>
                <ControlledCustomSelect
                  sx={{ width: "300px" }}
                  variant="outlined"
                  size="small"
                  type="string"
                  name="coverage_name"
                  value={values.coverage_name}
                  onChange={handleCoverageChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={defaultCoverageList}
                ></ControlledCustomSelect>
              </Stack>
            </FieldSet>
          )
        }
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <>
              <Grid container sx={{ mt: 2 }} spacing={2}>
                <Grid item xs={6} md={6}>
                  <CustomFormLabel
                    fontWeight={600}
                    variant="h4"
                    textAlign={"center"}
                  >
                    Vehicle Number
                  </CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="vehicle_number"
                    value={values.vehicle_number}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    placeholder="Select one"
                    options={vehcileDropdown}
                    helperText={errors.vehicle_number}
                    error={errors.vehicle_number ? true : false}
                  ></ControlledCustomSelect>
                </Grid>
                {!staticStatusValue && (
                  <Grid item xs={12} lg={6}>
                    <CustomFormLabel
                      fontWeight={600}
                      variant="h4"
                      textAlign={"center"}
                    >
                      Status
                    </CustomFormLabel>
                    <ControlledCustomSelect
                      fullWidth
                      value={values.status}
                      name="status"
                      onChange={handleChange}
                      placeholder="Select one"
                      displayEmpty
                      options={COMMON_STATUS}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table
                      aria-label="collapsible table"
                      sx={{
                        whiteSpace: {
                          xs: "nowrap",
                          sm: "nowrap",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h4">
                              Section
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            <Typography fontWeight={600} variant="h4">
                              Automobile
                            </Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Limit
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Deductible
                            </Typography>
                          </TableCell> */}
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Premium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Occassional Driver Premium
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.coverage_details.map((item, index) => {
                          const showDeductibleOnly = [
                            "Specified Perils (excluding Collision or Upset)",
                            "Comprehensive (excluding Collision or Upset)",
                            "Collision or Upset",
                            "All Perils",
                            "Direct Compensation-Property Damage",
                          ].includes(item.label);

                          return (
                            <React.Fragment key={item.key}>
                              {item.label ===
                                "Family Protection Coverage - OPCF 44R" && (
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      fontWeight={600}
                                      variant="h5"
                                      sx={{ padding: "8px 0" }}
                                    >
                                      Policy Change Forms (Name & No.)
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Deductible/Limit
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Premium
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Occassional Driver Premium
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}

                              {item.label ===
                                "Specified Perils (excluding Collision or Upset)" && (
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      fontWeight={600}
                                      variant="h5"
                                      sx={{ padding: "8px 0" }}
                                    >
                                      LOSS OR DAMAGE
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Deductible
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Premium
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography fontWeight={600} variant="h6">
                                      Occassional Driver Premium
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}

                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell sx={{ minWidth: 300 }}>
                                  {!item.isTypeOther ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      spacing={2}
                                      sx={{ width: "100%" }}
                                    >
                                      <Typography
                                        sx={{
                                          wordBreak: "break-word",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {item.label}
                                      </Typography>

                                      {item.label ===
                                        "Family Protection Coverage - OPCF 44R" &&
                                        !isUpdate && (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <Typography>
                                              is deductible?
                                            </Typography>
                                            <Checkbox
                                              checked={deductibleStates[index]}
                                              onChange={handleCheckboxChange(
                                                index
                                              )}
                                            />
                                          </Stack>
                                        )}
                                    </Stack>
                                  ) : (
                                    <Stack
                                      direction="row"
                                      gap={2}
                                      sx={{ width: "100%", display: "flex" }}
                                    >
                                      <AutoComplete
                                        fullWidth
                                        value={item.label}
                                        placeholder="Select one"
                                        options={csioOtherCoverages}
                                        onChange={handleSelectChange(
                                          index,
                                          "label"
                                        )}
                                      />
                                      {!isUpdate && (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <Typography>
                                            is deductible?
                                          </Typography>
                                          <Checkbox
                                            checked={deductibleStates[index]}
                                            onChange={handleCheckboxChange(
                                              index
                                            )}
                                          />
                                        </Stack>
                                      )}
                                    </Stack>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {!item.isOnlyPremimum && (
                                    <CustomShrinkTextField
                                      label={
                                        showDeductibleOnly ||
                                        deductibleStates[index]
                                          ? "Deductible"
                                          : "Limit"
                                      }
                                      value={
                                        showDeductibleOnly ||
                                        deductibleStates[index]
                                          ? item.deductible
                                          : item.limit
                                      }
                                      onChange={handleTextChange(
                                        index,
                                        showDeductibleOnly ||
                                          deductibleStates[index]
                                          ? "deductible"
                                          : "limit"
                                      )}
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <CustomShrinkTextField
                                    label="Premium"
                                    value={item.premimum}
                                    onChange={handleTextChange(
                                      index,
                                      "premimum"
                                    )}
                                    variant="outlined"
                                    size="small"
                                  />
                                </TableCell>
                                <TableCell>
                                  <CustomShrinkTextField
                                    label="Driver"
                                    value={item.description}
                                    onChange={handleTextChange(
                                      index,
                                      "description"
                                    )}
                                    variant="outlined"
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>

                              {item.label ===
                                "Accident Benefits (Standard Benefits)" && (
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <Typography
                                      fontWeight={600}
                                      variant="h5"
                                      sx={{ padding: "8px 0" }}
                                    >
                                      Optional Increased Accident Benefits
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography variant="h4" fontWeight={600}>
                    Total Premium
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {total}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {totalTwo}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: 4 }}>
                <RoleBasedCustomButton
                  moduleKey={MODULE_IDS.AUTOMOBILE}
                  sx={{ width: "15%" }}
                  disabled={saveLoading}
                  variant="contained"
                  type="submit"
                >
                  Save
                </RoleBasedCustomButton>
              </Box>
            </>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
