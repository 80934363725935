/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import produce from "immer";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../../../../components/Cards";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { PhoneTextField } from "../../../../../../../components/PhoneTextField/PhoneTextField";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import {
  getUniqueId,
  isAlphaNumeric,
  renderDropdownValue,
} from "../../../../../../../helpers";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import {
  IAutomobileDriverInformation,
  IDriverLicenseHistory,
  IFraudMisrepresentation,
  IInsuranceLapse,
} from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import {
  addAutomobileDriverAsync,
  clearAutomobileDriverInfo,
  fetchAutomobileDriverAsync,
} from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useBreadcrumbContext } from "../../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { datesDiffInYearMonth } from "../../../../../../../helpers/calcAge";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import {
  COMMON_STATUS,
  DRIVER_VALIDATION_MESSAGES,
  TABLE_CACHING_KEYS,
} from "../../../../../../../constants/constants";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import LicenseHistoryTable from "./LicenseHistoryTable";
import { AutomobileInsuranceLapseList } from "../AutomobileInsuranceLapse/AutomobileInsuranceLapseList";
import InsuranceLapseTable from "./InsuranceLapse";
import FraudOrMisrepresentationTable from "./FraudOrMisrepresentation";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";

interface IAutomobileDriverInfoFormProps {
  customerId?: number;
  customerPolicyId?: number;
  driverNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (driver: IAutomobileDriverInformation) => void;
  initialData?: IAutomobileDriverInformation;
  disableApiCalls?: boolean;
}

export const AutomobileDriverInformation: React.FC<
  IAutomobileDriverInfoFormProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    driverNumber,
    onSaveSuccess,
    readOnly = false,
    fromRightPanel = false,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
  } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/automobile-policy/automobile/" + customerId + "/" + customerPolicyId,
      title: "automoblie",
    },
    {
      title: "driver-information",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearAutomobileDriverInfo());
    };
  }, []);

  const {
    data,
    loading,
    error: notFoundError,
  } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver.driverInformation
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialData ? initialData : data,
    validate: (values) => {
      const errors: any = {};
      DRIVER_VALIDATION_MESSAGES.forEach(({ key, message, validate }) => {
        if (key.startsWith("license_history[].")) {
          // Extract actual field name and cast to keyof IDriverLicenseHistory
          const field = key.replace(
            "license_history[].",
            ""
          ) as keyof IDriverLicenseHistory;

          values.license_history.forEach((item, index) => {
            if (!item[field]) {
              if (!errors.license_history) errors.license_history = [];
              if (!errors.license_history[index])
                errors.license_history[index] = {};
              errors.license_history[index][field] = message;
            }
          });
        } else if (
          !(key in values) ||
          !values[key as keyof IAutomobileDriverInformation]
        ) {
          // Handle top-level fields
          errors[key] = message;
        }
      });

      if (!values.driver_phone_number) {
        errors.driver_phone_number = "*Phone number is required.";
      } else if (values.driver_phone_number.length !== 10) {
        errors.driver_phone_number = "*Phone number should be 10 digits.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerId && customerPolicyId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setSaveLoading(true);
          dispatch(
            addAutomobileDriverAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                status: statusValue,
                created_from: created_from || "Driver Information",
              },
              (isSuccess, driver) => {
                if (isSuccess && driver) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(driver);
                  setSaveLoading(false);
                }
              }
            )
          );
        }
      }
    },
  });

  React.useEffect(() => {
    setValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (customerPolicyId && driverNumber) {
      dispatch(fetchAutomobileDriverAsync(customerPolicyId, driverNumber));
    }
  }, [customerPolicyId, driverNumber]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileDriverInfo());
    };
  }, []);

  const handleAddNewLicenseHistory = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      number: null,
      class: "AZ",
      date_first_licensed: "",
      year_licensed: "",
      current_license_country: "",
      province_or_state: "",
      license_number: "",
      license_expiry_date: "",
      license_type: "",
      license_status: "",
    };
    const finalValues = [...values.license_history];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, license_history: finalValues });
  };

  const handleRemoveLicenseHistoryRow = (index: number) => {
    const finalRows = [...values.license_history];
    finalRows.splice(index, 1);
    setValues({ ...values, license_history: finalRows });
  };

  const handleLicenseHistoryRowChange = (
    key: keyof IDriverLicenseHistory,
    rowIndex: number,
    value: string | number | null
  ) => {
    if (values.license_history.length > rowIndex) {
      const finalRows = produce(values.license_history, (draftRows) => {
        (draftRows[rowIndex][key] as string | number | null) = value;
      });
      setFieldValue("license_history", finalRows);
    }
  };

  // Insurance Lapse
  const handleInsuranceLapseRowChange = (
    key: keyof IInsuranceLapse,
    rowIndex: number,
    value: string | number | null
  ) => {
    if (values.insurance_lapse.length > rowIndex) {
      const finalRows = produce(values.insurance_lapse, (draftRows) => {
        (draftRows[rowIndex][key] as string | number | null) = value;
      });
      setFieldValue("insurance_lapse", finalRows);
    }
  };

  const handleAddNewInsuranceLapse = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      s_no: null,
      date: null,
      description: null,
      duration: null,
      reinstated: null,
    };
    const finalValues = [...values.insurance_lapse];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, insurance_lapse: finalValues });
  };

  const handleRemoveInsuranceLapseRow = (index: number) => {
    const finalRows = [...values.insurance_lapse];
    finalRows.splice(index, 1);
    setValues({ ...values, insurance_lapse: finalRows });
  };

  // Fraud Misrepresentation
  const handleFraudMisrepresentRowChange = (
    key: keyof IFraudMisrepresentation,
    rowIndex: number,
    value: string | number | null
  ) => {
    if (values.fraud_or_misrepresentation.length > rowIndex) {
      const finalRows = produce(
        values.fraud_or_misrepresentation,
        (draftRows) => {
          (draftRows[rowIndex][key] as string | number | null) = value;
        }
      );
      setFieldValue("fraud_or_misrepresentation", finalRows);
    }
  };

  const handleAddNewFraudMisrepresent = (currentRowIndex: number) => {
    const row = {
      key: getUniqueId(),
      s_no: null,
      date: null,
      description: null,
      driver_number: null,
    };
    const finalValues = [...values.fraud_or_misrepresentation];
    finalValues.splice(currentRowIndex + 1, 0, row);
    setValues({ ...values, fraud_or_misrepresentation: finalValues });
  };

  const handleRemoveFraudMisrepresentRow = (index: number) => {
    const finalRows = [...values.fraud_or_misrepresentation];
    finalRows.splice(index, 1);
    setValues({ ...values, fraud_or_misrepresentation: finalRows });
  };

  const handleAddressChange = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      state_or_province: data.state,
      postal_code: data.postalCode,
    });
  };

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={notFoundError ? { message: notFoundError } : null}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Driver Information" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Driver Number</CustomFormLabel>
                <CustomTextField
                  name="driver_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.driver_number}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>First Name</CustomFormLabel>
                <CustomTextField
                  name="given_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.given_name}
                  onChange={handleChange}
                  error={errors.given_name ? true : false}
                  helperText={errors.given_name}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Last Name</CustomFormLabel>
                <CustomTextField
                  name="surname"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.surname}
                  onChange={handleChange}
                  error={errors.given_name ? true : false}
                  helperText={errors.given_name}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Driver Name</CustomFormLabel>
                <CustomTextField
                  name="driver_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.driver_name}
                  onChange={handleChange}
                  error={errors.driver_name ? true : false}
                  helperText={errors.driver_name}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Date of Birth</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.date_of_birth || ""}
                  onChange={(newValue, withDateTime, momentObject) => {
                    const date = moment(momentObject);
                    const age = datesDiffInYearMonth(date, moment());
                    const newDate = moment(date).format("YYYY-MM-DD");
                    setFieldValue("date_of_birth", newDate);
                    setFieldValue("age", age);
                  }}
                  errorMessage={errors.date_of_birth}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Age</CustomFormLabel>
                <CustomTextField
                  name="age"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.age}
                  error={errors.age ? true : false}
                  helperText={errors.age}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Gender</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.gender}
                  name="gender"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Male", "Female", "Other", "X"].map((template) => {
                    return { label: template, value: template };
                  })}
                  error={errors.gender ? true : false}
                  helperText={errors.gender}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Marital Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.marital_status}
                  name="marital_status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={[
                    "Single",
                    "Married",
                    "Common-Law",
                    "Divorced",
                    "Widowed",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                  error={errors.marital_status ? true : false}
                  helperText={errors.marital_status}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Phone Number (+1)</CustomFormLabel>
                <PhoneTextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="driver_phone_number"
                  value={values.driver_phone_number}
                  error={errors.driver_phone_number ? true : false}
                  helperText={errors.driver_phone_number}
                  setFieldValue={setFieldValue}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>License Number</CustomFormLabel>
                <CustomTextField
                  name="license_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.license_number}
                  error={errors.license_number ? true : false}
                  helperText={errors.license_number}
                  onChange={(e) => {
                    setFieldValue(
                      "license_number",
                      e.target.value.toUpperCase()
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Expiry Date of License</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.expiry_date_of_license || ""}
                  onChange={(newValue) => {
                    setFieldValue("expiry_date_of_license", newValue);
                  }}
                  errorMessage={errors.expiry_date_of_license}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Province</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.province}
                  name="province"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={[
                    "Ontario",
                    "Quebec",
                    "Nova Scotia",
                    "New Brunswick",
                    "Manitoba",
                    "British Columbia",
                    "Prince Edward Island",
                    "Saskatchewan",
                    "Alberta",
                    "Newfoundland and Labrador",
                    "Northwest Territories",
                    "Yukon",
                    "Nunavut",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                  error={errors.province ? true : false}
                  helperText={errors.province}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>License Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.license_status}
                  name="license_status"
                  onChange={handleChange}
                  placeholder="Select one"
                  options={[
                    "In-force or Permanent",
                    "Suspended",
                    "Cancelled or Revoked",
                    "Pending",
                    "Temporary",
                    "Lapsed",
                    "Other",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                  error={errors.license_status ? true : false}
                  helperText={errors.license_status}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>MVR Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.mvr_status}
                  name="mvr_status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Ordered", "Not Ordered", "Received"].map(
                    (template) => {
                      return { label: template, value: template };
                    }
                  )}
                  error={errors.mvr_status ? true : false}
                  helperText={errors.mvr_status}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>MVR Type</CustomFormLabel>
                <ExtendableSelect
                  fullWidth
                  value={values.mvr_type}
                  onChange={(newValue) => {
                    setFieldValue("mvr_type", newValue);
                  }}
                  placeholder="Select one"
                  options={["Gold"].map((template) => {
                    return { label: template, value: template };
                  })}
                  errorMessage={errors.mvr_type}
                ></ExtendableSelect>
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Last MVR Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.last_mvr_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("last_mvr_date", newValue);
                  }}
                  errorMessage={errors.last_mvr_date}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Relationship to Applicant</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.relationship_to_applicant}
                  name="relationship_to_applicant"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={[
                    "Brother/Sister",
                    "Child",
                    "Common-law Spouse",
                    "Co-habitant",
                    "Employee",
                    "Insured",
                    "Lessee",
                    "Other",
                    "Other Relative",
                    "Parent",
                    "Partner",
                    "Same Sex",
                    "Spouse",
                    "Third Party",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                  helperText={errors.relationship_to_applicant}
                  error={errors.relationship_to_applicant ? true : false}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Continously Insured Since</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.continuously_insured_since || ""}
                  onChange={(newValue) => {
                    setFieldValue("continuously_insured_since", newValue);
                  }}
                  errorMessage={errors.continuously_insured_since}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Hired Since</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.hire_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("hire_date", newValue);
                  }}
                  errorMessage={errors.hire_date}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Termination Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.termination_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("termination_date", newValue);
                  }}
                  errorMessage={errors.termination_date}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>
                  Insured as Principal Operator Since
                </CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.insured_as_principal_operator_since || ""}
                  onChange={(newValue) => {
                    setFieldValue(
                      "insured_as_principal_operator_since",
                      newValue
                    );
                  }}
                  errorMessage={errors.insured_as_principal_operator_since}
                />
              </Grid>

              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Driver Occupation Code</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="driver_occupation_code"
                  value={values.driver_occupation_code}
                  onChange={handleChange}
                  error={errors.driver_occupation_code ? true : false}
                  helperText={errors.driver_occupation_code}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <CustomFormLabel>Claims Experience Letter Date</CustomFormLabel>

                <CustomDatePicker
                  fullWidth
                  value={values.claims_experience_letter_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("claims_experience_letter_date", newValue);
                  }}
                  errorMessage={errors.claims_experience_letter_date}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Address</CustomFormLabel>
                <LocationAutoComplete
                  id="address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.address}
                  onLocationChange={handleAddressChange}
                  helperText={errors.address}
                  error={errors.address ? true : false}
                />
              </Grid>

              <Grid item xs={12} lg={1.8}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.unit_or_suite}
                  name="unit_or_suite"
                  onChange={handleChange}
                ></CustomTextField>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  helperText={errors.city}
                  error={errors.city ? true : false}
                ></CustomTextField>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Province/State</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.state_or_province}
                  name="state_or_province"
                  onChange={handleChange}
                  helperText={errors.state_or_province}
                  error={errors.state_or_province ? true : false}
                ></CustomTextField>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Postal Code</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.postal_code}
                  name="postal_code"
                  onChange={handleChange}
                  helperText={errors.postal_code}
                  error={errors.postal_code ? true : false}
                ></CustomTextField>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Email</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.driver_email}
                  name="driver_email"
                  onChange={handleChange}
                ></CustomTextField>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Years Insured</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={values.years_insured}
                  name="years_insured"
                  onChange={handleChange}
                ></CustomTextField>
              </Grid>
              
            </Grid>

            <LabelCard heading="License History" />

            <LicenseHistoryTable
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              onAddNewLicenseHistory={handleAddNewLicenseHistory}
              onRemoveLicenseHistory={handleRemoveLicenseHistoryRow}
              onLicenseHistoryRowChange={handleLicenseHistoryRowChange}
            />

            <LabelCard heading="Driver training Certificate" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Driver Training Certificate</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.driver_training_ceriticate}
                  name="driver_training_ceriticate"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.driver_training_certificate_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("driver_training_certificate_date", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Training Code</CustomFormLabel>
                <ExtendableSelect
                  fullWidth
                  value={values.driver_training_certificate_training_code}
                  onChange={(newValue) => {
                    setFieldValue(
                      "driver_training_certificate_training_code",
                      newValue
                    );
                  }}
                  placeholder="Select one"
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ExtendableSelect>
              </Grid>
            </Grid>
            <LabelCard heading="Insurance Lapse" />
            <InsuranceLapseTable
              values={values}
              setFieldValue={setFieldValue}
              onAddNewInsuranceLapse={handleAddNewInsuranceLapse}
              onRemoveInsuranceLapse={handleRemoveInsuranceLapseRow}
              onInsuranceLapseRowChange={handleInsuranceLapseRowChange}
            />

            <LabelCard heading="Fraud/ Misrepresentation" />
            <FraudOrMisrepresentationTable
              values={values}
              setFieldValue={setFieldValue}
              onAddNewFraudOrMisrepresentation={handleAddNewFraudMisrepresent}
              onRemoveFraudOrMisrepresentation={
                handleRemoveFraudMisrepresentRow
              }
              onFraudOrMisrepresentationRowChange={
                handleFraudMisrepresentRowChange
              }
            />

            <LabelCard heading="Additional Questions" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Heart Disorder: Epilepsy, Diabetes or any other Physical or
                  Mental Disability Exist
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.heart_disorder}
                  name="heart_disorder"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Has the Driver Provided Proof of Qualification for the
                  Preffered Driver Status (Y/N)
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="preffered_driver_status"
                  value={values.preffered_driver_status}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Insurance Cancelled/Declined/Refused (Y/N)
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="insurance_cancelled_or_declined_or_refused"
                  value={values.insurance_cancelled_or_declined_or_refused}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Has the Driver's License been Suspended/Cancelled/Laspsed
                  within the last 6 years
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="has_the_driver_license_been_suspended_within_the_last_six_years"
                  value={
                    values.has_the_driver_license_been_suspended_within_the_last_six_years
                  }
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Suspension Term - From</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.suspension_term_from || ""}
                  onChange={(newValue) => {
                    setFieldValue("suspension_term_from", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Suspension Term - To</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.suspension_term_to || ""}
                  onChange={(newValue) => {
                    setFieldValue("suspension_term_to", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Suspension Reason</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.suspension_reason}
                  name="suspension_reason"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Any serious convictions in past 6 years, or more than minor in
                  past 3 years (Y/N)?
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="serious_conviction_six_years_or_minor_conviction_three_years"
                  value={
                    values.serious_conviction_six_years_or_minor_conviction_three_years
                  }
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Any losses or claims by the applicant in past 5 years (Y/N)?
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="losses_claims_by_applicant_past_five_years"
                  value={values.losses_claims_by_applicant_past_five_years}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Non-Licensed Driver</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.non_licensed_driver}
                  name="non_licensed_driver"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Driver/Non-Driver - Drives a Listed Vehicle{" "}
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.driver_or_non_driver_drives_a_listed_vehicle}
                  name="driver_or_non_driver_drives_a_listed_vehicle"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Driver/Non-Driver - Drives a Listed Vehicle (Y) / Does Not
                  Drive any of the Listed Vehicles (N)?
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={
                    values.driver_or_non_driver_drives_a_listed_vehicle_y_n
                  }
                  name="driver_or_non_driver_drives_a_listed_vehicle_y_n"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Does the Driver have Retricted Coverages?
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.driver_restricted_coverages}
                  name="driver_restricted_coverages"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Restriction Code 1</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="restriction_code_one"
                  value={values.restriction_code_one}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Restriction Code 2</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="restriction_code_two"
                  value={values.restriction_code_two}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Country Code</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="country_code"
                  value={values.country_code}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                  options={["Canada", "USA"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Non-Smoker</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.non_smoker}
                  name="non_smoker"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Is the Driver Subject to Fainting Spells, Dizziness or Loss of
                  Consciousness?{" "}
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.driver_subject_to_dizziness_or_fainting}
                  name="driver_subject_to_dizziness_or_fainting"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Recent Date Coverage Ceased </CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.recent_date_coverage_ceased || ""}
                  onChange={(newValue) => {
                    setFieldValue("recent_date_coverage_ceased", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Months without Coverage </CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="months_without_coverage"
                  value={values.months_without_coverage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Reason Coverage Ceased Code</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.reason_coverage_ceased_code}
                  name="reason_coverage_ceased_code"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>Reside with Parents?</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.reside_with_parents}
                  name="reside_with_parents"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomFormLabel>
                  Number of Years Credit for Licensed Outside of Canada
                </CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="number_of_years_credit_for_licensed_outside_of_canada"
                  value={
                    values.number_of_years_credit_for_licensed_outside_of_canada
                  }
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Students Grade Code</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.student_grades_code}
                  name="student_grades_code"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Does Good Student Credit Apply (Y/N)
                </CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="does_good_student_credit_apply"
                  value={values.does_good_student_credit_apply}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Retiree Discount</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="retiree_discount"
                  value={values.retiree_discount}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {["Y", "N"].map((policyType) => {
                    return <MenuItem value={policyType}>{policyType}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Carry Passengers for Compensation
                </CustomFormLabel>
                <CustomTextField
                  name="carry_passengers_for_compensation"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.carry_passengers_for_compensation}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Is the Applicant Both the Registered Owner?
                </CustomFormLabel>
                <CustomTextField
                  name="is_the_applicant_both_the_registered_owner"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.is_the_applicant_both_the_registered_owner}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Has This Risk Been Bound?</CustomFormLabel>
                <CustomTextField
                  name="has_this_risk_been_bound"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.has_this_risk_been_bound}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Is a Market Availability Plan Accessed?
                </CustomFormLabel>
                <CustomTextField
                  name="is_a_market_availability_plan_accessed"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.is_a_market_availability_plan_accessed}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Any Other Drivers in Household
                </CustomFormLabel>
                <CustomTextField
                  name="other_drivers_in_household"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.other_drivers_in_household}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>
                  Has the All Corners Rule Been Applied?
                </CustomFormLabel>
                <CustomTextField
                  name="has_the_all_corners_rule_been_applied"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.has_the_all_corners_rule_been_applied}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomFormLabel>Drivers Qualified</CustomFormLabel>
                <CustomTextField
                  name="drivers_qualified"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.drivers_qualified}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {!staticStatusValue && (
              <Grid container>
                <Grid item xs={12} md={4}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{ mt: 2 }}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={saveLoading}
                variant="contained"
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>{" "}
        </form>
      </StandadCard>
    </PageLoader>
  );
};
