import { LoadState } from "../../constants/enums";

export interface IAIExcelFileExtractHeadersPayload {
  heading_row_start: number;
  heading_row_end: number;
  sheet_count: number;
  type: string;
  url: string;
}

export interface IAccountReconciliationDocument {
  reconciliation_document_code: string | null;
  reconciliation_document_name: string | null;
  file_path: string;
  file_type: "EXCEL" | "Pdf" | "Excel";
  status: "ACTIVE" | "INACTIVE";
  type: string | null;

  // read only
  create_ts?: string;
  insert_ts?: string;
}

export interface IComparisonDetails {
  status: "unmatched" | "matched";
  
  data: {
    [key: string]: {
      excel_value: string;
      db_value: string;
    };
  };
}

export interface IUploadPdfDocumentProcess {
  reconciliation_document_code: string;
  process_records_code: string;
  total_products: number;
  completed_products: number;
  status: "IN_PROGRESS" | "COMPLETED" | "FAILED";
  created_by_uuid: any;
  created_by_name: any;
  modified_by_uuid: any;
  modified_by_name: any;
  create_ts?: string;
  insert_ts?: string;
}

export interface IAccountReconciliationDetails {
  total_records: number;
  matched_records: number;
  unmatched_records: number;
  records_with_differences: number;
  comparison_details: IComparisonDetails[];
}

export interface IAccountReconciliationDetailsList {
  reconciliation_summary_id: string;
  reconciliation_summary_code: string;
  file_url: string | null;
  date_created: string | null;
  bill_type: string | null;
  template_code: string;
  reconciliation_document_code: string | null;
  matched_records: string | null;
  unmatched_records: string | null;
  records_with_differences: string | null;
  total_records: string | null;
  status: string | null;
  created_by_id: string | null;
  modified_by_id: string | null;
  create_ts: string | null;
  insert_ts: string | null;
}

export interface IExtractedPfdDataObject {
  [key: string]: string
}

export interface IExtractedPdfData {
  extracted_pdf_data_id: string;
  extracted_pdf_data_code: string;
  reconciliation_document_code: string;
  pdf_data: IExtractedPfdDataObject[];
}
export interface IAccountReconciliationState {
  list: IAccountReconciliationDetailsList[];
  totalRecords: number;
  loading: LoadState;
  reconciliationDetails: IAccountReconciliationDetails;
  reconciliationDetailsLoading: LoadState;
  reconciliationDetailsError: string | null;
}
export const initialAccountReconciliationState: IAccountReconciliationState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
  reconciliationDetails: {
    matched_records: 0,
    records_with_differences: 0,
    comparison_details: [],
    total_records: 0,
    unmatched_records: 0,
  },
  reconciliationDetailsError: null,
  reconciliationDetailsLoading: LoadState.NotLoaded,
};
