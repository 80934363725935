import { ThunkAction } from "redux-thunk";
import {
  IAccountReconciliationDetails,
  IAccountReconciliationDetailsList,
  IAccountReconciliationDocument,
  IAIExcelFileExtractHeadersPayload,
  IExtractedPdfData,
  IUploadPdfDocumentProcess,
} from "./aiReconciliation.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { makeApiCall } from "../../helpers/postRequest";
import { api } from "../../api/api";
import { action } from "typesafe-actions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const getExcelFileHeaderAsync =
  (
    excel_payload: IAIExcelFileExtractHeadersPayload,
    onCallback: (isSuccess: boolean, data?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(openLoaderWithMessage("File is being processed..."));
      const res = await makeApiCall(
        {
          url: "/general/get-excel-header",
          method: "POST",
        },
        undefined,
        {
          url: `${process.env["REACT_APP_SERVER_URL"]}/general/get-signed-url?key=${excel_payload.url}`,
          start_row: Number(excel_payload.heading_row_start || "0"),
          end_row: Number(excel_payload.heading_row_end || "0"),
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "File Processed successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };

export const upsertAccountReconciliationDocument =
  (
    data: IAccountReconciliationDocument,
    onCallback: (
      isSuccess: boolean,
      data?: IAccountReconciliationDocument
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = data;
      const payload = {
        ...rest,
        file_type: rest.file_type === "EXCEL" ? "XLSX" : rest.file_type,
      };
      const res = await api.post(
        "/accounting/edit-account-reconciliation-document",
        payload
      );

      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const extractDataFromPdfAsync =
  (
    reconciliation_document_code: string,
    onCallback: (
      isSuccess: boolean,
      data?: {
        process_records_code: string;
        reconciliation_document_code: string;
      }
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/general/extract-data-from-pdf", {
        reconciliation_document_code,
      });

      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetcheExtractedDataFromPdfAsync =
  (
    reconciliation_document_code: string,
    onCallback: (isSuccess: boolean, data?: IExtractedPdfData) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res =
        await api.get(`/general/get-extracted-pdf-data?reconciliation_document_code=${reconciliation_document_code}
`);
      const finalData: IExtractedPdfData[] = res.data.data;
      if (finalData.length > 0) {
        onCallback(true, res.data.data[0]);
      } else {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: ERROR_MESSAGES.NO_RECORD_FOUND,
            displayAs: "snackbar",
          })
        );
      }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const getUploadedPdfProccess =
  (
    process_records_code: string,
    onCallback: (
      isSuccess: boolean,
      process?: IUploadPdfDocumentProcess
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/general/get-process?process_records_code=${process_records_code}&pageNo=1&itemPerPage=1`
      );
      const data: IUploadPdfDocumentProcess[] = res.data.data;
      onCallback(true, data[0]);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const extractProductsFromExcelFileAsync =
  (
    payload: {
      reconciliation_template_code: string;
      reconciliation_template_name: string;
      reconciliation_document_code: string;

      file_type: string;
      template_mapping_data: any;
      status: "ACTIVE";
      start_row?: number;
      end_row?: number;
    },
    commissionData: {
      commission_paid: string;
      payment_paid_date: string;
    },
    onCallback: (
      isSuccess: boolean,
      data?: {
        reconciliation_template_code: string;
        reconciliation_document_code: string;
      }
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        reconciliation_template_code,
        reconciliation_document_code,
        start_row,
        end_row,
      } = payload;

      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/accounting/edit-account-reconciliation-template",
        reconciliation_template_code
          ? {
              reconciliation_document_code,
              reconciliation_template_code,
              start_row: start_row,
              end_row: end_row,
              data_for_transaction_items: {
                commission_paid: commissionData.commission_paid,
                payment_paid_date: commissionData.payment_paid_date,
              },
            }
          : {
              ...payload,
              data_for_transaction_items: {
                commission_paid: commissionData.commission_paid,
                payment_paid_date: commissionData.payment_paid_date,
              },
            }
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Data Fetched successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const getExcelColumnMappingWithAIAsync =
  (
    data: any,
    onCallback: (isSuccess: boolean, data?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(openLoaderWithMessage("Mappings are being Generated..."));

      const res = await makeApiCall(
        {
          url: "/general/excel-mapping",
          method: "POST",
        },
        undefined,
        {
          excel_headers: [data],
        }
      );

      const finalDocument: any = res.data.data;

      dispatch(
        showMessage({
          type: "success",
          message: "Mappings Generated successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true, finalDocument);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };

export const FETCH_RECONCILIATION_DETAILS_PROGRESS =
  "FETCH_RECONCILIATION_DETAILS_PROGRESS";
export const FETCH_RECONCILIATION_DETAILS_SUCCESS =
  "FETCH_RECONCILIATION_DETAILS_SUCCESS";
export const FETCH_RECONCILIATION_DETAILS_FAILED =
  "FETCH_RECONCILIATION_DETAILS_FAILED";

export const fetchReconciliationProgress = () =>
  action(FETCH_RECONCILIATION_DETAILS_PROGRESS);
export const fetchReconciliationSuccess = (
  data: IAccountReconciliationDetails
) =>
  action(FETCH_RECONCILIATION_DETAILS_SUCCESS, {
    data,
  });
export const fetchReconciliationFailed = (error: string) =>
  action(FETCH_RECONCILIATION_DETAILS_FAILED, { error });

export const fetchReconciliationDetailsAsync =
  (
    templateCode: string,
    documentCode: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchReconciliationProgress());

      const res = await api.post(
        "/accounting/edit-account-reconciliation-details",
        {
          template_code: templateCode,
          reconciliation_document_code: documentCode,
        }
      );

      const finalDocument: IAccountReconciliationDetails = res.data.data;
      dispatch(fetchReconciliationSuccess(finalDocument));
    } catch (err: any) {
      dispatch(fetchReconciliationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_RECONCILIATION_LIST_PROGRESS =
  " FETCH_RECONCILIATION_LIST_PROGRESS";
export const FETCH_RECONCILIATION_LIST_SUCCESS =
  " FETCH_RECONCILIATION_LIST_SUCCESS";
export const FETCH_RECONCILIATION_LIST_FAILED =
  " FETCH_RECONCILIATION_LIST_FAILED";

export const fetchReconciliationListProgress = () =>
  action(FETCH_RECONCILIATION_LIST_PROGRESS);
export const fetchReconciliationListSuccess = (
  data: IAccountReconciliationDetailsList[],
  totalRecords: number
) => action(FETCH_RECONCILIATION_LIST_SUCCESS, { data, totalRecords });
export const fetchReconciliationListFailed = () =>
  action(FETCH_RECONCILIATION_LIST_FAILED);

export const fetchReconciliationListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchReconciliationListProgress());

      let finalUrl = `/accounting/get-account-reconciliation-summary
?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/accounting/get-account-reconciliation-summary
?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IAccountReconciliationDetailsList[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchReconciliationListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchReconciliationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_RECONCILIATION_DETAILS_STATE =
  "CLEAR_RECONCILIATION_DETAILS_STATE";
export const clearReconciliationDetailsState = () =>
  action(CLEAR_RECONCILIATION_DETAILS_STATE);
