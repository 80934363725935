import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  clearHabitationalRiskLocation,
  fetchHabitationalRiskListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";

export const useHabitationalPolicyRiskLocation = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const riskLocations = useSelector(
    (storeState: IStoreState) => storeState.habitational.riskLocation.data
  );

  const riskLocationsDropDown = React.useMemo(() => {
    return riskLocations.map((item) => {
      return {
        label:
          item.incremental_number +
          ` (${item.address}, ${item.city}, ${item.province_or_state}, ${item.postal_code})`,
        value: item.incremental_number || "",
      };
    });
  }, [riskLocations]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalRiskListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearHabitationalRiskLocation());
    };
  }, []);


  return { riskLocationsDropDown };
};
