import { Box, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  useDispatchWrapper,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  SaveState,
  USER_ROLES,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { ComissionCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeAutoSearch";
import { BranchCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import {
  addAutomobilePolicyAdminsync,
  fetchAutomobilePolicyAdminAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { ILifeInsurancePolicyBasicDetailsProps } from "../LifeInsuranceBasicDetails.types";
import {
  addLifeInsurancePolicyAdminAsync,
  fetchLifeInsurancePolicyAdminAsync,
} from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { api } from "../../../../../../../api/api";
import { fetchBranchOfficeWithCallback } from "../../../../../../../redux/branchOffice/branchOfficeActions";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const LifeInsurancePolicyAdmin: React.FC<
  ILifeInsurancePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, Loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.lifeInsurance.basicDetails.policyAdmin
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);

  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addLifeInsurancePolicyAdminAsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          () => {
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchLifeInsurancePolicyAdminAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
      advisor_one: data.advisor_one || customerProspect.data.producer,
    });
  }, [data]);

  React.useEffect(() => {
    if (customerProspect.data.branch_code) {
      dispatch(
        fetchBranchOfficeWithCallback(
          customerProspect.data.branch_code,
          (data) => {
            setFieldValue("aga", data.name);
          }
        )
      );
    }
  }, [customerProspect]);

  return (
    <PageLoader loading={Loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Comission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>AGA</CustomFormLabel>
            <CustomTextField
              name="aga"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              disabled
              value={values.aga}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>AGA Code</CustomFormLabel>
            <CustomTextField
              name="branch_code"
              fullWidth
              value={values.branch_code}
              disabled
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Advisor 1"
              value={values.advisor_one}
              onSelect={(data) => {
                setFieldValue("advisor_one", data.user_fact_id);
                setFieldValue("advisor_one_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Advisor 2"
              value={values.advisor_two}
              onSelect={(data) => {
                setFieldValue("advisor_two", data.user_fact_id);
                setFieldValue("advisor_two_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Advisor 3"
              value={values.advisor_three}
              onSelect={(data) => {
                setFieldValue("advisor_three", data.user_fact_id);
                setFieldValue("advisor_three_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(value) => {
                setFieldValue("csr", value.user_fact_id);
                setFieldValue("csr_code", value.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              name="status"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              displayEmpty
              placeholder="Select One"
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ].map((data) => {
                return { label: data.label, value: data.value };
              })}
              value={values.status}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
            type="submit"
            moduleKey={MODULE_IDS.LIFE_AND_FINCANCIAL}
            // disabled={saveLoading === SaveState.Progress}
            variant="contained"
          >
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
