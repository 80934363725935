import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  clearHabitationalRiskLocation,
  fetchHabitationalRiskListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import {
  clearAutomobileDriverInfo,
  fetchAutomobileDriverListAsync,
} from "../../../../../../redux/automobileDriver/automobileDriverActions";

export const useAutomobilePolicyDriverInfo = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const Driver = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.driver.driverInformation.driversList
  );

  const driverDropDown = React.useMemo(() => {
    return Driver.map((item) => {
      return {
        label: `${item.driver_number || "--"} (Driver Name: ${
          item.driver_name || "N/A"
        } )`,
        value: item.driver_number || "",
      };
    });
  }, [Driver]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearAutomobileDriverInfo());
    };
  }, [customerPolicyId]);

  return { driverDropDown };
};
