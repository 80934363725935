import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { CsioEDIActions } from ".";
import { CLEAR_CSIO_EDI_STATE, FETCH_CSIO_EDI_FAILED, FETCH_CSIO_EDI_LIST_FAILED, FETCH_CSIO_EDI_LIST_PROGRESS, FETCH_CSIO_EDI_LIST_SUCCESS, FETCH_CSIO_EDI_PROGRESS, FETCH_CSIO_EDI_SUCCESS } from "./csioEDIActions";
import { initialCSIOEDIState } from "./csioEDI.types";


export const csioEDIReducer = (
  state: IStoreState["csioEDI"] = initialCSIOEDIState,
  action: CsioEDIActions
) => {
  switch (action.type) {
    case FETCH_CSIO_EDI_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_CSIO_EDI_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
      });
      return newState;
    }
    case FETCH_CSIO_EDI_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }

    case FETCH_CSIO_EDI_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.InProgress;
        draftState.data = [];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_CSIO_EDI_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.Loaded;
        draftState.data = list;
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_CSIO_EDI_FAILED: {
      const {error} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.InProgress;
        draftState.data = [];
        draftState.error = error;
      });
      return newState;
    }
    

    case CLEAR_CSIO_EDI_STATE: {
      return initialCSIOEDIState;
    }
    default: {
      return state;
    }
  }
};
