import { LoadState } from "../../constants/enums";

export interface IOldInsuranceReportsState {
    list: IOldInsuranceReportsList[];
    data: IOldInsuranceReportsList;
    loading: LoadState;
    saveLoading: boolean;
    error: string | null;
}




export interface IOldInsuranceReportsList {
  customer_policy_id: string | null;
  incremental_number: string | null;
  customer_code: string | null;
  customer_name: string | null;
  driver_number: string | null;
  driver_name: string | null;
  license_number: string | null;
  business_reason: string | null;
  date_and_time: string | null;
  days_left: string | null;
  transaction_type: string | null;
  report_requested: string | null;
  years_at_this_address: string | null;
  current_address_type: string | null;
  current_address: string | null;
  current_address_unit_or_suite: string | null;
  current_address_city: string | null;
  current_address_state_or_province: string | null;
  current_address_postal_code: string | null;
  current_address_country: string | null;
  new_address_type: string | null;
  new_address: string | null;
  new_address_unit_or_suite: string | null;
  new_address_city: string | null;
  new_address_state_or_province: string | null;
  new_address_postal_code: string | null;
  new_address_country: string | null;
  request_status: string | null;
  insurer_code: string | null;
  intact_branch: string | null;
  number_of_disclosed_convictions: string | null;
  policy_number: string | null;
  policy_status_cancelled: string | null;
  requested_by: string | null;
  requested_by_code: string | null;
  assigned_to: string | null;
  branch: string | null;
  producer: string | null;
  csr: string | null;
  notes: string | null;
  file_upload: string | null;
  file_upload_two: string | null;
  file_type: string | null;
  file_type_two: string | null;
  date_written: string | null;
  date_destroyed: string | null;
  date_voided: string | null;
  date_cancelled: string | null;
  producer_one_code: string | null;
  csr_code: string | null;
  insurer_code_written: string | null;
  status: string;
  create_ts: string;
  insert_ts: string;
}

export const initialOldInsuranceReportsState: IOldInsuranceReportsState = {
  list: [],
 data: {
    customer_policy_id: null,
    incremental_number: null,
    customer_code: null,
    customer_name: null,
    driver_number: null,
    driver_name: null,
    license_number: null,
    business_reason: null,
    date_and_time: null,
    days_left: null,
    transaction_type: null,
    report_requested: null,
    years_at_this_address: null,
    current_address_type: null,
    current_address: null,
    current_address_unit_or_suite: null,
    current_address_city: null,
    current_address_state_or_province: null,
    current_address_postal_code: null,
    current_address_country: null,
    new_address_type: null,
    new_address: null,
    new_address_unit_or_suite: null,
    new_address_city: null,
    new_address_state_or_province: null,
    new_address_postal_code: null,
    new_address_country: null,
    request_status: null,
    insurer_code: null,
    intact_branch: null,
    number_of_disclosed_convictions: null,
    policy_number: null,
    policy_status_cancelled: null,
    requested_by: null,
    requested_by_code: null,
    assigned_to: null,
    branch: null,
    producer: null,
    csr: null,
    notes: null,
    file_upload: null,
    file_upload_two: null,
    file_type: null,
    file_type_two: null,
    date_written: null,
    date_destroyed: null,
    date_voided: null,
    date_cancelled: null,
    producer_one_code: null,
    csr_code: null,
    insurer_code_written: null,
    status: "ACTIVE",
    create_ts: "",
    insert_ts: "",
 },
  loading: LoadState.NotLoaded,
  saveLoading: false,
  error: null,
}
