/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import { Box, Button, Grid, SelectChangeEvent } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  addBusinesPolicyInterestedAsync,
  clearBusinessPolicyIntrested,
  fetchBusinessPolicyIntrestedAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterestedActions";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchBusinessPolicyRiskListAsync } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { batch } from "react-redux";
import { fetchBrokerageIntrestedAllPartiesAsync } from "../../../../../../redux/InterestedParties/IntrestedPartiesActions";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { InterestedPartyCodeSearchSelect } from "../../../../../../components/AutoCompleteSearches/InterestedPartyCodeSearchSelect";
import { IBusinessPolicyInterested } from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterested.types";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IInterestedPartiesProps {
  customerId?: number;
  customerPolicyId?: number;
  interestedNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (data: IBusinessPolicyInterested) => void;
  initialData?: IBusinessPolicyInterested;
  disableApiCalls?: boolean;
}

export const InterestedParties: React.FC<IInterestedPartiesProps> = (props) => {
  const {
    customerId,
    customerPolicyId,
    interestedNumber,
    onSaveSuccess,
    created_from,
    fromRightPanel = false,
    readOnly = false,
    staticStatusValue,
    disableApiCalls = false,
    initialData,
  } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const { intrestLoading, interest, error } = useSelector(
    (storeState: IStoreState) => storeState.business.interested
  );
  const riskLocations = useSelector(
    (storeState: IStoreState) => storeState.business.riskLocation.data
  );

  const intrestedParities = useSelector(
    (storeState: IStoreState) =>
      storeState.borkerage.intrestedParties.intrestedParties.list
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData ? initialData : interest,
    validate: (values) => {},
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          setSaveLoading(true);
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          dispatch(
            addBusinesPolicyInterestedAsync(
              {
                ...values,
                customer_id: customerId,
                customer_policy_id: customerPolicyId,
                status: finalStatus,
                created_from: created_from || "Interested_Parties",
              },
              (isSuccess, data) => {
                if (isSuccess && data) {
                  window.scrollTo(0, 0);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  const handleIntrestedPartyCode = (data: IBusinessPolicyInterested) => {
    if (data) {
      setValues({
        ...values,
        interest: data.interest || "",
        interested_party_code: data.interested_party_code || "",
        interested_party: data.interested_party_name || "",
        address: data.address || "",
        unit_or_suite: data.unit_or_suite || "",
        city: data.city || "",
        country: data.country || "",
        province_or_state: data.province_or_state || "",
        postal_code: data.postal_code || "",
      });
    }
  };

  React.useEffect(() => {
    if (customerPolicyId && interestedNumber) {
      dispatch(
        fetchBusinessPolicyIntrestedAsync(customerPolicyId, interestedNumber)
      );
    }
  }, [customerPolicyId, interestedNumber]);

  React.useEffect(() => {
    setValues({ ...interest, incremental_number: interestedNumber || null });
  }, [interest]);

  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyIntrested());
    };
  }, []);

  React.useEffect(() => {
    if (customerPolicyId) {
      batch(() => {
        dispatch(fetchBusinessPolicyRiskListAsync(customerPolicyId));
        dispatch(fetchBrokerageIntrestedAllPartiesAsync());
      });
    }
  }, []);

  const riskLocationsDropDown = React.useMemo(() => {
    return riskLocations.map((item) => {
      return {
        label:
          item.location_number +
          ` (${item.address}, ${item.unit_or_suite}, ${item.city}, ${item.province_or_state}, ${item.postal_code})`,
        value: item.location_number || "",
      };
    });
  }, [riskLocations]);

  return (
    <PageLoader
      loading={intrestLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Interested Parties" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>#</CustomFormLabel>
                <CustomTextField
                  name="incremental_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.incremental_number}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.5}>
                <CustomFormLabel>Risk Location #</CustomFormLabel>

                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location"
                  fullWidth
                  displayEmpty
                  value={Number(values.risk_location)}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={riskLocationsDropDown}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <InterestedPartyCodeSearchSelect
                  label="Interested Party Code"
                  value={values.interested_party_code}
                  onSelect={(value) => handleIntrestedPartyCode(value)}
                />
              </Grid>

              <Grid item xs={12} lg={2.5}>
                <CustomFormLabel>Interested Party</CustomFormLabel>
                <CustomTextField
                  name="interested_party"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.interested_party}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Interest</CustomFormLabel>
                <CustomTextField
                  name="interest"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.interest}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3.5}>
                <CustomFormLabel>Address</CustomFormLabel>
                <LocationAutoComplete
                  id="address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.address}
                  onLocationChange={handleAddress}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  id="unit_or_suite"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.unit_or_suite}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  id="city"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>State/Province</CustomFormLabel>
                <CustomTextField
                  id="province_or_state"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.province_or_state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Postal Code</CustomFormLabel>
                <CustomTextField
                  id="postal_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.postal_code}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Country</CustomFormLabel>
                <CustomTextField
                  id="country"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                />
              </Grid>
              {!staticStatusValue && (
                <Grid container>
                  <Grid item xs={12} lg={3.5}>
                    <CustomFormLabel>Status</CustomFormLabel>
                    <ControlledCustomSelect
                      fullWidth
                      value={values.status}
                      name="status"
                      onChange={handleChange}
                      placeholder="Select one"
                      displayEmpty
                      options={COMMON_STATUS}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box sx={{ mt: 4 }}>
              <RoleBasedCustomButton
                sx={{ width: "15%" }}
                disabled={saveLoading}
                variant="contained"
                type="submit"
                moduleKey={MODULE_IDS.BUSINESS_POLICY}
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
