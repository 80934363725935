import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile } from "../../helpers";
import moment from "moment";
import { ISupportTickets } from "./supportTickets.types";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";

export const FETCH_SUPPORT_TICKETS_LIST_PROGRESS =
  "FETCH_SUPPORT_TICKETS_LIST_PROGRESS";
export const FETCH_SUPPORT_TICKETS_LIST_SUCCESS =
  "FETCH_SUPPORT_TICKETS_LIST_SUCCESS";
export const FETCH_SUPPORT_TICKETS_LIST_FAILED =
  "FETCH_SUPPORT_TICKETS_LIST_FAILED";

export const fetchSupportTicketsListProgress = () =>
  action(FETCH_SUPPORT_TICKETS_LIST_PROGRESS);
export const fetchSupportTicketsListSuccess = (
  data: ISupportTickets[],
  totalRecords: number
) => action(FETCH_SUPPORT_TICKETS_LIST_SUCCESS, { data, totalRecords });
export const fetchSupportTicketsListFailed = () =>
  action(FETCH_SUPPORT_TICKETS_LIST_FAILED);

export const fetchSupportTicketsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSupportTicketsListProgress());

      let finalUrl = `/other/get-support-ticket?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/other/get-support-ticket?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: ISupportTickets[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchSupportTicketsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSupportTicketsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_SUPPORT_TICKETS_PROGRESS = "FETCH_SUPPORT_TICKETS_PROGRESS";
export const FETCH_SUPPORT_TICKETS_SUCCESS = "FETCH_SUPPORT_TICKETS_SUCCESS";
export const FETCH_SUPPORT_TICKETS_FAILED = "FETCH_SUPPORT_TICKETS_FAILED";

export const fetchSupportTicketsProgress = () =>
  action(FETCH_SUPPORT_TICKETS_PROGRESS);
export const fetchSupportTicketsSuccess = (data: ISupportTickets) =>
  action(FETCH_SUPPORT_TICKETS_SUCCESS, { data });
export const fetchSupportTicketsFailed = (errorMessage?: string) =>
  action(FETCH_SUPPORT_TICKETS_FAILED, { errorMessage });

export const fetchSupportTicketsAsync =
  (supportTicketsCode: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSupportTicketsProgress());
      const res = await api.get(
        `/other/get-support-ticket?support_tickets_code=${supportTicketsCode}`
      );
      const data: ISupportTickets[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSupportTicketsSuccess(data[0]));
      } else {
        dispatch(
          fetchSupportTicketsFailed(
            "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertSupportTicketsAsync =
  (
    data: ISupportTickets,
    initialData: ISupportTickets,
    isUpdate: boolean,
    file: File | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        support_tickets_code: data.support_tickets_code,
      };
      const path = await uploadFile(
        file,
        "OTHER",
        data.file_upload || "",
        asPayload
      );
      await makeApiCall(
        {
          url: endPoints.support.upsertSupport,
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "support_tickets_code",
          },
        },
        initialData,
        {
          ...data,
          file_upload: path,
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Your Ticket saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SUPPORT_TICKETS = "CLEAR_SUPPORT_TICKETS";
export const clearSupportTickets = () => action(CLEAR_SUPPORT_TICKETS);
