import React from "react";

import { usePoliciesByCustomer } from "../../../../../../../hooks";
import {
    Box,
  Button,
  IconButton,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import produce from "immer";
import { getUniqueId } from "../../../../../../../helpers";
import { Add, Remove } from "@mui/icons-material";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import {
  attachment_status,
  habitational_attachment,
} from "../../../../../../../constants/constants";
import { FileUpload } from "../../../../../../../components/FileUpload/FileUpload";
import { IAutomobilePolicyChangeProps } from "./AutomobilePolicyChange.types";

export const PolicyChangeAttachmentUpload: React.FC<
  IAutomobilePolicyChangeProps
> = ({ values, setValues, errors, handleChange, setFieldValue }) => {
  const { policiesList, fetchPolicyOtherInfoByPolicyId } =
    usePoliciesByCustomer();

  const handleAdd =(index: number) => () => {
    const newFiles = produce(values.attachments, (draftState) => {
      draftState.splice(index,0,{
        key: getUniqueId(),
        description: null,
        status: "Pending",
        url: null,
        file: null,
      });
    });
    setValues({ ...values, attachments: newFiles });
  };

  const handleRemove = (index: number)=>() => {
    const newFiles = produce(values.attachments, (draftState) => {
      draftState.splice(index, 1);
    });
    setValues({ ...values, attachments: newFiles });
  };

    const handleRemoveFile =
    (rowIndex: number) =>()=> {
      const newFiles = produce(values.attachments, (draftState) => {
        draftState[rowIndex].file = null;
        draftState[rowIndex].url = null;
      });
      setValues({ ...values, attachments: newFiles });
    };

  const handleSelectChange =
    (key: string, index: number, newValue: any) => {
      const value = newValue as string;
      const newValues = produce(values.attachments, (draftValues) => {
        draftValues[index][key as "description"] = value;
      });
      setValues({ ...values, attachments: newValues });
    };

  const handleFileUpload = (rowIndex: number) => (file: File | null) => {
    const newFiles = produce(values.attachments, (draftState) => {
      draftState[rowIndex].file = file;
      draftState[rowIndex].url = null;
    });
    setValues({ ...values, attachments: newFiles });
  };

  return (
    <>
      <Stack
        direction={"row"}
        mt={2}
        justifyContent={"space-between"}
        spacing={1}
      >
        <Typography variant="h3" fontWeight={600}>
          Attachments
        </Typography>
        <Button size="small" variant="contained" onClick={handleAdd(values.attachments.length+1)}>
          <Add fontSize="small" />
        </Button>
      </Stack>

      <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {" "}
              <Typography fontWeight={600} variant="h4">
                #
              </Typography>
            </TableCell>
            <TableCell align="center">
              {" "}
              <Typography fontWeight={600} variant="h4">
                Status
              </Typography>
            </TableCell>
            <TableCell align="center">
              {" "}
              <Typography fontWeight={600} variant="h4">
                Description
              </Typography>
            </TableCell>
            <TableCell align="center">
              {" "}
              <Typography fontWeight={600} variant="h4">
                Documents
              </Typography>
            </TableCell>
            <TableCell align="center">
              {" "}
              <Typography fontWeight={600} variant="h4"></Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.attachments.map((item, index) => {
            return (
              <TableRow
                key={item.key}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    width: "5%",
                  }}
                >
                  {" "}
                  {index + 1}
                </TableCell>

                <TableCell
                  sx={{
                    width: "30%",
                  }}
                >
                  {" "}
                  <ExtendableSelect
                    value={item.status}
                    onChange={(newValue) => {
                      handleSelectChange("status", index,newValue);
                    }}
                    placeholder="Select"
                    options={attachment_status.map((business) => {
                      return { label: business, value: business };
                    })}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    width: "30%",
                  }}
                >
                  {" "}
                  <ExtendableSelect
                    value={item.description}
                    onChange={(newValue) => {
                      handleSelectChange("description", index,newValue);
                    }}
                    placeholder="Select"
                    options={habitational_attachment.map((business) => {
                      return { label: business, value: business };
                    })}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    width: "30%",
                  }}
                >
                  <FileUpload value={item.url} onChange={handleFileUpload(index)} onDelete={handleRemoveFile(index)} />
                </TableCell>
                <TableCell
                  sx={{
                    width: "5%",
                  }}
                >
                  <Box display={"flex"}>
                    <IconButton
                      color="error"
                      onClick={handleRemove(index)}
                    >
                      <Remove fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={handleAdd(index+1)}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
