import React from "react";
import { RightPanel } from "../../../../../../components/RightPanel";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { IBusinessPolicyLiability } from "../../../../../../redux/businessPolicy/businessPolicyForms/businessPolicyForms.types";
import { CSIOCertificate } from "./CSIOCertificate";

interface ICSIOCertificateRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  liabilityNumber?: number;
  onSaveSuccess: (data: IBusinessPolicyLiability) => void;
  staticStatusValue?: string;
  created_from?: string;
  readonly?: boolean;
  onClose: () => void;
  initialData?: IBusinessPolicyLiability;
  disableApiCalls?: boolean;
}

export const CSIOCertificateRightPanel: React.FC<
  ICSIOCertificateRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    liabilityNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Liability"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="80%"
      >
        <CSIOCertificate
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          liabilityNumber={liabilityNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          created_from={created_from}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
