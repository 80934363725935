import React from "react";
import { RightPanel } from "../../../../../../components/RightPanel";
import { InterestedParties } from "./InterestedParties";
import { IHabitationalInterested } from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";

interface IHabitationalRiskLocationRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  interestedNumber?: number;
  onSaveSuccess: (interestedParty: IHabitationalInterested) => void;
  readonly?: boolean;
  staticStatusValue?: string;
  createdFrom?: string;
  onClose: () => void;
    initialData?: IHabitationalInterested;
    disableApiCalls?: boolean;
}

export const InterestedPartiesRightPanel: React.FC<
  IHabitationalRiskLocationRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    interestedNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    createdFrom,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Inerested Party"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="80%"
      >
        <InterestedParties
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          interestedNumber={interestedNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          createdFrom={createdFrom}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
