import React from "react";
import { RightPanel } from "../../../../../../../components/RightPanel";
import {
  IAutomobileScheduleA,
  IAutomobileScheduleState,
} from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { AutomobileScheduleA } from "./AutomobileScheduleA";

interface IAutomobileScheduleARightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  onSaveSuccess: (data: IAutomobileScheduleA) => void;
  readonly?: boolean;
  onClose: () => void;
  staticStatusValue?: string;
  created_from?: string;
  initialData?: IAutomobileScheduleA;
  disableApiCalls?: boolean;
}

export const AutomobileScheduleARightPanel: React.FC<
  IAutomobileScheduleARightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Coverage"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="90%"
      >
        <AutomobileScheduleA
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          incrementalNumber={incrementalNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          created_from={created_from}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
