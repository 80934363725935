import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IVendor, initialAccountingVendorState } from "./Vendor.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IDataTableV2DateState } from "../../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_ACCOUNTING_VENDOR_PROGRESS =
  "FETCH_ACCOUNTING_VENDOR_PROGRESS";
export const FETCH_ACCOUNTING_VENDOR_SUCCESS =
  "FETCH_ACCOUNTING_VENDOR_SUCCESS";
export const FETCH_ACCOUNTING_VENDOR_FAILURE =
  "FETCH_ACCOUNTING_VENDOR_FAILURE";

export const fetchAccountingVendorProgress = () =>
  action(FETCH_ACCOUNTING_VENDOR_PROGRESS);

export const fetchAccountingVendorSuccess = (data: IVendor) =>
  action(FETCH_ACCOUNTING_VENDOR_SUCCESS, { data });

export const fetchAccountingVendorFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_VENDOR_FAILURE, { errorMessage });

export const fetchAccountingVendorAsync =
  (vendorCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingVendorProgress());
      const res = await api.get(
        `/accounting/get-account-vendor?vendor_code=${vendorCode}`
      );
      const data: IVendor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingVendorSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingVendorSuccess(initialAccountingVendorState["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingVendorFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingVendorAysnc =
  (
    data: IVendor,
    onCallback: (isSuccess: boolean, vendorCode: string | null) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/accounting/edit-account-vendor", {
        ...data,
      });
      const vendorCode = res.data.data.vendor_code;
      let message = "Vendor saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true, vendorCode);
    } catch (err: any) {
      onCallback(false, null);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_VENDOR_LIST_PROGRESS =
  "FETCH_ACCOUNTING_VENDOR_LIST_PROGRESS";
export const FETCH_ACCOUNTING_VENDOR_LIST_SUCCESS =
  "FETCH_ACCOUNTING_VENDOR_LIST_SUCCESS";
export const FETCH_ACCOUNTING_VENDOR_LIST_FAILURE =
  "FETCH_ACCOUNTING_VENDOR_LIST_FAILURE";

export const fetchAccountingVendorListProgress = () =>
  action(FETCH_ACCOUNTING_VENDOR_LIST_PROGRESS);
export const fetchAccountingVendorListSuccess = (data: IVendor[]) =>
  action(FETCH_ACCOUNTING_VENDOR_LIST_SUCCESS, { data });
export const fetchAccountingVendorListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_VENDOR_LIST_FAILURE, { errorMessage });

export const fetchAccountingVendorListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingVendorListProgress());

      let finalUrl = `/accounting/get-account-vendor?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/accounting/get-account-vendor?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IVendor[] = res.data.data;
      dispatch(fetchAccountingVendorListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingVendorListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_VENDOR = "CLEAR_ACCOUNTING_VENDOR";
export const clearAccountingVendor = () => action(CLEAR_ACCOUNTING_VENDOR);
