import { action } from "typesafe-actions";
import { IBrokerContact } from "./brokerContacts.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { getUniqueId, uploadFile } from "../../helpers";
import moment from "moment";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { uploadMultipleFile } from "../../components/FileUpload/utils";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_BROKER_CONTACTS_LIST_PROGRESS =
  "FETCH_BROKER_CONTACTS_LIST_PROGRESS";
export const FETCH_BROKER_CONTACTS_LIST_SUCCESS =
  "FETCH_BROKER_CONTACTS_LIST_SUCCESS";
export const FETCH_BROKER_CONTACTS_LIST_FAILED =
  "FETCH_BROKER_CONTACTS_LIST_FAILED";

export const fetchBrokerContactsListProgress = () =>
  action(FETCH_BROKER_CONTACTS_LIST_PROGRESS);
export const fetchBrokerContactsListSuccess = (
  data: IBrokerContact[],
  totalRecords: number
) => action(FETCH_BROKER_CONTACTS_LIST_SUCCESS, { data, totalRecords });
export const fetchBrokerContactsListFailed = () =>
  action(FETCH_BROKER_CONTACTS_LIST_FAILED);

export const fetchBrokerContactsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBrokerContactsListProgress());

      let finalUrl = `/insurance/get-broker-contracts?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/insurance/get-broker-contracts?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IBrokerContact[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBrokerContactsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBrokerContactsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BROKER_CONTACTS_PROGRESS = "FETCH_BROKER_CONTACTS_PROGRESS";
export const FETCH_BROKER_CONTACTS_SUCCESS = "FETCH_BROKER_CONTACTS_SUCCESS";
export const FETCH_BROKER_CONTACTS_FAILED = "FETCH_BROKER_CONTACTS_FAILED";

export const fetchBrokerContactsProgress = () =>
  action(FETCH_BROKER_CONTACTS_PROGRESS);
export const fetchBrokerContactsSuccess = (data: IBrokerContact) =>
  action(FETCH_BROKER_CONTACTS_SUCCESS, { data });
export const fetchBrokerContactsFailed = (errorMessage?: string) =>
  action(FETCH_BROKER_CONTACTS_FAILED, { errorMessage });

export const fetchBrokerContactsAsync =
  (brokerCode: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBrokerContactsProgress());
      const res = await api.get(
        `/insurance/get-broker-contracts?broker_contracts_code=${brokerCode}`
      );
      const data: IBrokerContact[] = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of (data[0].file_upload || []) as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(
          fetchBrokerContactsSuccess({ ...data[0], file_upload: attachments })
        );
      } else {
        dispatch(
          fetchBrokerContactsFailed(
            "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertBrokerContactAsync =
  (
    data: IBrokerContact,
    initialData: IBrokerContact,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        insurer_code_id: data.insurer,
      };
      const { paths } = await uploadMultipleFile(
        data.file_upload || [],
        "INSURER",
        asPayload
      );
      await makeApiCall(
        {
          url: endPoints.brokerContacts.createBorkerContact,
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "broker_contracts_code",
          },
        },
        initialData,
        {
          ...data,
          file_upload: paths,
          from: moment(data.from).format("YYYY-MM-DD"),
          to: moment(data.to).format("YYYY-MM-DD"),
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Broker contract saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BROKER_CONTACTS = "CLEAR_BROKER_CONTACTS";
export const clearBrokerContacts = () => action(CLEAR_BROKER_CONTACTS);
