import produce from "immer";
import {
  ADD_POLICY_COMMISSION,
  ADD_POLICY_COMMISSION_ID,
  ADD_POLICY_COMMISSION_PROGRESS,
  CLEAR_POLICY_COMMISSION_STATE,
  FETCH_POLICY_COMMISSION_LIST_FAILED,
  FETCH_POLICY_COMMISSION_LIST_PROGRESS,
  FETCH_POLICY_COMMISSION_LIST_SUCCESS,
  FETCH_POLICY_COMMISSION_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialPolicyCommissioneState } from "./policyCommissionState";
import { POLICY_COMMISSION_ERROR } from "./policyCommissionActions";
import { IPolicyCommissionActions } from ".";

export const policyCommissionReducer = (
  state: IStoreState["borkerage"]["policyCommission"] = initialPolicyCommissioneState,
  action: IPolicyCommissionActions
) => {
  switch (action.type) {
    case FETCH_POLICY_COMMISSION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.loading = LoadState.InProgress;
        draftState.policyCommission.list = [];
      });
      return newState;
    }
    case FETCH_POLICY_COMMISSION_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.loading = LoadState.Loaded;
        draftState.policyCommission.list = list;
      });
      return newState;
    }
    case FETCH_POLICY_COMMISSION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.loading = LoadState.InProgress;
        draftState.policyCommission.list = [];
      });
      return newState;
    }
    case ADD_POLICY_COMMISSION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.saveLoading = true;
      });
      return newState;
    }
    case ADD_POLICY_COMMISSION: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.loading = LoadState.Loaded;
        draftState.policyCommission.saveLoading = false;
        draftState.policyCommission.data = data;
      });
      return newState;
    }

    case FETCH_POLICY_COMMISSION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.loading = LoadState.InProgress;
      });
      return newState;
    }

    case POLICY_COMMISSION_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.policyCommission.error = action.payload.message;
        draftState.policyCommission.saveLoading = false;
        draftState.policyCommission.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_POLICY_COMMISSION_STATE: {
      return initialPolicyCommissioneState;
    }

    default: {
      return state;
    }
  }
};
