import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { PolicyChangeRequestActions } from ".";
import { initialPolicyChangeReqState } from "./policyChangeRequest.types";
import { CLEAR_POLICY_CHANGE_REQ, FETCH_POLICY_CHANGE_REQ_LIST_FAILED, FETCH_POLICY_CHANGE_REQ_LIST_PROGRESS, FETCH_POLICY_CHANGE_REQ_LIST_SUCCESS } from "./policyChangeRequestActions";


export const policyChangeRequestReducer = (
  state: IStoreState["tasks"]["policyChangeRequest"] = initialPolicyChangeReqState,
  action: PolicyChangeRequestActions
) => {
  switch (action.type) {
    case FETCH_POLICY_CHANGE_REQ_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_POLICY_CHANGE_REQ_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
      });
      return newState;
    }
    case FETCH_POLICY_CHANGE_REQ_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case CLEAR_POLICY_CHANGE_REQ: {
      return initialPolicyChangeReqState;
    }

    default: {
      return state;
    }
  }
};
