import { LoadState } from "../../../constants/enums";

export interface IBusinessPolicyInterestedState {
  data: IBusinessPolicyInterested[];
  loading: LoadState;
  interest: IBusinessPolicyInterested;
  intrestLoading: LoadState;
  error: string  | null;
}

export interface IBusinessPolicyInterested {
  customer_policy_id: number | null;
  customer_id: number | null;
  incremental_number: number | null;
  interested_party_name?: string;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  interested_party: string | null;
  interested_party_code: string | null;
  interest: string | null;
  risk_location: string | null;
  status: string;
  created_from: string | null;
}

export interface IInterestedRow {
  customer_policy_id: string;
  policy_number: string | null;
  customer_id: string | null;
  name: string | null;
}

export const defaultBusinessPolicyInterestedState: IBusinessPolicyInterestedState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    interest: {
      customer_policy_id: null,
      customer_id: null,
      incremental_number: null,
      status: "ACTIVE",
      address: null,
      city: null,
      country: null,
      postal_code: null,
      province_or_state: null,
      state_or_province: null,
      unit_or_suite: null,
      interested_party: null,
      risk_location: null,
      interest: null,
      interested_party_code: null,
      created_from: null,
    },
    intrestLoading: LoadState.NotLoaded,
    error: null,
  };
