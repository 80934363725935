import { action } from "typesafe-actions";
import { IOldBusinessTracker } from "./oldBusinessTracker.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";

export const FETCH_OLD_BUSINESS_TRACKER_LIST_PROGRESS = "FETCH_OLD_BUSINESS_TRACKER_LIST_PROGRESS";
export const FETCH_OLD_BUSINESS_TRACKER_LIST_SUCCESS = "FETCH_OLD_BUSINESS_TRACKER_LIST_SUCCESS";
export const FETCH_OLD_BUSINESS_TRACKER_LIST_FAILED = "FETCH_OLD_BUSINESS_TRACKER_LIST_FAILED";

export const fetchOldBusinessTrackerListProgress = () => action(FETCH_OLD_BUSINESS_TRACKER_LIST_PROGRESS);
export const fetchOldBusinessTrackerListSuccess = (list: IOldBusinessTracker[]) =>
  action(FETCH_OLD_BUSINESS_TRACKER_LIST_SUCCESS, { list });
export const fetchOldBusinessTrackerListFailed = () => action(FETCH_OLD_BUSINESS_TRACKER_LIST_FAILED);

export const fetchOldBusinessTrackerListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOldBusinessTrackerListProgress());
      let finalUrl = `/other/get-old-business-tracker?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/other/get-old-business-tracker?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&column=task_status&value=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IOldBusinessTracker[] = res.data.data;

      dispatch(fetchOldBusinessTrackerListSuccess(data ));
    } catch (err: any) {
      dispatch(fetchOldBusinessTrackerListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const CLEAR_OLD_BUSINESS_TRACKER = "CLEAR_OLD_BUSINESS_TRACKER";


  export const clearInsurerState = () => action(CLEAR_OLD_BUSINESS_TRACKER);