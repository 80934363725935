import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { FETCH_ACCOUNTING_GL_ACCOUNTS_FAILURE, FETCH_ACCOUNTING_GL_ACCOUNTS_PROGRESS, FETCH_ACCOUNTING_GL_ACCOUNTS_SUCCESS } from "./glAccountsActions";
import { initialAccountingGLAccountsState } from "./glAccounts.types";
import { GLAccountsActions } from ".";

export const AccountingGLAccountsReducer = (
  state: IStoreState["accounting"]["accountingGLAccounts"] = initialAccountingGLAccountsState,
  action: GLAccountsActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_GL_ACCOUNTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_GL_ACCOUNTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_GL_ACCOUNTS_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    default:
      return state;
  }
};
