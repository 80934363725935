import { action } from "typesafe-actions";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { showMessage } from "../messages/messagesActions";
import { ICSIOEDI, ICSIOEDIMapping } from "./csioEDI.types";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_CSIO_EDI_LIST_PROGRESS = "FETCH_CSIO_EDI_LIST_PROGRESS";
export const FETCH_CSIO_EDI_LIST_SUCCESS = "FETCH_CSIO_EDI_LIST_SUCCESS";
export const FETCH_CSIO_EDI_LIST_FAILED = "FETCH_CSIO_EDI_LIST_FAILED";

export const fetchCSIOEDIListProgress = () =>
  action(FETCH_CSIO_EDI_LIST_PROGRESS);
export const fetchCSIOEDIListSuccess = (
  list: ICSIOEDI[],
) => action(FETCH_CSIO_EDI_LIST_SUCCESS, { list });
export const fetchCSIOEDIListFailed = () => action(FETCH_CSIO_EDI_LIST_FAILED);

export const fetchCSIOEDIListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOEDIListProgress());
      let finalUrl = `/csio/get-csio-edi-logs?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/csio/get-csio-edi-logs?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: ICSIOEDI[] = res.data.data;

      dispatch(fetchCSIOEDIListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCSIOEDIListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/**---------- */
export const FETCH_CSIO_EDI_PROGRESS = "FETCH_CSIO_EDI_PROGRESS";
export const FETCH_CSIO_EDI_SUCCESS = "FETCH_CSIO_EDI_SUCCESS";
export const FETCH_CSIO_EDI_FAILED = "FETCH_CSIO_EDI_FAILED";

export const fetchCSIOEDIProgress = () => action(FETCH_CSIO_EDI_PROGRESS);
export const fetchCSIOEDISuccess = (list: ICSIOEDIMapping[]) =>
  action(FETCH_CSIO_EDI_SUCCESS, { list });
export const fetchCSIOEDIFailed = (error: string) => action(FETCH_CSIO_EDI_FAILED,{error});

export const fetchCSIOEDIAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    messageId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOEDIProgress());
      let finalUrl = `/csio/get-csio-policy-mapping-logs?message_guid=${messageId}&pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/csio/get-csio-policy-mapping-logs?message_guid=${messageId}&pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      if(res.data.data.length === 0){
        dispatch(fetchCSIOEDIFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
      else {
        const data: ICSIOEDIMapping[] = res.data.data;

        dispatch(fetchCSIOEDISuccess(data));
      }
     
    } catch (err: any) {
      dispatch(fetchCSIOEDIFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_CSIO_EDI_STATE = "CLEAR_CSIO_EDI_STATE";
export const clearCSIOEdiState = () => action(CLEAR_CSIO_EDI_STATE);
