/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import {
  apparatus,
  COMMON_STATUS,
  detached_structure_type,
  distance_to_hydrant,
  distance_to_reponding_fire_hall,
  electrical_panel_type,
  electrical_wiring_type,
  exterior_wall_framing_type,
  fire_burglar_smoke,
  foundation_type,
  fuel,
  garage_type,
  hibatational_location,
  hydrant_type,
  interior_wall_construction_type,
  kitchen_security,
  main_value_shout_off,
  pool_type,
  roof_covering_type,
  security_type,
  smoke_detector_type,
  structure_type,
  water_auxiliary_power,
  water_back_up_value,
  water_heater_apparatus,
  water_pump_type,
} from "../../../../../../constants/constants";
import produce from "immer";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import {
  addHabitationalRiskLocationAsync,
  clearHabitationalRiskLocation,
  fetchHabitationalRiskAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { IHabitationalRiskLocation } from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocation.types";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IHabitationalRiskLocationFormProps {
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (riskLocation: IHabitationalRiskLocation) => void;
  initialData?: IHabitationalRiskLocation;
  disableApiCalls?: boolean;
}

export const HabitationalRiskLocationForm: React.FC<
  IHabitationalRiskLocationFormProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onSaveSuccess,
    readOnly = false,
    fromRightPanel = false,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
  } = props;

  const {
    loading,
    risklocation,
    error: notFoundError,
  } = useSelector(
    (storeState: IStoreState) => storeState.habitational.riskLocation
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: initialData ? initialData : risklocation,
      validate: (values) => {},
      onSubmit: (values) => {
        if (disableApiCalls) {
          onSaveSuccess(values);
        } else {
          if (customerPolicyId && customerId) {
            let statusValue = values.status;
            if (staticStatusValue) {
              statusValue = staticStatusValue;
            }
            setSaveLoading(true);
            dispatch(
              addHabitationalRiskLocationAsync(
                {
                  ...values,
                  status: statusValue,
                  created_from: created_from || "Risk_Location",
                },
                customerPolicyId,
                (isSuccess, risklocation) => {
                  if (isSuccess && risklocation) {
                    window.scrollTo(0, 0);
                    onSaveSuccess(risklocation);
                    // changeTab(3)
                    setSaveLoading(true);
                  }
                }
              )
            );
          }
        }
      },
    });

  const updateApplicantInteriorWallConstructionType = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      values.interior_wall_construction_type,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("interior_wall_construction_type", newValues);
  };

  const handleChangeAutomobileInteriorWallConstructionType =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApplicantInteriorWallConstructionType(
        key,
        rowIndex,
        e.target.value
      );
    };

  const handleSelectAutomobileInteriorWallConstructionType =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateApplicantInteriorWallConstructionType(
        key,
        rowIndex,
        e.target.value
      );
    };

  const updateApplicantInteriorWallHeight = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.interior_wall_height, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("interior_wall_height", newValues);
  };

  const handleChangeAutomobileInteriorWallHeight =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApplicantInteriorWallHeight(key, rowIndex, e.target.value);
    };

  const updateApplicantInteriorWallFinishType = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      values.interior_wall_finish_type,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("interior_wall_finish_type", newValues);
  };

  const handleChangeAutomobileInteriorWallFinishType =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApplicantInteriorWallFinishType(key, rowIndex, e.target.value);
    };

  const handleSelectAutomobileInteriorWallFinishType =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateApplicantInteriorWallFinishType(key, rowIndex, e.target.value);
    };

  const updateApplicantCeiling = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      values.ceiling_construction_type,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("ceiling_construction_type", newValues);
  };

  const handleChangeAutomobileCeiling =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApplicantCeiling(key, rowIndex, e.target.value);
    };

  const handleSelectAutomobileCeiling =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateApplicantCeiling(key, rowIndex, e.target.value);
    };

  const updateApplicantRiskLocationUpgrade = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.risk_location_upgrades, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("risk_location_upgrades", newValues);
  };

  const handleChangeAutomobileRiskLocationUpgrade =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApplicantRiskLocationUpgrade(key, rowIndex, e.target.value);
    };

  const updateDetachedOutBuildingsStructures = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      values.detached_outbuilding_stucture,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("detached_outbuilding_stucture", newValues);
  };

  const handleChangeDetachedOutBuildingsStructures =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateDetachedOutBuildingsStructures(key, rowIndex, e.target.value);
    };

  const handleSelectDetachedOutBuildingsStructures =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateDetachedOutBuildingsStructures(key, rowIndex, e.target.value);
    };

  const handleCheckboxDetachedOutBuildingsStructures =
    (key: string, rowIndex: number, value: string) =>
    (e: any, checked: boolean) => {
      // console.log(e.target.value);

      updateDetachedOutBuildingsStructures(key, rowIndex, value);
    };
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  function getSelectedLabel(value: string | null): string | undefined {
    if (!value) return undefined;
    const option = distance_to_hydrant.find((opt) =>
      opt.values.includes(value)
    );
    return option ? option.label : undefined;
  }

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && !disableApiCalls) {
      dispatch(fetchHabitationalRiskAsync(customerPolicyId, incrementalNumber));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    if (initialData && disableApiCalls) {
      return;
    }
    setValues(risklocation);
  }, [risklocation]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalRiskLocation());
    };
  }, []);

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={notFoundError ? { message: notFoundError } : null}
    >
      {/* <Breadcrumb title="" items={BCrumb} />
      <CustomerBaiscDetailsLayout customerId={customerId}> */}
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Risk Location Form" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3.5}>
                <CustomFormLabel>Address</CustomFormLabel>
                <LocationAutoComplete
                  id="address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.address}
                  onLocationChange={handleAddress}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  name="unit_or_suite"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.unit_or_suite}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  name="city"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Province</CustomFormLabel>
                <CustomTextField
                  name="province_or_state"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.province_or_state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Country</CustomFormLabel>
                <CustomTextField
                  name="country"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Postal/ZIP Code</CustomFormLabel>
                <CustomTextField
                  name="postal_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.postal_code}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Construction" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Year Built</CustomFormLabel>
                <CustomTextField
                  name="risk_location_construction.year_built"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.risk_location_construction.year_built}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>No. of Storeys</CustomFormLabel>
                <CustomTextField
                  name="risk_location_construction.no_of_storeys"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.risk_location_construction.no_of_storeys}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>No. of Families</CustomFormLabel>
                <CustomTextField
                  name="risk_location_construction.no_of_families"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.risk_location_construction.no_of_families}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>No. of Units</CustomFormLabel>
                <CustomTextField
                  name="risk_location_construction.no_of_units"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.risk_location_construction.no_of_units}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Total Living Area (excluding basement)
                </CustomFormLabel>
                <Stack direction="row" spacing={1}>
                  <CustomTextField
                    name="risk_location_construction.total_living_area"
                    variant="outlined"
                    size="small"
                    type="text"
                    // fullWidth
                    value={values.risk_location_construction.total_living_area}
                    onChange={handleChange}
                    sx={{ width: "70%" }}
                  />
                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomTextField
                        name="risk_location_construction.total_living_area_unit"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={
                          values.risk_location_construction
                            .total_living_area_unit
                        }
                        onChange={handleChange}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Access Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.access_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_construction.access_type}
                  onChange={handleChange}
                  placeholder={
                    values.risk_location_construction.access_type || "Select"
                  }
                  options={[
                    "Easy Access Road",
                    "Difficult Access Road",
                    "Island",
                    "Isolated Rural",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Smokers</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.risk_location_construction.smokers === "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "risk_location_construction.smokers",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.risk_location_construction.smokers === "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "risk_location_construction.smokers",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Replacement Cost Emulator Product
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.replacement_cost_emulator_product"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_construction
                      .replacement_cost_emulator_product
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={[
                    "Applied iTV",
                    "e2Value",
                    "ezITV",
                    "ezITVTM",
                    "Clarify",
                    "Means CostWorks",
                    "PowerHouse",
                    "RCT",
                    "RCT EvaluRater",
                    "RCT Express",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Date Evaluation Completed</CustomFormLabel>
                <CustomDatePicker
                  value={
                    values.risk_location_construction.data_evaluation_completed
                  }
                  fullWidth
                  onChange={(date) => {
                    setFieldValue("data_evaluation_completed", date);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Date of Birth of Eldest Occupant
                </CustomFormLabel>
                <CustomDatePicker
                  value={
                    values.risk_location_construction
                      .date_of_birth_of_eldest_occupant
                  }
                  fullWidth
                  onChange={(date) => {
                    setFieldValue("date_of_birth_of_eldest_occupant", date);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Relationship to Applicant</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.relationship_to_applicant"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_construction.relationship_to_applicant
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={[
                    "Insured",
                    "Spouse",
                    "Common Law Spouse",
                    "Partner , Same-sex",
                    "Parent",
                    "Brother/Sister",
                    "Child",
                    "Co-habitant",
                    "Employee",
                    "Lessee",
                    "Other Relative",
                    "Third Party",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Occupancy Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.occupancy_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_construction.occupancy_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={[
                    "Primary Residence",
                    "Secondary, Non-seasonal",
                    "Secondary, Seasonal",
                    "Rental",
                    "Not Applicable",
                    "Unoccupied",
                    "Vacant",
                    "Rented to Third Party",
                    "Under Construction",
                    "Employee Occupied",
                    "Major Renovation",
                    "Other",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Structure Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.structure_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_construction.structure_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={structure_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Foundation Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.foundation_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_construction.foundation_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={foundation_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Finished Basement %</CustomFormLabel>
                <CustomTextField
                  name="risk_location_construction.finished_basement_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.risk_location_construction
                      .finished_basement_percentage
                  }
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Exterior Wall Framing Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.exterior_wall_framing_type"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_construction.exterior_wall_framing_type
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={exterior_wall_framing_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Exterior Wall Finish Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_construction.exterior_wall_finish_type"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_construction.exterior_wall_finish_type
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={exterior_wall_framing_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={4} md={4}>
                <Typography fontWeight={600}>
                  Interior Wall Construction Type
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Percentage</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
              {values.interior_wall_construction_type?.map((wall, index) => {
                return (
                  <Grid container spacing={1} marginTop={2} key={wall.key}>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <ControlledCustomSelect
                        variant="outlined"
                        size="small"
                        type="string"
                        name="wall.type"
                        fullWidth
                        displayEmpty
                        value={wall.type}
                        onChange={handleSelectAutomobileInteriorWallConstructionType(
                          "type",
                          index
                        )}
                        placeholder="Select"
                        options={interior_wall_construction_type.map(
                          (business) => {
                            return { label: business, value: business };
                          }
                        )}
                      ></ControlledCustomSelect>
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        name="wall.percentage"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        value={wall.percentage}
                        onChange={handleChangeAutomobileInteriorWallConstructionType(
                          "percentage",
                          index
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={4} md={4}>
                <Typography fontWeight={600}>Interior Wall Height</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Height</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Percentage</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              {values.interior_wall_height?.map((wallHeight, index) => {
                return (
                  <Grid container spacing={1} marginTop={2} key={index}>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <Stack direction="row" spacing={1}>
                        <CustomTextField
                          name="wallHeight.height"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={wallHeight.height}
                          onChange={handleChangeAutomobileInteriorWallHeight(
                            "height",
                            index
                          )}
                          sx={{ width: "60%" }}
                        />
                        <Stack direction={"row"} alignItems={"center"}>
                          <Checkbox
                            checked={wallHeight.height_unit === "sq"}
                            onChange={(e) => {
                              updateApplicantInteriorWallHeight(
                                "height_unit",
                                index,
                                "sq"
                              );
                            }}
                          />
                          <Typography>sq ft</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Checkbox
                            checked={wallHeight.height_unit === "m"}
                            onChange={(e) => {
                              updateApplicantInteriorWallHeight(
                                "height_unit",
                                index,
                                "m"
                              );
                            }}
                          />
                          <Typography>m</Typography>
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        name="percentage"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        value={wallHeight.percentage}
                        onChange={handleChangeAutomobileInteriorWallHeight(
                          "percentage",
                          index
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={4} md={4}>
                <Typography fontWeight={600}>
                  Interior Floor Finish Type
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Percentage</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              {values.interior_wall_finish_type?.map((wallFinish, index) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    marginTop={2}
                    key={wallFinish.key}
                  >
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <ControlledCustomSelect
                        variant="outlined"
                        size="small"
                        type="string"
                        name="wallFinish.type"
                        fullWidth
                        displayEmpty
                        value={wallFinish.type}
                        onChange={handleSelectAutomobileInteriorWallFinishType(
                          "type",
                          index
                        )}
                        placeholder="Select"
                        options={interior_wall_construction_type.map(
                          (business) => {
                            return { label: business, value: business };
                          }
                        )}
                      ></ControlledCustomSelect>
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        id=""
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        value={wallFinish.percentage}
                        onChange={handleChangeAutomobileInteriorWallFinishType(
                          "percentage",
                          index
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={4} md={4}>
                <Typography fontWeight={600}>
                  Ceiling Construction Type
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Percentage</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              {values.ceiling_construction_type?.map((ceiling, index) => {
                return (
                  <Grid container spacing={1} marginTop={2} key={ceiling.key}>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <ControlledCustomSelect
                        variant="outlined"
                        size="small"
                        type="string"
                        name="basement"
                        fullWidth
                        displayEmpty
                        value={ceiling.type}
                        onChange={handleSelectAutomobileCeiling("type", index)}
                        placeholder="Select"
                        options={interior_wall_construction_type.map(
                          (business) => {
                            return { label: business, value: business };
                          }
                        )}
                      ></ControlledCustomSelect>
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        id=""
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={ceiling.percentage}
                        onChange={handleChangeAutomobileCeiling(
                          "percentage",
                          index
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={1} marginTop={3}>
              <Grid item xs={12} md={12}></Grid>

              <Grid item xs={4} md={4}>
                <Typography fontWeight={600}>Upgrades</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Full(YY)</Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography>Partial(YY)</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              {values.risk_location_upgrades?.map((upgrade, index) => {
                return (
                  <Grid container spacing={1} marginTop={2} key={upgrade.key}>
                    <Grid item xs={4} md={4}>
                      {upgrade.upgrade_name}
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        name="full_yy"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={upgrade.full_yy}
                        onChange={handleChangeAutomobileRiskLocationUpgrade(
                          "full_yy",
                          index
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <CustomTextField
                        name="partial_yy"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={upgrade.partial_yy}
                        onChange={handleChangeAutomobileRiskLocationUpgrade(
                          "partial_yy",
                          index
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Roof Covering Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_roof_covering_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_roof_covering_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={roof_covering_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Electrical Wiring Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_electrical_wiring_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_electrical_wiring_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={electrical_wiring_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Electrical Panel Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_electrical_panel_type"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_electrical_panel_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={electrical_panel_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Service</CustomFormLabel>
                <CustomTextField
                  name="risk_location_service"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.risk_location_service}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Primary Heating Type" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Apparatus</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_heating_type.apparatus"
                  fullWidth
                  displayEmpty
                  value={values.primary_heating_type.apparatus}
                  onChange={handleChange}
                  placeholder="Select"
                  options={apparatus.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Fuel</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_heating_type.fuel"
                  fullWidth
                  displayEmpty
                  value={values.primary_heating_type.fuel}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fuel.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Location</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_heating_type.location"
                  fullWidth
                  displayEmpty
                  value={values.primary_heating_type.location}
                  onChange={handleChange}
                  placeholder="Select"
                  options={hibatational_location.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              {/* <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Professionally Installed</CustomFormLabel>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    checked={values.primary_heating_type.professionally_installed === "Yes"}
                    value={
                      values.primary_heating_type.professionally_installed
                    }
                  // onChange={}
                  />
                  <Typography>Yes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    value={
                      values.primary_heating_type.professionally_installed
                    }
                  />
                  <Typography>No</Typography>
                </Box>
              </Stack>
            </Grid> */}

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Professionally Installed</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.primary_heating_type.professionally_installed ===
                        "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "primary_heating_type.professionally_installed",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.primary_heating_type.professionally_installed ===
                        "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "primary_heating_type.professionally_installed",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>

              {/* <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Approved by ULC, CSA, or WH</CustomFormLabel>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    value={
                      values.primary_heating_type.approved_by_ulc_csa_or_wh
                    }
                  />
                  <Typography>Yes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox
                    value={
                      values.primary_heating_type.approved_by_ulc_csa_or_wh
                    }
                  />
                  <Typography>No</Typography>
                </Box>
              </Stack>
            </Grid> */}
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Approved by ULC, CSA, or WH</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.primary_heating_type
                          .approved_by_ulc_csa_or_wh === "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "primary_heating_type.approved_by_ulc_csa_or_wh",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.primary_heating_type
                          .approved_by_ulc_csa_or_wh === "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "primary_heating_type.approved_by_ulc_csa_or_wh",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <LabelCard heading="Auxiliary Heating Type" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Apparatus</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_heating_type.apparatus"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_heating_type.apparatus}
                  onChange={handleChange}
                  placeholder="Select"
                  options={apparatus.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fuel</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_heating_type.fuel"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_heating_type.fuel}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fuel.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Location</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_heating_type.location"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_heating_type.location}
                  onChange={handleChange}
                  placeholder="Select"
                  options={hibatational_location.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Professionally Installed</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.auxiliary_heating_type
                          .professionally_installed === "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "auxiliary_heating_type.professionally_installed",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.auxiliary_heating_type
                          .professionally_installed === "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "auxiliary_heating_type.professionally_installed",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Approved by ULC, CSA, or WH</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.auxiliary_heating_type
                          .approved_by_ulc_csa_or_wh === "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "auxiliary_heating_type.approved_by_ulc_csa_or_wh",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.auxiliary_heating_type
                          .approved_by_ulc_csa_or_wh === "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "auxiliary_heating_type.approved_by_ulc_csa_or_wh",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>No. of Face Cords Per Year</CustomFormLabel>
                <CustomTextField
                  name="auxiliary_heating_type.no_of_face_cords_per_year"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.auxiliary_heating_type.no_of_face_cords_per_year
                  }
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Solid Fuel Heating Questionaire Attached
                </CustomFormLabel>
                <Checkbox
                  checked={
                    values.auxiliary_heating_type
                      .solid_fuel_heating_questionaire_attached === "Y"
                  }
                  onChange={(e, checked) => {
                    setFieldValue(
                      "auxiliary_heating_type.solid_fuel_heating_questionaire_attached",
                      checked ? "Y" : "N"
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Radiant Heating Area</CustomFormLabel>
                <Stack direction="row" spacing={1}>
                  <CustomTextField
                    name="auxiliary_heating_type.radiant_heating_area"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={values.auxiliary_heating_type.radiant_heating_area}
                    onChange={handleChange}
                    sx={{ width: "70%" }}
                  />
                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type
                            .radiant_heating_area_unit === "sq"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.radiant_heating_area_unit",
                            checked ? "sq" : ""
                          );
                        }}
                      />
                      <Typography>sq ft</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type
                            .radiant_heating_area_unit === "m"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.radiant_heating_area_unit",
                            checked ? "m" : ""
                          );
                        }}
                      />
                      <Typography>m^2</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Make</CustomFormLabel>
                <CustomTextField
                  name="auxiliary_heating_type.make"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.auxiliary_heating_type.make}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Year</CustomFormLabel>
                <CustomTextField
                  name="auxiliary_heating_type.year"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.auxiliary_heating_type.year}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Oil Tank Year</CustomFormLabel>
                <Stack direction="row" spacing={1}>
                  <CustomTextField
                    name="auxiliary_heating_type.oil_tank_year"
                    variant="outlined"
                    size="small"
                    type="text"
                    // fullWidth
                    value={values.auxiliary_heating_type.oil_tank_year}
                    onChange={handleChange}
                  />
                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type.oil_tank_year_type ===
                          "inside"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.oil_tank_year_type",
                            checked ? "inside" : ""
                          );
                        }}
                      />
                      <Typography>Inside</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type.oil_tank_year_type ===
                          "outside"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.oil_tank_year_type",
                            checked ? "outside" : ""
                          );
                        }}
                      />
                      <Typography>Outside</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type.oil_tank_year_type ===
                          "inGround"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.oil_tank_year_type",
                            checked ? "inGround" : ""
                          );
                        }}
                      />
                      <Typography>In Ground</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          values.auxiliary_heating_type.oil_tank_year_type ===
                          "aboveGround"
                        }
                        onChange={(e, checked) => {
                          setFieldValue(
                            "auxiliary_heating_type.oil_tank_year_type",
                            checked ? "aboveGround" : ""
                          );
                        }}
                      />
                      <Typography>Above Ground</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>
                  Fuel Oil Tank Questionaire Attached
                </CustomFormLabel>
                <Checkbox
                  checked={
                    values.auxiliary_heating_type
                      .fuel_oil_tank_questionaire_attached === "Yes"
                  }
                  onChange={(e, checked) => {
                    setFieldValue(
                      "auxiliary_heating_type.fuel_oil_tank_questionaire_attached",
                      checked ? "Yes" : ""
                    );
                  }}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Plumbing Type" />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Copper %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.copper_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.copper_percentage}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Galvanized %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.galvanized_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.gaivanized_percentage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>ABS %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.abs_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.abs_percentage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>PVC %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.pvc_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.pvc_percentage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>PEX %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.pex_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.pex_percentage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>POLY-B %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.poly_b_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.poly_b_percentage}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>LEAD %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.lead_percentage"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.lead_percentage}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>Other %</CustomFormLabel>
                <CustomTextField
                  name="plumbing_type.other"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.plumbing_type.other}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Water Heater Type" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Apparatus</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="water_heater_type.apparatus"
                  fullWidth
                  displayEmpty
                  value={values.water_heater_type.apparatus}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_heater_apparatus.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Water Heater Type</CustomFormLabel>
                <CustomTextField
                  name="water_heater_type.water_heater_type"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.water_heater_type.water_heater_type}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Fuel</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="water_heater_type.fuel"
                  fullWidth
                  displayEmpty
                  value={values.water_heater_type.fuel}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fuel.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Professionally Installed</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.water_heater_type.professionally_installed ===
                        "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "water_heater_type.professionally_installed",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.water_heater_type.professionally_installed ===
                        "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "water_heater_type.professionally_installed",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Approved by ULC, CSA, or WH</CustomFormLabel>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.water_heater_type.approved_by_ulc_csa_or_wh ===
                        "Yes"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "water_heater_type.approved_by_ulc_csa_or_wh",
                          "Yes"
                        );
                      }}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={
                        values.water_heater_type.approved_by_ulc_csa_or_wh ===
                        "No"
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "water_heater_type.approved_by_ulc_csa_or_wh",
                          "No"
                        );
                      }}
                    />
                    <Typography>No</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <LabelCard heading="Primary Water Mitigation Type" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Sump Pump Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_water_mitigation_type.sump_pump_type"
                  fullWidth
                  displayEmpty
                  value={values.primary_water_mitigation_type.sump_pump_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_pump_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Auxiliary Power</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_water_mitigation_type.auxiliary_power"
                  fullWidth
                  displayEmpty
                  value={values.primary_water_mitigation_type.auxiliary_power}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_auxiliary_power.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Backup Value</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="primary_water_mitigation_type.backup_value"
                  fullWidth
                  displayEmpty
                  value={values.primary_water_mitigation_type.backup_value}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_back_up_value.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
            <LabelCard heading="Auxiliary Water Mitigation Type" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Sump Pump Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_water_mitigation_type.sump_pump_type"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_water_mitigation_type.sump_pump_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_pump_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Auxiliary Power</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_water_mitigation_type.auxiliary_power"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_water_mitigation_type.auxiliary_power}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_auxiliary_power.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Backup Value</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_water_mitigation_type.backup_value"
                  fullWidth
                  displayEmpty
                  value={values.auxiliary_water_mitigation_type.backup_value}
                  onChange={handleChange}
                  placeholder="Select"
                  options={water_back_up_value.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Main Water Value Shut off Type
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="auxiliary_water_mitigation_type.main_water_value_shut_off_type"
                  fullWidth
                  displayEmpty
                  value={
                    values.auxiliary_water_mitigation_type
                      .main_water_value_shut_off_type
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={main_value_shout_off.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  No. of Main Water Value Shut off Sensors
                </CustomFormLabel>
                <CustomTextField
                  name="auxiliary_water_mitigation_type.no_of_main_water_value_shut_off_sensor"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.auxiliary_water_mitigation_type
                      .no_of_main_water_value_shut_off_sensor
                  }
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>
                  Sewer Backup Questionaire Attached
                </CustomFormLabel>
                <Checkbox
                  checked={
                    values.auxiliary_water_mitigation_type
                      .sewer_backeup_questionaire_attached === "Yes"
                  }
                  onChange={(e, checked) => {
                    setFieldValue(
                      "auxiliary_water_mitigation_type.sewer_backeup_questionaire_attached",
                      checked ? "Yes" : ""
                    );
                  }}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Fire Protection" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Distance to Hydrant</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="fire_protection.distance_to_hydrant"
                  fullWidth
                  displayEmpty
                  value={
                    getSelectedLabel(
                      values.fire_protection.distance_to_hydrant
                    ) || ""
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={distance_to_hydrant.map((opt) => ({
                    label: opt.label,
                    value: opt.label,
                  }))}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Hydrant Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="fire_protection.hydrant_type"
                  fullWidth
                  displayEmpty
                  value={values.fire_protection.hydrant_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={hydrant_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  Distance to Responding Fire Hall
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="fire_protection.distance_to_responding_fire_hall"
                  fullWidth
                  displayEmpty
                  value={
                    values.fire_protection.distance_to_responding_fire_hall
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={distance_to_reponding_fire_hall.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire Hall Name</CustomFormLabel>
                <CustomTextField
                  name="fire_protection.fire_hall_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.fire_protection.fire_hall_name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Security System" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="security_system.fire"
                  fullWidth
                  displayEmpty
                  value={values.security_system.fire}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fire_burglar_smoke.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Burglary</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="security_system.burglary"
                  fullWidth
                  displayEmpty
                  value={values.security_system.burglary}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fire_burglar_smoke.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Smoke Detectors</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="security_system.smoke_detectors"
                  fullWidth
                  displayEmpty
                  value={values.security_system.smoke_detectors}
                  onChange={handleChange}
                  placeholder="Select"
                  options={fire_burglar_smoke.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Smoke Detector Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="security_system.smoke_detector_type"
                  fullWidth
                  displayEmpty
                  value={values.security_system.smoke_detector_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={smoke_detector_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>No. of Detectors</CustomFormLabel>
                <CustomTextField
                  name="security_system.no_of_detectors"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.security_system.no_of_detectors}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>
                  If Any of the Above are Monitored, Monitored By
                </CustomFormLabel>
                <CustomTextField
                  name="security_system.if_any_of_the_above_are_monitored_monitored_by"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.security_system
                      .if_any_of_the_above_are_monitored_monitored_by
                  }
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Alarm Certificate Attached</CustomFormLabel>
                <Checkbox
                  checked={values.security_system.alarm_certificate === true}
                  value={values.security_system.alarm_certificate}
                  onChange={(e, checked) => {
                    setFieldValue(
                      "security_system.alarm_certificate",
                      checked ? true : false
                    );
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} lg={2}>
              <CustomFormLabel>Home Sprinklered?</CustomFormLabel>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox />
                  <Typography>Yes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox />
                  <Typography>No</Typography>
                </Box>
              </Stack>
            </Grid> */}

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Premises Access Security Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="security_system.premises_type_security_system"
                  fullWidth
                  displayEmpty
                  value={values.security_system.premises_type_security_system}
                  onChange={handleChange}
                  placeholder="Select"
                  options={security_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="bathrooms" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>No. of Full</CustomFormLabel>
                <CustomTextField
                  name="bathrooms.no_of_full"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.bathrooms.no_of_full}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>No. of Half</CustomFormLabel>
                <CustomTextField
                  name="bathrooms.no_of_half"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.bathrooms.no_of_half}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <LabelCard heading="Kitchens" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>No. of Kitchens</CustomFormLabel>
                <CustomTextField
                  name="kitchens.no_of_kitchens"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.kitchens.no_of_kitchens}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Kitchen #1 Quality</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="kitchens.kitchen_1_quality"
                  fullWidth
                  displayEmpty
                  value={values.kitchens.kitchen_1_quality}
                  onChange={handleChange}
                  placeholder="Select"
                  options={kitchen_security.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Kitchen #2 Quality</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="kitchens.kitchen_2_quality"
                  fullWidth
                  displayEmpty
                  value={values.kitchens.kitchen_2_quality}
                  onChange={handleChange}
                  placeholder="Select"
                  options={kitchen_security.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="Garage/Carport" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>No. of Cars</CustomFormLabel>
                <CustomTextField
                  name="garage_or_carport.no_of_cars"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.garage_or_carport.no_of_cars}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Garage Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="garage_or_carport.garage_type"
                  fullWidth
                  displayEmpty
                  value={values.garage_or_carport.garage_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={garage_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="Swimming Pool" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Year</CustomFormLabel>
                <CustomTextField
                  name="swimming_pool.year"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.swimming_pool.year}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Pool Type</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="swimming_pool.pool_type"
                  fullWidth
                  displayEmpty
                  value={values.swimming_pool.pool_type}
                  onChange={handleChange}
                  placeholder="Select"
                  options={pool_type.map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              {/* <Grid item xs={12} lg={3}>
              <CustomFormLabel>Pool Fenced?</CustomFormLabel>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox />
                  <Typography>Yes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Checkbox />
                  <Typography>No</Typography>
                </Box>
              </Stack>
            </Grid> */}
            </Grid>

            <LabelCard heading="Detached Outbuildings/Structures" />
            <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      No.
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Year
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Structure Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Exterior Wall Framing Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Heating Apparatus Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Fuel Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Total Area
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.detached_outbuilding_stucture?.map((item, index) => {
                  return (
                    <TableRow
                      key={item.key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell> {item.sr_no}</TableCell>
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="year"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.year}
                          onChange={handleChangeDetachedOutBuildingsStructures(
                            "year",
                            index
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          variant="outlined"
                          size="small"
                          type="string"
                          name="structure_type"
                          fullWidth
                          displayEmpty
                          value={item.structure_type}
                          onChange={handleSelectDetachedOutBuildingsStructures(
                            "structure_type",
                            index
                          )}
                          placeholder="Select"
                          options={detached_structure_type.map((business) => {
                            return { label: business, value: business };
                          })}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          variant="outlined"
                          size="small"
                          type="string"
                          name="exterior_wall_framing_type"
                          fullWidth
                          displayEmpty
                          value={item.exterior_wall_framing_type}
                          onChange={handleSelectDetachedOutBuildingsStructures(
                            "exterior_wall_framing_type",
                            index
                          )}
                          placeholder="Select"
                          options={exterior_wall_framing_type.map(
                            (business) => {
                              return { label: business, value: business };
                            }
                          )}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          variant="outlined"
                          size="small"
                          type="string"
                          name="heating_apparatus_type"
                          fullWidth
                          displayEmpty
                          value={item.heating_apparatus_type}
                          onChange={handleSelectDetachedOutBuildingsStructures(
                            "heating_apparatus_type",
                            index
                          )}
                          placeholder="Select"
                          options={apparatus.map((business) => {
                            return { label: business, value: business };
                          })}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          variant="outlined"
                          size="small"
                          type="string"
                          name="fuel_type"
                          fullWidth
                          displayEmpty
                          value={item.fuel_type}
                          onChange={handleSelectDetachedOutBuildingsStructures(
                            "fuel_type",
                            index
                          )}
                          placeholder="Select"
                          options={fuel.map((business) => {
                            return { label: business, value: business };
                          })}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Stack direction="row" spacing={0}>
                          <CustomTextField
                            name="total_area"
                            variant="outlined"
                            size="small"
                            type="text"
                            sx={{
                              width: "40%",
                            }}
                            // fullWidth
                            value={item.total_area}
                            onChange={handleChangeDetachedOutBuildingsStructures(
                              "total_area",
                              index
                            )}
                          />
                          <Stack
                            direction="row"
                            spacing={0}
                            alignItems="center"
                          >
                            <Checkbox
                              // name="total_area_unit"
                              checked={item.total_area === "sqft"}
                              onChange={handleCheckboxDetachedOutBuildingsStructures(
                                "total_area_type",
                                index,
                                "sqft"
                              )}
                            />
                            <Typography>Sq. ft</Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={0}
                            alignItems="center"
                          >
                            <Checkbox
                              // name="total_area_unit"
                              checked={item.total_area === "m2"}
                              onChange={handleCheckboxDetachedOutBuildingsStructures(
                                "total_area_type",
                                index,
                                "m2"
                              )}
                            />
                            <Typography>m^2</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="value"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.value}
                          onChange={handleChangeDetachedOutBuildingsStructures(
                            "value",
                            index
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <LabelCard heading="Liability Exposure" />
            {/* <Typography sx={{ mb: 3, mt: 1 }} variant="h5" fontWeight={500}>
          All YES may require liability extension coverage or remarks explaning
          coverage declined.
        </Typography> */}
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  01. Daycare operation - Number of Children?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.daycare_operation_number_of_children"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures
                      ?.daycare_operation_number_of_children
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>02. Do you own a Trampoline?</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.do_you_own_a_trampoline.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures.do_you_own_a_trampoline.yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  03. Do you have a garden Tractor?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.do_you_have_a_garden_tractor.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures.do_you_have_a_garden_tractor
                      .yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>04. Do you have a Golf Cart?</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.do_you_have_a_golf_cart.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures.do_you_have_a_golf_cart.yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  05. Number of Saddle/draft Animals?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.no_of_saddle_draft_animals"
                  fullWidth
                  displayEmpty
                  value={values.liability_exposures.no_of_saddle_draft_animals}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  06. Do you own any Unlicensed recreational vehicles?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.do_you_own_any_unlicensed_recreational_vehicles.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures
                      .do_you_own_any_unlicensed_recreational_vehicles.yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  07. Do you own any Watercrafts?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.do_you_own_any_watercrafts.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures.do_you_own_any_watercrafts
                      .yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  08. Is there a Co-Occupant that requires coverage?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.is_there_a_co_occupant_that_requires_coverage"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures
                      .is_there_a_co_occupant_that_requires_coverage
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>09. Co-Occupant Name</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.co_occupant_name"
                  fullWidth
                  displayEmpty
                  value={values.liability_exposures.co_occupant_name}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  10. Is there any kind of Business Operation?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.is_there_any_kind_of_business_operation.yesNoCd"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures
                      .is_there_any_kind_of_business_operation.yesNoCd
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  11. If Yes, Describe Business?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.if_yes_describe_business"
                  fullWidth
                  displayEmpty
                  value={values.liability_exposures.if_yes_describe_business}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  12. Number of Cannabis Plants grown on Premises?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.number_of_cannabis_plants_grown_on_premises"
                  fullWidth
                  displayEmpty
                  value={
                    values.liability_exposures
                      .number_of_cannabis_plants_grown_on_premises
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>13. Other Exposures</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="liability_exposures.other_exposures"
                  fullWidth
                  displayEmpty
                  value={values.liability_exposures.other_exposures}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  14. Do you own/rent more than one location?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.do_you_own_rent_more_than_one_location"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .do_you_own_rent_more_than_one_location
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  15. Number of Weeks location rented to others?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.number_of_weeks_location_rented_to_others"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .number_of_weeks_location_rented_to_others
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  16. Number of Rooms rented to others?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.number_of_rooms_rented_to_others"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .number_of_rooms_rented_to_others
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  17. Renewable Energy Installation on Premises?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.renewable_energy_installation_on_premises"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .renewable_energy_installation_on_premises
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  18. Number of Full Residence Employees
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.number_of_full_residence_employees"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .number_of_full_residence_employees
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  19. Number of Dogs in the Household?
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.number_of_dogs_in_the_household"
                  fullWidth
                  displayEmpty
                  value={
                    values.risk_location_questions
                      .number_of_dogs_in_the_household
                  }
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel> 20. Breed of Dogs</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.breed_of_dogs"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_questions.breed_of_dogs}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>
                  21. Total Property Area (if greater than 1 acre)
                </CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_location_questions.total_property_area"
                  fullWidth
                  displayEmpty
                  value={values.risk_location_questions.total_property_area}
                  onChange={handleChange}
                  placeholder="Select"
                  options={["YES", "NO"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
            {!staticStatusValue && (
              <Grid container>
                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{ mt: 2, gap: 2, display: "flex" }}>
              <RoleBasedCustomButton
                type="submit"
                moduleKey={MODULE_IDS.HABITATIONAL}
                disabled={saveLoading}
                variant="contained"
              >
                Save
              </RoleBasedCustomButton>
              {/* <RoleBasedCustomButton
             moduleId={MODULE_IDS.HABITATIONAL}
              type="submit"
              // disabled={loading}
              variant="contained"
            >
              Save & Next
            </RoleBasedCustomButton> */}
            </Box>
          </FieldSet>{" "}
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
