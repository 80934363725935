import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { formatDate, formatDateWithTime, getUniqueId } from "../../../helpers";
import { STANDARD_APP_DATE_FORMAT } from "../../../constants/constants";
import { IHabitationalRiskLocation } from "../habitationalRiskLocation/habitationalRiskLocation.types";
import { IHabitationalInterested } from "../habitationalInterestedParty/habitationalInterested.types";
import { IHabitationalCoverages } from "../habitationalCoverages/habitationalCoverages.types";
import { IHabitationalScheduleDetail } from "../habitationalPropertySchedule/habitationalPropertySchedule.types";

export interface IHabitationalPolicyChangeState {
  list: IHabitationalPolicyChange[];
  policyChangeData: IHabitationalPolicyChange;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export interface IHabitationalLossPayee {
  key: string;
  loc_no: number | null;
  name: string | null;
  interested_party_code: string | null;
  address: string;
  nature_of_interest: string;
}

export interface IHabitationalSchedulePersonal {
  key: string;
  action: string;
  hash: number;
  class_code: string;
  description: string;
  coverage_code: string;
  type_of_one: string;
  type_of_two: string;
  purchase: string;
  appraisal_date: string;
  deductible: string;
  disc_applicable: string;
  amount_of_insurance: string;
}
export interface IHabitationalWaterCraftTrailerOne {
  key: string;
  action: string;
  year: string;
  description_make_model: string;
  coverage: string;
  deductible: string;
  identification_serial_number: string;
}
export interface IHabitationalWaterCraftTrailerTwo {
  key: string;
  action: string;
  type: string;
  style: string;
  construction: string;
  length: string;
  horse_power: string;
  purchase_date: string;
  new_or_used: string;
  purchase_price: string;
  replacement_cost: string;
  value_contents_purchase_price: string;
  value_of_non_standard: string;
  maximum_speed: string;
}

export interface IHabitationalAttachment {
  key: string;
  attachments: string | null;
  remarks: string;
  yes_no: string;
  code: string;
  isTypeOther: boolean;
}

export interface IHabitationalPolicyChange {
  incremental_number: number | null;
  customer_id: number | null;
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  policy_type: string | null;
  branch_code: string | null;
  producer_one_code: string | null;
  policy_status: string | null;
  policy_number: string | null;
  insurer: string | null;
  policy_effective_date: string | null;
  effective_time: string | null;
  expiry_date: string | null;
  type: string | null;
  sub_type: string | null;
  // date_change: string | null;
  // time_change: string | null;
  date_created: string | null;
  effective_date: string | null;
  days_left: string | null;
  due_date: string | null;
  date_completed: string | null;
  assigned_to_id: string | null;
  current_policy_status: string | null;
  credit_card_info: {
    cc: number | null;
    expiry: string | null;
    cvv: number | null;
    account_holder_name: string | null;
    bank_name: string | null;
    institution_number: string | null;
    transit_number: string | null;
    account_number: string | null;
  };
  name_change: {
    new_name: string | null;
  };
  address_change: {
    address_type: string | null;
    address: string | null;
    address_two: string | null;
    city: string | null;
    state: string | null;
    postal_code: string | null;
    country: string | null;
  };
  risk_location_change: {
    type: string | null;
    delete_risk_location: string | null;
    records: IHabitationalRiskLocation[];
  };
  interested_party_change: {
    type: string | null;
    delete_interested_party: string | null;
    records: IHabitationalInterested[]; //JSON
  };
  coverage_change: {
    type: string | null;
    delete_coverage: string | null;
    records: IHabitationalCoverages[]; //JSON
  };
  schedule_change: {
    type: string | null;
    delete_schedule: string | null;
    records: IHabitationalScheduleDetail[]; //JSON
  };

  attachments: {
    key: string;
    description: string | null;
    status: string | null;
    url: string | null;
    file: File | null;
  }[];

  remarks: string | null;
  status: string;
}

export const initialHabitationalPolicyChangeReqState: IHabitationalPolicyChangeState =
  {
    policyChangeData: {
      incremental_number: null,
      customer_id: null,
      customer_policy_id: null,
      customer_name: null,
      customer_code: null,
      policy_type: null,
      branch_code: null,
      producer_one_code: null,
      policy_status: null,
      insurer: null,
      policy_number: null,
      policy_effective_date: null,
      effective_time: null,
      expiry_date: moment().hours(0).minutes(1).format(),
      type: null,
      sub_type: null,
      // date_change: moment().format(STANDARD_APP_DATE_FORMAT),
      // time_change: moment().format(),
      date_created: moment().format(STANDARD_APP_DATE_FORMAT),
      effective_date: formatDateWithTime(moment().format()),
      days_left: null,
      due_date: moment().add(5, "days").format(STANDARD_APP_DATE_FORMAT),
      date_completed: null,
      assigned_to_id: null,
      credit_card_info: {
        cc: null,
        expiry: null,
        cvv: null,
        account_holder_name: null,
        bank_name: null,
        institution_number: null,
        transit_number: null,
        account_number: null,
      },
      name_change: {
        new_name: null,
      },
      address_change: {
        address_type: null,
        address: null,
        address_two: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
      },
      risk_location_change: {
        type: null,
        delete_risk_location: null,
        records: [],
      },
      interested_party_change: {
        type: null,
        delete_interested_party: null,
        records: [],
      },
      coverage_change: {
        type: null,
        delete_coverage: null,
        records: [],
      },
      schedule_change: {
        type: null,
        delete_schedule: null,
        records: [],
      },

      attachments: [
        {
          key: getUniqueId(),
          description: null,
          status: "Pending",
          url: null,
          file: null,
        },
      ],

      remarks: null,
      status: "Active",
      current_policy_status: null
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
