import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearAutomobileScheduleState, fetchAutomobileScheduleAListAsync } from "../../../../../../redux/automobileSchedule/automobileScheduleActions";

export const useAutomobileCoverageInfo = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const {listData} = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.schedule.scheduleA
  );

  const coverageDropdown = React.useMemo(() => {
    return listData.map((item) => {
      return {
        label: `${item.incremental_number}`,
        value: item.incremental_number || "",
      };
    });
  }, [listData]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileScheduleAListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, [customerPolicyId]);

  return { coverageDropdown };
};
