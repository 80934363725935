/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Grid, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import {
  IAutomobileInterestedParty,
  INITIAL_INTERESTED_STATE,
} from "../../../../../../../redux/automobileVehicle/automobileVehicle.types";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LocationAutoComplete } from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { useFormik } from "formik";
import { fetchAutomobileIntrestedPartieListAsync } from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { AutomobileInterested } from "./AutomobileInterested";
import { renderDropdownValue } from "../../../../../../../helpers";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import { clearAutomobileDriverState } from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";

export const AutomobileVehicleInterestedList: React.FC<{
  customerId: number;
  customerPolicyId: number;
  vehicleNumber: number;
}> = (props) => {
  const { customerPolicyId, vehicleNumber } = props;

  const { interestedPartyList, interestedPartyListLoading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle.interestedParty
  );

  const [open, setOpen] = React.useState<IAutomobileInterestedParty | null>(
    null
  );
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );
  const dispatch = useDispatchWrapper();

  const { interestedParty } = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle
  );
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: interestedParty.data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        // dispatch(
        //   addAutomobileInterestedAsync(
        //     { ...values, customer_policy_id: customerId, number: customerId },
        //     () => {}
        //   )
        // );
      },
    });

  const handleComplete = () => {
    dispatch(
      fetchAutomobileIntrestedPartieListAsync(
        customerPolicyId,
        status,
        vehicleNumber
      )
    );
    setOpen(null);
  };

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchAutomobileIntrestedPartieListAsync(
          customerPolicyId,
          status,
          vehicleNumber
        )
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearAutomobileDriverState());
    };
  }, [customerPolicyId, status]);

  const automobileInterestedTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: interestedPartyListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileInterestedParty) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                setOpen(row);
              }}
            />
          );
        },
      },
      {
        key: "number",
        headerName: "#",
        fieldName: "number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: IAutomobileInterestedParty) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(row);
              }}
            >
              {row.number}
            </Link>
          );
        },
      },

      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
        onRowCellRender: (value, row: IAutomobileInterestedParty) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(row);
              }}
            >
              {row.name}
            </Link>
          );
        },
      },

      {
        key: "interested_party_code",
        headerName: "Interested Party Code",
        fieldName: "interested_party_code",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 30,
      },
      {
        key: "Interest",
        headerName: "Interest",
        fieldName: "interest",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
      },

      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
    ],
    rows: interestedPartyList,
  };

  // React.useEffect(() => {
  //   dispatch(
  //     fetchAutomobileIntrestedPartieListAsync(
  //       customerPolicyId,
  //       status,
  //       vehicleNumber
  //     )
  //   );
  // }, [customerPolicyId, status]);

  return (
    <>
      {/* <Box sx={{ mt: 2 }}>
        <DataTable {...automobileInterestedTableProps} />
      </Box> */}
      <PageTitleBar
        sx={{ mt: 2 }}
        heading=""
        rightHeading={
          <Button
            variant="contained"
            startIcon={<AddOutlined />}
            onClick={() => {
              setOpen({
                ...INITIAL_INTERESTED_STATE,
                vehicle_id: vehicleNumber,
              });
            }}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...automobileInterestedTableProps} />
      {open && (
        <AutomobileInterested
          open={true}
          customerPolicyId={customerPolicyId}
          data={open}
          onClose={() => setOpen(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
