import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { Box, Button, Chip, MenuItem, Typography } from "@mui/material";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import {
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { fetchRequestFormListAsync } from "../../../redux/allApplications/allApplicationsActions";
import {
  IAllApplication,
  IHrRequestFormDialog,
} from "../../../redux/allApplications/allApplications.types";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RoleBasedEmailRightPanel } from "../../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_STATE_REQUEST_FORM } from "./AddRequestFormDialog/AddRequestFormDialog.types";
import { RequestFormDialog } from "./AddRequestFormDialog/AddRequestFormDialog";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import { TABLE_CACHING_KEYS } from "../../../constants/constants";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";

export const RequestFormsList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "request-forms",
    },
  ];
  const [openView, setOpenView] = React.useState<IAllApplication | null>(null);

  const { requestFormList, rquestFormLoading } = useSelector(
    (storeState: IStoreState) => storeState.allApplications
  );
  const [selectedApplications, setSelectedApplications] = React.useState<{
    isAllSelected: boolean;
    applicaion: IHrRequestFormDialog[];
  }>({
    isAllSelected: false,
    applicaion: [],
  });

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    state: {
      dateState,
      searchState,
      tabs,
      columnsConfig,
      pagination,
      masterColumnResize,
    },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.REQUEST_FORMS,
    },
  });

  const fetchList = async () => {
    dispatch(
      fetchRequestFormListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const menuItems =
    selectedApplications.applicaion.length > 0 ||
    selectedApplications.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose Bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: rquestFormLoading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["branch_name", "first_name"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        isFirstColumnSticky: true,
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          return (
            <StandardTableActions
              onViewClick={() => setOpenView(null)}
              onEditClick={() => {
                setOpenViewForm(row);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "branch_name",
        headerName: "Branch",
        fieldName: "branch_name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return (
            <Chip
              color="warning"
              sx={{ color: "#000" }}
              label={row.branch_name}
            ></Chip>
          );
        },
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IAllApplication) => {
          return (
            <CustomLink
              // to={"/applications/manage/" + row.application_number}
              to={"/on-boarding/" + row.application_number}
              label={row.first_name + " " + row.last_name}
            />
          );
        },
      },
      {
        key: "mobile",
        headerName: "Mobile",
        fieldName: "mobile",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          if (row.phone) {
            return (
              <RoleBasedMessages
                phone={row.phone}
                moduleKey={MODULE_IDS.HR_REQUEST_FORMS as string}
              />
            );
          }
          return "--";
        },
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          if (!row.email || row.email === "null") {
            return <Typography>--</Typography>;
          }
          return (
            <RoleBasedEmailRightPanel
              moduleKey={MODULE_IDS.HR_REQUEST_FORMS as string}
              displayLabel={row.email}
              toEmail={row.email}
            />
          );
        },
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
        // exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "create_ts",
        headerName: "Date Created",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
        // exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    rows: requestFormList,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: IHrRequestFormDialog[]) => {
      setSelectedApplications({
        isAllSelected: isAllSelected,
        applicaion: rows,
      });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns,
    },
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Active", value: "ACTIVE", variant: "success" },
        { label: "Inactive", value: "INACTIVE", variant: "error" },
        { label: "Completed", value: "Completed", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_hr_user_request",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [openViewForm, setOpenViewForm] =
    React.useState<IHrRequestFormDialog | null>(null);

  const handleCreate = () => {
    setOpenForm(true);
    setOpenViewForm(INITIAL_STATE_REQUEST_FORM);
  };

  const handleComplete = () => {
    setOpenViewForm(null);
    fetchList()
  }

  return (
    <PageContainer
      title="Request Forms"
      description="this is all request form list"
    >
      <Breadcrumb title="" items={BCrumb} />
      <PageTitleBar
        heading="Request Forms"
        rightHeading={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleCreate}
          >
            Create
          </Button>
        }
      />

      <DataTableV2 {...opportunitesTableProps} />
      {openViewForm && (
        <RequestFormDialog
          open={true}
          onClose={() => setOpenViewForm(null)}
          data={openViewForm}
          onComplete={handleComplete}
        />
      )}
    </PageContainer>
  );
};
