/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  USER_ROLES,
} from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import { Box, Button, Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import {
  addBusinesPolicyRiskLocationAsync,
  clearBusinessPolicyCRisk,
  fetchBusinessPolicyRiskAsync,
} from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { UsersAutoSearchByRole } from "../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import {
  COMMON_STATUS,
  Protection_One_Two_Three_and_Four,
} from "../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import moment from "moment";
import { ExtendableSelect } from "../../../../../../components/formsComponents/ExtendableSelect";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IRiskLocationFormProps {
  customerId?: number;
  customerPolicyId?: number;
  riskLocationNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (riskLocation: IBusinessPolicyRiskLocation) => void;
  initialData?: IBusinessPolicyRiskLocation;
  disableApiCalls?: boolean;
}

export const RiskLocation: React.FC<IRiskLocationFormProps> = (props) => {
  const {
    customerId,
    customerPolicyId,
    onSaveSuccess,
    fromRightPanel = false,
    created_from,
    readOnly = false,
    riskLocationNumber,
    staticStatusValue,
    initialData,
    disableApiCalls = false,
  } = props;

  const {
    loading,
    risklocation,
    error: notFoundError,
  } = useSelector(
    (storeState: IStoreState) => storeState.business.riskLocation
  );

  const data = useSelector(
    (storeState: IStoreState) =>
      storeState.business.basicDetails.customerInfo.data
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData ? initialData : risklocation,
    validate: (values) => {},
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          setSaveLoading(true);
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          dispatch(
            addBusinesPolicyRiskLocationAsync(
              {
                ...values,
                customer_id: customerId,
                customer_policy_id: customerPolicyId,
                status: finalStatus,
                created_from: created_from || "Risk_Location",
              },
              (isSuccess, data) => {
                if (isSuccess && data) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(data);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  React.useEffect(() => {
    if (customerPolicyId && riskLocationNumber) {
      dispatch(
        fetchBusinessPolicyRiskAsync(customerPolicyId, riskLocationNumber)
      );
    }
  }, [customerPolicyId, riskLocationNumber]);

  React.useEffect(() => {
    setValues({ ...risklocation, location_number: riskLocationNumber || null });
  }, [risklocation]);

  React.useEffect(() => {
    setValues({
      ...risklocation,
      address: data.address,
      unit_or_suite: data.unit_or_suite,
      city: data.city,
      country: data.country,
      province_or_state: data.province_or_state,
      postal_code: data.postal_code,
    });
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyCRisk());
    };
  }, []);

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={notFoundError ? { message: notFoundError } : null}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Risk Location" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>#</CustomFormLabel>
                <CustomTextField
                  name="location_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.location_number}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Address</CustomFormLabel>
                <LocationAutoComplete
                  id="address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.address}
                  onLocationChange={handleAddress}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>Unit/Suite</CustomFormLabel>
                <CustomTextField
                  id="unit_or_suite"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.unit_or_suite}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={1}>
                <CustomFormLabel>City</CustomFormLabel>
                <CustomTextField
                  id="city"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1.5}>
                <CustomFormLabel>State/Province</CustomFormLabel>
                <CustomTextField
                  id="province_or_state"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.province_or_state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>Postal Code</CustomFormLabel>
                <CustomTextField
                  id="postal_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.postal_code}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <CustomFormLabel>Country</CustomFormLabel>
                <CustomTextField
                  id="country"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Building Name</CustomFormLabel>
                <CustomTextField
                  name="building_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.building_name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <LabelCard heading="CONSTRUCTION" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Year Built</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.year_built || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue("year_built", momentObject ? newDate : null);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Number of Stories</CustomFormLabel>
                <CustomTextField
                  name="number_of_stories"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.number_of_stories}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Total Building Area(Sq. Ft.)</CustomFormLabel>
                <CustomTextField
                  name="total_building_area"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.total_building_area}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Area Occupied(Sq. Ft.)</CustomFormLabel>
                <CustomTextField
                  name="area_occupied"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.area_occupied}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Risk Inspected</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="risk_inspected"
                  fullWidth
                  displayEmpty
                  value={values.risk_inspected}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={["Y", "N"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Date Inspected</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.date_inspected || ""}
                  onChange={(date) => {
                    setFieldValue("date_inspected", date);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Walls</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.walls}
                  options={[
                    "Concrete panels on street structure",
                    "Fire resistive",
                    "Frame and all other",
                    "Frame metal clad",
                    "Frame w/brick veneer",
                    "HCB- Hollow Concrete Block",
                    "Masonry",
                    "Masonry veneer",
                    "Metallic panels on steel structure",
                    "Non-combustible with masonry walls",
                    "Non-combustible with non-masonry walls",
                    "Not applicable",
                    "Other",
                    "Poured Consrete",
                    "Solid Brick",
                    "Solid Brick 2",
                    "Wood",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("walls", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Floors</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.floors}
                  options={[
                    "Brick or stone",
                    "Concrete panels on street structure",
                    "Frame and all other",
                    "Heavy Beam or Mill",
                    "Masonry",
                    "Masonry on wood strucure or other combustible material",
                    "Non-combustible with masonry",
                    "Non-combustible without masonry",
                    "Pored concrete or fire resistive material",
                    "Steel",
                    "Wood",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("floors", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Roof</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.roof}
                  options={[
                    "Frame on steel joists",
                    "Frame on wood joists",
                    "Heavy beam or 'mill'",
                    "Not applicable",
                    "Poured concrete",
                    "Steel Beam",
                    "Steel Deck",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("roof", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Roof Coverings</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.roof_coverings}
                  options={[
                    "Asphalt Shingles",
                    "Concrete on steel structure",
                    "Concrete Tiles",
                    "Glass dome/skylight",
                    "Glass/poly on metal",
                    "Metal",
                    "Other(describe)",
                    "Plastic",
                    "Rubber/Polymer",
                    "Slate",
                    "Steel Deck",
                    "Tar and gravel",
                    "Tar paper",
                    "Tile",
                    "Wood shakes",
                    "Wood shingles",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("roof_coverings", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Electrical</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.electrical}
                  options={[
                    "Breakers",
                    "Fuses",
                    "Breakers & Fuses",
                    "Other",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("electrical", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Heating</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.heating}
                  options={[
                    "20 watt electric furnace(i.e. Kalor)",
                    "220 watt electric furnace(i.e. Kalor)",
                    "ACORN Stove,Franklin or Potbelly Stove(loose fitting or no)",
                    "Bi-",
                    "Boiler",
                    "Combined, primary outside ris unit & inside auxiliary",
                    "Cook Stove",
                    "Electric",
                    "Fireplace insert",
                    "Furnace(central)",
                    "Furnace(central) with add-on wood burning unit",
                    "Geothermal",
                    "Heat pump",
                    "Magazine type coal burning stove",
                    "Masonry fireplace",
                    "None",
                    "Other (describe)",
                    "Peller stove",
                    "Pipeless warm air furnace",
                    "Recessed heater",
                    "Roof mounted HVAC",
                    "Slow wood burning/air tight stove",
                    "Wood burning kicthen stove",
                    "Wood furnace",
                    "Wood burning unit-indoor",
                    "Wood stove,not airtight",
                    "Water heaters",
                    "Wood/oil",
                    "Wood furnace add on",
                    "Solid fuel heating unit-indoor",
                    "Solid fuel heating unit-outdoor",
                    "Space heater",
                    "Stove",
                    "Suspended heaters",
                    "Other",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("heating", newValue);
                  }}
                />
              </Grid>

              {values.heating === "Other" && (
                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Other</CustomFormLabel>
                  <CustomTextField
                    name="other"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.other}
                    onChange={handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Plumbing</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.plumbing}
                  options={[
                    "Copper",
                    "Copper/plastic PVC mix",
                    "Galvanized",
                    "Lead",
                    "Other(describe)",
                    "Plastic (PVS or CVS)",
                    "Stainless steel",
                    "Steel",
                    "Other",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("plumbing", newValue);
                  }}
                />
              </Grid>

              {values.plumbing === "Other" && (
                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Other</CustomFormLabel>
                  <CustomTextField
                    name="other"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.other}
                    onChange={handleChange}
                  />
                </Grid>
              )}

              {/* <Divider sx={{ my: 2 }} /> */}
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Basement</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="basement"
                  fullWidth
                  displayEmpty
                  value={values.basement}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={["Y", "N"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={4.8}>
                <CustomFormLabel>Use of Basement</CustomFormLabel>
                <CustomTextField
                  name="use_of_basement"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.use_of_basement}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <LabelCard heading="UPDATES" />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Roof Year</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.roof_year || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue("roof_year", momentObject ? newDate : null);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Roof Status</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="roof_year_status"
                  fullWidth
                  displayEmpty
                  value={values.roof_year_status}
                  onChange={handleChange}
                  placeholder={"Select one"}
                  options={["Partial", "Complete", "Not known", "None"].map(
                    (business) => {
                      return { label: business, value: business };
                    }
                  )}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Electrical Year</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.electrical_year || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue(
                      "electrical_year",
                      momentObject ? newDate : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Electrical Status</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="electrical_year_status"
                  fullWidth
                  displayEmpty
                  value={values.electrical_year_status}
                  onChange={handleChange}
                  placeholder={"Select one"}
                  options={["Partial", "Complete", "Not known", "None"].map(
                    (business) => {
                      return { label: business, value: business };
                    }
                  )}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Heating Year</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.heating_year || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue(
                      "heating_year",
                      momentObject ? newDate : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Heating Status</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="heating_year_status"
                  fullWidth
                  displayEmpty
                  value={values.heating_year_status}
                  onChange={handleChange}
                  placeholder={"Select one"}
                  options={["Partial", "Complete", "Not known", "None"].map(
                    (business) => {
                      return { label: business, value: business };
                    }
                  )}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Plumbing Year</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  views={["year"]}
                  inputFormat="YYYY"
                  value={values.plumbing_year || ""}
                  onChange={(newValue, x, momentObject) => {
                    const newDate = moment(momentObject).format("YYYY");
                    setFieldValue(
                      "plumbing_year",
                      momentObject ? newDate : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Plumbing Status</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="plumbing_year_status"
                  fullWidth
                  displayEmpty
                  value={values.plumbing_year_status}
                  onChange={handleChange}
                  placeholder={"Select one"}
                  options={["Partial", "Complete", "Not known", "None"].map(
                    (business) => {
                      return { label: business, value: business };
                    }
                  )}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="FIRE PROTECTION" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire Department</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.fire_department}
                  options={[
                    "Within 1km",
                    "Within 5km",
                    "Within 8km",
                    "Within 13km",
                    "Over 13km",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("fire_department", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire Hydrant</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.fire_hydrant}
                  options={[
                    "Within 150m",
                    "Within 300m",
                    "Over 300m",
                    "Unprotected",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("fire_hydrant", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire Extinguising System</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.fire_extinguising_system}
                  options={[
                    "None",
                    "Sprinkler",
                    "Portable Extinguisher(s)",
                    "Comb. Portable & Sprinkler",
                    "Other",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("fire_extinguising_system", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Extinguising Agent</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.extinguishing_agent}
                  options={[
                    "Carbon Dioxide",
                    "Dry Chemical",
                    "Foam",
                    "Halon",
                    "Water",
                    "Wet Chemical",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("extinguishing_agent", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Fire Alarm</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.fire_alarm}
                  options={[
                    "None",
                    "Central Station",
                    "Monitoring Station (Full service)",
                    "Monitoring Station (Shared service)",
                    "Other",
                    "Unknown",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("fire_alarm", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Sprinklered</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="sprinklered"
                  fullWidth
                  displayEmpty
                  value={values.sprinklered}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={["Y", "N"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>% Sprinklered</CustomFormLabel>
                <CustomTextField
                  name="percentage_sprinklered"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={values.percentage_sprinklered}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <LabelCard heading="CRIME PROTECTION" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Safe</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="safe"
                  fullWidth
                  displayEmpty
                  value={values.safe}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={["Y", "N"].map((business) => {
                    return { label: business, value: business };
                  })}
                ></ControlledCustomSelect>
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Safe Type</CustomFormLabel>
                <CustomTextField
                  name="safe_type"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.safe_type}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Safe Class</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.safe_class}
                  options={[
                    "None",
                    "Central Station",
                    "Monitoring Station (Full service)",
                    "Monitoring Station (Shared service)",
                    "Other",
                    "Unknown",
                  ].map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("safe_class", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Burglar Alarm</CustomFormLabel>
                <CustomTextField
                  name="burglar_alarm"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.burglar_alarm}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Protection One</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.protection_one}
                  options={Protection_One_Two_Three_and_Four.map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("protection_one", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Protection Two</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.protection_two}
                  options={Protection_One_Two_Three_and_Four.map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("protection_two", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Protection Three</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.protection_three}
                  options={Protection_One_Two_Three_and_Four.map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("protection_three", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Protection Four</CustomFormLabel>
                <ExtendableSelect
                  placeholder="Select one"
                  value={values.protection_four}
                  options={Protection_One_Two_Three_and_Four.map((business) => {
                    return { label: business, value: business };
                  })}
                  onChange={(newValue) => {
                    setFieldValue("protection_four", newValue);
                  }}
                />
              </Grid>
            </Grid>

            <LabelCard heading="EXPOSURE" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Left</CustomFormLabel>
                <CustomTextField
                  name="left_value"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.left_value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Right</CustomFormLabel>
                <CustomTextField
                  name="right_value"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.right_value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Front</CustomFormLabel>
                <CustomTextField
                  name="front"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.front}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Back</CustomFormLabel>
                <CustomTextField
                  name="back"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.back}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ mt: 4 }}>
              <RoleBasedCustomButton
                sx={{ width: "15%" }}
                disabled={saveLoading}
                variant="contained"
                moduleKey={MODULE_IDS.BUSINESS_POLICY}
                type="submit"
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
