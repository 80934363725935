import { LoadState } from "../../../constants/enums";

export interface IGeneralLedgerState {
  list: IGeneralLedger[];
  loading: LoadState;
}

export interface IGeneralLedger {
  code: string;
  apr_total: number;
  aug_total: number;
  dec_total: number;
  feb_total: string;
  jan_total: number;
  jul_total: number;
  jun_total: number;
  mar_total: number;
  may_total: number;
  nov_total: number;
  oct_total: number;
}

export const initialGeneralLedgerState: IGeneralLedgerState = {
  list: [],
  loading: LoadState.NotLoaded,
};
