import produce from "immer";
import {
  ADD_BRANCH_OFFICE,
  ADD_BRANCH_OFFICE_ID,
  ADD_BRANCH_OFFICE_PROGRESS,
  CLEAR_BRANCH_OFFICE_STATE,
  FETCH_BRANCH_OFFICE_LIST_FAILED,
  FETCH_BRANCH_OFFICE_LIST_PROGRESS,
  FETCH_BRANCH_OFFICE_LIST_SUCCESS,
  FETCH_BRANCH_OFFICE_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialBranchOfficeState } from "./branchOfficeState";
import { BRANCH_OFFICE_ERROR } from "./branchOfficeActions";
import { IBranchOfficeActions } from ".";

export const branchOfficeReducer = (
  state: IStoreState["borkerage"]["branchOffice"] = initialBranchOfficeState,
  action: IBranchOfficeActions
) => {
  switch (action.type) {
    case FETCH_BRANCH_OFFICE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.loading = LoadState.InProgress;
        draftState.branchOffice.list = [];
      });
      return newState;
    }
    case FETCH_BRANCH_OFFICE_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.loading = LoadState.Loaded;
        draftState.branchOffice.list = list;
      });
      return newState;
    }
    case FETCH_BRANCH_OFFICE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.loading = LoadState.InProgress;
        draftState.branchOffice.list = [];
      });
      return newState;
    }

    case ADD_BRANCH_OFFICE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.saveLoading = true;
      });
      return newState;
    }
    case ADD_BRANCH_OFFICE: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.loading = LoadState.Loaded;
        draftState.branchOffice.saveLoading = false;
        draftState.branchOffice.data = data;
      });
      return newState;
    }

    case FETCH_BRANCH_OFFICE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.loading = LoadState.InProgress;
      });
      return newState;
    }

    case BRANCH_OFFICE_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.branchOffice.error = action.payload.message;
        draftState.branchOffice.saveLoading = false;
        draftState.branchOffice.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_BRANCH_OFFICE_STATE: {
      return initialBranchOfficeState;
    }

    default: {
      return state;
    }
  }
};
