/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import {
  useBusinessCustomerBasicDetails,
  useBusinessPolicyAdmin,
  useCommAutovehcilesList,
  useCustomerActivePolicies,
  useDispatchWrapper,
} from "../../../../../../hooks";
import { useFormik } from "formik";

import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { Add, Remove } from "@mui/icons-material";
import { getFullAddres, getUniqueId } from "../../../../../../helpers";
import produce from "immer";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneTextField } from "../../../../../../components/PhoneTextField/PhoneTextField";
import { UsersAutoSearchByRole } from "../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useCurrentUserId } from "../../../../../../hooks/useAssignedTo";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import {
  addBusinessLiabilityAsync,
  clearBusinessPolicyFormsState,
  fetchBusinessLiabilityAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyForms/businessPolicyFormsActions";
import { CSIOAddMoreSection } from "./CSIOAddMoreSection";
import { CSIOOtherLiabilitySection } from "./CSIOOtherLiability";
import { IBusinessPolicyLiability } from "../../../../../../redux/businessPolicy/businessPolicyForms/businessPolicyForms.types";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface ICSIOCertificateProps {
  customerId?: number;
  customerPolicyId?: number;
  liabilityNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (data: IBusinessPolicyLiability) => void;
  isDuplicate?: boolean;
  initialData?: IBusinessPolicyLiability;
  disableApiCalls?: boolean;
}

export const CSIOCertificate: React.FC<ICSIOCertificateProps> = (props) => {
  const {
    customerId,
    customerPolicyId,
    liabilityNumber,
    staticStatusValue,
    onSaveSuccess,
    readOnly = false,
    fromRightPanel = false,
    created_from = "Liability_Certificate",
    initialData,
    disableApiCalls = false,
  } = props;
  const { isDuplicate } = props;

  const dispatch = useDispatchWrapper();

  const {
    data: businessPolicyLiability,
    loading: businessPolicyLiabilityLoading,
  } = useSelector(
    (storeState: IStoreState) => storeState.business.policyForms.liability
  );
  const { data: customerPolicies } = useCustomerActivePolicies(customerId);
  const { insurer, branch } = useBusinessPolicyAdmin(customerPolicyId);

  const customerInfo = useBusinessCustomerBasicDetails(customerPolicyId);

  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const currentUserId = useCurrentUserId();

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: businessPolicyLiability,
    validate: (values) => {
      const errors: any = {};
      if (!values.certificate_authorization_phone_number) {
        errors.certificate_authorization_phone_number =
          "*Phone number is required.";
      } else if (
        values.certificate_authorization_phone_number &&
        values.certificate_authorization_phone_number.length !== 10
      ) {
        errors.certificate_authorization_phone_number =
          "*Phone number should be 10 digits.";
      } else if (!values.certificate_authorization_email) {
        errors.certificate_authorization_email = "*Email is required.";
      } else if (
        values.certificate_authorization_email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.certificate_authorization_email
        )
      ) {
        errors.certificate_authorization_email = "*Invalid email address.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerId && customerPolicyId) {
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          dispatch(
            addBusinessLiabilityAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                status: finalStatus,
                created_from: created_from,
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                  navigate(
                    `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=0`
                  );
                }
                setSaveLoading(false);
              },
              isDuplicate
            )
          );
        }
      }
    },
  });

  const { vehicleDropdownlist } = useCommAutovehcilesList(
    false,
    values.automobile_policy || undefined
  );

  const handleCGLPolicy = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.cgl_policy_number = value;
      draftValues["commercial_general_liability_section_one"].policy_number =
        value;
      draftValues[
        "commercial_general_liability_section_one"
      ].insurance_company = insurer.name;
      draftValues["commercial_general_liability_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["commercial_general_liability_section_one"].expiry_date =
        customerInfo.data.expiry_date;
    });
    setValues(newValues);
  };

  const handleNonOwnedAutomobile = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.non_owned_automobiles_policy = value;
      draftValues["non_owned_automobiles_section_one"].policy_number = value;
      draftValues["non_owned_automobiles_section_one"].insurance_company =
        insurer.name;
      draftValues["non_owned_automobiles_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["non_owned_automobiles_section_one"].expiry_date =
        customerInfo.data.expiry_date;
    });
    setValues(newValues);
  };

  const handleHiredAutomobile = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.hired_automobiles = value;
      draftValues["hired_automobiles_section_one"].policy_number = value;
      draftValues["hired_automobiles_section_one"].insurance_company =
        insurer.name;
      draftValues["hired_automobiles_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["hired_automobiles_section_one"].expiry_date =
        customerInfo.data.expiry_date;
    });
    setValues(newValues);
  };

  const handleAutomobilemobile = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.automobile_policy = value;
      draftValues["automobile_liability_section_one"].policy_number = value;
      draftValues["automobile_liability_section_one"].insurance_company =
        insurer.name;
      draftValues["automobile_liability_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["automobile_liability_section_one"].expiry_date =
        customerInfo.data.expiry_date;
      draftValues["vehicle_unit_number_or_vin"] = null;
    });
    setValues(newValues);
  };

  const handleOtherPolicy = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.other_policy = value;
      draftValues["other_liability_section_one"].policy_number = value;
      draftValues["other_liability_section_one"].insurance_company =
        insurer.name;
      draftValues["other_liability_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["other_liability_section_one"].expiry_date =
        customerInfo.data.expiry_date;
    });
    setValues(newValues);
  };

  const handleExcessPolicy = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.umbrella_or_excess_liability_policy = value;
      draftValues["excess_liability_section_one"].policy_number = value;
      draftValues["excess_liability_section_one"].insurance_company =
        insurer.name;
      draftValues["excess_liability_section_one"].effective_date =
        customerInfo.data.effective_date;
      draftValues["excess_liability_section_one"].expiry_date =
        customerInfo.data.expiry_date;
    });
    setValues(newValues);
  };

  const handleAdd = () => {
    const finalLines = [...values.description_of_operations];
    finalLines.push({
      key: getUniqueId(),
      label: "Line " + (values.description_of_operations.length + 1),
      value: "",
    });
    setFieldValue("description_of_operations", finalLines);
  };

  const handleRemove = () => {
    const finalLines = [...values.description_of_operations];
    finalLines.pop();
    setFieldValue("description_of_operations", finalLines);
  };
  const handleDescriptionLine =
    (key: string, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const finalLines = produce(
        values.description_of_operations,
        (draftLines) => {
          draftLines[index]["value"] = value;
        }
      );

      setFieldValue("description_of_operations", finalLines);
    };

  const handleAdditionalNameInsured = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = checked ? "Y" : "N";
    if (value === "Y") {
      setValues({
        ...values,
        additional_name_insured_yes_or_no: value,
        additional_name_insured_name: values.certificate_holder_name,
        additional_name_insured_mailing_address:
          values.certificate_holder_address,
      });
    } else {
      setValues({
        ...values,
        additional_name_insured_yes_or_no: value,
      });
    }
  };
  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  React.useEffect(() => {
    if (customerPolicyId && liabilityNumber) {
      dispatch(fetchBusinessLiabilityAsync(customerPolicyId, liabilityNumber));
    }
  }, [customerPolicyId, liabilityNumber]);

  const handleUserSelect = (newValue: any) => {
    const { user_fact_id, branch_name, business_number, email } = newValue;

    setFieldValue("certificate_authorization_assigned_to", user_fact_id);
    setFieldValue("certificate_authorization_branch_name", branch_name || "");
    setFieldValue("certificate_authorization_phone_number", business_number);
    setFieldValue("certificate_authorization_email", email);
  };

  React.useEffect(() => {
    setValues({
      ...businessPolicyLiability,
      brokerage_full_name: values.brokerage_full_name || branch.name,
      brokerage_mailing_address:
        values.brokerage_mailing_address ||
        getFullAddres(
          branch.address,
          branch.unit_or_suite,
          branch.city,
          branch.province_or_state,
          branch.country,
          branch.postal_code
        ),
      brokerage_customer_code:
        values.brokerage_customer_code || customerInfo.data.customer_code,
      // certificate_authorization_branch_name: branch.name,
      // certificate_authorization_email:
      //   businessPolicyLiability.certificate_authorization_email ||
      //   branch.cl_email,
      // certificate_authorization_phone_number:
      //   businessPolicyLiability.certificate_authorization_phone_number ||
      //   branch.telephone,
      certificate_authorization_assigned_to:
        businessPolicyLiability.certificate_authorization_assigned_to ||
        currentUserId,
    });
  }, [businessPolicyLiability, branch]);

  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyFormsState());
    };
  }, []);

  return (
    <PageLoader
      loading={businessPolicyLiabilityLoading === LoadState.InProgress}
    >
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Certificate of Liability Insurance" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>CGL Policy</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.cgl_policy_number}
                  name="cgl_policy_number"
                  onChange={handleCGLPolicy}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Non-Owned Automobiles Policy</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.non_owned_automobiles_policy}
                  name="non_owned_automobiles_policy"
                  onChange={handleNonOwnedAutomobile}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Hired Automobiles</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.hired_automobiles}
                  name="hired_automobiles"
                  onChange={handleHiredAutomobile}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Automobile Policy</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.automobile_policy}
                  name="automobile_policy"
                  onChange={handleAutomobilemobile}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>
                  Umbrella/Excess Liability Policy
                </CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.umbrella_or_excess_liability_policy}
                  name="umbrella_or_excess_liability_policy"
                  onChange={handleExcessPolicy}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2}>
                <CustomFormLabel>Other Policy</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.other_policy}
                  name="other_policy"
                  onChange={handleOtherPolicy}
                  placeholder="Select one"
                  displayEmpty
                  options={customerPolicies.map((policy) => {
                    return {
                      label: policy.policy_number,
                      value: policy.policy_number || "",
                    };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="Select vehicle" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>Unit No/VIN</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="vehicle_unit_number_or_vin"
                  value={values.vehicle_unit_number_or_vin}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={vehicleDropdownlist}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <LabelCard heading="Certificate Holder" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={2}>
                <CustomFormLabel>Name</CustomFormLabel>
                <CustomTextField
                  name={"certificate_holder_name"}
                  value={values.certificate_holder_name}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomFormLabel>Full Address</CustomFormLabel>
                <CustomTextField
                  name="certificate_holder_address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.certificate_holder_address}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <Typography variant="h5" marginTop={2} fontWeight={600}>
                  Description of Operations/Locations/Automobiles/Special Items
                </Typography>
                <Box marginTop={2}>
                  <Stack spacing={1} direction={"row"} justifyContent={"end"}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleRemove}
                    >
                      <Remove fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdd}
                    >
                      <Add fontSize="small" />
                    </Button>
                  </Stack>
                  {(values.description_of_operations || []).map(
                    (line, index) => {
                      return (
                        <Box key={line.key} marginBottom={1}>
                          <Typography variant="body1">{line.label}</Typography>
                          <Box display={"flex"}>
                            <CustomTextField
                              name="country"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={line.value}
                              onChange={handleDescriptionLine(line.key, index)}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Grid>
            </Grid>

            <LabelCard heading="Commercial General Liability" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="commercial_general_liability_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.commercial_general_liability_section_one
                      .insurance_company
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="commercial_general_liability_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.commercial_general_liability_section_one
                      .policy_number
                  }
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="commercial_general_liability_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.commercial_general_liability_section_one
                      .effective_date
                  }
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="commercial_general_liability_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.commercial_general_liability_section_one.expiry_date
                  }
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={3}>
                    <Typography fontWeight={600} variant="h4">
                      Type of Insurance
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight={600} variant="h4">
                      Coverage
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight={600} variant="h4">
                      Deductible
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight={600} variant="h4">
                      Amount
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) => {
                        setValues({
                          ...values,
                          commercial_general_liability_section_two: {
                            ...values.commercial_general_liability_section_two,
                            claim_mode: checked ? "Y" : "N",
                            occurance: checked
                              ? "N"
                              : values.commercial_general_liability_section_two
                                  .occurance,
                          },
                        });
                      }}
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .claim_mode === "Y"
                          }
                        />
                      }
                      label="Claims Made OR"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      General Aggregate
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.claim_mode_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .claim_mode_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.claim_mode_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .claim_mode_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) => {
                        setValues({
                          ...values,
                          commercial_general_liability_section_two: {
                            ...values.commercial_general_liability_section_two,
                            occurance: checked ? "Y" : "N",
                            claim_mode: checked
                              ? "N"
                              : values.commercial_general_liability_section_two
                                  .claim_mode,
                          },
                        });
                      }}
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .occurance === "Y"
                          }
                        />
                      }
                      label="Occurance"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      Each Occurrence
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.occurance_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .occurance_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.occurance_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .occurance_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.products_completed_oprations",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .products_completed_oprations === "Y"
                          }
                        />
                      }
                      label="Products and/or completed operations"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      Products and Completed Operations Aggregate
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.products_completed_oprations_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .products_completed_oprations_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.products_completed_oprations_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .products_completed_oprations_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.empolyer_liability",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .empolyer_liability === "Y"
                          }
                        />
                      }
                      label="Empolyer Liability"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) => {
                        setValues({
                          ...values,
                          commercial_general_liability_section_two: {
                            ...values.commercial_general_liability_section_two,
                            empolyer_liability_peronal_injury: checked
                              ? "Y"
                              : "N",
                            waiver_of_subrogation_peronal_injury: checked
                              ? "N"
                              : values.commercial_general_liability_section_two
                                  .waiver_of_subrogation_peronal_injury,
                          },
                        });
                      }}
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .empolyer_liability_peronal_injury === "Y"
                          }
                        />
                      }
                      label="Personal Injury Liability"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.empolyer_liability_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .empolyer_liability_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.empolyer_liability_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .empolyer_liability_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.waiver_of_subrogation",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .waiver_of_subrogation === "Y"
                          }
                        />
                      }
                      label="Waiver of subrogation"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setValues({
                          ...values,
                          commercial_general_liability_section_two: {
                            ...values.commercial_general_liability_section_two,
                            waiver_of_subrogation_peronal_injury: checked
                              ? "Y"
                              : "N",
                            empolyer_liability_peronal_injury: checked
                              ? "N"
                              : values.commercial_general_liability_section_two
                                  .empolyer_liability_peronal_injury,
                          },
                        })
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .waiver_of_subrogation_peronal_injury === "Y"
                          }
                        />
                      }
                      label="Personal and Advertising Injury Liability"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.waiver_of_subrogation_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .waiver_of_subrogation_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.waiver_of_subrogation_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .waiver_of_subrogation_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.cross_liability",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .cross_liability === "Y"
                          }
                        />
                      }
                      label="Cross Liability"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      Medical Payments
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.cross_liability_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .cross_liability_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.cross_liability_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .cross_liability_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.tenants_Legal_Liability",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .tenants_Legal_Liability === "Y"
                          }
                        />
                      }
                      label="Tenants Legal Liability"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      Tenants Legal Liability
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.tenants_Legal_Liability_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .tenants_Legal_Liability_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.tenants_Legal_Liability_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .tenants_Legal_Liability_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="end"
                      onChange={(e, checked) =>
                        setFieldValue(
                          "commercial_general_liability_section_two.pollution_Liability_Extension",
                          checked ? "Y" : "N"
                        )
                      }
                      control={
                        <Checkbox
                          name="send_eDocs_via_email"
                          checked={
                            values.commercial_general_liability_section_two
                              .pollution_Liability_Extension === "Y"
                          }
                        />
                      }
                      label="Pollution Liability Extension"
                      sx={{ m: 0, fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontSize={15}>
                      Pollution Liability Extension
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.pollution_Liability_Extension_deductible"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .pollution_Liability_Extension_deductible
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      name="commercial_general_liability_section_two.pollution_Liability_Extension_amount"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={
                        values.commercial_general_liability_section_two
                          .pollution_Liability_Extension_amount
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h4" fontWeight={600}>
                    Add More
                  </Typography>
                  <Divider sx={{ width: "92%" }} />
                </Box>
                <CSIOAddMoreSection
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>

            <LabelCard heading="Non-Owned Automobiles" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="non_owned_automobiles_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.non_owned_automobiles_section_one.insurance_company
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="non_owned_automobiles_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.non_owned_automobiles_section_one.policy_number}
                  disabled
                  //  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="non_owned_automobiles_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.non_owned_automobiles_section_one.effective_date
                  }
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="non_owned_automobiles_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.non_owned_automobiles_section_one.expiry_date}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>

              <Grid container spacing={1} marginTop={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Type of Insurance
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Coverage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Deductible
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) => {
                      setFieldValue(
                        "non_owned_automobiles_section_two.non_owned",
                        checked ? "Y" : "N"
                      );
                    }}
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.non_owned_automobiles_section_two.non_owned ===
                          "Y"
                        }
                      />
                    }
                    label="Non-Owned Automobiles"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Non-Owned Automobiles</Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="non_owned_automobiles_section_two.non_owned_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.non_owned_automobiles_section_two
                        .non_owned_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="non_owned_automobiles_section_two.non_ownder_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.non_owned_automobiles_section_two.non_ownder_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <LabelCard heading="Hired Automobiles" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="hired_automobiles_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.hired_automobiles_section_one.insurance_company}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="hired_automobiles_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.hired_automobiles_section_one.policy_number}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="hired_automobiles_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.hired_automobiles_section_one.effective_date}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="hired_automobiles_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.hired_automobiles_section_one.expiry_date}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>

              <Grid container spacing={1} marginTop={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Type of Insurance
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Coverage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Deductible
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      setFieldValue(
                        "hired_automobiles_section_two.hired_type_of_insurance",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.hired_automobiles_section_two
                            .hired_type_of_insurance === "Y"
                        }
                      />
                    }
                    label="Hired Automobiles"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Hired Automobiles</Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="hired_automobiles_section_two.hired_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.hired_automobiles_section_two.hired_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="hired_automobiles_section_two.hired_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.hired_automobiles_section_two.hired_amount}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <LabelCard heading="Automobile Liability" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="automobile_liability_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={
                    values.automobile_liability_section_one.insurance_company
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="automobile_liability_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.automobile_liability_section_one.policy_number}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="automobile_liability_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.hired_automobiles_section_one.effective_date}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="automobile_liability_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.automobile_liability_section_one.expiry_date}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>

              <Grid container spacing={1} marginTop={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Type of Insurance
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Coverage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Deductible
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      setFieldValue(
                        "automobile_liability_section_two.described_automobiles",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.automobile_liability_section_two
                            .described_automobiles === "Y"
                        }
                      />
                    }
                    label="Described Automobiles"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    Bodily Injury and Property Damage Combined
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.described_automobiles_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .described_automobiles_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.described_automobiles_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .described_automobiles_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      setFieldValue(
                        "automobile_liability_section_two.all_owned_automobiles",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.automobile_liability_section_two
                            .all_owned_automobiles === "Y"
                        }
                      />
                    }
                    label="All Owned Automobiles"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    Bodily Injury (Per Person)
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.all_owned_automobiles_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .all_owned_automobiles_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.all_owned_automobiles_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .all_owned_automobiles_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      setFieldValue(
                        "automobile_liability_section_two.leased_automobiles",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.automobile_liability_section_two
                            .leased_automobiles === "Y"
                        }
                      />
                    }
                    label="Leased Automobiles"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    Bodily Injury (Per Accident)
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.leased_automobiles_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .leased_automobiles_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.leased_automobiles_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .leased_automobiles_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Property Damage</Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.property_damage_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .property_damage_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="automobile_liability_section_two.property_damage_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.automobile_liability_section_two
                        .property_damage_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <LabelCard heading="Excess Liability" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="excess_liability_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.excess_liability_section_one.insurance_company}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="excess_liability_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.excess_liability_section_one.policy_number}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="excess_liability_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.excess_liability_section_one.effective_date}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="excess_liability_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.excess_liability_section_one.expiry_date}
                  disabled
                />
              </Grid>

              <Grid container spacing={1} marginTop={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Type of Insurance
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Coverage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Deductible
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight={600}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    onChange={(e, checked) =>
                      setFieldValue(
                        "excess_liability_section_two.umbrella_form",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.excess_liability_section_two.umbrella_form ===
                          "Y"
                        }
                      />
                    }
                    label="Umbrella Form"
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    Each Occurrence Aggregate Coverage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="excess_liability_section_two.umbrella_form_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.excess_liability_section_two
                        .umbrella_form_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="excess_liability_section_two.umbrella_form_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.excess_liability_section_two.umbrella_form_amount
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Aggregate</Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="excess_liability_section_two.aggreate_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={
                      values.excess_liability_section_two.aggreate_deductible
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="excess_liability_section_two.aggreate_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.excess_liability_section_two.aggreate_amount}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      setFieldValue(
                        "excess_liability_section_two.extra",
                        checked ? "Y" : "N"
                      )
                    }
                    control={
                      <Checkbox
                        name="send_eDocs_via_email"
                        checked={
                          values.excess_liability_section_two.extra === "Y"
                        }
                      />
                    }
                    label={
                      <CustomTextField
                        name="excess_liability_section_two.extra_type_of_insurance"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={
                          values.excess_liability_section_two
                            .extra_type_of_insurance
                        }
                        onChange={handleChange}
                      />
                    }
                    sx={{ fontSize: 15 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    name="excess_liability_section_two.extra_coverage"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.excess_liability_section_two.extra_coverage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    id="excess_liability_section_two.extra_deductible"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.excess_liability_section_two.extra_deductible}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    id="excess_liability_section_two.extra_amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.excess_liability_section_two.extra_amount}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <LabelCard heading="Other Liability (Specify)" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Insurance Company</CustomFormLabel>
                <CustomTextField
                  name="other_liability_section_one.insurance_company"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.other_liability_section_one.insurance_company}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <CustomTextField
                  name="other_liability_section_one.policy_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.other_liability_section_one.policy_number}
                  disabled
                  //onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Effective Date</CustomFormLabel>
                <CustomTextField
                  name="other_liability_section_one.effective_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.other_liability_section_one.effective_date}
                  disabled
                  // onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Expiry Date</CustomFormLabel>
                <CustomTextField
                  name="other_liability_section_one.expiry_date"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.other_liability_section_one.expiry_date}
                  disabled
                  // onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} marginTop={2}>
                <CSIOOtherLiabilitySection
                  values={values.other_liability_section_two}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>

            <LabelCard heading="Cancellation" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>No. of Days</CustomFormLabel>
                <CustomTextField
                  name="cancelation_number_of_days"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.cancelation_number_of_days}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Brokerage/Agency Full Name and Mailing Address" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Brokerage/Agency Full name</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  value={values.brokerage_full_name}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomFormLabel>Mailing Address</CustomFormLabel>
                <CustomTextField
                  name="brokrage_mailing_address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.brokerage_mailing_address}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomFormLabel>Broker Client ID</CustomFormLabel>
                <CustomTextField
                  name="brokrage_customer_code"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.brokerage_customer_code}
                  disabled
                />
              </Grid>
            </Grid>
            <LabelCard heading="Additional Insured Name and Mailing Address" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={values.additional_name_insured_yes_or_no === "Y"}
                    onChange={handleAdditionalNameInsured}
                  />
                  <CustomFormLabel sx={{ mt: 2 }}>
                    Print Certificate Holder as Additional Named Insured (Y/N)
                  </CustomFormLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomFormLabel>Name</CustomFormLabel>
                <CustomTextField
                  name="additional_name_insured_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.additional_name_insured_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomFormLabel>Mailing Address</CustomFormLabel>
                <CustomTextField
                  name="additional_name_insured_mailing_address"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.additional_name_insured_mailing_address}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Certificate Authorization" />
            <Grid container spacing={1}>
              <Grid item xs={12} md={2}>
                <UsersAutoSearchByRole
                  label="Assigned To"
                  value={values.certificate_authorization_assigned_to}
                  onSelect={handleUserSelect}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomFormLabel>Branch Name</CustomFormLabel>
                <CustomTextField
                  name="certificate_authorization_branch_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.certificate_authorization_branch_name}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <CustomFormLabel>Phone (+1)</CustomFormLabel>
                <PhoneTextField
                  name="certificate_authorization_phone_number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={values.certificate_authorization_phone_number}
                  error={
                    errors.certificate_authorization_phone_number ? true : false
                  }
                  helperText={errors.certificate_authorization_phone_number}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomFormLabel>Fax</CustomFormLabel>
                <CustomTextField
                  name="certificate_authorization_fax_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.certificate_authorization_fax_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomFormLabel>Email</CustomFormLabel>
                <CustomTextField
                  name="certificate_authorization_email"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.certificate_authorization_email}
                  error={errors.certificate_authorization_email ? true : false}
                  helperText={errors.certificate_authorization_email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <CustomFormLabel>Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.certificate_authorization_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("certificate_authorization_date", newValue);
                  }}
                />
              </Grid>
            </Grid>
            <Box marginTop={3}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.BUSINESS_POLICY}
                type="submit"
                variant="contained"
                disabled={saveLoading}
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>{" "}
        </form>
      </StandadCard>
    </PageLoader>
  );
};
