import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AutomobileScheduleA } from "./AutomobileScheduleA";

export const AutomobileScheduleAPage = () => {
  const { customerId, customerPolicyId, incrementalNumber, vehicleNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    incrementalNumber?: number;
    vehicleNumber?: number;
  };

  const navigate = useNavigate();

  const handleSaveSuccses = () => {
    navigate(
        `/automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=0`
      );
  };

  return (
    <>
      <AutomobileScheduleA
        customerId={customerId}
        customerPolicyId={customerPolicyId}
        incrementalNumber={incrementalNumber}
        onSaveSuccess={handleSaveSuccses}
        vehicleNumber={vehicleNumber}
      />
    </>
  );
};
