import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialHabitationalClaimDetails } from "./habitationalClaim.types";
import { HabitationalClaimAction } from ".";
import {
  CLEAR_HABITATIONAL_CLAIM_STATE,
  FETCH_HABITATIONAL_CLAIM_FAILED,
  FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS,
  FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS,
  FETCH_HABITATIONAL_CLAIM_PROGRESS,
  FETCH_HABITATIONAL_CLAIM_SUCCESS,
} from "./habitationalClaimAction";

export const HabitationalClaimReducer = (
  state: IStoreState["habitational"]["claimDetails"] = initialHabitationalClaimDetails,
  action: HabitationalClaimAction
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_CLAIM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CLAIM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.claim = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CLAIM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_CLAIM_STATE: {
      return initialHabitationalClaimDetails;
    }

    default: {
      return state;
    }
  }
};
