import React from "react";
import { IBusinessPolicyLiability } from "../../../../../../redux/businessPolicy/businessPolicyForms/businessPolicyForms.types";
import { FormikErrors } from "formik";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomTextField } from "../../../../../../components/formsComponents";
import produce from "immer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { ICommAutomobileLiabilityInsurance } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificates.types";

interface ICommCSIOAddMoreSectionProps {
  values: ICommAutomobileLiabilityInsurance;
 
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<ICommAutomobileLiabilityInsurance>> | Promise<void>;
}

export const CommCSIOAddMoreSection: React.FC<ICommCSIOAddMoreSectionProps> = ({
  values,
  setFieldValue,
}) => {
  const handleCommericalAddMoreChange =
    (rowIndex: number) => (columnKey: string, value: any) => {
      const finalLines = produce(
        values.commercial_general_liability_section_three,
        (draftLines) => {
          draftLines[rowIndex][columnKey as "coverage"] = value;
        }
      );
      setFieldValue("commercial_general_liability_section_three", finalLines);
    };

  const handleCommericalAddMoreCheckBoxChange =
    (rowIndex: number) => (checked: boolean) => {
      const newValues = produce(
        values.commercial_general_liability_section_three,
        (draftvalues) => {
          draftvalues[rowIndex].type_of_insurance_check = checked ? "Y" : "N";
        }
      );

      setFieldValue("commercial_general_liability_section_three", newValues);
    };


  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Type of Insurance</TableCell>
              <TableCell>Coverage</TableCell>
              <TableCell>Deductible</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.commercial_general_liability_section_three.map(
              (item, index) => {
                return (
                  <Row
                    key={index}
                    row={item}
                    onCommericalAddMoreCheckBoxChange={handleCommericalAddMoreCheckBoxChange(
                      index
                    )}
                    onCommericalAddMoreChange={handleCommericalAddMoreChange(
                      index
                    )}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

const Row: React.FC<{
  row: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  };
  onCommericalAddMoreChange: (columnKey: string, value: any) => void;
  onCommericalAddMoreCheckBoxChange: (value: boolean) => void;
}> = ({
  row,
  onCommericalAddMoreChange,
  onCommericalAddMoreCheckBoxChange,
}) => {
  const handleCommericalAddMoreChange =
    (columnKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onCommericalAddMoreChange(columnKey, e.target.value);
    };

  const handleCommericalAddMoreCheckBoxChange = (e: any, checked: boolean) => {
    onCommericalAddMoreCheckBoxChange(checked);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCommericalAddMoreCheckBoxChange}
              control={
                <Checkbox
                  name="send_eDocs_via_email"
                  checked={row.type_of_insurance_check === "Y"}
                />
              }
              label={
                <CustomTextField
                  fullWidth
                  value={row.type_of_insurance}
                  onChange={handleCommericalAddMoreChange("type_of_insurance")}
                />
              }
              sx={{ fontSize: 15 }}
            />
          </Box>
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.coverage}
            onChange={handleCommericalAddMoreChange("coverage")}
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.deductible}
            onChange={handleCommericalAddMoreChange("deductible")}
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            fullWidth
            value={row.amount}
            onChange={handleCommericalAddMoreChange("amount")}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
