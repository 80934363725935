import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
	APP_FILE_IMPORTS_ROUTE,APP_EXTRACT_PRODUCST_WITH_API
} from "./ImportLineSheet.constants";

const ImportLineSheetPage = Loadable(
	lazy(() =>
		import("./ImportLineSheetPage").then(({ ImportLineSheetPage }) => ({
			default: ImportLineSheetPage,
		}))
	)
);

const ReconcilliationDetailsList = Loadable(
	lazy(() =>
		import("./ReconcilliationDetailsList").then(({ ReconcilliationDetailsList }) => ({
			default: ReconcilliationDetailsList,
		}))
	)
);

export const ReconciliationDetails = Loadable(
	lazy(()=>
		import("./ReconciliationDetails").then(({ ReconciliationDetails }) => ({
			default: ReconciliationDetails,
		}))
	)
)

const ExtractDataFromPdf = Loadable(
	lazy(() =>
		import("./ExtractDataFromPdf").then(
			({ ExtractDataFromPdf }) => ({
				default: ExtractDataFromPdf,
			})
		)
	)
);


export const fileUploadWithAIRouter = [
	{
		path: `${APP_FILE_IMPORTS_ROUTE}`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleKeys={[]}
			>
				<ImportLineSheetPage />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_FILE_IMPORTS_ROUTE}/get-reconcilliation-details/template/:templateCode/document/:documentCode`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleKeys={[]}
			>
				<ReconciliationDetails />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_FILE_IMPORTS_ROUTE}/list`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleKeys={[]}
			>
				<ReconcilliationDetailsList />
			</AuthorizedRoute>
		),
	},
	// {
	// 	path: `${APP_EXCEL_FILE_IMPORTS_ROUTE}`,
	// 	exact: true,
	// 	element: (
	// 		<AuthorizedRoute
	// 			allowFullAccess
	// 			moduleKeys={[]}
	// 		>
	// 			<ImportExcelLineSheet />
	// 		</AuthorizedRoute>
	// 	),
	// },
	// {
	// 	path: `${APP_PDF_FILE_IMPORTS_ROUTE}`,
	// 	exact: true,
	// 	element: (
	// 		<AuthorizedRoute
	// 			allowFullAccess
	// 			moduleKeys={[]}
	// 		>
	// 			<ManageSingleFileUploadWithAI />
	// 		</AuthorizedRoute>
	// 	),
	// },
	{
		path: `${APP_EXTRACT_PRODUCST_WITH_API}/document/:documentCode/proccess/:proccessCode`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleKeys={[]}
			>
				<ExtractDataFromPdf />
			</AuthorizedRoute>
		),
	},
];
