import { LoadState } from "../../constants/enums";
import { IUnderWriter } from "./underWriter.types";

export const initialUnderWriterState: IUnderWriter = {
    underwriter: {
        list: [],
        data: {
            "underwriters_primary_id": null,
            "insurer_code_id": null,
            "insurer_code": "",
            "name": "",
            "phone_number": "",
            "phone_ext": "",
            "mobile_number": "",
            "email": "",
            "department_email": "",
            "line_of_business": "",
            "status": "ACTIVE",
            department: null,
            position: null,
            type: null,
            website: null,
            "insert_ts": ""
        },
        loading: LoadState.NotLoaded,
        saveLoading: false,
        error: null,
    },

};
