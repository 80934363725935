import produce from "immer";
import {
  ADD_BROKER_CODE,
  ADD_BROKER_CODE_ID,
  ADD_BROKER_CODE_PROGRESS,
  BROKER_CODE_ERROR,
  CLEAR_BROKER_CODE_STATE,
  FETCH_BROKER_CODE_LIST_FAILED,
  FETCH_BROKER_CODE_LIST_PROGRESS,
  FETCH_BROKER_CODE_LIST_SUCCESS,
  FETCH_BROKER_CODE_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialBrokerCodeState } from "./brokerCodeState";
import { IBrokerCodeActions } from ".";

export const brokerCodeReducer = (
  state: IStoreState["borkerage"]["brokerCode"] = initialBrokerCodeState,
  action: IBrokerCodeActions
) => {
  switch (action.type) {
    case FETCH_BROKER_CODE_LIST_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.brokerCode.loading = LoadState.InProgress;
            draftState.brokerCode.list = [];
          });
          return newState;
        }
        case FETCH_BROKER_CODE_LIST_SUCCESS: {
          const { list } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.brokerCode.loading = LoadState.Loaded;
            draftState.brokerCode.list = list;
          });
          return newState;
        }
        case FETCH_BROKER_CODE_LIST_FAILED: {
          const newState = produce(state, (draftState) => {
            draftState.brokerCode.loading = LoadState.InProgress;
            draftState.brokerCode.list = [];
          });
          return newState;
        }

    case ADD_BROKER_CODE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.brokerCode.saveLoading = true;
      });
      return newState;
    }
    case ADD_BROKER_CODE: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.brokerCode.loading = LoadState.Loaded;
        draftState.brokerCode.saveLoading = false;
        draftState.brokerCode.data = data;
      });
      return newState;
    }

    case FETCH_BROKER_CODE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.brokerCode.loading = LoadState.InProgress;
      });
      return newState;
    }

    case BROKER_CODE_ERROR : {
      const newState = produce(state, (draftState) => {
        draftState.brokerCode.error = action.payload.message;
        draftState.brokerCode.saveLoading = false;
        draftState.brokerCode.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_BROKER_CODE_STATE: {
      return initialBrokerCodeState;
    }

    default: {
      return state;
    }
  }
};
