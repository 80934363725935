import React from "react";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  IBusinessPolicyCoverage,
  IBusinessPolicyCoverageState,
  ICoverageRow,
} from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import {
  Box,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../constants/enums";
import { ExtendableSelect } from "../../../../../../components/formsComponents/ExtendableSelect";

interface ICoveragePropertyTableProps {
  property: IBusinessPolicyCoverageState["coverage"]["property"];
  values: IBusinessPolicyCoverageState["coverage"];
  setFieldValue: any;
  onPropertyRowChange: (
    key: keyof ICoverageRow,
    rowIndex: number,
    value: string
  ) => void;
  onRemovePropertyRow: (index: number) => void;
  onAddNewProperty: (currentIndex: number) => void;
  changeTab: (value: number) => void;
}

export const CoveragePropertyTable: React.FC<ICoveragePropertyTableProps> = (
  props
) => {
  const {
    property,
    values,
    setFieldValue,
    onPropertyRowChange,
    onRemovePropertyRow,
    onAddNewProperty,
    changeTab,
  } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const handlePropertyRowChange =
    (key: keyof ICoverageRow, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onPropertyRowChange(key, rowIndex, event.target.value);
    };

  const handlePropertySelectChange =
    (key: keyof ICoverageRow, rowIndex: number) =>
    (event: SelectChangeEvent<any>) => {
      onPropertyRowChange(key, rowIndex, event.target.value);
    };

  const handleCurrencyRowChange =
    (key: keyof ICoverageRow, rowIndex: number) => (newValue: string) => {
      onPropertyRowChange(key, rowIndex, newValue);
    };

  const handleRemovePropertyRow = (index: number) => () => {
    onRemovePropertyRow(index);
  };

  const handleCoverageNewRow = (currentRowIndex: number) => () => {
    onAddNewProperty(currentRowIndex);
  };

  const coveragePropertyTableProps: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 10000,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "coverage",
        headerName: "Coverage",
        fieldName: "coverage",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return value.column.rowIndex === 0? (
            <ExtendableSelect
              placeholder="Select one"
              value={row.type}
              options={[
                "Property of Every Description",
                "Contents of Every Description",
              ].map((business) => {
                return { label: business, value: business };
              })}
              onChange={(newValue) => {
                handlePropertySelectChange(
                  "type",
                  value.column.rowIndex
                )({
                  target: {
                    value: newValue,
                  },
                } as any);
              }}
            />
          ) : (
            <CustomTextField
              fullWidth
              sx={{ minWidth: "350px" }}
              value={row.type}
              onChange={handlePropertyRowChange("type", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "deductible",
        headerName: "Deductible",
        fieldName: "deductible",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CustomTextField
              type="text"
              value={row.deductible}
              onChange={handlePropertyRowChange(
                "deductible",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "co_insurance",
        headerName: "Co-Insurance",
        fieldName: "co_insurance",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CustomTextField
              type="text"
              value={row.co_insurance}
              onChange={handlePropertyRowChange(
                "co_insurance",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "limit_amount",
        headerName: "Limit ($)",
        fieldName: "limit_amount",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limit}
              onChange={handleCurrencyRowChange("limit", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "rate",
        headerName: "Rate",
        fieldName: "rate",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.rate}
              onChange={handleCurrencyRowChange("rate", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "Premium",
        headerName: "Premium",
        fieldName: "premimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: ICoverageRow) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleCurrencyRowChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "delete",
        headerName: "",
        fieldName: "add",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <Box display={"flex"}>
              <IconButton
                color="error"
                onClick={handleRemovePropertyRow(value.column.rowIndex)}
              >
                <Remove fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleCoverageNewRow(value.column.rowIndex)}
              >
                <Add fontSize="small" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    items: property,
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={3}>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="risk_inspected"
            fullWidth
            displayEmpty
            sx={{m:3}}
            value={values.property_dropdown}
            onChange={(e) => setFieldValue("property_dropdown", e.target.value)}
            placeholder="Select One"
            options={[
              "Property of Every Description",
              "Contents of Every Description",
            ].map((business) => {
              return { label: business, value: business };
            })}
          ></ControlledCustomSelect>
          <Grid item xs={12} md={8}></Grid>
        </Grid> */}

        <Grid item xs={12} md={12}>
          <DataTable {...coveragePropertyTableProps} />
        </Grid>
      </Grid>

      <Stack direction={"row"} spacing={3} sx={{ mt: 4 }}>
        <RoleBasedCustomButton
          sx={{ width: "15%" }}
          moduleKey={MODULE_IDS.BUSINESS_POLICY}
          disabled={saveLoading}
          variant="contained"
          type="submit"
          // onClick={() => setButtonClicked("save")}
          // onClick={() => changeTab(1)}
        >
          Save
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          sx={{ width: "15%" }}
          moduleKey={MODULE_IDS.BUSINESS_POLICY}
          disabled={saveLoading}
          variant="contained"
          type="submit"
          // onClick={() => setButtonClicked("submit")}
          onClick={() => changeTab(1)}
        >
          Save & Next
        </RoleBasedCustomButton>
      </Stack>
    </>
  );
};
