import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES, LoadState } from "../../../constants/enums";
import moment from "moment";
import {
  ICommAutomobileAdditionalInsured,
  ICommAutomobileAttachment,
  ICommAutomobileCustomerInfo,
  ICommAutomobilePolicyAdmin,
  ICommAutomobilePolicyAdminManager,
  ICommAutomobilePremium,
  ICommAutomobilePriorPolicy,
  initialCommAutomobileDetails,
} from "./commAutoBasicDetails.types";
import { uploadFile } from "../../../helpers";
import { makeApiCall } from "../../../helpers/postRequest";

export const FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_FAILED =
  "FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_FAILED";

export const fetchCommAutomobileCustomerInfoProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_PROGRESS);
export const fetchCommAutomobileCustomerInfoSuccess = (
  customerInfo: ICommAutomobileCustomerInfo,
  customerPolicyId: number,
  isNewPolicy: boolean
) =>
  action(FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_SUCCESS, {
    customerInfo,
    customerPolicyId,
    isNewPolicy,
  });
export const fetchCommAutomobileCustomerInfoFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_CUSTOMER_INFO_FAILED, { errorMessage });

export const addCommAutomobileCustomerInfoAsync =
  (
    data: ICommAutomobileCustomerInfo,
    initialData: ICommAutomobileCustomerInfo,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean, policyId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await makeApiCall({
        url: "/policy/edit-policy-commauto-customer-info",
        method: "POST",
        automation: {
          isUpdate: isUpdate,
          primaryFieldName: "customer_policy_id"
        }
      }, initialData, {
        ...data,
        policy_id: data.sub_type,
        effective_time: data.effective_time,
      })

      const customerPolicyId = res.data.data.customer_policy_id;

      dispatch(
        fetchCommAutomobileCustomerInfoSuccess(
          res.data.data,
          customerPolicyId,
          true
        )
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Customer information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, customerPolicyId);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileCustomerInfoAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.customerInfo.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobileCustomerInfoProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-customer-info?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobileCustomerInfo[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            fetchCommAutomobileCustomerInfoSuccess(
              {
                ...data[0],
              },
              customerPolicyId,
              false
            )
          );
        } else {
          dispatch(
            fetchCommAutomobileCustomerInfoFailed(
              ERROR_MESSAGES.POLICY_NOT_FOUND
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileCustomerInfoFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Additional Insured

export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED";

export const fetchCommAutomobileAdditionalInsuredListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS);

export const fetchCommAutomobileAdditionalInsuredListSuccess = (
  data: ICommAutomobileAdditionalInsured[]
) => action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS, { data });

export const fetchCommAutomobileAdditionalInsuredListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED);

export const fetchCommAutomobileAdditionalInsuredListAsync =
  (
    customerpolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAdditionalInsuredListProgress());

      let finalUrl = `/policy/get-policy-commauto-additional-insured?customer_policy_id=${customerpolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: ICommAutomobileAdditionalInsured[] = res.data.data;
      dispatch(fetchCommAutomobileAdditionalInsuredListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const upsertCommAutomobileAdditionalInsuredAsync =
  (
    data: ICommAutomobileAdditionalInsured,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-additional-insured", data);

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Insured saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };


export const FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_FAILED =
  "FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_FAILED";

export const fetchCommAutomobilePriorPolicyProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_PROGRESS);
export const addCommAutomobilePriorPolicy = (
  priorPolicy: ICommAutomobilePriorPolicy
) => action(FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_SUCCESS, { priorPolicy });
export const fetchCommAutomobilePriorPolicyFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_PRIOR_POLICY_FAILED, { errorMessage });

export const addCommAutomobilePriorPolicyInfoAsync =
  (
    data: ICommAutomobilePriorPolicy,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-prior-policies", {
        ...data,
        status: "ACTIVE",
      });
      dispatch(addCommAutomobilePriorPolicy(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Prior policy saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchCommAutomobilePriorPolicyAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.priorPolicy.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobilePriorPolicyProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-prior-policies?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobilePriorPolicy[] = res.data.data;
        if (data.length > 0) {
          dispatch(addCommAutomobilePriorPolicy(data[0]));
        } else {
          dispatch(
            addCommAutomobilePriorPolicy(
              initialCommAutomobileDetails["priorPolicy"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilePriorPolicyFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_PREMIUM_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_PREMIUM_PROGRESS";

export const FETCH_COMM_AUTOMOBILE_PREMIUM_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_PREMIUM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_PREMIUM_FAILED =
  "FETCH_COMM_AUTOMOBILE_PREMIUM_FAILED";

export const fetchCommAutomobilePremiumProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_PREMIUM_PROGRESS);
export const fetchCommAutomobilePremiumSuccess = (
  premium: ICommAutomobilePremium
) => action(FETCH_COMM_AUTOMOBILE_PREMIUM_SUCCESS, { premium });
export const fetchCommAutomobilePremiumFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_PREMIUM_FAILED, { errorMessage });

export const addCommAutomobilePremiumAsync =
  (
    data: ICommAutomobilePremium,
    onCallback: (isSuccess: boolean,error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-premium", {
        ...data,
      });
      dispatch(fetchCommAutomobilePremiumSuccess(data));
      
      onCallback(true);
    } catch (err: any) {
      onCallback(false,err);
    
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
  
export const fetchCommAutomobilePremiumAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.premium.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobilePremiumProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-premium?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobilePremium[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCommAutomobilePremiumSuccess(data[0]));
        } else {
          dispatch(
            fetchCommAutomobilePremiumSuccess(
              initialCommAutomobileDetails["premium"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobilePremiumFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//policy admin

export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_FAILED =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_FAILED";

export const fetchCommAutomobilePolicyAdminProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_PROGRESS);

export const fetchCommAutomobilePolicyAdminSuccess = (
  data: ICommAutomobilePolicyAdmin
) => action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_SUCCESS, { data });
export const fetchCommAutomobilePolicyAdminFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_FAILED, { errorMessage });

export const addCommAutomobilePolicyAdminsync =
  (
    data: ICommAutomobilePolicyAdmin,
    onCallback: (isSuccess: boolean,error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {create_ts, ...rest} = data;
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-policy-admin", {
        ...rest,
      });
      dispatch(fetchCommAutomobilePolicyAdminSuccess(data));

      onCallback(true);
    } catch (err: any) {
      onCallback(false,err);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobilePolicyAdminAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.policyAdmin.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobilePolicyAdminProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-policy-admin?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobilePolicyAdmin[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCommAutomobilePolicyAdminSuccess(data[0]));
        } else {
          dispatch(
            fetchCommAutomobilePolicyAdminSuccess(
              initialCommAutomobileDetails["policyAdmin"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilePolicyAdminFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//policy admin manager
export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED =
  "FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED";

export const fetchCommAutomobilePolicyAdminManagerProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS);

export const fetchCommAutomobilePolicyAdminManagerSuccess = (
  data: ICommAutomobilePolicyAdminManager
) => action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS, { data });
export const fetchCommAutomobilePolicyAdminManagerFailed = (
  errorMessage: string
) =>
  action(FETCH_COMM_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED, { errorMessage });

export const addCommAutomobilePolicyAdminManagerAsync =
  (
    data: ICommAutomobilePolicyAdminManager,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-policy-admin-manager", data);
      dispatch(fetchCommAutomobilePolicyAdminManagerSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy admin manager saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchCommAutomobilePolicyAdminManagerAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.policyAdminManager.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobilePolicyAdminManagerProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-policy-admin-manager?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobilePolicyAdminManager[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCommAutomobilePolicyAdminManagerSuccess(data[0]));
        } else {
          dispatch(
            fetchCommAutomobilePolicyAdminManagerSuccess(
              initialCommAutomobileDetails["policyAdminManager"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobilePolicyAdminManagerFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_FAILED =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_FAILED";

export const fetchCommAutomobileAttachmentProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_PROGRESS);
export const fetchCommAutomobileAttachmentSuccess = (
  data: ICommAutomobileAttachment
  // customerPolicyId: number
) => action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_SUCCESS, { data });
export const fetchCommAutomobileAttachmentFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_FAILED, { errorMessage });

export const addCommAutomobileAttachmentAsync =
  (
    data: ICommAutomobileAttachment,
    customerID: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "COMM_AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Comm-Auto Attachment",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      await api.post("/policy/edit-policy-commauto-attachments", {
        ...data,
        file_upload: path,
      });
      // const data = res.data.data;

      dispatch(fetchCommAutomobileAttachmentSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileAttachmentAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().commAuto.basicDetails.attachment.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCommAutomobileAttachmentProgress());
        const res = await api.get(
          `/policy/get-policy-commauto-attachments?customer_policy_id=${customerPolicyId}`
        );
        const data: ICommAutomobileAttachment[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCommAutomobileAttachmentSuccess(data[0]));
        } else {
          dispatch(
            fetchCommAutomobileAttachmentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileAttachmentFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_FAILED";

export const fetchCommAutomobileAttachmentListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS);
export const fetchCommAutomobileAttachmentListSuccess = (
  data: ICommAutomobileAttachment[]
) => action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS, { data });
export const fetchCommAutomobileAttachmentListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_ATTACHMENT_LIST_FAILED);

export const fetchCommAutomobileAttachmentListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAttachmentListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-attachments?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileAttachment[] = res.data.data;
      dispatch(fetchCommAutomobileAttachmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCommAutomobileAttachmentListAsync =
  (
    data: ICommAutomobileAttachment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-commauto-attachments`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLAER_COMM_AUTOMOBILE_POLICY_ATTACHMENT_STATE =
  "CLAER_AUTOMOBILE_COMM_POLICY_ATTACHMENT_STATE";
export const clearCommAutomobilePolicyAttachment = () =>
  action(CLAER_COMM_AUTOMOBILE_POLICY_ATTACHMENT_STATE);

export const CLEAR_COMM_AUTOMOBILE_BASIC_DETAILS_STATE =
  "CLEAR_COMM_AUTOMOBILE_BASIC_DETAILS_STATE";

export const clearCommAutomobileBasicPolicyState = () =>
  action(CLEAR_COMM_AUTOMOBILE_BASIC_DETAILS_STATE);
