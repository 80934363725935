import React from "react";
import { RightPanel } from "../../../../../../components/RightPanel";
import { IBusinessPolicyInterested } from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterested.types";
import { InterestedParties } from "./InterestedParties";

interface IInterestedPartyRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  interestedNumber?: number;
  onSaveSuccess: (riskLocation: IBusinessPolicyInterested) => void;
  staticStatusValue?: string;
  createdFrom?: string;
  readonly?: boolean;
  onClose: () => void;
  initialData?: IBusinessPolicyInterested;
  disableApiCalls?: boolean;
}

export const InterestedPartiesRightPanel: React.FC<
  IInterestedPartyRightPanelProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    interestedNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    createdFrom,
    disableApiCalls = false,
    initialData,
  } = props;

  return (
    <>
      <RightPanel
        heading="Intertested Party"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="80%"
      >
        <InterestedParties
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          interestedNumber={interestedNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          created_from={createdFrom}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
