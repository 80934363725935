export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
  VEHICLE_CHANGE = "Vehicle Change",
  DRIVER_CHANGE = "Driver Change",
  COVERAGE_CHANGE = "Coverage Change",
  GARAGE_PLATE_CHANGE = "Garage Plate Change",
  INTERESTED_PARTY_CHANGE = "Interested Party Change",
  LESSOR_CHANGE = "Lessor Change",
  ADDRESS_CHANGE = "Address Change",
  RISK_LOCATION_CHANGE = "Risk Location Change",
  CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
  BUSINESS_SUBMISSION = "Business Submission",
  GENERAL_CHANGE = "General Change",
  PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
  LAO_BOR = "LOA/BOR",
  LETTER_OF_EXPERIENCE = "Letter of Experience",

  NEW_BUSINESS = "Business",
  NEW_PERSONAL_AUTO = "Personal Auto",
  NEW_HOME = "Home",
  NEW_COMMERCIAL_AUTO = "Commercial Auto",
  NEW_PERSONAL_AND_HOME = "Personal Auto & Home",
  REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_HOME = "Home",
  REMARKET_BUSINESS = "Business",
  REMARKET_PERSONAL_AUTO = "Personal Auto",
  REMARKET_PERSONAL_AND_HOME = "Personal Auto & Home",
  QUOTE_REQUEST = "Quote Request",
  TASK_CERTIFICATE_OF_INSURANCE = "Certificate of Insurance"
}

export enum POLICIES {
  BUSINESS_POLICY = "Business Policy",
  AUTOMOBILE_POLICY = "Automobile Policy",
  HABITATIONAL_POLICY = "Habitational Policy",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Financial Policy",
}

export enum POLICIES_NAMES {
  BUSINESS_POLICY = "Business",
  AUTOMOBILE_POLICY = "Automobile",
  HABITATIONAL_POLICY = "Habitational",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Financial",
}

export enum ERROR_MESSAGES {
  "NO_RECORD_FOUND"= "Unfortunately, there are no records available at the moment.",
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
  NONE = -1,
  LEADS = "LEADS|LEADS|LATEST_LEADS_WITHOUT_OPPORTUNITY_STATUS",
  OPPORTUNITY = "OPPORTUNITY|OPPORTUNITY|LATEST_LEAD_WITH_OPPORTUNITY",
  BUSINESS_POLICY = "POLICY|BUSINESS POLICY|LATEST_POLICY_BUSINESS_CUSTOMER_INFO",
  COMMERCIAL_AUTO = "POLICY|COMMERCIAL AUTOMOBILE POLICY|LATEST_POLICY_COMMAUTO_CUSTOMER_INFO",
  AUTOMOBILE = "POLICY|AUTOMOBILE POLICY|LATEST_POLICY_AUTOMOBILE_CUSTOMER_INFO",
  HABITATIONAL = "POLICY|HABITATIONAL POLICY|LATEST_POLICY_HABITATIONAL_CUSTOMER_INFO",
  CUSTOMER = "CUSTOMER|CUSTOMER|LATEST_CUSTOMER_ALL_DETAILED_RECORD",
  LIFE_AND_FINCANCIAL = "None",
  ALL_POLICY = "POLICY|ALL POLICY|LATEST_ALL_CUSTOMER_POLICY_DETAILS",
  LEGAL = "LEGAL PLUS|LEGAL PLUS|LATEST_LEGAL_PLUS",

  // HR
  HR_APPLICATION = "HR|APPLICATION FORM|LATEST_HR_APPLICATION_FORM",
  HR_ONBOARDING = "HR|ON BOARDING CHECKLIST|LATEST_HR_ONBOARDING_CHECKLIST",
  HR_OFFBOARDING = "HR|USER OFF BOARDING|LATEST_HR_USER_OFFBOARDING",
  HR_REQUEST_FORMS ="HR|REQUEST FORMS|LATEST_HR_USER_REQUEST",

  // Brokerage
  INSURER = "BROKERAGE|INSURER CODE|LATEST_INSURER_CODE",
  UNDERWRITERS = "BROKERAGE|UNDERWRITERS|LATEST_UNDERWRITERS",
  CSIO_COMPANY = "BROKERAGE|CSIO COMPANY|LATEST_CSIO_COMPANY",
  POLICY_COMISSION = "BROKERAGE|POLICY COMMISSION|LATEST_POLICY_COMMISSION",
  INSURER_CONTACTS = "BROKERAGE|INSURER CONTACTS|LATEST_INSURER_CONTACTS",
  BRANCH_OFFICES = "BROKERAGE|BRANCH OFFICE|LATEST_BRANCH_OFFICE",
  BROKER_CODES ="BROKERAGE|BROKER CODE|LATEST_BROKER_CODE",
  INSURER_SUB = "BROKERAGE|INSURER SUB|LATEST_INSURER_SUB",
  ADDITIONAL_INTERESTS = "BROKERAGE|ADDITIONAL INTEREST|LATEST_ADDITIONAL_INTEREST",
  INTERESTED_PARTIES = "BROKERAGE|INTERESTED PARTY|LATEST_INTERESTED_PARTY",
  BROKER_CONTACTS ="BROKERAGE|BROKER CONTRACTS|LATEST_BROKER_CONTRACTS",
  APPS_AND_FORMS= "BROKERAGE|APPS AND FORMS|LATEST_APPS_AND_FORMS",
  COMPANY_EQUIPMENT="BROKERAGE|COMPANY EQUIPMENTS|LATEST_BROKERAGE_EQUIPMENT",
  
  // Security
  ROLE = "SECURITY|ROLE|ROLE",
  ROLE_MODULE = "SECURITY|ROLE MODULE|ROLE_MODULE",
  AUTOMATION="SECURITY|AUTOMATION|LATEST_WORKFLOW_BASIC",
  UTILS= "SECURITY|UTILS|NAT_SECURITY_UTILS",

  // Template
  TEMPLATES = "TEMPLATE|TEMPLATE|LATEST_TEMPLATES",

  // User
  USERS = "USER|USER|LATEST_USER",

  // Task
  STAFF_USER = "USER|STAFF USER|LATEST_USER",
  TASK_NOTICES = "TASK|NOTICE|LATEST_NOTICE",
  ALL_TASK = "TASK|ALL TASK|LATEST_TASK",
  TASK_CALENDER = "TASK|CALENDER|CALENDER",
  TASK_TASKBOARD = "TASK|TASKBOARD|LATEST_TASK_USER_TASKBOARD",
  TASK_RENEWALS = "TASK|RENEWALS|LATEST_TASK_RENEWABLE",
  TASK_BINDERS = "TASK|BINDERS|LATEST_GET_ALL_BINDER_RECORD",
  TASK_MARKETING = "TASK|MARKETING|LATEST_GET_ALL_MARKETING_HISTORY",
  INSURANCE_REPORTS = "TASK|INSURANCE REPORTS|LATEST_TASK_DRIVER_INSURANCE_REPORT",
  NO_POLICY_INSURANCE_REPORTS = "TASK|NO POLICY INSURANCE REPORTS|LATEST_POLICY_NO_POLICY_DRIVER_INSURANCE_REPORTS",
  ALL_BINDERS = "TASK|BINDERS|LATEST_GET_ALL_BINDER_RECORD",
  NOTES = "TASK|NOTES|LATEST_NOTES",
  OLD_BUSINESS_TRACKER="TASK|OLD BUSINESS TRACKER|OLD_BUSINESS_TRACKER",
  OLD_INSURANCE_REPORTS="TASK|OLD INSURANCE REPORTS|OLD_INSURANCE_REPORTS",
  ABEYANCE_NOTES= "TASK|ABEYANCE/NOTES|LATEST_ALL_CUSTOMER_POLICY_DETAILS",

  // policy admin manager

  AUTO_POLICY_ADMIN_MANAGER = "POLICY ADMIN MANAGER|AUTOMOBILE POLICY ADMIN|LATEST_POLICY_AUTOMOBILE_POLICY_ADMIN",
  BUSINESS_POLICY_ADMIN_MANAGER = "POLICY ADMIN MANAGER|BUSINESS POLICY ADMIN|LATEST_POLICY_BUSINESS_POLICY_ADMIN",
  COMM_AUTO_POLICY_ADMIN_MANAGER = "POLICY ADMIN MANAGER|COMMERCIAL POLICY ADMIN|LATEST_POLICY_COMMAUTO_POLICY_ADMIN",
  HABITATIONAL_POLICY_ADMIN_MANAGER = "POLICY ADMIN MANAGER|HABITATIONAL POLICY ADMIN|LATEST_POLICY_HABITATIONAL_POLICY_ADMIN",
  LIFE_AND_FINCANCIAL_POLICY_ADMIN_MANAGER = "POLICY ADMIN MANAGER|LIFE AND FINANCIAL POLICY ADMIN|LATEST_POLICY_LIFE_BASIC_DETAILS_POLICY_ADMIN",


  G4_SIGN="G4 SIGN|DOCUMENTS|LATEST_SIGN_DOCUMENT",
  CSIO="CSIO|CSIO|LATEST_CSIO_RETRIEVE_MESSAGES",
  CSIO_EDOCS="CSIO|EDOC|LATEST_CSIO_EDOC",
  CSIO_MAPPING="CSIO|CSIO MAPPING|NAT_CSIO_MAPPING",
  CSIO_CRON="CSIO|CSIO CRON|NAT_CSIO_CRON",
  SUPPORT_TICKET="SUPPORT TICKET|SUPPORT TICKET|LATEST_SUPPORT_TICKETS",
  COMMUNICATION="COMMUNICATION|COMMUNICATION|NAT_COMMUNICATION",
  
  EQUIPMENT="BROKERAGE|COMPANY EQUIPMENTS|LATEST_BROKERAGE_EQUIPMENT",
}



export enum TRANSACTION_TYPE_ENUM {
  AuditPlus = "+ Audit",
  AuditMinus = "- Audit",
  Tax = "Tax",
  Reinstate = "Reinstate",
  Reissue = "Reissue",
  Rewrite = "Re-write",
  Correction = "Correction",
  Cancellation = "Cancellation",
  EndorsementPlus = "+ Endorsement",
  EndorsementMinus = "- Endorsement",
  Renewal = "Renewal",
  New = "New",
  FeeNSFFee = "Fee - NSF Fee",
  FeeServiceFee = "Fee - Service Fee",
  FeeInsuranceReports = "Fee - Insurance Reports",
  FeeFacilityFee = "Fee - Facility Fee",
  FeeMGAInsurerFee = "Fee - MGA/Insurer Fee",
  Payment = "Payment"
}

export enum MODULE_NAMES  {
   LEADS = "Leads",
   OPPORTUNITY = "Opportunity",
   CUSTOMER = "Customers",
   POLICIES = "Policies",
   LEGALS = "Legals"

}

export enum ROLES  {
  ADMIN= "ADMIN",
  CSR="CSR",
  PRODUCER_CL = "Producer-CL",
  PRODUCER = "Producer",
  HR = "HR",
  MANAGEMENT = "Management",
  CSR_HEADOFFICE= "CSR - Headoffice",
  BRANCH_MANAGER = "Branch Manager",
  ACCOUNTANT = "Accountant",
}

export enum TEMPLATE_RELATED_MODULE_ID {
  LEAD="LEAD", 
  G4SIGN="G4SIGN", 
  TASK="TASK",
}