import { AddOutlined } from "@mui/icons-material";
import React from "react";
import { PageContainer } from "../../components/container/PageContainer";
import {
  IData,
} from "../../components/Table/interfaces/IDataTableProps";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { IInsurerUnderWriter } from "../../redux/insurer/insurer.types";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../components/formsComponents";
import {
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../helpers";
import { ViewUnderWritersDetails } from "./components/UnderWriters/ViewUnderWriters/ViewUnderWritersDetails";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import { TABLE_CACHING_KEYS } from "../../constants/constants";
import { fetchUnderWriterListAsync } from "../../redux/underWriter/underWriterActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";

interface IUnderWriterListBaseProps {
  hideColumnsName?: string[];
  hideTableFilters?: boolean;
  onSelectionUnderwritter?: (
    isAllSelected: boolean,
    rows: IInsurerUnderWriter[]
  ) => void;
}

export const UnderWritersList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "underwriters",
    },
  ];
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/underwriter-composer");
  };

  return (
    <PageContainer title="Underwriter" description="this is innerpage">
      <Breadcrumb title="Underwriters" items={BCrumb} />

      <PageTitleBar
        heading="Underwriters/Insurer Contact"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <UnderWritersListBase />
    </PageContainer>
  );
};

export const UnderWritersListBase: React.FC<IUnderWriterListBaseProps> = (
  props
) => {
  const { hideColumnsName = [], hideTableFilters } = props;
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.borkerage.underwriter.underwriter
  );

  const [selectedUnderWriters, setSelectedUnderWriters] = React.useState<{
    isAllSelected: boolean;
    underwriter: IInsurerUnderWriter[];
  }>({
    isAllSelected: false,
    underwriter: [],
  });

  const [openView, setOpenView] = React.useState<IInsurerUnderWriter | null>(
    null
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination ,masterColumnResize},
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.BROKERAGE.UNDER_WRITER,
    },
  });

  const fetchList = () => {
    dispatch(
      fetchUnderWriterListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dateState.dates, tabs.selectedTab, searchState]);

  const menuItems =
    selectedUnderWriters.underwriter.length > 0 ||
    selectedUnderWriters.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const underwritersTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "underwriters_id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["insurer_code", "name"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        disableResize: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <StandardTableActions
              onViewClick={() => setOpenView(row)}
              commentBoxTypeId={"underwriters_primary_id"}
              onEditClick={() => {
                navigate(
                  "/underwriter-composer/" +
                    (row as IInsurerUnderWriter).underwriters_primary_id
                );
              }}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value: any, row: IInsurerUnderWriter) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        enableSorting: true,
      },
      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        enableSorting: true,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <CustomLink
              to={
                "/underwriter-composer/" +
                (row as IInsurerUnderWriter).underwriters_primary_id
              }
              label={value.value}
            />
          );
        },
      },

      {
        key: "phone",
        headerName: "Phone",
        fieldName: "phone_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedMessages
              phone={row.phone_number}
              moduleKey={MODULE_IDS.UNDERWRITERS as string}
            />
          );
        },
      },

      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleKey={MODULE_IDS.UNDERWRITERS as string}
            />
          );
        },
      },

      {
        key: "department_email",
        headerName: "Department Email",
        fieldName: "department_email",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
        enableSorting: true,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
        enableSorting: true,
      },

      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
        enableSorting: true,
        // exportCellWidth: 25,
        // onExportRender: (values: any) => {
        //   return formatDateWithTime(values.value);
        // },
      },
    ].filter((x) => !hideColumnsName.includes(x.headerName)),
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: IInsurerUnderWriter[]) => {
      setSelectedUnderWriters({
        isAllSelected: isAllSelected,
        underwriter: rows,
      });
      if (props.onSelectionUnderwritter) {
        props.onSelectionUnderwritter(isAllSelected, rows);
      }
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns
    }
  };

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_underwriters",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Active", value: "ACTIVE", variant: "success" },
        { label: "Inactive", value: "INACTIVE", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  return (
    <>
      <DataTableV2 {...underwritersTableProps} />
      {openView && (
        <ViewUnderWritersDetails
          open={true}
          underwriter={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </>
  );
};
