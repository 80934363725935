import React from "react";
import { Avatar, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { ITableTab } from "../../../../../../components/Table/components/TableTabs/TableTabs.types";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../components/Table/plugins";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { Breadcrumb } from "../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { clearCsioEdocsState, fetchCsioEdocsAsync, fetchCsioEdocsListAsync } from "../../../../../../redux/csioEdocs/csioEdocsActions";
import { ICSIOEDocs } from "../../../../../../redux/csioEdocs/csioEdocs.types";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { createTabsWithRecordcounts } from "../../../../../../helpers";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../../../../redux/common/commonActions";
import { EmailComposeRightPanel } from "../../../../../../components/MessagesDialogs/EmailCompose/EmailCompose";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { TABLE_CACHING_KEYS } from "../../../../../../constants/constants";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const BusinessCSIOEDocsList: React.FC<{
  customerId: number;
  customerPolicyId: string;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
      "ACTIVE"
    );
  // const { addBreadcrumb } = useBreadcrumbContext();

  // const BCrumb: IBreadcrumbProps["items"] = [
  //   {
  //     to: "/dashboard/",
  //     title: "Dashboard",
  //   },
  //   {
  //     title: "Csio Edocs",
  //   },
  // ];
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.csioEdocs
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const {
    state: {
      dateState,
      searchState,
      tabs,
      columnsConfig,
      pagination,
      masterColumnResize,
    },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
    onResizeMasterColumns,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.BUSINESS.CSIO_EDOCS,
    },
  });

  const fetchList = async () => {
      if (customerPolicyId) {
        dispatch(fetchCsioEdocsAsync(customerPolicyId, status));
      }
    };
  
    React.useEffect(() => {
      fetchList();
      return () => {
        dispatch(clearCsioEdocsState());
      };
    }, [customerPolicyId, status]);

  const handleRedirect = () => {
    navigate(`/csio-edocs-list/manage-csio-edocs`);
  };

  const handleEditRedirect = (messageGUId: string) => () => {
    navigate(`/csio-edocs-list/manage-csio-edoc/${messageGUId}`);
  };

  const csioEdocsTableProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: loading !== LoadState.Loaded,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
          loadInitialFilterOncePopoverOpened: [
            {
              column: ["message_guid"],
              logicalOperator: "OR",
              operator: "CONTAINS",
              value: "",
            },
          ],
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        isFirstColumnSticky: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: ICSIOEDocs) => {
          return (
            <StandardTableActions
              onEditClick={handleEditRedirect(row.message_guid as string)}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/csio-edocs-list/manage-csio-edoc/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },

      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row: ICSIOEDocs) => {
          if (!row.customer_email) {
            return <>--</>;
          }
          return (
            <EmailComposeRightPanel
              displayLabel={row.customer_email}
              toEmail={row.customer_email}
              attachements={[row.attachment]}
            />
          );
        },
      },
      {
        key: "business_purpose_type",
        headerName: "Busines Purpose Type",
        fieldName: "business_purpose_type",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 30,
      },
      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "producer_code",
        headerName: "Producer Code",
        fieldName: "producer_code",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
    ],
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    masterColumnResize: {
      values: masterColumnResize,
      onResize: onResizeMasterColumns,
    },
    // tableTabProps: {
    //   selectedTab: status,
    //   tabs: tableTabs,
    //   onTabChange: (newSelectedTab) => {
    //     setStatus(newSelectedTab);
    //   },
    // },
  };

  return (
    <PageContainer title="View eDocs" description="this is innerpage">
      {/* <Breadcrumb title="" items={BCrumb} /> */}

      <PageTitleBar
        heading="eDocs"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleRedirect}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...csioEdocsTableProps} />
    </PageContainer>
  );
};
