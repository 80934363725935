import moment from "moment";
import { LoadState } from "../../constants/enums";
import { formatDate } from "../../helpers";

export interface IAutomobileQuoteState {
  list: IAutomobileQuote[];
  data: IAutomobileQuote;
  loading: LoadState;
  error: string | null;
}

export interface IAutomobileQuote {
  customer_policy_id: number | null;
  incremental_number: number | null;
  customer_id: string | null;
  province: string | null;
  default_vehicle_type: string | null;
  
  term_length: string | null;
  multi_vehicle_enable_or_disable: string | null;
  policy_discount: string | null;
  policy_discount_all_carrier: string | null;
  web_discount: string | null;
  property_policy_policy_type: string | null;
  property_policy_carrier: string | null;
  property_policy_policy_number: string | null;
  property_policy_since: string | null;
  property_policy_occupied_since: string | null;
  substitution_type: string | null;
  commercial_property_policy_carrier: string | null;
  commercial_property_policy_policy_number: string | null;
  commercial_property_policy_since: string | null;

  // vehicle_incremental_number: string[];
  vehicle_vin_number: string[];
  pers_driver_number: string[];
  coverage_incremental_number: string[];
  claims_incremental_number: string[];
  conviction_driver_number: string[];
  insured_incremental_number: string[];
  transaction_effective_date: string | null;
  transaction_request_date: string | null;
  business_type: string | null;
  status: string;
}

export const initialAutomobileQuoteState: IAutomobileQuoteState = {
  list: [],
  loading: LoadState.NotLoaded,
  error: null,
  data: {
    customer_policy_id: 0,
    incremental_number: null,
    customer_id: null,
    province: "ON",
    default_vehicle_type: null,

    term_length: "twelve_months",
    multi_vehicle_enable_or_disable: "enable",
    policy_discount: null,
    policy_discount_all_carrier: null,
    web_discount: "excluded",
    property_policy_policy_type: null,
    property_policy_carrier: null,
    property_policy_policy_number: null,
    property_policy_since: null,
    property_policy_occupied_since: null,
    substitution_type: "None",
    commercial_property_policy_carrier: null,
    commercial_property_policy_policy_number: null,
    commercial_property_policy_since: null,
    business_type: null,
    transaction_effective_date: formatDate(moment().format()),
    transaction_request_date: formatDate(moment().format()),
    status: "ACTIVE",
    vehicle_vin_number: [],
    pers_driver_number: [],
    coverage_incremental_number: [],
    claims_incremental_number: [],
    conviction_driver_number: [],
    insured_incremental_number: []
  },
};
