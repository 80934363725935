import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
  clearHabitationalRiskLocation,
  fetchHabitationalRiskListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import {
    clearAutomobileConvictions,
  clearAutomobileDriverInfo,
  fetchAutomobileDriverConvitionsListAsync,
  fetchAutomobileDriverListAsync,
} from "../../../../../../redux/automobileDriver/automobileDriverActions";

export const useAutomobilePolicyDriverConviction = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const DriverConviction = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.driver.driverConviction.driverConvictionList
  );

  const driverConvictionDropDown = React.useMemo(() => {
    return DriverConviction.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (Code: ${
          item.conviction_code || "N/A"
        } )`,
        value: item.incremental_number || "",
      };
    });
  }, [DriverConviction]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverConvitionsListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearAutomobileConvictions());
    };
  }, [customerPolicyId]);

  return { driverConvictionDropDown };
};
