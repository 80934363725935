/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { StandadCard } from "../../../../../../../components/Cards";
import {
  addAutomobileDriverConvitionsAsync,
  clearAutomobileConvictions,
  fetchAutomobileDriverConvitionsAsync,
  fetchAutomobileDriverConvitionsListAsync,
  fetchAutomobileDriverListAsync,
} from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import {
  automobile_suspension_reason,
  COMMON_STATUS,
  conviction_code,
  suspension_reason,
} from "../../../../../../../constants/constants";
import { useBreadcrumbContext } from "../../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { renderDropdownValue } from "../../../../../../../helpers";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { IAutomobileDriverConviction } from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";

interface IAutomobileDriverConvictionFormProps {
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (riskLocation: IAutomobileDriverConviction) => void;
  initialData?: IAutomobileDriverConviction;
  disableApiCalls?: boolean;
}

export const AutomobileConvictionInformation: React.FC<
  IAutomobileDriverConvictionFormProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onSaveSuccess,
    readOnly = false,
    fromRightPanel = false,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
  } = props;

  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/comm-automobile-policy/comm-automobile/" +
        customerId +
        "/" +
        customerPolicyId,
      title: "comm-auto",
    },
    {
      title: "conviction",
    },
  ];
  const { driverConviction, driverInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver
  );

  const { data } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.customerInfo
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: initialData ? initialData : driverConviction.data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        if (disableApiCalls) {
          onSaveSuccess(values);
        } else {
          if (customerId && customerPolicyId) {
            let statusValue = values.status;
            if (staticStatusValue) {
              statusValue = staticStatusValue;
            }
            setLoading(true);
            dispatch(
              addAutomobileDriverConvitionsAsync(
                {
                  ...values,
                  customer_id: customerId,
                  customer_policy_id: customerPolicyId,
                  status: statusValue,
                  created_from: created_from || "Driver Conviction",
                },
                (isSuccess, driverConviction) => {
                  if (isSuccess && driverConviction) {
                    window.scrollTo(0, 0);
                    onSaveSuccess(driverConviction);
                  }
                  setLoading(false);
                }
              )
            );
          }
        }
      },
    });

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearAutomobileConvictions());
    };
  }, []);

  React.useEffect(() => {
    setValues({
      ...driverConviction.data,
      customer_name: data.customer_name,
      policy_number: data.policy_number,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverConviction.data]);

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber) {
      dispatch(
        fetchAutomobileDriverConvitionsAsync(
          customerPolicyId,
          incrementalNumber
        )
      );
    } else if (customerPolicyId) {
      dispatch(fetchAutomobileDriverListAsync(customerPolicyId));
    }
  }, [customerPolicyId, incrementalNumber]);

  // React.useEffect(() => {
  //   if (customerPolicyId) {
  //     dispatch(fetchAutomobileDriverConvitionsListAsync(customerPolicyId));
  //   }
  // }, []);
   

  React.useEffect(() => {
    if (values.suspension_date && values.reinstatement_date) {
      const days = moment(values.reinstatement_date).diff(
        moment(values.suspension_date),
        "days"
      );
      const months = Math.floor(days / 30);
      setFieldValue("suspended_no_of_months", months);
    }
  }, [values.suspension_date, values.reinstatement_date]);

  const driversDropdown = React.useMemo(() => {
    return driverInformation.driversList.map((item) => {
      return {
        label: item.driver_number + ` (${item.driver_name})`,
        value: item.driver_number || "--",
      };
    });
  }, [driverInformation.driversList]);

  function separateStringAtSpace(inputString: string): string[] {
    let x = inputString.split(" ");
    let first: string = x[0];
    let second: string = x.slice(1).join(" ");

    return [first, second];
  }

  React.useEffect(() => {
    if (values.conviction_code) {
      const separatedString = separateStringAtSpace(values.conviction_code);

      if (values.conviction_code !== separatedString[0]) {
        setFieldValue("conviction_code", separatedString[0]);
        setFieldValue("conviction_description", separatedString[1]);
      }
    }
  }, [values.conviction_code]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileConvictions());
    };
  }, []);

  return (
    <PageLoader
      loading={driverConviction.loading === LoadState.InProgress}
      error={
        driverConviction.error ? { message: driverConviction.error } : undefined
      }
    >
      <StandadCard
        sx={{ mt: 1 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Driver Convictions" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Number</CustomFormLabel>
                <CustomTextField
                  name="incremental_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  value={values.incremental_number}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Driver Number</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="driver_number"
                  value={values.driver_number}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driversDropdown}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Date of Conviction</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.date_of_conviction || ""}
                  onChange={(newValue) => {
                    setFieldValue("date_of_conviction", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Conviction Code</CustomFormLabel>
                <CustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="conviction_code"
                  fullWidth
                  value={values.conviction_code}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={renderDropdownValue(`Select one`)}
                >
                  {conviction_code.map((type) => {
                    return <MenuItem value={type}>{type}</MenuItem>;
                  })}
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Conviction Description</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="conviction_description"
                  value={values.conviction_description}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>No. of Kms Over</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  name="no_of_kms_over"
                  value={values.no_of_kms_over}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Suspended</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.suspended}
                  name="suspended"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={["Y", "N"].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Suspension Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.suspension_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("suspension_date", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Suspension Reason</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.suspension_reason}
                  name="suspension_reason"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={automobile_suspension_reason.map((template) => {
                    return { label: template.label, value: template.value };
                  })}
                ></ControlledCustomSelect>
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Reinstatement Date</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.reinstatement_date || ""}
                  onChange={(newValue) => {
                    setFieldValue("reinstatement_date", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Suspended No. of Months</CustomFormLabel>
                <CustomTextField
                  variant="outlined"
                  size="small"
                  type="string"
                  fullWidth
                  name="suspended_no_of_months"
                  value={values.suspended_no_of_months}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {!staticStatusValue && (
              <Grid container>
                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{ mt: 2 }}>
              <RoleBasedCustomButton
                type="submit"
                moduleKey={MODULE_IDS.AUTOMOBILE}
                disabled={loading}
                variant="contained"
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
