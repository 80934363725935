import produce from "immer";
import {
  ADD_UNDER_WRITER,
  ADD_UNDER_WRITER_ID,
  ADD_UNDER_WRITER_PROGESS,
  CLEAR_INSURER_STATE,
  FETCH_UNDER_WRITER_LIST_FAILED,
  FETCH_UNDER_WRITER_LIST_PROGRESS,
  FETCH_UNDER_WRITER_LIST_SUCCESS,
  FETCH_UNDER_WRITER_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { IUnderWriterActions } from ".";
import { initialUnderWriterState } from "./underWriterState";
import { UNDER_WRITER_ERROR } from "./underWriterActions";

export const underWriterReducer = (
  state: IStoreState["borkerage"]["underwriter"] = initialUnderWriterState,
  action: IUnderWriterActions
) => {
  switch (action.type) {
    case FETCH_UNDER_WRITER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.underwriter.loading = LoadState.InProgress;
        draftState.underwriter.list = [];
      });
      return newState;
    }
    case FETCH_UNDER_WRITER_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.underwriter.loading = LoadState.Loaded;
        draftState.underwriter.list = list;
      });
      return newState;
    }
    case FETCH_UNDER_WRITER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.underwriter.loading = LoadState.InProgress;
        draftState.underwriter.list = [];
      });
      return newState;
    }
    case ADD_UNDER_WRITER_PROGESS: {
      const newState = produce(state, (draftState) => {
        draftState.underwriter.saveLoading = true;
      });
      return newState;
    }
    case ADD_UNDER_WRITER: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.underwriter.loading = LoadState.Loaded;
        draftState.underwriter.saveLoading = false;
        draftState.underwriter.data = data;
      });
      return newState;
    }

    case FETCH_UNDER_WRITER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.underwriter.loading = LoadState.InProgress;
      });
      return newState;
    }

    case UNDER_WRITER_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.underwriter.error = action.payload.message;
        draftState.underwriter.saveLoading = false;
        draftState.underwriter.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_INSURER_STATE: {
      return initialUnderWriterState;
    }

    default: {
      return state;
    }
  }
};
