import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../../../../../components/formsComponents";
import { IHabitationalPolicyChangeProps } from "./HabitationalPolicyChange.types";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { useHabitationalPolicyRiskLocation } from "../../hooks/useHabitationalPolicyRiskLocation";
import { HabitationalRiskLocationRightPanel } from "../../HabitationalRiskLocation/HabitationalRiskLocationRightPanel";
import { IHabitationalRiskLocation } from "../../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocation.types";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import produce from "immer";
import { getFullAddres } from "../../../../../../../helpers";
import { ConfirmDialog } from "../../../../../../../components/Dialogs/ConfirmDialog";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";

export const RiskLocationInformation: React.FC<
  IHabitationalPolicyChangeProps
> = ({
  values,
  setValues,
  errors,
  handleChange,
  setFieldValue,
  customerId,
  customerPolicyId,
  isStatusCompleted,
  autoSaveData = () => {},
}) => {
  const [open, setOpen] = React.useState<{
    type: "create" | "edit" | "view";
    index?: number;
    data?: IHabitationalRiskLocation;
  } | null>(null);
  const { riskLocationsDropDown } =
    useHabitationalPolicyRiskLocation(customerPolicyId);

  const handleCheckBoxChange = (value: string) => {
    setFieldValue("risk_location_change.type", value);
  };

  const handleSaveSuccess = (riskLocation: IHabitationalRiskLocation) => {
    const newValues = produce(values, (draftValues) => {
      const updatedData = {
        ...riskLocation,
        customer_policy_id: customerPolicyId ?? null,
        customer_id: customerId ? Number(customerId) : null,
      };
      if (open && open.type === "edit" && open.index !== undefined) {
        draftValues.risk_location_change.records[open.index] = updatedData;
      } else {
        draftValues.risk_location_change.records.push(updatedData);
      }
    });
    autoSaveData(newValues);
    setValues(newValues);
    setOpen(null);
  };

  const handleCreateEdit = (index?: number) => () => {
    if (isStatusCompleted) {
      return;
    }
    setOpen({
      type: index !== undefined ? "edit" : "create",
      index: index,
      data:
        index !== undefined
          ? values.risk_location_change.records[index]
          : undefined,
    });
  };

  const handleView = (index: number) => () => {
    setOpen({
      type: "view",
      index: index,
      data: values.risk_location_change.records[index],
    });
  };

  const [deleteConfirm, setDeleteConfirm] = React.useState<number | null>(null);
  const handleDelete = (index: number) => () => {
    setDeleteConfirm(index);
  };

  const handleDeleteConfirm = () => {
    if (deleteConfirm === null) {
      return;
    }
    const newValues = produce(values, (draftValues) => {
      draftValues.risk_location_change.records.splice(deleteConfirm, 1);
    });
    setValues(newValues);
    autoSaveData(newValues);
    setDeleteConfirm(null);
  };

  return (
    <>
      <LabelCard heading="Risk Location Change" />
     <FieldSet disabled={isStatusCompleted}>
     <Grid container spacing={2} mb={3}>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.risk_location_change.type === "Add"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Add" : "");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.risk_location_change.type === "Delete"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Delete" : "");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.risk_location_change.type === "Change"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Change" : "");
              }}
            />
            <Typography>Change</Typography>
          </Stack>
        </Grid>
      </Grid>
     </FieldSet>

      {values.risk_location_change.type &&
        ["Add", "Change"].includes(values.risk_location_change.type) && (
          <>
            <Grid container spacing={3}>
              {values.risk_location_change.type === "Change" && (
                <Grid item xs={12} md={6}>
                  <CustomFormLabel>Delete Risk Location</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="risk_location_change.delete_risk_location"
                    fullWidth
                    displayEmpty
                    value={Number(
                      values.risk_location_change.delete_risk_location
                    )}
                    onChange={handleChange}
                    placeholder="Select One"
                    options={riskLocationsDropDown}
                  ></ControlledCustomSelect>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"end"}>
                  <Button
                    variant="contained"
                    disabled={isStatusCompleted}
                    onClick={handleCreateEdit()}
                  >
                    Add New
                  </Button>
                </Stack>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>View</TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.risk_location_change.records.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ display: "flex" }}>
                                <StandardTableActions
                                  onViewClick={handleView(index)}
                                  onEditClick={
                                    !isStatusCompleted
                                      ? handleCreateEdit(index)
                                      : undefined
                                  }
                                  onDeleteClick={
                                    !isStatusCompleted
                                      ? handleDelete(index)
                                      : undefined
                                  }
                                />
                              </TableCell>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {getFullAddres(
                                  item.address,
                                  item.unit_or_suite,
                                  item.city,
                                  item.province_or_state,
                                  item.country,
                                  item.postal_code
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}

      {values.risk_location_change.type &&
        ["Delete"].includes(values.risk_location_change.type) && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Risk Location</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="risk_location_change.delete_risk_location"
                fullWidth
                displayEmpty
                value={Number(values.risk_location_change.delete_risk_location)}
                onChange={handleChange}
                placeholder="Select One"
                options={riskLocationsDropDown}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
        )}

      {open && (
        <HabitationalRiskLocationRightPanel
          open={true}
          readonly={open.type === "view"}
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          onSaveSuccess={handleSaveSuccess}
          staticStatusValue="ACTIVE"
          created_from="Policy_Change"
          onClose={() => setOpen(null)}
          initialData={open.data}
          disableApiCalls
        />
      )}

      {deleteConfirm !== null && (
        <ConfirmDialog
          open
          content="Are you sure you want to delete this record?"
          onClose={() => setDeleteConfirm(null)}
          onConfrim={handleDeleteConfirm}
        />
      )}
    </>
  );
};
