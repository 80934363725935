import produce from "immer";
import { InsurerActions } from ".";
import {
  ADD_INSURER_CODE,
  ADD_INSURER_CODE_ID,
  ADD_INSURER_CODE_PROGESS,
  CLEAR_INSURER_STATE,
  FETCH_INSURER_CODE_LIST_FAILED,
  FETCH_INSURER_CODE_LIST_PROGRESS,
  FETCH_INSURER_CODE_LIST_SUCCESS,
  FETCH_INSURER_CODE_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialInsurerState } from "./insurerState";
import { LoadState } from "../../constants/enums";
import { INSURER_CODE_ERROR } from "./insurerActions";

export const insurerReducer = (
  state: IStoreState["borkerage"]["insurer"] = initialInsurerState,
  action: InsurerActions
) => {
  switch (action.type) {
    case FETCH_INSURER_CODE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.loading = LoadState.InProgress;
        draftState.insurerCode.list = [];
      });
      return newState;
    }
    case FETCH_INSURER_CODE_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.loading = LoadState.Loaded;
        draftState.insurerCode.list = list;
      });
      return newState;
    }
    case FETCH_INSURER_CODE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.loading = LoadState.InProgress;
        draftState.insurerCode.list = [];
      });
      return newState;
    }
    case ADD_INSURER_CODE_PROGESS: {
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.saveLoading = true;
      });
      return newState;
    }
    case ADD_INSURER_CODE: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.loading = LoadState.Loaded;
        draftState.insurerCode.saveLoading = false;
        draftState.insurerCode.data = data;
      });
      return newState;
    }

    case FETCH_INSURER_CODE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.loading = LoadState.InProgress;
      });
      return newState;
    }

    case INSURER_CODE_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.insurerCode.error = action.payload.message;
        draftState.insurerCode.saveLoading = false;
        draftState.insurerCode.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_INSURER_STATE: {
      return initialInsurerState;
    }

    default: {
      return state;
    }
  }
};
