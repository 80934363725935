import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialOldBusinessTrackerState } from "./oldBusinessTracker.types";
import { OldBusinessTrackerActions } from ".";
import {
  CLEAR_OLD_BUSINESS_TRACKER,
  FETCH_OLD_BUSINESS_TRACKER_LIST_FAILED,
  FETCH_OLD_BUSINESS_TRACKER_LIST_PROGRESS,
  FETCH_OLD_BUSINESS_TRACKER_LIST_SUCCESS,
} from "./oldBusinessTrackerActions";

export const oldBusinessTrackerReducer = (
  state: IStoreState["tasks"]["oldBusinessTracker"] = initialOldBusinessTrackerState,
  action: OldBusinessTrackerActions
) => {
  switch (action.type) {
    case FETCH_OLD_BUSINESS_TRACKER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_OLD_BUSINESS_TRACKER_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
      });
      return newState;
    }
    case FETCH_OLD_BUSINESS_TRACKER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case CLEAR_OLD_BUSINESS_TRACKER: {
      return initialOldBusinessTrackerState;
    }

    default: {
      return state;
    }
  }
};
