import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearBusinessPolicyCoverageState, fetchBusinessPolicyCoverageListAsync } from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businesPolicyCoverageActions";

export const useBusinessPolicyCoverage = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const coverageList = useSelector(
    (storeState: IStoreState) => storeState.business.coverage.data
  );

  const coverageDropdown = React.useMemo(() => {
    return coverageList.map((item) => {
      return {
        label:
          item.coverage_number,
        value: item.coverage_number || "",
      };
    });
  }, [coverageList]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchBusinessPolicyCoverageListAsync(Number(customerPolicyId), "ACTIVE"));
    }
    return () => {
      dispatch(clearBusinessPolicyCoverageState());
    };
  }, []);

  return { coverageDropdown };
};
