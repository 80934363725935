import produce from "immer";
import { AIReconciliationActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialAccountReconciliationState } from "./aiReconciliation.types";
import { CLEAR_RECONCILIATION_DETAILS_STATE, FETCH_RECONCILIATION_DETAILS_FAILED, FETCH_RECONCILIATION_DETAILS_PROGRESS, FETCH_RECONCILIATION_DETAILS_SUCCESS, FETCH_RECONCILIATION_LIST_FAILED, FETCH_RECONCILIATION_LIST_PROGRESS, FETCH_RECONCILIATION_LIST_SUCCESS } from "./aiReconciliationActions";
import { LoadState } from "../../constants/enums";




export const aiReconciliationReducer = (
  state: IStoreState["aiReconciliation"] = initialAccountReconciliationState,
  action: AIReconciliationActions
) => {
  switch (action.type) {
  
    case FETCH_RECONCILIATION_DETAILS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.reconciliationDetailsLoading = LoadState.InProgress;
        draftState.reconciliationDetails = initialAccountReconciliationState['reconciliationDetails'];
      });
      return newState;
    }
    case FETCH_RECONCILIATION_DETAILS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.reconciliationDetailsLoading = LoadState.Loaded;
        draftState.reconciliationDetails = data;
      });
      return newState;
    }
    case FETCH_RECONCILIATION_DETAILS_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.reconciliationDetailsLoading = LoadState.Failed;
        draftState.reconciliationDetails =  initialAccountReconciliationState['reconciliationDetails'];
        draftState.reconciliationDetailsError = error;
      });
      return newState;
    }

    case FETCH_RECONCILIATION_LIST_PROGRESS: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.list = [];
            draftState.totalRecords = 0;
          });
          return newState;
        }
        case FETCH_RECONCILIATION_LIST_SUCCESS: {
          const { data, totalRecords } = action.payload;
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Loaded;
            draftState.list = data;
            draftState.totalRecords = totalRecords;
          });
          return newState;
        }
        case FETCH_RECONCILIATION_LIST_FAILED: {
          const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
            draftState.list = [];
            draftState.totalRecords = 0;
          });
          return newState;
        }

    case CLEAR_RECONCILIATION_DETAILS_STATE: {
      return  initialAccountReconciliationState;
    }

    default: {
      return state;
    }
  }
};
