/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  isAlphaNumeric,
  renderDropdownValue,
} from "../../../../../../../helpers";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import {
  addAutomobileVehicleAsync,
  fetchAutomobileVehicleAsync,
} from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import {
  addAutomobileDriverClaimAsync,
  clearAutomobileClaimInfo,
  fetchAutomobileDriverClaimAsync,
  fetchAutomobileDriverListAsync,
} from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import {
  COMMON_STATUS,
  product_type_options,
  type_code_options,
  vehicle_use,
} from "../../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import { IAutomobileDriverClaims } from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { useAutomobilePolicyDriverInfo } from "../../hooks/useAutomobilePolicyDriverInfo";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";

export const DriverClaims: React.FC<{
  customerId?: number;
  customerPolicyId?: number;
  claimNumber?: number;
  changeStep?: (value: number) => void;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  showOnlySaveButton?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (claim: IAutomobileDriverClaims) => void;
  initialData?: IAutomobileDriverClaims;
  disableApiCalls?: boolean;
}> = (props) => {
  const {
    customerId,
    customerPolicyId,
    claimNumber,
    readOnly = false,
    fromRightPanel = false,
    showOnlySaveButton = false,
    staticStatusValue,
    created_from,
    onSaveSuccess,
    initialData,
    disableApiCalls = false,
  } = props;

  const {
    data,
    loading: claimLoading,
    error,
  } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver.driverClaims
  );

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { driverDropDown } = useAutomobilePolicyDriverInfo();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialData ? initialData : data,
    validate: (values) => {
      const errors: any = {};
      if (values.claim_number && !isAlphaNumeric(values.claim_number)) {
        errors.claim_number = "Must be alpha numeric.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerId && customerPolicyId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setLoading(true);
          dispatch(
            addAutomobileDriverClaimAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                status: statusValue,
                created_from: created_from || "Driver Claim",
              },
              customerId,
              (isSuccess, claim) => {
                if (isSuccess && claim) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(claim);
                }

                setLoading(false);
              }
            )
          );
        }
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId && claimNumber) {
      dispatch(
        fetchAutomobileDriverClaimAsync(Number(customerPolicyId), claimNumber)
      );
    } else if (customerPolicyId) {
      dispatch(fetchAutomobileDriverListAsync(Number(customerPolicyId)));
    }
  }, [customerPolicyId, claimNumber]);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileClaimInfo());
    };
  }, []);

  return (
    <PageLoader
      loading={claimLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <form onSubmit={handleSubmit}>
        <FieldSet disabled={readOnly}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Number</CustomFormLabel>
              <CustomTextField
                name="claim_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
                //onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Driver Number</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="driver_number"
                value={values.driver_number}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder="Select one"
                options={driverDropDown}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Vehicle Number</CustomFormLabel>
              <CustomTextField
                name="vehicle_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.vehicle_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date of Loss</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_of_loss || ""}
                onChange={(newValue) => {
                  setFieldValue("date_of_loss", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Time of Loss</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.time_of_loss || ""}
                onChange={(newValue) => {
                  setFieldValue("time_of_loss", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Value of Loss</CustomFormLabel>
              <CustomTextField
                fullWidth
                value={values.value_of_loss}
                name="value_of_loss"
                type="text"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Type</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="claim_type"
                value={values.claim_type}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {[
                  "AB",
                  "AP",
                  "BI",
                  "PD",
                  "SP",
                  "TL",
                  "OTH",
                  "UIM",
                  "COMP",
                  "DCPD",
                  "Collision",
                  "Liability",
                  "Non-Responsible Collision",
                ].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Amount Paid</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                value={values.amount_paid}
                onChange={(value) => {
                  setFieldValue("amount_paid", value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Insurer</CustomFormLabel>
              <InsurerCodeAutoSearch
                value={values.insurer}
                onSelect={(value) => {
                  setFieldValue("insurer", value.value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>At Fault</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.at_fault}
                name="at_fault"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["Y", "N"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Forgiven</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.forgiven}
                name="forgiven"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["Y", "N"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Ours</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="ours"
                value={values.ours}
                onChange={handleChange}
                displayEmpty
                fullWidth
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["Y", "N"].map((policyType) => {
                  return <MenuItem value={policyType}>{policyType}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            {values.ours === "Y" && (
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Claim Number</CustomFormLabel>
                <CustomTextField
                  name="claim_number"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.claim_number}
                  error={errors.claim_number ? true : false}
                  helperText={errors.claim_number}
                  onChange={(e) =>
                    setFieldValue("claim_number", e.target.value.toUpperCase())
                  }
                />
              </Grid>
            )}
          </Grid>
          {!staticStatusValue && (
            <Grid container>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Status</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={COMMON_STATUS}
                />
              </Grid>
            </Grid>
          )}
          {showOnlySaveButton && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("submit")}
              >
                Save
              </RoleBasedCustomButton>
            </Box>
          )}
          {!showOnlySaveButton && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("submit")}
              >
                Save & Exit
              </RoleBasedCustomButton>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.AUTOMOBILE}
                type="submit"
                disabled={loading}
                variant="contained"
                onClick={() => setButtonClicked("save")}
              >
                Save & Next
              </RoleBasedCustomButton>
            </Box>
          )}
        </FieldSet>
      </form>
    </PageLoader>
  );
};
