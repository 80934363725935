import React from "react";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { useFormik } from "formik";
import { Button, Grid, Typography } from "@mui/material";
import { FileUpload } from "../../../../../../components/FileUpload/FileUpload";
import { useDispatchWrapper } from "../../../../../../hooks";
import { upsertARSQuote } from "../../../../../../redux/automobileApplication/automobileApplicationActions";
import { getUploadedPdfProccess } from "../../../../../../redux/aiReconciliation/aiReconciliationActions";
import { IUploadPdfDocumentProcess } from "../../../../../../redux/aiReconciliation/aiReconciliation.types";
import { ERROR_MESSAGES } from "../../../../../../constants/enums";
import { ProgressCard } from "../../../../../../components/ProgressCard/ProgressCard";

export interface IARSQuoteProps {
  open: boolean;
  customerPolicyId: number;
  onComplete: () => void;
  onClose: () => void;
}

export const ARSQuoteDialog: React.FC<IARSQuoteProps> = (props) => {
  const { open, onComplete, customerPolicyId, onClose } = props;

  const [file, setFile] = React.useState<File | null>(null);
  const dispatch = useDispatchWrapper();
  const [code, setCode] = React.useState<string | null>(null);
  const [state, setState] = React.useState<IUploadPdfDocumentProcess | null>(
    null
  );
  const [error, setError] = React.useState<string | null>(null);

  const handleClick = () => {
    if (file) {
      dispatch(
        upsertARSQuote(file, customerPolicyId, (isSuccess, code, isAlready) => {
          if (isSuccess) {
          if(code){
            setCode(code);
          }
          else if(isAlready){
            onComplete();
          }
          }
        })
      );
    }
  };

  const fetchData = (proccessCode: string, interval?: any) => {
    dispatch(
      getUploadedPdfProccess(proccessCode, (isSuccess, data) => {
        if (isSuccess && data) {
          if (data.status === "COMPLETED") {
            onComplete();
            setState(data);

            clearInterval(interval);
          } else if (data.status === "FAILED") {
            setState(data);
            clearInterval(interval);
            setError(ERROR_MESSAGES.SERVER_ERROR);
          }
        }
      })
    );
  };

  React.useEffect(() => {
    let interval: any;
    if (code) {
      interval = setInterval(() => {
        fetchData(code, interval);
      }, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [code]);

  const renderData = () => {
    if (!state || state.status === "IN_PROGRESS") {
      return (
        <>
          {/* <Breadcrumb
					title="Extract Products"
					items={BCrumb}
				/> */}
          <ProgressCard
            heading="Your document is being processed....!"
            subText="This may take a few moments. We're retrieving data from your document."
          />
        </>
      );
    }
    if (state.status === "FAILED") {
      return (
        <>
          {/* <Breadcrumb
					title="Extract Products"
					items={BCrumb}
				/> */}
          <ProgressCard
            isError={true}
            heading={"There was an error processing your document."}
            subText={"Please try again later or check the document format."}
          />
        </>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="md"
      title="ARS Quote"
      actions={!code ? [
        {
          type: "button",
          variant: "contained",
          label: "Close",
          onClick: onClose,
        },
        {
          type: "button",
          variant: "contained",
          label: "Save",
          onClick: handleClick,
        },
      ]:[]}
    >
      {!code && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Upload File</Typography>
            <FileUpload
              accept="pdf"
              value={null}
              onChange={(newFile) => {
                setFile(newFile);
              }}
              onDelete={() => {
                setFile(null);
              }}
            />
          </Grid>
        </Grid>
      )}
      {code && renderData()}
    </Dialog>
  );
};
