import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalRiskLocationState {
  data: IHabitationalRiskLocation[];
  loading: LoadState;
  risklocation: IHabitationalRiskLocation;
  risklocationLoading: LoadState;
  error: string | null;
}

export interface IHabitationalInteriorWallConstruction {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitationalInteriorWallHeight {
  key: string;
  height: string | null;
  height_unit: string | null;
  percentage: string | null;
}
export interface IHabitationalInteriorFloorFinish {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitationalCeilingConstruction {
  key: string;
  type: string | null;
  percentage: string | null;
}
export interface IHabitatioanalUpgrades {
  key: string;
  upgrade_name: string;
  full_yy: string | null;
  partial_yy: string | null;
}

export interface IHabitationalDetachedOutbuildingStructure {
  key: string;
  sr_no: number | null;
  year: string | null;
  structure_type: string | null;
  exterior_wall_framing_type: string | null;
  heating_apparatus_type: string | null;
  fuel_type: string | null;
  total_area: string | null;
  total_area_type: string | null;
  value: string | null;
}

export interface IHabitationalRiskLocation {
  customer_policy_id: number | null;
  customer_id: number | null;
  incremental_number: number | null;
  created_from: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  status: string;
  risk_location_construction: {
    year_built: string | null;
    no_of_storeys: string | null;
    no_of_families: string | null;
    no_of_units: string | null;
    total_living_area: string | null;
    total_living_area_unit: string | null;
    access_type: string | null;
    smokers: string | null;
    replacement_cost_emulator_product: string | null;
    data_evaluation_completed: string;
    date_of_birth_of_eldest_occupant: string;
    relationship_to_applicant: string | null;
    occupancy_type: string | null;
    structure_type: string | null;
    foundation_type: string | null;
    finished_basement_percentage: string | null;
    exterior_wall_framing_type: string | null;
    exterior_wall_finish_type: string | null;
  };
  interior_wall_construction_type: IHabitationalInteriorWallConstruction[];
  interior_wall_height: IHabitationalInteriorWallHeight[];
  interior_wall_finish_type: IHabitationalInteriorFloorFinish[];
  ceiling_construction_type: IHabitationalCeilingConstruction[];
  risk_location_upgrades: IHabitatioanalUpgrades[];
  risk_location_roof_covering_type: string | null;
  risk_location_electrical_wiring_type: string | null;
  risk_location_electrical_panel_type: string | null;
  risk_location_service: string | null;

  primary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  auxiliary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
    no_of_face_cords_per_year: string | null;
    solid_fuel_heating_questionaire_attached: string | null;
    radiant_heating_area: string | null;
    radiant_heating_area_unit: string | null;
    make: string | null;
    year: string | null;
    oil_tank_year: string | null;
    oil_tank_year_type: string | null;
    fuel_oil_tank_questionaire_attached: string | null;
  };
  plumbing_type: {
    copper_percentage: string | null;
    gaivanized_percentage: string | null;
    abs_percentage: string | null;
    pvc_percentage: string | null;
    pex_percentage: string | null;
    poly_b_percentage: string | null;
    lead_percentage: string | null;
    other: string | null;
  };
  water_heater_type: {
    apparatus: string | null;
    water_heater_type: string | null;
    fuel: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  primary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
  };
  auxiliary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
    main_water_value_shut_off_type: string | null;
    no_of_main_water_value_shut_off_sensor: string | null;
    sewer_backeup_questionaire_attached: string | null;
  };
  fire_protection: {
    distance_to_hydrant: string | null;
    hydrant_type: string | null;
    distance_to_responding_fire_hall: string | null;
    fire_hall_name: string | null;
  };
  security_system: {
    fire: string | null;
    burglary: string | null;
    smoke_detectors: string | null;
    smoke_detector_type: string | null;
    no_of_detectors: string | null;
    if_any_of_the_above_are_monitored_monitored_by: string | null;
    home_sprinklered: string | null;
    alarm_certificate: boolean | null;
    premises_type_security_system: string | null;
  };
  bathrooms: {
    no_of_full: string | null;
    no_of_half: string | null;
  };
  kitchens: {
    no_of_kitchens: string | null;
    kitchen_1_quality: string | null;
    kitchen_2_quality: string | null;
  };
  garage_or_carport: {
    no_of_cars: string | null;
    garage_type: string | null;
  };
  swimming_pool: {
    year: string | null;
    pool_type: string | null;
    pool_fenced: string | null;
  };
  detached_outbuilding_stucture: IHabitationalDetachedOutbuildingStructure[];
  risk_location_questions: {
    do_you_own_rent_more_than_one_location: string | null;
    number_of_weeks_location_rented_to_others: string | null;
    number_of_rooms_rented_to_others: string | null;
    renewable_energy_installation_on_premises: string | null;
    number_of_full_residence_employees: string | null;
    number_of_dogs_in_the_household: string | null;
    breed_of_dogs: string | null;
    total_property_area: string | null;
  };

  liability_exposures: {
    daycare_operation_number_of_children: string | null;
    do_you_own_a_trampoline: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_have_a_garden_tractor: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_have_a_golf_cart: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    no_of_saddle_draft_animals: null;
    do_you_own_any_unlicensed_recreational_vehicles: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    do_you_own_any_watercrafts: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    is_there_a_co_occupant_that_requires_coverage: null;
    co_occupant_name: null;
    is_there_any_kind_of_business_operation: {
      questionCd: string | null;
      yesNoCd: string | null;
    };
    if_yes_describe_business: null;
    number_of_cannabis_plants_grown_on_premises: null;
    other_exposures: null;
    created_from: string | null;

  };
}

export const defaultHabitationalRiskLocationState: IHabitationalRiskLocationState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    risklocation: {
      created_from: null,
      customer_id: null,
      customer_policy_id: null,
      incremental_number: null,
      status: "ACTIVE",
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      risk_location_construction: {
        year_built: null,
        no_of_storeys: null,
        no_of_families: null,
        no_of_units: null,
        total_living_area: null,
        total_living_area_unit: null,
        access_type: null,
        smokers: null,
        replacement_cost_emulator_product: null,
        data_evaluation_completed: moment().format("YYYY-MM-DD"),
        date_of_birth_of_eldest_occupant: moment().format("YYYY-MM-DD"),
        relationship_to_applicant: null,
        occupancy_type: null,
        structure_type: null,
        foundation_type: null,
        finished_basement_percentage: null,
        exterior_wall_framing_type: null,
        exterior_wall_finish_type: null,
      },
      interior_wall_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      interior_wall_height: [
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
      ],
      interior_wall_finish_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      ceiling_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],

      risk_location_upgrades: [
        {
          key: getUniqueId(),
          upgrade_name: "Roof",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Electrical",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Heating",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Plumbing",
          full_yy: null,
          partial_yy: null,
        },
      ],
      risk_location_roof_covering_type: null,
      risk_location_electrical_wiring_type: null,
      risk_location_electrical_panel_type: null,
      risk_location_service: null,
      primary_heating_type: {
        apparatus: null,
        fuel: null,
        location: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
      },
      auxiliary_heating_type: {
        apparatus: null,
        fuel: null,
        location: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
        no_of_face_cords_per_year: null,
        solid_fuel_heating_questionaire_attached: null,
        radiant_heating_area: null,
        radiant_heating_area_unit: null,
        make: null,
        year: null,
        oil_tank_year: null,
        oil_tank_year_type: null,
        fuel_oil_tank_questionaire_attached: null,
      },
      plumbing_type: {
        copper_percentage: null,
        gaivanized_percentage: null,
        abs_percentage: null,
        pvc_percentage: null,
        pex_percentage: null,
        poly_b_percentage: null,
        lead_percentage: null,
        other: null,
      },
      water_heater_type: {
        apparatus: null,
        water_heater_type: null,
        fuel: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
      },
      primary_water_mitigation_type: {
        sump_pump_type: null,
        auxiliary_power: null,
        backup_value: null,
      },
      auxiliary_water_mitigation_type: {
        sump_pump_type: null,
        auxiliary_power: null,
        backup_value: null,
        main_water_value_shut_off_type: null,
        no_of_main_water_value_shut_off_sensor: null,
        sewer_backeup_questionaire_attached: null,
      },
      fire_protection: {
        distance_to_hydrant: null,
        hydrant_type: null,
        distance_to_responding_fire_hall: null,
        fire_hall_name: null,
      },
      security_system: {
        fire: null,
        burglary: null,
        smoke_detectors: null,
        smoke_detector_type: null,
        no_of_detectors: null,
        if_any_of_the_above_are_monitored_monitored_by: null,
        home_sprinklered: null,
        alarm_certificate: null,
        premises_type_security_system: null,
      },
      bathrooms: {
        no_of_full: null,
        no_of_half: null,
      },

      kitchens: {
        no_of_kitchens: null,
        kitchen_1_quality: null,
        kitchen_2_quality: null,
      },

      garage_or_carport: {
        no_of_cars: null,
        garage_type: null,
      },

      swimming_pool: {
        year: null,
        pool_type: null,
        pool_fenced: null,
      },
      detached_outbuilding_stucture: [
        {
          key: getUniqueId(),
          sr_no: 1,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
        {
          key: getUniqueId(),
          sr_no: 2,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
        {
          key: getUniqueId(),
          sr_no: 3,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
      ],
      risk_location_questions: {
        do_you_own_rent_more_than_one_location: null,
        number_of_weeks_location_rented_to_others: null,
        number_of_rooms_rented_to_others: null,
        renewable_energy_installation_on_premises: null,
        number_of_full_residence_employees: null,
        number_of_dogs_in_the_household: null,
        breed_of_dogs: null,
        total_property_area: null,
      },
      liability_exposures: {
        created_from: null,
        daycare_operation_number_of_children: "",
        do_you_own_a_trampoline: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_garden_tractor: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_have_a_golf_cart: {
          questionCd: null,
          yesNoCd: null,
        },
        no_of_saddle_draft_animals: null,
        do_you_own_any_unlicensed_recreational_vehicles: {
          questionCd: null,
          yesNoCd: null,
        },
        do_you_own_any_watercrafts: {
          questionCd: null,
          yesNoCd: null,
        },
        is_there_a_co_occupant_that_requires_coverage: null,
        co_occupant_name: null,
        is_there_any_kind_of_business_operation: {
          questionCd: null,
          yesNoCd: null,
        },
        if_yes_describe_business: null,
        number_of_cannabis_plants_grown_on_premises: null,
        other_exposures: null,
      },
    },
    risklocationLoading: LoadState.NotLoaded,
    error: null,
  };
