/* eslint-disable react-hooks/exhaustive-deps */
import { SelectChangeEvent, SelectProps } from "@mui/material";
import React from "react";
import { ControlledCustomSelect } from "../formsComponents";
import { ISelectOption } from "../../constants/types";
import { api } from "../../api/api";
import { IPolicyCommission } from "../../redux/policyCommission/policyCommission.types";

interface IComissionCodeSelect {
  value: any;
  insurerCode: string | null;
  onChange: (newValue: IPolicyCommission | null) => void;
}

export const ComissionCodeSelect: React.FC<IComissionCodeSelect> = (props) => {
  const { value, insurerCode, onChange } = props;
  const [state, setState] = React.useState<IPolicyCommission[]>([]);

  const fetchList = async () => {
    try {
      const res = await api.get(
        `insurance/get-policy-commission?status=ACTIVE&column=insurer_code_with_name&value=${insurerCode}`
      );
      const data: IPolicyCommission[] = res.data.data;
      setState(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const item = state.find(x=>x.policy_code === value);
    if(item){
      onChange(item);
    }
    else {
      onChange(null)
    }
  }

  React.useEffect(() => {
    if (!insurerCode) {
      setState([]);
      onChange(null);
    } else {
      fetchList();
    }
  }, [insurerCode]);

  const list = React.useMemo(() => {
    return state
      .filter((x) => x.policy_code)
      .map((comission) => {
        return {
          label: `${comission.policy_code} (${comission.policy_code}) ${comission.description ? "- "+comission.description:  ""}`,
          value: comission.policy_code,
        };
      });
  }, [state]);

  return (
    <>
      <ControlledCustomSelect
        variant="outlined"
        size="small"
        type="string"
        fullWidth
        displayEmpty
        value={value}
        onChange={handleChange}
        placeholder="Select One"
        options={list}
      />
    </>
  );
};
