import { Autocomplete, CircularProgress } from "@mui/material";
import React from "react";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { debounce } from "lodash";
import { api } from "../../api/api";
import {

  IInterestedPartySearchesProps,
  IInterestedPartySearchProps,
} from "./AutoCompleteSearches.types";
import axios from "axios";
import { IBusinessPolicyInterested } from "../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterested.types";
import { IHabitationalInterested } from "../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";


/* eslint-disable react-hooks/exhaustive-deps */

export const InterestedPartyCodeSearchSelect: React.FC<
  IInterestedPartySearchesProps
> = (props) => {
  const { value, onSelect, label, disabled } = props;
  const [options, setOptions] = React.useState<
    readonly IBusinessPolicyInterested[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const initialLoad = React.useRef(false);

  const fetchSuggestion = async (
    value: string,
    isById: boolean,
    cancelToken: any
  ) => {
    setLoading(true);
    try {
      let url = `/insurance/get-interested-party?column=interested_party_code&column=interested_party_name&value=${value}`;
      
      const res = await api.get(url, {
        cancelToken: cancelToken,
      });
      const data: IBusinessPolicyInterested[] = res.data.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: IBusinessPolicyInterested) => {

    return `${option.interested_party_name || ""} (${option.interested_party_code})`;
  };
  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (search && search !== value) {
      debounceFn(search, false, cancelTokenSource.token);
    }
    // Cleanup function to cancel the request when the component unmounts
    return () => {
      cancelTokenSource.cancel("Operation canceled due to component unmount.");
    };
  }, [search]);

  React.useEffect(() => {
    if (value && !initialLoad.current) {
      debounceFn(value, true, null);
    }
  }, [value]);

  return (
    <>
      {label && (<CustomFormLabel>{label}</CustomFormLabel>)}
      <Autocomplete
        id="google-map-demo"
        fullWidth
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
           
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        getOptionLabel={getOptionLabel}
        // isOptionEqualToValue={(option, value) =>
        //   typeof option === "string"
        //     ? option === value
        //     : //@ts-ignore
        //       option.interested_party_code === value.interested_party_code
        // }
        // filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={
          options.find((option) => option.interested_party_code === value) ||
          null
        } // Set the selected value based on the selectedId
        loading={loading}
        disabled={disabled}
        noOptionsText="Type to search"
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: IBusinessPolicyInterested | null
        ) => {
          setOptions(newValue ? [ ...options] : options);
          if (newValue) {
            onSelect(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
            initialLoad.current = true;
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};


export const InterestedPartyCodeSearchesSelect: React.FC<
  IInterestedPartySearchProps
> = (props) => {
  const { value, onSelect, label, disabled , fullWidth} = props;
  const [options, setOptions] = React.useState<
    readonly IHabitationalInterested[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const initialLoad = React.useRef(false);

  const fetchSuggestion = async (
    value: string,
    isById: boolean,
    cancelToken: any
  ) => {
    setLoading(true);
    try {
      let url = `/insurance/get-interested-party?column=interested_party_code&column=interested_party_name&value=${value}`;
      
      const res = await api.get(url, {
        cancelToken: cancelToken,
      });
      const data: IHabitationalInterested[] = res.data.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: IHabitationalInterested) => {

    return `${option.interested_party_name || ""} (${option.interested_party_code})`;
  };
  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (search && search !== value) {
      debounceFn(search, false, cancelTokenSource.token);
    }
    // Cleanup function to cancel the request when the component unmounts
    return () => {
      cancelTokenSource.cancel("Operation canceled due to component unmount.");
    };
  }, [search]);

  React.useEffect(() => {
    if (value && !initialLoad.current) {
      debounceFn(value, true, null);
    }
  }, [value]);

  return (
    <>
      {label && <CustomFormLabel>{label}</CustomFormLabel>}
      <Autocomplete
        id="google-map-demo"
        fullWidth={fullWidth}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        getOptionLabel={getOptionLabel}
        // isOptionEqualToValue={(option, value) =>
        //   typeof option === "string"
        //     ? option === value
        //     : //@ts-ignore
        //       option.interested_party_code === value.interested_party_code
        // }
        // filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={
          options.find((option) => option.interested_party_code === value) ||
          null
        } // Set the selected value based on the selectedId
        loading={loading}
        disabled={disabled}
        noOptionsText="Type to search"
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: IHabitationalInterested | null
        ) => {
          setOptions(newValue ? [ ...options] : options);
          if (newValue) {
            onSelect(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
            initialLoad.current = true;
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
