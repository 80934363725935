import moment from "moment";
import { LoadState } from "../../constants/enums";


export interface IPolicyChangeRequestState {
  list: IPolicyChangeRequest[];
  loading: LoadState;
  error: string | null;
}

export interface IPolicyChangeRequest {
  incremental_number: number | null;
  customer_id: number | null;
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  policy_type: string | null;
  branch_code: string | null;
  producer_one_code: string | null;
  policy_status: string | null;
  policy_number: string | null;
  insurer: string | null;
  policy_effective_date: string | null;
  effective_time: string | null;
  expiry_date: string | null;
  type: string | null;
  sub_type: string | null;
  date_change: string | null;
  time_change: string | null;
  date_created: string | null;
  effective_date: string | null;
  days_left: string | null;
  due_date: string | null;
  date_completed: string | null;
  assigned_to_id: string | null;
  status: string;
}

export const initialPolicyChangeReqState: IPolicyChangeRequestState =
  {
    list: [],
    loading: LoadState.NotLoaded,
    error: null,
  };
