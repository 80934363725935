import React from "react";
import { Grid } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { IBusinessPolicyChangeStepsProps } from "../PolicyChange.types";

export const CreditCardInformation: React.FC<
  IBusinessPolicyChangeStepsProps
> = ({ values, setValues, errors, handleChange, setFieldValue }) => {
  const handleCCChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 16) {
      setFieldValue("credit_card_info.cc", value);
    }
  };

  return (
    <>
      <LabelCard heading="Credit Card Infomation" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>CC</CustomFormLabel>
          <CustomTextField
            fullWidth
            type="number"
            id="credit_card_info.cc"
            value={values.credit_card_info.cc}
            onChange={handleCCChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Expiry</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            inputFormat="MM/YYYY"
            views={["month", "year"]}
            value={values.credit_card_info.expiry || ""}
            onChange={(newValue) => {
              setFieldValue("credit_card_info.expiry", newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>CVV</CustomFormLabel>
          <CustomTextField
            type="number"
            fullWidth
            id="credit_card_info.cvv"
            value={values.credit_card_info.cvv}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Account Holder Name</CustomFormLabel>
          <CustomTextField
            type="string"
            fullWidth
            id="credit_card_info.account_holder_name"
            value={values.credit_card_info.account_holder_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Bank Name</CustomFormLabel>
          <CustomTextField
            type="string"
            fullWidth
            id="credit_card_info.bank_name"
            value={values.credit_card_info.bank_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Account Number</CustomFormLabel>
          <CustomTextField
            type="string"
            fullWidth
            id="credit_card_info.account_number"
            value={values.credit_card_info.account_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <CustomFormLabel>Institution Number</CustomFormLabel>
          <CustomTextField
            type="string"
            fullWidth
            id="credit_card_info.institution_number"
            value={values.credit_card_info.institution_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <CustomFormLabel>Transit Number</CustomFormLabel>
          <CustomTextField
            type="string"
            fullWidth
            id="credit_card_info.transit_number"
            value={values.credit_card_info.transit_number}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
