import React from "react";
import { api } from "../api/api";
import { IPolicy } from "../redux/allPolicies/Policies.types";
import { useDispatchWrapper } from "./useDispatch";
import { showMessage } from "../redux/messages/messagesActions";

export const usePoliciesByCustomer = () => {
  const [policiesList, setPolicies] = React.useState<IPolicy[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const fetchPoliciesByCustomerId = async (
    customerId: number,
    policyType?: string
  ) => {
    try {
      setLoading(true);
      let finalUrl = `/policy/get-all-customer-policy-details?customer_id=${customerId}`;
      if (policyType !== undefined && policyType) {
        finalUrl += `&line_of_business=${policyType}`;
      }
      const res = await api.get(finalUrl);
      const policiesList: IPolicy[] = res.data.data || [];

      setPolicies(policiesList.filter((x) => x.policy_number !== null));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const fetchPoliciesByCustomerPolicyId = async (customerPolicyId: number) => {
    try {
      setLoading(true);
      const res = await api.get(
        `/policy/get-all-customer-policy-details?customer_policy_id=${customerPolicyId}`
      );
      const policiesList: { [key: string]: any } = res.data.data || [];

      return policiesList;
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPolicyOtherInfoByPolicyId = async (customerPolicyId: number) => {
    try {
      setLoading(true);
      const res = await api.get(
        `/policy/get-policy-data-for-invoice?customer_policy_id=${customerPolicyId}`
      );
      const policiesList: { [key: string]: any } = res.data.data || {};

      return policiesList;
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      return {};
    } finally {
      setLoading(false);
    }
  };

  return {
    policiesList,
    fetchPoliciesByCustomerId,
    policiesLoading: loading,
    fetchPoliciesByCustomerPolicyId,
    fetchPolicyOtherInfoByPolicyId,
  };
};
