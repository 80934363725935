import React from "react";
import { RightPanel } from "../../../../../../components/RightPanel";
import { RiskLocation } from "./RiskLocation";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";

interface IRiskLocationRightPanelProps {
  open: boolean;
  customerId?: number;
  customerPolicyId?: number;
  riskLocationNumber?: number;
  onSaveSuccess: (riskLocation: IBusinessPolicyRiskLocation) => void;
  staticStatusValue?: string;
  created_from?: string;
  readonly?: boolean;
  onClose: () => void;
  initialData?: IBusinessPolicyRiskLocation;
    disableApiCalls?: boolean;
}

export const RiskLocationRightPanel: React.FC<IRiskLocationRightPanelProps> = (
  props
) => {
  const {
    customerId,
    customerPolicyId,
    riskLocationNumber,
    onClose,
    onSaveSuccess,
    readonly,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls,
  } = props;

  return (
    <>
      <RightPanel
        heading="Risk Location"
        //subHeading="Add notes or comments"
        open={true}
        onClose={onClose}
        width="80%"
      >
        <RiskLocation
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          riskLocationNumber={riskLocationNumber}
          onSaveSuccess={onSaveSuccess}
          readOnly={readonly}
          fromRightPanel
          staticStatusValue={staticStatusValue}
          created_from={created_from}
          initialData={initialData}
          disableApiCalls={disableApiCalls}
        />
      </RightPanel>
    </>
  );
};
