/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearHabitationalScheduleDetail, fetchHabitationalScheduleDetailListAsync } from "../../../../../../redux/habitationalPolicy/habitationalPropertySchedule/habitationalPropertyScheduleActions";

export const useHabitationalSchedule = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();

  const scheduleList = useSelector(
    (storeState: IStoreState) => storeState.habitational.schedule.schedule_detail.list
  );
  const scheduleDropdown = React.useMemo(() => {
    return scheduleList.map((item) => {
      return {
        label: item.incremental_number +` (Property Number : ${item.property_number})`,
        value: item.incremental_number || "",
      };
    });
  }, [scheduleList]);



    React.useEffect(() => {
      if (customerPolicyId) {
        dispatch(fetchHabitationalScheduleDetailListAsync(Number(customerPolicyId)));
      }
      return(()=>{
        dispatch(clearHabitationalScheduleDetail());
      })
    }, []);
  


  return { scheduleDropdown };
};
