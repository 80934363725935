/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { fetchCommAutomobileCustomerInfoAsync } from "../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { LoadState } from "../../../../../../constants/enums";
import {
  downloadCommAutomobileAuthorizationPDFAsync,
  downloadCommAutomobileConsentFormPDFAsync,
  downloadCommAutomobileLiabilityInsurancePDFAsync,
  downloadCommAutomobileLiabilitySlipsPDFAsync,
  downloadCommAutomobileOcep16PDFAsync,
  downloadCommAutomobileOcep17PDFAsync,
  downloadCommAutomobileOcep28AListPDFAsync,
  downloadCommAutomobileRetireeDiscountPDFAsync,
  downloadCommAutomobileSharingFillablePDFAsync,
  fetchCommAutoPolicyFormsListAsync,
  previewCommAutomobileAuthorizationPDFAsync,
  previewCommAutomobileConsentFormPDFAsync,
  previewCommAutomobileLiabilityInsurancePDFAsync,
  previewCommAutomobileLiabilitySlipsPDFAsync,
  previewCommAutomobileOcep16PDFAsync,
  previewCommAutomobileOcep17PDFAsync,
  previewCommAutomobileOcep28AListPDFAsync,
  previewCommAutomobileRetireeDiscountPDFAsync,
  previewCommAutomobileSharingFillablePDFAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoPolicyForms/commAutoPolicyFormsActions";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import {
  alpha,
  Button,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  styled,
} from "@mui/material";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutoPolicyForm } from "../../../../../../redux/commAutoPolicy/commAutoPolicyForms/commAutoPolicyForm.types";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CancellationDialog } from "../../Cancellation/CancellationDialog";
import { formatText } from "../../../../../../helpers";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { useDataTableV2Pagination } from "../../../../../../components/TableV2/hooks/useDataTableV2Pagination";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import {
  downloadPoliciesCancellationListPDFAsync,
  previewPoliciesCancellationListPDFAsync,
} from "../../../../../../redux/tasks/tasksActions";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const POLICY_FORM_TYPES = [
  { label: "All Forms", value: "all_forms" },
  { label: "Liability Slips", value: "liability_slips" },
  {
    label: "Certificate of Liability Insurance",
    value: "certificate_of_liability_insurance",
  },
  { label: "OPCF-16", value: "opcf_sixteen" },
  { label: "OPCF-17", value: "opcf_seventeen" },
  { label: "OPCF-28A", value: "opcf_twenty_eight_a" },
  { label: "Payment Authorization Form", value: "payment_authorization" },
  {
    label: "Cancellation or Reinstatement",
    value: "cancellation_or_reinstatement",
  },
  { label: "Privacy Consent", value: "privacy_consent" },
  { label: "Retiree Discount", value: "retiree_discount" },
  { label: "Ride Sharing", value: "ride_sharing" },
];

export const CommAutoPolicyForms: React.FC = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const [downloading, setDownloading] = React.useState(false);

  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.policyForms.policyFormsList
  );
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const { tablePagination, onPageChange, onRowsPerPageChange } =
    useDataTableV2Pagination({
      pageNumber: 1,
      rowsInPerPage: 25,
      extraFetchFactor: 1,
    });
  const [policyFormRoot, setPolicyFormRoot] = React.useState("all_forms");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openPolicyCancel, setOpenPolicyCancel] =
    React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePolicyCancelNextStep = (taskCode: string) => {
    navigate(
      `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/cancellation/${taskCode}`
    );
  };

  const handleFormTypeClick =
    (
      value: string,
      actionType?: "navigate" | "download" | "preview",
      incrementNumber?: any,
      taskCode?: string
    ) =>
    () => {
      setAnchorEl(null);
      let additionalparms = "";
      if (incrementNumber) {
        additionalparms = "/" + incrementNumber;
      }

      switch (value) {
        case "liability_slips": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-slips${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileLiabilitySlipsPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileLiabilitySlipsPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "certificate_of_liability_insurance": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/liability-insurance${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileLiabilityInsurancePDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileLiabilityInsurancePDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "opcf_sixteen": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-16${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileOcep16PDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileOcep16PDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "opcf_seventeen": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-17${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileOcep17PDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileOcep17PDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "opcf_twenty_eight_a": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/opcf-28A${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileOcep28AListPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileOcep28AListPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "payment_authorization": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/authorization${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileAuthorizationPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileAuthorizationPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "cancellation_or_reinstatement": {
          if (incrementNumber) {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/cancellation${additionalparms}`
            );
          } else if (actionType === "download" && taskCode) {
            dispatch(downloadPoliciesCancellationListPDFAsync(taskCode));
          } else if (actionType === "preview" && taskCode) {
            dispatch(previewPoliciesCancellationListPDFAsync(taskCode));
          } else {
            setOpenPolicyCancel(true);
          }

          return;
        }
        case "privacy_consent": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/consent-form${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileConsentFormPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileConsentFormPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "retiree_discount": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/retiree-discount${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileRetireeDiscountPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileRetireeDiscountPDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
        case "ride_sharing": {
          if (actionType === "navigate") {
            navigate(
              `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}/sharing-fillable${additionalparms}`
            );
          } else if (actionType === "download") {
            setDownloading(true);
            dispatch(
              downloadCommAutomobileSharingFillablePDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {
                  setDownloading(false);
                }
              )
            );
          } else if (actionType === "preview") {
            dispatch(
              previewCommAutomobileSharingFillablePDFAsync(
                customerPolicyId,
                incrementNumber,
                () => {}
              )
            );
          }
          return;
        }
      }
    };

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSearchState,
    setSelectedTab,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last90Days",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    dispatch(
      fetchCommAutoPolicyFormsListAsync(
        customerPolicyId,
        policyFormRoot,
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage
      )
    );
  };

  const commAutoPolicyFormDataProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    extraFetchFactor: tablePagination.extraFetchFactor,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "none",
    uniqueRowKeyName: "incemental_number",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                sx={{ minWidth: "120px" }}
                value={policyFormRoot}
                options={POLICY_FORM_TYPES}
                onChange={(e) => setPolicyFormRoot(e.target.value as string)}
              ></ControlledCustomSelect>
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutoPolicyForm) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onEditClick={handleFormTypeClick(
                row.policy_form_type,
                "navigate",
                row.policy_form_type === "cancellation_or_reinstatement" ? row.task_code  :  row.incremental_number
              )}
              onDownLoadClick={handleFormTypeClick(
                row.policy_form_type,
                "download",
                row.incremental_number,
                row.task_code
              )}
              onDownloadPreview={handleFormTypeClick(
                row.policy_form_type,
                "preview",
                row.incremental_number,
                row.task_code
              )}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutoPolicyForm) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "type",
        headerName: "Policy Form Type",
        fieldName: "policy_form_type",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        onRowCellValueRender: (value, row) => {
          return formatText(value);
        },
      },
      {
        key: "create_ts",
        headerName: "Date Created",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 30,
      },
      {
        key: "created_by_name",
        headerName: "Created By",
        fieldName: "created_by_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
      },
    ],
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "create_ts",
      },
    },
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      fetchList();
    }
  }, [customerPolicyId, tablePagination, policyFormRoot]);

  return (
    <>
      <PageLoader
        loading={customerInfo.loading === LoadState.InProgress}
        error={customerInfo.error ? { message: customerInfo.error } : null}
      >
        <PageTitleBar
          heading="Policy Forms"
          rightHeading={
            <Stack>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Create
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {POLICY_FORM_TYPES.slice(1).map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={handleFormTypeClick(item.value, "navigate")}
                      disableRipple
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </StyledMenu>
            </Stack>
          }
        />

        <DataTableV2 {...commAutoPolicyFormDataProps} />
        {openPolicyCancel && customerId && customerPolicyId && (
          <CancellationDialog
            customerPolicyId={customerPolicyId}
            open={openPolicyCancel}
            onClose={() => setOpenPolicyCancel(false)}
            changeNextStep={handlePolicyCancelNextStep}
          />
        )}
      </PageLoader>
    </>
  );
};
