import { action } from "typesafe-actions";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IOldInsuranceReportsList } from "./oldInsuranceReport.types";

export const FETCH_OLD_INSURANCE_REPORTS_LIST_PROGRESS =
  "FETCH_OLD_INSURANCE_REPORTS_LIST_PROGRESS";
export const FETCH_OLD_INSURANCE_REPORTS_LIST_SUCCESS =
  "FETCH_OLD_INSURANCE_REPORTS_LIST_SUCCESS";
export const FETCH_OLD_INSURANCE_REPORTS_LIST_FAILED =
  "FETCH_OLD_INSURANCE_REPORTS_LIST_FAILED";

export const fetchOldInsuranceReportsListProgress = () =>
  action(FETCH_OLD_INSURANCE_REPORTS_LIST_PROGRESS);
export const fetchOldInsuranceReportsListSuccess = (
  list: IOldInsuranceReportsList[]
) => action(FETCH_OLD_INSURANCE_REPORTS_LIST_SUCCESS, { list });
export const fetchOldInsuranceReportsListFailed = () =>
  action(FETCH_OLD_INSURANCE_REPORTS_LIST_FAILED);

export const fetchOldInsuranceReportsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOldInsuranceReportsListProgress());
      let finalUrl = `/other/get-old-insurance-reports?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/other/get-old-insurance-reports?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IOldInsuranceReportsList[] = res.data.data;

      dispatch(fetchOldInsuranceReportsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchOldInsuranceReportsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_OLD_INSURANCE_REPORTS = "CLEAR_OLD_INSURANCE_REPORTS";

export const clearInsurerState = () => action(CLEAR_OLD_INSURANCE_REPORTS);
