import { IBranchOfficeState } from "./branchOffice/branchOffice.types";
import { initialBranchOfficeState } from "./branchOffice/branchOfficeState";
import { IBrokerCodeState } from "./brokerCode/brokerCode.types";
import { initialBrokerCodeState } from "./brokerCode/brokerCodeState";
import {
  IBusinessPolicyRiskLocationState,
  defaultBusinessPolicyRiskLocationState,
} from "./businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import {
  IBusinessPolicyAdditionalState,
  defaultBusinessPolicyAdditionalState,
} from "./businessPolicy/businessPolicyAdditional/businessPolicyAdditional.types";
import {
  IBusinessPolicyCoverageState,
  defaultBusinessPolicyCoverageState,
} from "./businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import {
  IBusinessPolicyEquipmentState,
  defaultBusinessPolicyEquipmentState,
} from "./businessPolicy/businessPolicyEquipment/businessPolicyEquipment.types";
import {
  IBusinessPolicyFineArtState,
  defaultBusinessPolicyFineArtState,
} from "./businessPolicy/businessPolicyFineArt/businessPolicyFineArt.types";
import {
  IBusinessPolicyInterestedState,
  defaultBusinessPolicyInterestedState,
} from "./businessPolicy/businessPolicyInterested/businessPolicyInterested.types";
import {
  IBusinessPolicyPremiumFinanceState,
  defaultBusinessPolicyPremiumFinanceState,
} from "./businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinance.types";
import {
  IBusinessPolicySubscriptionState,
  defaultBusinessPolicySubscriptionState,
} from "./businessPolicy/businessPolicySubscription/businessPolicySubscription.types";
import {
  IBusinessPolicyToolFloaterState,
  defaultBusinessPolicyToolFloaterState,
} from "./businessPolicy/businessPolicyToolFloater/businesPolicyToolFloater.types";
import {
  IBusinessPolicyUnderLyingState,
  defaultBusinessPolicyUnderLyingState,
} from "./businessPolicy/businessPolicyUnderLyingInsurance/businessPolicyUnderLyingInsurance.types";
import {
  IBusinessPolicyUnderWritingState,
  defaultBusinessPolicyUnderWritingState,
} from "./businessPolicy/businessPolicyUnderWriting/businessPolicyUnderWriting.types";
import { ICsioComapnyState } from "./csioCompany/csioCompany.types";
import { initialCsioCompanyReducerState } from "./csioCompany/csioCompanyState";
import { ICustomer } from "./customer/customer.types";
import { initialCustomerState } from "./customer/customerState";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IInsurerState } from "./insurer/insurer.types";
import { initialInsurerState } from "./insurer/insurerState";
import { IInsurerContactsState } from "./insurerContacts/insurerContacts.types";
import { initialInsurerContactsState } from "./insurerContacts/insurerContactsState";
import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
import {
  IBusinessBasicDetailsState,
  initialBasicDetails,
} from "./businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetails.types";
import { IPolicyCommissionState } from "./policyCommission/policyCommission.types";
import { initialPolicyCommissioneState } from "./policyCommission/policyCommissionState";
import { ISubInsurerState } from "./subInsurer/subInsurer.types";
import { initialSubInsurerState } from "./subInsurer/subInsurerState";
import { IUnderWriter } from "./underWriter/underWriter.types";
import { initialUnderWriterState } from "./underWriter/underWriterState";
import {
  IAutomobileBasicDetailsState,
  initialAutomobileDetails,
} from "./automobileBasicDetails/automobileBasicDetails.types";
// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { ICommonState, defaultCommonState } from "./common/common.types";
import {
  IAutomobileGaragePlatesState,
  defaultAutomobileGaragePlatesState,
} from "./automobileGaragePlates/automobileGaragePlates.types";
import {
  IAutomobileVehicleState,
  initialAutomoblieVehicleInformation,
} from "./automobileVehicle/automobileVehicle.types";
import {
  IAutomobileCSVState,
  initialAutomoblieCSV,
} from "./automobileCSV/automobileCSV.types";
import {
  IAutomobileDriverState,
  initialAutomoblieDriverInformation,
} from "./automobileDriver/automobileDriver.types";
import {
  IAutomobileScheduleState,
  initialAutomoblieSchedule,
} from "./automobileSchedule/automobileSchedule.types";
import {
  IHabitationalBasicDetailsState,
  initialHabitationalDetails,
} from "./habitationalPolicy/habitationalBasicDetails/habitationalBasicDetails.types";
import {
  IHabitationalClaimState,
  initialHabitationalClaimDetails,
} from "./habitationalPolicy/habitationalClaim/habitationalClaim.types";
import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";
// import { ITaskState, initialTaskState } from "./tasks/task.types";
import {
  ITaskCalenderState,
  initialTaskCalenderState,
} from "./tasksCalender/tasksCalender.types";
import {
  IGeneralChangeState,
  initialGeneralState,
} from "./generalChange/generalChange.types";
import { ITaskState, initialTaskState } from "./tasks/task.types";
import {
  ITaskDriverState,
  initialTaskDriverState,
} from "./TaskReducer/Driver/driver.types";
import {
  ITaskVehicleState,
  initialTaskVehicleState,
} from "./TaskReducer/Vehicle/taskVehicle.types";
import {
  ITaskGaragePlateState,
  initialTaskGaragePlateState,
} from "./TaskReducer/GaragePlate/garagePlate.types";
import {
  ITaskInterestedPartyState,
  initialTaskInterestedPartyState,
} from "./TaskReducer/InterestedParty/interestedParty.types";
import {
  ITaskLessorState,
  initialTaskLessorState,
} from "./TaskReducer/Lessor/lessor.types";
import {
  ITaskRiskLocationState,
  initialTaskRiskLocationState,
} from "./TaskReducer/RiskLocation/taskriskLocation.types";
import {
  IInsuranceReportsState,
  initialInsuranceReportsState,
} from "./insuranceReports/insuranceReports.types";
import {
  IOpportunityState,
  inititalOpportunityState,
} from "./opportunites/opportunites.types";
import { ILeadsState, initialLeadState } from "./leads/leads.types";
import {
  IBusinessPolicyRestrutantState,
  initialBusinessPolicyRestrutantState,
} from "./businessPolicy/businessPolicyRestrutant/businessPolicyRestrutant.types";
import {
  IBusinessPolicyContractorState,
  initialBusinessPolicyContractorState,
} from "./businessPolicy/businessPolicyContractor/businessPolicyContractor.type";
import {
  IBusinessPolicyHistoryState,
  initialBusinessPolicyHistoryState,
} from "./businessPolicy/businessPolicyHistory/businessPolicyHistory.types";
import {
  IBusinessPolicyMarketingState,
  initialBusinessPolicyMarketing,
} from "./businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import {
  ILifeInsuranceBasicDetailsState,
  initialLifeInsuranceBasicDetails,
} from "./lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetail.types";
import {
  IAutomobileMarketingHistoryState,
  initialAutomobileMarketingHistoryState,
} from "./automobileMarketingHistory/automobileMarketingHistory.types";


import {
  IAutomobileCertificatesState,
  initialAutomobileCertificatesState,
} from "./automobileCertificates/automobileCertificates.types";

import {
  IAutomobileApplicationState,
  initialAutomobileApplicationState,
} from "./automobileApplication/automobileApplication.types";
import {
  ILifeInsurancePolicyState,
  defaultLifeInsurancePolicy,
} from "./lifeInsurancePolicy/lifeInsurancePolicies/lifeInsurancePolicies.types";
import {
  ILifeInsurancePolcyComplianceCheckListState,
  defaultLifeInsurancePolicyComplianceCheckList,
} from "./lifeInsurancePolicy/lifeInsurancePolicyComplianceCheckList/lifeInsurancePolicyComplianceCheckList.types";
import {
  IlifeInsurancePolicyExistingPropertyState,
  defaultlifeInsurancePolicyExistingProperty,
} from "./lifeInsurancePolicy/lifeInsurancePolicyExistingProperty/lifeInsurancePolicyExistingProperty.types";
import {
  ILifeInsurancePolicyFamilyTreeState,
  defaultLifeInsurancePolicyFamilyTree,
} from "./lifeInsurancePolicy/lifeInsurancePolicyFamilyTree/lifeInsurancePolicyFamilyTree.types";
import {
  IAutomobileSupplementState,
  initialAutomobileSupplement,
} from "./automobileSupplement/automobileSupplement.types";
import {
  ILifeInsuranceCoverageNeedState,
  defaultLifeInsuranceCoverageNeed,
} from "./lifeInsurancePolicy/lifeInsuranceCoverageNeed/lifeInsuranceCoverageNeed.types";
import {
  IAutomobileGarageApplicationState,
  defaultAutomobileGarageApplicationState,
} from "./automobileGarageApplication/automobileGarageApplication.types";
import {
  ICommAutomobileBasicDetailsState,
  initialCommAutomobileDetails,
} from "./commAutoPolicy/commAutoBasicDetails/commAutoBasicDetails.types";
import {
  ICommAutomobileCertificatesState,
  initialCommAutomobileCertificatesState,
} from "./commAutoPolicy/commAutoCertificates/commAutoCertificates.types";
import {
  ICommAutomobileCSVState,
  initialCommAutomoblieCSV,
} from "./commAutoPolicy/commAutoCSV/commAutoCSV.types";
import {
  ICommAutomobileDriverState,
  initialCommAutomoblieDriverInformation,
} from "./commAutoPolicy/commAutoDriver/commAutoDriver.types";
import {
  ICommAutomobileGaragePlatesState,
  defaultCommAutomobileGaragePlatesState,
} from "./commAutoPolicy/commAutoGaragePlates/commAutoGaragePlates.types";
import {
  ICommAutomobileMarketingHistoryState,
  initialCommAutomobileMarketingHistoryState,
} from "./commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistory.types";

import {
  ICommAutomobileScheduleState,
  initialCommAutomoblieSchedule,
} from "./commAutoPolicy/commAutoSchedule/commAutoSchedule.types";
import {
  ICommAutomobileSupplementState,
  initialCommAutomobileSupplement,
} from "./commAutoPolicy/commAutoSupplement/commAutoSupplement.types";
import {
  ICommAutomobileVehicleState,
  initialCommAutomoblieVehicleInformation,
} from "./commAutoPolicy/commAutoVehicle/commAutoVehicle.types";

import {
  IAllApplicationState,
  initialAllApplicationState,
} from "./allApplications/allApplications.types";
import {
  ITemplateState,
  initialTemplateState,
} from "./templates/templates.types";
import { IBinderState, defaultBindersState } from "./Binder/binder.types";
import {
  IMarketingState,
  defaultMarketingsState,
} from "./marketing/marketing.types";
import {
  INewRemarketState,
  defaultNewRemarket,
} from "./NewRemarket/NewRemarket.types";
import {
  IIntrestedPartiesState,
  initialIntrestedPartiesState,
} from "./InterestedParties/IntrestedParties.types";
import {
  IAdditionalInterestState,
  initialAdditionalInterestState,
} from "./AdditionalInterest/additionalInterest.types";
import {
  ITaskPaymentMethodsState,
  initialPaymentMethodsState,
} from "./taskPaymentMethod/taskPaymentMethods.types";
import {
  ITaskUrgentTransactionsState,
  initialTaskurgentTransactionsState,
} from "./taskUrgentTransactions/taskUrgentTransactions.types";
import {
  ITaskBoardState,
  initialTaskBoardState,
} from "./taskBoard/taskBoard.types";
import {
  ISecurityState,
  initialSecurityState,
} from "./security/security.types";
import { ILegalsState, initialLegalState } from "./legals/legals.types";
import { INoticesState, initialNoticesState } from "./notices/notices.types";
import { IEDocsState, initialEDocsState } from "./eDocs/eDocs.types";
import {
  IPolicyNotesState,
  initialPolicyNotesState,
} from "./notes/notes.types";
import {
  IAccountingTaxInformationState,
  initialAccountingTaxInformationState,
} from "./Accounting/TaxInformation/taxInformation.types";
import {
  IAccountingGLAccountsState,
  initialAccountingGLAccountsState,
} from "./Accounting/GLAccounts/glAccounts.types";
import {
  IChartsOfAccountsState,
  initialChartsOfAccountsState,
} from "./Accounting/ChartsOfAccounts/ChartsOfAccounts.types";
import {
  IAppFormState,
  initialAppFormState,
} from "./appForm/appForm.types";
import {
  ICompanyDetailState,
  initialAccountingCompanyDetailState,
} from "./Accounting/CompanyDetail/CompanyDetail.types";
import {
  ICompanyOnboardingState,
  initialAccountingCompanyOnboardingState,
} from "./Accounting/CompanyOnboarding/CompanyOnboarding.types";
import {
  IAccountingTaxInfoState,
  initialAccountingTaxInfoState,
} from "./Accounting/TaxInfo/TaxInfo.types";
import {
  IAccountingTransactionsState,
  initialAccountingTransactionsState,
} from "./Accounting/Transactions/Transactions.types";
import {
  IAccountingInformationState,
  initialAccountingInformationState,
} from "./Accounting/AccountingInformation/AccountingInformation.types";
import {
  IAccountingInvoiceInformationState,
  initialAccountingInvoiceInformationState,
} from "./Accounting/InvoiceInformation/InvoiceInformation.types";
import {
  IAccountingBankAccountState,
  initialAccountingBankAccountState,
} from "./Accounting/BankAccount/BankAccount.types";
import {
  IAccountingChequeState,
  initialAccountingChequeState,
} from "./Accounting/Cheque/Cheque.types";
import {
  IAccountingAccountsPayableState,
  initialAccountingAccountsPayableState,
} from "./Accounting/AccountsPayable/AccountsPayable.types";
import {
  IAccountingRecievableTrustState,
  initialAccountingRecievableTrustState,
} from "./Accounting/RecievableTrust/RecievableTrust.types";
import {
  IAccountingPaymentAccountsRecievableState,
  initialAccountingPaymentAccountsRecievableState,
} from "./Accounting/PaymentAccountsRecievable/PaymentAccountsRecievable.types";
import {
  IAccountingAccountsPayableChequeState,
  initialAccountingAccountsPayableChequeState,
} from "./Accounting/AccountsPayableCheque/AccountsPayableCheque.types";
import {
  IAccountingVendorState,
  initialAccountingVendorState,
} from "./Accounting/Vendor/Vendor.types";
import {
  IAccountingCompanyInformationState,
  initialAccountingCompanyInformation,
} from "./Accounting/CompanyInformation/companyInformation.types";
import {
  IAccountingInteracEmailTransferState,
  initialAccountingInteracEmailTransferState,
} from "./Accounting/InteracEmailTransfer/InteracEmailTransfer.types";
import {
  IAccountingRecievableState,
  initialAccountingRecievableState,
} from "./Accounting/Recievable/Recievable.types";
import {
  IAccountingEftState,
  initialAccountingEftState,
} from "./Accounting/Eft/Eft.types";
import {
  IInvoiceTemplateState,
  initialInvoiceTemplateState,
} from "./Accounting/Template/Template.types";
import {
  ITransactionTypeState,
  initialTransactionTypeState,
} from "./Accounting/TransactionType/TransactionType.types";
import { ITransactionDescriptionState, initialTransactionDescriptionState } from "./Accounting/TransactionDescription/TransactionDescription.types";
import { IAccountingBillState, initialAccountingBillState } from "./Accounting/Bill/Bill.types";
import { IBillTemplateState, initialBillTemplateState } from "./Accounting/BillTemplate/BillTemplate.types";
import { IBillPaymentPayableState, initialBillPaymentPayableState } from "./Accounting/BillPaymentPayable/BillPaymentPayable.types";
import { IG4SignState, initialG4SignDocumentState } from "./g4SignDocuments/g4SignDocuments.types";
import { IBrokerContactsState, initialBrokerContactState } from "./brokerContacts/brokerContacts.types";
import { ISupportTicketsState, initialSupportTicketsState } from "./supportTickets/supportTickets.types";
import { IAllBinderState, defaultAllBindersState } from "./AllBinders/allbinders.types";
import { IRenewalsState, defaultRenewalsState } from "./renewals/renewals.types";
import { IEmailStatisticsState, initialEmailStatisticsState } from "./emailStatistics/emailStatistics.types";
import { IAutomobileAlbertaApplicationState, initialAutomobileAlbertaApplicationState } from "./automobileAlbertaApplication/automobileAlbertaApplication.types";
import { IUtilityState, initialUtilityState } from "./utility/utility.types";
import { ICSIOState, initialCSIOState } from "./csio/csio.types";
import { IAutomationState, initialAutomationState } from "./automation/automation.types";
import { IAutomobileCSIOApplicationState, initialAutomobileCSIOApplicationState } from "./automobileCSIOApplication/automobileCSIOApplication.types";

import { IHabitationalCSIOApplicationState, initialHabitationalCSIOApplicationState } from "./habitationalPolicy/habitationalCsioApplication/habitationalCsioApplication.types";

import { IEquipmentState, initialEquipmentState } from "./equipment/equipment.types";
import { ICSIOEDocsState, initialCSIOEDocsState } from "./csioEdocs/csioEdocs.types";
import { ICommAutomobileCSIOApplicationState, initialCommAutomobileCSIOApplicationState } from "./commAutoPolicy/commAutoCsioApplication/commAutoCsioApplication.types";
import { defaultHabitationalRiskLocationState, IHabitationalRiskLocationState } from "./habitationalPolicy/habitationalRiskLocation/habitationalRiskLocation.types";
import { defaultHabitationalAdditionalState, IHabitationalAdditionalState } from "./habitationalPolicy/habitationalAdditionalInterest/habitationalAdditional.types";
import { defaultHabitationalInterestedState, IHabitationalInterestedState } from "./habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";
import { defaultHabitationalCoveragesState, IHabitationalCoveragesState } from "./habitationalPolicy/habitationalCoverages/habitationalCoverages.types";
import { IHabitationalApplicationState, initialHabitationalApplicationState } from "./habitationalPolicy/habitationalApplication/habitationalApplicationNew.types";
import { IHabitationalPolicyChangeState, initialHabitationalPolicyChangeReqState } from "./habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReq.types";
import { IAcitivtyState, initialAcitvityState } from "./activity/activity.types";
import { IExpressionTransformationState, initialExpressionTransformation } from "./expressionTransformation/expressionTransformation.types";
import { initialSystemGLAccountState, ISystemGLAccountState } from "./Accounting/SystemGLAccount/SystemGLAccount.types";
import { IAccountingReportsState, initialAccountingReportsState } from "./Accounting/Reports/Reports.types";
import { ICSIOCronState, initialCSIOCronState } from "./csioCron/csioCron.types";
import { IAutomobileCSIOEDIApplicationState, initialAutomobileCSIOEDIApplicationState } from "./automobileCSIOEDIApplication/automobileCSIOEdiApplication.types";
import { IHabitationalPolicyFormState, initialHabitationalPolicyFormState } from "./habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyForm.types";
import { initialReconciliationState, IReconciliationState } from "./reconciliation/reconciliation.types";
import { IHabitationalCSIOEDIApplicationState, initialHabitationalCSIOEDIApplicationState } from "./habitationalPolicy/habitationalCSIOEDIApplication/habitationalCSIOEDIApplication.types";
import { IAllCustomersDetailsState, initialCustomerFullDetailsState } from "./allCustomers/allCustomers.types";
import { initialPolicyState, IPolicyState } from "./allPolicies/Policies.types";
import { initialTaxState, ITaxState } from "./Accounting/Tax/Tax.types";
import { IControlLogState, initialControlLogState } from "./ControlLog/controlLog.types";
import { IEnvConfigState, initialEnvConfigState } from "./envConfig/envConfig.types";
import { IAccountingDashboardState, initialAccountingDashboardState } from "./accountingDashboard/accountingDashboard.types";
import { ICommAutoPolicyFormsState, intitialCommAutoPolicyFormState } from "./commAutoPolicy/commAutoPolicyForms/commAutoPolicyForm.types";
import { IAutomobilePolicyFormsState, initialAutomobilePolciyFormsState } from "./automobilePolicyForms/automobliePolicyForms.types";
import { ICommAutoAllApplicationState, initialCommAutoAllApplicationState } from "./commAutoPolicy/commAutoAllApplications/commAutoAllApplications.types";
import { IBusinessPolicyFormsState, initialBusinessPolicyFormState } from "./businessPolicy/businessPolicyForms/businessPolicyForms.types";
import { IGroupPlansState, initialGroupPlansState } from "./GroupPlans/groupPlans.types";
import { IAutomobileQuoteState, initialAutomobileQuoteState } from "./automobileQuote/automobileQuote.types";
import { IAutomobileInsuranceLapseState, initialAutomobileInsuranceLapseState } from "./automobileInsuranceLapse/automobileInsuranceLapse.types";
import { IAutomobileFraudMisRepresentState, initialAutomobileFraudMisRepresentState } from "./automobileFraudMisRepresent/automobileFraudMisRepresent.types";
import { initialQuoteSettingsState, QuoteSettingsState } from "./QuoteSettings/quoteSettings.types";
import { initialOldBusinessTrackerState, IOldBusinessTrackerState } from "./OldBusinessTracker/oldBusinessTracker.types";
import { initialOldInsuranceReportsState, IOldInsuranceReportsState } from "./OldInsuranceReport/oldInsuranceReport.types";
import { initial } from "lodash";
import { IHabitationalPropertyScheduledState, initialPropertyScheduleState } from "./habitationalPolicy/habitationalPropertySchedule/habitationalPropertySchedule.types";
import { ICSIOEDIState, initialCSIOEDIState } from "./csioEDI/csioEDI.types";
import { IAutomobilePolicyChangeReqState, initialAutomobilePolicyChangeReqState } from "./automobilePolicyChangeReq/automobilePolicyChangeReq.types";
import { IBusinessPolicyChangeState, initialBusinessPolicyChangeState } from "./businessPolicy/businessPolicyChange/businessPolicyChane.types";
import { IAccountReconciliationState, initialAccountReconciliationState } from "./aiReconciliation/aiReconciliation.types";
import { IGeneralLedgerState, initialGeneralLedgerState } from "./Accounting/GeneralLedger/generalLedger.types";
import { initialPolicyChangeReqState, IPolicyChangeRequestState } from "./PolicyChangeRequest/policyChangeRequest.types";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  borkerage: {
    branchOffice: IBranchOfficeState;
    brokerCode: IBrokerCodeState;
    insurer: IInsurerState;
    subInsurer: ISubInsurerState;
    underwriter: IUnderWriter;
    csioCompany: ICsioComapnyState;
    insurerContacts: IInsurerContactsState;
    policyCommission: IPolicyCommissionState;
    intrestedParties: IIntrestedPartiesState;
    additionalInterest: IAdditionalInterestState;
    brokerContacts: IBrokerContactsState;
    equipment: IEquipmentState;
    groupPlans: IGroupPlansState;
  };
  customer: ICustomer;
  allpolicies: IPolicyState,
  allcustomers: IAllCustomersDetailsState;
  message: IMessagesState;
  business: {
    basicDetails: IBusinessBasicDetailsState;
    underwriting: IBusinessPolicyUnderWritingState;
    coverage: IBusinessPolicyCoverageState;
    riskLocation: IBusinessPolicyRiskLocationState;
    fineArt: IBusinessPolicyFineArtState;
    toolFloater: IBusinessPolicyToolFloaterState;
    equipment: IBusinessPolicyEquipmentState;
    interested: IBusinessPolicyInterestedState;
    additional: IBusinessPolicyAdditionalState;
    premiumFinance: IBusinessPolicyPremiumFinanceState;
    subscription: IBusinessPolicySubscriptionState;
    underLying: IBusinessPolicyUnderLyingState;
    restrutant: IBusinessPolicyRestrutantState;
    contractor: IBusinessPolicyContractorState;
    history: IBusinessPolicyHistoryState;
    marketing: IBusinessPolicyMarketingState;
    policyForms: IBusinessPolicyFormsState;
    policyChange: IBusinessPolicyChangeState;
  };

  automobile: {
    basicDetails: IAutomobileBasicDetailsState;
    garagePlate: IAutomobileGaragePlatesState;
    vehicle: IAutomobileVehicleState;
    csv: IAutomobileCSVState;
    driver: IAutomobileDriverState;
    schedule: IAutomobileScheduleState;
    marketing: IAutomobileMarketingHistoryState;
    certificates: IAutomobileCertificatesState;
    allApplications: IAutomobileApplicationState;
    albertaApplication: IAutomobileAlbertaApplicationState;
    csioapplication: IAutomobileCSIOApplicationState;
    edi: IAutomobileCSIOEDIApplicationState;
    policyChangeReq: IAutomobilePolicyChangeReqState;
    supplement: IAutomobileSupplementState;
    garageApplication: IAutomobileGarageApplicationState;
    policyForms: IAutomobilePolicyFormsState;
    quote: IAutomobileQuoteState;
    insuranceLapse: IAutomobileInsuranceLapseState;
    fraudMisRepresent: IAutomobileFraudMisRepresentState
  };
  commAuto: {
    basicDetails: ICommAutomobileBasicDetailsState;
    csioapplication: ICommAutomobileCSIOApplicationState;
    certificates: ICommAutomobileCertificatesState;
    csv: ICommAutomobileCSVState;
    driver: ICommAutomobileDriverState;
    garagePlate: ICommAutomobileGaragePlatesState;
    marketing: ICommAutomobileMarketingHistoryState;
    schedule: ICommAutomobileScheduleState;
    supplement: ICommAutomobileSupplementState;
    vehicle: ICommAutomobileVehicleState;
    policyForms: ICommAutoPolicyFormsState;
    allApplications: ICommAutoAllApplicationState;
  };
  habitational: {
    basicDetails: IHabitationalBasicDetailsState;
    claimDetails: IHabitationalClaimState;
    riskLocation: IHabitationalRiskLocationState;
    applications: IHabitationalApplicationState;
    csioapplication: IHabitationalCSIOApplicationState;
    edi: IHabitationalCSIOEDIApplicationState;
    additional: IHabitationalAdditionalState;
    interested: IHabitationalInterestedState;
    coverages: IHabitationalCoveragesState;
    policyChangeReq: IHabitationalPolicyChangeState;
    policyform: IHabitationalPolicyFormState;
    schedule: IHabitationalPropertyScheduledState;
  };
  lifeInsurance: {
    basicDetails: ILifeInsuranceBasicDetailsState;
    policies: ILifeInsurancePolicyState;
    complianceCheckList: ILifeInsurancePolcyComplianceCheckListState;
    existingProperty: IlifeInsurancePolicyExistingPropertyState;
    familyTree: ILifeInsurancePolicyFamilyTreeState;
    coverageNeed: ILifeInsuranceCoverageNeedState;
  };
  tasks: {
    allTasks: ITaskState;
    calender: ITaskCalenderState;
    generalChange: IGeneralChangeState;
    paymentMethods: ITaskPaymentMethodsState;
    urgentTransactions: ITaskUrgentTransactionsState;
    driver: ITaskDriverState;
    vehicle: ITaskVehicleState;
    garagePlate: ITaskGaragePlateState;
    interestedParty: ITaskInterestedPartyState;
    riskLocation: ITaskRiskLocationState;
    lessor: ITaskLessorState;
    taskBoard: ITaskBoardState;
    notices: INoticesState;
    oldBusinessTracker: IOldBusinessTrackerState
    oldInsurancReports: IOldInsuranceReportsState;
    policyChangeRequest: IPolicyChangeRequestState;
  };
  userprofile: IUsersState;
  common: ICommonState;

  NewRemarketChange: INewRemarketState;
  insuranceReports: IInsuranceReportsState;
  binders: IBinderState;
  allbinders: IAllBinderState;
  renewals : IRenewalsState;
  opportunites: IOpportunityState;
  leads: ILeadsState;
  allApplications: IAllApplicationState;
  Marketings: IMarketingState;

  legals: ILegalsState;
  eDocs: IEDocsState;
  //HR

  templates: ITemplateState;
  security: ISecurityState;

  policyNotes: IPolicyNotesState;

  //Accounting
  accounting: {
    accountingCompanyInformation: IAccountingCompanyInformationState;
    accountingGLAccounts: IAccountingGLAccountsState;
    accountingTaxInformation: IAccountingTaxInformationState;
    interacEmailTransfer: IAccountingInteracEmailTransferState;
    eft: IAccountingEftState;
    receivable: IAccountingRecievableState;
    companyDetail: ICompanyDetailState;
    systemGLAccount: ISystemGLAccountState;
    chartsOfAccounts: IChartsOfAccountsState;
    companyOnboarding: ICompanyOnboardingState;
    taxInfo: IAccountingTaxInfoState;
    transactions: IAccountingTransactionsState;
    information: IAccountingInformationState;
    invoiceInformation: IAccountingInvoiceInformationState;
    reports: IAccountingReportsState;
    bankAccount: IAccountingBankAccountState;
    cheque: IAccountingChequeState;
    accountsPayable: IAccountingAccountsPayableState;
    recievableTrust: IAccountingRecievableTrustState;
    paymentAccountsRecievable: IAccountingPaymentAccountsRecievableState;
    accountsPayableCheque: IAccountingAccountsPayableChequeState;
    vendor: IAccountingVendorState;
    invoiceTemplate: IInvoiceTemplateState;
    transactionType: ITransactionTypeState;
    transactionDescription: ITransactionDescriptionState;
    bill: IAccountingBillState;
    billTemplate: IBillTemplateState;
    billPaymentPayable: IBillPaymentPayableState;
    tax: ITaxState;
    generalLedger: IGeneralLedgerState;
  };
  g4Sign: {
    documents: IG4SignState;
  };
 

  appForm: IAppFormState;
  supportTickets: ISupportTicketsState;
  emailStatistics: IEmailStatisticsState;
  utility: IUtilityState;
  csio: ICSIOState;
  controlLog: IControlLogState
  automation: IAutomationState;
  csioEdocs: ICSIOEDocsState;
  acitivty: IAcitivtyState;
  expressionTransformation: IExpressionTransformationState;
  csioCron: ICSIOCronState;
  reconciliation: IReconciliationState;
  envConfig: IEnvConfigState;
  quoteConfig: QuoteSettingsState;
  accountingDashbaord: IAccountingDashboardState;
  csioEDI: ICSIOEDIState;
  aiReconciliation: IAccountReconciliationState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "LIGHT_BLUE_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
    sidebarOpen: true,
  },
  borkerage: {
    branchOffice: initialBranchOfficeState,
    brokerCode: initialBrokerCodeState,
    insurer: initialInsurerState,
    subInsurer: initialSubInsurerState,
    underwriter: initialUnderWriterState,
    csioCompany: initialCsioCompanyReducerState,
    insurerContacts: initialInsurerContactsState,
    policyCommission: initialPolicyCommissioneState,
    intrestedParties: initialIntrestedPartiesState,
    additionalInterest: initialAdditionalInterestState,
    brokerContacts: initialBrokerContactState,
    equipment: initialEquipmentState,
    groupPlans: initialGroupPlansState,
  },
  allpolicies: initialPolicyState,
  allcustomers: initialCustomerFullDetailsState,
  binders: defaultBindersState,
  allbinders: defaultAllBindersState,
  renewals: defaultRenewalsState,
  Marketings: defaultMarketingsState,

  customer: initialCustomerState,
  message: initialMessagesState,
  business: {
    basicDetails: initialBasicDetails,
    underwriting: defaultBusinessPolicyUnderWritingState,
    coverage: defaultBusinessPolicyCoverageState,
    riskLocation: defaultBusinessPolicyRiskLocationState,
    fineArt: defaultBusinessPolicyFineArtState,
    toolFloater: defaultBusinessPolicyToolFloaterState,
    equipment: defaultBusinessPolicyEquipmentState,
    interested: defaultBusinessPolicyInterestedState,
    additional: defaultBusinessPolicyAdditionalState,
    premiumFinance: defaultBusinessPolicyPremiumFinanceState,
    subscription: defaultBusinessPolicySubscriptionState,
    underLying: defaultBusinessPolicyUnderLyingState,
    restrutant: initialBusinessPolicyRestrutantState,
    contractor: initialBusinessPolicyContractorState,
    history: initialBusinessPolicyHistoryState,
    marketing: initialBusinessPolicyMarketing,
    policyForms: initialBusinessPolicyFormState,
    policyChange: initialBusinessPolicyChangeState
  },
  automobile: {
    basicDetails: initialAutomobileDetails,
    garagePlate: defaultAutomobileGaragePlatesState,
    vehicle: initialAutomoblieVehicleInformation,
    csv: initialAutomoblieCSV,
    driver: initialAutomoblieDriverInformation,
    schedule: initialAutomoblieSchedule,
    marketing: initialAutomobileMarketingHistoryState,
    certificates: initialAutomobileCertificatesState,
    allApplications: initialAutomobileApplicationState,
    albertaApplication: initialAutomobileAlbertaApplicationState,
    csioapplication: initialAutomobileCSIOApplicationState,
    policyChangeReq: initialAutomobilePolicyChangeReqState,
    edi: initialAutomobileCSIOEDIApplicationState,
    supplement: initialAutomobileSupplement,
    garageApplication: defaultAutomobileGarageApplicationState,
    policyForms: initialAutomobilePolciyFormsState,
    quote: initialAutomobileQuoteState,
    insuranceLapse: initialAutomobileInsuranceLapseState,
    fraudMisRepresent: initialAutomobileFraudMisRepresentState,
  },
  commAuto: {
    basicDetails: initialCommAutomobileDetails,
    csioapplication: initialCommAutomobileCSIOApplicationState,
    certificates: initialCommAutomobileCertificatesState,
    csv: initialCommAutomoblieCSV,
    driver: initialCommAutomoblieDriverInformation,
    garagePlate: defaultCommAutomobileGaragePlatesState,
    marketing: initialCommAutomobileMarketingHistoryState,
    schedule: initialCommAutomoblieSchedule,
    supplement: initialCommAutomobileSupplement,
    vehicle: initialCommAutomoblieVehicleInformation,
    policyForms: intitialCommAutoPolicyFormState,
    allApplications: initialCommAutoAllApplicationState,
  },
  habitational: {
    basicDetails: initialHabitationalDetails,
    applications: initialHabitationalApplicationState,
    claimDetails: initialHabitationalClaimDetails,
    riskLocation: defaultHabitationalRiskLocationState,
    csioapplication: initialHabitationalCSIOApplicationState,
    edi: initialHabitationalCSIOEDIApplicationState,
    additional: defaultHabitationalAdditionalState,
    interested: defaultHabitationalInterestedState,
    coverages: defaultHabitationalCoveragesState,
    policyChangeReq: initialHabitationalPolicyChangeReqState,
    policyform: initialHabitationalPolicyFormState,
    schedule: initialPropertyScheduleState,
  },
  lifeInsurance: {
    basicDetails: initialLifeInsuranceBasicDetails,
    policies: defaultLifeInsurancePolicy,
    complianceCheckList: defaultLifeInsurancePolicyComplianceCheckList,
    existingProperty: defaultlifeInsurancePolicyExistingProperty,
    familyTree: defaultLifeInsurancePolicyFamilyTree,
    coverageNeed: defaultLifeInsuranceCoverageNeed,
  },
  common: defaultCommonState,
  userprofile: initialUserProfileState,
  tasks: {
    allTasks: initialTaskState,
    calender: initialTaskCalenderState,
    generalChange: initialGeneralState,
    paymentMethods: initialPaymentMethodsState,
    urgentTransactions: initialTaskurgentTransactionsState,
    driver: initialTaskDriverState,
    vehicle: initialTaskVehicleState,
    garagePlate: initialTaskGaragePlateState,
    interestedParty: initialTaskInterestedPartyState,
    lessor: initialTaskLessorState,
    riskLocation: initialTaskRiskLocationState,
    notices: initialNoticesState,
    taskBoard: initialTaskBoardState,
    oldBusinessTracker: initialOldBusinessTrackerState,
    oldInsurancReports: initialOldInsuranceReportsState,
    policyChangeRequest: initialPolicyChangeReqState,
  },

  insuranceReports: initialInsuranceReportsState,
  NewRemarketChange: defaultNewRemarket,
  opportunites: inititalOpportunityState,
  leads: initialLeadState,

  //legals
  legals: initialLegalState,

  //eDocs
  eDocs: initialEDocsState,
  //HR
  allApplications: initialAllApplicationState,

  //Life Insurance Policy

  templates: initialTemplateState,

  security: initialSecurityState,

  policyNotes: initialPolicyNotesState,

  //Accounting
  accounting: {
    accountingCompanyInformation: initialAccountingCompanyInformation,
    accountingTaxInformation: initialAccountingTaxInformationState,
    accountingGLAccounts: initialAccountingGLAccountsState,
    interacEmailTransfer: initialAccountingInteracEmailTransferState,
    eft: initialAccountingEftState,
    receivable: initialAccountingRecievableState,
    companyDetail: initialAccountingCompanyDetailState,
    systemGLAccount: initialSystemGLAccountState,
    chartsOfAccounts: initialChartsOfAccountsState,
    companyOnboarding: initialAccountingCompanyOnboardingState,
    taxInfo: initialAccountingTaxInfoState,
    transactions: initialAccountingTransactionsState,
    information: initialAccountingInformationState,
    invoiceInformation: initialAccountingInvoiceInformationState,
    reports: initialAccountingReportsState,
    bankAccount: initialAccountingBankAccountState,
    cheque: initialAccountingChequeState,
    accountsPayable: initialAccountingAccountsPayableState,
    recievableTrust: initialAccountingRecievableTrustState,
    paymentAccountsRecievable: initialAccountingPaymentAccountsRecievableState,
    accountsPayableCheque: initialAccountingAccountsPayableChequeState,
    vendor: initialAccountingVendorState,
    invoiceTemplate: initialInvoiceTemplateState,
    transactionType: initialTransactionTypeState,
    transactionDescription: initialTransactionDescriptionState,
    bill: initialAccountingBillState,
    billTemplate: initialBillTemplateState,
    billPaymentPayable: initialBillPaymentPayableState,
    tax: initialTaxState,
    generalLedger: initialGeneralLedgerState,
  },
  g4Sign: {
    documents: initialG4SignDocumentState,
  },
  emailStatistics: initialEmailStatisticsState,



  appForm: initialAppFormState,
  supportTickets: initialSupportTicketsState,
  utility: initialUtilityState,
  csio: initialCSIOState,
  controlLog: initialControlLogState,
  automation: initialAutomationState,
  csioEdocs: initialCSIOEDocsState,
  acitivty: initialAcitvityState,
  expressionTransformation : initialExpressionTransformation,
  csioCron: initialCSIOCronState,
  reconciliation: initialReconciliationState,
  envConfig: initialEnvConfigState,
  quoteConfig: initialQuoteSettingsState,
  accountingDashbaord: initialAccountingDashboardState,
  csioEDI: initialCSIOEDIState,
  aiReconciliation: initialAccountReconciliationState
};
