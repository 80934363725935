import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WarningOutlined } from "@mui/icons-material";
import { Dialog } from "../../../../../../../components/Dialogs/Dialog";
export interface CreateVehiclesWarningDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: number;
  customerPolicyId: number;
}
export const CreateVehicleWarningDialog: React.FC<
  CreateVehiclesWarningDialogProps
> = (props) => {
  const { open, onClose, customerId, customerPolicyId } = props;

  const navigate = useNavigate();

  const handleGoToDriver = () => {
    navigate(
      `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}?tab=0`
    );
  };

  return (
    <Dialog
      title="Warning"
      size="md"
      open={open}
      onClose={() => {}}
      hideCloseIcon
      hideHeader
      actions={[
        {
          type: "button",
          variant: "contained",
          label: "Go to Driver",
          onClick: handleGoToDriver,
        },
        {
          type: "button",
          variant: "contained",
          label: "Accept",
          onClick: onClose,
        },
      ]}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <WarningOutlined color="warning" sx={{ fontSize: "4rem" }} />
        <Box>
          <Typography variant="h2" fontWeight="bold">
            Driver Requirement Notice
          </Typography>
          <Typography variant="h2" fontWeight="bold">
            Please Confirm!
          </Typography>
        </Box>
      </Stack>

      <Typography variant="h4" fontWeight="bold" marginTop={1}>
        Before proceeding, please ensure:
      </Typography>
      <Box>
        <ul>
          {/* <li>
            <Typography variant="body1" sx={{ fontSize: "1.15rem" }}>
              A Driver record must be created under policy before adding a vehicle.
            </Typography>
          </li> */}
          <li>
            <Typography variant="body1" sx={{ fontSize: "1.15rem" }}>
              Please make sure you have added a driver. If no driver is present,
              please add one before proceeding.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" sx={{ fontSize: "1.15rem" }}>
              <Link sx={{ cursor: "pointer" }} onClick={handleGoToDriver}>
                Click here to add a driver
              </Link>
            </Typography>
          </li>
        </ul>
      </Box>
    </Dialog>
  );
};
