import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IInvoiceDetails,
  IInvoiceInformation,
  IInvoicePaymentHistory,
  initialAccountingInvoiceInformationState,
} from "./InvoiceInformation.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IDataTableV2DateState } from "../../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import {
  IDataTableV2SearchFilterSearchItem,
  IDatatableV2AdvancedSearchFilter,
} from "../../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE";

export const fetchAccountingInvoiceInformationProgress = () =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS);

export const fetchAccountingInvoiceInformationSuccess = (
  data: IInvoiceInformation
) => action(FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS, { data });

export const fetchAccountingInvoiceInformationFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE, { errorMessage });

export const fetchAccountingInvoiceInformationAsync =
  (invoiceId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInvoiceInformationProgress());

      const res = await api.get(
        `/accounting/get-account-invoice?invoice_id=${invoiceId}`
      );
      const data: IInvoiceInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingInvoiceInformationSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingInvoiceInformationFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingInvoiceInformationAysnc =
  (
    data: IInvoiceInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-invoice", {
        ...data,
      });
      let message = "Invoice Information saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_INVOICE_PAYMENT_HISTORY_PROGRESS =
  "FETCH_INVOICE_PAYMENT_HISTORY_PROGRESS";
export const FETCH_INVOICE_PAYMENT_HISTORY_SUCCESS =
  "FETCH_INVOICE_PAYMENT_HISTORY_SUCCESS";
export const FETCH_INVOICE_PAYMENT_HISTORY_FAILED =
  "FETCH_INVOICE_PAYMENT_HISTORY_FAILED";

export const fetchInvoicePaymentHistoryProgress = () =>
  action(FETCH_INVOICE_PAYMENT_HISTORY_PROGRESS);
export const fetchInvoicePaymentHistorySuccess = (
  data: IInvoicePaymentHistory
) =>
  action(FETCH_INVOICE_PAYMENT_HISTORY_SUCCESS, {
    data,
  });
export const fetchInvoicePaymentHistoryFailed = (errorMessage: string) =>
  action(FETCH_INVOICE_PAYMENT_HISTORY_FAILED, { errorMessage });

export const fetchInvoicePaymentHistoryAsync =
  (invoiceId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInvoicePaymentHistoryProgress());

      const res = await api.get(
        `/accounting/get-account-invoice-and-payment-history?invoice_id=${invoiceId}`
      );
      const data: IInvoicePaymentHistory[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchInvoicePaymentHistorySuccess(data[0]));
      } else {
        dispatch(
          fetchInvoicePaymentHistoryFailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchInvoicePaymentHistoryFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE";

export const fetchAccountingInvoiceInformationListProgress = () =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS);

export const fetchAccountingInvoiceInformationListSuccess = (
  data: IInvoiceDetails[],
  totalRecords: number
) =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS, {
    data,
    totalRecords,
  });

export const fetchAccountingInvoiceInformationListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE, { errorMessage });

export const fetchAccountingInvoiceInformationListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue?: IDatatableV2AdvancedSearchFilter,
    policyId?: any,
    customerId?: any,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInvoiceInformationListProgress());
      let finalUrl = `/accounting/get-account-invoice?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue && searchValue.length > 0) {
        finalUrl = `/accounting/get-account-invoice?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      // if(customerId){
      //   finalUrl = finalUrl+"&customer_id="+customerId;
      // }

      if(policyId){
        finalUrl = finalUrl+"&policy_id="+policyId;
      }

      const res = await api.get(finalUrl);
      const data: IInvoiceDetails[] = res.data.data;
      dispatch(
        fetchAccountingInvoiceInformationListSuccess(
          data,
          res.data.totalRecords
        )
      );
    } catch (err: any) {
      dispatch(
        fetchAccountingInvoiceInformationListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  export const downloadInvoiceInformationPDFAsync =
  (
    invoice_number: string,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/accounting/get-account-invoice-pdf?invoice_number=${invoice_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const previewInvoiceInformationPDFAsync =
  (
    invoice_number: string,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/accounting/get-account-invoice-pdf?invoice_number=${invoice_number}&is_invoice_details_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ACCOUNTING_INVOICE_INFORMATION =
  "CLEAR_ACCOUNTING_INVOICE_INFORMATION";
export const clearAccountingInvoiceInformation = () =>
  action(CLEAR_ACCOUNTING_INVOICE_INFORMATION);
