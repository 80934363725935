import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  COMMON_STATUS,
  deductible_type,
  discount_surcharge,
  liability_extension_exclusion,
} from "../../../../../../constants/constants";
import produce from "immer";
import {
  addHabitationalCoverageAsync,
  clearHabitationalCoverage,
  fetchHabitationalCoverageAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoveragesActions";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { AutoComplete } from "../../../../../../components/AutoCompleteSearches/AutoComplete";
import { fetchHabitationalRiskListAsync } from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import { IHabitationalCoverages } from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoverages.types";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IHabitationalCoveragesProps {
  customerId?: number;
  customerPolicyId?: number;
  interestedNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  onSaveSuccess: (data: IHabitationalCoverages) => void;
  staticStatusValue?: string;
  createdFrom?: string;
  initialData?: IHabitationalCoverages;
  disableApiCalls?: boolean;
}

export const HabitationalCoverages: React.FC<IHabitationalCoveragesProps> = (
  props
) => {
  const {
    customerId,
    customerPolicyId,
    interestedNumber,
    readOnly = false,
    onSaveSuccess,
    fromRightPanel = false,
    createdFrom,
    staticStatusValue,
    initialData,
    disableApiCalls = false,
  } = props;

  const { coveragesLoading, coverages, error } = useSelector(
    (storeState: IStoreState) => storeState.habitational.coverages
  );
  const riskLocations = useSelector(
    (storeState: IStoreState) => storeState.habitational.riskLocation.data
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData ? initialData : coverages,
    validate: (values) => {},
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          setSaveLoading(true);
          dispatch(
            addHabitationalCoverageAsync(
              {
                ...values,
                status: finalStatus,
                created_from: createdFrom || "Coverage",
              },
              customerPolicyId,
              (isSuccess, data) => {
                if (isSuccess && data) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(data);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId && interestedNumber && !disableApiCalls) {
      dispatch(
        fetchHabitationalCoverageAsync(customerPolicyId, interestedNumber)
      );
    }
  }, [customerPolicyId, interestedNumber]);

  const riskLocationsDropDown = React.useMemo(() => {
    return riskLocations.map((item) => {
      return {
        label:
          item.incremental_number +
          ` (${item.address}, ${item.city}, ${item.province_or_state}, ${item.postal_code})`,
        value: item.incremental_number || "",
      };
    });
  }, [riskLocations]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalRiskListAsync(Number(customerPolicyId)));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    if(initialData && disableApiCalls){
      return;
    }
    setValues({ ...coverages });
  }, [coverages]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalCoverage());
    };
  }, []);

  const handleCoveragesFormChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(values.coverages_form, (draftRows: any) => {
        draftRows[rowIndex][key] = event.target.value;
      });
      setValues({ ...values, coverages_form: finalRows });
    };

  const handleSelectCoveragesFormChange =
    (rowIndex: number, key: string) => (value: any) => {
      const finalRows = produce(values.coverages_form, (draftRows: any) => {
        draftRows[rowIndex][key] = value ? value.value : "";
      });
      setValues({
        ...values,
        coverages_form: finalRows,
      });
    };

  const total = React.useMemo(() => {
    return values.coverages_form.reduce((item, currentValue) => {
      return item + (Number(currentValue.premium) || 0);
    }, 0);
  }, [values.coverages_form]);

  const handleLiabilityExtensionFormChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.liability_extension_and_exclusion_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({
        ...values,
        liability_extension_and_exclusion_form: finalRows,
      });
    };
  const handleLiabilityExtensionFormChangeCurrencyChange =
    (rowIndex: number, key: string) => (value: string) => {
      const finalRows = produce(
        values.liability_extension_and_exclusion_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = value;
        }
      );
      setValues({
        ...values,
        liability_extension_and_exclusion_form: finalRows,
      });
    };

  const handleSelectLiabilityExtensionFormChange =
    (rowIndex: number, key: string) => (selectedOption: any) => {
      const finalRows = produce(
        values.liability_extension_and_exclusion_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = selectedOption ? selectedOption : "";
        }
      );
      setValues({
        ...values,
        liability_extension_and_exclusion_form: finalRows,
      });
    };

  const total2 = React.useMemo(() => {
    return values.liability_extension_and_exclusion_form.reduce(
      (item, currentValue) => {
        return item + (Number(currentValue.estimated_premium) || 0);
      },
      0
    );
  }, [values.liability_extension_and_exclusion_form]);

  const handleDiscountSurchargesFormChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.discount_and_surcharges_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, discount_and_surcharges_form: finalRows });
    };
  const handleDiscountSurchargesFormChangeCurrencyChange =
    (rowIndex: number, key: string) => (value: string) => {
      const finalRows = produce(
        values.discount_and_surcharges_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = value;
        }
      );
      setValues({ ...values, discount_and_surcharges_form: finalRows });
    };

  const handleSelectDiscountSurchargesFormChange =
    (rowIndex: number, key: string) => (selectedOption: any) => {
      const finalRows = produce(
        values.discount_and_surcharges_form,
        (draftRows: any) => {
          draftRows[rowIndex][key] = selectedOption ? selectedOption : "";
        }
      );
      setValues({ ...values, discount_and_surcharges_form: finalRows });
    };

  const total3 = React.useMemo(() => {
    return values.discount_and_surcharges_form.reduce((item, currentValue) => {
      return item + (Number(currentValue.est_discount_or_surchage) || 0);
    }, 0);
  }, [values.discount_and_surcharges_form]);

  return (
    <PageLoader
      loading={coveragesLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      {/* <Breadcrumb title="" items={BCrumb} />
      <CustomerBaiscDetailsLayout customerId={customerId}> */}
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Coverages Form" : ""}
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant="h4" fontWeight={600} mb={1}>
                  Risk Location
                </Typography>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  name="coverage_risk_location"
                  fullWidth
                  displayEmpty
                  value={Number(values.coverage_risk_location)}
                  onChange={handleChange}
                  placeholder="Select One"
                  options={riskLocationsDropDown}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Coverage Discription
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Requested/Declined
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Amount of Insurance
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Deductible
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Deductible Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Type of
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography fontWeight={600} variant="h4">
                      Premium
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.coverages_form?.map((item, index) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {item.coverage_discription !== null ? (
                        <TableCell>
                          <Typography>{item.coverage_discription}</Typography>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <ControlledCustomSelect
                            fullWidth
                            value={item.coverage_discription}
                            name="item.coverage_discription"
                            onChange={handleChange}
                            placeholder="Select"
                            displayEmpty
                            options={liability_extension_exclusion}
                          ></ControlledCustomSelect>
                        </TableCell>
                      )}
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          fullWidth
                          value={item.requested_or_declined}
                          name="requested_or_declined"
                          onChange={handleSelectCoveragesFormChange(
                            index,
                            "requested_or_declined"
                          )}
                          placeholder="Select"
                          displayEmpty
                          options={["Requested", "Declined"].map((template) => {
                            return { label: template, value: template };
                          })}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="amount_of_insurance"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.amount_of_insurance}
                          onChange={handleCoveragesFormChange(
                            index,
                            "amount_of_insurance"
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="deductible"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.deductible}
                          onChange={handleCoveragesFormChange(
                            index,
                            "deductible"
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {" "}
                        <ControlledCustomSelect
                          fullWidth
                          value={item.deductible_type}
                          name="deductible_type"
                          onChange={handleSelectCoveragesFormChange(
                            index,
                            "deductible_type"
                          )}
                          placeholder="Select"
                          displayEmpty
                          options={deductible_type.map((template) => {
                            return { label: template, value: template };
                          })}
                        ></ControlledCustomSelect>
                      </TableCell>
                      <TableCell>
                        <Stack direction={"row"} spacing={1}>
                          <CustomTextField
                            name="type1"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Type1"
                            fullWidth
                            value={item.type1}
                            onChange={handleCoveragesFormChange(index, "type1")}
                          />
                          <CustomTextField
                            name="type2"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Type2"
                            fullWidth
                            value={item.type2}
                            onChange={handleCoveragesFormChange(index, "type2")}
                          />
                          <CustomTextField
                            name="type3"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Type3"
                            fullWidth
                            value={item.type3}
                            onChange={handleCoveragesFormChange(index, "type3")}
                          />
                          <CustomTextField
                            name="type4"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Type4"
                            fullWidth
                            value={item.type4}
                            onChange={handleCoveragesFormChange(index, "type4")}
                          />
                          <CustomTextField
                            name="type5"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Type5"
                            fullWidth
                            value={item.type5}
                            onChange={handleCoveragesFormChange(index, "type5")}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CustomTextField
                          name="premium"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          value={item.premium}
                          onChange={handleCoveragesFormChange(index, "premium")}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h4" fontWeight={600}>
                  Estimated Premium to this Section
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h4" fontWeight={600}>
                  $ {total}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <StandadCard
              sx={{ mt: 2, border: "none", boxShadow: "none" }}
              heading="Liability Extension and Exclusion Form"
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Liability Coverage Discription
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Requested/Declined
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Amount of Insurance
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Deductible Type
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Type of
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Estimated Premium
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.liability_extension_and_exclusion_form &&
                    values.liability_extension_and_exclusion_form.map(
                      (item, index) => {
                        return (
                          <TableRow
                            key={item.key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <AutoComplete
                                value={
                                  item.liability_coverage_discription || null
                                }
                                onChange={handleSelectLiabilityExtensionFormChange(
                                  index,
                                  "liability_coverage_discription"
                                )}
                                placeholder="Select one"
                                options={liability_extension_exclusion}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <ControlledCustomSelect
                                fullWidth
                                value={item.requested_or_declined}
                                name="requested_or_declined"
                                onChange={handleSelectLiabilityExtensionFormChange(
                                  index,
                                  "requested_or_declined"
                                )}
                                placeholder="Select one"
                                displayEmpty
                                options={["Requested", "Declined"].map(
                                  (template) => {
                                    return { label: template, value: template };
                                  }
                                )}
                              ></ControlledCustomSelect>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.amount_of_insurance}
                                onChange={handleLiabilityExtensionFormChangeCurrencyChange(
                                  index,
                                  "amount_of_insurance"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.deductible}
                                onChange={handleLiabilityExtensionFormChangeCurrencyChange(
                                  index,
                                  "deductible"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <ControlledCustomSelect
                                fullWidth
                                value={item.deductible_type}
                                name="deductible_type"
                                onChange={handleSelectLiabilityExtensionFormChange(
                                  index,
                                  "deductible_type"
                                )}
                                placeholder="Select one"
                                displayEmpty
                                options={deductible_type.map((template) => {
                                  return { label: template, value: template };
                                })}
                              ></ControlledCustomSelect>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Stack direction={"row"} spacing={1}>
                                <CustomTextField
                                  name="type1"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  fullWidth
                                  placeholder="Type1"
                                  value={item.type1}
                                  onChange={handleLiabilityExtensionFormChange(
                                    index,
                                    "type1"
                                  )}
                                />
                                <CustomTextField
                                  name="type2"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  fullWidth
                                  placeholder="Type2"
                                  value={item.type2}
                                  onChange={handleLiabilityExtensionFormChange(
                                    index,
                                    "type2"
                                  )}
                                />
                                <CustomTextField
                                  name="type3"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  fullWidth
                                  placeholder="Type3"
                                  value={item.type3}
                                  onChange={handleLiabilityExtensionFormChange(
                                    index,
                                    "type3"
                                  )}
                                />
                                <CustomTextField
                                  name="type4"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  fullWidth
                                  placeholder="Type4"
                                  value={item.type4}
                                  onChange={handleLiabilityExtensionFormChange(
                                    index,
                                    "type4"
                                  )}
                                />
                                <CustomTextField
                                  name="type5"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  fullWidth
                                  placeholder="Type5"
                                  value={item.type5}
                                  onChange={handleLiabilityExtensionFormChange(
                                    index,
                                    "type5"
                                  )}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.estimated_premium}
                                onChange={handleLiabilityExtensionFormChangeCurrencyChange(
                                  index,
                                  "estimated_premium"
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>

              <Divider sx={{ mt: 2, mb: 4 }} />

              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h4" fontWeight={600}>
                    Estimated Premium to this Section
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {total2}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />
            </StandadCard>
            <StandadCard
              sx={{ mt: 2, border: "none", boxShadow: "none" }}
              heading="Discount and Surcharges Form"
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Discount/Surcharge Discription
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        %
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Applied to Premium
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography fontWeight={600} variant="h4">
                        Est. Discount/Surcharge
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.discount_and_surcharges_form &&
                    values.discount_and_surcharges_form.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <AutoComplete
                              value={
                                item.discount_or_surcharge_discription || ""
                              }
                              onChange={handleSelectDiscountSurchargesFormChange(
                                index,
                                "discount_or_surcharge_discription"
                              )}
                              placeholder="Select"
                              options={[
                                ...liability_extension_exclusion,
                                ...discount_surcharge,
                              ]}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CurrencyTextField
                              fullWidth
                              value={item.percentage}
                              onChange={handleDiscountSurchargesFormChangeCurrencyChange(
                                index,
                                "percentage"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <ControlledCustomSelect
                              fullWidth
                              value={item.applied_to_premium}
                              name="applied_to_premium"
                              onChange={handleSelectDiscountSurchargesFormChange(
                                index,
                                "applied_to_premium"
                              )}
                              placeholder="Select"
                              displayEmpty
                              options={["Yes", "No"].map((template) => {
                                return { label: template, value: template };
                              })}
                            ></ControlledCustomSelect>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CurrencyTextField
                              fullWidth
                              value={item.est_discount_or_surchage}
                              onChange={handleDiscountSurchargesFormChangeCurrencyChange(
                                index,
                                "est_discount_or_surchage"
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h4" fontWeight={600}>
                    Estimated Premium to this Section
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {total3}
                  </Typography>
                </Grid>
              </Grid>
            </StandadCard>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h4" fontWeight={600}>
                  Total Premium to this Section
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h4" fontWeight={600}>
                  $ {total + total2 + total3}
                </Typography>
              </Grid>
              {!staticStatusValue && (
                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={COMMON_STATUS}
                  ></ControlledCustomSelect>
                </Grid>
              )}
            </Grid>

            <Divider sx={{ mt: 2, mb: 4 }} />

            <Box sx={{ mt: 4 }}>
              <Button
                sx={{ width: "15%" }}
                disabled={saveLoading}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </Box>
          </FieldSet>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
