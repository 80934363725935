import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { Breadcrumb } from "../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { CustomerBaiscDetailsLayout } from "../../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  addBusinesPolicyInterestedAsync,
  clearBusinessPolicyIntrested,
  fetchBusinessPolicyIntrestedAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterestedActions";
import {
  COMMON_STATUS,
  deductible_type,
  discount_surcharge,
  liability_extension_exclusion,
  TABLE_CACHING_KEYS,
} from "../../../../../../constants/constants";
import produce from "immer";
import {
  addHabitationalCoverageAsync,
  clearHabitationalCoverageState,
  fetchHabitationalCoverageAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoveragesActions";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { AutoComplete } from "../../../../../../components/AutoCompleteSearches/AutoComplete";
import {
  addHabitationalScheduleDetailAsync,
  addHabitationalScheduleSummaryAsync,
  clearHabitationalScheduleDetail,
  clearHabitationalScheduleSummary,
  fetchHabitationalScheduleDetailAsync,
  fetchHabitationalScheduleSummaryAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalPropertySchedule/habitationalPropertyScheduleActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { ExtendableSelect } from "../../../../../../components/formsComponents/ExtendableSelect";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import {
  IHabitationalPropertyScheduleDetial,
  IHabitationalScheduleDetail,
} from "../../../../../../redux/habitationalPolicy/habitationalPropertySchedule/habitationalPropertySchedule.types";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";

interface IHabitationalScheduleDetailProps {
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  onSaveSuccess: (data: IHabitationalScheduleDetail) => void;
  staticStatusValue?: string;
  createdFrom?: string;
  initialData?: IHabitationalScheduleDetail;
  disableApiCalls?: boolean;
}

export const HabitationalScheduleDetail: React.FC<
  IHabitationalScheduleDetailProps
> = (props) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onSaveSuccess,
    fromRightPanel,
    readOnly,
    staticStatusValue,
    createdFrom,
    disableApiCalls = false,
    initialData,
  } = props;

  const { loading, data, error } = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.schedule.schedule_detail
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData ? initialData : data,
    validate: (values) => {},
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          setSaveLoading(true);
          let finalStatus = values.status;
          if (staticStatusValue) {
            finalStatus = staticStatusValue;
          }
          dispatch(
            addHabitationalScheduleDetailAsync(
              {
                ...values,
                status: finalStatus,
                created_from: createdFrom || "Schedile_Detail",
              },
              customerPolicyId,
              (isSuccess, data) => {
                if (isSuccess && data) {
                  window.scrollTo(0, 0);
                  onSaveSuccess(data);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && !disableApiCalls) {
      dispatch(
        fetchHabitationalScheduleDetailAsync(
          customerPolicyId,
          incrementalNumber
        )
      );
    }
  }, [customerPolicyId, incrementalNumber]);

  React.useEffect(() => {
    if(disableApiCalls &&  initialData){
      return;
    }
    setValues({ ...data });
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalScheduleDetail());
    };
  }, []);

  const updateAdditionalInterest = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(
      values.property_schedule_detail,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("property_schedule_detail", newValues);
  };

  const handleAdditionalInterest =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAdditionalInterest(key, rowIndex, e.target.value);
    };

  const handleSelectAdditionalInterest =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateAdditionalInterest(key, rowIndex, e.target.value);
    };

  const describeScheduleDetail: IDataTableV2Props = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    tableCommandBarProps: {
      preDefinedPlugins: {},
      leftItems: {},
      rightItems: {},
    },
    masterColumns: [
      //   {
      //     key: "property_class_code",
      //     headerName: "Property Class Code",
      //     fieldName: "property_class_code",
      //     renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
      //     onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
      //       return (
      //         <ExtendableSelect
      //           fullWidth
      //           placeholder="Select one"
      //           value={row.property_class_code}
      //           options={[].map((business) => {
      //             return { label: business, value: business };
      //           })}
      //           onChange={(newValue) => {
      //             handleSelectAdditionalInterest(
      //               "property_class_code",
      //               value.column.rowIndex
      //             )({
      //               target: {
      //                 value: newValue,
      //               },
      //             } as any);
      //           }}
      //         />
      //       );
      //     },
      //   },
      //   {
      //     key: "description",
      //     headerName: "Class code.",
      //     fieldName: "description",
      //     renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
      //     onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
      //       return (
      //         <ExtendableSelect
      //           placeholder="Select one"
      //           value={row.description}
      //           options={[
      //             "JL",
      //             "FR",
      //             "SV",
      //             "CN",
      //             "ST",
      //             "FA",
      //             "CM",
      //             "EL",
      //             "MI",
      //             "BY",
      //             "FI",
      //             "TF",
      //           ].map((business) => {
      //             return { label: business, value: business };
      //           })}
      //           onChange={(newValue) => {
      //             handleSelectAdditionalInterest(
      //               "description",
      //               value.column.rowIndex
      //             )({
      //               target: {
      //                 value: newValue,
      //               },
      //             } as any);
      //           }}
      //         />
      //       );
      //     },
      //   },
      //   {
      //     key: "item_description",
      //     headerName: "Item Description",
      //     fieldName: "item_description",
      //     renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
      //     onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
      //       return (
      //         <ExtendableSelect
      //           placeholder="Select one"
      //           value={row.item_description}
      //           options={[
      //             "Jwellery",
      //             "Furs",
      //             "Silverware",
      //             "Coins",
      //             "Stamp",
      //             "Fine Arts",
      //             "Cameras",
      //             "Electronics Equipment",
      //             "Musical Instruments",
      //             "Bicycles",
      //             "Firearms",
      //             "Tools Floater",
      //           ].map((business) => {
      //             return { label: business, value: business };
      //           })}
      //           onChange={(newValue) => {
      //             handleSelectAdditionalInterest(
      //               "item_description",
      //               value.column.rowIndex
      //             )({
      //               target: {
      //                 value: newValue,
      //               },
      //             } as any);
      //           }}
      //         />
      //       );
      //     },
      //   },

      {
        key: "property_class_code",
        headerName: "Property Class Code",
        fieldName: "property_class_code",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.property_class_code}
              onChange={handleAdditionalInterest(
                "property_class_code",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "description",
        headerName: "Description",
        fieldName: "description",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description}
              onChange={handleAdditionalInterest(
                "description",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "exhibited",
        headerName: "Exhibited",
        fieldName: "exhibited",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <ExtendableSelect
              placeholder="Select one"
              value={row.exhibited}
              options={["Yes", "No"].map((business) => {
                return { label: business, value: business };
              })}
              onChange={(newValue) => {
                handleSelectAdditionalInterest(
                  "exhibited",
                  value.column.rowIndex
                )({
                  target: {
                    value: newValue,
                  },
                } as any);
              }}
            />
          );
        },
      },
      {
        key: "professional_use",
        headerName: "Professional Use",
        fieldName: "professional_use",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <ExtendableSelect
              placeholder="Select one"
              value={row.professional_use}
              options={["Yes", "No"].map((business) => {
                return { label: business, value: business };
              })}
              onChange={(newValue) => {
                handleSelectAdditionalInterest(
                  "professional_use",
                  value.column.rowIndex
                )({
                  target: {
                    value: newValue,
                  },
                } as any);
              }}
            />
          );
        },
      },
      {
        key: "photo_attached",
        headerName: "Photo Attached",
        fieldName: "photo_attached",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <ExtendableSelect
              placeholder="Select one"
              value={row.photo_attached}
              options={["Yes", "No"].map((business) => {
                return { label: business, value: business };
              })}
              onChange={(newValue) => {
                handleSelectAdditionalInterest(
                  "photo_attached",
                  value.column.rowIndex
                )({
                  target: {
                    value: newValue,
                  },
                } as any);
              }}
            />
          );
        },
      },
      {
        key: "purchase_date",
        headerName: "Purchase Date",
        fieldName: "purchase_date",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.purchase_date}
              onChange={(newValue) => {
                updateAdditionalInterest(
                  "purchase_date",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "appraisal_date",
        headerName: "Appraisal Date",
        fieldName: "appraisal_date",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.appraisal_date}
              onChange={(newValue) => {
                updateAdditionalInterest(
                  "appraisal_date",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "all_risks_named_perils",
        headerName: "All Risks/Named Perils",
        fieldName: "all_risks_named_perils",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.all_risks_named_perils}
              onChange={handleAdditionalInterest(
                "all_risks_named_perils",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "deductible",
        headerName: "Deductible",
        fieldName: "deductible",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.deductible}
              onChange={handleAdditionalInterest(
                "deductible",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "deductible_type",
        headerName: "Deductible Type",
        fieldName: "deductible_type",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.deductible_type}
              onChange={handleAdditionalInterest(
                "deductible_type",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "amount_of_insurance",
        headerName: "Amount of Insurance",
        fieldName: "amount_of_insurance",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.amount_of_insurance}
              onChange={handleAdditionalInterest(
                "amount_of_insurance",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPropertyScheduleDetial) => {
          return (
            <CustomTextField
              fullWidth
              value={row.premium}
              onChange={handleAdditionalInterest(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    rows: values.property_schedule_detail,
  };

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      {/* <Breadcrumb title="" items={BCrumb} />
      <CustomerBaiscDetailsLayout customerId={customerId}> */}
      <StandadCard
        sx={{ mt: 2 }}
        renderWithoutCard={fromRightPanel}
        heading={fromRightPanel ? "" : "Property Schedule Detail"}
      >
        <FieldSet disabled={readOnly}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomFormLabel>Property Number</CustomFormLabel>
                <CustomTextField
                  type="number"
                  name="property_number"
                  fullWidth
                  value={values.property_number}
                  onChange={handleChange("property_number")}
                />
              </Grid>
              <Grid item xs={12}>
                <DataTableV2 {...describeScheduleDetail} />
              </Grid>
            </Grid>
            <Stack direction={"row"} spacing={2} marginTop={3}>
              <RoleBasedCustomButton
                moduleKey={MODULE_IDS.HABITATIONAL}
                disabled={saveLoading}
                type="submit"
                variant="contained"
              >
                Save
              </RoleBasedCustomButton>
            </Stack>
          </form>
        </FieldSet>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
