import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../../../../../components/formsComponents";
import { IHabitationalPolicyChangeProps } from "./HabitationalPolicyChange.types";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { useHabitationalSchedule } from "../../hooks/useHabitationalSchedule";
import { HabitationalScheduleDetailRightPanel } from "../../HabitationalScheduleDetail/HabitationalScheduleDetailRightPanel.tsx";
import { IHabitationalScheduleDetail } from "../../../../../../../redux/habitationalPolicy/habitationalPropertySchedule/habitationalPropertySchedule.types";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import produce from "immer";
import { findIndexSS } from "yjs";
import { ConfirmDialog } from "../../../../../../../components/Dialogs/ConfirmDialog";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";

export const ScheduleInformation: React.FC<IHabitationalPolicyChangeProps> = ({
  values,
  setValues,
  errors,
  handleChange,
  setFieldValue,
  customerId,
  customerPolicyId,
  autoSaveData = () => {},
  isStatusCompleted
}) => {
  const [open, setOpen] = React.useState<{
    type: "create" | "edit" | "view";
       index?: number;
       data?: IHabitationalScheduleDetail;
  } | null>(null);
  const { scheduleDropdown } = useHabitationalSchedule(customerPolicyId);

  const handleCheckBoxChange = (value: string) => {
    setFieldValue("schedule_change.type", value);
  };

  const handleSaveSuccess = (data: IHabitationalScheduleDetail) => {
    const newValues = produce(values, (draftValues) => {
      const updatedData = {
        ...data,
        customer_policy_id: customerPolicyId ?? null,
        customer_id: customerId ? Number(customerId) : null,
      };
      if (open && open.type === "edit" && open.index !== undefined) {
        draftValues.schedule_change.records[open.index] = updatedData;
      } else {
        draftValues.schedule_change.records.push(updatedData);
      }
    });
    autoSaveData(newValues);
    setValues(newValues);
    setOpen(null);
  };


  const handleCreateEdit = (index?: number) => () => {
    if(isStatusCompleted){
      return;
    }
    setOpen({
      type: index !== undefined ? "edit" : "create",
      index: index,
      data:
        index !== undefined
          ? values.schedule_change.records[index]
          : undefined,
    });
  };

  const handleView = (index: number) => () => {

    setOpen({
      type: "view",
      index: index,
    });
  };

    const [deleteConfirm, setDeleteConfirm] = React.useState<number | null>(null)
      const handleDelete =(index: number) =>() => {
        setDeleteConfirm(index)
      }
    

      const handleDeleteConfirm = () => {
        if(deleteConfirm ===null){
          return;
        }
        const newValues = produce(values, draftValues=>{
          draftValues.schedule_change.records.splice(deleteConfirm,1);
        });
        setValues(newValues);
        autoSaveData(newValues);
        setDeleteConfirm(null);
      }

  return (
    <>
      <LabelCard heading="Schedule Item Change" />
           <FieldSet disabled={isStatusCompleted}>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.schedule_change.type === "Add"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Add" : "");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.schedule_change.type === "Delete"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Delete" : "");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.schedule_change.type === "Change"}
              onChange={(e) => {
                handleCheckBoxChange(e.target.checked ? "Change" : "");
              }}
            />
            <Typography>Change</Typography>
          </Stack>
        </Grid>
      </Grid>
      </FieldSet>

      {values.schedule_change.type &&
        ["Add", "Change"].includes(values.schedule_change.type) && (
          <>
            <Grid container spacing={3}>
              {values.schedule_change.type === "Change" && (
                <Grid item xs={12} md={6}>
                  <CustomFormLabel>Delete Schedule</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="schedule_change.delete_schedule"
                    fullWidth
                    displayEmpty
                    value={Number(values.schedule_change.delete_schedule)}
                    onChange={handleChange}
                    placeholder="Select One"
                    options={scheduleDropdown}
                  ></ControlledCustomSelect>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"end"}>
                  <Button variant="contained" disabled={isStatusCompleted} onClick={handleCreateEdit()}>
                    Add New
                  </Button>
                </Stack>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>View</TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Property Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.schedule_change.records.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell sx={{ display: "flex" }}>
                              <StandardTableActions
                                onViewClick={handleView(index)}
                                onEditClick={!isStatusCompleted ? handleCreateEdit(index): undefined}
                                onDeleteClick={!isStatusCompleted ? handleDelete(index): undefined}
                              />
                            </TableCell>
                            <TableCell>{index+1}</TableCell>
                            <TableCell>{item.property_number}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}

      {values.schedule_change.type &&
        ["Delete"].includes(values.schedule_change.type) && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Schedule #</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="schedule_change.delete_schedule"
                fullWidth
                displayEmpty
                value={Number(values.schedule_change.delete_schedule)}
                onChange={handleChange}
                placeholder="Select One"
                options={scheduleDropdown}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
        )}

      {open && (
        <HabitationalScheduleDetailRightPanel
          open={true}
          readonly={open.type === "view"}
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          onSaveSuccess={handleSaveSuccess}
          onClose={() => setOpen(null)}
          createdFrom="Policy_Change"
          staticStatusValue="ACTIVE"
          initialData={open.data}
          disableApiCalls
        />
      )}

      {deleteConfirm !== null &&
              <ConfirmDialog
                open
                content="Are you sure you want to delete this record?"
                onClose={()=> setDeleteConfirm(null)}
                onConfrim={handleDeleteConfirm}
              />
      
            }
    </>
  );
};
