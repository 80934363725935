import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { IAutomobileAuthorityReport } from "../../automobileDriver/automobileDriver.types";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalClaimState {
  data: IhabitationalClaims[];
  claim: IhabitationalClaims;
  loading: LoadState;
  claimloading: LoadState;
  error: string | null;
}

export interface IHabitationalLiabilityTable {
  key: string;
  single_limit: string | null;
  dwelling_building: string | null;
  detached_structures: string | null;
  personal_property: string | null;
  additional_living_expenses: string | null;
  legal_liability: string | null;
  voluntary_medical_payment: string | null;
  voluntary_property_damage: string | null;
  deductible: string | null;
}
export interface IHabitationalAdditionalCoverage {
  key: string;
  code: string | null;
  coverage_description: string | null;
  limit: string | null;
  deductible: string | null;
  deductible_type: string | null;
  one_type_of: string | null;
}
export interface IHabitationalLiabilityExtension {
  key: string;
  code: string | null;
  liability_coverage_description: string | null;
  number_of: string | null;
  type_of_one: string | null;
  type_of_two: string | null;
}
export interface IHabitationalSchedulePersonalProperty {
  key: string;
  number: string | null;
  class_code: string | null;
  description: string | null;
  coverage_code: string | null;
  qualifier_one: string | null;
  qualifier_two: string | null;
  purchase_appraisal_date: string | null;
  deductible: string | null;
  disc: string | null;
  amt_of_ins: string | null;
}
export interface IHabitationalWaterCraftTrailer {
  key: string;
  number: string | null;
  type: string | null;
  year: string | null;
  length: string | null;
  description: string | null;
  coverage: string | null;
  deductible: string | null;
  amt_of_ins: string | null;
}
export interface IHabitationalAdditionalInterest {
  key: string;
  number: string | null;
  name_address: string | null;
  nature_of_interest: string | null;
}

export interface IhabitationalClaims {
  customer_policy_id: number;
  claim_number: string | null;
  incremental_number: number | null;
  date_of_loss: string | null;
  time_of_loss: string;
  value_of_loss: string | null;
  location: string | null;
  amount_paid_dollar: string | null;
  loss_details: string | null;
  ours: string | null;
  policy_number: string | null;
  insurance_company: string | null;
  cause_of_loss_and_description: null;
  witness: string | null;
  witness_phone: string | null;
  witness_name: string | null;
  loss_reported_to_police: string | null;
  report_number: string | null;
  adjuster: string | null;
  phone: string | null;
  phone_ext: string | null;
  mobile: string | null;
  email: string | null;
  company_name: string | null;
  at_fault: string | null;
  claim_status: string | null;
  third_party_liability: string | null;
  bodily_injury: string | null;
  accidents_benefits: string | null;
  dcpd: string | null;
  collision: string | null;
  comprehensive: string | null;
  reserves: string | null;
  cgl: string | null;
  stock: string | null;
  crime: string | null;
  equipment_breakdown: string | null;
  business_interruption: string | null;
  other: string | null;
  total_claim: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  contact_phone_ext: string | null;
  contact_mobile: string | null;
  contact_email: string | null;
  preferred_contact_method: string | null;
  status: string | null;
  effective_date: string | null;
  effective_time: string | null;
  expiry_date: string | null;
  expiry_time: string | null;
  location_of_loss: string | null;
  coverage_information: {
    type: string | null;
    package_form_and_type: string | null;
    coverage: IHabitationalLiabilityTable[];
  };
  additional_coverage: IHabitationalAdditionalCoverage[];
  liability_extension: IHabitationalLiabilityExtension[];
  schedule_personal_property: IHabitationalSchedulePersonalProperty[];
  watercraft_and_trailer: IHabitationalWaterCraftTrailer[];
  additional_interest: IHabitationalAdditionalInterest[];
  details_of_loss: {
    risk_no: string | null;
    date: string;
    time: string;
    loss_type: string | null;
    description: string | null;
  };
  authority_report: IAutomobileAuthorityReport[];
  injured_party: {
    name: string | null;
    address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province_or_state: string | null;
    postal_code: string | null;
    country: string | null;
    contact_number: number | null;
    home: string | null;
    business: string | null;
    cell: string | null;
    fax: string | null;
    preferred_language: string | null;
    specify_type: string | null;
    nature_of_injury: string | null;
    hospitalized: string | null;
  };
  remarks: string | null;
  witness_contact_information: {
    name: string | null;
    address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province_or_state: string | null;
    postal_code: string | null;
    country: string | null;
    contact_number: number | null;
    home: string | null;
    business: string | null;
    cell: string | null;
    fax: string | null;
    preferred_language: string | null;
  };
  adjuster_assignment_information: {
    name: string | null;
    address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province_or_state: string | null;
    postal_code: string | null;
    country: string | null;
    contact_number: number | null;
    home: string | null;
    business: string | null;
    cell: string | null;
    fax: string | null;
    email: string | null;
    reported_by: string | null;
    reported_date: string | null;
    reported_time: string | null;
    reported_to_company_by: string | null;
    reported_to_company_date: string | null;
    reported_to_company_time: string | null;
  };
}

export const initialHabitationalClaimDetails: IHabitationalClaimState = {
  data: [],
  claim: {
    customer_policy_id: 0,
    claim_number: null,
    incremental_number: null,
    date_of_loss: null,
    value_of_loss: null,
    time_of_loss: moment().format(),
    location: null,
    amount_paid_dollar: null,
    loss_details: null,
    ours: null,
    cause_of_loss_and_description: null,
    insurance_company: "",
    policy_number: "",
    adjuster: null,
    phone: null,
    phone_ext: null,
    mobile: null,
    email: null,
    company_name: null,
    at_fault: null,
    claim_status: null,
    third_party_liability: null,
    bodily_injury: null,
    accidents_benefits: null,
    dcpd: null,
    collision: null,
    comprehensive: null,
    reserves: null,
    cgl: null,
    stock: null,
    crime: null,
    equipment_breakdown: null,
    business_interruption: null,
    other: null,
    total_claim: null,
    contact_name: null,
    contact_phone: null,
    contact_phone_ext: null,
    contact_mobile: null,
    contact_email: null,
    preferred_contact_method: null,
    witness: null,
    witness_phone: null,
    witness_name: null,
    loss_reported_to_police: null,
    report_number: null,
    status: null,
    effective_date: null,
    effective_time: null,
    expiry_date: null,
    expiry_time: null,
    location_of_loss: null,
    coverage_information: {
      type: null,
      package_form_and_type: null,
      coverage: [
        {
          key: getUniqueId(),
          single_limit: null,
          dwelling_building: null,
          detached_structures: null,
          personal_property: null,
          additional_living_expenses: null,
          legal_liability: null,
          voluntary_medical_payment: null,
          voluntary_property_damage: null,
          deductible: null,
        },
      ],
    },
    additional_coverage: [
      {
        key: getUniqueId(),
        code: null,
        coverage_description: null,
        limit: null,
        deductible: null,
        deductible_type: null,
        one_type_of: null,
      },
      {
        key: getUniqueId(),
        code: null,
        coverage_description: null,
        limit: null,
        deductible: null,
        deductible_type: null,
        one_type_of: null,
      },
    ],
    liability_extension: [
      {
        key: getUniqueId(),
        code: null,
        liability_coverage_description: null,
        number_of: null,
        type_of_one: null,
        type_of_two: null,
      },
      {
        key: getUniqueId(),
        code: null,
        liability_coverage_description: null,
        number_of: null,
        type_of_one: null,
        type_of_two: null,
      },
    ],
    schedule_personal_property: [
      {
        key: getUniqueId(),
        number: "1",
        class_code: null,
        description: null,
        coverage_code: null,
        qualifier_one: null,
        qualifier_two: null,
        purchase_appraisal_date: null,
        deductible: null,
        disc: null,
        amt_of_ins: null,
      },
      {
        key: getUniqueId(),
        number: "2",
        class_code: null,
        description: null,
        coverage_code: null,
        qualifier_one: null,
        qualifier_two: null,
        purchase_appraisal_date: null,
        deductible: null,
        disc: null,
        amt_of_ins: null,
      },
    ],
    watercraft_and_trailer: [
      {
        key: getUniqueId(),
        number: "1",
        type: null,
        year: null,
        length: null,
        description: null,
        coverage: null,
        deductible: null,
        amt_of_ins: null,
      },
      {
        key: getUniqueId(),
        number: "2",
        type: null,
        year: null,
        length: null,
        description: null,
        coverage: null,
        deductible: null,
        amt_of_ins: null,
      },
    ],
    additional_interest: [
      {
        key: getUniqueId(),
        number: "1",
        name_address: null,
        nature_of_interest: null,
      },
      {
        key: getUniqueId(),
        number: "2",
        name_address: null,
        nature_of_interest: null,
      },
      {
        key: getUniqueId(),
        number: "3",
        name_address: null,
        nature_of_interest: null,
      },
    ],
    details_of_loss: {
      date: "",
      time: "",
      risk_no: null,
      loss_type: null,
      description: null,
    },
    authority_report: [
      {
        key: getUniqueId(),
        authority: "Police",
        municipality_city: null,
        division_station_location_number: null,
        officer_or_contact_name: null,
        contact_number: null,
        badge_number: null,
        date_reported: null,
        occurence_or_report_number: null,
        charges_laid_or_other: null,
      },
      {
        key: getUniqueId(),
        authority: "Fire Department",
        municipality_city: null,
        division_station_location_number: null,
        officer_or_contact_name: null,
        contact_number: null,
        badge_number: null,
        date_reported: null,
        occurence_or_report_number: null,
        charges_laid_or_other: null,
      },
      {
        key: getUniqueId(),
        authority: "Other",
        municipality_city: null,
        division_station_location_number: null,
        officer_or_contact_name: null,
        contact_number: null,
        badge_number: null,
        date_reported: null,
        occurence_or_report_number: null,
        charges_laid_or_other: null,
      },
    ],
    injured_party: {
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      contact_number: null,
      home: null,
      business: null,
      cell: null,
      fax: null,
      preferred_language: null,
      specify_type: null,
      nature_of_injury: null,
      hospitalized: null,
    },
    remarks: null,
    witness_contact_information: {
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      contact_number: null,
      home: null,
      business: null,
      cell: null,
      fax: null,
      preferred_language: null,
    },
    adjuster_assignment_information: {
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      contact_number: null,
      home: null,
      business: null,
      cell: null,
      fax: null,
      email: null,
      reported_by: null,
      reported_date: null,
      reported_time: null,
      reported_to_company_by: null,
      reported_to_company_date: null,
      reported_to_company_time: null,
    },
  },
  error: null,
  claimloading: LoadState.NotLoaded,
  loading: LoadState.NotLoaded,
};
