/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Checkbox,
  Divider,
  IconButton,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { getUniqueId, renderDropdownValue } from "../../../../../../helpers";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { ExtendableSelect } from "../../../../../../components/formsComponents/ExtendableSelect";
import { DataTable } from "../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { IMortgagesLossPayee } from "../../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificates.types";
import produce from "immer";
import { Add, Remove } from "@mui/icons-material";
import { PhoneTextField } from "../../../../../../components/PhoneTextField/PhoneTextField";
import {
  addHabitationalDwellingAsync,
  clearHabitationalPolicyForms,
  fetchHabitationalDwellingAsync,
} from "../../../../../../redux/habitationalPolicy/HabitationalPolicyForm/HabitationalPolicyFormActions";
import { COMMON_STATUS } from "../../../../../../constants/constants";

export const HabitationalDwelling: React.FC<{ isDuplicate?: boolean }> = (
  props
) => {
  const { customerId, customerPolicyId, serialNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    serialNumber?: number;
  };

  const { isDuplicate } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      title: "Dwelling",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearHabitationalPolicyForms());
    };
  }, []);

  const { data:dwelling, loading:dwellingLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyform.dwelling
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.habitational.basicDetails.customerInfo.data
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: dwelling,
    validate: (values) => {
      let errors: any = {};
      if (values.tel && values.tel.length !== 10) {
        errors.tel = "*tel number should be 10 digits.";
      }
      if (values.fax && values.fax.length !== 10) {
        errors.fax = "*fax number should be 10 digits.";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addHabitationalDwellingAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=4`
                );
              }
              setSaveLoading(false);
            },
            isDuplicate
          )
        );
      }
    },
  });

  const handleMortgagesLossPayeeNewRow = () => {
    const row = {
      key: getUniqueId(),
      full_name: null,
      address: "",
      city: null,
      province: null,
      postal_code: null,
    };
    setValues({
      ...values,
      mortgages_or_loss_payees: [...values.mortgages_or_loss_payees, row],
    });
  };

  const handleRemoveMortgagesLossPayeeRow = (index: number) => () => {
    const finalRows = [...values.mortgages_or_loss_payees];
    finalRows.splice(index, 1);
    setValues({ ...values, mortgages_or_loss_payees: finalRows });
  };

  const handleMortgagesLossPayeeChange =
    (key: keyof IMortgagesLossPayee, rowIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
    (data: ILocationResponsePayload) => {
      if (values.mortgages_or_loss_payees.length > rowIndex) {
        const finalRows = produce(
          values.mortgages_or_loss_payees,
          (draftRows) => {
            draftRows[rowIndex][key] = event.target.value;
          }
        );

        setValues({ ...values, mortgages_or_loss_payees: finalRows });
      }
    };

  const mortgageLossPayeeTableProps: IDataTableProps = {
    isPagination: false,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "add",
            onRender: () => (
              <Button
                size="small"
                variant="contained"
                onClick={handleMortgagesLossPayeeNewRow}
              >
                <Add fontSize="small" />
              </Button>
            ),
          },
        ],
      },
    },
    uniqueRowKeyName: "key",

    columns: [
      {
        key: "full_name",
        headerName: "Full Name",
        fieldName: "full_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              value={row.full_name}
              onChange={handleMortgagesLossPayeeChange(
                "full_name",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.address}
              onChange={handleMortgagesLossPayeeChange(
                "address",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "city",
        headerName: "City",
        fieldName: "city",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.city}
              onChange={handleMortgagesLossPayeeChange(
                "city",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "province",
        headerName: "Province",
        fieldName: "province",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.province}
              onChange={handleMortgagesLossPayeeChange(
                "province",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "postal_code",
        headerName: "Postal Code",
        fieldName: "postal_code",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              type="number"
              value={row.postal_code}
              onChange={handleMortgagesLossPayeeChange(
                "postal_code",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "delete",
        headerName: "",
        fieldName: "add",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (value, row: IMortgagesLossPayee) => {
          return (
            <Box display={"flex"}>
              <IconButton
                color="error"
                onClick={handleRemoveMortgagesLossPayeeRow(
                  value.column.rowIndex
                )}
              >
                <Remove fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleMortgagesLossPayeeNewRow}
              >
                <Add fontSize="small" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    items: values.mortgages_or_loss_payees,
  };

  React.useEffect(() => {
    if (customerPolicyId && serialNumber) {
      dispatch(fetchHabitationalDwellingAsync(customerPolicyId, serialNumber));
    }
  }, [customerPolicyId, serialNumber]);

  React.useEffect(() => {
    if (!serialNumber) {
      setValues({
        ...dwelling,
        // customer_name: customerInfo.customer_name,
        customer_id: customerInfo.customer_id,
        policy_number: customerInfo.policy_number,
        applicant_name: customerInfo.customer_name,
        name_of_principal: customerInfo.customer_name,
        risk_information: {
          risk_location_address: customerInfo.address,
          unit_or_suite: customerInfo.unit_or_suite,
          city: customerInfo.city,
          province: customerInfo.province_or_state,
          postal_code: customerInfo.postal_code,
          is_the_home_located_on_leased_land: "",
          if_yes_how_long_a_term_remains_in_the_land_lease_no_years: "",
          size_of_property_in_acres: "",
        },
      });
    } else {
      setValues(dwelling);
    }
  }, [dwelling, customerInfo]);


  return (
    <PageLoader
      loading={dwellingLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 2 }} heading="Rental Dwelling">
        <form onSubmit={handleSubmit}>
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Applicant's Informtaion
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={1}>
              <CustomFormLabel>Policy #</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                disabled
                fullWidth
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Applicant Name</CustomFormLabel>
              <CustomTextField
                name="applicant_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Is the applicant a company?</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="is_the_applicant_a_company"
                fullWidth
                value={values.is_the_applicant_a_company}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Principal Name</CustomFormLabel>
              <CustomTextField
                name="name_of_principal"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name_of_principal}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={1.5}>
              <CustomFormLabel>Date of Birth</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_of_birth}
                onChange={(newValue) => {
                  setFieldValue("date_of_birth", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={1.5}>
              <CustomFormLabel>Province</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="province_of_primary_residence"
                fullWidth
                value={values.province_of_primary_residence || "Ontario"}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  "Ontario",
                  "Quebec",
                  "Nova Scotia",
                  "New Brunswick",
                  "Manitoba",
                  "British Columbia",
                  "Prince Edward Island",
                  "Saskatchewan",
                  "Alberta",
                  "Newfoundland and Labrador",
                  "Northwest Territories",
                  "Yukon",
                  "Nunavut",
                ]
                  .sort()
                  .map((template) => {
                    return { label: template, value: template };
                  })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Source of applicant's income</CustomFormLabel>
              <CustomTextField
                name="source_of_applicants_income"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.source_of_applicants_income}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Any additional applicants</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="any_additional_applicants"
                fullWidth
                value={values.any_additional_applicants}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Financial Stability
          </Typography>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                Out of last twelve month how long has the applicant been
                employed?
              </CustomFormLabel>
              <CustomTextField
                name="out_of_last_twelve_months"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.out_of_last_twelve_months}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                How many Mortages/Encumbrances are on the property?
              </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={
                  values.how_many_mortages_encumbrances_are_on_the_property
                }
                options={["0", "1", "2", "3"].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue(
                    "how_many_mortages_encumbrances_are_on_the_property",
                    newValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is any mortgage held with a non-conventional or private lender?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="is_any_mortage_held_with_private_or_non_conve_lender"
                fullWidth
                value={
                  values.is_any_mortage_held_with_private_or_non_conve_lender
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                How many non-conventional or private lenders are required to be
                listed on the policy?
              </CustomFormLabel>
              <CustomTextField
                name="how_many_non_conv_or_private_lender_required_to_list_prop"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.how_many_non_conv_or_private_lender_required_to_list_prop
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                If three or more what is the reason for the third mortgages?
              </CustomFormLabel>
              <CustomTextField
                name="if_three_or_more_what_is_the_reason_for_the_third_mortgage"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.if_three_or_more_what_is_the_reason_for_the_third_mortgage
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                How much Equity does the applicant retain the home(%)?
              </CustomFormLabel>
              <CustomTextField
                name="how_much_equity_does_the_applicant_retain_the_home"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.how_much_equity_does_the_applicant_retain_the_home
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is the Applicant behind/late in making their Mortgage Payments?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="is_the_applicant_behind_late_in_making_their_mortgage_payments"
                fullWidth
                value={
                  values.is_the_applicant_behind_late_in_making_their_mortgage_payments
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
          <LabelCard heading="Risk Information" />
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Risk Location Address</CustomFormLabel>
              <CustomTextField
                name="risk_information.risk_location_address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.risk_information.risk_location_address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="risk_information.unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.risk_information.unit_or_suite}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="risk_information.city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.risk_information.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Province</CustomFormLabel>
              <CustomTextField
                name="risk_information.province"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.risk_information.province}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="risk_information.postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.risk_information.postal_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>
                Is the Home located on leased land?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="risk_information.is_the_home_located_on_leased_land"
                fullWidth
                value={
                  values.risk_information.is_the_home_located_on_leased_land
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                If Yes, how long a term remains in the land lease(number of
                years)?
              </CustomFormLabel>
              <CustomTextField
                name="risk_information.if_yes_how_long_a_term_remains_in_the_land_lease_no_years"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.risk_information
                    .if_yes_how_long_a_term_remains_in_the_land_lease_no_years
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Size of Property in Acres</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="risk_information.size_of_property_in_acres"
                fullWidth
                value={values.risk_information.size_of_property_in_acres}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  "City Sized Lot",
                  "Less than 3 Acres",
                  "More than 3 Acres",
                  "15 or less Acres",
                  "More than 15 Acres",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>

          <LabelCard heading="Underwriting" />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Building Information
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Year Built</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_information.year_built"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.underwriting_building_information.year_built}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Type of Building</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_information.type_of_building"
                fullWidth
                value={
                  values.underwriting_building_information.type_of_building
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Detached", value: "Detached" },
                  { label: "Duplex", value: "Duplex" },
                  { label: "Triplex", value: "Triplex" },
                  { label: "Fourplex", value: "Fourplex" },
                  { label: "Fiveplex", value: "Fiveplex" },
                  { label: "Sixplex +", value: "Sixplex +" },
                  { label: "Mobile", value: "Mobile" },
                  {
                    label: "Row House/Townhouse",
                    value: "Row House/Townhouse",
                  },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Type of Construction</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_information.type_of_construction"
                fullWidth
                value={
                  values.underwriting_building_information.type_of_construction
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Wood Frame", value: "Wood Frame" },
                  {
                    label: "Concrete Block / Masonry",
                    value: "Concrete Block / Masonry",
                  },
                  { label: "Log", value: "Log" },
                  { label: "Fire Resistive", value: "Fire Resistive" },
                  { label: "Clay", value: "Clay" },
                  { label: "Straw", value: "Straw" },
                  { label: "Modular / Prefab", value: "Modular / Prefab" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Type of Foundation</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_information.type_of_foundation"
                fullWidth
                value={
                  values.underwriting_building_information.type_of_foundation
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Poured Concrete", value: "Poured Concrete" },
                  { label: "Concrete Block", value: "Concrete Block" },
                  { label: "Crawlspace", value: "Crawlspace" },
                  { label: "Treated Lumber", value: "Treated Lumber" },
                  { label: "Brick / Stone", value: "Brick / Stone" },
                  { label: "Post + Pier", value: "Post + Pier" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>No. of Stories</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_information.no_of_stories"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.underwriting_building_information.no_of_stories}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Square Footage</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_information.square_footage"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.underwriting_building_information.square_footage}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Renovation/Demolition
          </Typography>

          <Grid container px={2} spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Will there be any construction or renovation activity on the
                premises during the next 12 months?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_renovation_or_demolition.will_construction__renovation_activity_on_next_12_months"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .will_construction__renovation_activity_on_next_12_months
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>If Yes, please describe</CustomFormLabel>
              <CustomTextField
                name="underwriting_renovation_or_demolition.if_yes_please_check_all_that_apply"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .if_yes_please_check_all_that_apply
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                What is the budget for renovations?
              </CustomFormLabel>
              <CustomTextField
                name="underwriting_renovation_or_demolition.what_is_the_budget_for_renovations"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .what_is_the_budget_for_renovations
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Will there be any structural renovations (e.g. adding or
                removing walls)?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_renovation_or_demolition.will_there_be_any_structural_renov_adding_or_removing_walls"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .will_there_be_any_structural_renov_adding_or_removing_walls
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Will the home be demolished in the next 24 months?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_renovation_or_demolition.will_the_home_be_demolished_in_the_next_24_months"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .will_the_home_be_demolished_in_the_next_24_months
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>If Yes, please describe</CustomFormLabel>
              <CustomTextField
                name="underwriting_renovation_or_demolition.if_yes_please_describe"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_renovation_or_demolition
                    .if_yes_please_describe
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Building Electrical Updates
          </Typography>

          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                How many amps is the electrical system?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_electrical.how_many_amps_is_the_electrical_system"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .how_many_amps_is_the_electrical_system
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Under 60 amps", value: "Under 60 amps" },
                  { label: "60 amps", value: "60 amps" },
                  { label: "100 amps", value: "100 amps" },
                  { label: "More than 100 amps", value: "More than 100 amps" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Electrical Details</CustomFormLabel>
              <ControlledCustomSelect
                name="underwriting_building_updates_electrical.electrical_details"
                placeholder="Select one"
                multiple
                displayEmpty
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .electrical_details || []
                }
                options={[
                  "Circuit Breakers",
                  "Fuses",
                  "Aluminium",
                  "Knob & Tube",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Types of Wiring</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_electrical.type_of_wiring"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical.type_of_wiring
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Copper", value: "Copper" },
                  { label: "Aluminum", value: "Aluminum" },
                  {
                    label: "Mixed - Copper + Aluminum",
                    value: "Mixed - Copper + Aluminum",
                  },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>
                If there is any aluminum wiring?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_electrical.if_there_is_any_aluminum_wiring"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .if_there_is_any_aluminum_wiring
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>What percentage is Aluminum? </CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_electrical.what_percentage_is_aluminum"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .what_percentage_is_aluminum
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <CustomFormLabel>
                Has it been inspected and approved by a licensed electrician?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_electrical.has_it_been_inspected_and_approved_by_a_licensed_electrician"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .has_it_been_inspected_and_approved_by_a_licensed_electrician
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is there a Knob Tube wiring in any Kitchen or Laundry Area
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_electrical.is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area"
                fullWidth
                value={
                  values.underwriting_building_updates_electrical
                    .is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>
                Year of most recent electrical update
              </CustomFormLabel>
              <CustomDatePicker
                fullWidth
                views={["year"]}
                inputFormat="YYYY"
                value={
                  values.underwriting_building_updates_electrical
                    .electrical_year_of_most_recent_electrical_update || ""
                }
                onChange={(newValue, x, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue(
                    "underwriting_building_updates_electrical.electrical_year_of_most_recent_electrical_update",
                    momentObject ? newDate : null
                  );
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Roof Updates
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Roof Material</CustomFormLabel>
              <ControlledCustomSelect
                name="underwriting_building_updates_roof.roof_material"
                placeholder="Select one"
                multiple
                displayEmpty
                fullWidth
                value={
                  values.underwriting_building_updates_roof.roof_material || []
                }
                options={[
                  "Asphalt Shingles",
                  "Cedar",
                  "Clay / Slate",
                  "Rubber",
                  "Flat Deck/Tar+Gravel",
                  "Green",
                  "Metal",
                  "Straw",
                  "Thatched",
                  "Zinc",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                Year of most recent Roof Updates
              </CustomFormLabel>
              <CustomDatePicker
                fullWidth
                views={["year"]}
                inputFormat="YYYY"
                value={
                  values.underwriting_building_updates_roof
                    .year_of_most_recent_roof_update || ""
                }
                onChange={(newValue, x, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue(
                    "underwriting_building_updates_roof.year_of_most_recent_roof_update",
                    momentObject ? newDate : null
                  );
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Plumbing Updates
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Plumbing Types</CustomFormLabel>
              <ControlledCustomSelect
                name="underwriting_building_updates_plumbing.plumbing_types"
                placeholder="Select one"
                multiple
                displayEmpty
                fullWidth
                value={
                  values.underwriting_building_updates_plumbing
                    .plumbing_types || []
                }
                options={[
                  "Copper",
                  "PEX",
                  "PVC",
                  "ABS",
                  "Polybutylene",
                  "Galvanized steel",
                  "Kitec",
                  "Cast Iron",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Year of Plumbing Update</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                views={["year"]}
                inputFormat="YYYY"
                value={
                  values.underwriting_building_updates_plumbing
                    .year_of_plumbing_update || ""
                }
                onChange={(newValue, x, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue(
                    "underwriting_building_updates_plumbing.year_of_plumbing_update",
                    momentObject ? newDate : null
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Year of Hot Water Tank</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                views={["year"]}
                inputFormat="YYYY"
                value={
                  values.underwriting_building_updates_plumbing
                    .year_of_hot_water_tank || ""
                }
                onChange={(newValue, x, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue(
                    "underwriting_building_updates_plumbing.year_of_hot_water_tank",
                    momentObject ? newDate : null
                  );
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Heating Updates
          </Typography>

          <Grid container px={2} spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                What is the primary heat device/appliance to regularly heat the
                home?
              </CustomFormLabel>
              <ControlledCustomSelect
                name="underwriting_building_updates_heating.primary_heat_device_appliance_to_regularly_heat_the_home"
                placeholder="Select one"
                multiple
                displayEmpty
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .primary_heat_device_appliance_to_regularly_heat_the_home ||
                  []
                }
                options={[
                  "Baseboard Electric",
                  "Wall Furnace / Wall Heater",
                  "Heat pump",
                  "Radiant Ceiling Heat Panels - Electric",
                  "Wood heat appliance",
                  "Boiler - In floor radiant heat",
                  "Fireplace inserts",
                  "Stoves (wood heat, pellet)",
                  "Boiler - Water baseboard",
                  "ForcedAirFurnace",
                  "PlugInSpaceHeaters",
                  "Combination - Forced Air Furnace with add on wood burning unit",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>If other, please describe</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_heating.heating_other_please_describe"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .heating_other_please_describe
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                What is the primary heating fuel/source?
              </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={
                  values.underwriting_building_updates_heating
                    .what_is_the_primary_heating_fuel_source
                }
                options={[
                  "Natural Gas",
                  "Propane",
                  "Electricity",
                  "Geothermal",
                  "Oil",
                  "Wood",
                  "Solar",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue(
                    "underwriting_building_updates_heating.what_is_the_primary_heating_fuel_source",
                    newValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Does the home have solid fuel heat device (other than a
                traditional fireplace)?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.home_solid_fuel_heat_device_other_than_traditional_fireplace"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .home_solid_fuel_heat_device_other_than_traditional_fireplace
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomFormLabel>
                If yes, is wood heat the primary source or are more than 3 cords
                of wood/2 tons of pellets burned annually?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.if_yes_woo_heat_the_primary_pellets_burned_annually"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .if_yes_woo_heat_the_primary_pellets_burned_annually
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Was the solid fuel heat device professionally installed?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.was_the_solid_fuel_heat_device_professionally_installed"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .was_the_solid_fuel_heat_device_professionally_installed
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is there a passed WETT inspection on file?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.is_there_was_a_passed_wett_inspection_on_file"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .is_there_was_a_passed_wett_inspection_on_file
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                If Yes, Do you have a copy of the WETT inspection?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.if_yes_please_attach_a_copy_of_the_wett_inspection"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .if_yes_please_attach_a_copy_of_the_wett_inspection
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Have modification been made to the solid fuel heat device?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.have_modification_been_made_to_the_solid_fuel_heat_device"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .have_modification_been_made_to_the_solid_fuel_heat_device
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
          <Typography px={1} variant="h5" marginTop={2} fontWeight={600}>
            The Applicant attests that the following risk Mangement is in place
            at the home:
          </Typography>
          <Grid container px={2} spacing={1}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                The solid fuel heat device and chimney are cleaned every year
                prior to heating season?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.solid_fuel_heat_device__chimney_cleaned_every_year"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .solid_fuel_heat_device__chimney_cleaned_every_year
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Ashes are disposed of in a metal lidded container and placed on
                a non-flammable surface?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.ashes_disposed_metal_lidded_cont_placed_non_flammable_surface"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .ashes_disposed_metal_lidded_cont_placed_non_flammable_surface
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomFormLabel>
                At least 24 inches of clear space will be maintained from the
                solid fuel heat device to furniture, fuel, or other combustible
                materials?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_heating.at_least_24_inches_of_clear_space_combustible_materials"
                fullWidth
                value={
                  values.underwriting_building_updates_heating
                    .at_least_24_inches_of_clear_space_combustible_materials
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Other Updates
          </Typography>

          <Grid container px={2} spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Does the home have an oil tank?</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_other.does_the_home_have_an_oil_tank"
                fullWidth
                value={
                  values.underwriting_building_updates_other
                    .does_the_home_have_an_oil_tank
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>If yes, year of oil tank</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                views={["year"]}
                inputFormat="YYYY"
                value={
                  values.underwriting_building_updates_other
                    .if_yes_year_of_oil_tank || ""
                }
                onChange={(newValue, x, momentObject) => {
                  const newDate = moment(momentObject).format("YYYY");
                  setFieldValue(
                    "underwriting_building_updates_other.if_yes_year_of_oil_tank",
                    momentObject ? newDate : null
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Location of oil tank</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={
                  values.underwriting_building_updates_other
                    .location_of_oil_tank
                }
                options={[
                  "Underground",
                  "Above ground in home",
                  "Above ground outside home",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue(
                    "underwriting_building_updates_other.location_of_oil_tank",
                    newValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Are there operational smoke detectors?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_other.are_there_operational_smoke_detectors"
                fullWidth
                value={
                  values.underwriting_building_updates_other
                    .are_there_operational_smoke_detectors
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is there an operational sprinkler system?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_other.is_there_an_operational_sprinkler_system"
                fullWidth
                value={
                  values.underwriting_building_updates_other
                    .is_there_an_operational_sprinkler_system
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Is there a swimming pool?</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_other.is_there_a_swimming_pool"
                fullWidth
                value={
                  values.underwriting_building_updates_other
                    .is_there_a_swimming_pool
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Wildfire & Flood
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Has the risk location ever been evacuated or put on notice of
                evacuation due to wildfire or flood?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_wildfire_and_flood.risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>If yes, please provide details</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_wildfire_and_flood.wildfire_and_food_if_yes_please_provide_details"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .wildfire_and_food_if_yes_please_provide_details
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is this risk located within 25km of a current wildfire?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_wildfire_and_flood.is_this_risk_located_within_25km_of_a_current_wildfire"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .is_this_risk_located_within_25km_of_a_current_wildfire
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is the risk located in an area that is currently under flood
                warning?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="underwriting_building_updates_wildfire_and_flood.risk_located_in_an_area_that_is_currently_under_flood_warning"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .risk_located_in_an_area_that_is_currently_under_flood_warning
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel># of common kitchens?</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_wildfire_and_flood.of_common_kitchens"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .of_common_kitchens
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Distance to Fire hydrants?</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_wildfire_and_flood.distance_to_fire_hydrant"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .distance_to_fire_hydrant
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Distance to Firehall?</CustomFormLabel>
              <CustomTextField
                name="underwriting_building_updates_wildfire_and_flood.distance_to_firehall"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.underwriting_building_updates_wildfire_and_flood
                    .distance_to_firehall
                }
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Occupancy" />

          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Confirm occupancy</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.confirm_occupancy"
                fullWidth
                value={values.occupancy.confirm_occupancy}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Occupied", value: "Occupied" },
                  { label: "Vacant", value: "Vacant" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                Minimum rental arrangements for this property?
              </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={
                  values.occupancy.minimum_rental_arrangements_for_this_property
                }
                options={["Daily", "Weekly", "Monthly", "Yearly"].map(
                  (template) => {
                    return { label: template, value: template };
                  }
                )}
                onChange={(newValue) => {
                  setFieldValue(
                    "occupancy.minimum_rental_arrangements_for_this_property",
                    newValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Do you allow your tenants to sublet or are any occupants
                subtenants?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants"
                fullWidth
                value={
                  values.occupancy
                    .do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>
                If rented, how long is the rental?
              </CustomFormLabel>
              <CustomTextField
                name="occupancy.if_rented_how_long_is_the_rental"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.occupancy.if_rented_how_long_is_the_rental}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Are there any commercial occupancies in the building?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.are_there_any_commercial_occupancies_in_the_building"
                fullWidth
                value={
                  values.occupancy
                    .are_there_any_commercial_occupancies_in_the_building
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>
                If Yes, are there any restaurants in the building?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.if_yes_are_there_any_restaurants_in_the_building"
                fullWidth
                value={
                  values.occupancy
                    .if_yes_are_there_any_restaurants_in_the_building
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Does the owner’s child live in the dwelling?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.does_the_owners_child_live_in_the_dwelling"
                fullWidth
                value={
                  values.occupancy.does_the_owners_child_live_in_the_dwelling
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>
                How many rented, self-contained units are there?
              </CustomFormLabel>
              <CustomTextField
                name="occupancy.how_many_rented_self_contained_units_are_there"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.occupancy
                    .how_many_rented_self_contained_units_are_there
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Who lives in the home?</CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={values.occupancy.who_lives_in_the_home}
                options={[
                  "Single family",
                  "Students",
                  "Roomers/Boarders",
                  "More than 2 unrelated tenants(not students)",
                  "Rooming house",
                  "Vacation rental",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue("occupancy.who_lives_in_the_home", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>If students, # of students</CustomFormLabel>
              <CustomTextField
                name="occupancy.if_students_of_students"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.occupancy.if_students_of_students}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is this a fraternity or sorority house?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.is_this_a_faternity_or_sorority_house"
                fullWidth
                value={values.occupancy.is_this_a_faternity_or_sorority_house}
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Does the owner’s child or relative live in the home?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.does_the_owners_child_or_relative_live_in_the_home"
                fullWidth
                value={
                  values.occupancy
                    .does_the_owners_child_or_relative_live_in_the_home
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>
                Who is responsible for the care/maintenance of the property?
              </CustomFormLabel>
              <ExtendableSelect
                placeholder="Select one"
                value={
                  values.occupancy
                    .who_is_responsible_for_the_care_and_maintenance_of_the_property
                }
                options={[
                  "Insured",
                  "Neighbor",
                  "Property manager",
                  "Friends/Relative",
                  "Tenant",
                ].map((template) => {
                  return { label: template, value: template };
                })}
                onChange={(newValue) => {
                  setFieldValue(
                    "occupancy.who_is_responsible_for_the_care_and_maintenance_of_the_property",
                    newValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is there any farming, business, or commercial operations on
                premises?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy.is_there_farming_business_or_commercial_operations_on_premises"
                fullWidth
                value={
                  values.occupancy
                    .is_there_farming_business_or_commercial_operations_on_premises
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>If Yes, please describe</CustomFormLabel>
              <CustomTextField
                name="occupancy.if_yes_please_describe"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.occupancy.if_yes_please_describe}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Coverage Information" />
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Residential Building Limit</CustomFormLabel>
              <CustomTextField
                name="coverage_information.residential_building_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_information.residential_building_limit}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Annual Rental Income</CustomFormLabel>
              <CustomTextField
                name="coverage_information.annual_rental_income"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_information.annual_rental_income}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Premises Liability</CustomFormLabel>
              <CustomTextField
                name="coverage_information.premises_liability"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_information.premises_liability}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Deductible</CustomFormLabel>
              <CustomTextField
                name="coverage_information.deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_information.deductible}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Loss Assessment</CustomFormLabel>
              <CustomTextField
                name="coverage_information.loss_assessment"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_information.loss_assessment}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Unit owner’s improvements and betterments
              </CustomFormLabel>
              <CustomTextField
                name="coverage_information.unit_owners_imporovements_and_betterments"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.coverage_information
                    .unit_owners_imporovements_and_betterments
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Condominium deductible assessment coverage
              </CustomFormLabel>
              <CustomTextField
                name="coverage_information.condominium_deductible_assessment_coverage"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.coverage_information
                    .condominium_deductible_assessment_coverage
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is sewer backup coverage requested?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="coverage_information.is_sewer_backup_coverage_requested"
                fullWidth
                value={
                  values.coverage_information.is_sewer_backup_coverage_requested
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>
                Is earthquake coverage requested?{" "}
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="coverage_information.is_earthquake_coverage_requested"
                fullWidth
                value={
                  values.coverage_information.is_earthquake_coverage_requested
                }
                placeholder="Select one"
                onChange={handleChange}
                displayEmpty
                options={[
                  { label: "Y", value: "Yes" },
                  { label: "N", value: "No" },
                ]}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
          <LabelCard heading="Mortgages/Loss Payees" />
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={12}>
              <DataTable {...mortgageLossPayeeTableProps} />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <CustomFormLabel>Full Name</CustomFormLabel>
              <CustomTextField
                name="mortgages_or_loss_payees.full_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mortgages_or_loss_payees.full_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Address</CustomFormLabel>
              <CustomTextField
                name="mortgages_or_loss_payees.address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mortgages_or_loss_payees.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="mortgages_or_loss_payees.city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mortgages_or_loss_payees.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Province</CustomFormLabel>
              <CustomTextField
                name="mortgages_or_loss_payees.province"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mortgages_or_loss_payees.province}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="mortgages_or_loss_payees.postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mortgages_or_loss_payees.postal_code}
                onChange={handleChange}
              />
            </Grid> */}
          </Grid>
          <LabelCard heading="Other Information" />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Policy Term
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Proposed Effective Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_term_info_proposed_effective_date || ""}
                onChange={(newValue) => {
                  setFieldValue(
                    "policy_term_info_proposed_effective_date",
                    newValue
                  );
                }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Mailing Address
          </Typography>
          <Grid container px={2} spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomFormLabel>Address</CustomFormLabel>
              <CustomTextField
                name="mailing_address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mailing_address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="mailing_unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mailing_unit_or_suite}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="mailing_city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mailing_city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Province</CustomFormLabel>
              <CustomTextField
                name="mailing_province"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mailing_province}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="mailing_postal_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.mailing_postal_code}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography
            px={1}
            variant="body1"
            fontSize={"1.2rem"}
            fontWeight={600}
          >
            Brokerage Information
          </Typography>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Brokerage</CustomFormLabel>
              <CustomTextField
                name="brokerage"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.brokerage}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Broker Id</CustomFormLabel>
              <CustomTextField
                name="broker_id"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker_id}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Email</CustomFormLabel>
              <CustomTextField
                name="broker_email"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker_email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Tel</CustomFormLabel>
              <PhoneTextField
                name="tel"
                variant="outlined"
                size="small"
                fullWidth
                value={values.tel}
                error={errors.tel ? true : false}
                helperText={errors.tel}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Fax</CustomFormLabel>
              <PhoneTextField
                name="fax"
                variant="outlined"
                size="small"
                fullWidth
                value={values.fax}
                error={errors.fax ? true : false}
                helperText={errors.fax}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.status}
                name="status"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleKey={MODULE_IDS.HABITATIONAL}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
