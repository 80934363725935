import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearBusinessPolicySubscriptionState,
  fetchBusinessPolicySubscriptionListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicySubscription/businessPolicySubscriptionActions";
import { IBusinessPolicySubscription } from "../../../../../../redux/businessPolicy/businessPolicySubscription/businessPolicySubscription.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const SubscriptionList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.subscription
  );
  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last90Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchBusinessPolicySubscriptionListAsync(customerPolicyId, status)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPolicySubscriptionState());
    };
  }, [customerPolicyId, status]);

  React.useEffect(() => {
    dispatch(fetchBusinessPolicySubscriptionListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const subscriptionProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "insurer_or_subscriber",
        headerName: "Insurer/Subscriber",
        fieldName: "insurer_or_subscriber",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <CustomLink
              to={`/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`}
              label={value.value}
            />
          );
        },
      },
      {
        key: "premium_amount",
        headerName: "Premium ($)",
        fieldName: "premium_amount",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },

      {
        key: "schedule_or_line_of_coverage",
        headerName: "Schedule/Line of Coverage",
        fieldName: "schedule_or_line_of_coverage",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "subscription_percentage",
        headerName: "Subscription%",
        fieldName: "subscription_percentage",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },

      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 30,
      },
      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 30,
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Subscription"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...subscriptionProps} />
    </>
  );
};
