import { Box, Card, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { Error } from "@mui/icons-material";
import { AnimatedBarList } from "../PageLoader/PageLoader";

interface IProgressCardProps {
  heading: string;
  subText: string;
  isError?: boolean;
}

export const ProgressCard: React.FC<IProgressCardProps> = ({
  heading,
  subText,
  isError,
}) => {
  return (
    <Card sx={{ p: 0 }}>
      {!isError && <LinearProgress color="primary" />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "50vh",
        }}
      >
        {isError ? (
          <Error sx={{ fontSize: "50px" }} color="error" />
        ) : (
          <AnimatedBarList />
        )}

        <Box mt={2}>
          <Typography variant="h3" textAlign={"center"} color={isError? "error.main": "unset"} fontWeight={600}>
            {heading}
          </Typography>
          <Typography variant="body1"   textAlign={"center"}>
            {subText}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
