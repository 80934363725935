import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ITransactionType,
  initialTransactionTypeState,
} from "./TransactionType.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IDataTableV2DateState } from "../../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_TRANSACTION_TYPE_FAILURE = "FETCH_TRANSACTION_TYPE_FAILURE";
export const FETCH_TRANSACTION_TYPE_PROGRESS =
  "FETCH_TRANSACTION_TYPE_PROGRESS";
export const FETCH_TRANSACTION_TYPE_SUCCESS = "FETCH_TRANSACTION_TYPE_SUCCESS";

export const fetchTransactionTypeProgress = () =>
  action(FETCH_TRANSACTION_TYPE_PROGRESS);
export const fetchTransactionTypeSuccess = (data: ITransactionType) =>
  action(FETCH_TRANSACTION_TYPE_SUCCESS, { data });
export const fetchTransactionTypeFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_TYPE_FAILURE, { errorMessage });

export const fetchAccountingTransactionTypeAsync =
  (TransactionTypeId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionTypeProgress());

      const res = await api.get(
        `/accounting/get-account-transaction-type?transaction_type_id=${TransactionTypeId}`
      );
      const data: ITransactionType[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTransactionTypeSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(fetchTransactionTypeFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingTransactionTypeAysnc =
  (
    data: ITransactionType,
    onCallback: (isSuccess: boolean, transaction_type_id: string | null) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/accounting/edit-account-transaction-type", {
        ...data,
      });
      const transactionTypeId = res.data.data.transaction_type_id;
      let message = "Transaction saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true, transactionTypeId);
    } catch (err: any) {
      onCallback(false, null);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TRANSACTION_TYPE_LIST_PROGRESS =
  "FETCH_TRANSACTION_TYPE_LIST_PROGRESS";
export const FETCH_TRANSACTION_TYPE_LIST_SUCCESS =
  "FETCH_TRANSACTION_TYPE_LIST_SUCCESS";
export const FETCH_TRANSACTION_TYPE_LIST_FAILURE =
  "FETCH_TRANSACTION_TYPE_LIST_FAILURE";

export const fetchTransactionTypeListProgress = () =>
  action(FETCH_TRANSACTION_TYPE_LIST_PROGRESS);
export const fetchTransactionTypeListSuccess = (data: ITransactionType[]) =>
  action(FETCH_TRANSACTION_TYPE_LIST_SUCCESS, { data });
export const fetchTransactionTypeListFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_TYPE_LIST_FAILURE, { errorMessage });

export const fetchAccountingTransactionTypeListAsync = (
  pageNumber: number,
  rowsInPerPage: number,
  limit: number,
  status: string,
  date: IDataTableV2DateState["dates"],
  searchValue: IDatatableV2AdvancedSearchFilter
): ThunkAction<void, IStoreState, {}, AnyAction> => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionTypeListProgress());

      let finalUrl = `/accounting/get-account-transaction-type?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/accounting/get-account-transaction-type?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: ITransactionType[] = res.data.data;
      dispatch(fetchTransactionTypeListSuccess(data));
    } catch (err: any) {
      dispatch(fetchTransactionTypeListFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
};

export const fetchAllTransactionTypeListAsync = (): ThunkAction<
  void,
  IStoreState,
  {},
  AnyAction
> => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionTypeListProgress());
      let finalUrl = `/accounting/get-account-transaction-type?status=ACTIVE`;

      const res = await api.get(finalUrl);
      const data: ITransactionType[] = res.data.data;
      dispatch(fetchTransactionTypeListSuccess(data));
    } catch (err: any) {
      dispatch(fetchTransactionTypeListFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
};

export const CLEAR_TRANSACTION_TYPE = "CLEAR_TRANSACTION_TYPE";
export const clearTransactionType = () => action(CLEAR_TRANSACTION_TYPE);
