import produce from "immer";

import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialBrokerContactState } from "./brokerContacts.types";
import { BrokerContactsActions } from ".";
import { CLEAR_BROKER_CONTACTS, FETCH_BROKER_CONTACTS_FAILED, FETCH_BROKER_CONTACTS_LIST_FAILED, FETCH_BROKER_CONTACTS_LIST_PROGRESS, FETCH_BROKER_CONTACTS_LIST_SUCCESS, FETCH_BROKER_CONTACTS_PROGRESS, FETCH_BROKER_CONTACTS_SUCCESS } from "./brokerContactsActions";

export const borkerContactsReducer = (
  state: IStoreState["borkerage"]["brokerContacts"] = initialBrokerContactState,
  action: BrokerContactsActions
) => {
  switch (action.type) {
      case FETCH_BROKER_CONTACTS_LIST_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.list = [];
          draftState.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_BROKER_CONTACTS_LIST_SUCCESS: {
        const { data, totalRecords } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.list = data;
          draftState.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_BROKER_CONTACTS_LIST_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.list = [];
          draftState.totalRecords = 0;
        });
        return newState;
      }

      case FETCH_BROKER_CONTACTS_PROGRESS: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.InProgress;
          draftState.data = initialBrokerContactState['data'];
          draftState.error = null;
        });
        return newState;
      }
      case FETCH_BROKER_CONTACTS_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Loaded;
          draftState.data = data;
          draftState.error = null;
        });
        return newState;
      }
      case FETCH_BROKER_CONTACTS_FAILED: {
        const newState = produce(state, (draftState) => {
          draftState.loading = LoadState.Failed;
          draftState.data = initialBrokerContactState['data'];
          draftState.error = null;
        });
        return newState;
      }

      case CLEAR_BROKER_CONTACTS: {
        return initialBrokerContactState;
      }
  
    default: {
      return state;
    }
  }
};
