/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { clearInterestedPartyState } from "../../../../../../redux/InterestedParties/IntrestedPartiesActions";
import { fetchHabitationalIntrestedListAsync } from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterestedActions";

export const useHabitationalIntrestedParty = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();

    const InterestedParty = useSelector(
      (storeState: IStoreState) => storeState.habitational.interested.data
    );

  const interestedPartyDropDown = React.useMemo(() => {
    return InterestedParty.map((item) => {
      return {
        label: `Risk Location: ${item.location_number}, Interested Party: ${item.mortgage_or_loss_payee_name})`,
        value: item.incremental_number || "",
      };
    });
  }, [InterestedParty]);


    React.useEffect(() => {
      if (customerPolicyId) {
        dispatch(fetchHabitationalIntrestedListAsync(Number(customerPolicyId)));
      }
      return(()=>{
        dispatch(clearInterestedPartyState());
      })
    }, []);
  


  return { interestedPartyDropDown };
};
