/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  Contacts,
  ManageAccounts,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { AutomobilePriorPolicy } from "./PriorPolicy/PriorPolicy";
import { AutomobileCustomerInfo } from "./CustomerInfo/CustomerInfo";
import { AutomobilePolicyAdmin } from "./PolicyAdmin/PolicyAdmin";
import { AutomobilePremium } from "./Premium/Premium";
import { fetchAutomobileCustomerInfoAsync } from "../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { TabsList } from "../../../../../../components/Tabs/TabsList";
import { useParams } from "react-router-dom";
import { AutomobileAttachmentList } from "./Attachment/AttachmentList";
import { AutomobilePolicyAdminManager } from "./PolicyAdminManager/PolicyAdminManager";
import { usePremissions } from "../../../../../../security/PremissionsProvider/PremissionsProvider";
import { TabsUncontrolledProvider } from "../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { AutomobileAdditioanlInsured } from "./AdditionalInsured/AdditionalInsured";

export const AutomobileBasicDetails = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const [tab, setTab] = React.useState(0);
  const { customerInfo, customerPoliycId: savedCustomerPolicyId } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );
  const { premissionsList } = usePremissions();
  const showAdminManager = premissionsList.find((x) => {
    if (
      x.module_key === MODULE_IDS.AUTO_POLICY_ADMIN_MANAGER &&
      x.show_module
    ) {
      return true;
    }
    return false;
  });

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const changeStep = (newStep: number) => {
    setTab(newStep);
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  if (!customerId) {
    return null;
  }

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <TabsUncontrolledProvider step={tab} changeStep={changeStep}>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={tab}
          tabs={[
            { label: "Customer Info", icon: <AccountCircle /> },
            // {
            //   label: "Insurer Contact",
            //   icon: <Contacts />,
            //   disabled: !savedCustomerPolicyId,
            // },
            {
              label: "Prior Policy & NPC",
              icon: <AdminPanelSettings />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Policy Admin",
              icon: <StickyNote2 />,
              disabled: !savedCustomerPolicyId,
            },

            {
              label: "Policy Admin Manager",
              icon: <ManageAccounts />,
              hidden: !showAdminManager,
              disabled: !savedCustomerPolicyId,
            },
            // {
            //   label: "Premium",
            //   icon: <Receipt />,
            //   disabled: !savedCustomerPolicyId,
            // },
          ]}
          onChange={handleChange}
        />
        <StandadCard>
          <TabPanel value={tab} index={0}>
            <AutomobileCustomerInfo
              customerPolicyId={savedCustomerPolicyId}
              customerId={customerId}
            />
          </TabPanel>
          {savedCustomerPolicyId && (
            <>
              {/* <TabPanel value={tab} index={1}>
                <AutomobileAdditioanlInsured
                  customerpolicyId={savedCustomerPolicyId}
                />
              </TabPanel> */}
              <TabPanel value={tab} index={1}>
                <AutomobilePriorPolicy
                  customerpolicyId={savedCustomerPolicyId}
                />
              </TabPanel>

              <TabPanel value={tab} index={2}>
                <AutomobilePolicyAdmin
                  customerpolicyId={savedCustomerPolicyId}
                />
              </TabPanel>

              <TabPanel value={tab} index={3}>
                <AutomobilePolicyAdminManager
                  customerpolicyId={savedCustomerPolicyId}
                />
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <AutomobilePremium customerpolicyId={savedCustomerPolicyId} />
              </TabPanel>
            </>
          )}
        </StandadCard>
      </TabsUncontrolledProvider>
    </PageLoader>
  );
};
