import React from "react";
import { Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { IHabitationalPolicyChangeProps } from "./HabitationalPolicyChange.types";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { AddressSearchAutoComplete } from "../../../../../../../components/AddressAutoComplete/AddressSearchAutoComplete";
import { ILocationResponsePayload } from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";

export const AddressChangeInformation: React.FC<
  IHabitationalPolicyChangeProps
> = ({ values, setValues, errors, handleChange, setFieldValue }) => {
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address_change: {
        ...values.address_change,
        address: data.address,
        city: data.city,
        country: data.country,
        state: data.state,
        postal_code: data.postalCode,
      },
    });
  };

  return (
    <>
      <LabelCard heading="Address Change" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={1.5}>
          <CustomFormLabel>Address Type</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            displayEmpty
            name="address_change.address_type"
            value={values.address_change.address_type}
            onChange={handleChange}
            placeholder="Select One"
            options={[
              {
                label: "Mailing Address",
                value: "Mailing Address",
              },
              {
                label: "Garaging Location",
                value: "Garaging Location",
              },
              {
                label: "Both",
                value: "Mailing Address & Garaging Location",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <AddressSearchAutoComplete
            address={values.address_change.address}
            onAddressChange={handleAddress}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>Address 2</CustomFormLabel>
          <CustomTextField
            name="address_change.address_two"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_change.address_two}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={2}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            name="address_change.city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_change.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <CustomFormLabel>State/Province</CustomFormLabel>
          <CustomTextField
            name="address_change.state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_change.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            name="address_change.postal_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_change.postal_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            name="address_change.country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_change.country}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
