import React from "react";


export interface IFieldSetProps {
    disabled?: boolean;
    children: React.ReactNode;
}


export const FieldSet: React.FC<IFieldSetProps> = (props) => {
    const { disabled,children } = props;


    if(disabled){
        return <fieldset style={{pointerEvents: 'none', border: 0, padding: 0}}>
            {children}
        </fieldset>
    }
    return <fieldset style={{ border: 0, padding: 0}}>
    {children}
</fieldset>
}