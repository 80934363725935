/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { useDispatchWrapper, useFetchPolicies } from "../../../../../../hooks";
import { IAutomobileQuickAplication } from "../../../../../../redux/automobileApplication/automobileApplication.types";
import { useNavigate } from "react-router-dom";
import { addAutomobileQuickApllication } from "../../../../../../redux/automobileApplication/automobileApplicationActions";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { useAutomobilePolicyDriverInfo } from "../hooks/useAutomobilePolicyDriverInfo";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { useAutomobilePolicyVehcileInfo } from "../hooks/useAutomobileVehicleInfo";

export interface IAutomobileQuickApplicationProps {
  open: boolean;
  customerPolicyId: number;
  customerId: number;
  data: IAutomobileQuickAplication;
  onComplete: () => void;
  onClose: () => void;
}
export const QuickApplicationDialog: React.FC<
  IAutomobileQuickApplicationProps
> = (props) => {
  const { open, data, customerPolicyId, customerId, onClose, onComplete } =
    props;
  const { policies } = useFetchPolicies();
  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStep = (stepValue: number) => {
    stepValue === 0 ? setActiveStep(1) : setActiveStep(0);
  };
  const { driverDropDown } = useAutomobilePolicyDriverInfo(customerPolicyId);
  const { vehcileDropdown } = useAutomobilePolicyVehcileInfo(customerPolicyId);

  const navigate = useNavigate();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {},
    onSubmit: async (values) => {
      if (customerId && customerPolicyId) {
        setLoading(true);
        dispatch(
          addAutomobileQuickApllication(
            {
              ...values,
              customer_policy_id: customerPolicyId,
              customer_id: customerId,
            },
            (isSuccess) => {
              if (isSuccess) {
                onComplete();
              }
              setLoading(false);
            }
          )
        );
      }
    },
  });

  return (
    <>
      <Dialog
        open={open}
        title="Create Quick Application"
        onClose={onClose}
		size={activeStep === 0 ? "sm" : "md"}
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          {
            type: "button",
            label: activeStep === 0 ? "Next" : "Back",
            variant: "contained",
            onClick: () => {
              handleStep(activeStep);
            },
            disabled: !values.policy_type,
          },
          {
            type: "submit",
            label: "Save & Close",
            variant: "contained",
            disabled: loading,
            hidden: activeStep === 0 && true,
          },
        ]}
      >
        <Stepper sx={{ mb: 3, mt: 3 }} activeStep={activeStep}>
          {["Step 1", "Step 2"].map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography variant="h4">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          <TabPanel value={activeStep} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Stack direction="row" spacing={4}>
                  <Stack direction="row" spacing={0}></Stack>
                  <Stack direction="row" spacing={0}></Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomFormLabel>Policy Type</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.policy_type}
                  name="policy_type"
                  onChange={handleChange}
                  placeholder="Select One"
                  displayEmpty
                  options={policies.map((template) => {
                    return { label: template.type, value: template.type };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeStep} index={1}>
            <LabelCard heading="Select Drivers" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Driver 1</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="driver_incremental_number.driver_one"
                  value={Number(values.driver_incremental_number.driver_one)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Driver 2</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="driver_incremental_number.driver_two"
                  value={Number(values.driver_incremental_number.driver_two)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Driver 3</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="driver_incremental_number.driver_three"
                  value={Number(values.driver_incremental_number.driver_three)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomFormLabel>Driver 4</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="driver_incremental_number.driver_three"
                  value={Number(values.driver_incremental_number.driver_four)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
            </Grid>
            <LabelCard heading="Select Vehicles" />
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Vehicle 1</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="vehicle_incremental_number.vehicle_one"
                  value={Number(values.vehicle_incremental_number.vehicle_one)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Vehicle 2</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="vehicle_incremental_number.vehicle_two"
                  value={Number(values.vehicle_incremental_number.vehicle_two)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Vehicle 3</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="number"
                  name="vehicle_incremental_number.vehicle_three"
                  value={Number(values.vehicle_incremental_number.vehicle_three)}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={driverDropDown}
                />
              </Grid>
              
            </Grid>
          </TabPanel>
        </form>

        {/* <Grid container>
		  <Grid item xs={12} md={4}>
			<CustomFormLabel>Status</CustomFormLabel>
			<ControlledCustomSelect
			  fullWidth
			  value={values.status}
			  name="status"
			  onChange={handleChange}
			  placeholder="Select one"
			  displayEmpty
			  options={COMMON_STATUS}
			/>
		  </Grid>
		</Grid> */}
      </Dialog>
    </>
  );
};
