import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import {
    clearAutomobileClaimInfo,
  fetchAutomobileDriverClaimsListAsync,
} from "../../../../../../redux/automobileDriver/automobileDriverActions";

export const useAutomobilePolicyDriverClaim = (customerPolicyId?: number) => {
  const dispatch = useDispatchWrapper();
  const DriverConviction = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.driver.driverClaims.driverClaimsList
  );

  const driverClaimDropDown = React.useMemo(() => {
    return DriverConviction.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (Insurer: ${
          item.insurer || "N/A"
        } )`,
        value: item.incremental_number || "",
      };
    });
  }, [DriverConviction]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverClaimsListAsync(Number(customerPolicyId)));
    }
    return () => {
      dispatch(clearAutomobileClaimInfo());
    };
  }, [customerPolicyId]);

  return { driverClaimDropDown };
};
