import produce from "immer";
import {
  ADD_SUB_INSURER,
  ADD_SUB_INSURER_ID,
  ADD_SUB_INSURER_PROGRESS,
  SUB_INSURER_ERROR,
  CLEAR_SUB_INSURER_STATE,
  FETCH_SUB_INSURER_PROGRESS,
  FETCH_SUB_INSURER_LIST_PROGRESS,
  FETCH_SUB_INSURER_LIST_SUCCESS,
  FETCH_SUB_INSURER_LIST_FAILED,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialSubInsurerState } from "./subInsurerState";
import { ISubInsurerActions } from ".";

export const subInsurerReducer = (
  state: IStoreState["borkerage"]["subInsurer"] = initialSubInsurerState,
  action: ISubInsurerActions
) => {
  switch (action.type) {
    case FETCH_SUB_INSURER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.loading = LoadState.InProgress;
        draftState.subInsurer.list = [];
      });
      return newState;
    }
    case FETCH_SUB_INSURER_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.loading = LoadState.Loaded;
        draftState.subInsurer.list = list;
      });
      return newState;
    }
    case FETCH_SUB_INSURER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.loading = LoadState.InProgress;
        draftState.subInsurer.list = [];
      });
      return newState;
    }
    case ADD_SUB_INSURER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.saveLoading = true;
      });
      return newState;
    }
    case ADD_SUB_INSURER: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.loading = LoadState.Loaded;
        draftState.subInsurer.saveLoading = false;
        draftState.subInsurer.data = data;
      });
      return newState;
    }

    case FETCH_SUB_INSURER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.loading = LoadState.InProgress;
      });
      return newState;
    }

    case SUB_INSURER_ERROR: {
      const newState = produce(state, (draftState) => {
        draftState.subInsurer.error = action.payload.message;
        draftState.subInsurer.saveLoading = false;
        draftState.subInsurer.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_SUB_INSURER_STATE: {
      return initialSubInsurerState;
    }

    default: {
      return state;
    }
  }
};
